import React, { FC, useRef, InputHTMLAttributes, FormEvent } from 'react';
import UploadSVG from '~/assets/Icons/UploadSVG';
import FileIconSVG from '~/assets/Icons/FileIconSVG';
import TrashIconSVG from '~/assets/Icons/TrashIconSVG';
import {
  Wrapper,
  Upload,
  UploadLabel,
  UploadWrapper,
  UploadSpecs,
  Button,
} from './styles';

interface IUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  children: string;
  label?: string;
  className?: string;
  handleUpload: (field: string, fileLabel: string, image: File | null) => void;
  content: any[];
  handlePreview?: any;
  handleDelete: (field: string, fileLabel: string) => void;
  fileLabel: string;
  error: any;
}

const UploadField: FC<IUploadProps> = ({
  children,
  className,
  content,
  handleUpload,
  label,
  handlePreview,
  fileLabel,
  error,
  handleDelete,
  ...props
}) => {
  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    if (
      props.name &&
      (event.target as HTMLInputElement).files &&
      (event.target as HTMLInputElement).files?.[0]
    ) {
      const image = (event.target as HTMLInputElement).files?.[0];
      handleUpload(props.name, fileLabel, image || null);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".pdf,.jpg,.png"
        {...props}
      />
      {content ? (
        <Wrapper className={className}>
          {label ? <UploadLabel>{label}</UploadLabel> : null}
          <UploadWrapper>
            <Button onClick={handleClick}>
              <FileIconSVG />
            </Button>
            <Upload
              selected
              type="button"
              onClick={() => handlePreview(props.name)}
              error={error}
            >
              {children.slice(0, 21)} ...
              <span>Ver arquivo</span>
            </Upload>
            <Button
              className="empty"
              type="button"
              onClick={() => {
                handleDelete(props.name || '', fileLabel);
                hiddenFileInput.current.value = '';
              }}
            >
              <TrashIconSVG />
            </Button>
          </UploadWrapper>
        </Wrapper>
      ) : (
        <Wrapper className={className}>
          {label ? <UploadLabel>{label}</UploadLabel> : null}
          <UploadWrapper>
            <Upload type="button" onClick={handleClick} error={error}>
              {children}
            </Upload>
            <Button className="right" type="button" onClick={handleClick}>
              <UploadSVG />
            </Button>
          </UploadWrapper>
          <UploadSpecs>Envie no formato PDF ou JPG.</UploadSpecs>
        </Wrapper>
      )}
    </>
  );
};

export { UploadField };
