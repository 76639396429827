import { Reducer } from 'redux';
import {
  RepositoriesTypesEnrollmentsTogglesEnrollment,
  IRepositoriesStateEnrollmentsTogglesEnrollment,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsTogglesEnrollment = {
  data: [],
  loading: false,
  error: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsTogglesEnrollment, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsTogglesEnrollment.TOGGLES_ENROLLMENT_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsTogglesEnrollment.TOGGLES_ENROLLMENT_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesEnrollmentsTogglesEnrollment.TOGGLES_ENROLLMENT_GET_METHOD_FAILURE:
      return {
        ...state,
        data: [],
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
