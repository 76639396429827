import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export const Main = styled.div`
  padding: 20px 9px 63px 9px;
  width: 100%;
  background: ${COLORS.white};
  box-sizing: border-box;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    padding: 48px 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    padding: 22px 80px 22px 51px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 22px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
`;

export const Card = styled.section`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  margin: 0 auto;
  border-top: 25px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;
  padding-bottom: 40px;

  h1 {
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
    text-align: left;
    color: ${Color.blueIntense};
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 0 8px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 73px;
  }
`;

export const Line = styled.hr`
  border-color: ${Color.grayLightSeconde};
  border-bottom-color: transparent;
  width: 100%;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: ${Color.blueIntense};

  b {
    font-weight: 600;
  }

  a {
    text-decoration-line: underline;
    color: ${Color.blueSeconde};
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0 auto;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TitleLine = styled.h2`
  position: absolute;
  text-align: center;
  background: white;
  left: 105px;
  padding: 0 30px 0 30px;

  color: ${Color.blueIntense};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ButtonInfo = styled.button<{ isActive: boolean }>`
  width: 70%;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? Color.blueSeconde : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
  border: 2px solid ${Color.blueSeconde};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  svg {
    position: absolute;
    right: 32px;
    path {
      fill: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  svg {
    position: static;
    margin-right: 4px;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

export const EmptyContainer = styled.td`
  width: 100%;
  padding: 70px;
`;

export const TitleFilter = styled.p`
  margin: 24px 0px;
  font-size: 16px;
  font-weight: 500;
  color: ${Color.blueIntense};
`;
