import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div<{ statusColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 32px 24px 32px;
  gap: 24px;
  background: #f2f2f2;
  border-top: 8px solid ${({ statusColor }) => statusColor};
  border-radius: 8px;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 16px;

  input,
  textarea {
    :disabled {
      background: #f2f2f2;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 40px;
  }
`;

export const ContainerDescriptionText = styled.div`
  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-left: auto;
  }
`;

export const StatusText = styled.div<{ statusColor: string }>`
  color: ${({ statusColor }) => statusColor};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

export const ProtocolText = styled.div`
  color: #004b55;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
`;

export const DeadlineConclusionText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #004b55;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const BoxInput = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(50% - 20px);
  }
`;
