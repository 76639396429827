import { InputHTMLAttributes, HTMLInputTypeAttribute } from 'react';

import styled from 'styled-components';

import { Color } from '../../styles';

export const ContainerInputStyled = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
`;

export const InputCheckedStyled = styled.input<
  InputHTMLAttributes<HTMLInputTypeAttribute>
>`
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;

  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  min-width: 20px;
  height: 20px;
  border: 2px solid #37b4c8;
  border-radius: 3px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  ::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #37b4c8;
    border-radius: 3px;
  }

  :checked::before {
    transform: scale(1);
  }
`;

export const LabelStyled = styled.label`
  color: ${Color.blueSeconde};
  line-height: 17px;
  font-weight: 500;
  font-size: 16px;
`;

export const ContainerIconStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 8px;
`;
