/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import formatter from '~/utils/formatter';
import StatusInfo from '../../components/StatusInfo';
import { BoxInfos, BoxInputs, ContainerInfo, Main } from './styles';
import { Subtitle } from '../../styles';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ButtonBar from '../../components/ButtonBar';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  clearCreatePropertyRegistrationRequest,
  clearValidatePropertyRegistrationRequest,
  createPropertyRegistrationRequest,
  getValidatePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';

const ProcessIdentification: FC = () => {
  const [typesWork, setTypesWork] = useState<Array<string>>([]);
  const [typeWorkSelect, setTypeWorkSelect] = useState<'FCPA' | 'FCPE' | ''>('');
  const [numberCpae, setNumberCpae] = useState('');
  const [erro, setErro] = useState('');
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const dataValidate = useSelector(
    (item: IState) => item.propertyRegistration.dataValidate,
  );
  const loadingValidate = useSelector(
    (item: IState) => item.propertyRegistration.loadingValidate,
  );
  const loadingData = useSelector(
    (item: IState) => item.propertyRegistration.loadingData,
  );
  const errorValidate = useSelector(
    (item: IState) => item.propertyRegistration.erroValidate,
  );
  const errorData = useSelector(
    (item: IState) => item.propertyRegistration.erroData,
  );

  const labelTypeFCP = {
    FCPA: 'água',
    FCPE: 'esgoto',
    '': '',
  };

  useEffect(() => {
    console.log('errorValidate', errorValidate);
    if (
      (errorValidate?.message && !dataValidate) ||
      numberCpae.length < 8 ||
      loadingValidate
    ) {
      return setDisabled(true);
    }
    return setDisabled(false);
  }, [errorValidate?.message, dataValidate, numberCpae]);

  useEffect(() => {
    if (dataValidate?.FCPA || dataValidate?.FCPE) {
      let options: Array<any> = [];

      if (dataValidate?.FCPA) options.push({ value: 'FCPA', label: 'Água' });
      if (dataValidate?.FCPE) options.push({ value: 'FCPE', label: 'Esgoto' });

      setTypesWork(options);
      setTypeWorkSelect(options[0].value);
    } else {
      setTypesWork([]);
    }
  }, [dataValidate]);

  useEffect(() => {
    setErro(errorData?.message || '');
  }, [errorData]);

  return (
    <>
      <Subtitle>
        Preencha e confira as informações abaixo para dar início ao processo.
      </Subtitle>
      <Margin height={64} />
      <Main>
        <BoxInputs>
          <Input
            styleInput={{ width: `40%` }}
            label="Número CPAE"
            value={numberCpae}
            disabled={!!loadingValidate}
            onChange={(ev: any) => {
              setErro('');
              setNumberCpae(formatter.formatarNumber(ev.target.value) || '');
              if (ev.target.value.length === 8) {
                dispatch(clearCreatePropertyRegistrationRequest());
                dispatch(
                  getValidatePropertyRegistrationRequest({ case: ev.target.value }),
                );
              } else {
                dispatch(clearValidatePropertyRegistrationRequest());
              }
            }}
            error={
              !!(
                errorValidate &&
                errorValidate?.message === EnumResponseCode.CASE_NOT_FOUND
              )
            }
            errorText={
              errorValidate &&
              errorValidate?.message === EnumResponseCode.CASE_NOT_FOUND
                ? 'Não encontrado'
                : ''
            }
            errorPosition="bottom"
            maxLength={8}
          />

          <Margin width={40} />
          <InputSelect
            styleInput={{ width: `30%` }}
            label="Tipo de obra"
            values={typesWork}
            disabled={typesWork.length < 1}
            onChange={(ev: any) => setTypeWorkSelect(ev.target.value)}
          />
        </BoxInputs>
        {dataValidate && !errorValidate && (
          <BoxInfos>
            <p>
              NÚMERO DA CPAE: <span>{dataValidate?.case}</span>
            </p>
            <p>
              PROPRIETÁRIO: <span>{dataValidate?.owner}</span>{' '}
            </p>
            <p>
              ENDEREÇO: <span>{dataValidate?.address}</span>
            </p>
            <p>
              TIPO: <span>{dataValidate?.type}</span>
            </p>
            <p>
              QUANTIDADE DE ECONOMIAS: <span>{dataValidate?.savings}</span>
            </p>
          </BoxInfos>
        )}
        <ContainerInfo>
          {errorValidate &&
            errorValidate?.message === EnumResponseCode.OC_NOT_REQUESTED && (
              <>
                <Margin height={40} />
                <StatusInfo status="warning">
                  Solicite a O.C.A. e/ou O.C.E. referente a CPAE {numberCpae} para o
                  seguir com o processo de FCP.
                  <br />
                  Qualquer dúvida entre em contato com nossos canais de atendimento.
                </StatusInfo>
              </>
            )}
          {errorValidate &&
            errorValidate?.message === EnumResponseCode.FCP_NOT_ALLOWED && (
              <>
                <Margin height={40} />
                <StatusInfo status="warning">
                  FCP referente ao CPAE {numberCpae} ainda não liberada.
                  <br />
                  Qualquer dúvida entre em contato com nossos canais de atendimento.
                </StatusInfo>
              </>
            )}
          {erro === EnumResponseCode.FCP_ALREADY_REQUESTED && (
            <>
              <Margin height={40} />
              <StatusInfo status="info">
                Você já solicitou FCP {labelTypeFCP[typeWorkSelect]} para o número
                CPAE {numberCpae}.
              </StatusInfo>
            </>
          )}
        </ContainerInfo>

        <ButtonBar
          backOnClick={() =>
            Navigation.navigate(RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS)
          }
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={loadingValidate || loadingData || false}
          nextOnClick={() => {
            dispatch(
              createPropertyRegistrationRequest({
                cpaeCase: dataValidate?.id || '',
                operation: dataValidate?.operation || '',
                type: typeWorkSelect,
              }),
            );
          }}
        />
      </Main>
    </>
  );
};

export default ProcessIdentification;
