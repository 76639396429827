import styled from 'styled-components';
import { Color } from '~/styles';

interface Props {
  checked: boolean;
}

export const Checkbox = styled.div<Props>`
  display: flex;
  align-items: center;
`;

export const CheckboxHidden = styled.input.attrs({ type: 'checkbox' })<Props>`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const CheckboxLabel = styled.label<Props>`
  cursor: pointer;
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const CheckboxStyled = styled.label<Props>`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(Props) => (Props.checked ? Color.blueSeconde : '')};
  border: 1px solid ${Color.blueSeconde};
  border-radius: 4px;
  cursor: pointer;
`;
