import styled, { css } from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid ${Color.yellow};
  border-radius: 16px;
  background: #f2f2f2;
  padding: 24px;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    height: calc(91px - 52px);
    flex-direction: row;
  }
`;

export const ContainerIcon = styled.span`
  max-height: 44px;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px 0;
`;

export const TitleText = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: ${Color.yellow};
  text-align: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    text-align: left;
  }
`;

const textStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: ${Color.realBlack};
  text-align: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    text-align: left;
  }
`;

export const DescriptionText = styled.p`
  ${textStyles};
`;

export const Button = styled.button`
  ${textStyles};
  color: ${Color.blueSeconde};
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
`;
