import React, { FC, InputHTMLAttributes } from 'react';
import { CustomField, InputLabel, InputWrapper, HelperMessage } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  touched?: boolean;
  error?: any;
}

const InputField: FC<IProps> = ({ className, label, touched, error, ...props }) => {
  return (
    <InputWrapper className={className}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <CustomField {...props} error={error} />
      {touched && error && <HelperMessage>{error}</HelperMessage>}
    </InputWrapper>
  );
};

export { InputField };
