import React, { FC } from 'react';
import { PageNumber } from '../styles';

export interface IResponsePagination {
  totalItens: number;
  maxPages: number;
  page: number;
}

interface IProps {
  setPage: (e: number) => void;
  pagination: IResponsePagination;
}

export const PaginationMiddle: FC<IProps> = ({ setPage, pagination }: IProps) => {
  const { page } = pagination;

  const previusPage = page - 1;
  const nextPage = page + 1;

  return (
    <>
      <PageNumber
        key={1}
        isActive={pagination?.page === 1}
        type="button"
        onClick={() => {
          if (pagination?.page) {
            setPage(1);
          }
        }}
      >
        {1}
      </PageNumber>
      ...
      <PageNumber
        key={previusPage}
        isActive={pagination?.page === previusPage}
        type="button"
        onClick={() => {
          if (pagination?.page === previusPage) return;

          if (pagination?.page) {
            setPage(previusPage);
          }
        }}
      >
        {previusPage}
      </PageNumber>
      <PageNumber
        key={page}
        isActive={pagination?.page === page}
        type="button"
        onClick={() => {
          if (pagination?.page === page) return;

          if (pagination?.page) {
            setPage(page);
          }
        }}
      >
        {page}
      </PageNumber>
      <PageNumber
        key={nextPage}
        isActive={pagination?.page === nextPage}
        type="button"
        onClick={() => {
          if (pagination?.page === nextPage) return;

          if (pagination?.page) {
            setPage(nextPage);
          }
        }}
      >
        {nextPage}
      </PageNumber>
      ...
      <PageNumber
        key={pagination?.maxPages}
        isActive={pagination?.maxPages === pagination?.page}
        type="button"
        onClick={() => {
          if (pagination?.maxPages === pagination?.page) return;

          if (pagination?.page) {
            setPage(pagination?.maxPages);
          }
        }}
      >
        {pagination?.maxPages}
      </PageNumber>
    </>
  );
};
