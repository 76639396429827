import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import EmptyMessage from '~/components/EmptyMessage';
import SkeletonCustom from '~/components/Skeleton';

import { Breakpoints } from '~/styles';
import Chart from '~/components/ChartV3';
import { DigitalClientInfo } from './DigitalClientInfo';
import HistorySubtitleEllipseIconSVG from '~/assets/Icons/HistorySubtitleEllipse';
import HistorySubtitleLineBoldIconSVG from '~/assets/Icons/HistorySubtitleLineBold';
import HistorySubtitleLineIconSVG from '~/assets/Icons/HistorySubtitleLine';
import {
  ConfigureAlertButton,
  Container,
  ContainerConfigureAlert,
  ContainerEmpty,
  ContainerHistoryConsumer,
  HistoryConsumer,
  RowChart,
  SubtitleHistory,
  TextIot,
} from './styles';
import { ConfigureAlertButtonIcon } from './ConfigureAlert/icons';
import { Dialog } from '~/components/Dialog';
import { ModalAlert } from './ModalAlert';
import { ConfigureAlert } from './ConfigureAlert';
import configsAppJsonImport from '~/configs/app.config';
import { checkToggle } from '~/utils/toggles';

const configsAppJson = configsAppJsonImport as any;

const ConsumptionHistorySession = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isDesktopLarge = useMediaQuery({ minWidth: Breakpoints.desktopLarge.max });

  const loading = useSelector(
    (item: IState) => item.enrollmentsConsumptionHistory.loading,
  );
  const consumptionData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data,
  );

  const average = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.average,
  );
  const averageIot = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.averageIot,
  );
  const totalHistoryData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.totalHistoryData,
  );
  const totalHistoryIotData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.totalHistoryIotData,
  );
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  const dataSubscribe = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.data,
  );
  const isIotClient = eletronic_exists;

  const [open, setOpen] = useState<boolean>(false);
  const [existCreateAlert, setExistCreateAlert] = useState<boolean>(false);
  const [existIotView, setExistIotView] = useState<boolean>(false);
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);

  const verifyToggles = async () => {
    setExistCreateAlert(await checkToggle(toggles, 'MVP_CREATE_ALERT'));
    setExistIotView(await checkToggle(toggles, 'MVP_MESSAGE_SMART_METER'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  return (
    <Container>
      <Dialog open={open} onClose={setOpen}>
        <ModalAlert onClose={setOpen} />
      </Dialog>
      {(configsAppJson?.NODE_ENV !== 'PRD' ||
        (eletronic_exists && existIotView)) && <DigitalClientInfo />}
      <ContainerHistoryConsumer>
        <HistoryConsumer
          eletronic_exists={
            isIotClient && existCreateAlert && dataSubscribe && dataSubscribe[0]
          }
        >
          <RowChart>
            {loading ? (
              <div style={{ width: '100%' }}>
                <SkeletonCustom height={isMobile ? 155 : 260} />
              </div>
            ) : consumptionData && consumptionData?.historyData?.length > 0 ? (
              <>
                {isIotClient && <TextIot>Últimos 30 dias</TextIot>}
                <Chart
                  dataChart={
                    isIotClient
                      ? consumptionData?.iotData
                      : consumptionData?.historyData
                  }
                  average={isIotClient ? averageIot : average}
                />
                <SubtitleHistory>
                  <div id="legend">
                    <p>
                      <div>
                        <HistorySubtitleEllipseIconSVG />
                      </div>
                      Consumo real
                    </p>
                    <p>
                      <div>
                        <HistorySubtitleLineBoldIconSVG />
                      </div>
                      Média do período
                    </p>
                    <p>
                      <div>
                        <HistorySubtitleLineIconSVG />
                      </div>
                      Alerta de consumo
                    </p>
                  </div>
                  <div id="total">
                    <p>
                      Valor total consumido no período:{' '}
                      {eletronic_exists ? totalHistoryIotData : totalHistoryData}
                    </p>
                  </div>
                </SubtitleHistory>
              </>
            ) : (
              <ContainerEmpty>
                <Margin height={150} />
                <EmptyMessage>
                  Você ainda não possui <br /> histórico detalhado!
                </EmptyMessage>
                <Margin height={60} />
              </ContainerEmpty>
            )}
          </RowChart>
        </HistoryConsumer>
        {isIotClient &&
          existCreateAlert &&
          dataSubscribe &&
          dataSubscribe[0] &&
          (isDesktopLarge ? (
            <ContainerConfigureAlert>
              <ConfigureAlert />
            </ContainerConfigureAlert>
          ) : (
            <ConfigureAlertButton type="button" onClick={() => setOpen(true)}>
              <ConfigureAlertButtonIcon />
              Configurar alerta de consumo
            </ConfigureAlertButton>
          ))}
      </ContainerHistoryConsumer>
    </Container>
  );
};

export default ConsumptionHistorySession;
