/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import Input from '../Input';
import { Margin } from '~/components/Margin';

import { Button, Container, ContainerButton } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setValuesObject } from '~/store/modules/dpadpeDataMenager/actions';
import { IState } from '~/store/modules/types';
import { IFieldObject } from '~/store/modules/dpadpeDataMenager/types';
import { IPropsForm } from '../typesForm';
import FieldInput from '../FieldInput';
import { Color } from '~/styles';
import PlusSVG from '~/assets/Icons/Plus';

interface IProps extends IPropsForm {
  fields: IFieldObject[];
  background?: string;
  inputs?: IFieldObject[];
  setInputs: (e: IFieldObject[]) => void;
  onValidate: () => void;
}

const CharactersInput: React.FC<IProps> = ({ fields, onValidate }) => {
  const [inputsTipage, setInputsTipage] = useState<IFieldObject[][]>([]);
  const [inputsTipageDetails, setInputsTipageDetails] = useState<Array<any>>([]);

  const dispatch = useDispatch();

  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);

  const [indexField, setIndexField] = useState(0);

  const addField = () => {
    const resetField = fields.map((field) => {
      return {
        ...field,
        key: `${field.key}${indexField}`,
        value: '',
      };
    });

    setIndexField((prevState) => prevState + 1);

    setInputsTipage((prevState) => [...prevState, resetField]);
  };

  useEffect(() => {
    setInputsTipage([fields]);
  }, [fields]);

  const labelConvert = (characterField: any) => {
    switch (characterField) {
      case 'built_area':
        return 'Área Edificada (m²)';

      case 'business_structure_enterprise':
        return 'Natureza do Empreendimento';

      case 'dependecies':
        return 'Dependências';

      case 'industrial_process_predicted_consume':
        return 'Consumo Previsto para Processo Industrial';

      case 'industrial_process_predicted_discharge':
        return 'Descarga Prevista para Processo Industrial';

      case 'lot_area':
        return 'Área do Lote (m²)';

      case 'lots_quantity':
        return 'Número de Lotes';

      case 'number_commercial_rooms':
        return 'Número de salas';

      case 'number_consumers_day':
        return 'Número de Consumidores por Dia';

      case 'number_employees_day':
        return 'Número de Funcionários por Dia';

      case 'number_rooms':
        return 'Número de Quartos';

      case 'number_rooms_unity':
        return 'Número de Quartos ou Unidade';

      case 'number_stores':
        return 'Número de lojas';

      case 'number_unities_by_building':
        return 'Número de Unidade por Prédio';

      case 'number_unities_by_lot':
        return 'Número de Unidade por Lote';

      case 'quantity':
        return 'Quantidade';

      case 'quantity_apartments':
        return 'Quantidade de apartamento ou Unidade';

      case 'quantity_apartments_unity':
        return 'Quantidade de apartamento ou Unidade';

      case 'quantity_edifications':
        return 'Quantidade de Edificações';

      case 'type':
        return 'Tipo';

      case 'population_number_per_lot':
        return 'Número de população por lote';

      case 'number_of_types':
        return 'Número de tipos';

      case 'population_number_per_apartment':
        return 'Número de população por apartamento';

      case 'apartments_number':
        return 'Quantidade de apartamento';

      case 'social_area_quantity':
        return 'Quantidade de área social';

      case 'trade_type':
        return 'Tipo de comércio';

      case 'business_structure':
        return 'Natureza';

      default:
        return 'name';
    }
  };

  const createFields = () => {
    let fieldsDetails: any = [];

    caseDetail?.characteristics?.map((characteristic: any) => {
      const characteristicArray = Object.entries(characteristic);
      console.log('characteristicArray', characteristicArray);
      const objectField = characteristicArray
        ?.filter((characteristicArrayItem: any) => !!characteristicArrayItem[1])
        .map((characteristicArrayItem: any) => ({
          key: characteristicArrayItem[0],
          label: labelConvert(characteristicArrayItem[0]),
          value: characteristicArrayItem[1],
        }));

      return fieldsDetails.push(objectField);
    });

    console.log('fieldsDetails', fieldsDetails);

    fieldsDetails.map((fieldsDetailItem: any) => {
      Object.keys(fieldsDetailItem).forEach((key) => {
        return fieldsDetailItem[key] === undefined && delete fieldsDetailItem[key];
      });

      return fieldsDetailItem;
    });

    setInputsTipageDetails([...fieldsDetails]);
  };

  useEffect(() => {
    caseDetail?.selectedCase && createFields();
  }, []);

  return (
    <>
      {inputsTipage && !caseDetail?.selectedCase && (
        <div>
          {inputsTipage.map((inputsMap, index) => {
            return (
              <>
                <Container key={index.toString()}>
                  {inputsMap.map((item, indexInput) => (
                    <div
                      key={indexInput.toString()}
                      style={{
                        width: '48%',
                        marginTop: indexInput > 1 ? '24px' : '0px',
                      }}
                    >
                      <FieldInput
                        value={item.value}
                        selectForm={item.type === 'select' || false}
                        values={item.type === 'select' ? item.values : []}
                        onChange={(t) => {
                          onValidate();
                          const send = JSON.parse(JSON.stringify(inputsTipage));

                          send[index][indexInput].value = t;
                          setInputsTipage(JSON.parse(JSON.stringify(send)));
                          dispatch(
                            setValuesObject(JSON.parse(JSON.stringify(send))),
                          );
                        }}
                        label={`${item.label}*`}
                        name={item.key}
                        disabled={!!caseDetail?.selectedCase}
                        maxLength={item?.maxLength ? item?.maxLength : undefined}
                        character
                      />
                    </div>
                  ))}
                  {/* {index > 0 && inputsTipage.length < 30 && <Margin width={15} />} */}
                </Container>
                <ContainerButton>
                  {index > 0 && (
                    <Button
                      style={{
                        background: Color.red,
                        width: '30%',
                        fontSize: '12px',
                        color: Color.white,
                        marginRight: 'auto',
                      }}
                      border={Color.white}
                      onClick={() => {
                        const filter = inputsTipage.filter(
                          (value, filetrIndex) => index !== filetrIndex,
                        );

                        setInputsTipage(filter);
                      }}
                    >
                      - Excluir campo
                    </Button>
                  )}

                  {inputsTipage.length < 30 && (
                    <Button
                      style={{
                        background: Color.blueSeconde,
                        width: '30%',
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      border={Color.white}
                      onClick={() => addField()}
                      disabled={!!caseDetail?.selectedCase}
                    >
                      <PlusSVG />
                      <Margin width={12} />
                      Adicionar campo
                    </Button>
                  )}
                </ContainerButton>
              </>
            );
          })}
        </div>
      )}
      {inputsTipageDetails && caseDetail?.selectedCase && (
        <div>
          {inputsTipageDetails.map((inputsMap, index) => {
            console.log('inputsMap', inputsMap);
            return (
              <>
                <Container key={index.toString()}>
                  {inputsMap.map((item: any, indexInput: number) => (
                    <>
                      {item.key !== 'name' && item.key !== 'characteristic_type' && (
                        <>
                          <div key={indexInput.toString()} style={{ width: '48%' }}>
                            <Input
                              value={item.value}
                              label={item.label}
                              name={item.key}
                              disabled={!!caseDetail?.selectedCase}
                              character
                              onChangeValue={() => undefined}
                              index={indexInput}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </Container>

                <ContainerButton>
                  {inputsTipage.length < 30 && (
                    <Button
                      style={{
                        background: Color.blueSeconde,
                        width: '30%',
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      border={Color.white}
                      onClick={() => addField()}
                      disabled={!!caseDetail?.selectedCase}
                    >
                      <PlusSVG />
                      <Margin width={12} />
                      Adicionar campo
                    </Button>
                  )}
                </ContainerButton>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CharactersInput;
