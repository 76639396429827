/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Data, hasTankerTruckInputs } from '..';

import {
  Container,
  ContainerAlert,
  ContainerInputsBox,
  ContainerInputs,
  BoxInput,
  BoxInputsTankerTruck,
  DescriptionInputsTankerTruck,
  TermErrorText,
} from './styles';
import { Border, ContainerTerms, DescriptionText } from '../styles';

import InfoBox from '~/components/InfoBox';
import { InputSelect } from '~/components/InputSelect';
import { InputTextArea } from '~/components/InputTextArea';
import { IState } from '~/store/modules/types';
import { IResponseInputsLackWater } from '~/store/modules/orderServices/types';
import { Input } from '~/components/Input';
import formatter from '~/utils/formatter';
import InputCheckedV3 from '~/common/InputCheckedV3';
import { Color } from '~/styles';
import WarningBox from '~/components/WarningBox';

interface IProps {
  data: any;
  setData: (type: any) => void;
  setOpenTerm: Dispatch<SetStateAction<boolean>>;
  inputsTankerTruck: Array<IResponseInputsLackWater>;
  inputsTankerTruckRJ: Array<IResponseInputsLackWater>;
}

const ReportLackWaterStepThree: FC<IProps> = ({
  data,
  setData,
  setOpenTerm,
  inputsTankerTruck,
  inputsTankerTruckRJ,
}) => {
  const dataInputs = useSelector((state: IState) => state.orderServices.dataInputs);

  const inputsRequired = dataInputs?.filter(
    (inputs) => inputs.nameForm === 'required',
  );

  const handleInputChange = (name: string, value: any) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const renderInputField = (input: IResponseInputsLackWater) => {
    const supply_tanker_truck_hidden_inputs =
      hasTankerTruckInputs(input) &&
      data?.supply_tanker_truck?.toUpperCase() !== 'SIM';

    if (supply_tanker_truck_hidden_inputs && input?.name !== 'supply_tanker_truck') {
      return null;
    }

    switch (input?.type) {
      case 'select':
        return (
          <BoxInput key={input?.id}>
            <InputSelect
              values={[
                { label: input?.initialLabel || '', value: '' },
                ...input?.options?.map((option: any) => ({
                  label: option?.label,
                  value: option?.label,
                })),
              ]}
              label={input?.label + (input?.required ? '*' : '')}
              name={input?.name}
              value={data?.[input?.name]}
              onChange={(ev) => handleInputChange(input?.name, ev.target.value)}
              error={data?.errorMessages ? data?.errorMessages[input?.name] : false}
              errorText={data?.errorMessages ? data?.errorMessages[input?.name] : ''}
              errorPosition="bottom"
            />
          </BoxInput>
        );
      case 'textarea':
        return (
          <InputTextArea
            key={input?.id}
            style={{ resize: 'none' }}
            label={input?.label + (input?.required ? '*' : '')}
            name={input?.name}
            maxLength={input?.maxLength || 500}
            value={data?.[input?.name]}
            onChange={(ev) => handleInputChange(input?.name, ev.target.value)}
            error={data?.errorMessages ? data?.errorMessages[input?.name] : false}
            errorText={data?.errorMessages ? data?.errorMessages[input?.name] : ''}
            errorPosition="bottom"
          />
        );
      case 'input':
        return (
          <BoxInput key={input?.id}>
            <Input
              key={input?.id}
              label={input?.label + (input?.required ? '*' : '')}
              name={input?.name}
              maxLength={input?.maxLength || 500}
              value={data?.[input?.name]}
              onChange={(ev) =>
                handleInputChange(
                  input?.name,
                  input?.allowedCharacters === 'numbers'
                    ? formatter.formatarNumber(ev.target.value)
                    : ev.target.value,
                )
              }
              error={data?.errorMessages ? data?.errorMessages[input?.name] : false}
              errorText={data?.errorMessages ? data?.errorMessages[input?.name] : ''}
              errorPosition="bottom"
            />
          </BoxInput>
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      <DescriptionText>A seguir, descreva brevemente o problema:</DescriptionText>
      <ContainerAlert>
        <InfoBox
          title="Verifique o registro de cavalete"
          label="Antes de seguir com a solicitação, verifique se o registro de cavalete está aberto e teste novamente o seu abastecimento de água."
        />
      </ContainerAlert>
      <ContainerInputsBox>
        <ContainerInputs>
          {inputsRequired?.map((input) => renderInputField(input))}
        </ContainerInputs>
        {!!inputsTankerTruck.length && (
          <BoxInputsTankerTruck>
            <DescriptionInputsTankerTruck>
              Possibilidade de abastecimento por caminhão pipa
            </DescriptionInputsTankerTruck>
            <ContainerInputs>
              {inputsTankerTruck?.map((input) => renderInputField(input))}
            </ContainerInputs>
          </BoxInputsTankerTruck>
        )}
        {!!inputsTankerTruckRJ.length && (
          <>
            <BoxInputsTankerTruck>
              <DescriptionInputsTankerTruck>
                Possibilidade de abastecimento por caminhão pipa
              </DescriptionInputsTankerTruck>
              {data?.supply_tanker_truck?.toUpperCase() === 'SIM' && (
                <WarningBox label="Caso seja necessário abastecimento por caminhão pipa, o volume será calculado conforme tabela tarifária vigente, obedecendo às faixas tarifárias previamente determinadas pela Agência Reguladora." />
              )}
              <ContainerInputs>
                {inputsTankerTruckRJ?.map((input) => renderInputField(input))}
              </ContainerInputs>
            </BoxInputsTankerTruck>
            {data?.supply_tanker_truck?.toUpperCase() === 'SIM' && (
              <>
                <Border />
                <TermErrorText
                  color={data?.errorMessages?.term ? Color.red : Color.gray}
                >
                  Para finalizar a solicitação, leia com atenção os termos a seguir:
                </TermErrorText>
                <ContainerTerms>
                  <InputCheckedV3
                    checked={data?.accept_term}
                    onChange={(e) =>
                      handleInputChange('accept_term', e.target.checked)
                    }
                  >
                    <p className="term">
                      Li e aceito o{' '}
                      <button type="button" onClick={() => setOpenTerm(true)}>
                        termo de aceite
                      </button>
                    </p>
                  </InputCheckedV3>
                </ContainerTerms>
              </>
            )}
          </>
        )}
      </ContainerInputsBox>
    </Container>
  );
};

export default ReportLackWaterStepThree;
