import styled from 'styled-components';

export const Container = styled.div<{
  clickabled: boolean;
  notClientLogged?: boolean;
}>`
  position: relative;
  width: 270px;
  height: 540px;
  position: absolute;
  right: 0;
  border-radius: 24px;
  display: none;

  ${({ clickabled }) => clickabled && 'cursor: pointer;'}

  img {
    width: 270px;
    height: 540px;
    border-radius: 24px;
  }

  a {
    position: absolute;
    img {
      width: 80px;
      height: 30px;
      border-radius: 5px;
    }

    :first-of-type {
      bottom: 20px;
      ${({ notClientLogged }) => notClientLogged && 'bottom: 50px;'}
      right: 120px;
    }

    :last-of-type {
      bottom: 20px;
      ${({ notClientLogged }) => notClientLogged && 'bottom: 50px;'}
      right: 20px;
    }
  }

  @media (min-width: 1340px) {
    display: inherit;
  }
`;
