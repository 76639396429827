import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingComponent from '~/components/Loading';
import { getEnrollmentsNegativeCertificatesRequest } from '~/store/modules/enrollmentsNegativeCertificates/actions';
import { IState } from '~/store/modules/types';

import * as S from './styles';
import Table from './Table';

const NegativeCertificatePage: React.FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (item: IState) => item.enrollmentsNegativeCertificates.loading,
  );

  useEffect(() => {
    dispatch(getEnrollmentsNegativeCertificatesRequest());
  }, [dispatch]);

  return (
    <S.Content>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Table />
    </S.Content>
  );
};

export default NegativeCertificatePage;
