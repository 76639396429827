import React from 'react';

function WalletEloDetailSVG() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8365 0 32 7.1635 32 16C32 24.8366 24.8365 32 16 32C7.1635 32 0 24.8366 0 16C0 7.1635 7.1635 0 16 0Z"
        fill="#0E0E11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2521 14.849L6.34435 16.964C6.33665 16.8786 6.33273 16.7929 6.3326 16.7072C6.3326 15.1547 7.59098 13.8963 9.14335 13.8963C9.98335 13.8963 10.7371 14.265 11.2521 14.849ZM9.14335 11.7942C11.4592 11.7942 13.4005 13.3967 13.9196 15.5532L11.9396 16.419L11.9391 16.4148L9.91322 17.3048L5.0536 19.4298C4.51549 18.6239 4.22899 17.6763 4.23047 16.7072C4.23047 13.9938 6.42997 11.7942 9.14335 11.7942ZM12.5764 20.2217C10.8173 21.8912 8.4901 22.0551 6.44535 20.8137L7.60073 19.0571C8.76423 19.7526 9.93323 19.6398 11.1075 18.7178L12.5764 20.2216V20.2217ZM14.5769 19.0055L14.5644 9.71484H16.3246V18.7547C16.3246 18.8413 16.3354 18.9177 16.4491 18.962L17.9804 19.5575L17.2905 21.3512L15.4967 20.5923C14.8166 20.3045 14.578 19.8875 14.5767 19.0055H14.5769Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2046 18.8476C20.5448 18.3344 20.12 17.5338 20.12 16.6333C20.12 15.8328 20.456 15.1112 20.9941 14.6003L19.8572 12.7578C18.7143 13.6632 17.9805 15.0622 17.9805 16.6333C17.9805 18.3269 18.8325 19.8209 20.1308 20.7118L21.2046 18.8476Z"
        fill="#2091C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9759 13.9958C22.2802 13.8864 22.6012 13.8305 22.9247 13.8308C24.125 13.8308 25.1488 14.5857 25.5487 15.6463L27.7272 15.4643C27.2013 13.2993 25.2513 11.6914 22.9247 11.6914C22.2876 11.691 21.6566 11.8141 21.0664 12.0539L21.9759 13.9958Z"
        fill="#FAEC32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7246 16.7695C25.6536 18.255 24.4271 19.4377 22.9241 19.4377C22.6885 19.4377 22.4598 19.408 22.2413 19.3534L21.3867 21.332C21.883 21.4944 22.402 21.5771 22.9242 21.577C25.5861 21.577 27.7555 19.4727 27.8622 16.8369L25.7246 16.7695Z"
        fill="#D0352A"
      />
    </svg>
  );
}
export default WalletEloDetailSVG;
