import { api } from './api';

const url = 'v3/ownerships';

function getOwnershipOrdersService() {
  return new Promise((resolve, reject) => {
    try {
      const getOwnershipOrders = async () => {
        const response = await api.get(`${url}/me`);
        return response.data;
      };
      resolve(getOwnershipOrders());
    } catch (error) {
      reject(error);
    }
  });
}

function getOwnershipOperationsService() {
  return new Promise((resolve, reject) => {
    try {
      // const { enrollment, operation } = data;
      const getOwnershipOperations = async () => {
        const response = await api.get(`${url}/cities/`);
        return response.data;
      };
      // setTimeout(() => resolve(citiesList), 2000);
      resolve(getOwnershipOperations());
    } catch (error) {
      reject(error);
    }
  });
}

function getOwnershipValidateEnrollmentService(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const { enrollment, operation } = data;
      const getOwnershipEnrollmentValidation = async () => {
        const response = await api.get(
          `${url}/validate/enrollment/${enrollment}/operation/${operation}`,
        );
        return response.data;
      };
      resolve(getOwnershipEnrollmentValidation());
    } catch (error) {
      reject(error);
    }
  });
}

function postOwnershipOrderFilesService(data: any[]) {
  return new Promise((resolve, reject) => {
    try {
      const postOwnershipOrderFiles = async () => {
        const formData = new FormData();
        data.map((item) => formData.append(item.name, item.file));

        const response = await api.post(`${url}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      };
      resolve(postOwnershipOrderFiles());
    } catch (error) {
      reject(error);
    }
  });
}

function postOwnershipOrderService(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const postOwnershipOrder = async () => {
        const response = await api.post(`${url}/insert/change/ownership/`, data, {
          timeout: 360000,
        });
        return response.data;
      };
      resolve(postOwnershipOrder());
    } catch (error) {
      reject(error);
    }
  });
}

function deleteOwnershipOrderService(id: string) {
  return new Promise((resolve, reject) => {
    try {
      const deleteOwnershipOrder = async () => {
        const response = await api.delete(`${url}/${id}`);
        return response.data;
      };
      resolve(deleteOwnershipOrder());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getOwnershipOrdersService,
  getOwnershipOperationsService,
  getOwnershipValidateEnrollmentService,
  postOwnershipOrderFilesService,
  postOwnershipOrderService,
  deleteOwnershipOrderService,
};
