import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';
import { COLORS } from '~/utils/constants';

export const Main = styled.main`
  padding: 56px 24px;

  @media (max-width: ${Breakpoints.mobile.max}px) {
    padding-inline: 0px;
  }
`;

export const Wrapper = styled.main`
  position: relative;
  padding: 64px 24px;
  margin: 0px auto;
  max-width: 880px;
  display: grid;
  grid-template-columns: 1.5fr auto 1fr;
  gap: 24px;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const Divider = styled.div`
  width: 1px;
  background: ${Color.grayLight};
  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 100%;
    height: 1px;
  }
`;

export const OrderWrapper = styled.div`
  justify-self: center;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`;

export const OrderStatus = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  color: ${(props) => Color[props.color as keyof typeof Color]};
`;

export const OrderStatusBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

export const MiniBar = styled.div<{ bg: string }>`
  height: 8px;
  background: ${(props) => Color[props.bg as keyof typeof Color]};
  border-radius: 8px;
`;

export const OrderTitle = styled.div`
  font-size: 19px;
  font-weight: 500;
`;

export const OrderDate = styled.div`
  display: flex;
  gap: 48px;
`;

export const DateBox = styled.div`
  text-align: center;
  p {
    font-size: 14px;
    margin-bottom: 4px;
  }
  div {
    font-weight: 500;
  }
`;

export const OrderBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const OrderSlide = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.grayLight};
`;

export const OrderDescription = styled.div`
  color: ${Color.gray};
  font-size: 14px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  font-size: 15px;
  .btn-close {
    align-self: end;
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  text-decoration: underline;
  font-size: 14px;
  color: ${Color.gray};
  &:hover {
    cursor: pointer;
    color: var(--800);
  }
  transition: ease color 140ms;
`;

export const StatusMessage = styled.div`
  padding: 36px 24px;
  background: ${COLORS.gray200};
  border-radius: 4px;
  text-align: center;
`;

export const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  div {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const StatusOpt = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CopyButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: none;
  font-size: 19px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  transition: ease opacity 160ms;
`;

export const SuccessStyle = styled.p`
  color: ${Color.blueSeconde};
  font-weight: 400;
`;

export const ModalNav = styled.div`
  display: flex;
  gap: 12px;
`;
