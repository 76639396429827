import React from 'react';

const ArrowIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.5L13 6.5M13 6.5L8 11.5M13 6.5H1"
        stroke="#212529"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DiscountIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1982_117591)">
        <path
          d="M11.21 21.5L21 11.71V13.71C21 14.24 20.79 14.75 20.41 15.12L12.62 22.91C11.84 23.69 10.57 23.69 9.79 22.91L3.58 16.7C2.8 15.92 2.8 14.65 3.58 13.87L11.21 21.5Z"
          fill="#37B482"
        />
        <path
          d="M12.62 17.91C11.84 18.69 10.57 18.69 9.79 17.91L3.58 11.7C2.8 10.92 2.8 9.65 3.58 8.87L11.37 1.08C11.7499 0.709302 12.2592 0.501245 12.79 0.5L19 0.5C20.1 0.5 21 1.4 21 2.5V8.71C21 9.24 20.79 9.75 20.41 10.12L12.62 17.91ZM16.75 3.5C16.4185 3.5 16.1005 3.6317 15.8661 3.86612C15.6317 4.10054 15.5 4.41848 15.5 4.75C15.5 5.08152 15.6317 5.39946 15.8661 5.63388C16.1005 5.8683 16.4185 6 16.75 6C17.0815 6 17.3995 5.8683 17.6339 5.63388C17.8683 5.39946 18 5.08152 18 4.75C18 4.41848 17.8683 4.10054 17.6339 3.86612C17.3995 3.6317 17.0815 3.5 16.75 3.5Z"
          fill="#37B482"
        />
      </g>
      <defs>
        <clipPath id="clip0_1982_117591">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ArrowIcon, DiscountIcon };
