import React, { FC, SVGAttributes } from 'react';

const LogoHeaderSVG: FC<SVGAttributes<SVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="56"
      height="43"
      viewBox="0 0 56 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.799 23.0398C41.3262 22.617 42.1606 22.8636 42.5119 23.0398C43.5742 23.4627 43.3243 24.0686 43.0665 24.3188L40.3846 26.2927C40.2433 26.3612 39.7615 26.493 38.9654 26.4721C38.1693 26.4512 38.2639 25.8609 38.4107 25.5684C38.9871 24.9017 40.2718 23.4627 40.799 23.0398Z"
        fill="white"
      />
      <path
        d="M32.737 29.1867L30.4727 37.787C29.9605 39.327 28.9164 39.3563 28.6032 39.3563C28.29 39.3563 27.2655 39.2584 27.1644 38.0023C25.5252 39.6832 23.6473 39.5401 22.9132 39.2584C20.8094 38.7938 20.5151 36.6451 20.7631 35.141C21.2536 33.1279 22.2711 29.0294 22.4172 28.7397C22.5999 28.3775 23.1448 27.4803 24.4336 27.5814C25.4645 27.6623 25.6027 28.8049 25.5429 29.3661C25.1568 30.7288 24.3481 33.6095 24.2019 34.2307C24.0192 35.0072 24.0029 36.2829 25.5429 36.4624C26.7748 36.6059 27.6766 35.4542 27.9735 34.8604C28.4869 32.9691 29.5461 29.0973 29.6766 28.7397C29.8398 28.2927 30.3226 27.5488 31.5624 27.5814C32.5543 27.6075 32.7587 28.6625 32.737 29.1867Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2786 32.7168C39.4526 32.1491 39.6023 30.9361 38.8088 30.6255C37.8169 30.2372 36.6098 30.2242 35.3732 30.6255C34.384 30.9465 33.7713 30.2611 33.5885 29.8783C33.4254 29.4139 33.3869 28.4043 34.538 28.0806C35.9768 27.676 37.6766 27.415 39.9181 27.7478C42.1595 28.0806 42.5935 30.11 42.5935 30.7397C42.5935 31.669 42.1415 33.4971 41.7101 35.2418C41.5163 36.0258 41.3266 36.793 41.184 37.4542C41.0459 38.0882 40.4714 39.3563 39.2786 39.3563C37.7876 39.3563 37.7908 38.2177 37.8333 38.0023C37.8415 37.9603 37.7915 38.0051 37.6848 38.1009C37.2453 38.4952 35.8436 39.7527 33.5885 39.3563C31.3465 38.9622 31.0361 36.9365 31.1611 35.973C31.2427 34.9561 31.9579 32.8193 34.166 32.4069C36.3742 31.9945 38.4945 32.4417 39.2786 32.7168ZM38.799 34.5731C38.6022 35.3213 37.828 36.8876 36.3063 37.1669C34.7846 37.4462 34.364 36.5089 34.3488 36.0283C34.3488 35.0886 34.9465 34.3362 36.0584 34.1979C37.1083 34.0673 38.1409 34.3763 38.7042 34.5448C38.7375 34.5548 38.7691 34.5642 38.799 34.5731Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5338 29.3532L18.9987 38.7953C18.7029 39.7491 17.5716 41.8002 15.4131 42.3744C12.7148 43.0922 9.61207 42.3516 9.17161 42.1852C9.12198 42.1664 9.05114 42.1442 8.9653 42.1172C8.28932 41.9047 6.68308 41.3997 7.17813 40.0188C7.62446 38.7737 8.88885 39.0367 9.46525 39.3238C10.2766 39.7262 12.2829 40.4286 13.8176 40.0188C15.7361 39.5065 16.2711 37.1313 16.3462 36.7888C16.2551 36.8501 16.1499 36.9324 16.029 37.027C15.2875 37.6069 13.9578 38.6468 11.7165 38.1167C9.10962 37.5 7.64143 35.7545 9.03458 31.5392C9.90152 28.916 13.648 26.5701 16.4734 27.7284C16.9508 27.9742 17.9736 28.6537 18.245 29.4054C18.3761 29.1537 18.4768 28.9298 18.5655 28.7324C18.9184 27.9477 19.0822 27.5834 20.2189 27.562C21.4326 27.5391 21.6219 28.6387 21.5338 29.3532ZM11.8729 33.6796C11.8947 32.7835 12.4002 30.8646 14.2481 30.3582C16.5581 29.7253 17.4292 31.3696 17.4716 32.0678C17.514 32.7661 17.0997 34.8346 15.0638 35.4675C13.0279 36.1005 11.9284 35.0825 11.8729 33.6796Z"
        fill="white"
      />
      <path
        d="M40.726 4.29594C41.6592 4.24482 43.9822 4.59938 45.8093 6.42647C48.054 7.13779 49.0327 5.48689 49.0327 3.95018C49.0327 3.14431 47.8915 1.64284 45.6363 0.84414C44.0257 0.281877 40.0252 -0.539876 36.9087 0.671219C33.0131 2.18509 30.8435 4.62555 29.31 7.57825C27.7766 10.5309 28.0278 14.9812 28.9283 16.8246C29.8288 18.668 32.129 22.0448 37.7897 21.9861C42.3182 21.9391 44.4226 20.5984 44.9088 19.9339C45.1078 19.7229 45.59 18.9401 45.9267 17.4967C46.2634 16.0533 47.0262 13.4173 47.3655 12.2797C47.5646 11.3836 47.5189 9.41838 45.7929 9.41838C45.2101 9.41838 44.5146 9.41237 43.7976 9.40617C42.2949 9.39317 40.6977 9.37936 39.8451 9.41838C39.2622 9.57172 38.1388 10.2432 38.3084 11.7022C38.4781 13.1613 39.632 13.4869 40.1877 13.4673H42.7032L41.7636 16.9225C40.1638 17.6522 36.5578 18.4101 34.2595 16.2895C31.3068 13.5652 32.9185 4.64169 40.726 4.29594Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.45192 2.0709L0.0212401 19.2748C-0.0407502 19.7838 -0.00355601 20.8892 0.641143 21.239C1.34243 21.6194 1.7151 21.6243 1.97848 21.6278C2.01773 21.6283 2.05464 21.6288 2.08976 21.6305C2.25508 21.6384 4.52668 21.633 6.59211 21.628H6.59349L6.60875 21.628C7.24395 21.6264 7.85905 21.625 8.38667 21.6239C10.7651 21.5554 15.081 20.6928 18.1453 17.3205C21.9756 13.1052 21.1534 7.16715 20.5792 5.8001C20.005 4.43305 18.6608 2.31233 16.7358 1.5554C14.8108 0.798464 14.0441 0.59618 12.3671 0.478724C11.0255 0.38476 7.78852 0.439573 6.33773 0.478724C5.89074 0.521139 4.88781 0.898953 4.45192 2.0709ZM8.2949 4.63877L4.95395 17.4512L9.10077 17.3207C10.5102 17.1793 13.7279 16.2453 15.3226 13.6404C16.9174 11.0355 16.4156 8.32665 15.9654 7.29782C15.6076 6.41147 14.199 4.63877 11.427 4.63877H8.2949Z"
        fill="white"
      />
      <path
        d="M54.4002 2.42676C52.9288 2.12531 51.95 2.91289 51.5291 3.32072C50.2534 4.77912 50.8472 6.85743 53.1376 6.79218C55.428 6.72692 55.7898 5.15161 55.9304 4.74324C56.1001 4.25058 56.0837 2.77165 54.4002 2.42676Z"
        fill="white"
      />
      <path
        d="M27.3922 2.42676C25.9207 2.12531 24.9419 2.91289 24.521 3.32072C23.2453 4.77912 23.8391 6.85743 26.1295 6.79218C28.4199 6.72692 28.7817 5.15161 28.9223 4.74324C29.092 4.25058 29.0757 2.77165 27.3922 2.42676Z"
        fill="white"
      />
      <path
        d="M49.8488 10.1331L46.5861 22.7531C46.4427 23.3077 46.5861 24.802 48.4491 24.9097C49.9395 24.9958 50.7319 23.5078 50.9418 22.7531C51.9684 18.8172 54.0335 10.7831 54.0804 10.1331C54.1392 9.32074 53.5878 8.2767 52.0804 8.24081C50.8746 8.2121 50.0902 9.4904 49.8488 10.1331Z"
        fill="white"
      />
      <path
        d="M22.8459 10.1331L19.5832 22.7531C19.4398 23.3077 19.5832 24.802 21.4462 24.9097C22.9366 24.9958 23.7289 23.5078 23.9388 22.7531C24.9655 18.8172 27.0305 10.7831 27.0775 10.1331C27.1362 9.32074 26.5849 8.2767 25.0775 8.24081C23.8716 8.2121 23.0873 9.4904 22.8459 10.1331Z"
        fill="white"
      />
      <path
        d="M4.08784 35.0882L6.60655 25.3435C6.79293 24.8471 7.39843 23.8601 8.32934 23.8822C9.49299 23.91 9.91865 24.7161 9.87331 25.3435C9.84841 25.6881 9.08889 28.7214 8.35877 31.5723C7.84493 33.6173 7.21616 36.0529 6.80409 37.633C6.64205 38.2158 6.03034 39.3648 4.8798 39.2983C3.44161 39.2152 3.33092 38.0613 3.44161 37.633L4.0633 35.2277C4.06888 35.1758 4.07737 35.1288 4.08784 35.0882Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoHeaderSVG;
