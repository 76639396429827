import React, { FC, ReactNode } from 'react';
import { Main, Container, Card, CardTitle } from './styles';

interface IProps {
  children: ReactNode;
  title: string;
}

const ContainerCard: FC<IProps> = ({ children, title }) => {
  return (
    <Main>
      <Container>
        <Card>
          <CardTitle>{title}</CardTitle>
          {children}
        </Card>
      </Container>
    </Main>
  );
};

export { ContainerCard };
