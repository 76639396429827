import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
  background: #f8f9fa;
  border: 1px solid ${Color.blueIntense};
  border-radius: 8px;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    display: flex;
  }
`;

export const ContainerDiscountIcon = styled.div`
  position: absolute;
  right: -7px;
  top: 50%;
  bottom: auto;
  margin: auto;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${Color.blueIntense};
`;

export const Description = styled.p`
  padding: 8px;
  background: #e9ecef;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.blueIntense};

  b {
    font-weight: 600;
  }
`;

export const ContainerDiscount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DiscountText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.blueIntense};
`;

export const DiscountNumberText = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${Color.blueIntense};
`;

export const AmountTotalDiscountText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.gray};

  b {
    font-weight: 600;
  }
`;

export const ButtonNegotiationPayment = styled.button<{ background: string }>`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: ${({ background }) => background};
  border-radius: 8px;
  cursor: pointer;
`;

export const TextButtonPayment = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.blueIntense};
`;
