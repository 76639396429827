import styled from 'styled-components';

export const Container = styled.div`
  width: 270px;
  height: 540px;
  position: absolute;
  top: 72px;
  right: 0;
  border-radius: 24px;
  display: none;

  @media (min-width: 1340px) {
    display: inherit;
  }
`;
