/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Margin } from '~/components/Margin';
import { Breakpoints, Color } from '~/styles';

import {
  Border,
  CardInvoice,
  ContainerInfo,
  ContainerSvg,
  RowInfo,
} from '../styles';

import ArrowRightSVG from '~/assets/Icons/arrowRight';
import SkeletonCustom from '~/components/Skeleton';

const SkeletonRegistrationsList: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <div>
      <CardInvoice>
        <Border color={Color.gray} />
        <Margin width={isMobile ? 7 : 9} />
        <ContainerInfo>
          <RowInfo>
            <p>Mat. &nbsp;</p>
            <label
              style={{
                color: Color.blueSeconde,
              }}
            >
              <SkeletonCustom width={80} height={10} />
            </label>
          </RowInfo>
          <Margin height={5} />
          <RowInfo>
            <label>End.: </label>
            <label>
              &nbsp; <SkeletonCustom width={80} height={10} />
            </label>
          </RowInfo>
          <Margin height={isMobile ? 3 : 4} />
          <RowInfo>
            <label
              style={{
                color: Color.gray,
                fontWeight: '400',
              }}
            >
              <SkeletonCustom width={80} height={10} />
            </label>
          </RowInfo>
        </ContainerInfo>
        <ContainerSvg>
          <ArrowRightSVG color={Color.gray} />
        </ContainerSvg>
      </CardInvoice>
      <Margin height={isMobile ? 5 : 7} />
    </div>
  );
};

export default SkeletonRegistrationsList;
