import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;

  input {
    height: 36px;
    border-radius: 8px;
  }
`;
