import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import configsAppJsonImport from '~/configs/app.config';

const configsAppJson = configsAppJsonImport as any;

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, thunk];

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  devTools: configsAppJson.NODE_ENV !== 'PRD',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { history, store, persistor };
