import React, { FC, ButtonHTMLAttributes } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';
import NextSVG from '~/assets/Icons/Next';

import { Color as ColorStyle } from '~/styles';

import { ButtonStyled } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  typeBtn?: 'content' | 'outlined' | 'canceled' | undefined;
  onClick: () => void;
  Color?: string;
  backgroundColor?: string;
  loading?: boolean;
  children?: React.ReactNode;
  style?: any;
  nextIcon?: boolean;
  backIcon?: boolean;
}

export const Button: FC<Props> = ({
  typeBtn = 'content',
  onClick,
  loading,
  Color,
  backgroundColor,
  children,
  style,
  nextIcon,
  backIcon,
  ...props
}) => {
  return (
    <ButtonStyled
      typeBtn={typeBtn ?? 'content'}
      color={Color}
      backgroundColor={backgroundColor}
      onClick={() => !loading && onClick()}
      type="button"
      style={style}
      {...props}
    >
      {backIcon && (
        <div>
          <NextSVG />
        </div>
      )}
      {loading ? (
        <ClipLoader color={ColorStyle.white} loading={loading} size={10} />
      ) : (
        children
      )}
      {nextIcon && <NextSVG />}
    </ButtonStyled>
  );
};
