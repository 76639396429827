import styled from 'styled-components';
import { Color } from '~/styles';

export const Main = styled.div`
  height: 50vh;
  margin-block: 80px;
  margin-inline: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${Color.gray};
  }
`;
