import React from 'react';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}
function EditSVG({ color = '#34BBDA', width = 12, height = 12 }: IProp) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06022 7.81987L0.399351 10.672C0.376553 10.7763 0.377334 10.8843 0.401638 10.9883C0.425942 11.0922 0.473154 11.1894 0.539825 11.2727C0.606496 11.356 0.690942 11.4234 0.786994 11.47C0.883047 11.5165 0.988278 11.541 1.095 11.5416C1.14473 11.5466 1.19484 11.5466 1.24457 11.5416L4.11413 10.8807L9.62369 5.39204C8.42292 4.19398 7.74969 3.52228 6.54891 2.32422L1.06022 7.81987Z"
        fill={color}
      />
      <path
        d="M11.3552 2.64067L9.303 0.588502C9.16809 0.454263 8.9855 0.378906 8.79518 0.378906C8.60485 0.378906 8.42227 0.454263 8.28735 0.588502L7.14648 1.72937L10.2178 4.80067L11.3587 3.6598C11.4254 3.5927 11.4783 3.51309 11.5143 3.42553C11.5502 3.33797 11.5686 3.24418 11.5682 3.14952C11.5679 3.05486 11.5489 2.96119 11.5124 2.87388C11.4758 2.78657 11.4224 2.70732 11.3552 2.64067Z"
        fill={color}
      />
    </svg>
  );
}
export default EditSVG;
