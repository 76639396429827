import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  ContainerTerms,
  BoxTerm,
  BoxTermAccept,
  CloseIconButton,
  Main,
} from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import InputCheckedV3 from '~/common/InputCheckedV3';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  onClose: () => void;
}

const ModalTerm: FC<IProps> = ({ onClose }) => {
  const [acceptedTerm, setAcceptedTerm] = useState<boolean>(false);

  const handleContinue = () => {
    if (!acceptedTerm) return;

    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Termo de aceite] Continuar – Efetivação da negociação.',
    });

    Navigation.navigate(RouteName.DEBTNEGOTIATIONDETAIL);
  };

  return (
    <Main isAcceptedTerm={acceptedTerm}>
      <CloseIconButton onClick={onClose} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <h3>Termo de aceite</h3>
      <p>Para efetivar a negociação, leia com atenção os termos a seguir:</p>
      <Margin height={16} />
      <BoxTerm>
        <p>
          Declaro que tenho a posse da unidade imobiliária supracitada e que sou o
          responsável legal e financeiro pelos serviços de fornecimento de água a
          esgotamento sanitário daquela unidade. Declaro que estou ciente das
          condições da prestação dos serviços antes referidos e dos custos
          envolvidos, inclusive nas hipóteses de ligação, suspensão e religação.
          Nesse sentido, por este termo, assumo a integral responsabilidade pelo
          pagamento das faturas mensais, com todos os custos correlatos, e reconheço
          a possibilidade de ter os serviços interrompidos, caso não faça o pagamento
          da fatura mensal nos prazos de vencimento.
          <br />
          <br />
          Em adição, declaro que as informações apresentadas, inclusive quanto a
          posse da unidade imobiliária, são verdadeiras e de minha exclusiva
          responsabilidade. Estou ciente de que, a meu pedido, tais informações
          resultarão na atualização do cadastro junto a concessionária Iguá Rio de
          Janeiro.
          <br />
          <br />
          <b>
            Por fim, estou ciente de que, caso a posse ora declarada seja contestada
            (por qualquer motivo e por qualquer meio) ou seja demonstrada injusta ou
            ilegal, caberá à concessionária o direito de suprimir a ligação.
          </b>
          <br />
          <br />
          Foi-me informado que a prestação dos serviços de abastecimento de água e
          esgotamento sanitário é regida pelo Contrato de Concessão nº 034/2021 e
          demais normas técnicas brasileiras às quais a prestadora dos serviços está
          submetida, estando tais documentos disponíveis para consulta no endereço
          eletrônico da concessionária <b>(www.iguasa.com.br/rio-de-janeiro).</b>
          <br />
          <br />
          Estou ciente de que cabe ao usuário dos serviços: (i) a responsabilidade
          pela manutenção e conservação das rede interna da unidade imobiliária
          necessária ao fornecimento de água e coleta do esgoto; (ii) qualquer
          reparação de defeitos nessa rede interna que ocasionem perdas ou vazamentos
          de água e esgoto; (iii) permitir o livre acesso de representantes da
          prestadora dos serviços ao medidor de consumo, quando este for instalado
          (conforme previsto nas normas pertinentes; e (iv) informar à Iguá Rio de
          Janeiro sobre quaisquer mudanças na utilização do imóvel.
          <br />
          <br />
          Foi-me esclarecido que, nos termos da legislação vigente, é de
          responsabilidade do usuário a instalação prévia de abrigo do cavalete de
          ligação de água. Tais regras estabelecem que, fica proibido ao usuário: (i)
          conectar as instalações prediais de água em tubulações que não sejam de
          propriedade da Iguá Rio de Janeiro; (ii) executar derivação em canalizações
          da instalação predial de água para abastecimento de outro imóvel, mesmo de
          sua propriedade; (iii) usar nas instalações prediais de água quaisquer
          dispositivos que possam prejudicar o sistema de abastecimento de água; (iv)
          usar dispositivos no medidor de água que, de qualquer forma, possam
          comprometer a precisão na medição do consumo; (v) violar o selo do medidor
          de água bem como o lacre de instalação colocado no cavalete; e (vii)
          instalar dispositivo no ramal predial ou na instalação predial que provoque
          sucção na rede distribuidora.
          <br />
          <br />
          Por fim, autorizo que AVISOS de qualquer natureza, inerentes à matrícula
          deste imóvel, inclusive a fatura e avisos de cobrança, me sejam enviados
          por mensagens eletrônicas que tenham por destinatário o número de telefone
          celular ora informado e/ou correio eletrônico (e-mail), em substituição a
          entrega de documento físico.
        </p>
      </BoxTerm>
      <Margin height={10} />
      <BoxTermAccept>
        <ContainerTerms>
          <InputCheckedV3
            checked={acceptedTerm}
            onChange={(e) => setAcceptedTerm(e.target.checked)}
          >
            <p className="term">Li e aceito o termo de responsabilidade.</p>
          </InputCheckedV3>
        </ContainerTerms>
        <ButtonText
          text="Continuar"
          typeBtn="content"
          onClick={handleContinue}
          className="button-green"
        />
      </BoxTermAccept>
    </Main>
  );
};

export default ModalTerm;
