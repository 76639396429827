export enum RepositoriesTypesUsersNotClient {
  ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_VALIDATE_METHOD_REQUEST',
  ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS',
  ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE',
  ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_BAD_REQUEST = '@repositories/ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_BAD_REQUEST',
  ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_REQUEST = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_REQUEST',
  ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS',
  ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE',
  ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_REQUEST',
  ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_SUCCESS',
  ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE',
  ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL = '@repositories/ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL',
  ENROLLMENTS_NOT_CLIENT_CLEAR_MODAL = '@repositories/ENROLLMENTS_NOT_CLIENT_CLEAR_MODAL',
}

export interface IRequestRegisterEnrollmentNotClientValidate {
  enrollment: string;
  location: string;
}

export interface IResponseRegisterEnrollmentNotClientValidate {
  totalSize: number;
  done: boolean;
  records: Array<any>;
}

interface IFile {
  filename: string;
  filedata: string;
}

export interface IRequestRegisterEnrollmentNotClient {
  cnp: string;
  enrollment: string;
  operationId?: string;
  nomeCompleto: string;
  rg: string;
  celular: string;
  telefone: string;
  email: string;
  dataNascimento: string;
  nomeMae: string;
  vinculoImovel: string;
  tipoReservatorio: string;
  rua: string;
  numero: string;
  bairro: string;
  complemento: string;
  pontoReferencia: string;
  numeroResidenciaLado: string;
  cidade: string;
  estado: string;
  cep: string;
  deAcordo: string;
  files: IFile[];
}

export interface IResponseRegisterEnrollmentNotClient {
  id: string;
}

export interface IResponseGetLocations {
  label: string;
  value: string;
}

export interface IRepositoriesStateEnrollmentsUsersNotClient {
  readonly loading: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingRegister: boolean;
  readonly success: boolean;
  readonly protocol: string;
  readonly errorRegister: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly modalNotExists: boolean;
  readonly locations: IResponseGetLocations[];
  readonly operationId: string;
}
