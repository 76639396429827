import React, { FC } from 'react';

interface IDownloadSVGProps {
  color?: string;
}

const DownloadSVG: FC<IDownloadSVGProps> = ({ color = '#37B4C8' }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0064 12.1833L13.5616 8.62803M10.0064 12.1833V1.51758V12.1833ZM10.0064 12.1833L6.45117 8.62803L10.0064 12.1833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.11914 13.9609L1.67109 16.1696C1.76722 16.5542 1.98913 16.8956 2.30155 17.1396C2.61397 17.3836 2.99897 17.5161 3.39537 17.5162H16.619C17.0154 17.5161 17.4004 17.3836 17.7129 17.1396C18.0253 16.8956 18.2472 16.5542 18.3433 16.1696L18.8953 13.9609"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadSVG;
