import styled, { css } from 'styled-components';

import { Color } from '~/styles';

export const TableContainer = styled.div`
  display: grid;
  border-radius: 16px 16px 0 0;
  grid-template-rows: 100px auto;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  width: 100%;
  @media (max-width: 768px) {
    grid-template-rows: 72px auto;
  }
`;

export const TableName = styled.div`
  background-color: ${Color.blueSeconde};
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableNameText = styled.div`
  color: ${Color.white};
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const TableInfo = styled.div``;
export const TableInfoText = styled.div``;

export const Table = styled.table`
  background: #ffffff;
  margin: 20px;
  height: 412px;
`;
export const TContentHeader = styled.thead``;
export const TableHeader = styled.tr`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 32px;
  border-bottom: 2px solid #000;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const TableHeaderText = styled.th`
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
`;

interface IPropsTableBody {
  emptyData: boolean;
}

export const TableBody = styled.tbody<IPropsTableBody>`
  display: block;
  height: 380px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ emptyData }) =>
    emptyData &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: 9fr 1fr;
  }
`;
export const TableCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  border-bottom: 1px solid rgba(71, 92, 102, 0.1);
  @media (max-width: 768px) {
    display: none;
  }
`;
export const TableCellText = styled.div`
  color: #27251f;
  font-weight: 300;
`;
export const TableCellApp = styled.td`
  display: none;
  height: 120px;
  border-bottom: 1px solid rgba(71, 92, 102, 0.1);
  padding: 10px 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const TableCellTextApp = styled.div`
  display: none;
  font-size: 16px;
  font-weight: 400;
  height: 30px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const TableCellButton = styled.button`
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.blueSeconde};
  box-shadow: 0px 3.42857px 5.14286px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  background-color: ${Color.white};
  &:hover {
    cursor: pointer;
    background-color: ${Color.gray};
  }
`;
