import React, { FC } from 'react';
import { Color } from '~/styles';

interface IProps {
  color?: string;
}

const DigitalClientSVG: FC<IProps> = ({ color = Color.blueSeconde }) => {
  return (
    <svg
      width="44"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.72648 12.5275C8.47106 7.79888 14.9072 4.89174 22.0034 4.89174C29.0997 4.89174 35.5358 7.79888 40.2804 12.5275C41.1605 13.3989 42.5495 13.3703 43.3884 12.4632C44.2273 11.556 44.1998 10.106 43.3265 9.2346C37.7981 3.71317 30.2824 0.320312 22.0034 0.320312C13.7245 0.320312 6.20879 3.71317 0.673446 9.22746C-0.199832 10.106 -0.227337 11.5489 0.61156 12.4632C1.45046 13.3775 2.84633 13.406 3.71961 12.5275H3.72648ZM22.0034 16.3203C25.9091 16.3203 29.471 17.8275 32.194 20.3203C33.1085 21.156 34.4975 21.0632 35.302 20.1203C36.1065 19.1775 36.0171 17.7275 35.1095 16.8917C31.6164 13.6917 27.0231 11.7489 22.0034 11.7489C16.9838 11.7489 12.3905 13.6917 8.90427 16.8917C7.98973 17.7275 7.90722 19.1703 8.71173 20.1203C9.51625 21.0703 10.9052 21.156 11.8198 20.3203C14.5359 17.8275 18.0978 16.3203 22.0103 16.3203H22.0034ZM26.4042 27.7489C26.4042 26.5365 25.9406 25.3737 25.1153 24.5164C24.2899 23.6591 23.1706 23.1775 22.0034 23.1775C20.8363 23.1775 19.7169 23.6591 18.8916 24.5164C18.0663 25.3737 17.6027 26.5365 17.6027 27.7489C17.6027 28.9613 18.0663 30.1241 18.8916 30.9814C19.7169 31.8387 20.8363 32.3203 22.0034 32.3203C23.1706 32.3203 24.2899 31.8387 25.1153 30.9814C25.9406 30.1241 26.4042 28.9613 26.4042 27.7489Z"
        fill={color}
      />
    </svg>
  );
};
export default DigitalClientSVG;
