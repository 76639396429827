import React, { FC, useState } from 'react';

import { Container } from '~/common';
import {
  ContainerBreadCrumb,
  Main,
  Title,
  Wrapper,
  TextContent,
  ContainerButton,
  ButtonInfo,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import LandingDpaDpeImg from '~/assets/Images/landing-dpa-dpe.png';
import { Margin } from '~/components/Margin';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ArrowUpSVG from '~/assets/Icons/arrowUp';

export const SupplyAndDepletionPossibilityDeclarationKnowMore: FC = () => {
  const [panelToggleRio, setPanelToggleRio] = useState(false);
  const [panelToggleCuiaba, setPanelToggleCuiaba] = useState(false);

  return (
    <Main>
      <Container>
        <Wrapper>
          <ContainerBreadCrumb>
            <BreadCrumb
              links={[
                {
                  label: 'Home',
                  active: false,
                  onClick: () => Navigation.navigate(RouteName.HOME),
                },
                {
                  label: 'DPA e DPE',
                  active: true,
                },
              ]}
            />
          </ContainerBreadCrumb>
          <Margin height={15} />
          <img src={LandingDpaDpeImg} alt="DPA e DPE" />
          <Margin height={40} />
          <Title>
            Declaração de Possibilidade de Abastecimento e esgotamento (DPA/DPE)
          </Title>
          <Margin height={40} />
          <TextContent>
            Os processos para emissão da Declaração de Possibilidade de Abastecimento
            e esgotamento (Dpa/DPE) são obrigatórios para novos empreendimentos. Esse
            processo é aberto para que os grandes clientes possam verificar se no
            local do empreendimento existe possibilidade de abastecimento e
            esgotamento através da concessionária ou se deverão executar algum tipo
            de Obrigação do Cliente para que esse seja feito.
            <br />
            <br />
            Para empreendimentos localizados no{' '}
            <strong>Rio de Janeiro e Cuiabá</strong>, é possível solicitar e
            acompanhar o progresso das solicitações no{' '}
            <strong>Painel de viabilidades</strong>. Para demais regiões, deve ser
            consultado a operação através dos nossos canais de atendimento. Para
            demais regiões, deve ser consultado a operação através dos nossos canais
            de atendimento.
            <br />
            <br />
            Confira mais detalhes de cada região abaixo:
          </TextContent>
          <Margin height={40} />

          <ButtonInfo
            isActive={panelToggleRio}
            onClick={() => {
              setPanelToggleRio(!panelToggleRio);
              setPanelToggleCuiaba(false);
            }}
          >
            Viabilidade de empreendimento Rio de Janeiro
            <ArrowUpSVG />
          </ButtonInfo>
          {panelToggleRio && (
            <>
              <Margin height={40} />
              <TextContent>
                Os processos de viabilidade são obrigatórios para clientes que
                possuem empreendimentos com mais de 10 economias, esses são chamados
                de clientes Iguá +. São eles: CPAE, OC e FCP. Sendo o CPAE necessário
                para a solicitação dos demais:
                <br />
                <br />
                <strong>CPAE</strong>
                <br />
                <br />
                A CPAE é um processo obrigatório para clientes que possuem novos
                empreendimentos e já existentes, que teve ou terá aumento no consumo.
                Esse processo é aberto para que os grandes clientes possam verificar
                se no local do empreendimento existe possibilidade de abastecimento e
                esgotamento através da concessionária ou se deverão executar algum
                tipo de Obrigação do Cliente para que esse seja feito. Nesse processo
                é gerada a Declaração de possibilidade de abastecimento e esgotamento
                (DPA/DPE).
                <br />
                <br />
                <strong>OC</strong>
                <br />
                <br />
                A OBRIGAÇÃO DO CLIENTE é o processo gerado através da elaboração da
                CPAE. Caso a Declaração de Possibilidade de Abastecimento e/ou
                Esgotamento indique que o cliente deverá executar obra, se faz
                obrigatório a abertura de uma O.C. Esse processo é aberto para que os
                clientes possam legalizar e executar a obra em via pública com
                acompanhamento da Iguá, tendo em vista que a rede executada pelo
                cliente será doada a concessionária.
                <br />
                <br />
                <strong>FCP</strong>
                <br />
                <br />A FCP é o processo gerado para regularização do sistema interno
                de abastecimento e esgotamento, o qual busca certificar que o
                empreendimento esta sendo abastecido e esgotado seguindo a Norma
                Brasileira – NBR. Para este processo você necessita de uma{' '}
                <strong>DPA</strong> e uma <strong>DPE</strong>.
                <br />
                <strong>Abastecimento: </strong> para empreendimentos que não possuam
                vias internas, o processo busca calcular o ramal necessário para
                atender a vazão solicitada E VERIFICAR A RESERVAÇÃO ADOTADA. Já para
                os que possuem, busca-se regulamentar e aprovar o projeto executivo
                de distribuição de água. <br />
                <strong>Esgotamento: </strong>Para empreendimentos que não possuam
                vias internas, o processo busca fiscalizar o projeto das caixas
                internas. Já para os que possuem, busca-se regulamentar e aprovar o
                projeto executivo de esgotamento sanitário.
              </TextContent>
            </>
          )}

          <Margin height={40} />

          <ButtonInfo
            isActive={panelToggleCuiaba}
            onClick={() => {
              setPanelToggleCuiaba(!panelToggleCuiaba);
              setPanelToggleRio(false);
            }}
          >
            Viabilidade de empreendimento Cuiabá
            <ArrowUpSVG />
          </ButtonInfo>
          {panelToggleCuiaba && (
            <>
              <Margin height={40} />
              <TextContent>
                <strong>CPAE</strong>
                <br />
                <br />A Consulta de possibilidade de Abastecimento (CPA) e a Consulta
                de Possibilidade de Esgotamento (CPE) são documentos emitidos pela
                concessionária afim de verificar a possibilidade de interligação do
                empreendimento em questão ao sistema público de abastecimento e/ou
                esgotamento. O cliente solicita a consulta e após a análise será
                enviada a Declaração de possibilidade de Abastecimento (DPA) e
                Declaração de possibilidade de Esgotamento (DPE).
                <br />
                <br />
                Quando solicitado o serviço de CPA/CPE, o cliente receberá em até 48
                horas* o boleto, essa taxa será enviada no e-mail cadastrado no
                formulário de solicitação. Após o pagamento dessa taxa o cliente
                receberá a DPA/DPE dentro do prazo de 15 dias corridos, que também
                será encaminhado via e-mail.
                <br />
                <br />
                O solicitante/cliente deve apresentar um croqui de localização do
                imóvel (imagem satélite) e/ou cópia da conta de água, documentos
                pessoais do proprietário e em caso de pessoas jurídicas os documentos
                do representante legal.
                <br />
                <br />
                Para empreendimentos acima de 99 economias residenciais é necessário
                apresentar a ALA (Análise de Localização e Atividade) emitida e
                deferida pela Prefeitura de Cuiabá.
                <br />
                <br /> *Caso seja identificado alguma pendência no documento, a taxa
                só será enviada após a apresentação dos documentos solicitados.
                <br />
                <br />
                <strong>Análise de Projeto</strong>
                <br />
                <br />
                Após o recebimento da DPA/DPE e em caso em que a resposta for:
                positiva com restrição, o empreendedor deve apresentar projeto para
                análise e aprovação desta concessionária.
                <br />
                <br />
                O interessado/cliente entra com o processo para análise de aprovação
                do projeto, a concessionária tem até 60 dias* para realizar a
                análise.
                <br />
                <br />
                Quando a análise for de aprovação será envia taxa de aprovação para o
                cliente, caso contrário será enviado taxa de reprovado.
                <br />
                <br />
                Assim que o cliente recebe o projeto aprovado o mesmo está liberado
                para iniciar a execução.
                <br />
                <br />
                Em caso, onde o projeto foi reprovado o mesmo deve entrar com novo
                processo de análise.
                <br />
                <br />
                *Caso seja identificado alguma pendência no documento, será envido
                e-mail solicitado a documento que falta e o prazo da concessionária
                será paralisado
              </TextContent>
            </>
          )}

          <Margin height={40} />

          <ContainerButton>
            <Button
              backIcon
              backgroundColor={Color.blueSeconde}
              style={{ maxWidth: 300 }}
              onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
            >
              Voltar
            </Button>
          </ContainerButton>

          <Margin height={40} />
        </Wrapper>
      </Container>
    </Main>
  );
};
