/* eslint-disable no-alert */
import React, { FC, useEffect, useState } from 'react';

import { Main, Row, SenFiles } from './styles';

import DropZone from '../../DropZone';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  patchAttachFilesCustomerObligationRequest,
  patchProtocolCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  postUploadFileCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import convertFileToBase64 from '~/utils/convertBase64';
import formatter from '~/utils/formatter';
import StatusInfo from '../../../../components/StatusInfo';
import { Link } from 'react-router-dom';
import folhaPadraoIgua from '../../../WorkStartRequest/Prolongation/FirstLicenseExtension/files/folhaPadraoIgua.dwg';

export const Form: FC = () => {
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loading = useSelector(
    (item: IState) => item.customerObligation.loadingDarmCode,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadFile,
  );

  const [file, setFile] = useState<File | null>(null);
  const [fileErro, setFileErro] = useState('');
  const [startRequestDate, setStartRequestDate] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledDate, setDisabledDate] = useState(true);

  const handleClick = () => {
    dispatch(
      patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '5A',
      }),
    );
    dispatch(
      patchProtocolCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        startWorkDate: startRequestDate,
      }),
    );
  };

  useEffect(() => {
    if (
      file &&
      !loadingUploadFile &&
      startRequestDate.length === 10 &&
      !disabledDate
    ) {
      return setDisabled(false);
    }
    return setDisabled(true);
  }, [file, startRequestDate, disabled, disabledDate]);

  return (
    <>
      <StatusInfo status="info">
        Os arquivos devem ser anexados em <b>formato PDF</b> e com{' '}
        <Link
          to={folhaPadraoIgua}
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          anexos padrão iguá.
        </Link>{' '}
        (clique para o download).
      </StatusInfo>
      <Margin height={40} />
      <Subtitle>
        Nesta etapa é necessário o anexo de todos os documentos e informações
        solicitados a seguir para a solicitação de aceite de rede.
      </Subtitle>
      <Margin height={64} />
      <Main>
        <>
          <Row>
            <SenFiles>
              <DropZone
                disabled={!!file}
                infoFileName="Anexo 13*"
                name="Anexo 13*"
                content={file || null}
                onFileUploaded={(file: File | null) => {
                  if (file === null) {
                    setFileErro('');
                  }
                  if (file && file.size < 100) {
                    return setFileErro('Por favor, verifique o seu arquivo.');
                  }

                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFileCustomerObligationRequest({
                          caseIdSales: idCreate || caseOC?.id || '',
                          type: 'start_work_letter',
                          step: '5A',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }

                  return setFile(file);
                }}
                type="default"
                error={!!fileErro}
                errorText={fileErro}
              />
            </SenFiles>
          </Row>
          <Margin height={30} />
          <Row>
            <SenFiles>
              <DropZone
                disabled={!!file}
                infoFileName="Relatório fotográfico*"
                name="Relatório fotográfico*"
                content={file || null}
                onFileUploaded={(file: File | null) => {
                  if (file === null) {
                    setFileErro('');
                  }
                  if (file && file.size < 100) {
                    return setFileErro('Por favor, verifique o seu arquivo.');
                  }

                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFileCustomerObligationRequest({
                          caseIdSales: idCreate || caseOC?.id || '',
                          type: 'start_work_letter',
                          step: '5A',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }

                  return setFile(file);
                }}
                type="default"
                error={!!fileErro}
                errorText={fileErro}
              />
            </SenFiles>
          </Row>
          <Margin height={40} />
          <Input
            styleInput={{ width: `40%`, maxWidth: `335px` }}
            label="Data para vistoria de conclusão de obra"
            type="date"
            value={startRequestDate}
            min={caseOC?.details.startWorkDates.initial}
            max={caseOC?.details.startWorkDates.final}
            onChange={(ev) => {
              const date = formatter.formatDate(ev.target.value);
              if (
                caseOC?.details?.startWorkDates.initial &&
                caseOC?.details?.startWorkDates.final
              ) {
                const dateInitial = new Date(
                  caseOC?.details?.startWorkDates.initial,
                );
                const dateFinal = new Date(caseOC?.details?.startWorkDates.final);
                if (new Date(date) < dateInitial || new Date(date) > dateFinal) {
                  setStartRequestDate(date);
                  return setDisabledDate(true);
                }

                setStartRequestDate(date);
                return setDisabledDate(false);
              }

              setStartRequestDate(date);
              return setDisabledDate(false);
            }}
            maxLength={6}
          />
        </>
      </Main>
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 4,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles || loading}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};
