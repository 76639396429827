import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';

import { Card, Column, Container, ContainerBreadCrumb, Separator } from './styles';
import { Text } from '~/components/TextInfo';
import { Margin } from '~/components/Margin';
import Header from './Header';
import Options from './Options';
import ModalError from './ModalError';
import Modal from '~/components/Modal';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Side from './Side';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints } from '~/styles';
import LoadingComponent from '~/components/Loading';

const AccountConfig: React.FC = () => {
  const isDesktopExtraLarge = useMediaQuery({
    minWidth: Breakpoints.desktopExtraLarge.min,
  });

  const loading = useSelector((item: IState) => item.enrollmentsAuth.loading);
  const errorUpdate = useSelector(
    (item: IState) => item.enrollmentsUsers.errorUpdate,
  );
  const isEntrepreneur = useSelector(
    (item: IState) => item.enrollmentsAuth.isEntrepreneur,
  );

  useEffect(() => {
    if (errorUpdate && errorUpdate.state) {
      ReactGA.event({
        category: 'Perfil',
        action: 'Erro na Solicitação de Atualização Cadastral',
      });
    }
  }, [errorUpdate]);

  return (
    <>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () =>
                isEntrepreneur
                  ? Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)
                  : Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Alteração de dados cadastrais',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Modal open={errorUpdate?.state || false} type="error" title="Ops!">
        <ModalError />
      </Modal>
      <Container>
        <Card>
          <Column>
            <Text
              color="#004B55"
              size={28}
              weight={400}
              fontFamily="Montserrat-Regular"
            >
              Alteração cadastral
            </Text>
          </Column>
          <Margin height={40} />
          <Separator />
          <Margin height={40} />
          <Header />
          <Margin height={40} />
          <Column>
            <Options />
          </Column>
        </Card>
        {isDesktopExtraLarge && !isEntrepreneur && (
          <>
            <Margin width={30} />
            <div>
              <Side />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default AccountConfig;
