import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsCard {
  isBorder?: boolean;
  height?: number;
  hiddenPadding?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 37px 10px 68px 10px;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 207px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1010px;
    padding: 22px 0 296px 0;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    max-width: 1170px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
    padding: 0 63px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
    padding: 0;
  }
`;

export const ContainerBanner = styled.div`
  position: relative;
  width: 270px;
  height: 540px;
  position: absolute;
  right: 0;
  top: 60px;
  border-radius: 24px;
  display: none;

  img {
    width: 270px;
    height: 540px;
    border-radius: 24px;
  }

  @media (min-width: 1340px) {
    display: inherit;
  }
`;

export const Card = styled.section<IPropsCard>`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  padding: 0 0 40px 0;
  margin: 0 auto;
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;

  img {
    width: 100%;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    height: 32px;
    text-align: center;
    color: ${Color.blueIntense};
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 0 0 0;

    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 870px;
    padding: 0 0 40px 0;

    h1 {
      font-size: 40px;
    }
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 0 33px 0;
    margin: 0;
  }

  @media (min-width: 1340px) {
    img {
      display: none;
    }
  }
`;

export const Content = styled.div`
  padding: 34px 16px 24px 16px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 34px 40px 24px 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding-bottom: 88px;
  }
`;

export const ContentPlan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 48px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;

    #subscribe-card {
      order: 2;
    }

    #plan-card {
      order: 1;
    }
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    gap: 60px;
  }
`;

export const ContentSubscribe = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const ContainerIcons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: space-around;
  }
`;

export const ContainerCancelSubscribe = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    border: none;
    background: none;
    color: ${Color.gray};
    text-align: right;
    font-size: 11px;
    font-weight: 600;
    line-height: 161.4%;
    text-decoration-line: underline;
    margin-top: 40px;
    cursor: pointer;
  }
`;
