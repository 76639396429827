import styled from 'styled-components';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${Color.black};
  }

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.gray};
  }

  .button-green {
    max-width: 200px;
    height: 32.18px;
    background: ${Color.green};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    align-self: end;
  }
`;

export const CloseIconButton = styled.button`
  width: 32px;
  height: 32px;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 17px;
  cursor: pointer;
  border: 2px solid ${Color.grayLightSeconde};
  color: ${Color.grayLightSeconde};
  font-size: 16px;
  font-weight: 500;
  border-radius: 100px;
  z-index: 9999;
`;

export const BoxTerm = styled.div`
  width: 100%;
  max-width: calc(500px - 20px);
  height: 277px;
  border: 1px solid ${Color.gray};
  text-align: left;
  overflow-y: scroll;
  padding: 0 14px 0 4px;
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.gray};

    a {
      font-weight: 600;
      color: ${Color.blueSeconde};
    }
  }
`;
