/* eslint-disable prefer-const */
import React, { FC } from 'react';
import ReactGA from 'react-ga4';

import {
  ButtonPayment,
  Container,
  LabelButtonPayment,
  NextLabelButtonPayment,
} from './styles';
import BoxDetail from './BoxDetail';
import { ArrowIcon } from '../../Icons';
import BarChart from './BarChart';
import { Breakpoints } from '~/styles';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IDados, IDadosDebts } from '~/store/modules/outsides/types';

const SummaryPendingIssues: FC = () => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const expiredQuantity = dataDischargeDebts?.info?.total?.expiredQuantity || 0;
  const pendingQuantity = dataDischargeDebts?.info?.total?.pendingQuantity || 0;
  const hasPendingIssues = expiredQuantity > 0 || pendingQuantity > 0;

  return (
    <Container>
      {isLaptop && hasPendingIssues && <BarChart />}
      <BoxDetail />
      {hasPendingIssues && (
        <ButtonPayment
          type="button"
          onClick={() => {
            ReactGA.event({
              category: 'Negociação de dívidas',
              action: '[Negociação de dívidas][Tela de dívidas] Pagar',
            });

            let data: Array<any> = [];

            dataDischargeDebts?.dados?.forEach((item: IDados) => {
              const updatedDebts = item?.debts?.map((debt: IDadosDebts) => ({
                ...debt,
                active: true,
              }));

              data?.push({
                ...item,
                debts: updatedDebts,
                activeAll: true,
              });
            });

            Navigation.navigate(RouteName.DEBTNEGOTIATIONSINGLEPAYMENT, {
              dados: data,
            });
          }}
        >
          <LabelButtonPayment>Até 12x sem juros no cartão</LabelButtonPayment>
          <NextLabelButtonPayment>
            <span>Pagar</span>
            <div>
              <ArrowIcon />
            </div>
          </NextLabelButtonPayment>
        </ButtonPayment>
      )}
    </Container>
  );
};

export default SummaryPendingIssues;
