import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerInputsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  textarea {
    height: calc(74px - 18px);
  }
`;

export const ContainerAlert = styled.div`
  margin: 24px auto;

  button {
    color: ${Color.blueSeconde};
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  div {
    padding: 24px;
    p + p {
      margin-top: 4px;
    }

    div {
      padding: initial;
    }
  }
`;

export const BoxInput = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(50% - 20px);
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px 40px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const BoxInputsTankerTruck = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 24px;
  background: #f2f2f2;
  border: 2px solid #37b4c8;
  border-radius: 8px;

  input,
  select,
  textare {
    background-color: #fff;
  }
`;

export const DescriptionInputsTankerTruck = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #004b55;
`;

export const TermErrorText = styled.p<{ color: string }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${({ color }) => color};
`;
