import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #004b55;
`;

export const ButtonBalloon = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const ContainerInfoBox = styled.div`
  margin-top: 24px;
`;

export const Divisor = styled.div`
  height: 1px;
  background: #c7c9c7;
  margin: 24px 0;
`;
