import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface IProps {
  fill?: string | null;
  v3?: boolean;
}

const PixSVG: FC<IProps> = ({ fill, v3 }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return v3 ? (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1827 7.94788C9.45912 7.18031 8.54305 6.7595 7.57032 6.63198L12.0937 2.10143C13.5635 0.632535 15.9408 0.633373 17.4043 2.10085L17.4047 2.10122L21.8882 6.58725C20.7949 6.67173 19.7728 7.13956 18.9871 7.92616L14.7858 12.1324C14.6084 12.2778 14.4668 12.2386 14.3807 12.1508L14.3807 12.1508L14.3775 12.1476L10.1827 7.94788ZM12.7938 13.726L12.8056 13.7378L12.8181 13.7488C13.2764 14.1503 13.9036 14.4648 14.5768 14.4648C15.2452 14.4648 15.8723 14.1497 16.33 13.7488L16.3426 13.7378L16.3543 13.726L20.569 9.50637C20.5693 9.50604 20.5697 9.50571 20.57 9.50538C21.0138 9.06612 21.6265 8.8124 22.2487 8.8124H24.1058L27.1518 11.862C28.6161 13.3279 28.6161 15.709 27.1518 17.175L24.1058 20.2246H22.2487C21.6263 20.2246 21.0141 19.9707 20.5708 19.5269L16.3543 15.3055C15.3979 14.348 13.75 14.3476 12.7933 15.3115C12.793 15.3117 12.7928 15.312 12.7926 15.3122L8.60103 19.5032C8.10042 19.964 7.52485 20.2081 6.91592 20.2081H5.38069L2.35 17.1749L2.3498 17.1747C0.883401 15.7088 0.883401 13.3282 2.3498 11.8623L2.35031 11.8617L5.38095 8.82337H6.91592C7.5248 8.82337 8.10034 9.06749 8.60092 9.52818L12.7938 13.726ZM14.3775 16.8894C14.4779 16.7889 14.6703 16.7889 14.7706 16.8894L18.9871 21.1108C19.7717 21.8963 20.792 22.3639 21.8836 22.4494L17.407 26.9312C15.9426 28.3563 13.5614 28.3563 12.097 26.9312L7.56794 22.4014C8.55066 22.2626 9.46203 21.8106 10.1774 21.0944L14.3775 16.8894Z"
        fill="#37B4C8"
        stroke="#37B4C8"
      />
    </svg>
  ) : (
    <svg
      width={isMobile ? '34' : '42'}
      height={isMobile ? '34' : '42'}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1181 19.4723C16.4772 19.1128 17.0956 19.1128 17.4546 19.4723L22.5747 24.5984C23.5189 25.5437 24.7756 26.0629 26.1055 26.0629H27.1096L20.653 32.5271C18.6382 34.491 15.3667 34.491 13.352 32.5271L6.86879 26.043H7.48719C8.81707 26.043 10.0738 25.5237 11.018 24.5784L16.1181 19.4723ZM17.4546 14.5726C17.0291 14.9387 16.4838 14.9454 16.1181 14.5726L11.018 9.46652C10.0738 8.46128 8.81707 8.00193 7.48719 8.00193H6.86879L13.3453 1.51515C15.3667 -0.505049 18.6382 -0.505049 20.653 1.51515L27.1162 7.98196H26.1055C24.7756 7.98196 23.5189 8.50122 22.5747 9.44655L17.4546 14.5726ZM7.48719 9.4998C8.40481 9.4998 9.24928 9.87261 9.95411 10.525L15.0542 15.6311C15.533 16.0505 16.158 16.3501 16.7897 16.3501C17.4147 16.3501 18.0398 16.0505 18.5185 15.6311L23.6386 10.505C24.2902 9.85929 25.1879 9.48649 26.1055 9.48649H28.6123L32.4889 13.3676C34.5037 15.3848 34.5037 18.6601 32.4889 20.6773L28.6123 24.5584H26.1055C25.1879 24.5584 24.2902 24.1856 23.6386 23.5332L18.5185 18.4072C17.5943 17.4818 15.9785 17.4818 15.0542 18.4138L9.95411 23.5132C9.24928 24.1656 8.40481 24.5384 7.48719 24.5384H5.37135L1.51337 20.6773C-0.504457 18.6601 -0.504457 15.3848 1.51337 13.3676L5.37135 9.4998H7.48719Z"
        fill={fill || '#37B4C8'}
      />
    </svg>
  );
};

export default PixSVG;
