/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  background: ${Color.white};
`;

export const Header = styled.div`
  border-bottom: 1px solid ${Color.gray};
  color: ${Color.black};
  font-size: 12px;
  font-weight: 300;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    border-bottom: 1px solid ${Color.gray};
    border: none;

    img {
      width: 171px;
      height: 34px;
    }
  }
`;

export const ContainerCardTemplate = styled.div`
  height: 172px;
  padding: 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: auto;
    height: 228px;
    width: 100%;
    max-width: 370px;
    padding: 0 19px;
  }
`;

export const ContainerInfoCard = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  input {
    height: 36px;
    border-radius: 8px;
    border: 1px solid ${Color.grayLightSeconde};
    background: ${Color.white};
  }

  label {
    color: ${Color.blueIntense};
  }

  .payment {
    border-radius: 8px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
  }

  button {
    border-radius: 8px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 35px;
    margin: auto;

    button {
      max-width: 230px;
    }
  }
`;

export const SummaryContainer = styled.div`
  padding: 18px 16px 0 16px;

  select {
    height: 32px;
    color: ${Color.grayLightSeconde};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.49px;
    padding: 0 17px;
    border-radius: 4px;
    border: 1px solid #c7c9c7;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 35px;
  }
`;

export const TitleSummary = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.035em;
  color: ${Color.black};

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const RowSummary = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.49px;
  color: ${Color.grayLightSeconde};
  span {
    text-transform: uppercase;
  }
`;

export const CustomButtonIcon = styled.button`
  width: 100%;
  height: 50px;
  background: ${Color.white};
  font-weight: 500;
  font-size: 14px;
  border-radius: 12px;
  position: relative;
  color: ${Color.blueSeconde};
  border-radius: 8px;
  border: 2px solid ${Color.blueSeconde};
  filter: none;
  box-shadow: none;

  svg {
    position: absolute;
    left: 16px;
    width: 17px;
    height: 15;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 230px;
  }
`;
