import React, { useCallback, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import {
  Container,
  ContainerDrop,
  ContainerInfo,
  ContainerSelectedFileName,
  ErrorsText,
} from './styles';
import UploadFileSVG from '~/assets/Icons/UploadFile';
import { Color } from '~/styles';
import TrashSVG from '~/assets/Icons/Trash';

interface Props {
  onFileUploaded: (file: File | null) => void;
  infoFileName?: string | '';
  disabled?: boolean;
  disabledDeleteFile?: boolean;
  name?: string;
  content?: File | null;
  type?:
    | 'default'
    | 'statementInterested'
    | 'statementResponsibility'
    | 'listMaterials'
    | 'CSV';
  error?: boolean;
  isRevision?: boolean;
  errorText?: string;
}

const DropZone: React.FC<Props> = ({
  onFileUploaded,
  infoFileName,
  disabled,
  disabledDeleteFile,
  name,
  content,
  type,
  error,
  isRevision,
  errorText,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  useEffect(() => {
    content && setSelectedFileUrl(content.name);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      setSelectedFileUrl(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const getAcceptType = () => {
    switch (type) {
      case 'default':
        return { 'image/pdf': ['.pdf'] };
      case 'statementInterested':
        return {
          'text/plain': ['.docx'],
        };
      case 'statementResponsibility':
        return {
          'text/plain': ['.docx'],
        };
      case 'listMaterials':
        return {
          'text/plain': ['.csv'],
          'application/vnd.ms-excel': ['.xlsx', '.xls'],
        };
      default:
        return { 'image/pdf': ['.pdf'] };
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: getAcceptType() as unknown as Accept,
    disabled: disabled || !!selectedFileUrl,
  });

  return (
    <Container>
      <ContainerDrop {...getRootProps()} error={selectedFileUrl ? false : error}>
        <input {...getInputProps()} name={name} />
        {selectedFileUrl ? (
          <ContainerSelectedFileName>
            {' '}
            <p>{selectedFileUrl}</p>
            <button
              type="button"
              onClick={() => {
                if (disabledDeleteFile) return;
                onFileUploaded(null);
                setSelectedFileUrl('');
              }}
            >
              <TrashSVG width={20} height={24} />
            </button>
          </ContainerSelectedFileName>
        ) : (
          <ContainerInfo error={content ? false : error}>
            <p>{infoFileName}</p>
            <div>
              <UploadFileSVG
                colorNewIcon={isRevision ? '#000000' : Color.gray}
                newIcon
              />
            </div>
          </ContainerInfo>
        )}
      </ContainerDrop>
      {errorText && <ErrorsText>{errorText}</ErrorsText>}
    </Container>
  );
};

export default DropZone;
