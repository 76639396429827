import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import ConfirmedSVG from '~/assets/Icons/Confirmed';
import { Breakpoints, Color } from '~/styles';
import { Wrapper, Step, StepNumber, StepLabel, StepDivider } from './styles';

interface Props {
  steps: {
    label: string;
    id: number;
  }[];
  activeStep: number;
}

export const Stepper: FC<Props> = ({ steps, activeStep }) => {
  const isResponsive = useMediaQuery({ maxWidth: Breakpoints.laptop.max });

  return (
    <Wrapper>
      {steps.map((step) => {
        const stepsCount = steps.length;
        const stepPosition = steps.indexOf(step);
        let isActive = false;
        stepPosition === activeStep ? (isActive = true) : (isActive = false);
        return (
          <React.Fragment key={step.id}>
            <Step>
              <StepNumber
                isActive={isActive || stepPosition < activeStep}
                stepPosition={stepPosition}
              >
                {steps.length === stepPosition + 1 ? (
                  <ConfirmedSVG color={Color.white} />
                ) : (
                  stepPosition + 1
                )}
              </StepNumber>
              {!isResponsive ? (
                <StepLabel isActive={isActive}>{step.label}</StepLabel>
              ) : null}
            </Step>
            {stepPosition < stepsCount - 1 ? (
              <StepDivider isActive={stepPosition < activeStep} />
            ) : null}
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};
