import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from '~/components/Button';
import { Margin } from '~/components/Margin';
import { updateSuccessCloseEnrollmentsUsers } from '~/store/modules/enrollmentsUsers/actions';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';

import { Main } from './styles';

const ModalSuccss: FC = () => {
  const dispatch = useDispatch();
  return (
    <Main>
      <p>Telefone atualizado com sucesso!</p>
      <Margin height={39} />
      <Button
        modalType="success"
        text="Entendi"
        type="button"
        onClick={() => {
          dispatch(updateSuccessCloseEnrollmentsUsers());
          Navigation.navigate(RouteName.HOME);
        }}
      />
    </Main>
  );
};

export default ModalSuccss;
