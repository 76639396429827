import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints, Color } from '~/styles';

import { Container, Separator } from './styles';

interface IProps {
  title: string;
}

const TitleStep: React.FC<IProps> = ({ title }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <Container>
      <Separator style={{ width: 80 }} />
      <div
        style={{
          padding: isMobile ? '0 5px' : '0px 24px',
          color: Color.blueIntense,
          fontSize: isMobile ? '14px' : '20px',
          fontFamily: 'Montserrat',
          fontWeight: 700,
        }}
      >
        {title}
      </div>
      <Separator style={{ flex: 1 }} />
    </Container>
  );
};

export default TitleStep;
