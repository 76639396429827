import React, { FC, useEffect, useState } from 'react';
import { Tiltle } from './styles';
import { IPropsForm } from '../typesForm';
import TitleStep from '../TitleStep';
import {
  CommentSelect,
  InputText,
  ListCommentSelect,
  ListCommentSelectElement,
  SelectContainer,
} from '../Input/styles';

import CuiabaCharacteristics from '../../characteristics/cuiaba.json';
import DefaultCharacteristics from '../../characteristics/default.json';

import ObjectInputs from '../Object';
import { Margin } from '~/components/Margin';
import Aggregator from '../Aggregator';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  ICharacterFilds,
  IFieldAgrgregatort,
  IFieldObject,
} from '~/store/modules/dpadpeDataMenager/types';
import WarningBox from '../WarnigBox';
import {
  DpaDpeLocationsEnum,
  DpaDpeLocationsLabelEnum,
  DpaDpeLocationsLabelMap,
} from '~/enums/dpadpeEnum';

interface IProps extends IPropsForm {
  onPres: (e: ICharacterFilds) => void;
  inputs?: IFieldObject[];
  setInputs: (e: IFieldObject[]) => void;
  onValidate: () => void;
}

const ThirdStep: FC<IProps> = ({
  onPres,
  inputs,
  setInputs,
  errors,
  touched,
  onValidate,
}) => {
  const [charCaters, setCaracters] = useState<ICharacterFilds[]>();

  const selectedFeature = useSelector(
    (item: IState) => item.dpadpeDataMenager.selectedFeature,
  );

  const fieldsObject = selectedFeature?.fields as IFieldObject[];
  const fieldsAggregator = selectedFeature?.fields as IFieldAgrgregatort[];

  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);
  const dpadpeLocationData = useSelector(
    (item: IState) => item.dpadpe.dpadpeLocation,
  );

  const dpadpeLocation = caseDetail?.selectedCase
    ? DpaDpeLocationsLabelMap[caseDetail?.enterprise?.city] ||
      DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.RIO]
    : dpadpeLocationData;

  const characteristics = {} as any;

  useEffect(() => {
    characteristics[DpaDpeLocationsEnum.CUIABA] = CuiabaCharacteristics;
    characteristics[DpaDpeLocationsEnum.MIGUEL] = DefaultCharacteristics;
    characteristics[DpaDpeLocationsEnum.PATY] = DefaultCharacteristics;
    characteristics[DpaDpeLocationsEnum.RIO] = DefaultCharacteristics;

    const characteristic =
      characteristics[dpadpeLocation] || characteristics[DpaDpeLocationsEnum.RIO];

    const value = Object.values(characteristic) as ICharacterFilds[];

    setCaracters(value);
  }, []);

  return (
    <>
      <TitleStep title="Características" />

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginTop: 20,
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            padding: '0 80px',
          }}
        >
          <Tiltle>Escolha a opção que mais se enquadre*</Tiltle>
          {charCaters && (
            <SelectContainer>
              <InputText
                disabled={!!caseDetail?.selectedCase}
                selectForm
                as="select"
                name="color"
                style={{
                  width: '60%',
                  height: '45px',
                  color: '#000',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
                onChange={(e: any) => onPres(e)}
              >
                {charCaters.map((item) => (
                  <option
                    key={item.key}
                    value={JSON.stringify(item)}
                    selected={
                      caseDetail?.selectedCase
                        ? item?.label ===
                          caseDetail?.characteristics[0]?.characteristic_type
                        : undefined
                    }
                  >
                    {item.label}
                  </option>
                ))}
              </InputText>
              {selectedFeature && selectedFeature.comments && (
                <CommentSelect>{selectedFeature.comments}</CommentSelect>
              )}
            </SelectContainer>
          )}
          {selectedFeature && selectedFeature.subComments && (
            <>
              <Margin height={16} />
              <WarningBox>
                <>
                  <Margin height={8} />
                  {selectedFeature.subComments}
                  {selectedFeature && selectedFeature.listComments && (
                    <>
                      <Margin height={8} />

                      <ListCommentSelect>
                        {selectedFeature.listComments.map((element: string) => (
                          <ListCommentSelectElement>
                            {element}
                          </ListCommentSelectElement>
                        ))}
                      </ListCommentSelect>
                    </>
                  )}
                </>
              </WarningBox>
            </>
          )}
        </div>
        <Margin height={26} />
        {selectedFeature && (
          <>
            {selectedFeature.type === 'object' && (
              <ObjectInputs
                setInputs={setInputs}
                inputs={inputs}
                fields={fieldsObject}
                errors={errors}
                touched={touched}
                onValidate={() => onValidate()}
              />
            )}
            {selectedFeature.type === 'aggregator' && (
              <Aggregator
                fields={fieldsAggregator}
                errors={errors}
                touched={touched}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ThirdStep;
