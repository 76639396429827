import React, { FC } from 'react';

import {
  Button,
  ContainerIcon,
  ContainerTexts,
  DescriptionText,
  Main,
  TitleText,
} from './styles';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';

interface IWarningNumberAttemptsExceededProps {
  onClick: () => void;
}

const WarningNumberAttemptsExceeded: FC<IWarningNumberAttemptsExceededProps> = ({
  onClick,
}) => {
  return (
    <Main>
      <ContainerIcon>
        <StatusWarningIconSVG />
      </ContainerIcon>
      <ContainerTexts>
        <TitleText>Número de tentativas excedido</TitleText>
        <DescriptionText>
          Para continuar:{' '}
          <Button type="button" onClick={onClick}>
            Reenviar o código
          </Button>
        </DescriptionText>
      </ContainerTexts>
    </Main>
  );
};

export default WarningNumberAttemptsExceeded;
