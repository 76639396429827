/* eslint-disable consistent-return */
import { RE_NON_DIGIT } from './constants';

/* eslint-disable no-param-reassign */
const partialHiddenCPFMask = (text: string): string => {
  const cpf = text.replace(/[^0-9]/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.***.***-$4');
};

const formatCurrency = (
  value = 0,
  withoutSymbol?: boolean,
  currency?: string,
): string => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    currency: currency || 'BRL',
    style: 'currency',
  }).format(value);

  return withoutSymbol ? formattedValue.replace('R$', '') : formattedValue;
};

const removeWidowWord = (text: string): string => {
  let formattedText = text;

  const wordArray = text.split(' ');

  if (wordArray.length > 1) {
    wordArray[wordArray.length - 2] += `\u00A0${wordArray[wordArray.length - 1]}`;
    wordArray.pop();
    formattedText = wordArray.join(' ');
  }

  return formattedText;
};

const cpfCnpjMask = (value: string) => {
  const dataTemp = value.toString();
  const UnformatteCPF: string = dataTemp.replace(/\D/g, '');

  if (UnformatteCPF.length <= 11) {
    const cpf = UnformatteCPF.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    return cpf;
  }
  const cnpj = UnformatteCPF.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return cnpj;
};

const cnpjMask = (value: string) => {
  const dataTemp = value.toString();

  return dataTemp
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const cpfMask = (value: string) => {
  const dataTemp = value.toString();

  return dataTemp
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

// 36.217.936-0
const rgMask = (value: string) => {
  const dataTemp = value.toString();

  return dataTemp
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{1})\d+?$/, '$1');
};

const formatarPhone = (tel?: string) => {
  return tel
    ? tel
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    : '';
};

const formatarTel = (tel?: string) => {
  return tel
    ? tel
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    : '';
};

const formatarNumber = (number?: string) => {
  if (!number) return '';

  number = number?.replace(/\D/g, '');

  return number;
};

const formatarDecimalNumber = (number?: string) => {
  if (!number) return number;
  if (number.length < 2) return number;

  number = number.replace(/[^\d]/g, '');
  number = number.replace(/([\d]*)(\d{2})$/g, '$1.$2');

  return number;
};

const creditCard = (number?: string) => {
  if (!number) return '';
  return String(number)
    .replace(/\W/g, '')
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$1 $2 $3 $4')
    .replace(/(\d{4})(\d{4})(\d{4})/g, '$1 $2 $3')
    .replace(/(\d{4})(\d{4})/g, '$1 $2')
    .replace(/(\d{4})/g, '$1');
};

const creditCardValidity = (value?: string) => {
  if (!value) return '';

  return String(value)
    .substring(0, 7)
    .replace(/\D$/g, '')
    .replace(/(\d{2})/, '$1')
    .replace(/(\d{2})(\d{4})/, '$1/$2');
};

const emailValidation = (value: string) => {
  if (!value) return false;
  const regex =
    /^([\w_\-\\.]+)@((\[[0-9]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.)|(([\w\\-]+\.)+))([a-zA-Z]{2,3}|[\d]{1,3})(\]?)$/g;

  const validate = regex.test(String(value).toLowerCase());
  return validate;
};

const rejexEmail = (value: string) => {
  if (!value) return false;

  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validate = regex.test(value);
  return validate;
};

const cepMask = (value: string) => {
  const cepString = value.toString().replace(/\D/g, '');

  if (cepString.length >= 5) {
    return cepString
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }

  return cepString;
};

const darmProcessNumber = (string: string) => {
  return string
    .replace(/\D/g, '')
    .substring(0, 12)
    .replace(/^(\d{2})(\d)/g, '$1/$2')
    .replace(/^(\d{2})\/(\d{6})(\d{1,4})/, '$1/$2/$3');
};

const enrollment = (value: string) => {
  if (!value) return value;
  if (value.length < 2) return value.replace(RE_NON_DIGIT, '');
  const number = value.replace(/[^\d]/g, '');
  const enrollment = number.replace(/([\d]*)(.)$/g, '$1-$2');
  return enrollment;
};

const removeDigitEnrollment = (value: string) => {
  if (!value) return value;
  const searchDigit = value.search('-');

  if (searchDigit === -1) {
    return value;
  }

  return value.substring(0, searchDigit);
};

interface IProps {
  typeFile: string;
  base64: string;
}

const transformBase64 = ({ typeFile, base64 }: IProps) => {
  const isImage = `data:application/${typeFile.toLocaleLowerCase()};base64,${base64}`;

  return isImage;
};

const obscureEmail = (email: string) => {
  if (!email) return '';

  const [emailUser, afterUser] = email.split('@');
  const [provider, domain, lasted] = afterUser.split('.');

  if (emailUser && provider && domain) {
    const nameUserEmail = `${emailUser[0]}${emailUser[1]}${emailUser[2]}***@`;

    const providerEmail = `${provider[0]}${provider[1]}${new Array(
      provider.length - 1,
    ).join('*')}.`;

    const domainEmail = `${domain[0]}${new Array(domain.length).join('*')}`;
    if (lasted) {
      const lasetEmail = `.${lasted[0]}${new Array(lasted.length).join('*')}`;

      return `${nameUserEmail}${providerEmail}${domainEmail}${lasetEmail}`;
    }

    return `${nameUserEmail}${providerEmail}${domainEmail}`;
  }
  return 'Será enviado para seu e-mail informado';
};
const obscurePhone = (mobile: string) => {
  const [before, after] = mobile.split('-');
  if (before && after) {
    const firstPart = `${before[0]}${before[1]}${before[2]}${before[3]}${before[4]}${
      before[5]
    }${new Array(before.length - 5).join('*')} `;
    const lasted = `${after[0]}${after[1]}${new Array(after.length - 1).join('*')} `;

    return `${firstPart}-${lasted}`;
  }
};

const formatDate = (input: string): string => {
  const digitsOnly = input.replace(/\D/g, '');
  const year = digitsOnly.slice(0, 4);
  const month = digitsOnly.slice(-4, -2);
  const day = digitsOnly.slice(-2);

  return `${year}-${month}-${day}`;
};

const formatOnlyText = (value: string): string => {
  if (!value) return value;

  return value.replace(/[^a-zA-Z ]/g, '');
};

const capitalize = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export default {
  cpfCnpjMask,
  partialHiddenCPFMask,
  formatCurrency,
  removeWidowWord,
  formatarPhone,
  formatarTel,
  formatarNumber,
  formatarDecimalNumber,
  creditCard,
  creditCardValidity,
  emailValidation,
  rejexEmail,
  cepMask,
  cpfMask,
  rgMask,
  cnpjMask,
  enrollment,
  removeDigitEnrollment,
  darmProcessNumber,
  transformBase64,
  obscureEmail,
  obscurePhone,
  formatDate,
  formatOnlyText,
  capitalize,
};
