import React from 'react';

interface IProps {
  color?: string;
  colorV3?: string;
  layoutv3?: boolean;
}

const MailSVG = ({ layoutv3, color = '#37B4C8', colorV3 = '#004B55' }: IProps) => {
  return layoutv3 ? (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13175_5982)">
        <path
          opacity="0.3"
          d="M55 22L33 35.75L11 22V49.5H55V22ZM55 16.5H11L33 30.2225L55 16.5Z"
          fill={colorV3}
        />
        <path
          d="M11 55H55C58.025 55 60.5 52.525 60.5 49.5V16.5C60.5 13.475 58.025 11 55 11H11C7.975 11 5.5 13.475 5.5 16.5V49.5C5.5 52.525 7.975 55 11 55ZM55 16.5L33 30.2225L11 16.5H55ZM11 22L33 35.75L55 22V49.5H11V22Z"
          fill={colorV3}
        />
      </g>
      <defs>
        <clipPath id="clip0_13175_5982">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          opacity="0.3"
          d="M55 22L33 35.75L11 22V49.5H55V22ZM55 16.5H11L33 30.2225L55 16.5Z"
          fill={color}
        />
        <path
          d="M11 55H55C58.025 55 60.5 52.525 60.5 49.5V16.5C60.5 13.475 58.025 11 55 11H11C7.975 11 5.5 13.475 5.5 16.5V49.5C5.5 52.525 7.975 55 11 55ZM55 16.5L33 30.2225L11 16.5H55ZM11 22L33 35.75L55 22V49.5H11V22Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default MailSVG;
