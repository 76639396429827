import { IRequestCreateCard } from '~/store/modules/enrollmentsCards/types';
import { api } from './api';

function getEnrollmentsCardsService() {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsCards: any = async () => {
        const response = await api.get('v3/cards/me');

        return response.data;
      };

      resolve(getEnrollmentsCards());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsCreateCardService(data: IRequestCreateCard) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsCreateCard: any = async () => {
        const response = await api.post('v3/cards', { ...data });

        return response.data;
      };

      resolve(postEnrollmentsCreateCard());
    } catch (error) {
      reject(error);
    }
  });
}

function deleteEnrollmentsCardService(cardId: number) {
  return new Promise((resolve, reject) => {
    try {
      const deleteEnrollmentsCard: any = async () => {
        const response = await api.delete(`v3/cards/${cardId}`);

        return response.data;
      };

      resolve(deleteEnrollmentsCard());
    } catch (error) {
      reject(error);
    }
  });
}

function patchEnrollmentsCardDefaultService(cardId: number) {
  return new Promise((resolve, reject) => {
    try {
      const patchEnrollmentsCardDefault: any = async () => {
        const response = await api.patch(`v3/cards/${cardId}`);

        return response.data;
      };

      resolve(patchEnrollmentsCardDefault());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsCardsService,
  postEnrollmentsCreateCardService,
  deleteEnrollmentsCardService,
  patchEnrollmentsCardDefaultService,
};
