import styled from 'styled-components';
import { Color } from '~/styles';

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const UploadLabel = styled.label`
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const UploadFile = styled.button`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  justify-content: center;
  background: white;
  border: 1px solid ${Color.gray};
  &:hover {
    background: #00000005;
    cursor: pointer;
  }
  transition: ease background 100ms;
  p {
    font-weight: 500;
  }
`;

export const UploadInfo = styled.p`
  color: ${Color.gray};
  font-size: 14px;
`;

export const ImageSlider = styled.div`
  height: 300px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${Color.gray};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
