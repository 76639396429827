import React from 'react';

function WalletVisaDetailSVG() {
  return (
    <svg
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.6465 13.8693C32.6465 13.8693 33.174 16.4437 33.2927 16.983H30.974L32.0852 13.9727C32.0715 13.9939 32.314 13.3425 32.4527 12.9414L32.6465 13.8693ZM40 3.39982V27.7564C39.9997 28.6373 39.6483 29.482 39.0232 30.1049C38.3981 30.7277 37.5504 31.0778 36.6664 31.0781H3.33365C2.44961 31.0778 1.60188 30.7277 0.976768 30.1049C0.35166 29.482 0.000331286 28.6373 0 27.7564V3.39982C0.000331286 2.51895 0.35166 1.67426 0.976768 1.05139C1.60188 0.428525 2.44961 0.0784551 3.33365 0.078125H36.6664C37.5504 0.0784551 38.3981 0.428525 39.0232 1.05139C39.6483 1.67426 39.9997 2.51895 40 3.39982ZM10.5909 20.7817L14.9795 10.0432H12.0284L9.29971 17.3778L9.00097 15.8907L8.0285 10.9499C7.8685 10.2649 7.37602 10.0706 6.76479 10.0432H2.27243L2.22368 10.2574C3.25249 10.5089 4.23936 10.9075 5.15359 11.4406L7.63976 20.7817H10.5909ZM17.147 20.7954L18.8969 10.0432H16.1057L14.3633 20.7954H17.147ZM26.8617 17.2807C26.8754 16.0564 26.1254 15.1223 24.5217 14.3538C23.543 13.8619 22.9455 13.5306 22.9455 13.0249C22.9593 12.5678 23.453 12.097 24.5492 12.097C25.2632 12.0769 25.9727 12.2165 26.6254 12.5055L26.8754 12.6226L27.2579 10.2973C26.4599 9.99291 25.6124 9.83794 24.758 9.8402C22.0006 9.8402 20.0631 11.3074 20.0494 13.396C20.0281 14.9392 21.4381 15.7973 22.4943 16.3154C23.5705 16.841 23.9393 17.1873 23.9393 17.6506C23.9255 18.3705 23.0643 18.7018 22.2656 18.7018C21.1543 18.7018 20.5569 18.5287 19.6469 18.1276L19.2794 17.9545L18.8907 20.3695C19.5431 20.6672 20.7519 20.93 22.0018 20.9437C24.9317 20.9499 26.8417 19.5039 26.8629 17.277L26.8617 17.2807ZM36.6664 20.7954L34.4164 10.0432H32.2565C31.5903 10.0432 31.0828 10.2375 30.7978 10.9362L26.6517 20.7967H29.5816C29.5816 20.7967 30.0603 19.4678 30.1653 19.185H33.7489C33.8327 19.5662 34.0827 20.7967 34.0827 20.7967L36.6664 20.7954Z"
        fill="#004B55"
      />
    </svg>
  );
}
export default WalletVisaDetailSVG;
