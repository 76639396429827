import React, { FC, useState } from 'react';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { Margin } from '~/components/Margin';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Main } from './styles';

const Status: FC = () => {
  const [disabled] = useState(true);

  return (
    <Main>
      <StatusInfo status="wait">
        Os documentos foram enviados para análise. Você pode acompanhar o andamento
        na tela inicial da <b>Viabilidade de Empreendimento.</b>
      </StatusInfo>

      <Margin height={40} />
      <ContainerButton>
        <Button backIcon backgroundColor={Color.green} onClick={() => null}>
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={() => {
            console.log('passe a etapa');
          }}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Status;
