import React from 'react';

function ArrowBackSVG() {
  return (
    <svg
      width="43"
      height="39"
      viewBox="0 0 43 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5625 2L2 12.9687L12.5625 23.9375"
        stroke="#003C28"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.5625L19.3333 12.5625C31.2998 12.5625 41 22.4831 41 34.7216V36.9375"
        stroke="#003C28"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowBackSVG;
