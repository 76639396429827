/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsRevision {
  isRevision?: boolean;
}

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 22px 54px 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 118px 20px 19px 20px;
  }
`;

export const ContainerViability = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-top: 48px solid ${Color.blueIntense};
  border-radius: 48px 48px 0px 0px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  }
`;

export const TextTutorial = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: ${Color.blueIntense};
  text-align: justify;
  text-justify: inter-word;

  b {
    font-weight: 600;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 850px;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${Color.blueIntense};
  position: absolute;
  text-align: center;
  background: white;
  left: 105px;
  padding: 0 30px 0 30px;
`;

export const Subtitle = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${Color.gray};
  background: white;
  left: 105px;
`;

export const SeparatorTitle = styled.hr`
  width: 100%;
  border-top: 1px solid ${Color.grayLightSeconde};
`;

export const Header = styled.div`
  padding: 24px 0px 27px 20px;
  color: ${Color.blueIntense};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid ${Color.grayLightSeconde};
  margin-bottom: 20px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 40px 80px;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
  }
`;

export const ContainerBody = styled.div<IPropsRevision>`
  display: flex;
  flex-direction: column;
  padding: 41px 17px 22px 19px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: ${({ isRevision }) => !isRevision && '850px'};
    margin: 0 auto;
    padding: 0 0 64px 0;
  }
`;

export const ContainerButton = styled.div<IPropsRevision>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: 100%;
    max-width: 252px;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    filter: none;
  }

  button:first-of-type {
    svg {
      left: 16px;
    }
  }

  button:last-of-type {
    svg {
      right: 16px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: ${({ isRevision }) => isRevision && '850px'};
    margin: ${({ isRevision }) => isRevision && '0 auto'};
  }
`;

export const ContainerCase = styled.div`
  display: flex;
  height: auto;
`;

interface IBorderCase {
  color?: string;
}

export const BorderCase = styled.div<IBorderCase>`
  margin-top: 1px;
  height: auto;
  width: 7px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ color }) => color};
`;

export const ContainerCaseText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 10px;
  position: relative;
  overflow: hidden;
  p {
    font-weight: 700;
    font-size: 14px;
    color: ${Color.black};
  }

  svg {
    position: absolute;
    right: 0;
  }
`;

export const SeparadorBorder = styled.div`
  width: 100%;
  border: 1px solid rgba(199, 201, 199, 0.5);
  position: absolute;
  bottom: 0;
  left: 24px;
`;

export const ButtonInfo = styled.button<{ isActive: boolean }>`
  width: 70%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 14px;
  border-radius: 11px;
  background: ${({ isActive }) => (isActive ? Color.blueSeconde : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
  border: 2px solid ${Color.blueSeconde};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  svg {
    position: absolute;
    right: 12px;
    path {
      fill: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }
`;

export const ButtonInfoLight = styled.div`
  width: 75%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  border: 1px solid ${Color.blueSeconde};
  background: ${Color.white};
  color: ${Color.blueSeconde};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  svg {
    position: absolute;
    right: 12px;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  svg {
    position: static;
    margin-right: 4px;
  }
`;

export const EmptyContainer = styled.td`
  width: 100%;
  padding: 70px;
`;
