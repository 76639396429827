import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsError {
  error?: boolean;
}

export const ContainerDrop = styled.div<IPropsError>`
  width: 100%;
  height: 58px;
  background: ${Color.white};
  border: 1px solid ${({ error }) => (error ? Color.red : Color.grayLightSeconde)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ContainerSelectedFileName = styled.div<IPropsError>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    width: auto;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.06em;
    color: ${({ error }) => (error ? Color.red : '#000')};
    padding: 12px 12px 12px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  svg {
    margin-right: 16px;
  }

  @media (min-width: ${Breakpoints.tablet.max}px) {
    p {
      max-width: 195px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    p {
      max-width: max-content;
    }
  }
`;

export const ContainerInfo = styled.div<IPropsError>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${({ error }) => (error ? Color.red : Color.gray)};
    letter-spacing: -0.06em;
    padding: 12px 12px 12px 16px;
  }

  svg {
    margin-right: 16px;
  }
`;

export const TextError = styled.div<IPropsError>`
  position: absolute;
  right: 0;
  bottom: -20px;
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  color: ${Color.red};
`;
