import styled, { css } from 'styled-components';
import SelectImage from '~/assets/Images/SelectArrow.png';
import SelectImageForm from '~/assets/Images/Select.png';
import { Color } from '~/styles';

interface IPropsContainer {
  character?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  flex: 1;

  ${({ character }) =>
    character &&
    css`
      flex: none;
      width: 100%;
    `};
`;

interface IProps {
  select?: boolean;
  selectForm?: boolean;
  character?: boolean;
  isSub?: boolean;
}

export const SelectContainer = styled.div`
  display: flex;
`;

export const CommentSelect = styled.p<IProps>`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: ${Color.gray};
  line-height: initial;
  width: calc(40% - 40px);
`;

export const SubCommentSelect = styled.p`
  display: flex;
  align-items: center;
  font-size: 10pt;
  font-style: italic;
  font-weight: 400;
  color: #aad7ff;
  width: 100%;
  margin-top: 10px;
`;

export const ListCommentSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ListCommentSelectElement = styled.li`
  font-size: 14px;
  font-weight: 400;
  color: #999;
  width: 50%;
`;

export const InputText = styled.input<IProps>`
  display: flex;
  width: calc(100% - 22px);
  height: 30px;

  background: transparent;
  border: 1px solid #c7c9c7;
  border-radius: 8px;

  padding: 0 10px;
  ${({ select, selectForm }) =>
    (select || selectForm) &&
    `
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${select ? SelectImage : SelectImageForm});
    background-repeat: no-repeat;
    background-position: 98% 50%;
 `}

  ${({ selectForm }) =>
    selectForm &&
    `
      width: 100%;
      height: 32px;
  `}
`;

export const Label = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;

  color: #004b55;
`;
