import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';
import { COLORS } from '~/utils/constants';

export const Main = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 10px;
  margin: 0;
  position: relative;

  p {
    color: ${Color.gray};
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    text-align: center;
    max-width: 375px;
  }

  div {
    :first-of-type {
      min-height: inherit;

      h1 {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 128px 0 120px 0;
  }
`;

export const ResendTextStyled = styled.label`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.gray400};

  button {
    font-weight: 400;
    color: ${Color.blueSeconde};
    cursor: pointer;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 14px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  align-items: center;
  justify-content: space-between;
  margin: 0px 60px;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: 350px;
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowTextError = styled(Row)`
  justify-content: flex-end;
  height: 20px;
`;

export const TextError = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.red};
  text-align: right;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    margin: 0 auto;
  }
`;
