import { Reducer } from 'redux';

import {
  RepositoriesTypesEnrollmentsConsumptionHistory,
  IRepositoriesStateEnrollmentsConsumptionHistory,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsConsumptionHistory = {
  data: null,
  loading: false,
  error: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsConsumptionHistory, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };

    case RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
