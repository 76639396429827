/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import { BoxInputs, Main, Row } from './styles';
import { InputTextArea } from '~/components/InputTextArea';
import { Input } from '~/components/Input';
import { Color } from '~/styles';

interface IProps {
  labelSelect: string;
  labelInput: string;
  spaceTextArea?: boolean;
  onlyJustifcation?: boolean;
  textInsideInput?: string;
  inputJustification: {
    value: string;
    onChange: (e: any) => void;
    error?: boolean;
    errorText: string;
  };
  inputFlow?: {
    value: string;
    onChange: (e: any) => void;
    error: boolean;
    errorText: string;
    errorPosition?: string;
  };
  inputSelect?: {
    value?: string;
    onChange?: (e: any) => void;
    error: boolean;
    errorText?: string;
    errorPosition?: 'top' | 'bottom';
  };
  setSelectValue: (e: any) => void;
  selectValue: boolean | null;
}
const BoxInput: FC<IProps> = ({
  labelSelect,
  labelInput,
  spaceTextArea = false,
  onlyJustifcation = false,
  textInsideInput,
  inputFlow,
  inputJustification,
  setSelectValue,
  selectValue,
  inputSelect,
}) => {
  const [valueString, setValueString] = useState('null');

  useEffect(() => {
    if (selectValue === null || selectValue === undefined) {
      return setValueString('null');
    }
    return selectValue ? setValueString('true') : setValueString('false');
  }, [selectValue]);

  return (
    <Main>
      <BoxInputs>
        <>
          <InputSelect
            style={{ width: `45%` }}
            styleError={{ width: `45%` }}
            label={labelSelect}
            error={inputSelect?.error}
            errorText={inputSelect?.errorText}
            errorPosition="bottom"
            value={valueString}
            values={[
              { label: '', value: null },
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            onChange={(ev: any) => setSelectValue(ev.target.value)}
          />
          {valueString !== 'null' && <Margin height={10} />}
          {valueString === 'false' && (
            <Row>
              <Margin width={spaceTextArea ? 80 : 0} />
              <InputTextArea
                rows={5}
                label="Justifique.*"
                name="Justifique"
                value={inputJustification.value}
                onChange={inputJustification.onChange}
                error={inputJustification.error}
                errorText={inputJustification.errorText}
                errorPosition="bottom"
                maxLength={500}
                style={{ resize: 'none' }}
              />
              <Margin width={80} />
            </Row>
          )}
          {valueString === 'true' && !onlyJustifcation && (
            <Input
              style={{ width: 'calc(40% - 55px)', paddingRight: '55px' }}
              styleLabelError={{ width: '40%' }}
              label={labelInput}
              name="input"
              rigthTextInsideInput="60%"
              textInsideInput={textInsideInput}
              colorTextInsideInput={Color.grayLightSeconde}
              value={inputFlow!.value}
              onChange={inputFlow!.onChange}
              error={inputFlow!.error}
              errorText={inputFlow!.errorText}
              errorPosition="bottom"
            />
          )}
          <Margin height={35} />
        </>
      </BoxInputs>
    </Main>
  );
};

export default BoxInput;
