import React from 'react';

interface IProps {
  className: string;
}

const BorderSVG: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      className={className}
    >
      <path
        d="M2 48.0941V18.3008C2 9.46422 9.16344 2.30078 18 2.30078H47.7933"
        stroke="#C7C9C7"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BorderSVG;
