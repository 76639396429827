import React from 'react';

function EmptySVG() {
  return (
    <svg
      width="62"
      height="77"
      viewBox="0 0 62 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 0C10.3463 17.5175 0 32.648 0 45.43C0 64.603 14.725 77 31 77C47.275 77 62 64.603 62 45.43C62 32.648 51.6538 17.5175 31 0ZM31 69.3C18.0188 69.3 7.75 59.4055 7.75 45.43C7.75 36.421 15.3062 24.486 31 10.241C46.6938 24.486 54.25 36.3825 54.25 45.43C54.25 59.4055 43.9813 69.3 31 69.3ZM14.8412 46.2C16.275 46.2 17.4375 47.201 17.7087 48.587C19.2975 57.134 26.5438 60.06 31.8137 59.6365C33.48 59.5595 34.875 60.8685 34.875 62.524C34.875 64.064 33.635 65.3345 32.085 65.4115C23.8312 65.912 14.1825 61.215 11.9738 49.5495C11.9064 49.1367 11.9301 48.7143 12.0432 48.3116C12.1563 47.9088 12.3561 47.5352 12.6288 47.2167C12.9015 46.8982 13.2406 46.6423 13.6226 46.4667C14.0046 46.2912 14.4204 46.2002 14.8412 46.2Z"
        fill="#C7C9C7"
      />
    </svg>
  );
}
export default EmptySVG;
