import { api } from '~/services/api';

interface IRecaptchaToken {
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
}

export const apiHeaderSetRecaptchaToken = ({
  recaptchaTokenV2,
  recaptchaTokenV3,
}: IRecaptchaToken) => {
  api.defaults.headers.common['x-recaptcha-v2'] = recaptchaTokenV2;
  api.defaults.headers.common['x-recaptcha-v3'] = recaptchaTokenV3;
};

export const apiHeaderResetRecaptchaToken = () => {
  delete api.defaults.headers.common['x-recaptcha-v2'];
  delete api.defaults.headers.common['x-recaptcha-v3'];
};
