export enum RepositoriesTypesEnrollmentsSubscribes {
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_REQUEST = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_REQUEST',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_SUCCESS',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_REQUEST = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_REQUEST',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_SUCCESS = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_SUCCESS',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE',
  ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_REQUEST = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_REQUEST',
  ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_SUCCESS',
  ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE',
  ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_REQUEST = '@repositories/ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_REQUEST',
  ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_SUCCESS',
  ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE = '@repositories/ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE',
  ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_REQUEST = '@repositories/ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_REQUEST',
  ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_SUCCESS',
  ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE = '@repositories/ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE',
  ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE_CLOSE',
}

export interface IResponseSubscribeMe {
  id: number;
  user_id: number;
  enrollment_id: string;
  recurrent_payment_id_cielo: string;
  amount: number;
  start_date: string;
  plan: {
    id: number;
    name: string;
    description: string;
    trial: number;
    amount: number;
    interval: string;
    amount_compare: number | null;
    interval_compare: number | null;
    is_advantageous: false;
  };
  card: {
    id: number;
    card_number: string;
    brand: string;
    type: string;
    issuer: string;
    issuer_code: string;
    foreign: number;
    corporate: number;
    prepaid: number;
    is_default: boolean;
  };
}

export interface IResponseSubscribeMeEnrollment {
  id: number;
  user_id: number;
  enrollment_id: string;
  plan_id: number;
  plan: {
    id: number;
    name: string;
    description: string;
    trial: number;
    amount: number;
    interval: 'monthly';
    amount_compare: null;
    interval_compare: null;
    is_advantageous: boolean;
    deletedAt: null;
    createdAt: string;
    updatedAt: string | null;
  };
  card_id: number;
  recurrent_payment_id_cielo: string;
  next_recurrency: string;
  payment_key: string;
  amount: number;
  start_date: string | null;
  deletedAt: string | null;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface IResponseSubscribePlans {
  id: number;
  name: string;
  description: string;
  trial: number;
  amount: number;
  interval: string;
  amount_compare: number | null;
  interval_compare: number | null;
  is_advantageous: boolean;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: null | string;
}

export interface IRequestSubscribeCreate {
  plan_id: number;
  enrollment_id: string;
  payment: {
    card_number: string;
    card_holder: string;
    expiration_date: string;
    security_code: number;
    brand: string;
    installments: number;
  };
}

export interface IRequestSubscribeDelete {
  plan_id: number;
  enrollment_id: string;
}

export interface IRepositoriesStateEnrollmentsEnrollmentsSubscribes {
  readonly data: IResponseSubscribeMe[] | [];
  readonly dataEnrollment: IResponseSubscribeMeEnrollment | null;
  readonly dataPlans: IResponseSubscribePlans[] | null;
  readonly loading: boolean;
  readonly error: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingEnrollment: boolean;
  readonly errorEnrollment: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingPlans: boolean;
  readonly errorPlans: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingCreate: boolean;
  readonly successCreate: boolean;
  readonly errorCreate: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingDelete: boolean;
  readonly errorDelete: {
    message: string;
    status: number;
    state: boolean;
  } | null;
}
