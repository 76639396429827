import { Dispatch } from 'redux';
import { outsideTogglesServices } from '~/services/outsides';
import { RepositoriesTypesEnrollmentsToggles, IResponse } from './types';

const getTogglesRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_REQUEST,
    });

    const response = (await outsideTogglesServices()) as IResponse[];

    return dispatch({
      type: RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

export { getTogglesRequest };
