/* eslint-disable prettier/prettier */

import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';
import { BREAKPOINTS, COLORS } from '~/utils/constants';

const variants = {
  default: css`
    --text-color: ${COLORS.secondary};
    --bg-color: ${COLORS.white};
    --border-color: ${COLORS.gray400};
  `,
  active: css`
    --text-color: ${COLORS.white};
    --bg-color: ${COLORS.secondary};
    --border-color: ${COLORS.secondary};
  `,
};

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.a<{ active: boolean | number }>`
  width: 100%;
  max-width: calc(128px - 16px);
  padding: 11px 0 11px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: var(--bg-color);
  color: var(--text-color);
  text-decoration: none;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  transition: border-color 160ms ease;
  margin-right: 16px;

  &:hover {
    border-color: ${COLORS.secondary};
  }

  ${({ active }) => (active ? variants.active : variants.default)}

  svg {
    flex: 0 0 24px;
    width: 21px;
    height: 18px;
  }

  ${({ active }) =>
    active &&
    css`
      width: 100%;
      height: calc(116px - 24px);
      margin: 14px 0 0 0;
      max-width: calc(428px - 16px);
    `}

  @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
    flex: 1 1 112px;
  }

  @media screen and (min-width: ${Breakpoints.tablet.min}px) and (max-width: ${Breakpoints.desktopLarge.max - 1}px) {
    max-width: calc(102px - 14px);
    padding: 11px 0 11px 10px;
    margin-right: 13px;

    ${({ active }) =>
      active &&
      css`
        width: 100%;
        height: calc(116px - 24px);
        margin: 14px 0 97px 0;
        max-width: calc(329px - 14px);
      `}
  }
`;

export const BoxTitle = styled.p`
  max-width: 90px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
`;

export const BoxDescription = styled.p<{ active?: boolean }>`
  max-width: 102px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;

  ${({ active }) =>
    active &&
    css`
      max-width: inherit;
    `}

  @media screen and (min-width: ${Breakpoints.tablet.min}px) and (max-width: ${Breakpoints.desktopLarge.max - 1}px) {
    font-size: 9px;
  }
`;
