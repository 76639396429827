/* eslint-disable consistent-return */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Main, NavWrap, Wrapper } from './styles';

import { Input } from '~/common/InputV3';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';

import {
  postCloseErrorEnrollmentsUsersSendSmsRegister,
  postEnrollmentsUsersSendSmsRegisterRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { Card } from '~/common/Card';
import { Button, ButtonLink, Tooltip } from '~/common';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { IDataRegister } from '../Register';
import { RouteName } from '~/routes/Routes.name';

const RegisterNumberPhone: React.FC = () => {
  const dispatch = useDispatch();

  const dataRegister = history.location.state as IDataRegister;

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingRegisterSendSms,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorRegisterSendSms,
  );
  const dataCreateClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateClient,
  );
  const dataCreateNotClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateNotClient,
  );
  const [phone, setPhone] = useState<string>(dataRegister?.phone || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorEmail, setErrorEmail] = useState(false);
  const [textEmail, setEmailText] = useState<string>(dataRegister?.email || '');

  useEffect(() => {
    dispatch(postCloseErrorEnrollmentsUsersSendSmsRegister());
  }, []);

  useEffect(() => {
    if (dataRegister && dataRegister?.method === 'email') {
      if (textEmail && textEmail.length > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (phone && phone.length === 15) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone, dataRegister, textEmail]);

  return (
    <Main>
      <Card
        title={
          dataRegister && dataRegister?.method === 'email'
            ? 'Confirmação via email'
            : 'Confirmação via sms'
        }
        label={
          dataRegister && dataRegister?.method === 'email'
            ? 'Agora informe seu email cadastrado, você receberá um código de verificação.'
            : 'Agora informe seu número cadastrado, você receberá um código de verificação.'
        }
      >
        <Wrapper>
          <Input
            value={
              dataRegister && dataRegister?.method === 'email'
                ? textEmail
                : formatter.formatarPhone(phone)
            }
            errorText="Email inválido"
            error={errorEmail}
            onChange={(ev) => {
              if (dataRegister && dataRegister?.method === 'email') {
                if (ev.target.value) {
                  if (formatter.rejexEmail(ev.target.value)) {
                    setErrorEmail(false);
                    setEmailText(ev.target.value);
                  } else {
                    setErrorEmail(true);
                    setEmailText(ev.target.value);
                  }
                } else {
                  setEmailText('');
                }
              } else {
                setPhone(formatter.formatarPhone(ev.target.value));
              }
            }}
            label={
              dataRegister && dataRegister?.method === 'email' ? 'Email' : 'Celular'
            }
          />
          <Tooltip variant="error" icon>
            {errorMessage}
          </Tooltip>{' '}
          {errorMessage && <Margin height={32} />}
          <NavWrap>
            <Button
              onClick={() => {
                if (disabled || loading) return;
                dispatch(
                  postEnrollmentsUsersSendSmsRegisterRequest(
                    {
                      cnp: dataRegister?.cnp || '',
                      email:
                        dataRegister && dataRegister?.method === 'email'
                          ? textEmail
                          : dataRegister?.email,
                      phone:
                        dataRegister && dataRegister?.method === 'email'
                          ? dataRegister?.phone
                          : phone,
                      isEntrepreneur: false,
                      isManager: false,
                    },
                    {
                      ...dataRegister,
                    },
                  ),
                );
              }}
              loading={loading}
              disabled={disabled}
            >
              Continuar
            </Button>
            <ButtonLink
              type="button"
              color="gray"
              onClick={() =>
                Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD, {
                  ...dataRegister,
                })
              }
            >
              Voltar
            </ButtonLink>
          </NavWrap>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default RegisterNumberPhone;
