/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Navigation from '~/utils/Navigation';
import { updateEnrollmentsUsersRequest } from '~/store/modules/enrollmentsUsers/actions';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

import { ButtonText } from '~/components/ButtonText';
import { CardForm } from '~/components/CardForm';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';

import { Main, DescriptionTextStyled } from './styles';
import ModalError from './ModalError';
import ModalSuccss from './ModalSuccss';
import formatter from '~/utils/formatter';

const RegisterClientPassword: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );

  const loading = useSelector((item: IState) => item.enrollmentsUsers.loadingUpdate);
  const dna = useSelector((item: IState) => item.enrollmentsAuth.token);
  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const password = useSelector((item: IState) => item.enrollmentsAuth.password);
  const succes = useSelector((item: IState) => item.enrollmentsUsers.successUpdate);
  const error = useSelector((item: IState) => item.enrollmentsUsers.errorUpdate);

  const [phone, setPhone] = useState<string>(
    formatter.formatarPhone(enrollmentsUserInfo?.mobile?.primary),
  );

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (error && error.state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  return (
    <>
      <Modal open={open} type="error" title="Ops!">
        <ModalError />
      </Modal>
      <Modal open={succes} type="success" title="Sucesso!">
        <ModalSuccss />
      </Modal>
      <Main>
        <CardForm>
          <Margin height={isMobile ? 83 : 124} />
          <Input
            value={phone}
            onChange={(ev) => setPhone(formatter.formatarPhone(ev.target.value))}
            label="Atualizar Celular"
            editIcon
          />
          <Margin height={isMobile ? 34 : 94} />
          <DescriptionTextStyled>
            Para atualizar a titularidade da conta entre em contato através do{' '}
            <span>FALE CONOSCO.</span>
          </DescriptionTextStyled>
          <Margin height={isMobile ? 26 : 94} />
          <ButtonText
            text="Atualizar celular"
            typeBtn="content"
            backgroundColor={Color.blueSeconde}
            onClick={() => {
              if (phone && phone.length === 15) {
                const dataSend = {
                  phone: {
                    primary: enrollmentsUserInfo?.phone.primary
                      ? enrollmentsUserInfo?.phone?.primary
                      : '',
                    secondary: enrollmentsUserInfo?.phone.secondary
                      ? enrollmentsUserInfo?.phone?.secondary
                      : '',
                  },
                  mobile: {
                    primary:
                      phone ||
                      enrollmentsUserInfo?.mobile?.primary?.substring(13) ||
                      '',
                    secondary: enrollmentsUserInfo?.mobile.secondary
                      ? enrollmentsUserInfo?.mobile?.secondary
                      : '',
                  },
                  registration: enrollment,
                  email: enrollmentsUserInfo?.email || '',
                  authData: enrollmentsUserInfo?.cnp
                    .toString()
                    .replace(/[^0-9]/g, ''),
                  dna,
                  password,
                };

                dispatch(updateEnrollmentsUsersRequest(dataSend));
              }
            }}
            loading={loading}
          />
          <Margin height={isMobile ? 11 : 25} />
          <ButtonText
            text="Cancelar"
            typeBtn="outlined"
            Color={Color.red}
            onClick={() => Navigation.navigate(RouteName.HOME)}
          />
          <Margin height={isMobile ? 21 : 32} />
        </CardForm>
      </Main>
    </>
  );
};

export default RegisterClientPassword;
