import styled, { css } from 'styled-components';
import { SituationAccount } from '~/store/modules/automaticDebits/types';
import { Color } from '~/styles';

export const Container = styled.div<SituationAccount>`
  width: calc(100% - 24px);
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.035em;
    color: ${Color.greenDark};
  }

  ${({ situation }) =>
    situation === 'Aguardando Confirmação' &&
    css`
      background: rgba(255, 215, 155, 0.5);
      border: 1px solid ${Color.yellow};
    `};

  ${({ situation }) =>
    (situation === 'Cadastrado' || situation === 'Confirmado') &&
    css`
      background: ${Color.greenLight};
      border: 1px solid ${Color.green};
    `};

  ${({ situation }) =>
    (situation === 'Recusado' ||
      situation === 'Enviado Exclusão' ||
      situation === 'Excluído') &&
    css`
      background: ${Color.redLight};
      border: 1px solid ${Color.red};
    `};

  ${({ situation }) =>
    situation === 'Recusado' &&
    css`
      p {
        color: ${Color.red};
      }
    `};
`;
