import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import bannerSocialTariff from '~/assets/Images/banner-social-tariff-page.png';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import {
  getSocialTariffRequest,
  postErrorCloseSocialTariffAttachmentsRequest,
} from '~/store/modules/socialTariff/actions';

import {
  Container,
  Title,
  ContainerButtons,
  ContainerText,
  Main,
  Text,
  ContainerBreadCrumb,
} from './styles';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { BreadCrumb } from '~/components/BreadCrumb';
import StatusInfo from './StatusInfo';
import { socialTariffStatusCuiaba } from '~/utils/socialTariffStatus';

const SocialTariffCuiaba: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const data = useSelector((item: IState) => item.socialTariff.data);
  const loading = useSelector((item: IState) => item.socialTariff.loading);

  useEffect(() => {
    dispatch(getSocialTariffRequest());
    dispatch(postErrorCloseSocialTariffAttachmentsRequest());
  }, []);

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa Social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <div className="banner">
          <img src={bannerSocialTariff} alt="banner - tarifa social" />
          {!data && (
            <ButtonText
              text="Acesse e faça sua solicitação"
              typeBtn="content"
              nextIcon
              onClick={() => Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)}
            />
          )}
        </div>
        <ContainerText>
          <Title>Tarifa social</Title>
          {data && (
            <>
              <StatusInfo
                status={data.status}
                onClick={
                  !socialTariffStatusCuiaba(data.status).hideButton
                    ? () => {
                        if (
                          socialTariffStatusCuiaba(data.status).label === 'Atenção'
                        ) {
                          Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION);
                        } else {
                          Navigation.navigate(RouteName.SOCIALTARIFFREQUEST, {
                            ...data,
                          });
                        }
                      }
                    : undefined
                }
                labelButton={
                  socialTariffStatusCuiaba(data.status).label ===
                    'Documentação aprovada' ||
                  socialTariffStatusCuiaba(data.status).label === 'Aguarde'
                    ? 'Ver solicitação'
                    : 'Solicitar novamente'
                }
              >
                {socialTariffStatusCuiaba(data.status)?.labelDescription ||
                  (socialTariffStatusCuiaba(data.status)?.label === 'Atenção' ? (
                    <>
                      Sua solicitação foi recusada. Para maiores informações, entre
                      em contato pelo Fale conosco!
                    </>
                  ) : (
                    <>
                      Sua solicitação foi concluída com sucesso! <br />
                      O desconto começara na próxima fatura! <br />
                      Após 1 ano, será necessária a renovação da solicitação.
                    </>
                  ))}
              </StatusInfo>
              <Margin height={isMobile ? 36 : 0} />
            </>
          )}
          <Text>
            A tarifa social nos serviços públicos de abastecimento de água e
            esgotamento sanitário é um benefício concedido à clientes consumidores
            das concessionárias do Grupo Iguá, onde há normativa oficial, publicada
            pelo poder concedente, para tal aplicação.
            <br />
            Em Cuiabá, a Prefeitura estabeleceu a ampliação do número de pessoas
            beneficiadas com a tarifa social nos serviços públicos de abastecimento
            de água e esgotamento sanitário.
            <br />
            <br />
            <h3>Quem pode ser beneficiado?</h3>
            <br />
            A Tarifa Social corresponde a um benefício de redução de 50% (cinquenta
            por cento) na tarifa de água e esgoto, categoria residencial, para
            população de baixa renda. O benefício da Tarifa Social é limitado ao
            consumo mensal de 10m³ de água e a correspondente coleta de esgoto, sendo
            o excedente faturado de acordo com a tabela tarifária vigente.
            <br />
            O benefício também será concedido às ligações que abasteçam proprietários
            ou inquilinos em situação comprovada de carência que sejam portadores de
            doenças graves, comprovado por laudo pericial expedido por instituições
            de saúde pública do Município de Cuiabá.
            <br />
            <br />
            <h3>
              Qual os requisitos necessários para cadastro e atualização de dados na
              tarifa social?
            </h3>
            <br />
            <ul>
              <li>
                {' '}
                Ser cadastrado(a) na categoria residencial, junto à Águas Cuiabá;
              </li>
              <li>
                Comprovar ser beneficiário(a) de algum programa de Proteção Social do
                Governo Federal;
              </li>
              <li>
                Tenha ligação cadastrada como apenas 1 (uma) *economia, com área
                construída menor ou igual a 80m²;
              </li>
              <li>
                Tenha comprovado, através de seu histórico de consumo média de
                energia elétrica nos últimos 12 (doze) meses, consumo igual ou
                inferior a 120 (cento e vinte) KWh;
              </li>
              <li>
                Esteja adimplente com as faturas junto à Águas Cuiabá no ato da
                adesão ao benefício, ou tenha realizado parcelamento do seu débito;
              </li>
              <li>Não possua fonte alternativa de abastecimento de água.</li>
            </ul>
            <br />
            *Todo o imóvel ou subdivisão de um imóvel considerado ocupável, com
            entrada própria independente das demais, e com instalações hidráulicas
            internas para o abastecimento de água e/ou coleta de esgoto.
            <br />
            <br />
            <h3>Documentos necessários</h3>
            <br />
            Depois de inscrito na Plataforma do Digi Iguá, o responsável pela família
            deve solicitar o cadastro da tarifa social de água e esgotamento
            sanitário e apresentar os seguintes documentos:
            <br />
            <br />
            <ul>
              <li>CadÚnico atualizado;</li>
              <li>Documento pessoal;</li>
              <li>Conta de energia atualizada (90dias);</li>
              <li>Matrícula (conta de água).</li>
            </ul>
            <br />
            <h3>Informações complementares</h3>
            <br />
            <ul>
              <li>
                O benefício da Tarifa Social será concedido pelo período de 1 (um)
                ano, podendo ser renovado sucessivamente por mais 2 (dois) períodos
                iguais, mediante comprovação de todos os requisitos citados acima.
              </li>
              <li>
                Os beneficiários da Tarifa Social serão informados pela Águas Cuiabá
                30 (trinta) dias antes do vencimento do benefício, via e-mail ou
                whatsapp.
              </li>
              <li>
                Não havendo requerimento de prorrogação da Tarifa Social ou não sendo
                mais cabível, o benefício será automaticamente cancelado, podendo ser
                novamente requerido após 90 (noventa) dias da data do cancelamento.
              </li>
              <li>
                Fica também cancelado o benefício caso não haja o pagamento de 3
                (três) faturas consecutivas ou não pelo cliente, podendo ser
                requerido novo enquadramento após 90 (noventa) dias da data do
                cancelamento.
              </li>
              <li>
                Os clientes, cujas ligações caracterizem fraude de qualquer natureza,
                perderão o direito ao benefício da Tarifa Social, podendo ser
                requerido novamente somente após 1 (um) ano da data do cancelamento.
              </li>
              <li>
                O prazo de aprovação da solicitação do benefício é de 15 dias úteis.
              </li>
            </ul>
            <br />
            <br />
          </Text>
        </ContainerText>
        {!data && (
          <>
            <Margin height={40} />
            <ContainerButtons>
              {isMobile ? (
                <>
                  <ButtonText
                    text="Solicitar tarifa social"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.green}
                    style={{ visibility: data ? 'hidden' : undefined }}
                    nextIcon
                    onClick={() =>
                      Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                    }
                  />
                  <Margin height={24} />{' '}
                  <ButtonText
                    text="Voltar"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.green}
                    style={{ visibility: 'hidden' }}
                    backIcon
                    onClick={() => Navigation.goBack()}
                  />
                </>
              ) : (
                <>
                  <ButtonText
                    text="Voltar"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.green}
                    style={{ visibility: 'hidden' }}
                    backIcon
                    onClick={() => Navigation.goBack()}
                  />
                  <ButtonText
                    text="Solicitar tarifa social"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.green}
                    style={{ visibility: data ? 'hidden' : undefined }}
                    nextIcon
                    onClick={() =>
                      Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                    }
                  />
                </>
              )}
            </ContainerButtons>
          </>
        )}
      </Container>
    </Main>
  );
};

export default SocialTariffCuiaba;
