/* eslint-disable prefer-destructuring */
import creditCardType from 'credit-card-type';
import { Cards } from '~/enums/cardsEnum';

const ALLOWED_CARDS = [
  {
    brand: null,
    issuer: 'default',
  },
  {
    brand: 'Master',
    issuer: 'mastercard',
  },

  {
    brand: 'Visa',
    issuer: 'visa',
  },
  {
    brand: 'Hiper',
    issuer: 'hipercard',
  },

  {
    brand: 'Elo',
    issuer: 'elo',
  },
];

export const findBrand = (card: any, setCard: (card: any) => void) => {
  let brandType = creditCardType((card.number || '').replace(/\D/g, '')) as any;

  if (brandType.length > 1) brandType = [{ type: 'undefined' }];
  else if (brandType.length >= 0) brandType = brandType[0];

  const brand = ALLOWED_CARDS.find(
    (itemBrand) => itemBrand.issuer === brandType?.type,
  );

  if (brand?.brand) setCard({ ...card, brand: brand.issuer });
};

export const findTypeCard = (cardType: string) => {
  switch (cardType) {
    case Cards.CREDIT:
      return 'Crédito';
    case Cards.MULTIPLE:
      return 'Múltiplo';
    case Cards.DEBIT:
      return 'Débito';
    default:
      return 'Múltiplo';
  }
};
