import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsContainer {
  successCreate?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  height: 286px;
  border-radius: 16px;
  border: 2px solid ${Color.green};
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ successCreate }) => (successCreate ? '0' : '24px')};
  margin-top: ${({ successCreate }) => (successCreate ? '0' : '24px')};
  padding: 0 22px;

  svg {
    margin-right: 24px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    p,
    a {
      line-height: 122.5%;
      text-align: left;
      margin: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.4px;
      color: ${Color.green};
      margin-bottom: 4px;
    }

    .subtitle {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 122.5%;
      letter-spacing: -0.35px;
      margin-top: 9.5px;

      a {
        text-decoration: underline;
        color: ${Color.blueSeconde};
        font-weight: 500;
      }
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    height: ${({ successCreate }) => (successCreate ? '140px' : '157px')};
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 24px;

    div {
      width: auto;
      flex-direction: column;
      align-items: flex-start;

      .subtitle {
        margin-top: 0;
      }
    }
  }
`;
