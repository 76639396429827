import styled from 'styled-components';
import { Color } from '~/styles';
import { BREAKPOINTS } from '~/utils/constants';

export const Main = styled.div`
  margin: 0 auto;
  padding: 64px 20px;
  width: 100%;
  max-width: 1170px;
  display: flex;
  gap: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
    padding-block-start: 0px;
  }
`;

export const ContainerButtonAction = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    flex-direction: column;
  }
`;

export const ButtonAction = styled.button`
  width: 100%;
  height: 164px;
  background: ${Color.green};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
  font-weight: 900;
  font-size: 1.1rem;
  line-height: 1.3;
  color: ${Color.white};
  cursor: pointer;

  > img {
    height: 87px;
    margin-right: 16px;

    @media (max-width: ${BREAKPOINTS.tablet}) {
      height: 64px;
    }
  }

  > .none-space {
    margin-right: 0;
  }
`;
