import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const TrashIconSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46154 7.1875V21.5625C1.46154 22.3531 2.11923 23 2.92308 23H16.0769C16.8808 23 17.5385 22.3531 17.5385 21.5625V7.1875H1.46154ZM5.84615 20.125H4.38462V10.0625H5.84615V20.125ZM8.76923 20.125H7.30769V10.0625H8.76923V20.125ZM11.6923 20.125H10.2308V10.0625H11.6923V20.125ZM14.6154 20.125H13.1538V10.0625H14.6154V20.125ZM17.9038 2.875H13.1538V1.07812C13.1527 0.792538 13.0368 0.518972 12.8315 0.31703C12.6262 0.115088 12.3481 0.00113555 12.0577 0L6.94231 0C6.65194 0.00113555 6.3738 0.115088 6.16849 0.31703C5.96317 0.518972 5.84731 0.792538 5.84615 1.07812V2.875H1.09615C0.805673 2.87576 0.52731 2.98959 0.321908 3.19161C0.116507 3.39364 0.000771534 3.66742 0 3.95312V5.75H19V3.95312C18.9992 3.66742 18.8835 3.39364 18.6781 3.19161C18.4727 2.98959 18.1943 2.87576 17.9038 2.875ZM11.6923 2.875H7.30769V1.45619H11.6923V2.875Z"
        fill={fill || '#872D2D'}
      />
    </svg>
  );
};

export default TrashIconSVG;
