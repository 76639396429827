import React, { FC, useEffect, useRef, useState } from 'react';
import clipboardCopy from 'clipboard-copy';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useSelector } from 'react-redux';

import CopyIcon from '~/assets/Icons/Copy';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';

import {
  BoxProtocol,
  Container,
  Content,
  ContainerDate,
  ContainerInfo,
  ContainerProtocol,
  ContentProtocol,
  ContentProtocolButtons,
  CopyText,
  Divisor,
  Separator,
} from './styles';
import { IState } from '~/store/modules/types';
import Protocol from './Protocol';
import { useMediaQuery } from 'react-responsive';

const SocialTariffStepConcluded: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const printRef = useRef(null);

  const data = useSelector((item: IState) => item.socialTariff.dataCreate);

  const [copy, setCopy] = useState<boolean>(false);
  const [downloadProtocol, setDownloadProtocol] = useState<boolean>(false);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 5000);
    }
  }, [copy]);

  const handleDownload = async () => {
    const element = printRef.current;
    const canvas = element ? await html2canvas(element) : null;

    const data = canvas?.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      data ? (link.href = data) : null;
      link.download = 'protocolo.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }

    setDownloadProtocol(!downloadProtocol);
  };

  // const steps = [
  //   {
  //     id: 0,
  //     label: 'Tipo de tarifa social',
  //   },
  //   {
  //     id: 1,
  //     label: 'Documentação',
  //   },
  //   {
  //     id: 2,
  //     label: 'Solicitação realizada',
  //   },
  // ];

  return (
    <Container>
      {downloadProtocol ? (
        <BoxProtocol>
          <ContentProtocol ref={printRef}>
            <Protocol />
          </ContentProtocol>
          <ContentProtocolButtons>
            <ButtonText
              text="Baixar protocolo"
              typeBtn="content"
              Color={Color.white}
              backgroundColor={Color.blueSeconde}
              onClick={handleDownload}
            />
            <Margin height={16} />
            <ButtonText
              text="Voltar"
              typeBtn="content"
              Color={Color.white}
              backgroundColor={Color.green}
              onClick={() => setDownloadProtocol(!downloadProtocol)}
            />
          </ContentProtocolButtons>
        </BoxProtocol>
      ) : (
        <>
          <Margin height={32} />
          <Content>
            <Divisor>
              <Margin height={17} />
              <h2
                style={{
                  color: '#000',
                }}
              >
                Solicitação aberta
              </h2>
              <Margin height={26} />
              <ContainerDate>
                <div>
                  <p>Data de solicitação</p>
                  <p>{moment(new Date()).format('DD/MM/YYYY')}</p>
                </div>
              </ContainerDate>
              <Margin height={32} />
              <ContainerInfo>
                Por favor, aguarde! Estamos avaliando sua solicitação.
              </ContainerInfo>
            </Divisor>
            <Margin width={69} />
            {!isMobile && <Separator />}
            <Margin width={30} />
            <Divisor>
              <ContainerProtocol>
                <p>O número do seu protocolo é:</p>
                <Margin height={3} />
                <div>
                  <b>{data?.protocol}</b>{' '}
                  <button
                    type="button"
                    onClick={() => {
                      setCopy(true);
                      clipboardCopy(data?.protocol || '');
                    }}
                  >
                    <CopyIcon />
                  </button>
                </div>
              </ContainerProtocol>
              <Margin height={copy ? 12 : 42} />
              {copy && (
                <>
                  <CopyText>Código copiado!</CopyText>
                  <Margin height={42} />
                </>
              )}
              <ButtonText
                text="Visualizar protocolo"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.blueSeconde}
                style={{
                  height: '48px',
                  marginTop: isMobile ? '18px' : undefined,
                }}
                onClick={() => setDownloadProtocol(!downloadProtocol)}
              />
              <Margin height={16} />
              <ButtonText
                text="Voltar para o home"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.green}
                style={{ height: '48px' }}
                onClick={() => Navigation.navigate(RouteName.HOME)}
              />
              <Margin height={isMobile ? 104 : 12} />
            </Divisor>
          </Content>
        </>
      )}
    </Container>
  );
};

export default SocialTariffStepConcluded;
