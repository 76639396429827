import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface ICard {
  miniCard?: boolean;
  hiddenBorder?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 18px 8px 134px 8px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1170px;
    width: 100%;
    padding: 22px 22px 120px 22px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 22px 0 120px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 32px);

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 44px);
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    width: 100%;
    position: relative;
    flex-direction: row;
    display: inherit;
    justify-content: flex-start;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
    padding: 0 63px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
    padding: 0;
  }
`;

export const Card = styled.div<ICard>`
  width: calc(100% - 28px);
  padding: 24px 14px 40px 14px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  border-top: 24px solid ${Color.blueIntense};

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(870px - 160px);
    padding: 12px 80px 40px 80px;
    align-self: flex-start;
  }

  h1 {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 24px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #000;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: flex;
  }
`;

export const ContainerColumn = styled.div<{ flexBasis: number }>`
  flex-basis: ${({ flexBasis }) => flexBasis}%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const HeaderText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 28px);
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;
