import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Modal from '~/components/Modal';
import ConsumptionHistorySession from '../ConsumptionHistory';
import EasyInvoice from '../EasyInvoice';
import OwerServices from '../OwerServices';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';

import { Breakpoints } from '~/styles';
import { Main } from '../styles';

import ModalAccountDebt from '../ModalAccountDebt';

const HomeClient: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const [openAccountDebt, setOpenAccountDebt] = useState<boolean>(false);

  const checkModal = useSelector((item: IState) => item.automaticDebits.checkModal);

  useEffect(() => {
    if (checkModal) {
      setOpenAccountDebt(true);
    } else {
      setOpenAccountDebt(false);
    }
  }, [checkModal]);

  return (
    <>
      <Modal open={openAccountDebt} type="info" title="Atenção!">
        <ModalAccountDebt />
      </Modal>
      <Main>
        <EasyInvoice />
        <ConsumptionHistorySession />
        <Margin height={isMobile ? 0 : 5} />
        <OwerServices />
      </Main>
    </>
  );
};

export default HomeClient;
