import React from 'react';

function ArrowDownButtonSVG() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.9062L12 15.9062M16 11.9062L12 15.9062M12 15.9062V5.90625"
        stroke="#212529"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 19.9062H5"
        stroke="#212529"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default ArrowDownButtonSVG;
