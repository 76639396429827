import React from 'react';

const ConsumeSVG = () => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2044 18.1049C17.2044 23.0652 13.5454 27.0861 9.03189 27.0861C4.51833 27.0861 0.859375 23.0652 0.859375 18.1049C0.859375 10.2462 9.03189 0.934082 9.03189 0.934082C9.03189 0.934082 17.2044 10.2455 17.2044 18.1049Z"
        fill="#E2E3E2"
      />
    </svg>
  );
};

export default ConsumeSVG;
