import React, { InputHTMLAttributes, HTMLInputTypeAttribute } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ContainerInputStyled, InputCheckedStyled, LabelStyled } from './styles';
import { Margin } from '~/components/Margin';
import { Breakpoints } from '~/styles';

interface Props extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  checked: boolean;
  onChange?: (ev: any) => void;
  label?: string;
  children?: React.ReactNode;
}

export const InputChecked: React.FC<Props> = ({
  checked,
  onChange,
  label,
  children,
}) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <ContainerInputStyled>
      <InputCheckedStyled
        type="checkbox"
        name="controlled"
        checked={checked}
        onChange={onChange}
      />
      {label ? (
        <>
          <Margin width={isMobile ? 2 : 4} />
          <LabelStyled>{label}</LabelStyled>
        </>
      ) : (
        <>
          <Margin width={isMobile ? 13 : 20} />
          {children}
        </>
      )}
    </ContainerInputStyled>
  );
};
