import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';

import styled from 'styled-components';

import { Color } from '../../styles';
import { Breakpoints } from '../../styles/breakpoints';
import SelectImage from '~/assets/Images/Select.png';
import SelectBlueImage from '~/assets/Images/select-blue.png';

export interface IPropStyled extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  error?: boolean;
  newUx?: boolean;
}

export const ContainerInputStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LabelError = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  color: ${Color.red};
  text-align: right;
  margin: 8px 0;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 11px;
  }
`;

export const InputStyled = styled.select<IPropStyled>`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  height: 30px;
  color: ${({ error }) => (error ? `${Color.red} !important` : Color.blackSeconde)};
  border: 1px solid ${({ error }) => (error ? Color.red : Color.gray)};
  border-radius: 8px;
  padding-left: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${({ newUx }) => (newUx ? SelectBlueImage : SelectImage)});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: ${({ newUx }) => (newUx ? '15px' : '10px')};
  background-color: transparent;

  :disabled {
    color: ${Color.grayLightSeconde};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 38px;
    padding-left: ${({ newUx }) => (newUx ? '8px' : '12px')};
  }
`;

export const LabelStyled = styled.label`
  color: #004b55;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
`;

export const ContainerIconStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 8px;
  cursor: pointer;
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    bottom: 15px;
  }
`;
