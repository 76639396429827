import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import BankSVG from '~/assets/Icons/Bank';
import { Margin } from '~/components/Margin';
import {
  CloseIconButton,
  Title,
  Text,
  Wrapper,
  ContainerAddress,
  TextAddress,
  ContainerWarning,
  TextWarning,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Input } from '~/components/Input';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import WarningAlertSVG from '~/assets/Icons/WarningAlert';
import { postAutomaticDebitsRequest } from '~/store/modules/automaticDebits/actions';
import InputSelectTwo from '~/components/InputSelectTwo';

interface IProps {
  open: boolean;
  onClose: (status: boolean) => void;
  operation: 'create' | 'view';
}

const ModalDebitAccount: React.FC<IProps> = ({ open, onClose, operation }) => {
  const dispatch = useDispatch();

  const data = useSelector((item: IState) => item.automaticDebits.data);
  const banks = useSelector((item: IState) => item.automaticDebits.dataBanks) || [];
  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck,
  );

  const [bank, setBank] = useState<number | string>();
  const [agency, setAgency] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [disabledAllInputs, setDisabledAllInputs] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!open) {
      setBank(0);
      setAgency('');
      setAccount('');
    }
  }, [open]);

  const findBank = async (data: any) => {
    const bankSearch = await banks.find((bankItem: any) => {
      const bankSubstring = bankItem.label.substring(
        bankItem.label.indexOf('- ') + 2,
        bankItem.label.lenght,
      );
      return bankSubstring === data.bank;
    });

    setBank(bankSearch.label);
  };

  useEffect(() => {
    if (operation === 'view' && data) {
      setDisabledAllInputs(true);

      if (data) {
        findBank(data);
        setAgency(data.agency_number.toString());
        setAccount(data.account_number.toString());
      }
    }
  }, [operation, data]);

  useEffect(() => {
    if (bank && agency && account) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [bank, agency, account]);

  const style = {
    color: Color.grayLightSeconde,
    fontWeight: 500,
  };

  return (
    <Wrapper>
      <CloseIconButton onClick={() => onClose(false)} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <Title>
        <BankSVG />
        <Margin width={11} />
        Autorização para débito automático
      </Title>
      <ContainerAddress>
        <TextAddress>
          Matrícula: <span>{registrationCheck?.code}</span>
        </TextAddress>
        <TextAddress>{registrationCheck?.address_connection}</TextAddress>
      </ContainerAddress>
      <Margin height={16} />
      <Text>
        Preencha abaixo os dados solicitados,{' '}
        <b>obrigatoriamente referentes ao titular da matrícula</b>, para autorização
        do débito automático em conta.
      </Text>
      <Margin height={32} />
      <InputSelectTwo
        name="codeBank"
        label="Código do campo*"
        placeholder={bank?.toString() || 'Código do banco'}
        options={banks}
        onChange={(ev: any) => setBank(Number(ev.value))}
        disabled={disabledAllInputs}
      />

      <Margin height={16} />
      <Input
        value={agency}
        onChange={(ev) => setAgency(formatter.formatarNumber(ev.target.value) || '')}
        label="Agência + dígito*"
        placeholder="Digite o número da agência com dígito."
        name="agency"
        maxLength={7}
        disabled={disabledAllInputs}
        style={style}
      />
      <Margin height={16} />
      <Input
        value={account}
        onChange={(ev) =>
          setAccount(formatter.formatarNumber(ev.target.value) || '')
        }
        label="Conta + dígito*"
        placeholder="Digite o número da conta com dígito."
        name="account"
        maxLength={10}
        disabled={disabledAllInputs}
        style={style}
      />
      <Margin height={50} />
      <ContainerWarning>
        <TextWarning>
          Atenção!
          <br />
          <p>
            O débito em conta não será habilitado automaticamente. É necessário
            aguardar o tempo de confirmação do Banco cadastrado!
          </p>
        </TextWarning>
        <Margin width={6} />
        <div>
          <WarningAlertSVG />
        </div>
      </ContainerWarning>
      {operation === 'create' && (
        <>
          <Margin height={44} />
          <ButtonText
            text="Continuar"
            typeBtn="content"
            backgroundColor={disabled ? Color.grayLightSeconde : Color.green}
            Color={Color.white}
            onClick={() => {
              if (disabled) return;
              dispatch(
                postAutomaticDebitsRequest({
                  account_number: Number(account),
                  agency_number: Number(agency),
                  bank_id: bank,
                }),
              );
              ReactGA.event({
                category: 'Débito em conta',
                action: 'Adesão Débito',
              });
              onClose(false);
            }}
            style={{
              height: '48px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '9px',
            }}
            nextIcon
          />
        </>
      )}
    </Wrapper>
  );
};

export default ModalDebitAccount;
