import { QRCodeSVG } from 'qrcode.react';
import React, { FC } from 'react';

import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface IProps {
  codeValue: string;
}

export const QRCode: FC<IProps> = ({ codeValue }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <QRCodeSVG
      value={codeValue}
      width={isMobile ? 120 : 180}
      height={isMobile ? 120 : 180}
    />
  );
};
