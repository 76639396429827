import React, { FC } from 'react';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Border,
  Content,
  TextOption,
  ContainerOptions,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import TypePayment from './TypePayment';

const DebtNegotiationTypePayment: FC = () => {
  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Tipo de pagamento',
      active: true,
    },
  ];

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Content>
          <Title>Escolha o tipo de pagamento</Title>
        </Content>
        <Border />
        <Content>
          <TextOption>Selecione a opção desejada:</TextOption>
          <ContainerOptions>
            <TypePayment
              title="Parcele na fatura"
              description="Defina o valor a ser pago de entrada e receba o restante parcelado nas próximas faturas."
              data={{
                discountPercentage: 50,
                discountAmount: 5,
              }}
              typeButton="simulation"
            />
            <TypePayment
              hasDiscount
              title="Pague à vista"
              description={
                <>
                  Pague o valor total no PIX, boleto ou em até{' '}
                  <b>12x sem juros no cartão de crédito.</b>
                </>
              }
              data={{
                discountPercentage: 70,
                discountAmount: 7,
              }}
              typeButton="payment"
            />
          </ContainerOptions>
        </Content>
      </Card>
    </Main>
  );
};

export default DebtNegotiationTypePayment;
