import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsMain {
  status: string;
  maxWidthButton?: number;
}

export const Main = styled.div<IPropsMain>`
  min-height: calc(102px - 48px);
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid
    ${({ status }) =>
      status === 'Aguarde'
        ? Color.blueSeconde
        : status === 'Atenção'
        ? Color.yellow
        : Color.green};
  border-radius: 16px;
  padding: 24px;

  div:first-of-type {
    margin-bottom: 24px;
  }

  div:last-of-type {
    margin-left: 24px;

    p {
      color: #000000;
    }

    p:first-of-type {
      color: ${({ status }) =>
        status === 'Aguarde'
          ? Color.blueSeconde
          : status === 'Atenção'
          ? Color.yellow
          : Color.green};
      font-weight: 700;
      font-size: 16px;
      line-height: 122.5%;
    }

    p:last-of-type {
      margin-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 122.5%;
      letter-spacing: -0.025em;
    }

    b,
    a {
      font-weight: 700;
      color: #000000;
    }
  }

  div:has(+ button) {
    margin-right: 15px;
  }

  .button-download {
    max-width: ${({ maxWidthButton }) => `${maxWidthButton}px` || 'inherit'};
    height: 44px;
    padding: 12px 16px;
    background: ${({ status }) =>
      status === 'Aguarde' ? Color.blueSeconde : Color.green};
    border-radius: 8px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      color: ${Color.white};
      font-size: 16px;
      font-weight: 700;
    }

    svg {
      margin-left: 23px;
    }
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: calc(100% - 96px);
    margin: 0 auto;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;

    div:first-of-type {
      margin-bottom: 0;
    }

    div:last-of-type {
      margin-left: 24px;
    }

    div:has(+ button) {
      margin-right: 15px;
    }

    .button-download {
      max-width: 231px;
      margin-top: 0;
      margin-left: auto;

      div {
        margin: 0 12px 0 0;
      }
    }
  }
`;
