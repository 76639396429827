import styled from 'styled-components';
import { Column, Row, TitleCard } from '../styles';
import { Color, Breakpoints } from '~/styles';
import { Text } from '~/components/TextInfo';

interface IPropsPaymentGroupCard {
  color: string;
  fullHeight: boolean;
}

export { Column, Row, TitleCard };

export const Container = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 auto;

  @media (max-width: 437px) {
    width: 99vw;
  }
`;

export const ContentFaturaFacil = styled.div`
  height: auto;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.laptop.min}px) {
    width: 83vw;
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }
`;

export const CardColumnDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  max-width: 400px;

  @media (max-width: 1250px) {
    max-width: 350px;
    width: fit-content;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: none;
    align-items: center;
    justify-content: center;
  }
`;

export const CardColumnDescSlick = styled(CardColumnDesc)`
  display: block;
  max-width: 650px;
  width: fit-content;

  @media (max-width: 1200px) {
    width: 58%;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: 100%;
  }
  @media (max-width: 437px) {
    width: 100vw;
  }
  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    padding: 62px 0 0 0;
  }
`;

export const DescriptionCard = styled(Text).attrs({
  color: Color.blueSeconde,
  size: 18,
  weight: 500,
})`
  line-height: 1.4;
  text-align: justify;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    font-size: 10px;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 99%;
  }
`;

export const Main = styled.div`
  padding: 40px 24px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 40px;
  align-items: center;
  border: 1px dashed;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div``;

export const Heading = styled.p`
  color: ${Color.blueIntense};
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  color: ${Color.blueSeconde};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-align: justify;
`;

export const TitlePaymentGroupCard = styled.h1`
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: ${Color.blueIntense};
  margin-bottom: 24px;

  @media (min-width: ${Breakpoints.mobile.max}px) {
    text-align: left;
    max-width: 350px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 300px;
    font-size: 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 362px;
  }
`;

export const PaymentGroupCard = styled.div<IPropsPaymentGroupCard>`
  min-height: ${({ fullHeight }) => (fullHeight ? '120px' : 'calc(208px - 42px)')};
  background: #f2f2f2;
  border-top: 8px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 17px 8px 17px 24px;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 122.5%;
    display: flex;
    align-items: center;
    color: ${({ color }) => color};

    span {
      svg {
        width: 45px;
        margin-right: 24px;
      }
    }

    :last-of-type {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: ${Color.blueIntense};

      svg {
        display: none;
        margin-right: 25px;
      }
    }

    div {
      :last-of-type {
        margin-top: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.blueIntense};
      }

      button {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.blueSeconde};
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${Breakpoints.mobile.max}px) {
    max-width: calc(350px - 32px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(300px - 32px);
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: calc(362px - 32px);

    p {
      :last-of-type {
        svg {
          display: inherit;
          visibility: hidden;
        }
      }
    }
  }
`;

export const PaymentGroupButton = styled.button`
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.white};
  background: ${Color.green};
  border-radius: 8px;
  margin-top: 17px;
  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
  cursor: pointer;

  @media (min-width: ${Breakpoints.mobile.max}px) {
    max-width: 350px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 300px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 362px;
    height: 48px;
  }
`;
