import styled, { css } from 'styled-components';
import { COLORS } from '~/utils/constants';

const colors = {
  secondary: css`
    color: var(--brand-secondary);
  `,
  gray: css`
    color: var(--500);
  `,
};

const variants = {
  underline: css`
    text-decoration: underline;
  `,
};

export const MyButtonLink = styled.button<{
  variant?: 'underline';
  color?: 'secondary' | 'gray';
}>`
  display: inline-block;
  background: none;
  font-weight: 500;
  line-height: inherit;
  font-size: inherit;
  color: var(--brand-highlight);
  &:hover {
    cursor: pointer;
  }
  ${({ color }) => (color ? colors[color] : null)}
  ${({ variant }) => (variant ? variants[variant] : null)}

  :disabled {
    color: ${COLORS.gray400};
    cursor: auto;
  }
`;
