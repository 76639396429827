import config from '~/configs/app.config';
import Geocode from 'react-geocode';

interface Location {
  address: string;
  city: string;
  state: string;
  country: string;
}

export const getCoords = async () => {
  const pos: GeolocationPosition = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  return {
    latitude: pos.coords.latitude,
    longitude: pos.coords.longitude,
  };
};

export const getLocation = async (latitude: number, longitude: number) => {
  return new Promise<Location>((resolve) => {
    const { GOOGLE_GEOCODING_API } = config;

    Geocode.setApiKey(GOOGLE_GEOCODING_API.API_KEY);
    Geocode.setLanguage('pt');
    Geocode.setRegion('br');

    Geocode.fromLatLng(latitude.toString(), longitude.toString()).then(
      (response) => {
        const address = response.results[0].formatted_address;

        let city;
        let state;
        let country;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'administrative_area_level_2':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'country':
                country = response.results[0].address_components[i].long_name;
                break;
              default:
                null;
            }
          }
        }
        resolve({
          address,
          city,
          state,
          country,
        });
      },
    );
  });
};
