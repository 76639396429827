import React, { Dispatch, FC, SetStateAction } from 'react';
import { ButtonDetail, ContentRow, Main, Text, TextHeader } from './styles';
import { Color } from '~/styles';
import { mockInstallments } from './mock';

interface IOpenInstallmentsProps {
  setType: Dispatch<
    SetStateAction<'open_installments' | 'installment_details' | 'is_paid'>
  >;
}

const OpenInstallments: FC<IOpenInstallmentsProps> = ({ setType }) => {
  const getBackgroundColor = (index: number) => {
    return index % 2 === 0 ? '#F8F9FA' : Color.white;
  };

  const handleDetail = (installments: any) => {
    const typeDetail =
      installments?.situacao === 'Faturada' ? 'installment_details' : 'is_paid';

    setType(typeDetail);
  };

  return (
    <Main>
      <ContentRow backgroundColor="#e9ecef">
        <TextHeader>Parcela</TextHeader>
        <TextHeader>Valor</TextHeader>
        <TextHeader>Situação</TextHeader>
        <TextHeader>Ref. faturamento</TextHeader>
      </ContentRow>
      {mockInstallments.map((installments, index) => (
        <ContentRow
          key={index && index.toString()}
          backgroundColor={
            index === 0 ? Color.greenLight : getBackgroundColor(index)
          }
        >
          <Text>{installments?.parcela}</Text>
          <Text>{installments?.valor}</Text>
          {installments?.situacao === 'Faturada' ? (
            <ButtonDetail type="button" onClick={() => handleDetail(installments)}>
              {installments?.situacao}
            </ButtonDetail>
          ) : (
            <Text>{installments?.situacao}</Text>
          )}
          <Text>{installments?.referenciaFaturamento}</Text>
        </ContentRow>
      ))}
    </Main>
  );
};

export default OpenInstallments;
