import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 22px 54px 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 118px 20px 19px 20px;
  }
`;

export const ContainerWallet = styled.div`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  }
`;

export const Header = styled.div`
  height: 81px;
  background: ${Color.green};
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: ${Color.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 112px;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 32px 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 23px 40px 70px 36px;
  }
`;

export const TitleCard = styled.h2`
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: ${Color.blueIntense};
`;

export const ContainerPaymentMethods = styled.div`
  width: calc(100% - 27px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 11px 5px 16px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 528px;
    justify-content: flex-start;
    padding: 49px 0 23px 0;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #c7c9c7;
`;

export const ContainerCard = styled.div`
  width: 100%;
`;

export const ContainerCardTemplate = styled.div`
  height: 172px;
  padding: 22px 22px 14px 36px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 202px;
    padding: 0;
    margin: 0 auto;
    max-width: 370px;
  }
`;

export const ContainerFormCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 528px;
  }
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
