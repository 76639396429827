import React, { FC } from 'react';
import { PaginationDefault } from './PaginationDefault';
import { PaginationStart } from './PaginationStart';
import { PaginationEnd } from './PaginationEnd';
import { PaginationMiddle } from './PaginationMiddle';

export interface IResponsePagination {
  totalItens: number;
  maxPages: number;
  page: number;
}

interface IProps {
  setPage: (e: number) => void;
  pagination: IResponsePagination;
  chunkPagesSize: number;
  isDefault?: boolean;
}

export const PaginationResolver: FC<IProps> = ({
  setPage,
  pagination,
  chunkPagesSize,
  isDefault,
}: IProps) => {
  const startEndPages = pagination.maxPages - chunkPagesSize + 1;
  const limitDefaultPages = chunkPagesSize + 2;

  if (isDefault || pagination.maxPages < limitDefaultPages)
    return <PaginationDefault pagination={pagination} setPage={setPage} />;

  if (pagination.page < chunkPagesSize)
    return (
      <PaginationStart
        pagination={pagination}
        setPage={setPage}
        chunkPagesSize={chunkPagesSize}
      />
    );

  if (pagination.page > startEndPages)
    return (
      <PaginationEnd
        pagination={pagination}
        setPage={setPage}
        chunkPagesSize={chunkPagesSize}
      />
    );

  return <PaginationMiddle pagination={pagination} setPage={setPage} />;
};
