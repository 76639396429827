import { IResponse } from '~/store/modules/toggles/types';

export const checkToggle = async (toggles: IResponse[], toggleName: string) => {
  const pixExists = await toggles.find(
    (item: IResponse) => item.featureToggle === toggleName,
  );

  if (pixExists) {
    if (pixExists.toggle) {
      return true;
    }
    return false;
  }
  return false;
};
