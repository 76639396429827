/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Container, Content, IconSearch } from './styles';
import SearchSVG from '~/assets/Icons/Search';

interface IProps {
  onChange: (e: any) => void;
}

const InputSearch = ({ onChange }: IProps) => {
  return (
    <Container>
      <Content onChange={(e) => onChange(e)} placeholder="Pesquise por protocolo" />
      <IconSearch>
        <SearchSVG width="15px" heigth="15px" />
      </IconSearch>
    </Container>
  );
};

export default InputSearch;
