/* eslint-disable array-callback-return */
import {
  IRequestCreateLackWater,
  IRequestPostAttachments,
  IRequestPostCreate,
  IRequestQueryOrders,
  IResponsePostAttachments,
} from '~/store/modules/orderServices/types';
import { api } from './api';

const url = 'v3/order-services';

function getServiceOrdersService(enrollment: string, params?: IRequestQueryOrders) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrders = async () => {
        const response = await api.get(`${url}/enrollment/${enrollment}`, {
          params: {
            ...params,
          },
        });
        return response.data;
      };
      resolve(getServiceOrders());
    } catch (error) {
      reject(error);
    }
  });
}

function getServiceOrderTypesService(operation: number) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderTypes = async () => {
        const response = await api.get(`${url}/operation/${operation}`);
        return response.data;
      };
      resolve(getServiceOrderTypes());
    } catch (error) {
      reject(error);
    }
  });
}

function postServiceOrderAttachmentsService(
  data: IRequestPostAttachments[] | IResponsePostAttachments[],
) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderTypes = async () => {
        const formData = new FormData();

        await data.map((item: any) => {
          return formData.append('orderServicesImages[]', item.file);
        });

        const response = await api.post(`${url}/attachments`, formData, {
          timeout: 120000,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      };
      resolve(getServiceOrderTypes());
    } catch (error) {
      reject(error);
    }
  });
}

function postServiceOrderCreateService(data: IRequestPostCreate) {
  return new Promise((resolve, reject) => {
    try {
      const postServiceOrderCreate = async () => {
        const response = await api.post(`${url}`, data, {
          timeout: 360000,
        });
        return response.data;
      };

      resolve(postServiceOrderCreate());
    } catch (error) {
      reject(error);
    }
  });
}

function getOrderValidateLackWaterService(enrollment: string) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderValidateLackWater = async () => {
        const response = await api.get(`${url}/validateLackWater/${enrollment}`);
        return response.data;
      };
      resolve(getServiceOrderValidateLackWater());
    } catch (error) {
      reject(error);
    }
  });
}

function getOrderInputsLackWaterService(
  identificadorInput: 'FALTA D AGUA',
  operationId: number,
) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderInputsLackWater = async () => {
        const response = await api.get(
          `v3/options/${identificadorInput}/operation/${operationId}`,
        );
        return response.data;
      };
      resolve(getServiceOrderInputsLackWater());
    } catch (error) {
      reject(error);
    }
  });
}

function postOrderCreateLackWaterService(data: IRequestCreateLackWater) {
  return new Promise((resolve, reject) => {
    try {
      const postOrderCreateLackWater = async () => {
        const response = await api.post(`${url}/createLackWater`, data);
        return response.data;
      };
      resolve(postOrderCreateLackWater());
    } catch (error) {
      reject(error);
    }
  });
}

function getOrderAddressLackWaterService(enrollment: string) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderAddressLackWater = async () => {
        const response = await api.get(`v3/enrollments/${enrollment}/me`);
        return response.data;
      };
      resolve(getServiceOrderAddressLackWater());
    } catch (error) {
      reject(error);
    }
  });
}

function getOrderDownloadLackWaterService(caseNumber: string, enrollment: string) {
  return new Promise((resolve, reject) => {
    try {
      const getServiceOrderDownloadLackWater = async () => {
        const response = await api.get(
          `${url}/caseNumber/${caseNumber}/enrollment/${enrollment}/download`,
          {
            responseType: 'blob',
          },
        );
        return response.data;
      };
      resolve(getServiceOrderDownloadLackWater());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getServiceOrdersService,
  getServiceOrderTypesService,
  postServiceOrderAttachmentsService,
  postServiceOrderCreateService,
  getOrderValidateLackWaterService,
  getOrderInputsLackWaterService,
  postOrderCreateLackWaterService,
  getOrderAddressLackWaterService,
  getOrderDownloadLackWaterService,
};
