import styled, { css } from 'styled-components';
import { Color } from '~/styles';
import { BREAKPOINTS, COLORS } from '~/utils/constants';

interface IPropsContainerLoginSession {
  openSession: boolean;
  authenticated?: boolean;
}

interface IPropsHeader {
  fullHeader?: boolean;
}

export const ContainerHeader = styled.header`
  padding-inline: 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  top: 0;
  z-index: 777;
  background: ${COLORS.secondary};

  @media (min-width: ${BREAKPOINTS.mobilePortrait}) {
    padding-inline: 20px;
  }

  @media (min-width: ${BREAKPOINTS.mobileLandscape}) {
    position: sticky;
  }
`;

export const ContainerTop = styled.div`
  width: 100%;
  height: 64px;
`;

export const ContainerBottom = styled.div`
  margin-inline: -20px;
  padding-inline: 20px;
  box-sizing: border-box;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ContentHeader = styled.div<IPropsHeader>`
  height: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  position: relative;
  align-items: center;

  .menu-dropdown,
  .button-icon,
  .button-logo {
    background: none;
    cursor: pointer;
  }
  .button-icon {
    svg {
      rotate: 180deg;
    }
  }
`;

export const ContainerDigitalClient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 28px;

  div {
    height: 32px;
    margin: 0 28px;
    border: 1px solid #c7c9c7;
  }

  span {
    font-size: 16px;
    font-weight: 900;
    color: ${Color.white};
  }
`;

export const ContainerLoginSession = styled.div<IPropsContainerLoginSession>`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  z-index: 999;

  span {
    width: 1px;
    height: 32px;
    margin: 0 12px;
    background: ${Color.grayLightSeconde};
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 32px;

    p {
      line-height: 17px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: ${Color.grayLightSeconde};
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 14px;
      color: ${Color.white};
    }
  }

  .button-icon,
  #notifications-button {
    background: none;
    cursor: pointer;
  }

  #notifications-button {
    position: relative;
    span {
      width: 20px;
      height: auto;
      position: absolute;
      z-index: 9999;
      top: 0;
      right: 0;
      background: #872d2d;
      border-radius: 2px;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      color: ${Color.white};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 0px;
      margin: 0;
    }
  }

  ${({ openSession }) =>
    openSession
      ? css`
          .button-icon {
            svg {
              transition: 0.5s;
              transform: rotate(180deg);
            }
          }
        `
      : css`
          .button-icon {
            svg {
              transition: 0.5s;
              transform: rotate(0deg);
            }
          }
        `}
`;

export const ContainerMenu = styled.div`
  /* width: 90%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  .button-icon {
    background: none;
    cursor: pointer;
  }

  p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3;
    text-align: center;
    color: ${Color.blueIntense};
    cursor: pointer;
  }

  p:first-of-type {
    font-weight: 700;
  }

  @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
    > * {
      display: none;
    }
    > :nth-child(1) {
      display: revert;
    }
  }
`;

export const BorderBlur = styled.div`
  height: 17px;
  border: 1px solid #c7c9c7;
  filter: blur(2px);
  opacity: 0.5;
`;

export const Backdrop = styled.div<{ open: boolean }>`
  &:before {
    content: '';
  }
  &:after {
    content: '';
  }

  padding-inline: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 900;
  display: ${(p) => (p.open ? 'grid' : 'none')};
  grid-template-columns: 1fr minmax(auto, 1170px) 1fr;
  isolation: isolate;

  .ballon {
    z-index: 9999;
    position: relative;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;
