import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

const InfoIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#004B55" />
      <path
        d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 48L40 38"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0117 32L39.9938 32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { InfoIcon };
