import { Reducer } from 'redux';
import {
  RepositoriesTypesSocialTariff,
  IRepositoriesStateSocialTariff,
} from './types';

const INITIAL_STATE: IRepositoriesStateSocialTariff = {
  data: null,
  dataAttachments: null,
  dataCreate: null,
  error: null,
  errorTypes: null,
  errorAttachments: null,
  loading: false,
  loadingTypes: false,
  loadingAttachments: false,
  successCreate: false,
  needRenovation: false,
  loadingRenovation: false,
  viewedBanner: false,
};

const reducer: Reducer<IRepositoriesStateSocialTariff, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        successCreate: false,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_REQUEST:
      return {
        ...state,
        loadingAttachments: true,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS:
      return {
        ...state,
        dataCreate: action.payload.data,
        successCreate: true,
        loadingAttachments: false,
        errorAttachments: null,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_FAILURE:
      return {
        ...state,
        errorAttachments: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingAttachments: false,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_CLOSE:
      return {
        ...state,
        errorAttachments: null,
        successCreate: false,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_REQUEST:
      return {
        ...state,
        loadingRenovation: true,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS:
      return {
        ...state,
        needRenovation: action.payload.needRenovation,
        loadingRenovation: false,
      };
    case RepositoriesTypesSocialTariff.SOCIAL_TARIFF_VIEWED_BANNER_METHOD_SUCCESS:
      return {
        ...state,
        viewedBanner: true,
      };

    default:
      return state;
  }
};

export default reducer;
