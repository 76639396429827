import styled from 'styled-components';

import { Color } from '~/styles';

export const Colors = Color;

export const TableContainer = styled.table`
  width: 98%;
  margin: auto;
`;

export const TheadContainer = styled.div`
  border-bottom: 1px solid #000000;

  tr {
    width: 100%;
    display: block;
  }

  th {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #000000;
    padding-bottom: 15px;
  }

  th + th {
    text-align: start;
  }
`;

export const TbodyContainer = styled.div`
  width: 100%;

  tr:hover {
    background: #d9d9d9;
    button {
      div {
        background: ${Color.green};
      }
      svg {
        path {
          stroke: ${Color.white};
        }
      }
    }
  }

  tr {
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    position: relative;
    height: 65px;
    button {
      cursor: pointer;
      border: none;
      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto;
    }
  }

  td {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${Color.realBlack};
    border-bottom: 1px solid rgba(71, 92, 102, 0.1);
    position: relative;
  }
  td + td {
    padding: 16px 0px;
    justify-content: start;
  }
`;

export const AlertIconContainer = styled.div`
  background: #e9ecef;
  border-radius: 8px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertText = styled.p`
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0px 20px;
  text-align: left;
`;

interface IBorderCase {
  color?: string;
}

export const BorderCase = styled.div<IBorderCase>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  border-radius: 8px 0 0 8px;
  background-color: ${({ color }) => color};
`;
