import {
  IRequestInvoiceCreateGroup,
  IRequestInvoiceGroupPayment,
  IRequestInvoiceInstallmentGroup,
  IRequestQueryInvoices,
} from '~/store/modules/enrollmentsInvoices/types';
import { api } from './api';

function getEnrollmentsInvoicesService(
  enrollmentId: string,
  params?: IRequestQueryInvoices,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoices: any = async () => {
        const response = await api.get(`v3/enrollments/${enrollmentId}/invoices`, {
          params: {
            ...params,
          },
        });

        return response.data;
      };

      resolve(getEnrollmentsInvoices());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoicesDetailService(
  enrollmentId: string,
  invoiceId: number,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoicesDetail: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/invoices/${invoiceId}`,
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoicesDetail());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoicesDownloadService(
  enrollmentId: string,
  invoiceId: number,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoicesDownload: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/invoices/${invoiceId}/download`,
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoicesDownload());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoicesMaturityDaysService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoicesMaturityDays: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/maturity-days`,
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoicesMaturityDays());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoicesMaturityDaysAlternateService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoicesMaturityDaysAlternate: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/maturity-days/alternate`,
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoicesMaturityDaysAlternate());
    } catch (error) {
      reject(error);
    }
  });
}

function putEnrollmentsInvoicesMaturityDaysAlternateService(
  enrollmentId: string,
  day: number,
) {
  return new Promise((resolve, reject) => {
    try {
      const putEnrollmentsInvoicesMaturityDaysAlternate: any = async () => {
        const response = await api.put(
          `v3/enrollments/${enrollmentId}/maturity-days/alternate`,
          { day },
        );

        return response.data;
      };

      resolve(putEnrollmentsInvoicesMaturityDaysAlternate());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsTypesInvoicesService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsTypesInvoices: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/types-invoices`,
        );

        return response.data;
      };

      resolve(getEnrollmentsTypesInvoices());
    } catch (error) {
      reject(error);
    }
  });
}

function putEnrollmentsTypesInvoicesService(
  enrollmentId: string,
  typeInvoiceId: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const putEnrollmentsTypesInvoices: any = async () => {
        const response = await api.put(
          `v3/enrollments/${enrollmentId}/types-invoices/${typeInvoiceId}`,
        );

        return response.data;
      };

      resolve(putEnrollmentsTypesInvoices());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsInvoiceCreateGroupService(
  invoices: IRequestInvoiceCreateGroup,
  registration: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsInvoiceCreateGroup: any = async () => {
        const response = await api.post(
          `v3/enrollments/${registration}/invoices/group`,
          {
            ...invoices,
          },
        );

        return response.data;
      };

      resolve(postEnrollmentsInvoiceCreateGroup());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoiceInstallmentGroupService({
  invoice_group_id,
}: IRequestInvoiceInstallmentGroup) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoiceInstallmentGroup: any = async () => {
        const response = await api.get(`v3/enrollments/${invoice_group_id}/group`);

        return response.data;
      };

      resolve(getEnrollmentsInvoiceInstallmentGroup());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsInvoiceGroupPaymentService(
  data: IRequestInvoiceGroupPayment,
  enrollmentId: string,
  { invoice_group_id }: IRequestInvoiceInstallmentGroup,
) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsInvoiceGroupPayment: any = async () => {
        const response = await api.post(
          `v3/enrollments/${enrollmentId}/invoice/group/${invoice_group_id}/payment`,
          {
            ...data,
          },
        );

        return response.data;
      };

      resolve(postEnrollmentsInvoiceGroupPayment());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsInvoiceGroupPaymentVoucherService(
  enrollmentId: string,
  { invoice_group_id }: IRequestInvoiceInstallmentGroup,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoiceGroupPaymentVoucher: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/group/${invoice_group_id}/payment/voucher`,
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoiceGroupPaymentVoucher());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsInvoicesService,
  getEnrollmentsInvoicesDetailService,
  getEnrollmentsInvoicesDownloadService,
  getEnrollmentsInvoicesMaturityDaysService,
  getEnrollmentsInvoicesMaturityDaysAlternateService,
  putEnrollmentsInvoicesMaturityDaysAlternateService,
  getEnrollmentsTypesInvoicesService,
  putEnrollmentsTypesInvoicesService,
  postEnrollmentsInvoiceCreateGroupService,
  getEnrollmentsInvoiceInstallmentGroupService,
  postEnrollmentsInvoiceGroupPaymentService,
  getEnrollmentsInvoiceGroupPaymentVoucherService,
};
