import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  gap: 8px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 0px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid ${Color.gray};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
    flex-basis: 40%;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      flex-basis: 10%;
    }

    p {
      flex-basis: 30%;
    }
  }
`;

interface IPropsStatusInvoice {
  color: string;
}

export const ContainerInvoice = styled.div<IPropsStatusInvoice>`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    p {
      flex-basis: 30%;
    }
  }
`;

export const TextInvoice = styled.p<{ color?: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color || Color.blueIntense};
`;

export const TextStatusInvoice = styled.p<IPropsStatusInvoice>`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.red};
  color: ${({ color }) => color};
`;

export const Border = styled.span<IPropsStatusInvoice>`
  height: auto;
  align-self: stretch;
  width: 4px;
  background: ${({ color }) => color};
  border-radius: 8px;
  margin-right: 8px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: 100%;
    max-height: 48px;
    margin-right: 16px;
    align-self: inherit;
  }
`;

export const ContainerEmpty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerMobileInvoice = styled.div<IPropsStatusInvoice>`
  width: 100%;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerInfoMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TextTypeMobile = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
`;

export const TextAmountMobile = styled.p<IPropsStatusInvoice>`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${({ color }) => color};
`;

export const TextDueMobile = styled.p<IPropsStatusInvoice>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({ color }) => color};
`;
