import { all, takeLatest } from 'redux-saga/effects';

import { RepositoriesTypesDpaDpe } from './dpadpe/types';
import {
  downloadSlip,
  getCases,
  getOperations,
  getSlips,
  listCases,
  listSlips,
  listStatuses,
  registerViability,
  updateViability,
} from './dpadpe/sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_LISTCASES_REQUEST, listCases),
    takeLatest(
      RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_REQUEST,
      registerViability,
    ),
    takeLatest(
      RepositoriesTypesDpaDpe.DPADPE_UPDATE_VIABILITY_REQUEST,
      updateViability,
    ),
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_REQUEST, getCases),
    takeLatest(
      RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_REQUEST,
      getOperations,
    ),
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_REQUEST, listSlips),
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_REQUEST, getSlips),
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_REQUEST, downloadSlip),
    takeLatest(RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_REQUEST, listStatuses),
  ]);
}
