import styled from 'styled-components';
import { Color } from '~/styles';

interface IPropsCard {
  isBorder?: boolean;
}

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1010px;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 90px 0 296px 0;
`;

export const Card = styled.div<IPropsCard>`
  width: 100%;
  height: 576px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  h1 {
    height: 113px;
    background: ${Color.blueSeconde};
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${Color.white};
    border-radius: 24px 24px 0px 0px;
  }

  div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: ${Color.grayLightSeconde};
      text-align: center;

      button {
        font-weight: 300;
        color: ${Color.blueSeconde};
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
`;
