/* eslint-disable import/no-unresolved */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';

import { Description } from '../components/Description';
import { Header } from '../components/Header';
import { TitleHeader } from '../components/text';
import InitialForm from '../components/InitialStep';

import { Container, Main, ContainerBreadCrumb } from './styles';
import SecondStep from '../components/SecondeStep';
import ThirdStep from '../components/ThirdStep';

import Anexos from '../components/Anexos';
import { Margin } from '~/components/Margin';
import LoadingComponent from '~/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  setAlreadyTriedSend,
  setCaseValues,
  setSelectedFeature,
} from '~/store/modules/dpadpeDataMenager/actions';
import { IFieldObject } from '~/store/modules/dpadpeDataMenager/types';
import {
  registerCaseRequest,
  updateCaseRequest,
} from '~/store/modules/dpadpe/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { SignupSchemaLabel } from '../Schemas';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import CuiabaCharacteristics from '../characteristics/cuiaba.json';
import DefaultCharacteristics from '../characteristics/default.json';
import ReviewFields from '../components/ReviewFields';
import { validateEmptyInputsFormCpae } from '~/utils/validation';
import { Link } from 'react-router-dom';
import { Separator } from '../components/TitleStep/styles';
import ButtonBar from '../components/ButtonBar';
import { BreadCrumb } from '~/components/BreadCrumb';
import {
  DpaDpeLocationsEnum,
  DpaDpeLocationsLabelEnum,
  DpaDpeLocationsLabelMap,
} from '~/enums/dpadpeEnum';

export interface ICharacter {
  A: {
    key: string;
    label: string;
    type: string;
    fields: any;
  };
  B: {
    key: string;
    label: string;
    type: string;
    fields: any;
  };
  C: {
    key: string;
    label: string;
    type: string;
    fields: any;
  };
  D: {
    key: string;
    label: string;
    type: string;
    fields: any;
  };
}

const FormDpaDpeWeb: FC = () => {
  const [valuesCharacters, setValuesCharacters] = useState<IFieldObject[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const isEntrepreneur = useSelector(
    (item: IState) => item.enrollmentsAuth.isEntrepreneur,
  );
  const selectedFeature = useSelector(
    (item: IState) => item.dpadpeDataMenager.selectedFeature,
  );
  const loading = useSelector((item: IState) => item.dpadpe.loading);
  const caseValues = useSelector(
    (item: IState) => item.dpadpeDataMenager.caseValues,
  );
  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);
  const dpadpeLocationData = useSelector(
    (item: IState) => item.dpadpe.dpadpeLocation,
  );

  const dpadpeLocation = caseDetail?.selectedCase
    ? DpaDpeLocationsLabelMap[caseDetail?.enterprise?.city] ||
      DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.RIO]
    : dpadpeLocationData;

  const characteristics = {} as any;

  characteristics[DpaDpeLocationsEnum.CUIABA] = CuiabaCharacteristics;
  characteristics[DpaDpeLocationsEnum.MIGUEL] = DefaultCharacteristics;
  characteristics[DpaDpeLocationsEnum.PATY] = DefaultCharacteristics;
  characteristics[DpaDpeLocationsEnum.RIO] = DefaultCharacteristics;

  const characteristic =
    characteristics[dpadpeLocation] || characteristics[DpaDpeLocationsEnum.RIO];

  useEffect(() => {
    if (!!caseDetail?.selectedCase && caseDetail.status !== `Revisar documentos`)
      setIsDisabled(true);
  }, [caseDetail]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setCaseValues({
        ...caseValues,
        characteristic_type: characteristic.A.label,
      }),
    );
    dispatch(setSelectedFeature(characteristic.A, 'object'));
  }, []);

  const getSelect = useCallback(
    (e: any) => {
      const { type } = JSON.parse(e.target.value);

      dispatch(
        setCaseValues({
          ...caseValues,
          characteristic_type: JSON.parse(e.target.value).label,
        }),
      );
      dispatch(setSelectedFeature(JSON.parse(e.target.value), type));
    },
    [dispatch],
  );

  const touched = {};

  const scrollTop = () => {
    document.getElementById('root')?.scrollTo({ top: 200, behavior: 'smooth' });
  };

  const scrollCharacterics = () => {
    document.getElementById('root')?.scrollTo({ top: 1090, behavior: 'smooth' });
  };

  useEffect(
    () => () => {
      dispatch(setAlreadyTriedSend({ setAlreadyTriedSend: false }));
    },
    [],
  );

  const tableLabels = {} as any;

  tableLabels[DpaDpeLocationsEnum.CUIABA] = 'Tabela de valores CPAE Cuiabá.';
  tableLabels[DpaDpeLocationsEnum.MIGUEL] = 'Tabela de valores CPAE Rio de Janeiro.';
  tableLabels[DpaDpeLocationsEnum.PATY] = 'Tabela de valores CPAE Rio de Janeiro.';
  tableLabels[DpaDpeLocationsEnum.RIO] = 'Tabela de valores CPAE Rio de Janeiro.';

  const tableLabel =
    tableLabels[dpadpeLocation] || tableLabels[DpaDpeLocationsEnum.RIO];

  return (
    <Container>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Margin height={24} />
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () =>
                isEntrepreneur
                  ? Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)
                  : Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Viabilidade de empreendimento',
              active: true,
              onClick: () => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY),
            },
            {
              label: 'CPAE',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Margin height={24} />
      <Main>
        <Header>
          <TitleHeader>
            Consulta de possibilidade de abastecimento (CPAE)
          </TitleHeader>
        </Header>
        <Separator />

        {caseDetail &&
        caseDetail.status &&
        caseDetail.status === `Revisar documentos` ? (
          <ReviewFields
            fields={caseDetail.review_fields}
            comments={caseDetail.review_comments}
          />
        ) : (
          <>
            <Description>
              {dpadpeLocation === DpaDpeLocationsEnum.CUIABA
                ? `A CPAE é um processo obrigatório para clientes que possuem novos
          empreendimentos e já existentes, que teve ou terá aumento no consumo. Esse
          processo é aberto para que os grandes clientes possam verificar se no local
          do empreendimento existe possibilidade de abastecimento e esgotamento
          através da concessionária ou se deverão executar algum tipo de Obrigação do
          Cliente para que esse seja feito.`
                : `A CPAE é um processo obrigatório para clientes que possuem empreendimentos
            com mais de 10 economias, esses são chamados de clientes Iguá +. Já para
            clientes que possuem menos de dez economias a abertura de CPAE é
            facultativa. Esse processo é aberto para que os grandes clientes possam
            verificar se no local do empreendimento existe possibilidade de
            abastecimento e esgotamento através da concessionária ou se deverão
            executar algum tipo de Obrigação do Cliente para que esse seja feito.`}
            </Description>
            <Description>
              Após o preenchimento do formulário, a cobrança será enviada conforme a{' '}
              <Link to={RouteName.SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE}>
                {tableLabel}
              </Link>
            </Description>
          </>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            owner: caseDetail?.selectedCase ? caseDetail?.enterprise?.owner : '',
            email: caseDetail?.selectedCase ? caseDetail?.enterprise?.email : '',
            cpf_cnpj: caseDetail?.selectedCase
              ? caseDetail?.enterprise?.cpf_cnpj
              : '',
            cep: caseDetail?.selectedCase ? caseDetail?.enterprise?.cep : '',
            street: caseDetail?.selectedCase ? caseDetail?.enterprise?.street : '',
            neighborhood: caseDetail?.selectedCase
              ? caseDetail?.enterprise?.neighborhood
              : '',
            number: caseDetail?.selectedCase ? caseDetail?.enterprise?.number : '',
            complement: caseDetail?.selectedCase
              ? caseDetail?.enterprise?.complement
              : '',
            city: caseDetail?.selectedCase ? caseDetail?.enterprise?.city : '',
            state: caseDetail?.selectedCase ? caseDetail?.enterprise?.state : '',
            name: caseDetail?.selectedCase ? caseDetail?.interested?.name : '',
            qualification: caseDetail?.selectedCase
              ? caseDetail?.interested?.qualification
              : '',
            address: caseDetail?.selectedCase ? caseDetail?.interested?.address : '',
            interestedAddress: caseDetail?.selectedCase
              ? caseDetail?.interested?.address
              : '',
            interestedEmail: caseDetail?.selectedCase
              ? caseDetail?.interested?.email
              : '',
            phone: caseDetail?.selectedCase ? caseDetail?.interested?.phone : '',
            identificationDocument: null,
            locationPlan: null,
            situationPlan: null,
            locationAndActivityAnalysis: null,
            lots_quantity: '',
            lot_area: '',
            number_unities_by_lot: '',
            number_unities_by_building: '',
            number_stores: '',
            number_commercial_rooms: '',
            quantity_edifications: '',
            built_area: '',
            number_rooms_unity: '',
            dependecies: '',
            quantity_apartments_unity: '',
            type: '',
            quantity: '',
            number_rooms: '',
            quantity_apartments: '',
            business_structure: '',
            industrial_process_predicted_discharge: '',
            industrial_process_predicted_consume: '',
            number_employees_day: '',
            business_structure_enterprise: '',
            number_consumers_day: '',
          }}
          validationSchema={SignupSchemaLabel}
          onSubmit={(values) => {
            const hasEmptyInput = validateEmptyInputsFormCpae();
            if (hasEmptyInput) {
              dispatch(setAlreadyTriedSend({ setAlreadyTriedSend: true }));
              scrollCharacterics();
              return;
            }

            const dataCase = {
              enterprise: {
                owner: values?.owner || '',
                email: values?.email || '',
                cpf_cnpj: formatter.formatarNumber(values?.cpf_cnpj) || '',
                cep: formatter?.formatarNumber(values?.cep) || '',
                street: values?.street || '',
                neighborhood: values?.neighborhood || '',
                number: values?.number || '',
                complement: values?.complement || '',
                city: values?.city || '',
                state: values?.state || '',
              },
              interested: {
                name: values?.name || '',
                qualification: values?.qualification || '',
                address: values?.interestedAddress || '',
                email: values?.interestedEmail || '',
                phone: formatter?.formatarNumber(values?.phone) || '',
              },
              files: {
                identification_document_file: values?.identificationDocument,
                location_plan_file: values?.locationPlan,
                situation_plan_file: values?.situationPlan,
                location_and_activity_analysis: values?.locationAndActivityAnalysis,
              },
            };

            if (
              !!caseDetail?.selectedCase &&
              caseDetail?.status === `Revisar documentos`
            ) {
              dispatch(updateCaseRequest({ ...dataCase, case_id: caseDetail.id }));
              return;
            }

            dispatch(registerCaseRequest({ ...dataCase }));
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            isValid,
            isSubmitting,
          }: FormikProps<any>) => {
            if (isSubmitting && !isValid) scrollTop();

            return (
              <Form name="cpaeForm" style={{ flex: 1 }}>
                <InitialForm
                  values={values}
                  setFieldValue={setFieldValue}
                  isDisabled={isDisabled}
                />
                <SecondStep
                  values={values}
                  errors={errors}
                  touched={touched}
                  isDisabled={isDisabled}
                />
                {((selectedFeature && caseDetail?.characteristics?.length > 0) ||
                  (selectedFeature && !caseDetail?.selectedCase)) && (
                  <ThirdStep
                    inputs={valuesCharacters}
                    setInputs={setValuesCharacters}
                    onPres={(e) => getSelect(e)}
                    errors={errors}
                    touched={touched}
                    onValidate={() => SignupSchemaLabel(selectedFeature?.fields)}
                  />
                )}

                <Anexos
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  submit={submit}
                />

                <div style={{ display: 'flex', padding: '0 80px' }}>
                  <ButtonBar
                    backOnClick={() =>
                      caseDetail?.selectedCase
                        ? Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)
                        : Navigation.navigate(
                            RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS,
                          )
                    }
                    nextOnClick={() => {
                      setSubmit(true);
                    }}
                    nextLoading={false}
                    nextDisabled={isDisabled}
                    nextBackground={isDisabled ? Color.gray : Color.blueSeconde}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>

        <Margin height={57} />
      </Main>
    </Container>
  );
};

export default FormDpaDpeWeb;
