import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { InfoSVG } from '~/assets/Icons';
import { getEnrollmentsUsersObfuscatedRequest } from '~/store/modules/enrollmentsUsers/actions';
import { IState } from '~/store/modules/types';

interface IProps {
  cnp: string;
}

const ModalPasswordExpiration: React.FC<IProps> = ({ cnp }) => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingObfuscated,
  );

  return (
    <Main>
      <InfoSVG />
      <h1>Sua senha expirou!</h1>
      <p>Para seguir, cadastre uma nova senha.</p>
      <ButtonText
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="Ok"
        onClick={() => {
          if (loading) return;
          ReactGA.event({
            category: 'Expiração de senha',
            action: '[Expiração de Senha][WEB] Redefinir Minha Senha',
          });
          dispatch(getEnrollmentsUsersObfuscatedRequest(cnp));
        }}
        loading={loading}
      />
    </Main>
  );
};

export default ModalPasswordExpiration;
