/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import configsAppJsonImport from '~/configs/app.config';

import { ButtonText } from '~/components/ButtonText';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';

import { Color } from '~/styles';
import { Breakpoints } from '../../styles/breakpoints';
import {
  Main,
  DescriptionTextStyled,
  TitleTextStyled,
  CardForm,
  SubTitle,
  ButtonGoBack,
  ContainerTermsStyled,
  TextTerm,
} from './styles';

import { getCloseErrorEnrollmentsUsersValidate } from '~/store/modules/enrollmentsUsers/actions';
import { RepositoriesTypesUsers } from '~/store/modules/enrollmentsUsers/types';
import { getEnrollmentsUsersValidateService } from '~/services/enrollmentsUsers';
import { ErrorTypes } from '~/enums/errorsEnum';
import { UserCategoriesEnum } from '~/enums/userCategoriesEnum';
import { MessageBox } from '~/common';
import { InputChecked } from '~/common/InputChecked';

import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { history } from '~/store';

const configsAppJson = configsAppJsonImport as any;

const RegisterEntrepreneurV3: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataRegister = history.location.state as any;

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRegister,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateRegister,
  );
  const [razaoSocial, setRazaoSocial] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [systemTerms, setSystemTerms] = useState<boolean>(false);
  const [productTerms, setProductTerms] = useState<boolean>(false);
  const [enableSubmit, setEnableSubmit] = useState<boolean>(
    configsAppJson?.NODE_ENV !== 'PRD',
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [messageBox, setMessageBox] = useState<boolean>(false);

  const clearError = () => {
    dispatch(getCloseErrorEnrollmentsUsersValidate());
  };

  useEffect(() => {
    return () => clearError();
  }, []);

  useEffect(() => {
    if (error && error.state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  useEffect(() => {
    if (
      cpf &&
      cnpj &&
      name &&
      email &&
      phone &&
      systemTerms &&
      productTerms &&
      enableSubmit
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [cpf, cnpj, name, email, phone, systemTerms, productTerms, enableSubmit]);

  const handleContinue = () => {
    return Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD, {
      razaoSocial,
      clientType: 'entrepreneur',
      cnp: formatter.formatarNumber(cnpj),
      cnpj: formatter.formatarNumber(cnpj),
      cpf: formatter.formatarNumber(cpf),
      name,
      documentType: 'CNPJ',
      phone,
      telephone,
      email,
      documentNumberResponsible: cpf,
      nameResponsible: razaoSocial,
      isEntrepreneur: true,
      category: UserCategoriesEnum.ENTREPRENEUR,
      acceptance_terms_agree: true,
      concession_terms_agree: true,
    });
  };

  const validateIsClient = async () => {
    try {
      await getEnrollmentsUsersValidateService({
        cnp: cnpj,
      });
    } catch (error: any) {
      if (error.data.type === ErrorTypes.USER_ALREADY_EXISTS) setMessageBox(true);
      // Navigation.navigate(RouteName.LOGIN);
    }
  };

  return (
    <Main>
      <CardForm>
        <Margin height={isMobile ? 38 : 59} />
        <TitleTextStyled>CRIAR SEU CADASTRO</TitleTextStyled>
        <DescriptionTextStyled>
          Para começar o cadastro, preencha corretamente os dados a seguir:
        </DescriptionTextStyled>
        <Margin height={isMobile ? 12 : 32} />
        {messageBox && (
          <MessageBox title="Atenção">
            Olá, cliente! Identificamos que você já possui cadastro,{' '}
            <Link to="/login">faça seu login!</Link>
          </MessageBox>
        )}
        <Margin height={isMobile ? 12 : 32} />
        <SubTitle>
          <p>Dados do empreendimento </p>
          <hr />
        </SubTitle>
        <Margin height={isMobile ? 12 : 32} />
        <Input
          value={formatter.cnpjMask(cnpj)}
          onChange={(ev) => {
            setMessageBox(false);
            setCnpj(formatter.cnpjMask(ev.target.value));
          }}
          onBlur={validateIsClient}
          label="CNPJ (será utilizado como login)*"
        />
        <Margin height={isMobile ? 12 : 16} />
        <Input
          value={razaoSocial}
          onChange={(ev) => setRazaoSocial(ev.target.value)}
          label="Razão social*"
        />
        <Margin height={isMobile ? 12 : 40} />
        <SubTitle>
          <p>Dados do representante</p>
          <hr />
        </SubTitle>
        <Margin height={isMobile ? 12 : 28} />
        <Input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          label="Nome completo*"
        />
        <Margin height={isMobile ? 12 : 16} />
        <Input
          value={formatter.cpfMask(cpf)}
          onChange={(ev) => setCpf(formatter.cpfMask(ev.target.value))}
          label="CPF*"
        />
        <Margin height={isMobile ? 12 : 16} />
        <Input
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          label="Email*"
        />
        <Margin height={isMobile ? 12 : 16} />
        <Input
          value={formatter.formatarPhone(phone)}
          onChange={(ev) => setPhone(formatter.formatarPhone(ev.target.value))}
          label="Celular*"
        />
        <Margin height={isMobile ? 12 : 16} />
        <Input
          value={formatter.formatarTel(telephone)}
          onChange={(ev) => setTelephone(formatter.formatarTel(ev.target.value))}
          label="Telefone"
        />

        <Margin height={24} />
        <ContainerTermsStyled>
          <InputChecked
            checked={systemTerms}
            onChange={(e) => setSystemTerms(e.target.checked)}
          >
            <TextTerm>
              Li o{' '}
              <a href="/trm1.pdf" target="_blank" rel="noreferrer">
                Termo de Adesão
              </a>{' '}
              para utilização dos sistemas do Grupo Iguá
            </TextTerm>
          </InputChecked>
          <InputChecked
            checked={productTerms}
            onChange={(e) => setProductTerms(e.target.checked)}
          >
            <TextTerm>
              Li o{' '}
              <a href="/trm2.pdf" target="_blank" rel="noreferrer">
                Termo de Adesão
              </a>{' '}
              para oferecimento de produtos e serviços
            </TextTerm>
          </InputChecked>
        </ContainerTermsStyled>
        {configsAppJson?.NODE_ENV === 'PRD' && (
          <div
            style={{
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              marginTop: '24px',
            }}
          >
            <ReCAPTCHA
              sitekey={configsAppJson?.GOOGLE_RECAPTCHA[configsAppJson?.NODE_ENV]}
              onErrored={() => setEnableSubmit(false)}
              onExpired={() => setEnableSubmit(false)}
              onChange={(j) => setEnableSubmit(!!j)}
              size="normal"
            />
          </div>
        )}

        <Margin height={isMobile ? 12 : 24} />

        {disabled ? (
          <ButtonText
            text="Continuar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.gray}
            onClick={() => {
              /* disabled */
            }}
            loading={loading}
            style={{ height: 'auto', aspectRatio: '6.6' }}
          />
        ) : (
          <ButtonText
            text="Continuar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.blueSeconde}
            onClick={() => {
              handleContinue();
            }}
            loading={loading}
            style={{ height: 'auto', aspectRatio: '6.6' }}
          />
        )}
        <Margin height={21} />
        <ButtonGoBack to={RouteName.REGISTERPROFILE}>Voltar</ButtonGoBack>
        <Margin height={isMobile ? 15 : 32} />
      </CardForm>
    </Main>
  );
};

export default RegisterEntrepreneurV3;
