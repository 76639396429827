import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import DocumentationApprovalRevision from './Revision';
import Attachments from './Form';
import { clearDataAttachFilesCustomerObligationRequest } from '~/store/modules/customerObligation/actions';
import Status from './Status';
import { stepStatusEnum, subStepEnum } from '~/enums/customerObligationEnum';
import DocumentationApprovalGenerateCode from './FormGenerateCode';

interface IProps {
  setActualStep: (e: 1 | 2 | 3 | 4 | 5) => void;
}

const DocumentationApproval: FC<IProps> = ({ setActualStep }) => {
  const dispatch = useDispatch();
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true && attachment.step.includes('2'),
  );
  const successAttachFilesCode = useSelector(
    (item: IState) => item.customerObligation.successAttachFilesCode,
  );
  const successAttachFiles = useSelector(
    (item: IState) => item.customerObligation.successAttachFiles,
  );

  useEffect(() => {
    if (successAttachFiles && !isRevision) {
      dispatch(clearDataAttachFilesCustomerObligationRequest());
    }
  }, [successAttachFiles, successAttachFilesCode]);

  return (
    <>
      {caseOC?.step?.status === stepStatusEnum.FORM &&
        caseOC?.step?.substep === subStepEnum.A && (
          <Attachments setActualStep={setActualStep} />
        )}
      {caseOC?.step?.status === stepStatusEnum.FORM &&
        caseOC?.step?.substep === subStepEnum.B && (
          <DocumentationApprovalGenerateCode />
        )}
      {(caseOC?.step?.status === stepStatusEnum.WAIT ||
        caseOC?.step?.status === stepStatusEnum.SUCCESS) && <Status />}
      {caseOC?.step?.status === stepStatusEnum.REVIEW && (
        <DocumentationApprovalRevision setActualStep={setActualStep} />
      )}
    </>
  );
};

export default DocumentationApproval;
