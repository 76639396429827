import React, { FC } from 'react';
import { ContainerInfoTitle, ContainerInvoiceDetails, Container } from './styles';
import { Margin } from '~/components/Margin';

interface IDescriptionPaymentProps {
  type: 'credit-card' | 'pix' | 'billet';
}

export const DescriptionPayment: FC<IDescriptionPaymentProps> = ({ type }) => {
  return (
    <Container>
      <ContainerInfoTitle>
        {' '}
        {type === 'pix'
          ? 'Pagar com o PIX é muito mais fácil!'
          : 'Pague o seu boleto com o código de barras'}{' '}
      </ContainerInfoTitle>
      <ContainerInvoiceDetails>
        <p>1 Abra o aplicativo de pagamento ou banco</p>
        <p>
          {type === 'pix'
            ? '2 Escolha a opção de pagamento via PIX'
            : 'Escolha a opção de pagamento via boleto'}{' '}
        </p>
        <p>
          {type === 'pix'
            ? '3 Aponte a câmera para a tela para escanear o QR code'
            : '3 Copie o código abaixo e cole no seu app'}{' '}
        </p>
        <p>4 Confirme os detalhes da transação</p>
        <Margin height={40} />
        <p>
          {type === 'pix'
            ? 'Você também pode pagar com o código PIX abaixo.'
            : 'Você também pode usar o código para pagar em qualquer lotérica!'}{' '}
        </p>
      </ContainerInvoiceDetails>
    </Container>
  );
};
