import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import DocumentationApproval from './DocumentationApproval';
import DocumentationProject from './DocumentationProject';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Status from './Status';
import Review from './Review';

const DocumentationApprovalStep: FC = () => {
  const dataItem = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.step,
  );

  return (
    <>
      {dataItem?.substep === 'A' && dataItem?.status === stepStatusEnum.FORM && (
        <DocumentationApproval />
      )}
      {dataItem?.substep === 'B' && dataItem?.status === stepStatusEnum.FORM && (
        <DocumentationProject />
      )}
      {dataItem?.status === stepStatusEnum.REVIEW && <Review />}
      {(dataItem?.status === stepStatusEnum.WAIT ||
        dataItem?.status === stepStatusEnum.SUCCESS) && <Status />}
    </>
  );
};

export default DocumentationApprovalStep;
