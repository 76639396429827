import { action } from 'typesafe-actions';
import { IError } from 'interfaces/ErrorResponse';

import {
  RepositoriesTypesDpaDpe,
  IResponseListCases,
  IResponseListSlips,
  IResponseGetSlips,
  IResponseListStatuses,
} from './types';
import { IPropsRequest, IPropsRequestSlip } from '~/services/viability';

export interface IDataCase {
  case_id?: string;
  enterprise: {
    owner: string;
    email: string;
    cpf_cnpj: string;
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string;
    city: string;
    state: string;
  };
  interested: {
    name: string;
    qualification: string | null;
    address: string;
    email: string;
    phone: string;
  };
  files: {
    identification_document_file: File | null;
    location_plan_file: File | null;
    situation_plan_file: File | null;
  };
}

export const registerCaseRequest = (data: IDataCase) =>
  action(RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_REQUEST, { data });
export const registerCaseSuccess = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_SUCCESS);
export const registerCaseFailure = ({ type, status }: IError) =>
  action(RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_FAILURE, {
    type,
    status,
  });
export const updateCaseRequest = (data: IDataCase) =>
  action(RepositoriesTypesDpaDpe.DPADPE_UPDATE_VIABILITY_REQUEST, { data });

export const setSelectedCaseRequst = (id: string) =>
  action(RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_REQUEST, { id });
export const setSelectedCaseSuccess = (data: IDataCase) =>
  action(RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_SUCCESS, { data });
export const setSelectedCaseFailure = (error: IError) =>
  action(RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_FAILURE, error);

export const resetSelectedCase = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_RESET_SELECTED_CASE);

export const setLocation = (data: string) =>
  action(RepositoriesTypesDpaDpe.DPADPE_SET_LOCATION, data);
export const resetLocation = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_RESET_LOCATION);

export const listOperationsRequst = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_REQUEST);
export const listOperationsSucess = (data: IDataCase) =>
  action(RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_SUCCESS, { data });
export const listOperationsFailure = (error: IError) =>
  action(RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_FAILURE, error);

export const listCasesRequest = (data: IPropsRequest) => {
  return action(RepositoriesTypesDpaDpe.DPADPE_LISTCASES_REQUEST, data);
};
export const listCasesSuccess = (data: IResponseListCases[]) =>
  action(RepositoriesTypesDpaDpe.DPADPE_LISTCASES_SUCCESS, { data });
export const listCasesFailure = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_LISTCASES_FAILURE);

export const listSlipsRequest = (data: IPropsRequestSlip) => {
  return action(RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_REQUEST, data);
};
export const listSlipsSuccess = (data: IResponseListSlips[]) =>
  action(RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_SUCCESS, { data });
export const listSlipsFailure = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_FAILURE);

export const getSlipsRequest = (data: { slipId: string }) => {
  return action(RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_REQUEST, data);
};
export const getSlipsSuccess = (data: IResponseGetSlips) =>
  action(RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_SUCCESS, { data });
export const getSlipsFailure = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_FAILURE);

export const downloadSlipRequest = (data: { slipId: string }) => {
  return action(RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_REQUEST, data);
};
export const downloadSlipSuccess = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_SUCCESS);
export const downloadSlipFailure = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_FAILURE);

export const listStatusesRequest = () => {
  return action(RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_REQUEST);
};
export const listStatusesSuccess = (data: IResponseListStatuses[]) =>
  action(RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_SUCCESS, { data });
export const listStatusesFailure = () =>
  action(RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_FAILURE);
