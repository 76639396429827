import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Navigation from '~/utils/Navigation';

import { Main, MessageError, Wrapper } from './styles';
import { Card } from '~/common/Card';
import Modal from '~/components/Modal';
import { Button } from '~/common/Button';
import { ButtonLink, Input } from '~/common';
import {
  getCloseErrorEnrollmentsUsersRecoveryValidate,
  getEnrollmentsUsersRecoveryValidateRequest,
  putCloseSuccessUsersUpdatePassword,
} from '~/store/modules/enrollmentsUsers/actions';
import formatter from '~/utils/formatter';
import { Margin } from '~/components/Margin';
import { checkCnp } from '~/utils/validation';
import { IState } from '~/store/modules/types';
import { Breakpoints } from '~/styles';
import { history } from '~/store';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import { Recaptcha } from '~/components/Recaptcha';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';

const ForgotPassword: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataLogin = history?.location?.state as any;

  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateRecovery,
  );

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRecovery,
  );

  const [cnp, setCnp] = useState<string>(dataLogin?.cnp || '');
  const [cnpError, setCnpError] = useState<string>('');
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  useEffect(() => {
    dispatch(getCloseErrorEnrollmentsUsersRecoveryValidate());
    dispatch(putCloseSuccessUsersUpdatePassword());
  }, []);

  useEffect(() => {
    if (error && error.type === EnumResponseCode.USER_NOT_EXISTS_IN_PARTNERS) {
      setOpenModalError(true);
    } else {
      setOpenModalError(false);
    }
  }, [error]);

  useEffect(() => {
    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setCnpError('CPF ou CNPJ inválido');
    } else {
      setCnpError('');
    }
  }, [cnp]);

  return (
    <Main>
      <Modal open={openModalError} type="error" title="Ops!">
        <MessageError>Usuário não encontrado em nosso sistema.</MessageError>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(getCloseErrorEnrollmentsUsersRecoveryValidate());
            dispatch(putCloseSuccessUsersUpdatePassword());
          }}
        >
          Entendi
        </Button>
      </Modal>
      <Recaptcha
        setRecaptchaTokenV3={setRecaptchaTokenV3}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        showRecaptchaV2={showRecaptcha}
        onClose={() => {
          dispatch(getCloseErrorEnrollmentsUsersRecoveryValidate());
        }}
      />
      <Card
        title="RECUPERAR SENHA"
        style={{ padding: isMobile ? '32px 20px' : '32px 80px', maxWidth: '600px' }}
      >
        <p>
          Para começar, precisamos localizar sua matrícula em nosso sistema. Por
          favor, informe seu CPF ou CNPJ.
        </p>
        <Margin height={24} />
        <Wrapper>
          <Input
            name="cnp"
            label="CPF ou CNPJ"
            color="red"
            value={cnp}
            onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
            error={!!cnpError}
            errorText={cnpError}
          />
          <Margin height={48} />
          <Button
            onClick={() => {
              if (!!cnpError || !cnp) return;
              dispatch(
                getEnrollmentsUsersRecoveryValidateRequest({
                  cnp,
                  recaptchaTokenV3: recaptchaTokenV3 || '',
                  recaptchaTokenV2: recaptchaTokenV2 || '',
                }),
              );
            }}
            loading={loading}
            disabled={!!cnpError || !cnp}
          >
            Continuar
          </Button>
          <ButtonLink type="button" color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default ForgotPassword;
