import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import SocialTariffSolicitationCuiaba from './Cuiaba';
import SocialTariffSolicitationRio from './Rio';

const SocialTariffSolicitation: FC = () => {
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.operation.name,
  );

  return operation?.toLocaleUpperCase() === 'CUIABA' ? (
    <SocialTariffSolicitationCuiaba />
  ) : (
    <SocialTariffSolicitationRio />
  );
};

export default SocialTariffSolicitation;
