import React, { FC } from 'react';
import { Comments, Container, Fields, Title, Text } from './style';

interface Props {
  fields?: string[] | null;
  comments?: string | null;
}

const ReviewFields: FC<Props> = ({ comments, fields }) => {
  return (
    <Container>
      <Fields>
        <Title>Campos que precisam de atenção:</Title>
        {fields && fields.map((field) => <Text>{field}</Text>)}
      </Fields>
      <Comments>
        <Title>Observações:</Title>
        <Text>{comments}</Text>
      </Comments>
    </Container>
  );
};

export default ReviewFields;
