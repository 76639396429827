import React from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';

import { Button, Container, Row } from './styled';
import { Text } from '~/components/TextInfo';
import { Margin } from '~/components/Margin';
import { Separator } from '../../styles';
import { Input } from '~/components/Input';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import SuccesBox from '~/components/SuccesBox';
import { Breakpoints } from '~/styles';

interface IProps {
  succes: boolean;
}

const Acces: React.FC<IProps> = ({ succes }) => {
  const isLaptop = useMediaQuery({
    minWidth: Breakpoints.laptop.min,
  });

  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );

  const password = useSelector((item: IState) => item.enrollmentsAuth.password);

  return (
    <Container>
      <Text color="#004B55" size={28} weight={400} fontFamily="Montserrat-Regular">
        Acesso
      </Text>
      <Margin height={34} />
      <Separator />
      <Margin height={24} />
      <Text color="#004B55" size={12} weight={500} fontFamily="Montserrat-Regular">
        Autorizado acesso utilizando:
      </Text>
      <Margin height={24} />
      <Separator />
      <Margin height={24} />
      <div style={{ flex: 1, width: '100%' }}>
        {succes && <SuccesBox label="Senha atualizada com sucesso!" />}
      </div>
      <Margin height={24} />
      <Row>
        <Input
          isNewHeigthLabelInput
          label="CPF"
          disabled
          value={
            enrollmentsUserInfo?.cnp
              ? formatter.cpfCnpjMask(enrollmentsUserInfo?.cnp)
              : ''
          }
          style={{ borderColor: '#C7C9C7', color: '#C7C9C7', background: '#F2F2F2' }}
        />
        <Margin
          width={isLaptop ? 40 : undefined}
          height={isLaptop ? undefined : 40}
        />
        <Input
          isNewHeigthLabelInput
          disabled
          label="Senha"
          type="password"
          value={password}
          style={{ borderColor: '#C7C9C7', color: '#C7C9C7', background: '#F2F2F2' }}
        />
      </Row>

      <Margin height={30} />
      <Separator />
      <Margin height={24} />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{ cursor: 'pointer' }}
          onClick={() => {
            ReactGA.event({
              category: 'Perfil',
              action: 'Solicitação de Alteração de Senha',
            });
            Navigation.navigate(RouteName.CHANGEPASSOWORD);
          }}
        >
          Alterar senha
        </Button>
      </div>
    </Container>
  );
};

export default Acces;
