import styled, { css } from 'styled-components';
import { COLORS } from '~/utils/constants';

const variants = {
  default: css`
    color: ${COLORS.secondary};
  `,
};

export const MyBreadcrumb = styled.nav`
  ${variants.default}
`;

export const List = styled.ol`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export const Wrapper = styled.li`
  --spacing: 12px;

  display: inline;
  color: ${COLORS.highlight};
  font-size: 0.875rem;
  font-weight: 500;

  &:not(:first-of-type) {
    margin-inline-start: var(--spacing);

    [aria-current='page'] {
      font-weight: 600;
      text-decoration: none;
    }

    &::before {
      content: '>';
      opacity: 0.5;
      margin-inline-end: var(--spacing);
    }
  }
`;

export const CrumbLink = styled.button`
  color: inherit;
  font-weight: inherit;
  background: none;
  cursor: pointer;
  text-decoration: underline;
`;
