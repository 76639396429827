import React, { FC, ButtonHTMLAttributes } from 'react';
import { Loading } from '../ButtonNav/styles';
import { MyButton } from './styles';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  variant?: 'primary' | 'secondary' | 'error';
  outline?: boolean;
  loading?: boolean;
}

const Button: FC<IButton> = ({ children, loading, ...props }) => {
  return <MyButton {...props}>{loading ? <Loading /> : children}</MyButton>;
};

export { Button };
