import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ContainerOptions = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: inherit;
    flex-direction: column;
    overflow: inherit;
  }
`;

interface IProps {
  active?: boolean;
}

export const Button = styled.button<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 0;

  width: 146px;

  background: ${(props) => (props.active ? '#004b55' : 'white')};
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  border-left: 8px solid #004b55;

  cursor: pointer;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: ${(props) => (props.active ? 'white' : '#004b55')};
`;
