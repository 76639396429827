import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { Main, Wrapper } from './styles';

interface IProps {
  children: any;
  fullHeader?: boolean;
}

const GlobalLayout = ({ children, fullHeader }: IProps) => {
  return (
    <Wrapper>
      <Header fullHeader={fullHeader} />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
};

export default GlobalLayout;
