export enum RepositoriesTypesSubscription {
  SUBSCRIPTION_GET_METHOD_REQUEST = '@repositories/SUBSCRIPTION_GET_METHOD_REQUEST',
  SUBSCRIPTION_GET_METHOD_SUCCESS = '@repositories/SUBSCRIPTION_GET_METHOD_SUCCESS',
  SUBSCRIPTION_GET_METHOD_FAILURE = '@repositories/SUBSCRIPTION_GET_METHOD_FAILURE',

  SUBSCRIPTION_POST_METHOD_REQUEST = '@repositories/SUBSCRIPTION_POST_METHOD_REQUEST',
  SUBSCRIPTION_POST_METHOD_SUCCESS = '@repositories/SUBSCRIPTION_POST_METHOD_SUCCESS',
  SUBSCRIPTION_POST_METHOD_SUCCESS_CLOSE = '@repositories/SUBSCRIPTION_POST_METHOD_SUCCESS_CLOSE',
  SUBSCRIPTION_POST_METHOD_FAILURE = '@repositories/SUBSCRIPTION_POST_METHOD_FAILURE',
  SUBSCRIPTION_POST_METHOD_FAILURE_CLOSE = '@repositories/SUBSCRIPTION_POST_METHOD_FAILURE_CLOSE',

  SUBSCRIPTION_DELETE_METHOD_REQUEST = '@repositories/SUBSCRIPTION_DELETE_METHOD_REQUEST',
  SUBSCRIPTION_DELETE_METHOD_SUCCESS = '@repositories/SUBSCRIPTION_DELETE_METHOD_SUCCESS',
  SUBSCRIPTION_DELETE_METHOD_FAILURE = '@repositories/SUBSCRIPTION_DELETE_METHOD_FAILURE',
  SUBSCRIPTION_DELETE_METHOD_FAILURE_CLOSE = '@repositories/SUBSCRIPTION_DELETE_METHOD_FAILURE_CLOSE',
}

interface IPlan {
  id: number;
  name: string;
  description: string;
  trial: number;
  amount: number;
  interval: string;
  amount_compare: any;
  interval_compare: any;
  is_advantageous: boolean;
}

export interface ICard {
  id: number;
  card_number: string;
  brand: string;
  type: string;
  issuer: string;
  issuer_code: string;
  foreign: boolean;
  corporate: boolean;
  prepaid: boolean;
  is_default: boolean;
}

export interface IResponseGetSubscription {
  id: number;
  user_id: number;
  registration_id: string;
  recurrent_payment_id_cielo: string;
  amount: number;
  start_date: Date;
  plan: IPlan;
  card: ICard;
}

export interface IRequestPostSubscription {
  plan_id: number;
  card_id: number;
  security_code: string;
  registration_id: string;
}

export interface IRequestDeleteSubscription {
  plan_id: number;
  registration_id: string;
}

export interface IRepositoriesStateSubscription {
  readonly data: IResponseGetSubscription | null;
  readonly successPostSubscription: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPostSubscription: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingPostSubscription: boolean;
  readonly loadingDeleteSubscription: boolean;
}
