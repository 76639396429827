import React, { FC, ButtonHTMLAttributes } from 'react';
import ArrowFowardSVG from '~/assets/Icons/ArrowFowardSVG';
import ArrowBackwardSVG from '~/assets/Icons/ArrowBackwardSVG';
import { ButtonIcon, Loading } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'error';
  children: any;
  // disabled?: boolean;
  loading?: boolean;
}

const ButtonFoward: FC<IProps> = ({ color, children, loading, ...props }) => {
  return (
    <ButtonIcon color={color} {...props}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {children}
          <ArrowFowardSVG />
        </>
      )}
    </ButtonIcon>
  );
};

const ButtonBackward: FC<IProps> = ({ color, disabled, children, ...props }) => {
  return (
    <ButtonIcon color={color} disabled={disabled} {...props}>
      <ArrowBackwardSVG />
      {children}
    </ButtonIcon>
  );
};

const Button: FC<IProps> = ({ color, disabled, children, loading, ...props }) => {
  return (
    <ButtonIcon color={color} disabled={disabled} {...props}>
      {loading ? <Loading /> : children}
    </ButtonIcon>
  );
};

export { ButtonFoward, ButtonBackward, Button };
