import styled, { css } from 'styled-components';

const fontStyle = css`
  width: calc(100% - 24px);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  p {
    ${fontStyle};
    padding: 10px 12px;
    border-bottom: 24px solid #fff;
  }

  ul {
    width: 100%;
    padding: 0 12px;

    li {
      ${fontStyle};
      padding: 10px 0;
      border-top: 1px solid #fff;

      :first-of-type {
        padding: 4px 0 10px 0;
      }
      :last-of-type {
        padding: 10px 0 4px 0;
      }
    }
  }
`;
