import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const OwnershipSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="35"
      height="26"
      viewBox="0 0 35 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4785 6.33362C22.3022 6.33342 22.1306 6.39914 21.9897 6.52083C21.8488 6.64253 21.7463 6.81359 21.6976 7.00814C21.6489 7.2027 21.6567 7.41016 21.7198 7.59916C21.783 7.78817 21.898 7.94843 22.0475 8.05571L24.2293 10.5606C24.3047 10.6471 24.3943 10.7157 24.4928 10.7625C24.5913 10.8093 24.6969 10.8334 24.8035 10.8333C24.9101 10.8333 25.0157 10.8091 25.1141 10.7623C25.2126 10.7154 25.3021 10.6467 25.3775 10.5601C25.4528 10.4735 25.5126 10.3708 25.5534 10.2577C25.5941 10.1446 25.6151 10.0233 25.615 9.90095C25.615 9.77855 25.594 9.65736 25.5531 9.54429C25.5123 9.43123 25.4525 9.3285 25.3771 9.24198L24.4672 8.19829H30.5954C30.8107 8.19829 31.0171 8.10011 31.1693 7.92535C31.3216 7.75059 31.4071 7.51357 31.4071 7.26642C31.4071 7.01928 31.3216 6.78225 31.1693 6.60749C31.0171 6.43273 30.8107 6.33456 30.5954 6.33456L22.4785 6.33362ZM33.8535 4.49971C34.0298 4.49991 34.2014 4.4342 34.3423 4.3125C34.4832 4.1908 34.5858 4.01974 34.6344 3.82519C34.6831 3.63064 34.6753 3.42317 34.6122 3.23417C34.5491 3.04517 34.434 2.8849 34.2845 2.77762L32.1027 0.27276C31.9504 0.0980275 31.7439 -8.73212e-05 31.5286 5.83168e-08C31.3132 8.74378e-05 31.1068 0.09837 30.9546 0.273226C30.8024 0.448082 30.7169 0.685189 30.717 0.932385C30.7171 1.17958 30.8027 1.41662 30.955 1.59135L31.8649 2.63504H25.7366C25.5214 2.63504 25.3149 2.73322 25.1627 2.90798C25.0105 3.08274 24.9249 3.31976 24.9249 3.56691C24.9249 3.81406 25.0105 4.05108 25.1627 4.22584C25.3149 4.4006 25.5214 4.49878 25.7366 4.49878L33.8535 4.49971Z"
        fill={fill || 'white'}
      />
      <path
        d="M2.16667 26C2.16667 26 0 26 0 23.8333C0 21.6667 2.16667 15.1667 13 15.1667C23.8333 15.1667 26 21.6667 26 23.8333C26 26 23.8333 26 23.8333 26H2.16667ZM13 13C14.7239 13 16.3772 12.3152 17.5962 11.0962C18.8152 9.87721 19.5 8.22391 19.5 6.5C19.5 4.77609 18.8152 3.12279 17.5962 1.90381C16.3772 0.68482 14.7239 0 13 0C11.2761 0 9.62279 0.68482 8.40381 1.90381C7.18482 3.12279 6.5 4.77609 6.5 6.5C6.5 8.22391 7.18482 9.87721 8.40381 11.0962C9.62279 12.3152 11.2761 13 13 13Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default OwnershipSVG;
