import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import SocialTariffRio from './Rio';
import SocialTariffCuiaba from './Cuiaba';

const SocialTariff: FC = () => {
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.operation.name,
  );

  return operation?.toLocaleUpperCase() === 'CUIABA' ? (
    <SocialTariffCuiaba />
  ) : (
    <SocialTariffRio />
  );
};

export default SocialTariff;
