import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${Color.blueIntense};
  background: ${Color.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${Color.blueIntense};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin: 16px 0;

    @media screen and (min-width: ${Breakpoints.tablet.min}px) {
      white-space: nowrap;
    }
  }

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.blueSeconde};
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: auto;
    border-radius: 16px;
    max-width: calc(400px - 66px);
    min-height: calc(336px - 66px);
    padding: 32px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    width: 100%;
    color: ${Color.white};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    filter: none;
    box-shadow: none;
    background: ${Color.blueIntense};
    text-decoration: none;
  }
`;
