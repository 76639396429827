export enum RepositoriesTypesOwnershipOrders {
  OWNERSHIP_ORDERS_GET_METHOD_REQUEST = '@repositories/OWNERSHIP_ORDERS_GET_METHOD_REQUEST',
  OWNERSHIP_ORDERS_GET_METHOD_SUCCESS = '@repositories/OWNERSHIP_ORDERS_GET_METHOD_SUCCESS',
  OWNERSHIP_ORDERS_GET_METHOD_FAILURE = '@repositories/OWNERSHIP_ORDERS_GET_METHOD_FAILURE',

  OWNERSHIP_OPERATIONS_GET_METHOD_REQUEST = '@repositories/OWNERSHIP_OPERATIONS_GET_METHOD_REQUEST',
  OWNERSHIP_OPERATIONS_GET_METHOD_SUCCESS = '@repositories/OWNERSHIP_OPERATIONS_GET_METHOD_SUCCESS',
  OWNERSHIP_OPERATIONS_GET_METHOD_FAILURE = '@repositories/OWNERSHIP_OPERATIONS_GET_METHOD_FAILURE',

  OWNERSHIP_ORDER_DETAIL_GET_METHOD_REQUEST = '@repositories/OWNERSHIP_ORDER_DETAIL_GET_METHOD_REQUEST',
  OWNERSHIP_ORDER_DETAIL_GET_METHOD_SUCCESS = '@repositories/OWNERSHIP_ORDER_DETAIL_GET_METHOD_SUCCESS',
  OWNERSHIP_ORDER_DETAIL_GET_METHOD_FAILURE = '@repositories/OWNERSHIP_ORDER_DETAIL_GET_METHOD_FAILURE',

  OWNERSHIP_ORDER_DELETE_METHOD_REQUEST = '@repositories/OWNERSHIP_ORDER_DELETE_METHOD_REQUEST',
  OWNERSHIP_ORDER_DELETE_METHOD_SUCCESS = '@repositories/OWNERSHIP_ORDER_DELETE_METHOD_SUCCESS',
  OWNERSHIP_ORDER_DELETE_METHOD_FAILURE = '@repositories/OWNERSHIP_ORDER_DELETE_METHOD_FAILURE',

  OWNERSHIP_ERROR_OPEN = '@repositories/OWNERSHIP_ERROR_OPEN',
  OWNERSHIP_ERROR_CLOSE = '@repositories/OWNERSHIP_ERROR_CLOSE',
}

interface IError {
  message: string;
  status: number;
  state: boolean;
}

export interface IResponseGetOwnershipOrders {
  case_id: string;
  createdByDigiIguaUser: any | null;
  createdDate: string;
  closedDate: string;
  operationC: string;
  operationR: {
    attributes: {
      type: string;
      url: string;
    };
    Name: string;
  };
  caseNumber: string;
  status: string;
  category: string;
  attachments: null;
}

export interface IResponseGetOwnershipOperations {
  id: number;
  label: string;
}

export interface IResponsePostOwnershipOrder {
  id: number;
}

export interface IRepositoriesStateOwnership {
  readonly data: IResponseGetOwnershipOrders[] | null;
  readonly loading: boolean;
  readonly error: IError | null;
  readonly operations: {
    data: IResponseGetOwnershipOperations[] | null;
  };
  readonly detail: {
    data: IResponseGetOwnershipOrders | null;
  };
}
