/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Loading } from '~/common';
import { CopyButton } from '~/views/SupportCenter/Material/CopyButton';
import {
  Wrapper,
  OrderWrapper,
  StatusWrapper,
  OrderBottom,
  StatusOpt,
  StatusInfo,
  StatusMessage,
  Divider,
  Bar,
  WrapperProtocoll,
  StatusMessageProtocoll,
  StatusWrapperProtocoll,
  StatusInfoProtocoll,
  OrderWrapperProtocoll,
  OrderTitleProtocoll,
  OrderDateProtocoll,
} from './styles';
import { FormikValues, useFormikContext } from 'formik';
import { getStatusDetail } from '~/utils/ownership';
import { OrderDateSection } from '~/views/Ownership/ViewOrder';
import moment from 'moment';
import { DownloadButton, OrderStatusSection } from '~/views/Ownership/Common';
import { IResponseGetOwnershipOrders } from '~/store/modules/ownership/types';
import { api } from '~/services/api';

const ResumeStep = () => {
  const printRef = useRef(null);
  const [print, setPrint] = useState(false);

  const { values }: FormikValues = useFormikContext();

  const [order, setOrder] = useState<IResponseGetOwnershipOrders>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      const url = 'v3/ownerships';

      const response = await api.get(`${url}/me`);

      const arr = response.data.filter(
        (item: any) => item.case_id === values.caseId,
      );
      const detail = arr[0];

      setOrder(detail);
      setLoading(false);

      return response.data;
    };

    init();
  }, []);

  const PrintArea = () => {
    return (
      <WrapperProtocoll ref={printRef} className="super-area">
        <StatusMessageProtocoll>
          {getStatusDetail(order?.status || '').message}
        </StatusMessageProtocoll>

        <StatusWrapperProtocoll>
          <StatusInfoProtocoll>
            <p>O número do seu protocolo é:</p>
            <p style={{ fontWeight: 600 }}>{order?.caseNumber}</p>
          </StatusInfoProtocoll>
        </StatusWrapperProtocoll>
        <Bar />
        <OrderWrapperProtocoll>
          <OrderTitleProtocoll>
            {getStatusDetail(order?.status || '').label}
          </OrderTitleProtocoll>
          <OrderDateProtocoll>
            <p>Data de solicitação</p>

            <div style={{ marginTop: 5 }}>
              {moment(order?.createdDate).format('DD/MM/YYYY')}
            </div>
          </OrderDateProtocoll>
        </OrderWrapperProtocoll>
        <Divider />
      </WrapperProtocoll>
    );
  };

  return (
    <Wrapper>
      {print && <PrintArea />}
      {print && <Loading />}
      {loading && <Loading />}
      <OrderWrapper>
        <OrderStatusSection status={order?.status ?? ''} />
        <OrderDateSection
          createdAt={order?.createdDate}
          closedAt={order?.closedDate}
        />
        <OrderBottom>
          <StatusMessage>
            {getStatusDetail(order?.status || '').message}
          </StatusMessage>
        </OrderBottom>
      </OrderWrapper>
      <Divider />
      <StatusWrapper>
        <StatusInfo>
          <p>O número do seu protocolo é:</p>
          <CopyButton>{order?.caseNumber?.toString() || ''}</CopyButton>
        </StatusInfo>
        <StatusOpt>
          <DownloadButton reference={printRef} setPrint={setPrint}>
            Baixar protocolo
          </DownloadButton>
          <Button color="secondary">Voltar para home</Button>
        </StatusOpt>
      </StatusWrapper>
    </Wrapper>
  );
};

export { ResumeStep };
