import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import EmptyMessage from '~/components/EmptyMessage';
import SkeletonCustom from '~/components/Skeleton';

import { Breakpoints } from '~/styles';
import * as S from './styles';
import Chart from '~/components/Chart';
import { TrialBanner } from './TrialBanner';
import SmartMeterBanner from '~/assets/Images/trial-banner-01.png';
import { checkToggle } from '~/utils/toggles';
import HistorySubtitleEllipseIconSVG from '~/assets/Icons/HistorySubtitleEllipse';
import HistorySubtitleLineBoldIconSVG from '~/assets/Icons/HistorySubtitleLineBold';
import HistorySubtitleLineIconSVG from '~/assets/Icons/HistorySubtitleLine';

const ConsumptionHistorySession = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const loading = useSelector(
    (item: IState) => item.enrollmentsConsumptionHistory.loading,
  );
  const consumptionData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data,
  );
  const enrollmentsAverage = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.historyDataAverage,
  );
  const totalHistoryData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.totalHistoryData,
  );
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);

  const [existSmartAlert, setExistSmartAlert] = useState<boolean>(false);

  const verifyToggles = async () => {
    setExistSmartAlert(await checkToggle(toggles, 'MVP_CREATE_ALERT'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  return (
    <S.Wrapper>
      {eletronic_exists && existSmartAlert && <TrialBanner />}
      <S.Content>
        {eletronic_exists && existSmartAlert && (
          <S.TrialImg src={SmartMeterBanner} alt="medição inteligente" />
        )}
        <S.HistoryConsumer className="hstConsum">
          <S.Header className="header">
            <S.RowHistoryTitle>
              <S.TitleCard style={{ whiteSpace: 'nowrap', width: 'auto' }}>
                Histórico detalhado
              </S.TitleCard>
            </S.RowHistoryTitle>
          </S.Header>
          <S.RowChart className="rowChart">
            {loading ? (
              <div style={{ width: '100%' }}>
                <SkeletonCustom height={isMobile ? 155 : 260} />
              </div>
            ) : consumptionData && consumptionData?.historyData?.length > 0 ? (
              <>
                <Chart
                  dataChart={consumptionData?.historyData}
                  average={enrollmentsAverage}
                />
                <S.SubtitleHistory>
                  <p>
                    <HistorySubtitleEllipseIconSVG />
                    <Margin width={7} />
                    Consumo real
                  </p>
                  <Margin width={10} />
                  <p>
                    <HistorySubtitleLineBoldIconSVG />
                    <Margin width={7} />
                    Média do {isMobile && <br />} período
                  </p>
                  <Margin width={10} />
                  <p>
                    <HistorySubtitleLineIconSVG />
                    <Margin width={7} />
                    Alerta de consumo
                  </p>
                  <Margin width={10} />
                  <p id="subtitle-total">
                    Valor total consumido no período: {totalHistoryData}
                  </p>
                </S.SubtitleHistory>
              </>
            ) : (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Margin height={150} />
                <EmptyMessage textMessage="Você ainda não possui histórico detalhado!" />
                <Margin height={60} />
              </div>
            )}
          </S.RowChart>
        </S.HistoryConsumer>
      </S.Content>
    </S.Wrapper>
  );
};

export default ConsumptionHistorySession;
