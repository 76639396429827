export const Breakpoints = {
  mobile: {
    min: 320,
    max: 480,
  },
  tablet: {
    min: 481,
    max: 768,
  },
  laptop: {
    min: 769,
    max: 1024,
  },
  desktopLarge: {
    min: 1025,
    max: 1200,
  },
  desktopExtraLarge: {
    min: 1201,
    max: 1500,
  },
};
