import { Dispatch } from 'redux';
import { RepositoriesTypesUnavailableService } from './types';

const setUnavailableService = (data: boolean) => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesUnavailableService.SET_UNAVAILABLE_SERVICE,
    payload: data,
  });
};

export { setUnavailableService };
