import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Button = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ValueDisplay = styled.input`
  all: unset;
  width: 100%;
  border-bottom: 1px solid #999999;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1f1f1f;
  opacity: 0.74;
  padding-bottom: 4px;
`;

export const ContainerLimitText = styled.div`
  display: flex;
  gap: 16px;
`;

export const LimitText = styled.span`
  width: 100%;
  height: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  gap: 8px;
  background: #aad7e1;
  border-radius: 4px;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${Color.blueIntense};
  text-align: center;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: auto;
    min-width: 59px;
    height: 28px;
  }
`;
