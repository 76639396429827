import styled from 'styled-components';
import { COLORS } from '~/utils/constants';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputWrap = styled.div`
  width: 100%;
  /* max-width: 400px; */
  display: flex;
  gap: 8px;
`;

interface IPropsMyInputToken {
  error?: boolean;
}

export const MyInputToken = styled.input<IPropsMyInputToken>`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border: 1px solid ${({ error }) => (error ? COLORS.danger : COLORS.gray400)};
  border-radius: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;

  :focus-visible {
    outline: none;
  }
`;

export const HelperMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.danger};
  align-self: flex-end;
`;
