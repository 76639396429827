/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import {
  ButtonPayment,
  ContaineInvoices,
  ContainerBreadCrumb,
  ContainerButtonBack,
  ContainerCardFull,
  ContainerMessage,
  ContainerMethods,
  ContainerPageNumber,
  ContainerPagination,
  // ContainerSinglePayment,
  Content,
  Divider,
  Main,
  PageNumber,
  // PaymentButton,
  TextList,
  Wrapper,
} from './styles';
import { Container, Input, MessageBox } from '~/common';
import { Button } from '~/components/ButtonV3';
import { BreadCrumb, IDatePage } from './BreadCrumb';
import MyCard from './MyCard';
import { IState } from '~/store/modules/types';
import {
  clearDischargeDebts,
  getDischargeDebtsRequest,
  getErrorCloseDischargeDebts,
  getDischargeDebtsInstallmentsRequest,
  getOutsidesRegistrationUpdateRequest,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { Breakpoints, Color } from '~/styles';
import { Margin } from '~/components/Margin';
import { CardSubtitle, CardTitle } from './MyCard/styles';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { UsefulLinks } from './UsefulLinks';
import Invoice from './Invoice';
import InvoiceDetail from './InvoiceDetail';
import PixSVG from '~/assets/Icons/pix';
import BarCodeSVG from '~/assets/Icons/BarCode';
import EmptyMessage from '~/components/EmptyMessage';
import LoadingComponent from '~/components/Loading';
import { checkCnp } from '~/utils/validation';
import ArrowSVG from '~/assets/Icons/Arrow';
import Navigation from '~/utils/Navigation';
import { useMediaQuery } from 'react-responsive';
// import { PaymentIcon } from '../Invoices/icons';
import { CreditCardIcon } from './Icons';
import { history } from '~/store';
// import ReCAPTCHA from 'react-google-recaptcha';

import configsAppJsonImport from '~/configs/app.config';
import WarningBox from '~/components/WarningBox';
import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';

const configsAppJson = configsAppJsonImport as any;

export interface Values {
  cnp: string;
  invoice: IDadosDebts | null;
  method: 'pixCode' | 'barCode';
  type: 'CPF' | 'CNPJ';
  customError: string;
  invoiceList: IDadosDebts[] | null;
  disabled: boolean;
}

const DischargeDebts: FC = () => {
  const dataHistory = history.location.state as any;

  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const [cnp, setCnp] = useState<string>('');
  const [cnpError, setCnpError] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<IDadosDebts | null>(null);
  const [resetSelectedInvoice, setResetSelectedInvoice] = useState<boolean>(false);
  const [method, setMethod] = useState<'barCode' | 'pixCode' | 'creditCard'>(
    'barCode',
  );
  const [pages, setPages] = useState<Array<number>>([]);
  const [errorCnp, setErrorCnp] = useState<boolean>(false);
  const [errorCnpMessage, setErrorCnpMessage] = useState<string>('');
  const linksDefault: IDatePage[] = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: true,
      onClick: () => {
        dispatch(clearDischargeDebts());
        setSelectedInvoice(null);
      },
    },
  ];
  const [links, setLinks] = useState<IDatePage[]>(linksDefault);
  const [loadingCopy, setLoadingCopy] = useState<boolean>(false);
  const singlePayment = true;
  const [enableSubmit, setEnableSubmit] = useState<boolean>(
    configsAppJson?.NODE_ENV !== 'PRD',
  );

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const loading = useSelector((item: IState) => item.outsides.loadingDischargeDebts);
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );
  const error = useSelector((item: IState) => item.outsides.errorDischargeDebts);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };

  const setErrorEvent = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Acesso] CPF/CNPJ incorreto ou não está na base da iguá',
    });
  };

  useEffect(() => {
    if (dataHistory) {
      setCnp(dataHistory?.cnp);

      if (dataHistory?.typeView === 'detail') {
        setSelectedInvoice(dataHistory?.invoice);
        setResetSelectedInvoice(true);
      }
    } else {
      dispatch(clearDischargeDebts());
    }

    dispatch(getErrorCloseDischargeDebts());
    dispatch(postErrorCloseOutsidesRegistrationUpdate());
  }, []);

  useEffect(() => {
    if (
      (dataDischargeDebts && dataDischargeDebts?.dados?.length > 0) ||
      (dataDischargeDebts && dataDischargeDebts?.dados)
    ) {
      let arrayLinks: IDatePage[] = [...linksDefault];
      arrayLinks[1].active = false;
      arrayLinks.push({
        label: 'Dívidas em aberto',
        active: true,
        onClick: () => setSelectedInvoice(null),
      });

      if (selectedInvoice) {
        arrayLinks[1].active = false;
        arrayLinks[2].active = false;

        arrayLinks.push({
          label:
            method === 'barCode' ? 'Pagamento boleto bancário' : 'Pagamento PIX',
          active: true,
        });
      }
      setLinks(arrayLinks);
    } else {
      setLinks(linksDefault);
    }
  }, [dataDischargeDebts, selectedInvoice, method]);

  useEffect(() => {
    if (
      dataDischargeDebts &&
      dataDischargeDebts?.dados?.length === 1 &&
      dataDischargeDebts?.pagination?.maxPages === 1
    ) {
      setSelectedInvoice(dataDischargeDebts?.dados[0]);
      setMethod(dataDischargeDebts?.dados[0]?.pix_code ? 'pixCode' : 'barCode');
    }
  }, [dataDischargeDebts]);

  useEffect(() => {
    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setErrorCnpMessage('CPF ou CNPJ inválido');
      setErrorCnp(true);
    } else {
      setErrorCnpMessage('');
      setErrorCnp(false);
    }
  }, [cnp]);

  useEffect(() => {
    if (error && error.status === 404) {
      setErrorEvent();
      setErrorCnp(true);
    }
  }, [error]);

  useEffect(() => {
    if (
      dataDischargeDebts?.pagination &&
      dataDischargeDebts?.pagination?.maxPages > 1
    ) {
      let arrayPages = [];
      for (
        let index = 1;
        index <= dataDischargeDebts?.pagination?.maxPages;
        index++
      ) {
        arrayPages.push(index);
      }

      setPages(arrayPages);
    }
  }, [dataDischargeDebts]);

  const getInvoices = async () => {
    if (!cnp) {
      setErrorEvent();
      setErrorCnpMessage('Preenchimento obrigatório');
      setErrorCnp(true);
      return;
    }

    if (cnp) {
      const cnpIsvalid = checkCnp(cnp);

      if (!cnpIsvalid) {
        setErrorEvent();
        setErrorCnpMessage('CPF ou CNPJ inválido');
        setErrorCnp(true);
        return;
      }
    }

    ReactGA.event({
      category: 'Quitação de dívidas',
      action: '[QD][C4][WEB] - Consultar',
    });

    await dispatch(
      getDischargeDebtsRequest({
        ...recaptchaTokens,
        cnp,
        params: {
          page: 1,
          size: 5,
        },
      }),
    );

    dispatch(getOutsidesRegistrationUpdateRequest(cnp));
  };

  return (
    <Main>
      <Container>
        <Recaptcha
          setRecaptchaTokenV3={setRecaptchaTokenV3}
          setRecaptchaTokenV2={setRecaptchaTokenV2}
          showRecaptchaV2={showRecaptcha}
          onClose={() => {
            dispatch(getErrorCloseDischargeDebts());
            dispatch(postErrorCloseOutsidesRegistrationUpdate());
          }}
        />
        <Wrapper>
          {!isMobile && !isTablet && (
            <ContainerBreadCrumb>
              <BreadCrumb links={links} />
            </ContainerBreadCrumb>
          )}
          <Content>
            {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
            {selectedInvoice ||
            (dataHistory?.typeView === 'detail' && !resetSelectedInvoice) ? (
              <MyCard
                type="full"
                title={
                  method === 'barCode'
                    ? 'Pagamento boleto bancário'
                    : 'Pagamento PIX'
                }
              >
                <ContainerCardFull detailInvoice>
                  <Margin height={34} />
                  <InvoiceDetail
                    data={selectedInvoice || dataHistory?.invoice}
                    method={method}
                    cnp={cnp}
                    setLoadingCopy={setLoadingCopy}
                  />
                  <Margin height={44} />
                  <ContainerMethods>
                    <p>Selecione a forma de pagamento</p>
                    <Margin height={25} />
                    <div>
                      {selectedInvoice?.pix_code ? (
                        <>
                          <ButtonPayment
                            type="button"
                            onClick={() => {
                              if (method === 'pixCode' || loadingCopy) return;
                              setMethod('pixCode');
                            }}
                            method={method === 'pixCode' || false}
                          >
                            <PixSVG v3 />
                            <Margin height={5} />
                            PIX
                            <Margin height={5} />
                          </ButtonPayment>
                          <Margin width={isMobile ? 8 : 24} />
                        </>
                      ) : null}
                      <ButtonPayment
                        type="button"
                        onClick={() => {
                          if (method === 'barCode' || loadingCopy) return;
                          setMethod('barCode');
                        }}
                        method={method === 'barCode' || false}
                      >
                        <Margin height={5} />
                        <BarCodeSVG width={38} heigth={30} />
                        <Margin height={5} />
                        Boleto
                      </ButtonPayment>
                      {singlePayment && selectedInvoice?.type === 'INVOICE' && (
                        <>
                          <Margin width={isMobile ? 8 : 24} />
                          <ButtonPayment
                            type="button"
                            onClick={() => {
                              if (method === 'creditCard' || loadingCopy) return;

                              ReactGA.event({
                                category: 'Pagamento',
                                action:
                                  '[QD][C4][WEB] - Pg. de uma única dívida – Cartão de credito',
                              });

                              dispatch(
                                getDischargeDebtsInstallmentsRequest(
                                  cnp,
                                  selectedInvoice?.amount || 0,
                                  {
                                    typeView: 'detail',
                                    invoicesGroup: [
                                      {
                                        ...selectedInvoice,
                                      },
                                    ],
                                    totalAmount: selectedInvoice?.amount,
                                    cnp,
                                  },
                                ),
                              );
                            }}
                            method={method === 'creditCard' || false}
                          >
                            <Margin height={5} />
                            <CreditCardIcon />
                            <Margin height={5} />
                            Cartão de crédito
                          </ButtonPayment>
                        </>
                      )}
                    </div>
                  </ContainerMethods>
                  <Margin height={34} />
                  <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
                  <Margin height={34} />
                  <ContainerButtonBack>
                    <Button
                      type="button"
                      backgroundColor={Color.white}
                      color={Color.gray}
                      onClick={() => setSelectedInvoice(null)}
                    >
                      Voltar
                    </Button>
                  </ContainerButtonBack>
                  <Margin height={74} />
                </ContainerCardFull>
              </MyCard>
            ) : dataDischargeDebts?.dados ? (
              <>
                {loading && <LoadingComponent labelWait="Carregando..." />}
                <MyCard type="full" title="Dívidas em aberto">
                  <ContainerCardFull>
                    {/* {singlePayment ? (
                      <>
                        {dataDischargeDebts?.dados?.length > 1 && (
                          <ContainerSinglePayment>
                            <div>
                              <p>
                                {dataDischargeDebts?.pagination.totalItens} faturas em
                                aberto!
                              </p>
                              <p>
                                Parcele suas faturas em aberto em até 12X sem juros!
                              </p>
                            </div>
                            <PaymentButton
                              type="button"
                              onClick={() => {
                                ReactGA.event({
                                  category: 'Pagamento Único',
                                  action: '[QD][C4][WEB] – Pagamento único',
                                });

                                dispatch(
                                  getDischargeDebtsRequest(
                                    cnp,
                                    {
                                      page: 1,
                                      size: dataDischargeDebts?.pagination.totalItens,
                                    },
                                    {
                                      invoices: dataDischargeDebts?.dados,
                                      typeView: 'group',
                                      enrollment,
                                      cnp,
                                    },
                                  ),
                                );
                              }}
                            >
                              Pagamento único
                              <PaymentIcon />
                            </PaymentButton>
                          </ContainerSinglePayment>
                        )}
                      </>
                    ) : (
                      <>
                        {dataDischargeDebts?.dados?.length > 1 && !isMobile && (
                          <ContainerMessage>
                            <MessageBox
                              title="Atenção!"
                              variant="loading"
                              icon="warning"
                            >
                              <Margin height={4} />
                              Para ter acesso à conta detalhada, pagamentos com
                              cartão de crédito, parcelamento de fatura e muito mais,{' '}
                              <Link to="/login">faça seu login!</Link>
                            </MessageBox>
                          </ContainerMessage>
                        )}
                      </>
                    )} */}
                    {!isMobile && dataDischargeDebts?.dados?.length < 1 && (
                      <ContainerMessage>
                        <Margin height={isMobile ? 28 : isTablet ? 34 : 51} />
                        <MessageBox
                          title="Atenção!"
                          variant="loading"
                          icon="warning"
                        >
                          <Margin height={4} />
                          Para ter acesso à conta detalhada, pagamentos com cartão de
                          crédito, parcelamento de fatura e muito mais,{' '}
                          <Link to="/login">faça seu login!</Link>
                        </MessageBox>
                      </ContainerMessage>
                    )}
                    {dataDischargeDebts?.dados?.length > 0 ? (
                      <>
                        <TextList>
                          Parcelas de serviço, negociações e outras cobranças
                          vinculadas ao seu CPF/CNPJ:
                        </TextList>
                        {dataDischargeDebts?.dados?.map((invoice: any) => (
                          <ContaineInvoices key={invoice.invoice_id}>
                            <Margin height={16} />
                            <Invoice
                              key={invoice.invoice_id}
                              data={invoice}
                              setSelectedInvoice={setSelectedInvoice}
                              setMethod={setMethod}
                            />
                          </ContaineInvoices>
                        ))}
                      </>
                    ) : (
                      <>
                        <Margin height={40} />
                        <EmptyMessage textMessage="CPF/CNPJ não possui dívidas em aberto" />
                        <Margin height={252} />
                      </>
                    )}
                    <Margin height={26} />
                    {dataDischargeDebts?.pagination?.maxPages > 1 && (
                      <ContainerPagination>
                        <button
                          type="button"
                          onClick={() => {
                            if (dataDischargeDebts?.pagination?.page === 1) return;
                            if (dataDischargeDebts?.pagination?.page) {
                              dispatch(
                                getDischargeDebtsRequest({
                                  ...recaptchaTokens,
                                  cnp,
                                  params: {
                                    page: dataDischargeDebts?.pagination?.page - 1,
                                    size: 5,
                                  },
                                }),
                              );
                            }
                          }}
                          className="change-page"
                        >
                          <ArrowSVG />
                        </button>
                        <Margin width={16} />
                        <ContainerPageNumber>
                          {pages.map((page) => (
                            <PageNumber
                              key={page}
                              isActive={
                                page === dataDischargeDebts?.pagination?.page
                              }
                              type="button"
                              onClick={() => {
                                if (dataDischargeDebts?.pagination?.page === page)
                                  return;
                                if (dataDischargeDebts?.pagination?.page) {
                                  dispatch(
                                    getDischargeDebtsRequest({
                                      ...recaptchaTokens,
                                      cnp,
                                      params: {
                                        page,
                                        size: 5,
                                      },
                                    }),
                                  );
                                }
                              }}
                            >
                              {page}
                            </PageNumber>
                          ))}
                        </ContainerPageNumber>
                        <Margin width={16} />
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              dataDischargeDebts?.pagination?.page ===
                              dataDischargeDebts?.pagination?.maxPages
                            )
                              return;
                            if (dataDischargeDebts?.pagination?.page) {
                              dispatch(
                                getDischargeDebtsRequest({
                                  ...recaptchaTokens,
                                  cnp,
                                  params: {
                                    page: dataDischargeDebts?.pagination?.page + 1,
                                    size: 5,
                                  },
                                }),
                              );
                            }
                          }}
                          className="change-page"
                        >
                          <ArrowSVG />
                        </button>
                      </ContainerPagination>
                    )}
                    <Margin height={16} />
                    <ContainerButtonBack>
                      <Button
                        type="button"
                        color={Color.gray}
                        onClick={() => {
                          setCnp('');
                          dispatch(clearDischargeDebts());
                          setEnableSubmit(configsAppJson?.NODE_ENV !== 'PRD');
                        }}
                      >
                        Voltar
                      </Button>
                    </ContainerButtonBack>
                    <Margin height={56} />
                  </ContainerCardFull>
                </MyCard>
                {isMobile && dataDischargeDebts?.dados?.length < 1 && (
                  <>
                    <Margin height={32} />
                    <MessageBox title="Atenção!" variant="loading" icon="warning">
                      <Margin height={4} />
                      Para ter acesso à conta detalhada, dados de consumo e muito
                      mais,
                      <Link to="/login"> faça seu login!</Link>
                    </MessageBox>
                  </>
                )}
              </>
            ) : (
              <>
                <MyCard
                  title="QUITAÇÃO DE DÍVIDAS"
                  subtitle="Digite o CPF/CNPJ do titular para consultar seus débitos."
                >
                  <Input
                    name="cnp"
                    label="CPF ou CNPJ*"
                    value={cnp}
                    onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
                    error={errorCnp}
                    errorText={errorCnpMessage}
                    borderColor={Color.blueIntense}
                    inputMode="numeric"
                  />
                  <Margin height={isMobile ? 12 : 24} />
                  {/* {configsAppJson?.NODE_ENV === 'PRD' && (
                    <>
                      <Margin height={24} />
                      <div
                        style={{
                          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                          margin: 'auto',
                          width: 'min-content',
                        }}
                      >
                        <ReCAPTCHA
                          sitekey={
                            configsAppJson?.GOOGLE_RECAPTCHA[
                              configsAppJson?.NODE_ENV
                            ]
                          }
                          onErrored={() => setEnableSubmit(false)}
                          onExpired={() => setEnableSubmit(false)}
                          onChange={(j) => setEnableSubmit(!!j)}
                          size="normal"
                        />
                      </div>
                    </>
                  )} */}
                  <Margin height={isMobile ? 12 : 24} />
                  <Button
                    type="button"
                    loading={loading}
                    backgroundColor={disabled ? Color.grayLightSeconde : Color.green}
                    disabled={disabled}
                    onClick={getInvoices}
                    style={{
                      height: '48px',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Consultar
                  </Button>
                  <Margin height={isMobile ? 0 : 60} />
                </MyCard>
                {isMobile && selectedInvoice && (
                  <>
                    <Margin height={20} />
                    <MessageBox title="Atenção!" variant="loading" icon="warning">
                      <Margin height={4} />
                      Para ter acesso à conta detalhada, dados de consumo e muito
                      mais,
                      <Link to="/login"> faça seu login!</Link>
                    </MessageBox>
                  </>
                )}
                {!isMobile && (
                  <>
                    {!isTablet && <Divider />}
                    {isTablet && <Margin height={81} />}
                    <MyCard type="link">
                      <CardTitle style={{ textAlign: 'left' }}>
                        Serviços digi iguá
                      </CardTitle>
                      <CardSubtitle style={{ textAlign: 'left' }}>
                        Faça seu <Link to={RouteName.LOGIN}>login</Link> e tenha
                        acesso a muito mais!
                      </CardSubtitle>
                      <UsefulLinks />
                    </MyCard>
                  </>
                )}
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default DischargeDebts;
