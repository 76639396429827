import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Status from './Status';
import Form from './Form';

const DocumentationApprovalStep: FC = () => {
  const dataItem = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.step,
  );

  const status = (dataItem?.status || '') as stepStatusEnum;

  return (
    <>
      {[stepStatusEnum.FORM, stepStatusEnum.REVIEW].includes(status) && <Form />}
      {(dataItem?.status === stepStatusEnum.WAIT ||
        dataItem?.status === stepStatusEnum.SUCCESS) && <Status />}
    </>
  );
};

export default DocumentationApprovalStep;
