import styled from 'styled-components';
import { Form } from 'formik';
import { Color, Breakpoints } from '~/styles';

export const Wrapper = styled(Form)`
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    padding: 40px;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 40px 24px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: ${Color.grayLight};
`;

export const NavWrapper = styled.div`
  display: flex;
  gap: 24px;
  button.next {
    margin-left: auto;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
    button.next {
      order: 1;
    }
    button.back {
      order: 2;
    }
  }
`;

export const ErrorMsg = styled.div`
  margin-bottom: 24px;
  max-width: 400px;
  color: ${Color.gray};
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
`;
