import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}

const NextSVG = ({ color = Color.white, width = 14, height = 16 }: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62903 15.8103C6.57033 15.8704 6.50015 15.9181 6.42263 15.9506C6.34511 15.9832 6.26182 16 6.17768 16C6.09354 16 6.01025 15.9832 5.93273 15.9506C5.85521 15.9181 5.78503 15.8704 5.72634 15.8103C5.60599 15.6873 5.53865 15.5224 5.53865 15.3506C5.53865 15.1789 5.60599 15.0139 5.72634 14.8909L12.473 8.00137L5.72634 1.11046C5.6656 1.04856 5.61771 0.975344 5.5854 0.89501C5.55309 0.814675 5.53699 0.728789 5.53802 0.642258C5.53905 0.555728 5.55718 0.470245 5.5914 0.390693C5.62561 0.311143 5.67522 0.239081 5.73741 0.178624C5.79665 0.120991 5.86671 0.0755527 5.9436 0.0449102C6.0205 0.0142686 6.1027 -0.000977779 6.18551 4.74132e-05C6.26833 0.00107165 6.35013 0.0183455 6.42623 0.0508801C6.50234 0.0834147 6.57125 0.13057 6.62902 0.18965L13.8035 7.52029C13.9295 7.649 14 7.82163 14 8.00137C14 8.18111 13.9295 8.35374 13.8035 8.48245L6.62903 15.8103ZM1.09105 15.8103C1.03236 15.8704 0.962178 15.9181 0.884659 15.9506C0.807139 15.9832 0.723851 16 0.63971 16C0.55557 16 0.472281 15.9832 0.394761 15.9506C0.317242 15.9181 0.247063 15.8704 0.188366 15.8103C0.0680208 15.6873 0.000677067 15.5224 0.000677052 15.3506C0.000677037 15.1789 0.0680207 15.0139 0.188366 14.8909L6.935 8.00137L0.188365 1.11046C0.127632 1.04856 0.079743 0.975345 0.0474306 0.89501C0.0151192 0.814676 -0.000982666 0.72879 4.53874e-05 0.642259C0.00107439 0.555728 0.0192123 0.470246 0.0534254 0.390694C0.0876375 0.311143 0.137252 0.239082 0.19944 0.178624C0.258673 0.120991 0.328742 0.0755532 0.405633 0.0449107C0.482523 0.0142691 0.564727 -0.000977295 0.647543 4.78974e-05C0.730358 0.00107214 0.812158 0.018346 0.88826 0.0508806C0.964364 0.0834152 1.03328 0.130571 1.09105 0.189651L8.2655 7.52029C8.3915 7.649 8.46203 7.82163 8.46203 8.00137C8.46203 8.18111 8.3915 8.35374 8.2655 8.48245L1.09105 15.8103Z"
        fill={color}
      />
    </svg>
  );
};

export default NextSVG;
