import React, { Dispatch, FC, SetStateAction } from 'react';
import ReactGA from 'react-ga4';

import { Color } from '~/styles';
import ContinueButton from '../ContinueButton';
import BackButton from '../BackButton';
import {
  ButtonPromotionalDiscounts,
  Main,
  RowTextSummaryNegociation,
  SummaryNegociation,
  TextButtonDiscounts,
  TextSummaryNegociation,
} from './styles';
import { DiscountIcon } from '../Icons';

interface IStepThreeProps {
  setStep: Dispatch<SetStateAction<number>>;
  setOpenTerm: Dispatch<SetStateAction<boolean>>;
}

const StepThree: FC<IStepThreeProps> = ({ setStep, setOpenTerm }) => {
  const handleBackOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 03 – Simular outra negociação',
    });

    setStep(0);
  };

  const handleOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 03 – Quero negociar.',
    });

    setOpenTerm(true);
  };

  return (
    <>
      <Main>
        <ButtonPromotionalDiscounts type="button">
          <div>
            <DiscountIcon />
          </div>
          <TextButtonDiscounts>Você ganhou 50% de desconto</TextButtonDiscounts>
        </ButtonPromotionalDiscounts>
        <SummaryNegociation>
          <RowTextSummaryNegociation>
            <TextSummaryNegociation>Entrada:</TextSummaryNegociation>
            <TextSummaryNegociation>R$ 2,00</TextSummaryNegociation>
          </RowTextSummaryNegociation>
          <RowTextSummaryNegociation>
            <TextSummaryNegociation>Parcelamento:</TextSummaryNegociation>
            <TextSummaryNegociation>3 x de R$ 1,00</TextSummaryNegociation>
          </RowTextSummaryNegociation>
        </SummaryNegociation>
      </Main>
      <ContinueButton
        label="Quero negociar"
        backgroundColor={Color?.blueSeconde}
        onClick={handleOnClick}
      />
      <BackButton label="Simular outra negociação" onClick={handleBackOnClick} />
    </>
  );
};

export default StepThree;
