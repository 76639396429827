import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 850px !important;

  button {
    width: 100%;
    max-width: 252px;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    filter: none;
  }

  button:first-of-type {
    svg {
      left: 16px;
    }
  }

  button:last-of-type {
    svg {
      right: 16px;
    }
  }
`;
