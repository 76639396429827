import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.grayLightSeconde};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;
