import React from 'react';
import * as S from './styles';
import { BtnText } from '~/components/Btn';
import SmartImg from '~/assets/Images/smart-light.png';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

export const TrialBanner = () => {
  return (
    <S.Trial>
      <S.Container>
        <S.TrialWrapper>
          <img src={SmartImg} alt="cliente iluminado" />
          <S.TrialInfo>
            <div className="title">Aproveite seu período de teste</div>
            <div className="subtitle">
              Conheça os benefícios da medição inteligente!
            </div>
          </S.TrialInfo>
          <S.TrialCTA>
            <BtnText onClick={() => Navigation.navigate(RouteName.SMARTMETER)}>
              Saiba Mais
            </BtnText>
          </S.TrialCTA>
        </S.TrialWrapper>
      </S.Container>
    </S.Trial>
  );
};
