import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Container = styled.div`
  max-width: 1010px;
  margin: 0px auto;
`;

export const Trial = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px 24px;
  background: linear-gradient(180deg, ${Color.yellow} 20%, ${Color.yellow}00);
`;

export const TrialWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr auto;
  gap: 24px;
`;

export const TrialInfo = styled.div`
  color: ${Color.blueIntense};
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    @media (max-width: ${Breakpoints.tablet.max}px) {
      line-height: 1;
      margin-bottom: 12px;
    }
  }
  .subtitle {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const TrialCTA = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  button {
    color: #91460a;
    text-decoration: underline;
  }
`;
