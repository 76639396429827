import React, { FC } from 'react';
import EmptySVG from '~/assets/Icons/Empty';
import BannerImg from '~/assets/Images/simplified-invoice-banner-pix-02.png';
import { Banner, EmptyWrapper } from './styles';

interface IProps {
  existPix: boolean;
}

export const BannerPix: FC<IProps> = ({ existPix }) => {
  return (
    <Banner>
      {existPix ? (
        <img src={BannerImg} alt="pix" />
      ) : (
        <EmptyWrapper>
          <EmptySVG />
          Pagamento PIX indisponível!
        </EmptyWrapper>
      )}
    </Banner>
  );
};
