import { Dispatch } from 'redux';
import { IDataLoginCredentials, RepositoriesTypesLoginCredentials } from './types';

const saveLoginCredentials =
  (data: IDataLoginCredentials) => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesLoginCredentials.ENROLLMENTS_LOGIN_CREDENTIALS,
      payload: {
        data: {
          remember: data?.remember ? data?.remember : false,
          cnp: data?.remember ? data?.cnp : '',
          password: data?.remember ? data?.password : '',
        },
      },
    });
  };

export { saveLoginCredentials };
