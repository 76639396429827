import { Reducer } from 'redux';
import {
  RepositoriesTypesNotifications,
  IRepositoriesStateNotifications,
} from './types';

const INITIAL_STATE: IRepositoriesStateNotifications = {
  modalNotifications: false,
  data: [],
  loading: false,
  error: null,
};

const reducer: Reducer<IRepositoriesStateNotifications, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesNotifications.NOTIFICATIONS_ACTIVATE_MODAL:
      return {
        ...state,
        modalNotifications: true,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_CLEAR_MODAL:
      return {
        ...state,
        modalNotifications: false,
      };

    case RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
