import React from 'react';

function CloseSVG() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 30C12.287 30 8.72601 28.525 6.1005 25.8995C3.475 23.274 2 19.713 2 16C2 12.287 3.475 8.72601 6.1005 6.1005C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32Z"
        fill="#C7C9C7"
      />
      <path
        d="M9.29379 9.29379C9.38668 9.20066 9.49703 9.12677 9.61852 9.07636C9.74001 9.02595 9.87025 9 10.0018 9C10.1333 9 10.2636 9.02595 10.3851 9.07636C10.5065 9.12677 10.6169 9.20066 10.7098 9.29379L16.0018 14.5878L21.2938 9.29379C21.3868 9.20081 21.4971 9.12706 21.6186 9.07674C21.7401 9.02642 21.8703 9.00052 22.0018 9.00052C22.1333 9.00052 22.2635 9.02642 22.385 9.07674C22.5064 9.12706 22.6168 9.20081 22.7098 9.29379C22.8028 9.38676 22.8765 9.49714 22.9268 9.61862C22.9772 9.7401 23.003 9.8703 23.003 10.0018C23.003 10.1333 22.9772 10.2635 22.9268 10.385C22.8765 10.5064 22.8028 10.6168 22.7098 10.7098L17.4158 16.0018L22.7098 21.2938C22.8028 21.3868 22.8765 21.4971 22.9268 21.6186C22.9772 21.7401 23.003 21.8703 23.003 22.0018C23.003 22.1333 22.9772 22.2635 22.9268 22.385C22.8765 22.5064 22.8028 22.6168 22.7098 22.7098C22.6168 22.8028 22.5064 22.8765 22.385 22.9268C22.2635 22.9772 22.1333 23.003 22.0018 23.003C21.8703 23.003 21.7401 22.9772 21.6186 22.9268C21.4971 22.8765 21.3868 22.8028 21.2938 22.7098L16.0018 17.4158L10.7098 22.7098C10.6168 22.8028 10.5064 22.8765 10.385 22.9268C10.2635 22.9772 10.1333 23.003 10.0018 23.003C9.8703 23.003 9.7401 22.9772 9.61862 22.9268C9.49714 22.8765 9.38676 22.8028 9.29379 22.7098C9.20081 22.6168 9.12706 22.5064 9.07674 22.385C9.02642 22.2635 9.00052 22.1333 9.00052 22.0018C9.00052 21.8703 9.02642 21.7401 9.07674 21.6186C9.12706 21.4971 9.20081 21.3868 9.29379 21.2938L14.5878 16.0018L9.29379 10.7098C9.20066 10.6169 9.12677 10.5065 9.07636 10.3851C9.02595 10.2636 9 10.1333 9 10.0018C9 9.87025 9.02595 9.74001 9.07636 9.61852C9.12677 9.49703 9.20066 9.38668 9.29379 9.29379Z"
        fill="#C7C9C7"
      />
    </svg>
  );
}

export default CloseSVG;
