import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface ICard {
  miniCard?: boolean;
  hiddenBorder?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 18px 8px 134px 8px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 22px 0 64px 0;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 16px);

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: auto;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    flex-direction: row;
    display: inherit;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
    padding: 0 63px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
    padding: 0;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 26px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    justify-content: center;
    gap: 49px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 21px;
  }
`;

export const Card = styled.div<ICard>`
  width: calc(100% - 28px);
  min-height: 470px;
  padding: 24px 14px 64px 14px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  border-top: 24px solid ${Color.blueIntense};

  @media (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 640px;
    max-width: calc(870px - 140px);
    padding: 24px 80px 64px 80px;
    align-self: flex-start;
  }

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 40px;
  }

  .line-icon {
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      margin-right: 16px;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  ${({ miniCard }) =>
    miniCard &&
    css`
      min-height: auto;
      border-radius: 16px 16px 0px 0px;
      align-items: flex-start;
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
      align-self: baseline;

      div {
        &.mini-card-top {
          display: flex;
          flex-direction: column;
          align-items: start;
          border-radius: 8px;
          background: #f2f2f2;
          padding: 6px 16px;
          width: calc(100% - 32px);
        }
      }

      h2,
      button {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.blueIntense};
        text-align: left;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        text-decoration: underline;
      }
      p,
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${Color.gray};
        text-align: left;
      }

      @media (min-width: ${Breakpoints.laptop.min}px) {
        padding: 24px 31px 35px 31px;
        max-width: calc(270px - 62px);
        min-height: auto;
      }
    `}

  ${({ hiddenBorder }) =>
    hiddenBorder &&
    css`
      min-height: auto;
      width: 100%;
      padding: 0 0 103px 0;
      border-top: 0;
      border-radius: 0px 0px 16px 16px;
      align-self: flex-start;

      h3 {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: ${Color.blueIntense};
        white-space: nowrap;
        background: #f2f2f2;
        margin-bottom: 32px;
      }

      div {
        padding: 0 43px 0 40px;

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: ${Color.blueIntense};
          margin-bottom: 8px;
          display: list-item;
        }
      }

      @media (min-width: ${Breakpoints.laptop.min}px) {
        padding: 0 0 135px 0;
        max-width: 381px;
      }

      @media (min-width: ${Breakpoints.desktopLarge.min}px) {
        padding: 0 0 135px 0;
        max-width: 551px;
      }

      @media (min-width: ${Breakpoints.desktopLarge.max}px) {
        padding: 0 0 205px 0;
        max-width: 270px;
      }
    `}
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  display: none;

  div {
    flex-basis: 5%;
    height: 100%;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    flex-basis: 35%;

    &.small {
      flex-basis: 25%;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 28px);
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);
    margin-bottom: 54px;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const ContainerSinglePayment = styled.div`
  width: inherit;
  min-height: 64px;
  background: #f2f2f2;
  border: 1px solid #004b55;
  border-radius: 8px;
  margin-bottom: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 11px 8px 10px 8px;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
    text-align: center;

    :last-of-type {
      font-weight: 500;
      color: #000;
      margin-bottom: 14px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    padding: 0 16px 0 24px;

    p {
      text-align: left;

      :last-of-type {
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
`;

export const PaymentButton = styled.button`
  height: 36px;
  max-width: 196px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${Color.white};
  background: ${Color.blueIntense};
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }
`;
