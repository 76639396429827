import React, { FC, ReactNode } from 'react';

import { Main } from './styles';
import { CongratulationsIcon, SuccessIcon } from './Icons';

interface IProps {
  title?: string;
  label?: string;
  children?: ReactNode;
  isCongratulationsIcon?: boolean;
}

const SuccessBox: FC<IProps> = ({
  title,
  label,
  children,
  isCongratulationsIcon,
}) => {
  return (
    <Main>
      <div>{isCongratulationsIcon ? <CongratulationsIcon /> : <SuccessIcon />}</div>
      <div>
        <p>{title || 'Sucesso'}</p>
        <p>
          {label}
          {children && children}
        </p>
      </div>
    </Main>
  );
};

export default SuccessBox;
