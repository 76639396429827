export const DpaDpeLocationsEnum = {
  MIGUEL: 'miguel',
  RIO: 'rio',
  CUIABA: 'cuiaba',
  PATY: 'paty',
};

export const DpaDpeLocationsLabelEnum = {
  MIGUEL: 'Miguel Pereira',
  RIO: 'Rio de Janeiro',
  CUIABA: 'Cuiabá',
  PATY: 'Paty do Alferes',
};

const DpaDpeLocationsLabelMap = {} as any;

DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.MIGUEL] =
  DpaDpeLocationsEnum.MIGUEL;
DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.RIO] = DpaDpeLocationsEnum.RIO;
DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.CUIABA] =
  DpaDpeLocationsEnum.CUIABA;
DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.PATY] = DpaDpeLocationsEnum.PATY;

export { DpaDpeLocationsLabelMap };
