import styled from 'styled-components';

import { Color } from '../../../styles/colors';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 80px);
  max-width: calc(400px - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 17px;
  background: ${Color.white};
  padding: 36px 20px 30px 20px;
  border-radius: 16px;
  border: 1px solid ${Color.yellow};
  gap: 16px;

  svg {
    width: 64px;
    height: 64px;
    border-radius: 100px;
  }

  h1 {
    color: ${Color.yellow};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    color: ${Color.black};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    b {
      font-weight: 700;
    }
  }

  button {
    filter: none !important;
    font-weight: 500;

    :last-child {
      text-decoration: underline;
    }
  }

  #sim-modal-aviso {
    border-radius: 8px;
    background: ${Color.yellow};
    height: 48px;
    filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 36px 40px 50px 40px;
  }
`;
