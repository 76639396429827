import React from 'react';

const NotificationIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4552 18.7186V13.5684C23.4552 11.1269 22.468 8.77795 20.6987 7.04034C18.9281 5.30153 16.5192 4.31836 14 4.31836C11.4808 4.31836 9.07185 5.30153 7.30132 7.04034C5.53202 8.77795 4.5448 11.1269 4.5448 13.5684V18.7186L2.36678 24.0683H25.6332L23.4552 18.7186ZM27.9092 24.3546C27.9863 24.5442 28.015 24.7495 27.9927 24.9524C27.9704 25.1554 27.8978 25.35 27.7812 25.519C27.6647 25.6881 27.5078 25.8266 27.3242 25.9223C27.1406 26.0181 26.9359 26.0682 26.728 26.0683H1.272C1.06411 26.0682 0.859417 26.0181 0.675816 25.9223C0.492214 25.8266 0.33529 25.6881 0.21876 25.519C0.102231 25.35 0.0296444 25.1554 0.00734444 24.9524C-0.0149555 24.7495 0.0137102 24.5442 0.0908364 24.3546L2.5448 18.3271V13.5684C2.5448 10.5847 3.75168 7.72319 5.89995 5.61341C8.04821 3.50362 10.9619 2.31836 14 2.31836C17.0381 2.31836 19.9518 3.50362 22.1001 5.61341C24.2483 7.72319 25.4552 10.5847 25.4552 13.5684V18.3271L27.9092 24.3546ZM10.1244 30.5683C9.62447 29.9929 9.26126 29.3098 9.0679 28.5683H18.9321C18.7387 29.3098 18.3755 29.9929 17.8756 30.5683C17.6508 30.827 17.3984 31.064 17.1213 31.2746C16.2281 31.9538 15.1297 32.3211 14 32.3183C12.8703 32.3211 11.7719 31.9538 10.8787 31.2746C10.6016 31.064 10.3492 30.827 10.1244 30.5683Z"
        fill="white"
      />
    </svg>
  );
};

export { NotificationIcon };
