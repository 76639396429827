import React from 'react';

const StatusWarningIconSVG = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="43.2242" rx="8" fill="#FAB419" />
      <path
        d="M22 17.4152V23.169M22.5754 26.6212C22.5754 26.939 22.3178 27.1966 22 27.1966C21.6822 27.1966 21.4246 26.939 21.4246 26.6212C21.4246 26.3034 21.6822 26.0458 22 26.0458C22.3178 26.0458 22.5754 26.3034 22.5754 26.6212ZM14.3046 31.2242H29.6954C31.4273 31.2242 32.5386 29.3834 31.732 27.8508L24.0366 13.2296C23.1738 11.5901 20.8262 11.5901 19.9634 13.2296L12.268 27.8508C11.4614 29.3834 12.5727 31.2242 14.3046 31.2242Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default StatusWarningIconSVG;
