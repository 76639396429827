import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 48px);
  padding: 0 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0;
  }
`;

export const ContainerFile = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
