import { Color } from '~/styles/colors';

export const socialTariffStatus = (statusLabel: string) => {
  switch (statusLabel.toUpperCase()) {
    case 'FINALIZADO COM SUCESSO':
      return {
        color: Color.greenLight,
        label: 'Ótimo! Sua tarifa social foi aceita.',
        colorRequest: Color.green,
        labelRequest: 'Concluído',
      };
    case 'RESOLVIDO PELO ATENDIMENTO':
      return {
        color: Color.greenLight,
        label: 'Ótimo! Sua tarifa social foi aceita.',
        colorRequest: Color.green,
        labelRequest: 'Concluído',
      };
    case 'RESOLVIDO PELA OUVIDORIA':
      return {
        color: Color.greenLight,
        label: 'Ótimo! Sua tarifa social foi aceita.',
        colorRequest: Color.green,
        labelRequest: 'Concluído',
      };
    case 'DECLARAÇÃO EMITIDA':
      return {
        color: Color.greenLight,
        label: 'Ótimo! Sua tarifa social foi aceita.',
        colorRequest: Color.green,
        labelRequest: 'Concluído',
      };

    case 'NOVO':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.blueSeconde,
        labelRequest: 'Solicitação aberta',
      };
    case 'EM ABERTO':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.blueSeconde,
        labelRequest: 'Solicitação aberta',
      };
    case 'AGUARDANDO APROVAÇÃO':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.blueSeconde,
        labelRequest: 'Solicitação aberta',
      };
    case 'EM ANÁLISE':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.yellow,
        labelRequest: 'Em análise',
      };
    case 'EM TRÂMITE':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.yellow,
        labelRequest: 'Em análise',
      };
    case 'EM ANDAMENTO':
      return {
        color: Color.Blue,
        label:
          'Ótimo! Você já solicitou sua tarifa social, acompanhe aqui o status da sua solicitação.',
        colorRequest: Color.yellow,
        labelRequest: 'Em análise',
      };

    case 'AGUARDANDO RESPOSTA DO CLIENTE':
      return {
        color: Color.yellowSeconde,
        label: 'Atenção! Documentação pendente, acompanhe aqui sua solicitação.',
        colorRequest: Color.yellow,
        labelRequest: 'Revisar documentos',
      };
    case 'REVISAR DOCUMENTOS':
      return {
        color: Color.yellowSeconde,
        label: 'Atenção! Documentação pendente, acompanhe aqui sua solicitação.',
        colorRequest: Color.yellow,
        labelRequest: 'Revisar documentos',
      };

    case 'CANCELADO':
      return {
        color: Color.redLight,
        label:
          'Atenção! Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };
    case 'CANCELADO.':
      return {
        color: Color.redLight,
        label:
          'Atenção! Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };
    case 'FINALIZADO SEM SUCESSO':
      return {
        color: Color.redLight,
        label:
          'Atenção! Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };
    case 'FECHADO POR FALTA DE CONTATO':
      return {
        color: Color.redLight,
        label:
          'Atenção! Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };
    case 'FINALIZADO':
      return {
        color: Color.redLight,
        label:
          'Atenção! Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };

    default:
      return {
        color: Color.yellowSeconde,
        label:
          'Atenção! Solicitação expirada. Para dar continuidade ao benefício é necessário recadastramento!',
        colorRequest: Color.red,
        labelRequest: 'Recusado',
      };
  }
};

export const socialTariffStatusCuiaba = (statusLabel: string) => {
  switch (statusLabel?.toUpperCase()) {
    case 'FINALIZADO COM SUCESSO':
      return {
        color: Color.green,
        label: 'Documentação aprovada',
        labelDescriptionDetail: 'Sua solicitação foi concluída com sucesso!',
      };
    case 'RESOLVIDO PELO ATENDIMENTO':
      return {
        color: Color.green,
        label: 'Documentação aprovada',
        labelDescriptionDetail: 'Sua solicitação foi concluída com sucesso!',
      };
    case 'RESOLVIDO PELA OUVIDORIA':
      return {
        color: Color.green,
        label: 'Documentação aprovada',
        labelDescriptionDetail: 'Sua solicitação foi concluída com sucesso!',
      };
    case 'DECLARAÇÃO EMITIDA':
      return {
        color: Color.green,
        label: 'Documentação aprovada',
        labelDescriptionDetail: 'Sua solicitação foi concluída com sucesso!',
      };

    case 'NOVO':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'EM ABERTO':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'AGUARDANDO APROVAÇÃO':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'EM ANÁLISE':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'EM TRÂMITE':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'EM ANDAMENTO':
      return {
        color: Color.blueSeconde,
        label: 'Aguarde',
        labelDescription: 'Favor, aguarde! Estamos avaliando sua solicitação.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };

    case 'AGUARDANDO RESPOSTA DO CLIENTE':
      return {
        color: Color.yellow,
        label: 'Atenção',
        hideButton: true,
        labelDescription:
          'Documentação pendente, fique atento ao email e ao celular cadastrado! Um representante da águas Cuiabá entrará em contato.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };
    case 'REVISAR DOCUMENTOS':
      return {
        color: Color.yellow,
        label: 'Atenção',
        hideButton: true,
        labelDescription:
          'Documentação pendente, fique atento ao email e ao celular cadastrado! Um representante da águas Cuiabá entrará em contato.',
        labelDescriptionDetail: 'Favor, aguarde! Estamos avaliando sua solicitação.',
      };

    case 'CANCELADO':
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        labelDescriptionDetail: '',
      };
    case 'CANCELADO.':
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        labelDescriptionDetail: '',
      };
    case 'FINALIZADO SEM SUCESSO':
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        labelDescriptionDetail: '',
      };
    case 'FECHADO POR FALTA DE CONTATO':
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        labelDescriptionDetail: '',
      };
    case 'FINALIZADO':
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Sua solicitação foi recusada. Para maiores informações, entre em contato com sua operação!',
        labelDescriptionDetail: '',
      };

    default:
      return {
        color: Color.yellow,
        label: 'Atenção',
        labelDescription:
          'Solicitação expirada. Para dar continuidade, é necessário solicitar novamente o benefício!',
        labelDescriptionDetail: '',
      };
  }
};
