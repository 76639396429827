import React from 'react';

function HistorySubtitleLineIconSVG() {
  return (
    <svg
      width="17"
      height="3"
      viewBox="0 0 17 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.73242"
        y1="1.05078"
        x2="15.7799"
        y2="1.05078"
        stroke="#37B482"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
export default HistorySubtitleLineIconSVG;
