import React from 'react';
import { Color } from '~/styles';

interface IProps {
  color?: string;
}

function MenuSVG({ color = Color.blueIntense }: IProps) {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="menu-dropdown"
    >
      <rect
        className="menu-dropdown"
        width="24"
        height="3.5557"
        rx="1.77785"
        fill={color}
      />
      <rect
        className="menu-dropdown"
        y="12.4443"
        width="24"
        height="3.5557"
        rx="1.77785"
        fill={color}
      />
      <rect
        className="menu-dropdown"
        y="6.22192"
        width="24"
        height="3.5557"
        rx="1.77785"
        fill={color}
      />
    </svg>
  );
}
export default MenuSVG;
