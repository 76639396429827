import React, { FC } from 'react';
import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';

interface IProps {
  onClose: () => void;
}

const ModalInfoAddress: FC<IProps> = ({ onClose }) => {
  return (
    <Main>
      <StatusWarningIconSVG />
      <h1>Atenção</h1>
      <p>
        Não foi possível dar continuidade à sua solicitação.
        <br />
        <br />
        Para solicitar tarifa social, entre em contato com a sua operação através dos
        nossos canais de atendimento.
      </p>
      <ButtonText
        id="button-close-modal"
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="Entendi"
        onClick={onClose}
      />
    </Main>
  );
};

export default ModalInfoAddress;
