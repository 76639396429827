import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Route } from 'react-router-dom';

import { RouteName } from './Routes.name';

import ForgotPassword from '~/views/ForgotPassword';
import ForgotPasswordMethod from '~/views/ForgotPasswordMethod';
import ForgotPasswordCheckCode from '~/views/ForgotPasswordCheckCode';
import ForgotPasswordNewPassword from '~/views/ForgotPasswordNewPassword';
import Landing from '~/views/Landing';
import Login from '~/views/Login';
import Register from '~/views/Register';
import RegisterVerificationMethod from '~/views/RegisterVerificationMethod';
import RegisterCheckCode from '../views/RegisterCheckCode';
import RegisterClientPassword from '~/views/RegisterClientPassword';
import RegisterNumberPhone from '~/views/RegisterNumberPhone';
import RegisterEntrepreneur from '~/views/RegisterEntrepreneur';
import RegisterNotClient from '~/views/RegisterNotClient';
import RegisterManager from '~/views/RegisterManager';
import RegisterProfile from '~/views/RegisterProfile';
import GlobalLayout from '~/components/GlobalLayout';
import EasyDuplicate from '~/views/EasyDuplicate';
import EasyDuplicateSinglePayment from '~/views/EasyDuplicateSinglePayment';
import EasyDuplicateSinglePaymentDataPayment from '~/views/EasyDuplicateSinglePaymentDataPayment';
import EasyDuplicateSinglePaymentDataPaymentReceipt from '~/views/EasyDuplicateSinglePaymentDataPaymentReceipt';
import { Dialog } from '~/components/Dialog';

import { IState } from '~/store/modules/types';
import { getTogglesRequest } from '~/store/modules/toggles/actions';
import {
  clearModalDataUpdate,
  clearModalSalesforceSatisfaction,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import PasswordExpirationNewPassword from '~/views/PasswordExpirationNewPassword';
import PasswordExpiration from '~/views/PasswordExpiration';
import PasswordExpirationCheckCode from '~/views/PasswordExpirationCheckCode';
import ModalSalesforceSatisfaction from '~/components/ModalSalesforceSatisfaction';
import { checkToggle } from '~/utils/toggles';
import DischargeDebts from '~/views/DischargeDebts';
import DischargeDebtsSinglePayment from '~/views/DischargeDebtsSinglePayment';
import DischargeDebtsSinglePaymentDataPayment from '~/views/DischargeDebtsSinglePaymentDataPayment';
import DischargeDebtsSinglePaymentReceipt from '~/views/DischargeDebtsSinglePaymentReceipt';
import SupplyAndDepletionPossibilityDeclarationLanding from '~/views/SupplyAndDepletionPossibilityDeclaration/outside/landing';
import ForgotPasswordSuccess from '~/views/ForgotPasswordSuccess';
import ModalCopyDataUpdate from '~/components/ModalCopyDataUpdate';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import Versions from '~/views/Versions';
import SocialTariffOutside from '~/views/SocialTariffOutside';
import SocialTariffOutsideDetail from '~/views/SocialTariffOutsideDetail';
import SocialTariffOutsideSolicitation from '~/views/SocialTariffOutsideSolicitation';
import { apiHeaderSetSession } from '~/utils/apiSession';
import DebtNegotiation from '~/views/DebtNegotiation';
import DebtNegotiationSinglePayment from '~/views/DebtNegotiationSinglePayment';
import DebtNegotiationSinglePaymentDataPayment from '~/views/DebtNegotiationSinglePaymentDataPayment';
import DebtNegotiationTypePayment from '~/views/DebtNegotiationTypePayment';
import DebtNegotiationSimulation from '~/views/DebtNegotiationSimulation';
import DebtNegotiationDetail from '~/views/DebtNegotiationDetail';

function RouteAppWithLayout({ Component, path, fullHeader, ...props }: any) {
  const dispatch = useDispatch();

  const modalSalesforceSatisfaction = useSelector(
    (item: IState) => item.outsides.modalSalesforceSatisfaction,
  );
  const modalDataUpdate = useSelector(
    (item: IState) => item.outsides.modalDataUpdate,
  );
  const fromTypeRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.fromTypeRegistrationUpdate,
  );
  const errorRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.errorRegistrationUpdate,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);
  const [dataUpdate] = useState<boolean>(true);

  const verifyToggles = async () => {
    setSatisfactionSurvey(
      await checkToggle(toggles, 'SATISFACTION_SURVEY_NOT_LOGGED'),
    );
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  useEffect(() => {
    if (errorRegistrationUpdate && errorRegistrationUpdate?.state) {
      if (fromTypeRegistrationUpdate) {
        if (fromTypeRegistrationUpdate === 'pixCode') {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][Pix] Erro na Atualização Cadastral',
          });
        } else {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][Boleto] Erro na Atualização Cadastral',
          });
        }
      } else {
        ReactGA.event({
          category: 'Atualização cadastral',
          action: '[WEB][AC][CP] Erro na Atualização Cadastral',
        });
      }
    }
  }, [errorRegistrationUpdate]);

  return (
    <Route path={path + window.location.search} {...props}>
      <GlobalLayout fullHeader={fullHeader}>
        {satisfactionSurvey && modalSalesforceSatisfaction && (
          <Dialog
            open={modalSalesforceSatisfaction}
            onClose={async () => dispatch(clearModalSalesforceSatisfaction())}
          >
            <ModalSalesforceSatisfaction />
          </Dialog>
        )}
        {dataUpdate && modalDataUpdate && (
          <Dialog
            open={modalDataUpdate}
            onClose={async () => dispatch(clearModalDataUpdate())}
          >
            <ModalCopyDataUpdate />
          </Dialog>
        )}
        {errorRegistrationUpdate && (
          <Modal
            open={errorRegistrationUpdate && errorRegistrationUpdate?.state}
            type="error"
            title="Ops!"
            v3
          >
            <ModalError
              onClose={() => dispatch(postErrorCloseOutsidesRegistrationUpdate())}
            />
          </Modal>
        )}
        <Component />
      </GlobalLayout>
    </Route>
  );
}

const AuthRoutes: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    apiHeaderSetSession();
  }, []);

  useEffect(() => {
    dispatch(getTogglesRequest());
  }, [dispatch]);

  return (
    <Switch>
      <RouteAppWithLayout Component={Versions} path={RouteName.VERSIONS} exact />
      <RouteAppWithLayout Component={Landing} path={RouteName.LANDING} exact />
      <RouteAppWithLayout Component={Login} path={RouteName.LOGIN} exact />
      <RouteAppWithLayout
        Component={RegisterProfile}
        path={RouteName.REGISTERPROFILE}
        exact
      />
      <RouteAppWithLayout Component={Register} path={RouteName.REGISTER} exact />
      <RouteAppWithLayout
        Component={ForgotPassword}
        path={RouteName.FORGOTPASSWORD}
        exact
      />
      <RouteAppWithLayout
        Component={ForgotPasswordMethod}
        path={RouteName.FORGOTPASSWORDPHONE}
        exact
      />
      <RouteAppWithLayout
        Component={ForgotPasswordCheckCode}
        path={RouteName.FORGOTPASSWORDCODE}
        exact
      />
      <RouteAppWithLayout
        Component={ForgotPasswordNewPassword}
        path={RouteName.FORGOTPASSWORDUPDATE}
        exact
      />
      <RouteAppWithLayout
        Component={ForgotPasswordSuccess}
        path={RouteName.FORGOTPASSWORDUPDATESUCCESS}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterVerificationMethod}
        path={RouteName.REGISTERVERIFICATIONMETHOD}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterNumberPhone}
        path={RouteName.REGISTERUMBERPHONE}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterNumberPhone}
        path={RouteName.REGISTEREMAIL}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterCheckCode}
        path={RouteName.REGISTERTWOVERSIOVERIFYCODE}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterClientPassword}
        path={RouteName.REGISTERPASSWORDCLIENT}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterEntrepreneur}
        path={RouteName.REGISTERENTREPRENEUR}
        exact
      />
      <RouteAppWithLayout
        Component={RegisterNotClient}
        path={RouteName.REGISTERNOTCLIENT}
        exact
      />
      <Route component={RegisterManager} path={RouteName.REGISTERMANAGER} exact />
      <RouteAppWithLayout
        Component={EasyDuplicate}
        path={RouteName.EASYDUPLICATE}
        exact
      />
      <RouteAppWithLayout
        Component={EasyDuplicateSinglePayment}
        path={RouteName.EASYDUPLICATESINGLEPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={EasyDuplicateSinglePaymentDataPayment}
        path={RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={EasyDuplicateSinglePaymentDataPaymentReceipt}
        path={RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT}
        exact
      />
      <RouteAppWithLayout
        Component={EasyDuplicate}
        path={RouteName.EASYDUPLICATETOKEN}
        exact
      />
      <RouteAppWithLayout
        Component={PasswordExpiration}
        path={RouteName.PASSWORDEXPIRATION}
        exact
      />
      <RouteAppWithLayout
        Component={PasswordExpirationCheckCode}
        path={RouteName.PASSWORDEXPIRATIONVERIFYCODE}
        exact
      />
      <RouteAppWithLayout
        Component={PasswordExpirationNewPassword}
        path={RouteName.PASSWORDEXPIRATIONNEWPASSWORD}
        exact
      />
      <RouteAppWithLayout
        Component={DischargeDebts}
        path={RouteName.DISCHARGEDEBTS}
        exact
      />
      <RouteAppWithLayout
        Component={DischargeDebtsSinglePayment}
        path={RouteName.DISCHARGEDEBTSSINGLEPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={DischargeDebtsSinglePaymentDataPayment}
        path={RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={DischargeDebtsSinglePaymentReceipt}
        path={RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiation}
        path={RouteName.DEBTNEGOTIATION}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiationSinglePayment}
        path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiationSinglePaymentDataPayment}
        path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiationTypePayment}
        path={RouteName.DEBTNEGOTIATIONTYPEPAYMENT}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiationSimulation}
        path={RouteName.DEBTNEGOTIATIONSIMULATION}
        exact
      />
      <RouteAppWithLayout
        Component={DebtNegotiationDetail}
        path={RouteName.DEBTNEGOTIATIONDETAIL}
        exact
      />
      <RouteAppWithLayout
        Component={SupplyAndDepletionPossibilityDeclarationLanding}
        path={RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING}
        exact
      />
      <RouteAppWithLayout
        Component={SocialTariffOutside}
        path={RouteName.SOCIALTARIFFOUTSIDE}
        exact
      />
      <RouteAppWithLayout
        Component={SocialTariffOutsideDetail}
        path={RouteName.SOCIALTARIFFOUTSIDEDETAIL}
        exact
      />
      <RouteAppWithLayout
        Component={SocialTariffOutsideSolicitation}
        path={RouteName.SOCIALTARIFFOUTSIDESOLICITATION}
        exact
      />
    </Switch>
  );
};

export default AuthRoutes;
