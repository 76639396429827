import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.main`
  padding: 56px 24px;
`;

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div`
  padding: 30px 80px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 36px;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    padding: 24px;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const DetailsForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

interface IRowProps {
  columns?: number;
}

export const Row = styled.div<IRowProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 12px;
`;

interface IInvoiceAmountProps {
  color: string;
}

export const InvoiceAmount = styled.div<IInvoiceAmountProps>`
  height: 74px;
  margin-bottom: 64px;
  color: ${({ color }) => color};
  text-align: center;
  font-size: 24px;
  font-weight: 700;

  @media (max-width: ${Breakpoints.tablet.max}px) {
    margin-top: 50px;
  }
`;

export const Divider = styled.div`
  height: 2px;
  background: ${Color.grayLight};
`;

export const InfoWrapper = styled.div`
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  > :nth-child(1) {
    align-self: center;
  }
  .vertical {
    height: 100%;
    width: 2px;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    padding: 36px 24px;
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    > :nth-child(1) {
      justify-self: center;
    }
    .vertical {
      height: 2px;
      width: 100%;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: grid;
    grid-template-columns: auto auto 1fr;
  }
`;

export const InfoPix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  .topic {
    color: ${Color.blueIntense};
    font-weight: 500;
  }
  .highlight {
    color: ${Color.blueSeconde};
    font-weight: 600;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: inherit;
  }
`;

export const NavWrapper = styled.div`
  padding: 40px 80px;
  display: flex;
  justify-content: space-around;
  button {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
  }

  div {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 36px 24px;
    flex-direction: column;
    row-gap: 12px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      width: inherit;
    }
    button {
      width: 100%;
      min-width: 320px;
      font-size: 12px;
    }
  }
`;

export const CreditWrapper = styled.div`
  padding: 40px 80px;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    padding: 0;
  }
`;

export const AutomaticDebitWrapper = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${Color.green};
  }
`;

export const ContainerDownloadVoucher = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    background: none;
    border: none;
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }
  }
`;
