import { Color } from '~/styles';

export const verifyStatus = (status: string) => {
  const statuses: any = {
    Quitado: { color: Color.green },
    Pendente: { color: Color.yellow },
    Cancelado: { color: Color.gray },
  };

  if (!Object.keys(statuses).includes(status)) return statuses.default;

  return statuses[status];
};
