import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 24px;
  background: #f2f2f2;
  padding: 24px;
`;

export const ButtonPromotionalDiscounts = styled.button`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  gap: 24px;
  background: #afd7c3;
  border-radius: 16px;
  text-align: left;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 45px;
    align-items: center;
  }
`;

export const TextButtonDiscounts = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1f1f1f;
`;

export const SummaryNegociation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 4px;
`;

export const RowTextSummaryNegociation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    flex-direction: row;
  }
`;

export const TextSummaryNegociation = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #004b55;
`;
