import React from 'react';
import WalletEloSVG from '~/assets/Icons/WalletElo';
import WalletHiperCardSVG from '~/assets/Icons/WalletHiperCard';
import WalletMasterCardSVG from '~/assets/Icons/WalletMasterCard';
import WalletVisaSVG from '~/assets/Icons/WalletVisa';
import { Main } from './styles';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

export const INFO_CARD = [
  {
    backGroundColor: 'linear-gradient(25deg, #bbb, #d9d9d9)',
  },
  {
    issuer: 'mastercard',
    backGroundColor: 'linear-gradient(25deg, #C7402E, #EF9434)',
    icon: <WalletMasterCardSVG />,
  },
  {
    issuer: 'visa',
    backGroundColor: 'linear-gradient(25deg, #1C409E, #631C9E)',
    icon: <WalletVisaSVG />,
  },
  {
    issuer: 'hipercard',
    backGroundColor: 'linear-gradient(25deg, #ce282e, #b82026)',
    icon: <WalletHiperCardSVG />,
  },

  {
    issuer: 'elo',
    backGroundColor: 'linear-gradient(25deg, #959190, #2b2726)',
    icon: <WalletEloSVG />,
  },
];

const CardTemplate = ({ holder, cardNumber, validity, issuer }: any) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const CARD = INFO_CARD.find((info) => info?.issuer === issuer);

  return (
    <Main background={CARD?.backGroundColor || INFO_CARD[0]?.backGroundColor}>
      {issuer && <div>{CARD?.icon}</div>}
      <p
        style={{
          margin: isMobile ? '8px 0px' : '16px 0px',
        }}
      >
        {cardNumber}
      </p>
      <div
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          margin: '0px',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            width: '75%',
          }}
        >
          {holder}
        </p>
        <div
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            minWidth: '0px',
            width: '25%',
          }}
        >
          {validity && (
            <>
              <p
                style={{
                  textAlign: 'right',
                  width: '75%',
                  marginBottom: '8px',
                }}
              >
                Validade
              </p>
              <p
                style={{
                  textAlign: 'right',
                  width: '75%',
                }}
              >
                {validity
                  .substring(0, 7)
                  .replace(/\D$/g, '')
                  .replace(/(\d{2})/, '$1')
                  .replace(/(\d{2})(\d{4})/, '$1/$2')}
              </p>
            </>
          )}
        </div>
      </div>
    </Main>
  );
};

export default CardTemplate;
