import React, { useState, ButtonHTMLAttributes, FC } from 'react';
import clipboardCopy from 'clipboard-copy';
import CopyIcon from '~/assets/Icons/Copy';
import { Wrapper, Label, Button } from './styles';
import { pixRegisterCopyClipboard } from '~/services/pix';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  label: string;
}

const ButtonLabel: FC<IProps> = ({ label, children, ...props }) => {
  const [showMsg, setShowMsg] = useState<boolean>(false);

  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const dataDetail = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataDetail,
  );

  const handleClick = () => {
    const request = {
      invoice_id: dataDetail?.invoice_id,
      amount: dataDetail?.amount,
      qrcode: dataDetail?.qr_code,
      qrcode_key: dataDetail?.qr_code_key,
      pix_key: dataDetail?.pix_key,
      barcode_number: dataDetail?.bar_code_number,
      registration_id: registration,
      metric_type: 'BOLETO_L',
    };
    pixRegisterCopyClipboard(request);

    clipboardCopy(children || '');
    setShowMsg(true);
    setTimeout(() => {
      setShowMsg(false);
    }, 5000);
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Button {...props} onClick={handleClick}>
        {children?.substring(0, 36)}
        <CopyIcon />
      </Button>
      {showMsg ? <p>Código copiado!</p> : null}
    </Wrapper>
  );
};

export { ButtonLabel };
