import React, { FC } from 'react';

interface IProps {
  width?: number;
  heigth?: number;
}

const BarCodeSVG: FC<IProps> = ({ width, heigth }) => {
  return (
    <svg
      width={width || '22'}
      height={heigth || '18'}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.722222 0C0.530677 0 0.346977 0.0760912 0.211534 0.211534C0.0760912 0.346977 0 0.530677 0 0.722222V16.6111C0 16.8027 0.0760912 16.9864 0.211534 17.1218C0.346977 17.2572 0.530677 17.3333 0.722222 17.3333C0.913767 17.3333 1.09747 17.2572 1.23291 17.1218C1.36835 16.9864 1.44444 16.8027 1.44444 16.6111V0.722222C1.44444 0.530677 1.36835 0.346977 1.23291 0.211534C1.09747 0.0760912 0.913767 0 0.722222 0Z"
        fill="black"
      />
      <path
        d="M3.61285 0C3.4213 0 3.2376 0.0760912 3.10216 0.211534C2.96672 0.346977 2.89062 0.530677 2.89062 0.722222V13.7222C2.89062 13.9138 2.96672 14.0975 3.10216 14.2329C3.2376 14.3684 3.4213 14.4444 3.61285 14.4444C3.80439 14.4444 3.98809 14.3684 4.12354 14.2329C4.25898 14.0975 4.33507 13.9138 4.33507 13.7222V0.722222C4.33507 0.530677 4.25898 0.346977 4.12354 0.211534C3.98809 0.0760912 3.80439 0 3.61285 0Z"
        fill="black"
      />
      <path
        d="M6.49957 0C6.30802 0 6.12432 0.0760912 5.98888 0.211534C5.85343 0.346977 5.77734 0.530677 5.77734 0.722222V13.7222C5.77734 13.9138 5.85343 14.0975 5.98888 14.2329C6.12432 14.3684 6.30802 14.4444 6.49957 14.4444C6.69111 14.4444 6.87481 14.3684 7.01025 14.2329C7.1457 14.0975 7.22179 13.9138 7.22179 13.7222V0.722222C7.22179 0.530677 7.1457 0.346977 7.01025 0.211534C6.87481 0.0760912 6.69111 0 6.49957 0Z"
        fill="black"
      />
      <path
        d="M9.38628 0C9.19474 0 9.01104 0.0760912 8.8756 0.211534C8.74015 0.346977 8.66406 0.530677 8.66406 0.722222V13.7222C8.66406 13.9138 8.74015 14.0975 8.8756 14.2329C9.01104 14.3684 9.19474 14.4444 9.38628 14.4444C9.57783 14.4444 9.76153 14.3684 9.89697 14.2329C10.0324 14.0975 10.1085 13.9138 10.1085 13.7222V0.722222C10.1085 0.530677 10.0324 0.346977 9.89697 0.211534C9.76153 0.0760912 9.57783 0 9.38628 0Z"
        fill="black"
      />
      <path
        d="M12.2769 0C12.0854 0 11.9017 0.0760912 11.7662 0.211534C11.6308 0.346977 11.5547 0.530677 11.5547 0.722222V13.7222C11.5547 13.9138 11.6308 14.0975 11.7662 14.2329C11.9017 14.3684 12.0854 14.4444 12.2769 14.4444C12.4685 14.4444 12.6522 14.3684 12.7876 14.2329C12.923 14.0975 12.9991 13.9138 12.9991 13.7222V0.722222C12.9991 0.530677 12.923 0.346977 12.7876 0.211534C12.6522 0.0760912 12.4685 0 12.2769 0Z"
        fill="black"
      />
      <path
        d="M15.1675 0C14.976 0 14.7923 0.0760912 14.6568 0.211534C14.5214 0.346977 14.4453 0.530677 14.4453 0.722222V13.7222C14.4453 13.9138 14.5214 14.0975 14.6568 14.2329C14.7923 14.3684 14.976 14.4444 15.1675 14.4444C15.3591 14.4444 15.5428 14.3684 15.6782 14.2329C15.8137 14.0975 15.8898 13.9138 15.8898 13.7222V0.722222C15.8898 0.530677 15.8137 0.346977 15.6782 0.211534C15.5428 0.0760912 15.3591 0 15.1675 0Z"
        fill="black"
      />
      <path
        d="M18.0582 0C17.8666 0 17.6829 0.0760912 17.5475 0.211534C17.412 0.346977 17.3359 0.530677 17.3359 0.722222V13.7222C17.3359 13.9138 17.412 14.0975 17.5475 14.2329C17.6829 14.3684 17.8666 14.4444 18.0582 14.4444C18.2497 14.4444 18.4334 14.3684 18.5688 14.2329C18.7043 14.0975 18.7804 13.9138 18.7804 13.7222V0.722222C18.7804 0.530677 18.7043 0.346977 18.5688 0.211534C18.4334 0.0760912 18.2497 0 18.0582 0Z"
        fill="black"
      />
      <path
        d="M20.9449 0C20.7533 0 20.5696 0.0760912 20.4342 0.211534C20.2987 0.346977 20.2227 0.530677 20.2227 0.722222V16.6111C20.2227 16.8027 20.2987 16.9864 20.4342 17.1218C20.5696 17.2572 20.7533 17.3333 20.9449 17.3333C21.1364 17.3333 21.3201 17.2572 21.4556 17.1218C21.591 16.9864 21.6671 16.8027 21.6671 16.6111V0.722222C21.6671 0.530677 21.591 0.346977 21.4556 0.211534C21.3201 0.0760912 21.1364 0 20.9449 0Z"
        fill="black"
      />
    </svg>
  );
};

export default BarCodeSVG;
