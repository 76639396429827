export const COLORS = {
  light: '#AAD7E1', // azulado
  primary: '#37B482', // vegetação
  secondary: '#004B55', // azul intenso
  dark: '#003C28', // verde intenso
  highlight: '#37B4C8', // água
  danger: '#872D2D', // bordô
  error: '#E74C3C',
  warning: '#FAB419', // sol
  success: '#2ECC71',
  white: '#ffffff', // branco
  gray200: '#F2F2F2', // cinza claro
  gray400: '#C7C9C7', // cinza apoio
  gray600: '#999999', // cinza forte
  gray800: '#4A4A4A',
  black: '#000000', // preto
};

export const BREAKPOINTS = {
  mobilePortrait: '480px',
  mobileLandscape: '768px',
  tablet: '991px', // 1024px (laptop)
  desktop: '1200px',
};

export const RE_DIGIT = /\d+$/;
export const RE_NON_DIGIT = /\D/g;
