import styled, { css } from 'styled-components';
import { Color } from '~/styles';
import { BREAKPOINTS } from '~/utils/constants';

interface IPropsContainer {
  active: boolean;
}

interface IPropsColumn {
  hidden?: boolean;
}

const textStyles = css`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.blueIntense};
`;

export const Container = styled.div<IPropsContainer>`
  margin-inline: -20px;
  padding-inline: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  opacity: 1;
  background: #f2f2f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 16px 16px;
  position: absolute;
  top: 128px;
  z-index: 888;
  transition: height 0.3s, opacity 0.3s;

  ${({ active }) =>
    !active &&
    css`
      z-index: -1;
      height: 0;
      opacity: 0;
      display: none;
    `}
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;

  @media (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    gap: 40px;
  }

  @media (min-width: ${BREAKPOINTS.desktop}) {
    gap: 80px;
  }
`;

export const Column = styled.div<IPropsColumn>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const TextTitle = styled.p`
  margin-block-end: 8px;
  ${textStyles};
  font-weight: 600;
`;

export const Text = styled.button`
  ${textStyles};
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  text-wrap: nowrap;
`;

export const Button = styled.button`
  width: 230px;
  height: 24px;
  background: ${Color.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Color.blueSeconde};
  padding: 0 12px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;
