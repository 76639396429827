import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Border,
  TextId,
  Content,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import ContinueButton from './ContinueButton';
import { Color } from '~/styles';
import { INegotiationDetailProps } from './types';
import LoadingComponent from '../Loading';
import IsPaid from './IsPaid';
import OpenInstallments from './OpenInstallments';
import InstallmentDetails from './InstallmentDetails';
import { Dialog } from '../Dialog';
import ModalSuccess from './ModalSuccess';

const NegotiationDetail: FC<INegotiationDetailProps> = ({
  links,
  loading,
  handlePayment,
}) => {
  const [type, setType] = useState<
    'open_installments' | 'installment_details' | 'is_paid'
  >('installment_details');
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);

  const handleOnClose = () => {
    setOpenSuccess(false);
  };

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      {openSuccess && (
        <Dialog open onClose={handleOnClose} fullScreen>
          <ModalSuccess onClose={handleOnClose} />
        </Dialog>
      )}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Title>Detalhes da negociação</Title>
        <Border />
        {type !== 'installment_details' && <TextId>ID 983492</TextId>}
        {type === 'open_installments' && <OpenInstallments setType={setType} />}
        {type === 'installment_details' && (
          <>
            <Content>
              <InstallmentDetails />
            </Content>
            <ContinueButton
              label="Pagar entrada"
              backgroundColor={Color.green}
              onClick={() => {
                ReactGA.event({
                  category: 'Negociação de dívidas',
                  action:
                    '[Negociação de dívidas][Detalhes da negociação] Pagar entrada',
                });

                setOpenSuccess(true);
                handlePayment && handlePayment();
              }}
            />
          </>
        )}
        {type === 'is_paid' && <IsPaid />}
      </Card>
    </Main>
  );
};

export default NegotiationDetail;
