import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

interface IProps {
  border: string;
}

export const Container = styled.div`
  background: #f2f2f2;
  padding: 24px;
  margin: 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 2px solid ${Color.blueSeconde};
  border-radius: 8px;

  input,
  select {
    background-color: ${Color.white};
  }
`;

export const ContainerButton = styled.div`
  margin: 0 80px;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const Button = styled.button.attrs({
  type: 'button',
})<IProps>`
  width: 100%;

  padding: 10px;
  background: #ffffff;
  font-size: 12px;
  color: ${(props) => props.border};
  font-weight: 700;

  border-radius: 8px;

  border: 2px solid ${(props) => props.border};

  margin-bottom: 26px;

  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 24px;
  }
`;
