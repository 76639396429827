import { Reducer } from 'redux';

import {
  RepositoriesTypesNegativeCertificatesHistory,
  IRepositoriesStateEnrollmentsNegativeCertificates,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsNegativeCertificates = {
  data: [],
  loading: false,
  error: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsNegativeCertificates, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };

    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_FAILURE:
      return {
        ...state,
        data: [],
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
