export enum RepositoriesTypesEnrollmentsAlerts {
  ALERTS_GET_METHOD_REQUEST = '@repositories/ALERTS_GET_METHOD_REQUEST',
  ALERTS_GET_METHOD_SUCCESS = '@repositories/ALERTS_GET_METHOD_SUCCESS',
  ALERTS_GET_METHOD_FAILURE = '@repositories/ALERTS_GET_METHOD_FAILURE',
  ALERTS_GET_METHOD_CLOSE = '@repositories/ALERTS_GET_METHOD_CLOSE',

  ALERTS_CREATE_POST_METHOD_REQUEST = '@repositories/ALERTS_CREATE_POST_METHOD_REQUEST',
  ALERTS_CREATE_POST_METHOD_SUCCESS = '@repositories/ALERTS_CREATE_POST_METHOD_SUCCESS',
  ALERTS_CREATE_POST_METHOD_SUCCESS_CLOSE = '@repositories/ALERTS_CREATE_POST_METHOD_SUCCESS_CLOSE',
  ALERTS_CREATE_POST_METHOD_FAILURE = '@repositories/ALERTS_CREATE_POST_METHOD_FAILURE',
  ALERTS_CREATE_POST_METHOD_FAILURE_CLOSE = '@repositories/ALERTS_CREATE_POST_METHOD_FAILURE_CLOSE',

  ALERTS_DELETE_METHOD_REQUEST = '@repositories/ALERTS_DELETE_METHOD_REQUEST',
  ALERTS_DELETE_METHOD_SUCCESS = '@repositories/ALERTS_DELETE_METHOD_SUCCESS',
  ALERTS_DELETE_METHOD_SUCCESS_CLOSE = '@repositories/ALERTS_DELETE_METHOD_SUCCESS_CLOSE',
  ALERTS_DELETE_METHOD_FAILURE = '@repositories/ALERTS_DELETE_METHOD_FAILURE',
  ALERTS_DELETE_METHOD_FAILURE_CLOSE = '@repositories/ALERTS_DELETE_METHOD_FAILURE_CLOSE',

  ALERTS_UPDATE_PATCH_METHOD_REQUEST = '@repositories/ALERTS_UPDATE_PATCH_METHOD_REQUEST',
  ALERTS_UPDATE_PATCH_METHOD_SUCCESS = '@repositories/ALERTS_UPDATE_PATCH_METHOD_SUCCESS',
  ALERTS_UPDATE_PATCH_METHOD_SUCCESS_CLOSE = '@repositories/ALERTS_UPDATE_PATCH_METHOD_SUCCESS_CLOSE',
  ALERTS_UPDATE_PATCH_METHOD_FAILURE = '@repositories/ALERTS_UPDATE_PATCH_METHOD_FAILURE',
  ALERTS_UPDATE_PATCH_METHOD_FAILURE_CLOSE = '@repositories/ALERTS_UPDATE_PATCH_METHOD_FAILURE_CLOSE',
}

export interface IResponseGetAlerts {
  id: number;
  user_id: number;
  cubic_meter: number;
  enrollment: number;
  is_active: boolean;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface IRequestCreateAlert {
  cubic_meter: number;
}

export interface IRequestPatchAlert {
  cubic_meter: number;
  alertId: number;
}

export interface IRepositoriesStateEnrollmentsAlerts {
  readonly data: IResponseGetAlerts[] | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreateAlert: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDeleteAlert: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPatchAlert: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingCreateAlert: boolean;
  readonly loadingDeleteAlert: boolean;
  readonly loadingPatchAlert: boolean;
  readonly successCreateAlert: boolean;
  readonly successDeleteAlert: boolean;
  readonly successPatchAlert: boolean;
}
