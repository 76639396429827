import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}

const ClockSVG = ({ color = Color.yellow, width = 24, height = 24 }: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2C7.375 2 2 7.375 2 14C2 20.625 7.375 26 14 26C20.625 26 26 20.625 26 14C26 7.375 20.625 2 14 2Z"
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M14 6V15H20"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockSVG;
