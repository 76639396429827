import styled, { css } from 'styled-components';
import { Color } from '~/styles';
import { BREAKPOINTS, COLORS } from '~/utils/constants';

interface IPropsActive {
  active: boolean;
  objectFit?: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
  overflow: hidden;
  isolation: isolate;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    display: none;
    width: auto;
    border-radius: 0px;
    margin-inline: -20px;
    height: auto;
  }
`;

export const ContentBanner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Banner = styled.img<IPropsActive>`
  width: 100%;
  height: 100%;
  max-height: 467px;
  display: none;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      display: inherit;
      animation-name: fade;
      animation-duration: 1.5s;

      @keyframes fade {
        from {
          opacity: 0.6;
        }
        to {
          opacity: 1;
        }
      }
    `}
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
    height: auto;
  }
`;

export const ContainerText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
`;

export const ContentText = styled.button<IPropsActive>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? Color.blueSeconde : Color.white)};
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #e9e9e9;

  :first-of-type {
    border-radius: 0 24px 0 0;
  }

  :last-of-type {
    border-radius: 0 0 24px 0;
  }

  .box-text {
    padding: 12px;
    width: 100%;
    max-width: 220px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-sizing: border-box;

    @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
      display: none;
    }
  }

  h2 {
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: ${({ active }) => (active ? Color.white : Color.blueIntense)};
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    color: ${Color.blueIntense};
  }

  ${({ active }) =>
    active &&
    css`
      animation-name: fade;
      animation-duration: 1.2s;

      @keyframes fade {
        from {
          opacity: 0.2;
        }
        to {
          opacity: 1;
        }
      }
    `}
`;

export const BorderText = styled.div<IPropsActive>`
  width: 16px;
  height: 100%;
  background: ${({ active }) => (active ? COLORS.light : COLORS.gray200)};
`;

export const ContainerImgs = styled.div<IPropsActive>`
  width: 100%;
  max-width: 320px;
  display: none;
  justify-content: space-between;
  position: absolute;
  bottom: 10%;
  right: 5%;
  img {
    width: 135px;
    height: 42px;
  }

  ${({ active }) =>
    active &&
    css`
      display: flex;
    `}
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 0.625rem;
  }
`;

export const BannerInfo = styled.div`
  position: absolute;
  bottom: 16px;
  left: 32px;
  z-index: 99999;
  color: ${COLORS.white};
  font-size: 0.725rem;
  font-weight: 500;

  @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
    bottom: auto;
    top: 8px;
    left: 8px;
    right: 8px;
    font-size: 0.75rem;
  }
`;
