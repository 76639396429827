import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Margin } from '~/components/Margin';

import { Main, Card, ContainerBreadCrumb } from './styles';
import { Banner } from './Banner';
import CardTitle from './CardTitle';
import NegotiationSVG from '~/assets/Icons/Negotiation';
import MyPendingIssues from './MyPendingIssues';
import PendingIssuesAvailableNegotiation from './PendingIssuesAvailableNegotiation';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const DebtNegotiation: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );

  const [balloonMyPendingIssues, setBalloonMyPendingIssues] =
    useState<boolean>(false);
  const [balloonPendingIssuesAvailable, setBalloonPendingIssuesAvailable] =
    useState<boolean>(false);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: true,
    },
  ];

  useEffect(() => {
    if (
      (dataDischargeDebts?.info?.total?.expiredQuantity || 0) < 1 &&
      (dataDischargeDebts?.info?.total?.pendingQuantity || 0) < 1
    ) {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action: '[Negociação de dívidas][Tela de dívidas] Sem pendências',
      });
    }
  });

  return (
    <Main>
      {!isMobile && !isTablet && (
        <ContainerBreadCrumb>
          <BreadCrumb links={links} />
        </ContainerBreadCrumb>
      )}
      <Banner />
      <Card isBorder>
        <CardTitle
          label="Minhas pendências"
          showBallonInfo={balloonMyPendingIssues}
          message=" Aqui você encontra um resumo das pendências vinculadas ao seu CPF/CNPJ."
          onClick={() => setBalloonMyPendingIssues(!balloonMyPendingIssues)}
        />
        <MyPendingIssues />
      </Card>
      <Margin height={16} />
      <Card>
        <CardTitle
          icon={<NegotiationSVG />}
          label="Pendências disponíveis para negociação"
          showBallonInfo={balloonPendingIssuesAvailable}
          message=" Aqui você encontra as pendências vinculadas ao seu CPF/CNPJ que foram
            selecionadas para descontos promocionais de negociação."
          onClick={() =>
            setBalloonPendingIssuesAvailable(!balloonPendingIssuesAvailable)
          }
        />
        <PendingIssuesAvailableNegotiation />
      </Card>
      <Margin height={16} />
    </Main>
  );
};

export default DebtNegotiation;
