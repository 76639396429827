import { Reducer } from 'redux';

import {
  IRepositoriesStateUnavailableService,
  RepositoriesTypesUnavailableService,
} from './types';

const INITIAL_STATE: IRepositoriesStateUnavailableService = {
  isUnavailableService: false,
};

const reducer: Reducer<IRepositoriesStateUnavailableService, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesUnavailableService.SET_UNAVAILABLE_SERVICE:
      return {
        ...state,
        isUnavailableService: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
