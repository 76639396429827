import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { ContainerDrop, ContainerInfo, SelectedFileName } from './styles';
import UploadFileSVG from '~/assets/Icons/UploadFile';
import TrashSVG from '~/assets/Icons/Trash';

interface Props {
  onFileUploaded: (file: File | null) => void;
  infoFileName?: string | '';
  disabled?: boolean;
  name?: string;
  newIcon?: boolean;
  trashIcon?: boolean;
  v3?: boolean;
}

const DropZone: React.FC<Props> = ({
  onFileUploaded,
  infoFileName,
  disabled,
  name,
  newIcon,
  trashIcon,
  v3,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      setSelectedFileUrl(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/pdf': ['.pdf'],
    },
  });

  if (trashIcon && selectedFileUrl) {
    return (
      <ContainerDrop>
        <SelectedFileName v3={v3}>
          <p>{selectedFileUrl}</p>
          <button
            type="button"
            onClick={() => {
              onFileUploaded(null);
              setSelectedFileUrl('');
            }}
          >
            <TrashSVG width={20} height={24} />
          </button>
        </SelectedFileName>
      </ContainerDrop>
    );
  }

  return (
    <ContainerDrop {...getRootProps()}>
      <input {...getInputProps()} disabled={disabled} name={name} />
      {selectedFileUrl ? (
        <SelectedFileName>
          <p>{selectedFileUrl}</p>
        </SelectedFileName>
      ) : (
        <ContainerInfo>
          <p>{infoFileName}</p>
          {newIcon ? <UploadFileSVG /> : <UploadFileSVG newIcon />}
        </ContainerInfo>
      )}
    </ContainerDrop>
  );
};

export default DropZone;
