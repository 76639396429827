import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import MailSVG from '~/assets/Icons/Mail';
import PhoneSVG from '~/assets/Icons/PhoneSMS';

import Navigation from '~/utils/Navigation';

import { Main, MethodButton, MethodDiv, Wrapper } from './styles';
import { RouteName } from '~/routes/Routes.name';
import { Card } from '~/common/Card';
import { ButtonLink } from '~/common';
import { IState } from '~/store/modules/types';
import { postEnrollmentsUsersSendRecoveryRequest } from '~/store/modules/enrollmentsUsers/actions';
import { history } from '~/store';
import { Dialog } from '~/components/Dialog';
import ModalInfo from './ModalInfo';
import { Color } from '~/styles';

const PasswordExpiration: React.FC = () => {
  const dataHistory = history.location.state as any;

  const dispatch = useDispatch();

  const dataObfuscated = useSelector(
    (item: IState) => item.enrollmentsUsers.dataObfuscated,
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    !dataObfuscated?.phone && !dataObfuscated?.email && setOpen(true);
  }, []);

  const handleSendToken = (shippingChannel: 'phone' | 'email') => {
    ReactGA.event({
      category: 'Expiração de senha',
      action:
        shippingChannel === 'phone'
          ? '[Expiração de Senha][WEB][Código de Verificação] Receber por SMS'
          : '[Expiração de Senha][WEB][Código de Verificação] Receber por E-mail',
    });

    dispatch(
      postEnrollmentsUsersSendRecoveryRequest({
        cnp: dataHistory?.cnp || '',
        shippingChannel,
      }),
    );
    Navigation.navigate(RouteName.PASSWORDEXPIRATIONVERIFYCODE, {
      cnp: dataHistory?.cnp,
      shippingChannel,
    });
  };

  return (
    <Main>
      {open && (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
          <ModalInfo setOpen={setOpen} />
        </Dialog>
      )}
      <Card
        title="Alterar senha"
        style={{ padding: '40px 66px', maxWidth: '600px' }}
      >
        <p>Para começar, escolha por onde receber o código de verificação:</p>
        <Wrapper>
          <MethodDiv>
            <MethodButton
              onClick={() => dataObfuscated?.phone && handleSendToken('phone')}
              empty={!dataObfuscated?.phone}
            >
              <div>
                <PhoneSVG
                  layoutv3
                  color={!dataObfuscated?.phone ? Color.gray : undefined}
                />
              </div>
              Receber por <br />
              SMS
              <p>{dataObfuscated?.phone}</p>
            </MethodButton>
            <MethodButton
              onClick={() => dataObfuscated?.email && handleSendToken('email')}
              empty={!dataObfuscated?.email}
            >
              <div>
                <MailSVG
                  layoutv3
                  colorV3={!dataObfuscated?.email ? Color.gray : undefined}
                />
              </div>
              Receber por <br />
              e-mail
              <p>{dataObfuscated?.email}</p>
            </MethodButton>
          </MethodDiv>
          <ButtonLink type="button" color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default PasswordExpiration;
