import React from 'react';

const DigitalClientIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#37B482" />
      <path
        d="M15.0752 18.0771C13.1114 20.042 12.0056 22.705 12 25.4835C11.9944 28.262 13.0893 30.9295 15.0452 32.9023C17.2502 27.8005 21.1801 23.539 26.0851 21.0031C21.8515 24.5866 18.9936 29.53 18.0001 34.988C21.9001 36.8337 26.7001 36.1584 29.9251 32.9323C35.145 27.7105 36 12 36 12C36 12 20.2951 12.8553 15.0752 18.0771Z"
        fill="white"
      />
    </svg>
  );
};

export { DigitalClientIcon };
