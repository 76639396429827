import React from 'react';
import { getStatusDetail } from '~/utils/ownership';
import { MiniBar, OrderStatus, OrderStatusBar, OrderTitle } from './styles';

interface OrderStatusProps {
  status: string;
}

const OrderStatusSection: React.FC<OrderStatusProps> = ({ status }) => {
  const miniBar = ['a', 'b', 'c'];
  const statusObj = getStatusDetail(status);

  switch (statusObj.label) {
    case 'Em aberto':
      return (
        <OrderStatus color={statusObj.color}>
          <OrderStatusBar>
            <MiniBar bg={statusObj.color} />
            <MiniBar bg="#C7C9C7" />
            <MiniBar bg="#C7C9C7" />
          </OrderStatusBar>
          <OrderTitle>{statusObj.label}</OrderTitle>
        </OrderStatus>
      );

    case 'Em análise':
      return (
        <OrderStatus color={statusObj.color}>
          <OrderStatusBar>
            <MiniBar bg={statusObj.color} />
            <MiniBar bg={statusObj.color} />
            <MiniBar bg="#C7C9C7" />
          </OrderStatusBar>
          <OrderTitle>{statusObj.label}</OrderTitle>
        </OrderStatus>
      );

    default:
      return (
        <OrderStatus color={statusObj.color}>
          <OrderStatusBar>
            {miniBar.map((item) => (
              <MiniBar key={item} bg={statusObj.color} />
            ))}
          </OrderStatusBar>
          <OrderTitle>{statusObj.label}</OrderTitle>
        </OrderStatus>
      );
  }
};

export { OrderStatusSection };
