import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px 15px 0 16px;
  background: ${Color.white};
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 31px;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: ${Color.red};
  }

  p {
    margin-top: 40px;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    color: #000000;
    text-align: center;
  }

  button {
    margin-top: 40px;
    max-width: 252px;
    height: 48px;
    background: ${Color.red};
    border-radius: 8px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: auto;
    border-radius: 16px;
    max-width: calc(541px - 58px);
    min-height: calc(431px - 64px);
    padding: 32px 30px 32px 28px;
  }
`;
