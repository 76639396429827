import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 24px;
`;

export const ContainerSuccessText = styled.p`
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  color: ${Color.blueIntense};

  span {
    line-height: 150%;
    font-weight: 500;
    color: ${Color.blueSeconde};
  }
`;

export const ContainerAlert = styled.div`
  margin: 24px auto;

  button {
    color: ${Color.blueSeconde};
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  div {
    padding: 24px;
    p + p {
      margin-top: 4px;
    }

    div {
      padding: initial;
    }
  }
`;
