import React, { FC, HTMLAttributes } from 'react';
import { Label } from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: string;
}

const FormLabel: FC<IProps> = ({ children, ...props }) => {
  return <Label {...props}>{children}</Label>;
};

export { FormLabel };
