import React, { FC, useEffect, useRef, useState } from 'react';
import clipboardCopy from 'clipboard-copy';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';

import {
  BorderStatus,
  BoxContent,
  Container,
  ContainerBorderStatus,
  ContainerBreadCrumb,
  ContainerDate,
  ContainerInfo,
  ContainerProtocol,
  Content,
  ContentProtocol,
  ContentProtocolButtons,
  CopyText,
  Divisor,
  Header,
  Main,
  Separator,
  SeparatorDate,
} from './styles';
import CopyIcon from '~/assets/Icons/Copy';
import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import { IState } from '~/store/modules/types';
import { socialTariffStatus } from '~/utils/socialTariffStatus';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Protocol from './Protocol';
import { useMediaQuery } from 'react-responsive';
import { BannerApp } from '~/components/BannerApp';
import { BreadCrumb } from '~/components/BreadCrumb';

interface IPropsStatus {
  status: string;
}

export const Borders: FC<IPropsStatus> = ({ status }) => {
  switch (socialTariffStatus(status).labelRequest) {
    case 'Solicitação aberta':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Em análise':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Revisar documentos':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Concluído':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
        </>
      );

    default:
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
        </>
      );
  }
};

export const getDescription = ({ status }: IPropsStatus) => {
  switch (socialTariffStatus(status).labelRequest) {
    case 'Solicitação aberta':
      return 'Por favor, aguarde! Estamos avaliando sua solicitação.';
    case 'Em análise':
      return 'Por favor, aguarde! Estamos avaliando sua solicitação.';
    case 'Revisar documentos':
      return 'Documentação pendente! Entrar em contato através dos nossos canais de atendimento.';
    case 'Concluído':
      return 'Sua solicitação foi concluída com sucesso!';
    default:
      return 'Sua solicitação foi recusada, entrar em contato através dos nossos canais de atendimento.';
  }

  return '';
};

const SocialTariffRequestRio: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const printRef = useRef(null);

  const data = useSelector((item: IState) => item.socialTariff.data);

  const [copy, setCopy] = useState<boolean>(false);
  const [downloadProtocol, setDownloadProtocol] = useState<boolean>(false);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 5000);
    }
  }, [copy]);

  const handleDownload = async () => {
    const element = printRef.current;
    const canvas = element ? await html2canvas(element) : null;

    const data = canvas?.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      data ? (link.href = data) : null;
      link.download = 'protocolo.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }

    setDownloadProtocol(!downloadProtocol);
  };

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa Social',
              onClick: () => Navigation.navigate(RouteName.SOCIALTARIFF),
              active: false,
            },
            {
              label: 'Solicitar tarifa Social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <Header />
        <BoxContent>
          {downloadProtocol ? (
            <>
              <ContentProtocol ref={printRef}>
                <Protocol />
              </ContentProtocol>
              <ContentProtocolButtons>
                <ButtonText
                  text="Baixar protocolo"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.blueSeconde}
                  onClick={handleDownload}
                />
                <Margin height={16} />
                <ButtonText
                  text="Voltar"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.green}
                  onClick={() => setDownloadProtocol(!downloadProtocol)}
                />
              </ContentProtocolButtons>
            </>
          ) : (
            <>
              <h1>Solicitação de tarifa social</h1>
              <Margin height={32} />

              {/* <Stepper steps={steps} activeStep={2} /> */}
              <Separator />
              <Content>
                <Divisor>
                  <ContainerBorderStatus>
                    {data && <Borders status={data?.status} />}
                  </ContainerBorderStatus>
                  <Margin height={17} />
                  <h2
                    style={{
                      color: socialTariffStatus(data!.status).colorRequest,
                    }}
                  >
                    {data && socialTariffStatus(data?.status).labelRequest}
                  </h2>
                  <Margin height={26} />
                  <ContainerDate
                    style={{
                      justifyContent: data?.closedDate ? 'space-between' : 'center',
                    }}
                  >
                    <div>
                      <p>Data de solicitação</p>
                      <p>
                        {moment(data?.createdDate || new Date()).format(
                          'DD/MM/YYYY',
                        )}
                      </p>
                    </div>
                    {(data?.expectedDate || data?.closedDate) && (
                      <>
                        <SeparatorDate />
                        {data?.closedDate ? (
                          <div>
                            <p>Data de conclusão</p>
                            <p>
                              {moment(data?.closedDate || new Date()).format(
                                'DD/MM/YYYY',
                              )}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p>Prazo para conclusão</p>
                            <p>{moment(data?.expectedDate).format('DD/MM/YYYY')}</p>
                          </div>
                        )}
                      </>
                    )}
                  </ContainerDate>
                  <Margin height={32} />
                  <ContainerInfo>
                    {getDescription({ status: data?.status || '' })}
                  </ContainerInfo>
                </Divisor>
                <Margin width={69} />
                <Separator />
                <Margin width={30} />
                <Divisor>
                  {data &&
                    socialTariffStatus(data?.status).labelRequest === 'Recusado' && (
                      <Link to={RouteName.SOCIALTARIFFSOLICITATION}>
                        Reenviar solicitação
                      </Link>
                    )}
                  <ContainerProtocol>
                    <p>
                      O número do seu protocolo é:
                      <Margin height={6} />
                      <b>{data?.protocol}</b>{' '}
                      <button
                        type="button"
                        onClick={() => {
                          setCopy(true);
                          clipboardCopy(data?.protocol || '');
                        }}
                      >
                        <CopyIcon />
                      </button>
                    </p>
                  </ContainerProtocol>
                  <Margin height={copy ? 12 : 42} />
                  {copy && (
                    <>
                      <CopyText>Código copiado!</CopyText>
                      <Margin height={42} />
                    </>
                  )}
                  <ButtonText
                    text="Visualizar protocolo"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.blueSeconde}
                    style={{
                      height: '48px',
                      marginTop: isMobile ? '18px' : undefined,
                    }}
                    onClick={() => setDownloadProtocol(!downloadProtocol)}
                  />
                  <Margin height={16} />
                  <ButtonText
                    text="Voltar para o home"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={Color.green}
                    style={{ height: '48px' }}
                    onClick={() => Navigation.navigate(RouteName.HOME)}
                  />
                  <Margin height={isMobile ? 104 : 12} />
                </Divisor>
              </Content>
            </>
          )}
          <Margin height={64} />
        </BoxContent>
        <BannerApp />
      </Container>
    </Main>
  );
};

export default SocialTariffRequestRio;
