import { api } from './api';

function getEnrollmentsNegativeCertificatesService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getNegativeCertificates: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/negative-certificates`,
        );
        return response.data;
      };

      resolve(getNegativeCertificates());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsNegativeCertificateDownloadService(
  enrollmentId: string,
  year: number,
) {
  return new Promise((resolve, reject) => {
    try {
      const getBufferNegativeCertificate: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/negative-certificates/${year}/download`,
        );
        return response.data;
      };

      resolve(getBufferNegativeCertificate());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsNegativeCertificatesService,
  getEnrollmentsNegativeCertificateDownloadService,
};
