import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const AlertLineSVG: FC<IProps> = ({ color = 'white' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11535 1.52906C5.91426 0.0111408 8.08779 0.0111493 8.88669 1.52907L13.5039 10.3018C14.2507 11.7208 13.2218 13.4251 11.6182 13.4251H2.3838C0.780275 13.4251 -0.248703 11.7208 0.498131 10.3018L5.11535 1.52906ZM7.55932 2.22769C7.32278 1.77827 6.67926 1.77827 6.44273 2.22768L1.82551 11.0004C1.60439 11.4205 1.90905 11.9251 2.3838 11.9251H11.6182C12.093 11.9251 12.3977 11.4205 12.1765 11.0004L7.55932 2.22769ZM7.00102 3.63975C7.41524 3.63975 7.75102 3.97554 7.75102 4.38975V7.842C7.75102 8.25621 7.41524 8.592 7.00102 8.592C6.58681 8.592 6.25102 8.25621 6.25102 7.842V4.38975C6.25102 3.97554 6.58681 3.63975 7.00102 3.63975ZM5.9058 9.91334C5.9058 9.30847 6.39615 8.81812 7.00102 8.81812C7.6059 8.81812 8.09625 9.30847 8.09625 9.91334C8.09625 10.5182 7.6059 11.0086 7.00102 11.0086C6.39614 11.0086 5.9058 10.5182 5.9058 9.91334Z"
        fill={color}
      />
    </svg>
  );
};
export default AlertLineSVG;
