import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsToken {
  token: boolean;
}

export const Container = styled.div<IPropsToken>`
  min-width: ${({ token }) => (token ? '370px' : '280px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ token }) => (token ? '0' : '24px')};
  gap: 32px;

  @media (max-width: ${Breakpoints.tablet.min}px) {
    min-width: 275px;
  }
`;

export const ContainerUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BoxUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 31px 34px 0 32px;
  margin-inline-start: initial !important;
`;

export const BoxUserName = styled.div`
  min-width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 3px solid ${Color.blueIntense};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: ${Color.blueIntense};
  position: relative;
`;

export const BoxUserInfo = styled.div`
  width: 100%;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${Color.blueIntense};

    span {
      font-weight: 500;
      color: ${Color.blueSeconde};
    }
  }

  p:first-of-type {
    text-transform: capitalize;
    line-height: 20px;
    font-size: 16px;
  }
`;

export const ContainerLinkButton = styled.button`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #02505b;
  border: none;
  background: none;
  border-top: 1px solid ${Color.grayLight};
  cursor: pointer;
  svg {
    margin-left: 24px;
    margin-right: 26px;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${Color.blueIntense};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
