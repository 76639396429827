import html2canvas from 'html2canvas';
import React, { FC, HTMLAttributes } from 'react';
import { Button } from '~/common';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: string;
  reference: any;
}

const DownloadButton: FC<Props> = ({ children, reference }) => {
  const handleDownload = async () => {
    setTimeout(async () => {
      // const area: any = document.getElementsByClassName('super-area');
      const element = reference.current;
      const canvas = element ? await html2canvas(element) : null;

      // html2canvas(area[0]).then((canvas) => {
      const data = canvas?.toDataURL('image/jpg');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        data ? (link.href = data) : null;
        link.download = 'protocolo.jpg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }, 400);
  };

  return (
    <Button
      variant="secondary"
      type="button"
      color="primary"
      onClick={handleDownload}
    >
      {children}
    </Button>
  );
};

export { DownloadButton };
