/* eslint-disable react/no-unescaped-entities */
import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';
import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ButtonInfo,
  ContainerButtonsInfo,
  ContainerText,
  Text,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { BreadCrumb } from '~/components/BreadCrumb';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import { Link } from 'react-router-dom';
import declaracaoMoradia from './declaracao-moradia.pdf';

export interface IDocuments {
  key: string;
  label: string;
  file: null | File;
}

const SocialTariffOutsideDetail: FC = () => {
  const [socialTariffRio, setSocialTariffRio] = useState(false);
  const [socialTariffCuiaba, setSocialTariffCuiaba] = useState(false);

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Tarifa social',
              active: false,
              onClick: () => Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE),
            },
            {
              label: 'Detalhes por região',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Detalhes da tarifa social</h1>
        <Margin height={34} />
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <p className="description">
          A tarifa social nos serviços públicos de abastecimento de água e
          esgotamento sanitário é um benefício concedido à clientes consumidores das
          concessionárias do Grupo Iguá, onde há normativa oficial, publicada pelo
          poder concedente, para tal aplicação.
          <br /> <br />
          Para as matrículas localizadas no Rio de Janeiro e Cuiabá, o cliente
          titular poderá fazer a solicitação do benefício clicando em "Solicitar
          tarifa social”. Para demais regiões, deve ser consultado a operação através
          dos nossos canais de atendimento.
          <br /> <br />
          Confira mais detalhes de cada região abaixo:
        </p>
        <ContainerButtonsInfo>
          <ButtonInfo
            isActive={socialTariffRio}
            onClick={() => {
              setSocialTariffRio(!socialTariffRio);
              setSocialTariffCuiaba(false);
            }}
          >
            Tarifa social Rio de Janeiro
            <ArrowUpSVG />
          </ButtonInfo>
          {socialTariffRio && (
            <ContainerText>
              <Text>
                <h3>Quem pode ser beneficiado?</h3>
                <br />
                A tarifa social poderá beneficiar os moradores de baixa renda em
                áreas de ocupação não regular, em habitações multifamiliares
                regulares e irregulares ou em empreendimentos habitacionais de
                interesse social, caracterizados como tal pelos governos municipais,
                estaduais ou do Distrito Federal ou pelo governo federal, desde que
                mantido o limite máximo de renda mensal per capita de meio salário
                mínimo.
                <br />
                <br />
                <h3>
                  Qual a documentação necessária para cadastro e atualização de dados
                  na tarifa social?
                </h3>
                <br />
                Depois de inscrito na Plataforma do Digi Iguá, o responsável pela
                família deve solicitar o cadastro da tarifa social de água e
                esgotamento sanitário e apresentar os seguintes documentos:
                <br />
                <br />
                <h3>Para habitação em favela</h3>
                <br />
                <ul>
                  <li>Documento de identidade (CPF)</li>
                  <li>
                    Documentação FAFERJ (local de favela), da associação de moradores
                    ou vistoria de localização realizada pela Iguá (Não é obrigatório
                    o seguimento do processo)
                  </li>
                  <li>
                    Comprovante de moradia ( conta de luz, documento de usucapião ou
                    RGI - Registro geral de imóvel)
                  </li>
                  <li>
                    <Link to={declaracaoMoradia} target="_blank">
                      Declaração de moradia padrão Iguá
                    </Link>{' '}
                    <i>(clique para download)</i>
                  </li>
                </ul>
                <br />
                <br />
                <h3>Para conjunto habitacional</h3>
                <br />
                <ul>
                  <li>Documento de identidade (CPF)</li>
                  <li>Documento do órgão financiador (com nome do titular)</li>
                  <li>IPTU (casas isoladas)</li>
                  <li>
                    Ata do condomínio onde conste a eleição do síndico (ou documento
                    que conste o responsável pelo conjunto)
                  </li>
                  <li>
                    <Link to={declaracaoMoradia} target="_blank">
                      Declaração de moradia padrão Iguá
                    </Link>{' '}
                    <i>(clique para download)</i>
                  </li>
                </ul>
                <br />
                <br />
                <h3>Para área de interesse social</h3>
                <br />
                <ul>
                  <li>Documento de identidade (CPF)</li>
                  <li>IPTU ou outro documento que comprove a posse</li>
                  <li>
                    Vistoria de localização realizada pela Iguá (Na ausência de
                    documentação)
                  </li>
                  <li>
                    <Link to={declaracaoMoradia} target="_blank">
                      Declaração de moradia padrão Iguá
                    </Link>{' '}
                    <i>(clique para download)</i>
                  </li>
                </ul>
                <br />
                <br />
                <h3>Para habitação Popular</h3>
                <br />
                <ul>
                  <li>Documento de identidade (CPF)</li>
                  <li>
                    {' '}
                    Documento comprobatório da cessão do terreno emitido pelo órgão
                    público (incluindo nome do titular)
                  </li>
                  <li>
                    <Link to={declaracaoMoradia} target="_blank">
                      Declaração de moradia padrão Iguá
                    </Link>{' '}
                    <i>(clique para download)</i>
                  </li>
                </ul>
                <br />
                <br />
                <h3>Quem já tem o desconto pode perdê-lo?</h3>
                <br />
                Sim. Para não perder esse benefício, o cliente deverá manter seu
                cadastro ativo e atualizado da seguinte forma:
                <br />
                <br />
                <div>
                  <span>1.</span>{' '}
                  <span>
                    Mantenha ativo o seu cadastro e com dados atualizados no
                    Ministério de Desenvolvimento Social (MDS) da sua cidade.
                  </span>
                </div>
                <div>
                  <span>2.</span>{' '}
                  <span>
                    Se você recebeu uma carta da Iguá junto com a sua fatura,
                    solicitando a atualização do seu cadastro, fique atento e entre
                    em contato conosco, através dos nossos canais de atendimento.
                  </span>
                </div>
                <div>
                  <span>3.</span>{' '}
                  <span>
                    Para continuidade do desconto na tarifa de água e esgoto, os
                    consumidores que receberem o comunicado, deverão realizar a
                    solicitação da tarifa social novamente selecionando a opção{' '}
                    <b>"Renovação do benefício”</b> (Válido apenas para casos onde
                    não houve alteração nos dados de moradia, caso contrário, a
                    solicitação deve ser refeita). As concessionárias
                    obrigatoriamente, pelo menos uma vez por ano, deverão verificar
                    se os beneficiados com a tarifa social de água e esgoto atendem
                    aos critérios estabelecidos para continuidade do desconto.
                  </span>
                </div>
                <br />
                <h3>Existe prazo para a realização do recadastramento?</h3>
                <br />
                Não. O cliente deve solicitar o recadastramento antes do término da
                vigência.
                <br />
                <br />
                <h3>Informações importantes:</h3>
                <br />
                Cada família terá direito ao benefício da tarifa social em apenas uma
                unidade consumidora.
                <br />
                <br />
                <b style={{ fontWeight: 700 }}>Atenção:</b> Faturas anteriores da
                data da solicitação não tem direito a revisão.
                <br />
                <br />
                Para mais informações entre em contato com nossos canais de
                atendimento.
              </Text>
            </ContainerText>
          )}
          <ButtonInfo
            isActive={socialTariffCuiaba}
            onClick={() => {
              setSocialTariffCuiaba(!socialTariffCuiaba);
              setSocialTariffRio(false);
            }}
          >
            Tarifa social Cuiabá
            <ArrowUpSVG />
          </ButtonInfo>
        </ContainerButtonsInfo>
        {socialTariffCuiaba && (
          <ContainerText>
            <Text>
              Em Cuiabá, a Prefeitura estabeleceu a ampliação do número de pessoas
              beneficiadas com a tarifa social nos serviços públicos de abastecimento
              de água e esgotamento sanitário.
              <br />
              <br />
              <h3>Quem pode ser beneficiado?</h3>
              <br />
              A Tarifa Social corresponde a um benefício de redução de 50% (cinquenta
              por cento) na tarifa de água e esgoto, categoria residencial, para
              população de baixa renda.
              <br />
              O benefício da Tarifa Social é limitado ao consumo mensal de 10m³ de
              água e a correspondente coleta de esgoto, sendo o excedente faturado de
              acordo com a tabela tarifária vigente.
              <br />
              O benefício também será concedido às ligações que abasteçam
              proprietários ou inquilinos em situação comprovada de carência que
              sejam portadores de doenças graves, comprovado por laudo pericial
              expedido por instituições de saúde pública do Município de Cuiabá.
              <br />
              <br />
              <h3>
                Qual os requisitos necessários para cadastro e atualização de dados
                na tarifa social?
              </h3>
              <br />
              <ul>
                <li>
                  {' '}
                  Ser cadastrado(a) na categoria residencial, junto à Águas Cuiabá;
                </li>
                <li>
                  Comprovar ser beneficiário(a) de algum programa de Proteção Social
                  do Governo Federal;
                </li>
                <li>
                  Tenha ligação cadastrada como apenas 1 (uma) *economia, com área
                  construída menor ou igual a 80m²;
                </li>
                <li>
                  Tenha comprovado, através de seu histórico de consumo média de
                  energia elétrica nos últimos 12 (doze) meses, consumo igual ou
                  inferior a 120 (cento e vinte) KWh;
                </li>
                <li>
                  Esteja adimplente com as faturas junto à Águas Cuiabá no ato da
                  adesão ao benefício, ou tenha realizado parcelamento do seu débito;
                </li>
                <li>Não possua fonte alternativa de abastecimento de água.</li>
              </ul>
              <br />
              *Todo o imóvel ou subdivisão de um imóvel considerado ocupável, com
              entrada própria independente das demais, e com instalações hidráulicas
              internas para o abastecimento de água e/ou coleta de esgoto.
              <br />
              <br />
              <h3>Documentos necessários</h3>
              <br />
              Depois de inscrito na Plataforma do Digi Iguá, o responsável pela
              família deve solicitar o cadastro da tarifa social de água e
              esgotamento sanitário e apresentar os seguintes documentos:
              <br />
              <br />
              <ul>
                <li>CadÚnico atualizado;</li>
                <li>Documento pessoal;</li>
                <li>Conta de energia atualizada (90dias);</li>
                <li>Matrícula (conta de água).</li>
              </ul>
              <br />
              <h3>Informações complementares</h3>
              <br />
              <ul>
                <li>
                  O benefício da Tarifa Social será concedido pelo período de 1 (um)
                  ano, podendo ser renovado sucessivamente por mais 2 (dois) períodos
                  iguais, mediante comprovação de todos os requisitos citados acima.
                </li>
                <li>
                  Os beneficiários da Tarifa Social serão informados pela Águas
                  Cuiabá 30 (trinta) dias antes do vencimento do benefício, via
                  e-mail ou whatsapp.
                </li>
                <li>
                  Não havendo requerimento de prorrogação da Tarifa Social ou não
                  sendo mais cabível, o benefício será automaticamente cancelado,
                  podendo ser novamente requerido após 90 (noventa) dias da data do
                  cancelamento.
                </li>
                <li>
                  Fica também cancelado o benefício caso não haja o pagamento de 3
                  (três) faturas consecutivas ou não pelo cliente, podendo ser
                  requerido novo enquadramento após 90 (noventa) dias da data do
                  cancelamento.
                </li>
                <li>
                  Os clientes, cujas ligações caracterizem fraude de qualquer
                  natureza, perderão o direito ao benefício da Tarifa Social, podendo
                  ser requerido novamente somente após 1 (um) ano da data do
                  cancelamento.
                </li>
                <li>
                  O prazo de aprovação da solicitação do benefício é de 15 dias
                  úteis.
                </li>
              </ul>
              <br />
              <br />
            </Text>
          </ContainerText>
        )}
        <Margin height={66} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.green}
            onClick={() => {
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Detalhes da Tarifa Social] - Voltar`,
              });
              Navigation.goBack();
            }}
            backIcon
            id="voltar-da-tela-de-detalhes-tarifa-social"
          />
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SocialTariffOutsideDetail;
