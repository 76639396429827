import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const ContainerInputAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 16px;
  }
`;

export const TextAmount = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.gray};
  text-align: center;
  opacity: 0.74;
`;

export const ButtonChangeAmount = styled.button`
  flex: 1;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${Color.blueSeconde};
  opacity: 0.74;
  cursor: pointer;
`;
