/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { Color } from '~/styles';
import {
  ContainerBody,
  ContainerAutomaticDebit,
  Header,
  Main,
  ContainerButtons,
  ReasonRequestDeniedText,
  EmptyText,
} from './styles';
import LoadingComponent from '~/components/Loading';
import EmptyMessage from '~/components/EmptyMessage';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import DebitSatusInformation from './DebitSatusInformation';
import DebitAccount from './DebitAccount';
import Modal from '~/components/Modal';
import ModalDeleteDebtAccount from './ModalDeleteDebtAccount';
import { Dialog } from '~/components/Dialog';
import ModalDebitAccount from './ModalDebitAccount';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteErrorCloseAutomaticDebitsRequest,
  deleteSuccessCloseAutomaticDebitsRequest,
  getAutomaticDebitsBanksRequest,
  getAutomaticDebitsRequest,
  postErrorCloseAutomaticDebitsRequest,
  postSuccessCloseAutomaticDebitsRequest,
} from '~/store/modules/automaticDebits/actions';
import { IState } from '~/store/modules/types';
import ModalCreateDebtAccount from './ModalCreateDebtAccount';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const AutomaticDebit: FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item.automaticDebits.loading);
  const loadingCreate = useSelector(
    (item: IState) => item.automaticDebits.loadingCreate,
  );
  const loadingDelete = useSelector(
    (item: IState) => item.automaticDebits.loadingDelete,
  );

  const data = useSelector((item: IState) => item.automaticDebits.data);

  const [disabledCancelService, setDisabledCancelService] = useState<boolean>(false);
  const [openCreateAccount, setOpenCreateAccount] = useState<boolean>(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false);
  const [openDebtAccount, setOpenDebtAccount] = useState<boolean>(false);
  const [operation, setOperation] = useState<'create' | 'view'>('create');
  const successCreateAccount = useSelector(
    (item: IState) => item.automaticDebits.successCreate,
  );
  const errorCreateAccount = useSelector(
    (item: IState) => item.automaticDebits.errorCreate,
  );
  const successDeleteAccount = useSelector(
    (item: IState) => item.automaticDebits.successDelete,
  );
  const errorDeleteAccount = useSelector(
    (item: IState) => item.automaticDebits.errorDelete,
  );

  const styleButton = {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    height: '38px',
    fontSize: '12px',
    lineHeight: '15px',
  };

  useEffect(() => {
    dispatch(postSuccessCloseAutomaticDebitsRequest());
    dispatch(postErrorCloseAutomaticDebitsRequest());
    dispatch(deleteSuccessCloseAutomaticDebitsRequest());
    dispatch(deleteErrorCloseAutomaticDebitsRequest());
    dispatch(getAutomaticDebitsBanksRequest());
    dispatch(getAutomaticDebitsRequest());
  }, []);

  useEffect(() => {
    if (data) {
      if (data.situation === 'Enviado Exclusão') {
        setDisabledCancelService(true);
      } else {
        setDisabledCancelService(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (successCreateAccount || (errorCreateAccount && errorCreateAccount.state)) {
      setOpenCreateAccount(true);
    } else {
      setOpenCreateAccount(false);
    }
  }, [successCreateAccount]);

  return (
    <Main>
      {(loading || loadingCreate || loadingDelete) && (
        <LoadingComponent labelWait="Carregando..." />
      )}
      <Modal
        open={loadingCreate ? false : openCreateAccount}
        type={successCreateAccount ? 'success' : 'error'}
        title={successCreateAccount ? 'Sucesso!' : 'Ops!'}
      >
        <ModalCreateDebtAccount setOpen={setOpenCreateAccount} />
      </Modal>
      <Modal
        open={loadingDelete ? false : openDeleteAccount}
        type={
          !successDeleteAccount && !errorDeleteAccount
            ? 'info'
            : successDeleteAccount
            ? 'success'
            : 'error'
        }
        title={
          !successDeleteAccount && !errorDeleteAccount
            ? ''
            : successDeleteAccount
            ? 'Sucesso!'
            : 'Ops!'
        }
      >
        <ModalDeleteDebtAccount setOpen={setOpenDeleteAccount} />
      </Modal>
      {openDebtAccount && (
        <Dialog open={openDebtAccount} onClose={setOpenDebtAccount}>
          <ModalDebitAccount
            open={openDebtAccount}
            onClose={setOpenDebtAccount}
            operation={operation}
          />
        </Dialog>
      )}
      <ContainerAutomaticDebit>
        <Header>Débito automático</Header>
        <ContainerBody>
          {data && Object.keys(data).length > 0 ? (
            <>
              <Margin height={107} />
              <DebitSatusInformation situation={data?.situation} />
              <Margin height={30} />
              <DebitAccount
                situation={data.situation}
                bankName={data.bank}
                onClick={() => {
                  setOperation('view');
                  setOpenDebtAccount(true);
                }}
              />
              {data.situation === 'Recusado' && (
                <>
                  <Margin height={16} />
                  <ReasonRequestDeniedText>
                    Deseja cadastrar novamente uma conta? Solicite o cancelamento do
                    serviço abaixo (prazo de até 48h para cancelamento) ou
                    diretamente com o seu Banco.
                  </ReasonRequestDeniedText>
                </>
              )}
              <Margin height={data.situation === 'Recusado' ? 22 : 102} />
              {data.situation === 'Excluído' ? (
                <ContainerButtons>
                  <ButtonText
                    text="Cadastrar débito em conta"
                    typeBtn="content"
                    backgroundColor={Color.green}
                    onClick={() => {
                      setOperation('create');
                      setOpenDebtAccount(true);
                    }}
                    loading={loading}
                    style={styleButton}
                  />
                  <Margin width={11} />
                  <ButtonText
                    text="Voltar"
                    typeBtn="content"
                    backgroundColor={Color.blueSeconde}
                    onClick={() => Navigation.navigate(RouteName.HOME)}
                    style={styleButton}
                  />
                </ContainerButtons>
              ) : (
                <>
                  {!disabledCancelService ? (
                    <ContainerButtons>
                      <ButtonText
                        text="Cancelar serviço"
                        typeBtn="content"
                        backgroundColor={
                          disabledCancelService ? Color.gray : Color.red
                        }
                        onClick={() => {
                          if (disabledCancelService) return;
                          setOpenDeleteAccount(true);
                        }}
                        style={styleButton}
                      />
                      <Margin width={11} />
                      <ButtonText
                        text="Voltar"
                        typeBtn="content"
                        backgroundColor={Color.blueSeconde}
                        onClick={() => Navigation.navigate(RouteName.HOME)}
                        style={styleButton}
                      />
                    </ContainerButtons>
                  ) : (
                    <ButtonText
                      text="Voltar"
                      typeBtn="content"
                      backgroundColor={Color.blueSeconde}
                      onClick={() => Navigation.navigate(RouteName.HOME)}
                      style={{
                        ...styleButton,
                        maxWidth: '190px',
                      }}
                    />
                  )}
                </>
              )}
              <Margin height={164} />
            </>
          ) : (
            <>
              <Margin height={102} />
              <EmptyMessage />
              <EmptyText>
                Vocé ainda não possui débito automático cadastrado.
                <br /> Cadastre suas próximas faturas em débito automático e mantenha
                suas contas em dia!
              </EmptyText>
              <Margin height={36} />
              <ContainerButtons>
                <ButtonText
                  text="Cadastrar débito em conta"
                  typeBtn="content"
                  backgroundColor={Color.green}
                  onClick={() => {
                    setOperation('create');
                    setOpenDebtAccount(true);
                  }}
                  loading={loading}
                  style={styleButton}
                />
                <Margin width={11} />
                <ButtonText
                  text="Voltar"
                  typeBtn="content"
                  backgroundColor={Color.blueSeconde}
                  onClick={() => Navigation.navigate(RouteName.HOME)}
                  style={styleButton}
                />
              </ContainerButtons>
              <Margin height={164} />
            </>
          )}
        </ContainerBody>
      </ContainerAutomaticDebit>
    </Main>
  );
};

export default AutomaticDebit;
