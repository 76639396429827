import React, { FC } from 'react';
import { Margin } from '~/components/Margin';
import { ContainerButton } from './styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';

interface IProps {
  backOnClick: () => void;
  nextOnClick: () => void;
  nextBackground: string;
  nextLoading: boolean;
  nextDisabled: boolean;
  nextLabel?: string;
}

const ButtonBar: FC<IProps> = ({
  backOnClick,
  nextOnClick,
  nextBackground,
  nextDisabled,
  nextLoading,
  nextLabel,
}) => {
  return (
    <>
      <Margin height={40} />
      <ContainerButton>
        <Button backIcon backgroundColor={Color.green} onClick={backOnClick}>
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={nextBackground}
          style={{ opacity: 1 }}
          onClick={nextOnClick}
          loading={nextLoading}
          disabled={nextDisabled}
        >
          {nextLabel || 'Continuar'}
        </Button>
      </ContainerButton>
    </>
  );
};

export default ButtonBar;
