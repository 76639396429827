import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface IProps {
  v3?: boolean;
  color?: string;
}

function CopySVG({ v3, color = '#34BBDA' }: IProps) {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  if (v3) {
    return (
      <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.45037 0H1.39358C0.65504 0 0.0507812 0.654545 0.0507812 1.45455V11.6364H1.39358V1.45455H9.45037V0ZM11.4646 2.90909H4.07917C3.34064 2.90909 2.73638 3.56364 2.73638 4.36364V14.5455C2.73638 15.3455 3.34064 16 4.07917 16H11.4646C12.2031 16 12.8074 15.3455 12.8074 14.5455V4.36364C12.8074 3.56364 12.2031 2.90909 11.4646 2.90909ZM11.4646 14.5455H4.07917V4.36364H11.4646V14.5455Z"
          fill={color}
        />
      </svg>
    );
  }

  return isMobile ? (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5432_3120)">
        <path
          opacity="0.3"
          d="M5.46875 4.76953H12.9866V14.3085H5.46875V4.76953Z"
          fill="#34BBDA"
        />
        <path
          d="M10.9353 0.681641H2.73406C1.98228 0.681641 1.36719 1.29486 1.36719 2.04435V11.5833H2.73406V2.04435H10.9353V0.681641ZM12.9856 3.40706H5.46782C4.71604 3.40706 4.10094 4.02028 4.10094 4.76977V14.3087C4.10094 15.0582 4.71604 15.6714 5.46782 15.6714H12.9856C13.7374 15.6714 14.3525 15.0582 14.3525 14.3087V4.76977C14.3525 4.02028 13.7374 3.40706 12.9856 3.40706ZM12.9856 14.3087H5.46782V4.76977H12.9856V14.3087Z"
          fill="#34BBDA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5432_3120">
          <rect width="16.4025" height="16.3525" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2105 0H2.31579C1.04211 0 0 1.06364 0 2.36364V18.9091H2.31579V2.36364H16.2105V0ZM19.6842 4.72727H6.94737C5.67368 4.72727 4.63158 5.79091 4.63158 7.09091V23.6364C4.63158 24.9364 5.67368 26 6.94737 26H19.6842C20.9579 26 22 24.9364 22 23.6364V7.09091C22 5.79091 20.9579 4.72727 19.6842 4.72727ZM19.6842 23.6364H6.94737V7.09091H19.6842V23.6364Z"
        fill="#37B4C8"
      />
    </svg>
  );
}

export default CopySVG;
