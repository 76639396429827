import React from 'react';
import { Column } from '../styles';

import { Button, Card, Circle } from './styles';
import { Text } from '~/components/TextInfo';
import { Margin } from '~/components/Margin';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const Header: React.FC = () => {
  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );
  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );
  const isEntrepreneur = useSelector(
    (item: IState) => item.enrollmentsAuth.isEntrepreneur,
  );

  return (
    <Column>
      <Card>
        <div>
          <Circle>{enrollmentsUserInfo?.fullName.substring(0, 1)}</Circle>
          <Column id="text-info-user">
            <Text
              size={16}
              weight={500}
              fontFamily="Montserrat-Regular"
              color="#004B55"
            >
              {enrollmentsUserInfo?.fullName}
            </Text>
            <Margin height={13} />
            <Text
              size={12}
              fontFamily="Montserrat-Regular"
              color="#004B55"
              weight={500}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              Matrícula:
              <Margin width={5} />
              <Text
                size={12}
                fontFamily="Montserrat-Regular"
                weight={500}
                color="#37B4C8"
              >
                {enrollment?.code}
              </Text>
            </Text>
            <Text
              size={12}
              fontFamily="Montserrat-Regular"
              weight={500}
              color="#004B55"
            >
              {' '}
              {enrollment?.address_connection}
            </Text>
          </Column>
        </div>

        {isEntrepreneur ? (
          <div />
        ) : (
          <Button onClick={() => Navigation.navigate(RouteName.REGISTRATIONLIST)}>
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.90129 12.5739V10.6515H9.4094V12.5739C9.4094 12.7854 9.57906 12.9584 9.78643 12.9584H10.9175C11.1249 12.9584 11.2945 12.7854 11.2945 12.5739V9.88247H11.9355C12.1089 9.88247 12.1919 9.66331 12.0599 9.54796L8.90795 6.65273C8.76468 6.52201 8.54601 6.52201 8.40273 6.65273L5.25078 9.54796C5.1226 9.66331 5.20177 9.88247 5.3752 9.88247H6.01615V12.5739C6.01615 12.7854 6.18581 12.9584 6.39318 12.9584H7.52426C7.73163 12.9584 7.90129 12.7854 7.90129 12.5739Z"
                fill="#02505B"
              />
              <path
                d="M9.21114 12.7965H9.35209V15.2122C9.35209 15.6694 9.6999 16.1288 10.2291 16.1288H11.3602C11.8894 16.1288 12.2372 15.6694 12.2372 15.2122V13.6296H12.7453V15.2122C12.7453 15.6694 13.0931 16.1288 13.6224 16.1288H14.7534C15.2827 16.1288 15.6305 15.6694 15.6305 15.2122V12.7965H15.7714C16.1171 12.7965 16.3414 12.555 16.4207 12.3255C16.4994 12.0978 16.4709 11.7947 16.2453 11.5765L13.0966 8.4432L13.0954 8.44203C12.7573 8.10787 12.2252 8.10787 11.8872 8.44203L11.8872 8.44203L11.886 8.4432L8.73539 11.5784C8.51369 11.7964 8.48564 12.0969 8.56236 12.3229C8.64008 12.5519 8.86283 12.7965 9.21114 12.7965Z"
                fill="#02505B"
                stroke="white"
              />
              <path
                d="M10.3679 21.1761C15.7404 21.1761 20.1609 17.1612 20.6887 12.0177C20.7453 11.4719 20.3023 11.0001 19.7462 11.0001C19.2655 11.0001 18.8602 11.3517 18.8131 11.8142C18.3984 16.0326 14.7696 19.3259 10.3679 19.3259C7.4272 19.3259 4.83522 17.855 3.31774 15.6256H4.71269C5.23109 15.6256 5.65523 15.2093 5.65523 14.7005C5.65523 14.1917 5.23109 13.7754 4.71269 13.7754H0.942539C0.424142 13.7754 0 14.1917 0 14.7005V18.4008C0 18.9096 0.424142 19.3259 0.942539 19.3259C1.46093 19.3259 1.88508 18.9096 1.88508 18.4008V16.8559C3.76073 19.4647 6.86168 21.1761 10.3679 21.1761ZM10.3679 0.824219C4.99545 0.824219 0.574949 4.83908 0.0471269 9.98255C-4.5646e-08 10.5284 0.433568 11.0001 0.989666 11.0001C1.47036 11.0001 1.87565 10.6486 1.92278 10.1861C2.3375 5.96769 5.96627 2.67439 10.3679 2.67439C13.3086 2.67439 15.9006 4.14527 17.4181 6.37472H16.0232C15.5048 6.37472 15.0806 6.79101 15.0806 7.29981C15.0806 7.8086 15.5048 8.22489 16.0232 8.22489H19.7933C20.3117 8.22489 20.7358 7.8086 20.7358 7.29981V3.59947C20.7358 3.09068 20.3117 2.67439 19.7933 2.67439C19.2749 2.67439 18.8508 3.09068 18.8508 3.59947V5.14436C17.8935 3.80927 16.6231 2.71979 15.1467 1.96789C13.6703 1.21599 12.0313 0.823728 10.3679 0.824219Z"
                fill="#02505B"
              />
            </svg>
            <Margin width={16} />
            Trocar matrícula
          </Button>
        )}
      </Card>
    </Column>
  );
};

export default Header;
