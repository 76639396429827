/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable new-cap */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  RowInfo,
  ContainerShare,
  ContainerDetail,
} from './styles';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { DownloadIcon } from './icon';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import LoadingComponent from '~/components/Loading';
import {
  activateModalDataUpdate,
  activateModalSalesforceSatisfaction,
} from '~/store/modules/outsides/actions';

const EasyDuplicateSinglePaymentDataPaymentReceipt: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const dataInvoiceGroupVoucher = useSelector(
    (item: IState) => item?.outsides?.dataInvoiceVoucher,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const printPDF = async () => {
    setLoading(true);
    setTimeout(async () => {
      const pdf = new jsPDF('portrait', 'pt', 'a4', true);
      const domElement = document.getElementById('download-receipt');
      if (domElement) {
        const data = await html2canvas(domElement);
        const img = data.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('comprovante.pdf');
      }

      if (dataInvoiceGroupVoucher && dataInvoiceGroupVoucher?.detail) {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[Ñ Logado][Pg. Única Fatura][WEB] baixar comprovante',
        });

        ReactGA.event({
          category: 'Pagamento Único',
          action:
            '[QD][C3][WEB] – Cartão – Pg. de uma única dívida - Baixar Comprovante',
        });
      } else {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[Ñ Logado][Pg. Único][WEB] baixar comprovante',
        });

        ReactGA.event({
          category: 'Pagamento Único',
          action: '[QD][C3][WEB] – Pg. Único - Baixar Comprovante',
        });
      }

      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (dataInvoiceGroupVoucher && dataInvoiceGroupVoucher?.detail) {
      ReactGA.event({
        category: 'Pagamento Único',
        action: '[Ñ Logado][Pg. Única Fatura][WEB] Pagamento com Sucesso',
      });

      ReactGA.event({
        category: 'Pagamento Único',
        action:
          '[QD][C3][WEB] – Cartão – Pg. de uma única dívida - Sucesso no Pagamento',
      });
    } else {
      ReactGA.event({
        category: 'Pagamento Único',
        action: '[Ñ Logado][Pg. Único][WEB] Pagamento com Sucesso',
      });

      ReactGA.event({
        category: 'Pagamento Único',
        action: '[QD][C3][WEB] – Cartão – PG. Único - Sucesso no Pagamento',
      });
    }

    dataRegistrationUpdate?.isUpdatable
      ? dispatch(activateModalDataUpdate())
      : dispatch(activateModalSalesforceSatisfaction());
  }, []);

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Baixando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.LANDING),
            },
            {
              label: 'Comprovante de pagamento',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card id="download-receipt">
        <h1>Comprovante de pagamento</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Sucesso!</h2>
          <Border />
        </ContainerBorder>
        <Margin height={14} />
        {!loading && (
          <>
            <ContainerShare>
              <button type="button" onClick={printPDF}>
                Baixar
                <DownloadIcon />
              </button>
              {/* <button type="button" onClick={() => console.log('Email')}>
            Email
            <EmailIcon />
          </button> */}
            </ContainerShare>
            <Margin height={24} />
          </>
        )}
        <RowInfo>
          <p className="capitalize">
            Cliente: &ensp;{dataInvoiceGroupVoucher?.header.name.toLowerCase()}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>
            CPF: &ensp;
            {formatter.cpfCnpjMask(dataInvoiceGroupVoucher?.header.cnp || '')}
          </p>
        </RowInfo>
        <RowInfo>
          <p>Endereço: &ensp;{dataInvoiceGroupVoucher?.header.adrress}</p>
        </RowInfo>
        <RowInfo backgroundWhite />
        <RowInfo>
          <p>Valor total:</p>
          <p className="text-end">
            {formatter.formatCurrency(dataInvoiceGroupVoucher?.header?.amount_total)}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>Tipo/bandeira: &ensp;{dataInvoiceGroupVoucher?.header.type}</p>
        </RowInfo>
        <RowInfo>
          <p>
            Pagamento: &ensp;
            {moment(dataInvoiceGroupVoucher?.header.received_date).format(
              'DD/MM/YYYY HH:mm',
            )}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>
            Autenticação de pagamento: {dataInvoiceGroupVoucher?.header.payment_id}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite />
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Detalhes</h2>
          <Border />
        </ContainerBorder>
        {dataInvoiceGroupVoucher && dataInvoiceGroupVoucher?.detail && (
          <ContainerDetail>
            <RowInfo key={dataInvoiceGroupVoucher?.detail.invoice_id}>
              <p>Referência: &ensp;{dataInvoiceGroupVoucher?.detail.reference}</p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>Fatura: &ensp;{dataInvoiceGroupVoucher?.detail.invoice_id}</p>
            </RowInfo>
            <RowInfo>
              <p>
                Vencimento: &ensp;
                {moment(dataInvoiceGroupVoucher?.detail.maturity_date).format(
                  'DD/MM/YYYY',
                )}
              </p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>
                Emissão: &ensp;
                {moment(dataInvoiceGroupVoucher?.detail?.issue_date_hour).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </p>
            </RowInfo>
            {dataInvoiceGroupVoucher?.detail.items?.map((item, index) => (
              <RowInfo key={item.description}>
                <p>
                  {index === 0 ? (
                    'Descrição:  '
                  ) : (
                    <>
                      {!isMobile && (
                        <>
                          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                        </>
                      )}
                    </>
                  )}{' '}
                  {item.description.toLowerCase()}
                </p>
                <p className="text-end">{formatter.formatCurrency(item.amount)}</p>
              </RowInfo>
            ))}
            <RowInfo backgroundWhite>
              <p>Valor:</p>
              <p>
                {formatter.formatCurrency(dataInvoiceGroupVoucher?.detail.amount)}
              </p>
            </RowInfo>
            <RowInfo backgroundWhite />
          </ContainerDetail>
        )}
        {dataInvoiceGroupVoucher &&
          dataInvoiceGroupVoucher?.details &&
          dataInvoiceGroupVoucher?.details.length > 0 &&
          dataInvoiceGroupVoucher?.details?.map((detail) => (
            <ContainerDetail>
              <RowInfo key={detail.invoice_id}>
                <p>Referência: &ensp;{detail.reference}</p>
              </RowInfo>
              <RowInfo backgroundWhite>
                <p>Fatura: &ensp;{detail.invoice_id}</p>
              </RowInfo>
              <RowInfo>
                <p>
                  Vencimento: &ensp;
                  {moment(detail.maturity_date).format('DD/MM/YYYY')}
                </p>
              </RowInfo>
              <RowInfo backgroundWhite>
                <p>
                  Emissão: &ensp;
                  {moment(detail?.issue_date_hour).format('DD/MM/YYYY HH:mm')}
                </p>
              </RowInfo>
              {detail.items?.map((item, index) => (
                <RowInfo key={item.description}>
                  <p>
                    {index === 0 ? (
                      'Descrição:  '
                    ) : (
                      <>
                        {!isMobile && (
                          <>
                            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                          </>
                        )}
                      </>
                    )}{' '}
                    {item.description.toLowerCase()}
                  </p>
                  <p className="text-end">{formatter.formatCurrency(item.amount)}</p>
                </RowInfo>
              ))}
              <RowInfo backgroundWhite>
                <p>Valor:</p>
                <p>{formatter.formatCurrency(detail.amount)}</p>
              </RowInfo>
              <RowInfo backgroundWhite />
            </ContainerDetail>
          ))}
        {!loading && (
          <ContainerButtons>
            <ButtonText
              text="Ir para home"
              typeBtn="content"
              onClick={() => Navigation.navigate(RouteName.LANDING)}
            />
          </ContainerButtons>
        )}
      </Card>
    </Main>
  );
};

export default EasyDuplicateSinglePaymentDataPaymentReceipt;
