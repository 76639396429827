import React, { FC, useEffect, useState } from 'react';

import {
  Container,
  Content,
  ContainerNotification,
  CardNotification,
  ContainerEmptyMessage,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Margin } from '~/components/Margin';
import { putUpdateNotificationRequest } from '~/store/modules/notifications/actions';
import moment from 'moment';
import EmptyMessage from '~/components/EmptyMessage';
import ArroButtonwBackSVG from '~/assets/Icons/ArrowBack copy';

interface IProps {
  setSelecetedNotification: (e: any) => void;
  selectedNotification: any;
}

const ModalNotifications: FC<IProps> = ({
  selectedNotification,
  setSelecetedNotification,
}: IProps) => {
  const dispatch = useDispatch();

  const code = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.code,
  );
  const data = useSelector((state: IState) => state.notifications.data);

  console.log(data);

  return (
    <Container>
      <Content>
        <h1>Notificações</h1>
        <Margin height={32} />
        <span>
          Matrícula {code} <div id="border" />
        </span>
        {data && data.length > 0 && (
          <ContainerNotification
            style={{ overflowY: data.length > 5 ? 'scroll' : undefined }}
          >
            {selectedNotification ? (
              <CardNotification
                key={selectedNotification?.id}
                type="button"
                is_read={false}
                read_view
              >
                <div>
                  <p>{selectedNotification.body}</p>
                </div>
                <p>
                  {selectedNotification.createdAt
                    ? moment(selectedNotification.createdAt).format('D/MMM')
                    : ''}
                  <br />
                  {selectedNotification.createdAt
                    ? moment(selectedNotification.createdAt).format('ddd')
                    : ''}
                </p>
              </CardNotification>
            ) : (
              data?.map((notification) => (
                <CardNotification
                  key={notification.id}
                  onClick={() => {
                    setSelecetedNotification(notification);
                    if (notification.is_read) return;
                    dispatch(putUpdateNotificationRequest(notification.id));
                  }}
                  type="button"
                  is_read={notification.is_read}
                >
                  <div>
                    <p>{notification.is_read ? 'Lida' : 'Nova'}</p>
                    <p>{notification.body}</p>
                  </div>
                  <p>
                    {notification.createdAt
                      ? moment(notification.createdAt).format('D/MMM')
                      : ''}
                    <br />
                    {notification.createdAt
                      ? moment(notification.createdAt).format('ddd')
                      : ''}
                  </p>
                </CardNotification>
              ))
            )}
          </ContainerNotification>
        )}
        <ContainerEmptyMessage read_view={selectedNotification}>
          {data && data.length > 0 ? (
            selectedNotification ? (
              <button
                type="button"
                onClick={() => setSelecetedNotification(undefined)}
              >
                <ArroButtonwBackSVG />
              </button>
            ) : (
              <p>
                Essas foram todas as notificações <br /> dos últimos 12 meses
              </p>
            )
          ) : (
            <>
              <Margin height={70} />

              <EmptyMessage marginHeight={15}>
                <p>
                  Você ainda não possui <br /> notificações
                </p>
              </EmptyMessage>
              <Margin height={70} />
            </>
          )}
        </ContainerEmptyMessage>
      </Content>
    </Container>
  );
};

export default ModalNotifications;
