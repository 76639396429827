import React, { FC } from 'react';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';
import StatusSuccessIconSVG from '~/assets/Icons/StatusSuccess';

import { Main } from './styles';
import StatusWaitIconSVG from '~/assets/Icons/StatusWait';
import { socialTariffStatusCuiaba } from '~/utils/socialTariffStatus';
import NextSVG from '~/assets/Icons/Next';

interface IProps {
  status: string;
  children: React.ReactNode;
  onClick?: () => void;
  labelButton?: string;
  maxWidthButton?: number;
}

const typeStatus = {
  Aguarde: {
    icon: <StatusWaitIconSVG />,
    title: 'Aguarde',
  },
  Atenção: {
    icon: <StatusWarningIconSVG />,
    title: 'Atenção',
  },
  'Documentação aprovada': {
    icon: <StatusSuccessIconSVG />,
    title: 'Documentação aprovada',
  },
};

const StatusInfo: FC<IProps> = ({
  status,
  children,
  onClick,
  labelButton,
  maxWidthButton,
}) => {
  const statusIcon = socialTariffStatusCuiaba(status).label as
    | 'Aguarde'
    | 'Atenção'
    | 'Documentação aprovada';

  return (
    <Main
      status={socialTariffStatusCuiaba(status).label}
      maxWidthButton={maxWidthButton}
    >
      <div>{typeStatus[statusIcon]?.icon}</div>
      <div>
        <p>{socialTariffStatusCuiaba(status).label}</p>
        <p>{children}</p>
      </div>
      {onClick && (
        <button type="button" className="button-download" onClick={onClick}>
          <p>{labelButton}</p>
          {labelButton === 'Ver solicitação' && <NextSVG />}
        </button>
      )}
    </Main>
  );
};

export default StatusInfo;
