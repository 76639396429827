import styled from 'styled-components';
import { Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 80px 60px;
`;

export const ContainerInfo = styled.div`
  width: 100%;
`;

export const BoxInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  input {
    height: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  select {
    padding: 6px 28px 6px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.gray};
  }
`;

export const BoxInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.blueIntense};

    span {
      font-weight: 500;
      text-transform: uppercase;
      color: ${Color.blueSeconde};
    }
  }
`;
