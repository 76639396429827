import { v4 } from 'uuid';
import { api } from '~/services/api';

export const apiHeaderSetSession = () => {
  const session_id = `${v4()}`;

  localStorage.setItem('x-session-id', session_id);

  api.defaults.headers.common['x-session-id'] = session_id;
};

export const apiHeaderReset = () => {
  localStorage.removeItem('x-session-id');

  delete api.defaults.headers.common['x-session-id'];
};
