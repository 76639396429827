import React from 'react';

import versionsAppJsonImport from '~/configs/versions.json';

import { Main } from './styles';

const versionsAppJson = versionsAppJsonImport as any;

const Versions: React.FC = () => {
  return (
    <Main>
      <p>Versão atual: {versionsAppJson.version}</p>
    </Main>
  );
};

export default Versions;
