import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px;
  gap: 16px;
  background: #f8f9fa;
  border-radius: 16px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #004b55;
  display: flex;
  align-items: center;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-start;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ButtonPromotionalDiscounts = styled.button`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 4px;
  gap: 8px;
  background: #afd7c3;
  border-radius: 4px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 339px;
  }
`;

export const TextButtonDiscounts = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1f1f1f;
`;

export const ButtonNegotiationPayment = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 8px 12px;
  width: 200px;
  height: 33px;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
`;

export const NextLabelButtonPayment = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextButtonPayment = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;
