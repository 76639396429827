import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CardNotClient } from '~/components/CardNotClient';
import ModalUpdateEnrollment from '~/components/ModalUpdateEnrollment';
import LayoutApp from '~/components/Layout';
import ChangeEmail from '~/views/ChangeEmail';
import ChangePhone from '~/views/ChangePhone';
import ChangePhoneMobile from '~/views/ChangePhoneMobile';
import Invoices from '~/views/Invoices';
import InvoicesChangeDueDate from '~/views/Invoices/InvoiceChangeDueDate';
import RegistrationsList from '~/views/RegistrationsList';
import SimplifiedInvoiceNew from '~/views/SimplifiedInvoiceV4';
import SimplifiedInvoicePixNew from '~/views/SimplifiedInvoice/PaymentMethods/Pix';
import SimplifiedInvoiceCreditCardNew from '~/views/SimplifiedInvoice/PaymentMethods/CreditCard';
import NegativeCertificatePage from '~/views/NegativeCertificate';
import HomeVersionTwo from '~/views/Home';
import AccountConfig from '~/views/AccountConfig';
import Notifications from '../views/Notifications';
import SmartMeter from '~/views/SmartMeter';
import SupportCenter from '~/views/SupportCenter';
import { IState } from '~/store/modules/types';
import { RouteName } from './Routes.name';

import DetailsHistory from '~/views/DetailsHistory';
import SupplyPossibilityConsultation from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SupplyPossibilityConsultation';
import AccessProfile from '~/views/AccessProfile';
import Wallet from '~/views/Wallet';
import WalletAddCard from '~/views/WalletAddCard';
import WalletDetailCard from '~/views/WalletDetailCard';
import AutomaticDebit from '~/views/AutomaticDebit';
import OwnershipManagement from '~/views/Ownership';
import OwnershipCreateOrder from '~/views/Ownership/CreateOrder';
import OwnershipViewOrder from '~/views/Ownership/ViewOrder';
import OwnershipProtocol from '~/views/Ownership/ViewProtocol';
import SocialTariff from '~/views/SocialTariff';
import SocialTariffSolicitation from '~/views/SocialTariffSolicitation';
import SocialTariffRequest from '~/views/SocialTariffRequest';
import CustomerObligation from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation';
import { Dialog } from '~/components/Dialog';
import {
  clearModalDuplicateInvoices,
  clearModalSalesforceSatisfaction,
  clearRouteReportLackWater,
} from '~/store/modules/outsides/actions';
import {
  clearRouteAuthRedirect,
  getEnrollmentsAuthDestroySessionRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import Navigation from '~/utils/Navigation';
import { checkToggle } from '~/utils/toggles';
import LinkEnrollment from '~/views/LinkEnrollment';
import LinkEnrollmentForm from '~/views/LinkEnrollmentForm';
import { clearModalNotClient } from '~/store/modules/enrollmentsUsersNotClient/actions';
import ModalNotClient from '~/components/ModalNotClient';
import PropertyRegistration from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration';
import ChangePassword from '~/views/AccountConfig/Options/Access/changePassword';
import { history } from '~/store';
import SinglePayment from '~/views/SinglePayment';
import SinglePaymentDataPayment from '~/views/SinglePaymentDataPayment';
import SinglePaymentDataPaymentReceipt from '~/views/SinglePaymentDataPaymentReceipt';
import InvoiceChangeDeliveryType from '~/views/Invoices/InvoiceChangeDeliveryType';
import SmartMeterSubscribesPayment from '~/views/SmartMeterSubscribesPayment';
import SupportCenterDetail from '~/views/SupportCenterDetail';
import ModalSalesforceSatisfaction from '~/components/ModalSalesforceSatisfaction';
import SupplyAndDepletionPossibilityDeclarationPanel from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/Panel';
import { EnterpriseViabilityNewProcess } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/location';
import CpaeValuesTable from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SupplyPossibilityConsultation/ValuesTable';
import SupplyAndDepletionPossibilityDeclarationPanelSlips from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PanelSlips';
import { SupplyAndDepletionPossibilityDeclarationKnowMore } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/KnowMore';
import SimplifiedInvoicePayments from '~/views/SimplifiedInvoicePayments';
import SimplifiedInvoicePaymentsReceipt from '~/views/SimplifiedInvoicePaymentsReceipt';
import SupplyAndDepletionPossibilityDeclarationSlips from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SlipsPayment';
import ReportLackWater from '~/views/ReportLackWater';
import SupportCenterProtocol from '~/views/SupportCenterProtocol';
import ModalReportLackWaterInfo from '~/components/ModalReportLackWaterInfo';
import LoadingComponent from '~/components/Loading';
import { getErrorCloseServiceValidateLackWaterRequest } from '~/store/modules/orderServices/actions';
import Negotiations from '~/views/Negotiations';
import NegotiationsDetail from '~/views/NegotiationsDetail';

function RouteAppWithLayout({ Component, path, titleNotClient, ...props }: any) {
  const dispatch = useDispatch();

  const dataHistory = history.location.state as any;

  const modalSalesforceSatisfaction = useSelector(
    (item: IState) => item.outsides.modalSalesforceSatisfaction,
  );
  const modalEnrollmentNotExists = useSelector(
    (item: IState) => item.enrollmentsAuth.modalEnrollmentNotExists,
  );
  const modalNotExists = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.modalNotExists,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const dataValidateLackWater = useSelector(
    (item: IState) => item.orderServices.dataValidateLackWater,
  );
  const reportLackWaterRoute = useSelector(
    (item: IState) => item.outsides.reportLackWaterRoute,
  );
  const loadingValidateLackWater = useSelector(
    (item: IState) => item.orderServices.loadingValidateLackWater,
  );

  const isNotClient = () => {
    return !isClient && !isEntrepreneur && !isManager;
  };

  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);
  const [modalReportLackWater, setModalReportLackWater] = useState<boolean>(false);

  const verifyToggles = async () => {
    setSatisfactionSurvey(
      await checkToggle(togglesMe, 'SATISFACTION_SURVEY_LOGGED'),
    );
  };

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifyToggles();
  }, [togglesMe]);

  const handleOnCloseReportLackWaterInfo = () => {
    dispatch(clearRouteReportLackWater());
    dispatch(getErrorCloseServiceValidateLackWaterRequest());

    setModalReportLackWater(false);
  };

  useEffect(() => {
    if (
      (dataValidateLackWater?.type_info && !dataValidateLackWater?.validate) ||
      (dataValidateLackWater?.type_info &&
        !dataValidateLackWater?.validate &&
        reportLackWaterRoute)
    ) {
      setModalReportLackWater(true);
    }
  }, [reportLackWaterRoute, dataValidateLackWater]);

  return (
    <Route path={path} {...props}>
      <LayoutApp>
        {loadingValidateLackWater && <LoadingComponent labelWait="Carregando..." />}
        <Dialog
          open={modalReportLackWater}
          onClose={handleOnCloseReportLackWaterInfo}
        >
          <ModalReportLackWaterInfo
            onClose={handleOnCloseReportLackWaterInfo}
            typeInfo={dataValidateLackWater?.type_info || 'water_supply_interrupted'}
          />
        </Dialog>
        {modalEnrollmentNotExists && (
          <Dialog
            open={modalEnrollmentNotExists}
            onClose={async () => {
              dispatch(clearModalDuplicateInvoices());
              dispatch(getEnrollmentsAuthDestroySessionRequest());
              Navigation.navigate(
                (await checkToggle(toggles, 'LAYOUT_V3_WEB'))
                  ? RouteName.LOGIN
                  : RouteName.LANDING,
              );
            }}
            fullScreen
          >
            <ModalUpdateEnrollment />
          </Dialog>
        )}
        {modalNotExists && (
          <Dialog
            open={modalNotExists}
            onClose={() => dispatch(clearModalNotClient())}
            fullScreen
          >
            <ModalNotClient />
          </Dialog>
        )}
        {satisfactionSurvey && modalSalesforceSatisfaction && (
          <Dialog
            open={modalSalesforceSatisfaction}
            onClose={async () => dispatch(clearModalSalesforceSatisfaction())}
          >
            <ModalSalesforceSatisfaction />
          </Dialog>
        )}
        {isNotClient() && titleNotClient ? (
          <CardNotClient
            title={dataHistory?.titleNotClient || titleNotClient || ''}
          />
        ) : (
          <Component />
        )}
      </LayoutApp>
    </Route>
  );
}

const AppRoutes: FC = () => {
  const dispatch = useDispatch();

  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );
  const registrationCheckEnrollment = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loadingAuth = useSelector((state: IState) => state.enrollmentsAuth.loading);
  const firstAccess = useSelector(
    (state: IState) => state.enrollmentsAuth.firstAccess,
  );
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);

  const dataCreateClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateClient,
  );

  const dataCreateNotClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateNotClient,
  );

  const routeAuthRedirect = useSelector(
    (item: IState) => item.enrollmentsAuth.routeAuthRedirect,
  );
  const loadingEnrollmentsAuth = useSelector(
    (item: IState) => item.enrollmentsAuth.loading,
  );

  const [hasRedirected, setHasRedirected] = useState(false);

  const isNotClient = () => {
    return !isClient && !isEntrepreneur && !isManager;
  };

  const redirectRoutesEntrepreneur = [RouteName.ACCOUNTCONFIG];

  const redirects = {
    accessProfile: RouteName.ACCESSPROFILE,
    entrepreneur: redirectRoutesEntrepreneur.includes(routeAuthRedirect)
      ? routeAuthRedirect
      : RouteName.ENTERPRISE_VIABILITY,
    manager: RouteName.HOME,
    multiRegistrations: RouteName.REGISTRATIONLIST,
    notClient: routeAuthRedirect || RouteName.HOME,
    registerInfoClient: RouteName.REGISTERINFOCLIENT,
    default: routeAuthRedirect || RouteName.HOME,
  };

  const resolveRedirect = () => {
    if ((isEntrepreneur && isClient) || (isManager && isClient))
      return 'accessProfile';

    if (isManager) return 'manager';

    if (isEntrepreneur) return 'entrepreneur';

    if (firstAccess && dataCreateNotClient?.documentNumber && !isClient)
      return 'notClient';

    if (firstAccess && !dataCreateClient?.isNotClient) {
      if ((registrations && registrations.length > 1) || loadingAuth)
        return 'multiRegistrations';

      return 'default';
    }

    if ((registrations && registrations.length > 1) || loadingAuth)
      return 'multiRegistrations';

    return 'default';
  };

  const redirect = resolveRedirect();

  useEffect(() => {
    if (
      isNotClient() &&
      !loadingEnrollmentsAuth &&
      routeAuthRedirect &&
      !hasRedirected
    ) {
      setHasRedirected(true);
      dispatch(clearRouteAuthRedirect());
    }

    if (
      isClient &&
      registrationCheckEnrollment &&
      !loadingEnrollmentsAuth &&
      routeAuthRedirect &&
      !hasRedirected
    ) {
      setHasRedirected(true);
      dispatch(clearRouteAuthRedirect());
    }
  }, [loadingEnrollmentsAuth, routeAuthRedirect, hasRedirected]);

  return (
    <>
      {!hasRedirected && <Redirect to={redirects[redirect]} />}
      <Switch>
        <RouteAppWithLayout
          Component={RegistrationsList}
          path={RouteName.REGISTRATIONLIST}
          exact
        />
        <RouteAppWithLayout Component={HomeVersionTwo} path={RouteName.HOME} exact />
        <RouteAppWithLayout
          Component={NegativeCertificatePage}
          path={RouteName.NEGATIVECERTIFICATE}
          exact
          titleNotClient="Certidão negativa"
        />
        <RouteAppWithLayout
          Component={AccountConfig}
          path={RouteName.ACCOUNTCONFIG}
          exact
        />
        <RouteAppWithLayout
          Component={Invoices}
          path={RouteName.INVOICES}
          exact
          titleNotClient="Faturas"
        />
        <RouteAppWithLayout
          Component={InvoicesChangeDueDate}
          path={RouteName.INVOICESCHANGEEXPIRATION}
          exact
          titleNotClient="Faturas"
        />
        <RouteAppWithLayout
          Component={InvoiceChangeDeliveryType}
          path={RouteName.INVOICESCHANGEDELIVERYTYPE}
          exact
          titleNotClient="Faturas"
        />
        <RouteAppWithLayout
          Component={SimplifiedInvoicePaymentsReceipt}
          path={RouteName.INVOICERECEIP}
          exact
        />
        <RouteAppWithLayout
          Component={SimplifiedInvoiceNew}
          path={RouteName.SIMPLIFIEDINVOICE}
          exact
          titleNotClient="EMISSÃO DE SEGUNDA VIA"
        />
        <RouteAppWithLayout
          Component={SimplifiedInvoicePixNew}
          path={RouteName.SIMPLIFIEDINVOICEPIX}
          exact
        />
        <RouteAppWithLayout
          Component={SimplifiedInvoiceCreditCardNew}
          path={RouteName.SIMPLIFIEDINVOICECREDITCARD}
          exact
        />
        <RouteAppWithLayout
          Component={SimplifiedInvoicePayments}
          path={RouteName.SIMPLIFIEDINVOICEPAYMENTMETHODS}
          exact
        />
        <RouteAppWithLayout
          Component={ChangePhone}
          path={RouteName.CHANGEPHONE}
          exact
        />
        <RouteAppWithLayout
          Component={ChangePhoneMobile}
          path={RouteName.CHANGEMOBILE}
          exact
        />
        <RouteAppWithLayout Component={ChangeEmail} path={RouteName.CHANGEEMAIL} />
        <RouteAppWithLayout
          Component={ChangePassword}
          path={RouteName.CHANGEPASSOWORD}
          exact
        />
        <RouteAppWithLayout
          Component={DetailsHistory}
          path={RouteName.DETAILEDHISTORY}
          exact
          titleNotClient="HISTÓRICO DETALHADO"
        />
        <RouteAppWithLayout
          Component={SupplyAndDepletionPossibilityDeclarationPanel}
          path={RouteName.ENTERPRISE_VIABILITY}
          exact
        />
        <RouteAppWithLayout
          Component={CustomerObligation}
          path={RouteName.CUSTOMEROBLIGATION}
          exact
        />
        <RouteAppWithLayout
          Component={PropertyRegistration}
          path={RouteName.PROPERTYREGISTRATION}
          exact
        />
        <Route component={AccessProfile} path={RouteName.ACCESSPROFILE} exact />
        <RouteAppWithLayout
          Component={Notifications}
          path={RouteName.NOTIFICATIONS}
          exact
        />
        <RouteAppWithLayout
          Component={SmartMeter}
          path={RouteName.SMARTMETER}
          exact
        />
        <RouteAppWithLayout
          Component={SmartMeterSubscribesPayment}
          path={RouteName.SMARTMETERSUBSCRIPTION}
          exact
        />
        <RouteAppWithLayout Component={Wallet} path={RouteName.WALLET} exact />
        <RouteAppWithLayout
          Component={WalletAddCard}
          path={RouteName.WALLETADDCARD}
          exact
        />
        <RouteAppWithLayout
          Component={WalletDetailCard}
          path={RouteName.WALLETDETAILCARD}
          exact
        />
        <RouteAppWithLayout
          Component={SupportCenter}
          path={RouteName.SUPPORTCENTER}
          exact
          titleNotClient="Minhas solicitações"
        />
        <RouteAppWithLayout
          Component={SupportCenterDetail}
          path={RouteName.SUPPORTCENTERSERVICEORDER}
          exact
        />
        <RouteAppWithLayout
          Component={AutomaticDebit}
          path={RouteName.AUTOMATICDEBIT}
          exact
          titleNotClient="Débito automático"
        />
        <RouteAppWithLayout
          Component={OwnershipManagement}
          path={RouteName.OWNERSHIPMANAGEMENT}
          exact
        />
        <RouteAppWithLayout
          Component={OwnershipCreateOrder}
          path={RouteName.OWNERSHIPMANAGEMENTCREATEORDER}
          exact
        />
        <RouteAppWithLayout
          Component={OwnershipViewOrder}
          path={RouteName.OWNERSHIPMANAGEMENTVIEWORDER}
          exact
        />
        <RouteAppWithLayout
          Component={OwnershipProtocol}
          path={RouteName.OWNERSHIPMANAGEMENTPROTOCOL}
          exact
        />
        <RouteAppWithLayout
          Component={SocialTariff}
          path={RouteName.SOCIALTARIFF}
          exact
          titleNotClient="Tarifa social"
        />
        <RouteAppWithLayout
          Component={SocialTariffSolicitation}
          path={RouteName.SOCIALTARIFFSOLICITATION}
          exact
        />
        <RouteAppWithLayout
          Component={SocialTariffRequest}
          path={RouteName.SOCIALTARIFFREQUEST}
          exact
        />
        <RouteAppWithLayout
          Component={LinkEnrollment}
          path={RouteName.LINKENROLLMENT}
          exact
        />
        <RouteAppWithLayout
          Component={LinkEnrollmentForm}
          path={RouteName.LINKENROLLMENTFORM}
          exact
        />
        <RouteAppWithLayout
          Component={SinglePayment}
          path={RouteName.SINGLEPAYMENT}
          exact
        />
        <RouteAppWithLayout
          Component={SinglePaymentDataPayment}
          path={RouteName.SINGLEPAYMENTDATAPAYMENT}
          exact
        />
        <RouteAppWithLayout
          Component={SinglePaymentDataPaymentReceipt}
          path={RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT}
          exact
        />
        <RouteAppWithLayout
          Component={EnterpriseViabilityNewProcess}
          path={RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS}
          exact
        />
        <RouteAppWithLayout
          Component={SupplyPossibilityConsultation}
          path={RouteName.SUPPLYPOSSIBILITYCONSULTATION}
          exact
        />
        <RouteAppWithLayout
          Component={CpaeValuesTable}
          path={RouteName.SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE}
          exact
        />
        <RouteAppWithLayout
          Component={SupplyAndDepletionPossibilityDeclarationKnowMore}
          path={RouteName.ENTERPRISE_VIABILITY_KNOW_MORE}
          exact
        />
        <RouteAppWithLayout
          Component={SupplyAndDepletionPossibilityDeclarationPanelSlips}
          path={RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS}
          exact
        />
        <RouteAppWithLayout
          Component={SupplyAndDepletionPossibilityDeclarationSlips}
          path={RouteName.ENTERPRISE_VIABILITY_SLIPS_PAYMENT}
          exact
        />
        <RouteAppWithLayout
          Component={ReportLackWater}
          path={RouteName.REPORTLACKWATER}
          exact
        />
        <RouteAppWithLayout
          Component={SupportCenterProtocol}
          path={RouteName.SUPPORTCENTERPROTOCOL}
          exact
        />
        <RouteAppWithLayout
          Component={Negotiations}
          path={RouteName.NEGOTIATIONS}
          exact
        />
        <RouteAppWithLayout
          Component={NegotiationsDetail}
          path={RouteName.NEGOTIATIONSDETAIL}
          exact
        />
      </Switch>
    </>
  );
};

export default AppRoutes;
