import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Container = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 24px;
  background: ${Color.white};
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;

  button {
    width: 100%;
    height: 48px;
    cursor: pointer;
    border-radius: 8px;
    background: ${Color.blueSeconde};
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    margin-top: 24px;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
  margin: 24px 0;
`;

export const ContainerAmount = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  p,
  span {
    text-align: center;
    color: ${Color.blueSeconde};
    font-size: 12px;
    font-weight: 400;
  }

  span {
    font-size: 28px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    a {
      font-size: 14px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    a {
      display: flex;
    }
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    color: ${Color.gray};
    font-size: 11px;
    font-weight: 600;
    line-height: 161.4%;
    margin-bottom: 8px;

    svg {
      margin-right: 9px;
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    a {
      font-size: 14px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    a {
      display: flex;
    }
  }
`;
