import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import { Margin } from '~/components/Margin';
import { Breakpoints, Color } from '~/styles';
import InvoiceSlick from './InvoiceSlick';
import {
  CardColumnDescSlick,
  CardColumnDesc,
  Container,
  ContentFaturaFacil,
  DescriptionCard,
  TitleCard,
  TitlePaymentGroupCard,
  PaymentGroupCard,
  PaymentGroupButton,
} from './styles';
import { Main } from './styles';
import {
  StatusGroupCongratulations,
  StatusGroupInstallment,
  StatusGroupPending,
  StatusGroupWait,
} from './icon';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { checkToggle } from '~/utils/toggles';

const EasyInvoice = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const loading = useSelector((item: IState) => item?.enrollmentsInvoices?.loading);
  const invoices = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.invoices,
  );
  const invoicesGroup = invoices?.filter(
    (invoice) =>
      invoice.status.label === 'PENDENTE' || invoice.status.label === 'VENCIDA',
  );
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);
  const [groupPayment, setGroupPayment] = useState<boolean>(false);

  const verifyToggles = async () => {
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const getStatusInvoicesGroup = () => {
    const invoicesGroupLengths = invoicesGroup?.length || 0;
    const invoicesGroupPending = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'PENDENTE',
    );
    const invoicesGroupDefeated = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'VENCIDA',
    );

    if (!invoices || invoices.length < 1 || loading) {
      return {
        color: Color.blueSeconde,
        icon: <StatusGroupWait />,
        title: 'Aguarde!',
        description:
          'Sua matrícula ainda não tem faturas. Em caso de dúvidas, favor entre em contato pelo Fale conosco.',
      };
    }

    if (invoicesGroup && invoicesGroup?.length > 1) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: `${invoicesGroupLengths} faturas em aberto`,
        description: 'Pague suas faturas em aberto em até 12X sem juros!',
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupPending &&
      invoicesGroupPending.length === 1
    ) {
      return {
        color: Color.blueIntense,
        icon: <StatusGroupPending />,
        title: 'Sua fatura vence em breve',
        description:
          'Com o Digi Iguá você pode pagar com PIX, boleto ou parcelar em até 12X no cartão de crédito.',
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupDefeated &&
      invoicesGroupDefeated.length === 1
    ) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: 'Não perca tempo!',
        description:
          'Mantenha suas contas em dia pagando com PIX, boleto ou parcelando em até 12X no cartão de crédito.',
      };
    }

    return {
      color: Color.green,
      icon: <StatusGroupCongratulations />,
      title: 'Parabéns!',
      description: 'Agradecemos por manter as suas contas em dia.',
    };
  };

  return (
    <Main>
      <Container>
        <ContentFaturaFacil>
          <CardColumnDesc>
            {groupPayment ? (
              <>
                <TitlePaymentGroupCard>Fatura fácil</TitlePaymentGroupCard>
                <PaymentGroupCard
                  fullHeight={
                    !!(invoicesGroup && invoicesGroup?.length > 1 && !loading)
                  }
                  color={getStatusInvoicesGroup().color}
                >
                  <p>
                    <span>{getStatusInvoicesGroup().icon}</span>
                    {getStatusInvoicesGroup().title}
                  </p>
                  <p>
                    <span>{getStatusInvoicesGroup().icon} </span>
                    {!invoices || invoices.length < 1 || loading ? (
                      <div>{getStatusInvoicesGroup().description}</div>
                    ) : (
                      getStatusInvoicesGroup().description
                    )}
                  </p>
                </PaymentGroupCard>
                {invoicesGroup && invoicesGroup?.length > 1 && !loading && (
                  <PaymentGroupButton
                    type="button"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Pagamento Único',
                        action: '[Pag.Único] [WEB] Parcelar meus débitos',
                      });
                      Navigation.navigate(RouteName.SINGLEPAYMENT, {
                        invoices: invoicesGroup.reverse(),
                      });
                    }}
                  >
                    Parcelar meus débitos
                  </PaymentGroupButton>
                )}
              </>
            ) : (
              <>
                <TitleCard>Fatura Fácil</TitleCard>
                <Margin height={isMobile ? 0 : 20} />
                <DescriptionCard>
                  Com o novo card de pagamento Digi Iguá ficou mais fácil a
                  visualização do status das suas faturas! <br />
                  Pague de forma rápida e segura.
                  <br /> Agora, além do cartão de crédito, você também pode quitar
                  sua fatura com PIX. Aqui você também encontra o link de acesso à
                  sua 2 via.
                </DescriptionCard>
              </>
            )}
          </CardColumnDesc>
          <CardColumnDescSlick className="content_slick">
            <InvoiceSlick />
          </CardColumnDescSlick>
        </ContentFaturaFacil>
      </Container>
    </Main>
  );
};

export default EasyInvoice;
