import React, { FC, useState, useEffect } from 'react';
import * as S from './styles';
import CheckedIcon from '~/assets/Icons/CheckedIcon';

interface Props {
  children: string;
  handleCheck?: (checked: boolean) => any;
}

const Checkbox: FC<Props> = ({ children, handleCheck }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    handleCheck?.(checked);
  }, [checked]);

  return (
    <S.Checkbox onClick={() => setChecked(!checked)} checked={checked}>
      <S.CheckboxHidden onChange={() => setChecked(!checked)} checked={checked} />
      <S.CheckboxStyled checked={checked}>
        <CheckedIcon />
      </S.CheckboxStyled>
      <S.CheckboxLabel checked={checked}>{children}</S.CheckboxLabel>
    </S.Checkbox>
  );
};

export { Checkbox };
