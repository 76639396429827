import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 24px;
  background: #f2f2f2;
  border-radius: 8px;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 16px;

  input,
  textarea {
    :disabled {
      background: #f2f2f2;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 40px;
  }
`;

export const ContainerDescriptionText = styled.div`
  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-left: auto;
  }
`;

export const BoxInput = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(50% - 20px);
  }
`;
