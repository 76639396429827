import React from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

import { ButtonStyled } from './styles';

interface Props {
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  loading?: boolean;
  modalType?: 'error' | 'info' | 'success';
  disabled?: boolean;
}

export const Button: React.FC<Props> = ({
  text,
  type,
  onClick,
  loading,
  modalType,
  disabled,
}) => {
  return (
    <ButtonStyled
      modalType={modalType}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <ClipLoader color="#FFF" loading={loading} size={10} /> : text}
    </ButtonStyled>
  );
};
