import { api } from './api';
import {
  IRequestPostSubscription,
  IRequestDeleteSubscription,
} from '~/store/modules/subscription/types';

const getSubscriptionService = () => {
  return new Promise((resolve, reject) => {
    try {
      const getSubscription = async () => {
        const res = await api.get('v3/subscribes/me');
        return res.data[0];
      };

      resolve(getSubscription());
    } catch (error) {
      reject(error);
    }
  });
};

const postSubscriptionService = (data: IRequestPostSubscription) => {
  return new Promise((resolve, reject) => {
    try {
      const postSubscription = async () => {
        const res = await api.post('v3/subscribes/me', data);
        return res;
      };

      resolve(postSubscription());
    } catch (error) {
      reject(error);
    }
  });
};

const deleteSubscriptionService = (data: IRequestDeleteSubscription) => {
  return new Promise((resolve, reject) => {
    try {
      const deleteSubscription = async () => {
        const res = await api.delete('v3/subscribes/me', { data });
        return res;
      };

      resolve(deleteSubscription());
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getSubscriptionService,
  postSubscriptionService,
  deleteSubscriptionService,
};
