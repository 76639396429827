import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import SocialTariffRequestCuiaba from './Cuiaba';
import SocialTariffRequestRio from './Rio';
import { IState } from '~/store/modules/types';

const SocialTariffRequest: FC = () => {
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.operation.name,
  );

  return operation?.toLocaleUpperCase() === 'CUIABA' ? (
    <SocialTariffRequestCuiaba />
  ) : (
    <SocialTariffRequestRio />
  );
};

export default SocialTariffRequest;
