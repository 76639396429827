import { Color } from '~/styles/colors';

export const statusColor = (statusLabel: string, v3?: boolean): string => {
  switch (statusLabel?.toUpperCase()) {
    case 'QUITADA':
      return Color.green;
    case 'PENDENTE':
      return Color.yellow;
    case 'PROCESSANDO':
      return Color.yellow;
    case 'PARCELA':
      return Color.gray;

    default:
      return v3 ? Color.red : Color.redAscendet;
  }
};
