import React from 'react';

const ChartSVG = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03248 29.5647H30.1204V27.4841H3.07277V0.436523H0.992188V28.5244L2.03248 29.5647ZM5.15336 24.3633V7.71857L6.19365 6.67828H10.3548L11.3951 7.71857V24.3633L10.3548 25.4036H6.19365L5.15336 24.3633ZM9.31453 23.323V8.75887H7.23395V23.323H9.31453ZM21.798 3.5574V24.3633L22.8383 25.4036H26.9995L28.0398 24.3633V3.5574L26.9995 2.51711H22.8383L21.798 3.5574ZM25.9592 4.5977V23.323H23.8786V4.5977H25.9592ZM13.4757 24.3633V11.8797L14.516 10.8395H18.6772L19.7175 11.8797V24.3633L18.6772 25.4036H14.516L13.4757 24.3633ZM17.6369 23.323V12.92H15.5563V23.323H17.6369Z"
        fill="white"
      />
    </svg>
  );
};

export default ChartSVG;
