import styled from 'styled-components';
import { Color } from '~/styles';

export const Button = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const TextButton = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: ${Color.gray};
`;
