import React, { FC, useState } from 'react';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Content,
  ContainerStepper,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import StepOne from './StepOne';
import { Stepper } from './Stepper';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Modal from '~/components/Modal';
import ModalTerm from './ModalTerm';

const steps = [
  {
    id: 0,
    label: 'Entrada',
  },
  {
    id: 1,
    label: 'Parcelas',
  },
  {
    id: 2,
    label: 'Cofirmação',
  },
];

const DebtNegotiationSimulation: FC = () => {
  const [step, setStep] = useState<number>(0);
  const [openTerm, setOpenTerm] = useState<boolean>(false);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DEBTNEGOTIATION),
    },
    {
      label: 'Tipo de pagamento',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Simulação',
      active: true,
    },
  ];

  const getStep = () => {
    switch (step) {
      case 0:
        return {
          step: <StepOne setStep={setStep} />,
        };
      case 1:
        return {
          step: <StepTwo setStep={setStep} />,
        };

      default:
        return {
          step: <StepThree setStep={setStep} setOpenTerm={setOpenTerm} />,
        };
    }
  };

  return (
    <Main>
      {openTerm && (
        <Modal open type="info" v3 hidden maxWidth={670}>
          <ModalTerm onClose={() => setOpenTerm(false)} />
        </Modal>
      )}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Content>
          <Title>Simule uma negociação</Title>
          <ContainerStepper>
            <Stepper steps={steps} activeStep={step} />
          </ContainerStepper>
        </Content>
        <Content>{getStep()?.step}</Content>
      </Card>
    </Main>
  );
};

export default DebtNegotiationSimulation;
