import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  Container,
  ContainerDrop,
  ContainerInfo,
  ContainerSelectedFileName,
  ErrorsText,
} from './styles';
import UploadFileSVG from '~/assets/Icons/UploadFile';
import { Color } from '~/styles';
import TrashSVG from '~/assets/Icons/Trash';

interface Props {
  onFileUploaded: (file: File | null) => void;
  infoFileName?: string | '';
  disabled?: boolean;
  name?: string;
  content?: File | null;
  type?: 'default' | 'letter' | string;
  error?: boolean;
  errorText?: string;
}

const DropZone: React.FC<Props> = ({
  onFileUploaded,
  infoFileName,
  disabled,
  name,
  content,
  type,
  error,
  errorText,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  useEffect(() => {
    content && setSelectedFileUrl(content.name);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      setSelectedFileUrl(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const getAcceptType = () => {
    switch (type) {
      case 'letter':
        return '.pdf';
      default:
        return '.pdf';
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/pdf': [getAcceptType()],
    },
    disabled,
  });

  return (
    <Container>
      {selectedFileUrl ? (
        <ContainerDrop error={error}>
          <ContainerSelectedFileName>
            {' '}
            <p>{selectedFileUrl}</p>
            <button
              type="button"
              onClick={() => {
                setSelectedFileUrl('');
                onFileUploaded(null);
              }}
            >
              <TrashSVG width={20} height={24} />
            </button>
          </ContainerSelectedFileName>
        </ContainerDrop>
      ) : (
        <ContainerDrop {...getRootProps()} error={error}>
          <input {...getInputProps()} name={name} />
          <ContainerInfo error={error}>
            <p>{infoFileName}</p>
            <div>
              <UploadFileSVG color={Color.gray} newIcon />
            </div>
          </ContainerInfo>
        </ContainerDrop>
      )}
      {errorText && <ErrorsText>{errorText}</ErrorsText>}
    </Container>
  );
};

export default DropZone;
