/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { CardForm } from '~/components/CardForm';
import { Color, Breakpoints } from '~/styles';
import { COLORS } from '~/utils/constants';

export const CustomCardForm = styled(CardForm)`
  background: ${COLORS.white};
  .logo-wrapper {
    display: none;
  }
`;

export const Main = styled.main`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 22px 83px 22px;
`;

export const ContainerCardInvoice = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardInvoice = styled.div`
  display: flex;
  position: relative;
  width: 99%;
  min-height: 91px;
  background: ${Color.white};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 83px;
  }
`;

interface BorderProps {
  color: string;
}

export const Border = styled.div<BorderProps>`
  width: 5px;
  height: 100%;
  background: ${({ color }) => color};
  border-radius: 10px;
  position: absolute;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 7px;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0 4px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RowInfo = styled.div`
  display: flex;
  justify-items: center;
  font-weight: 300;
  font-size: 14px;
  color: #000;
`;

export const ContainerSvg = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0;
  }
`;
