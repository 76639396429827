import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import { RequestNetworkAcceptance } from './RequestNetworkAcceptance';
import { ConstructionCompletionInspection } from './ConstructionCompletionInspection';

export const LicenseTermination: FC = () => {
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const typesMap: Map<string | undefined, React.JSX.Element> = new Map([
    ['A', <ConstructionCompletionInspection />],
    ['B', <RequestNetworkAcceptance />],
  ]);

  return typesMap.get(caseOC?.step.substep);
};
