import React from 'react';

function WalletVisaSVG() {
  return (
    <svg
      width="62"
      height="48"
      viewBox="0 0 62 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.602 21.3541C50.602 21.3541 51.4196 25.3403 51.6037 26.1753H48.0097L49.7321 21.5142C49.7108 21.547 50.0867 20.5384 50.3017 19.9174L50.602 21.3541ZM62 5.14327V42.8567C61.9995 44.2207 61.4549 45.5286 60.486 46.493C59.5171 47.4574 58.2031 47.9995 56.8328 48H5.16715C3.79689 47.9995 2.48291 47.4574 1.51399 46.493C0.545073 45.5286 0.000513493 44.2207 0 42.8567V5.14327C0.000513493 3.77935 0.545073 2.47143 1.51399 1.50699C2.48291 0.542554 3.79689 0.00051112 5.16715 0H56.8328C58.2031 0.00051112 59.5171 0.542554 60.486 1.50699C61.4549 2.47143 61.9995 3.77935 62 5.14327ZM16.4159 32.0572L23.2183 15.4298H18.644L14.4145 26.7867L13.9515 24.484L12.4442 16.8337C12.1962 15.7731 11.4328 15.4722 10.4854 15.4298H3.52226L3.4467 15.7615C5.04136 16.1509 6.571 16.768 7.98806 17.5936L11.8416 32.0572H16.4159ZM26.5778 32.0784L29.2902 15.4298H24.9639L22.2631 32.0784H26.5778ZM41.6356 26.6362C41.6569 24.7405 40.4944 23.2942 38.0087 22.1043C36.4917 21.3425 35.5656 20.8296 35.5656 20.0466C35.5869 19.3389 36.3522 18.6099 38.0513 18.6099C39.158 18.5788 40.2576 18.795 41.2694 19.2424L41.6569 19.4237L42.2497 15.8232C41.0129 15.3519 39.6992 15.112 38.3749 15.1155C34.1009 15.1155 31.0978 17.3872 31.0765 20.6213C31.0436 23.0107 33.229 24.3394 34.8662 25.1417C36.5343 25.9555 37.1058 26.4916 37.1058 27.209C37.0845 28.3237 35.7496 28.8366 34.5116 28.8366C32.7892 28.8366 31.8631 28.5686 30.4527 27.9476L29.8831 27.6796L29.2805 31.4189C30.2919 31.8798 32.1654 32.2867 34.1028 32.3079C38.6442 32.3176 41.6046 30.0786 41.6375 26.6305L41.6356 26.6362ZM56.8328 32.0784L53.3455 15.4298H49.9976C48.9649 15.4298 48.1783 15.7307 47.7366 16.8125L41.3101 32.0804H45.8514C45.8514 32.0804 46.5935 30.0227 46.7562 29.5849H52.3109C52.4407 30.175 52.8282 32.0804 52.8282 32.0804L56.8328 32.0784Z"
        fill="white"
      />
    </svg>
  );
}
export default WalletVisaSVG;
