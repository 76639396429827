import styled from 'styled-components';
import { Color } from '~/styles';

export const Content = styled.input`
  display: flex;
  height: 36px;
  padding: 0px 24px 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${Color.white};
  border: 1px solid ${Color.gray};
  color: ${Color.gray};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  min-width: 170px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 170px;
`;

export const IconSearch = styled.div`
  position: absolute;
  right: 8px;
`;
