import { Dispatch } from 'redux';
import {
  IProjectAndLicenseInformationFCP,
  IRequestCreate,
  IRequestCreateFileCodesPropertyRegistration,
  IRequestGetDownloadFilePropertyRegistration,
  IRequestGetFileCodesPropertyRegistration,
  IRequestGetValidate,
  IRequestPatchProtocolPropertyRegistration,
  IRequestPostUploadFile,
  IRequestSetStep,
  IResponseGetDownloadFilePropertyRegistration,
  IResponseGetFileCodesPropertyRegistration,
  IResponseGetItem,
  IStep,
  RepositoriesTypesPropertyRegistration,
} from './types';
import {
  attachFilesPropertyRegistrationService,
  createFileCodePropertyRegistrationService,
  createPropertyRegistrationService,
  fileCodesPropertyRegistrationService,
  getDataDownloadFilePropertyRegistrationService,
  getItemPropertyRegistrationService,
  getStepPropertyRegistrationService,
  getValidatePropertyRegistrationService,
  listPropertyRegistrationService,
  patchPropertyRegistrationService,
  patchProtocolPropertyRegistrationService,
  setStepPropertyRegistrationService,
  uploadFilePropertyRegistrationService,
} from '~/services/propertyRegistration';
import onShareFile from '~/utils/onShareFile';
import { stepStatusEnum } from '~/enums/customerObligationEnum';

const getListPropertyRegistrationRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_REQUEST,
    });

    const response = await listPropertyRegistrationService();

    return dispatch({
      type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          message: error?.response?.data.type,
          state: true,
        },
      },
    });
  }
};

const getValidatePropertyRegistrationRequest =
  (data: IRequestGetValidate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_REQUEST,
      });

      const response = await getValidatePropertyRegistrationService(data);

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const clearValidatePropertyRegistrationRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_CLEAR,
    });
  };

const clearCreatePropertyRegistrationRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_CLEAR,
  });
};

const createPropertyRegistrationRequest =
  (data: IRequestCreate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_REQUEST,
      });
      console.log('createPropertyRegistrationRequest');

      const response = (await createPropertyRegistrationService(data)) as {
        id: string;
      };

      const responseStep = await getItemPropertyRegistrationService({
        case: response?.id,
      });

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_SUCCESS,
        payload: {
          data: response,
          dataItem: responseStep,
        },
      });
    } catch (error: any) {
      console.log('error', error);
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const clearPropertyRegistrationRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_METHOD_CLEAR,
  });
};

const patchInformationPropertyRegistrationRequest =
  (caseId: string, data: IProjectAndLicenseInformationFCP, file: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_REQUEST,
      });

      await patchPropertyRegistrationService(caseId, data);
      if (file) await attachFilesPropertyRegistrationService(caseId, '2');

      const stepResponse = await getStepPropertyRegistrationService({
        case: caseId,
      });

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_SUCCESS,
        payload: stepResponse,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchAttachmentPropertyRegistrationRequest =
  ({ caseId, step }: { caseId: string; step: string }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_REQUEST,
      });

      await attachFilesPropertyRegistrationService(caseId, step);

      const responseStep = await getStepPropertyRegistrationService({
        case: caseId,
      });

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_SUCCESS,
        payload: responseStep,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const postUploadFilePropertyRegistrationRequest =
  (data: IRequestPostUploadFile) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_REQUEST,
      });

      const response = await uploadFilePropertyRegistrationService(data);

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_SUCCESS,
        payload: {
          attachments: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getItemPropertyRegistrations =
  (data: IRequestGetValidate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_REQUEST,
      });

      const response = (await getItemPropertyRegistrationService(
        data,
      )) as IResponseGetItem;

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getFileCodesPropertyRegistrationRequest =
  (data: IRequestGetFileCodesPropertyRegistration) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_REQUEST,
      });

      const response = (await fileCodesPropertyRegistrationService(
        data,
      )) as IResponseGetFileCodesPropertyRegistration;

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS,
        payload: {
          documentType: data.documentType,
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getDataDownloadFilePropertyRegistrationRequest =
  (data: IRequestGetDownloadFilePropertyRegistration) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_REQUEST,
      });

      const response = (await getDataDownloadFilePropertyRegistrationService(
        data,
      )) as IResponseGetDownloadFilePropertyRegistration;

      onShareFile({ buffer: response.file, fileName: 'documento-fcp' });

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const postCreateFileCodesPropertyRegistrationRequest =
  (data: IRequestCreateFileCodesPropertyRegistration) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_REQUEST,
      });

      const response = (await createFileCodePropertyRegistrationService(
        data,
      )) as IResponseGetFileCodesPropertyRegistration;

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS,
        payload: {
          documentType: data.documentType,
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };
const patchProtocolPropertyRegistratioRequest =
  (data: IRequestPatchProtocolPropertyRegistration) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_REQUEST,
      });

      await patchProtocolPropertyRegistrationService(data);

      const stepResponse = await getStepPropertyRegistrationService({
        case: data.caseId,
      });

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_SUCCESS,
        payload: {
          step: stepResponse,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchStepLocalPropertyRegistratioRequest =
  (step: IStep) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_LOCAL_METHOD_REQUEST,
      payload: step,
    });
  };

const patchStepPropertyRegistratioRequest =
  (data: IRequestSetStep) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_REQUEST,
      });

      const stepResponse = (await setStepPropertyRegistrationService(data)) as {
        step: 1 | 2 | 3 | 4 | 5 | 6;
      };

      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_SUCCESS,
        payload: {
          name: '',
          number: stepResponse.step,
          substep: '',
          status: stepStatusEnum.FORM,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

export {
  getListPropertyRegistrationRequest,
  createPropertyRegistrationRequest,
  getValidatePropertyRegistrationRequest,
  clearValidatePropertyRegistrationRequest,
  clearCreatePropertyRegistrationRequest,
  clearPropertyRegistrationRequest,
  patchInformationPropertyRegistrationRequest,
  patchAttachmentPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
  getItemPropertyRegistrations,
  getFileCodesPropertyRegistrationRequest,
  getDataDownloadFilePropertyRegistrationRequest,
  postCreateFileCodesPropertyRegistrationRequest,
  patchStepLocalPropertyRegistratioRequest,
  patchProtocolPropertyRegistratioRequest,
  patchStepPropertyRegistratioRequest,
};
