import React from 'react';

interface IProp {
  width?: number;
  height?: number;
}

const BankSVG = ({ width = 29, height = 31 }: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86842 13.95H3.81579V24.8H6.86842V13.95ZM16.0263 13.95H12.9737V24.8H16.0263V13.95ZM29 27.9H0V31H29V27.9ZM25.1842 13.95H22.1316V24.8H25.1842V13.95ZM14.5 3.503L22.4521 7.75H6.54789L14.5 3.503ZM14.5 0L0 7.75V10.85H29V7.75L14.5 0Z"
        fill="#999999"
      />
    </svg>
  );
};

export default BankSVG;
