import styled from 'styled-components';
import { Color } from '~/styles';

interface Props {
  open: boolean;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const Backdrop = styled.div<Props>`
  margin-inline-start: auto;
  height: min-content;
  width: min-content;
  background: ${Color.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.25));
  border-radius: 16px;
  box-sizing: border-box;
  position: absolute;
  z-index: 900;
  top: ${({ top }) => top}px;
  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;
  right: ${({ right }) => right}px;
  display: ${({ open }) => (open ? 'flex' : 'none')};

  .arrow {
    width: 0;
    height: 0;
    border: 17px solid transparent;
    border-top: 0;
    border-bottom: 20px solid ${Color.white};
    position: absolute;
    top: -17px;
    right: 20px;
  }

  div {
    margin-inline-start: auto;
    z-index: 901;
    position: relative;
    right: 0;
  }
`;
