import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20000;
`;

export const Label = styled.label`
  color: white;
  font-size: 16px;
  margin-top: 10px;
`;
