import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface Props {
  isActive?: boolean;
  stepPosition?: number;
}

export const Wrapper = styled.div`
  padding: 0px 64px 79px 64px;
  margin: 0px auto;
  max-width: 640px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 48px;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    padding: 24px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StepNumber = styled.div<Props>`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  background: ${(p) =>
    p.isActive && p.stepPosition === 2
      ? Color.green
      : p.isActive && p.stepPosition !== 2
      ? Color.blueIntense
      : '#C4C4C4'};
  border-radius: 36px;
`;

export const StepLabel = styled.div<Props>`
  width: 180px;
  position: absolute;
  top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(p) => (p.isActive ? Color.green : '#C4C4C4')};
  font-size: 14px;
  font-weight: ${(p) => (p.isActive ? 600 : 400)};
  line-height: 17px;
  line-height: 1.3;
`;

export const StepDivider = styled.div<Props>`
  margin-top: 16px;
  width: 100%;
  height: 3px;
  background: ${(p) => (p.isActive ? Color.blueIntense : Color.gray)};
`;
