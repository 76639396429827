import React from 'react';
import { Color } from '~/styles';

const FreeDaysIcon = () => {
  return (
    <svg
      width="119"
      height="27"
      viewBox="0 0 119 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83984 0.617188C2.6307 0.617188 0.839844 2.40805 0.839844 4.61719V16.6172C0.839844 18.8263 2.6307 20.6172 4.83984 20.6172H85.8582H114.997H119V0.617188H114.997H85.8582H4.83984Z"
        fill="#E6735F"
      />
      <path d="M113.742 20.6172H119L113.742 26.6172V20.6172Z" fill="#872D2D" />
    </svg>
  );
};

interface IPropsConnectionIcon {
  color?: string;
}

const ConnectionIcon = ({ color = Color.white }: IPropsConnectionIcon) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.51823 10.275C4.22829 9.975 4.09137 9.61667 4.10748 9.2C4.12358 8.78333 4.28466 8.45833 4.59071 8.225C5.47664 7.525 6.4634 6.97933 7.55099 6.588C8.63859 6.19667 9.78611 6.00067 10.9935 6C12.2177 6 13.3736 6.2 14.4612 6.6C15.5488 7 16.5353 7.55833 17.4205 8.275C17.7266 8.50833 17.8838 8.82933 17.8922 9.238C17.9005 9.64667 17.7594 10.0007 17.4689 10.3C17.195 10.5833 16.8606 10.7293 16.4657 10.738C16.0707 10.7467 15.7044 10.634 15.3668 10.4C14.7386 9.95 14.0582 9.604 13.3256 9.362C12.593 9.12 11.8157 8.99933 10.9935 9C10.172 9 9.39469 9.125 8.66146 9.375C7.92824 9.625 7.2559 9.96667 6.64445 10.4C6.29008 10.65 5.91541 10.7627 5.52045 10.738C5.12549 10.7133 4.79141 10.559 4.51823 10.275ZM0.410748 6.05C0.136916 5.76667 0 5.42067 0 5.012C0 4.60333 0.14497 4.266 0.434909 4C1.8685 2.75 3.47927 1.77067 5.26724 1.062C7.0552 0.353334 8.96397 -0.000665727 10.9935 9.3985e-07C13.0231 9.3985e-07 14.9319 0.354334 16.7198 1.063C18.5078 1.77167 20.1186 2.75067 21.5522 4C21.8421 4.26667 21.9913 4.6 21.9996 5C22.008 5.4 21.8669 5.75 21.5763 6.05C21.3025 6.33333 20.9684 6.48333 20.5741 6.5C20.1798 6.51667 19.8212 6.39167 19.4984 6.125C18.3226 5.14167 17.0137 4.375 15.5717 3.825C14.1297 3.275 12.6037 3 10.9935 3C9.38277 3 7.85672 3.275 6.4154 3.825C4.97407 4.375 3.66516 5.14167 2.48865 6.125C2.16649 6.39167 1.80793 6.51667 1.41297 6.5C1.01801 6.48333 0.683935 6.33333 0.410748 6.05ZM10.9935 16C10.4459 16 9.98681 15.8083 9.61633 15.425C9.24585 15.0417 9.06061 14.5667 9.06061 14C9.06061 13.4333 9.24585 12.9583 9.61633 12.575C9.98681 12.1917 10.4459 12 10.9935 12C11.5412 12 12.0003 12.1917 12.3708 12.575C12.7412 12.9583 12.9265 13.4333 12.9265 14C12.9265 14.5667 12.7412 15.0417 12.3708 15.425C12.0003 15.8083 11.5412 16 10.9935 16Z"
        fill={color}
      />
    </svg>
  );
};

export { FreeDaysIcon, ConnectionIcon };
