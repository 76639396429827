import React, { FC } from 'react';

import { Input } from '~/components/Input';
import { Container, ContainerDescriptionText, ContainerInputs } from './styles';
import { DescriptionText } from '../../styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';

const FormDataEnrollment: FC = () => {
  const code = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.code,
  );
  const data = useSelector(
    (item: IState) => item.orderServices.dataAddressLackWater,
  );

  return (
    <Container>
      <ContainerInputs>
        <Input value={code} label="Matrícula" name="enrollment" disabled />
        <Input
          value={formatter.cepMask(data?.zip_code || '')}
          label="CEP"
          name="zip_code"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        <Input value={data?.state} label="Estado" name="state" disabled />
        <Input value={data?.city} label="Cidade" name="city" disabled />
      </ContainerInputs>
      <ContainerInputs>
        <Input value={data?.district} label="Bairro" name="district" disabled />
        <Input value={data?.address} label="Rua" name="address" disabled />
      </ContainerInputs>
      <ContainerInputs>
        <Input value={data?.number} label="Número" name="number" disabled />
        <Input
          value={data?.complement}
          label="Complemento"
          name="complement"
          disabled
        />
      </ContainerInputs>
      <ContainerDescriptionText>
        <DescriptionText>
          Para alterar dados da matrícula, fale conosco
        </DescriptionText>
      </ContainerDescriptionText>
    </Container>
  );
};

export default FormDataEnrollment;
