import React from 'react';

function HistorySubtitleEllipseIconSVG() {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="3.43702" cy="4.01479" rx="3.13234" ry="3.33754" fill="#AAD7E1" />
    </svg>
  );
}
export default HistorySubtitleEllipseIconSVG;
