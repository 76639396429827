import styled from 'styled-components';
import { Color } from '~/styles';

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionTitle = styled.h1`
  color: ${Color.gray};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 80px;
`;

export const BoxInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  input {
    height: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  select {
    padding: 6px 28px 6px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.gray};
  }
`;

export const Row = styled.div<{ divider?: string }>`
  display: flex;
  width: 100%;
  margin 12px 0px;

  ${({ divider }) =>
    divider &&
    `div: first-child {
    width: ${divider}%;
    div {
      width: initial;
    }
  }`}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1010px;
  width: calc(100% + 160px);
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${Color.blueIntense};
  position: absolute;
  text-align: center;
  background: white;
  left: 105px;
  padding: 0 30px 0 30px;
`;

export const SeparatorTitle = styled.hr`
  width: 100%;
  border-top: 1px solid ${Color.grayLightSeconde};
`;

export const Label = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.gray};
  font-style: italic;
  margin: auto;
  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }
`;

export const Subtitle = styled.div`
  color: ${Color.blueIntense};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
`;

export const TextFooter = styled.p`
  color: ${Color.gray};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  text-align: center;
`;

export const CheckboxContaner = styled.div`
  color: ${Color.blueSeconde};
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  cursor: pointer;
  appearance: none;
  margin-right: 8px;
  border-radius: 4px;
  height: 15px;
  width: 15px;
  border: 1px solid ${Color.blueSeconde};

  &:checked {
    background: ${Color.blueSeconde};
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 50%;
  max-width: 335px;
`;

export const ButtonInfo = styled.button<{ isActive: boolean; isRevision?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isActive }) => (isActive ? '0' : '40px')};
  padding: 0 32px 0 32px;
  background: ${({ isActive, isRevision }) =>
    isActive ? (isRevision ? Color.grayDark : Color.blueSeconde) : Color.white};
  color: ${({ isActive, isRevision }) =>
    isActive ? Color.white : isRevision ? Color.grayDark : Color.blueSeconde};
  border: 2px solid
    ${({ isRevision }) => (isRevision ? Color.grayDark : Color.blueSeconde)};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 32px;
    path {
      fill: ${({ isActive, isRevision }) =>
        isActive ? Color.white : isRevision ? Color.grayDark : Color.blueSeconde};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }

  :last-of-type {
    margin: 0;
  }
`;

export const ContentGenerateCode = styled.div`
  min-height: calc(202px - 52px);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 24px 32px;
  background: #f2f2f2;
  border: 2px solid Color.blueSeconde;
  border-radius: 8px;
`;

export const ContainerGenerateCode = styled.div`
  width: 100%;
`;

export const BoxGeneratedCode = styled.div`
  min-height: calc(70px - 24px);
  display: flex;
  margin-top: 24px;
  padding: 12px;
  border: 1px dashed #999999;
  border-radius: 8px;

  button {
    height: 48px;
    cursor: pointer;
    padding: 14px 16px;
    background: ${Color.green};
    border: 2px solid ${Color.green};
    border-radius: 8px;
    color: ${Color.white};
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 40px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: ${Color.gray};
    }

    p:first-of-type {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #000;
    }

    .code {
      color: #000000;
    }
  }
`;
