import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 270px;
  height: 282px;

  background: #ffffff;
  border-top: 24px solid #004b55;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px 24px 0px 0px;

  margin-bottom: 24px;
`;

export const CardLinks = styled.div`
  display: none;
  width: 270px;
  height: 483px;
  border-top: 24px solid #004b55;
  border-radius: 24px 24px 16px 16px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    display: inherit;
  }
`;

export const Items = styled.button`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  background-color: white;
  margin-bottom: 8px;
  text-decoration-line: underline;
  color: #004b55;
  text-align: left;
  margin-left: 5px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  background: #f2f2f2;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #004b55;
`;
