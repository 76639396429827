import React, { FC } from 'react';

import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import { BoxPropertyDetails } from '../styles';
import formatter from '~/utils/formatter';

interface IProps {
  dataForm: any;
  setDataForm: any;
  dataFormErrors: any;
  setDataFormErrors: any;
}

interface ISelectValues {
  value: string;
  label: string;
}

const FormImmobile: FC<IProps> = ({
  dataForm,
  setDataForm,
  dataFormErrors,
  setDataFormErrors,
}) => {
  const valuesVinculoImovel: ISelectValues[] = [
    { value: '', label: 'Selecione' },
    { value: 'Locatário', label: 'Locatário' },
    { value: 'Proprietário', label: 'Proprietário' },
    { value: 'Responsável Financeiro', label: 'Responsável Financeiro' },
  ];
  const valuesTipoReservatorio: ISelectValues[] = [
    { value: '', label: 'Selecione' },
    { value: 'Caixa D’Água', label: 'Caixa D’Água' },
    { value: 'Cisterna', label: 'Cisterna' },
    { value: 'Não Possui', label: 'Não Possui' },
  ];

  const validateCep = () => {
    if (!dataForm.cep) return setDataFormErrors({ ...dataFormErrors, cep: '' });

    if (dataForm.cep.length < 9) {
      return setDataFormErrors({ ...dataFormErrors, cep: 'CEP inválido' });
    }
    return setDataFormErrors({ ...dataFormErrors, cep: '' });
  };

  const validateEmpty = (key: string, value: string) => {
    if (!value)
      return setDataFormErrors({ ...dataFormErrors, [key]: 'Campo obrigatório' });

    return setDataFormErrors({ ...dataFormErrors, [key]: '' });
  };

  return (
    <BoxPropertyDetails>
      <Margin height={24} />
      <div className="container-inputs">
        <Input
          value={dataForm.enrollment}
          label="Matrícula*"
          name="enrollment"
          disabled
        />
        <InputSelect
          label="Vínculo com o imóvel*"
          name="vinculoImovel"
          values={valuesVinculoImovel}
          onChange={(ev) => {
            setDataForm({ ...dataForm, vinculoImovel: ev.target.value });
            if (ev.target.value)
              setDataFormErrors({ ...dataFormErrors, vinculoImovel: '' });
          }}
          error={!!dataFormErrors?.vinculoImovel}
          errorText={dataFormErrors?.vinculoImovel}
          errorPosition="bottom"
        />
      </div>
      <div className="container-inputs">
        <InputSelect
          label="Tipo de reservatório*"
          name="tipoReservatorio"
          values={valuesTipoReservatorio}
          onChange={(ev) => {
            setDataForm({ ...dataForm, tipoReservatorio: ev.target.value });
            if (ev.target.value)
              setDataFormErrors({ ...dataFormErrors, tipoReservatorio: '' });
          }}
          error={!!dataFormErrors?.tipoReservatorio}
          errorText={dataFormErrors?.tipoReservatorio}
          errorPosition="bottom"
        />
        <Input
          value={dataForm.cep}
          onChange={(ev) =>
            setDataForm({ ...dataForm, cep: formatter.cepMask(ev.target.value) })
          }
          label="CEP*"
          name="cep"
          onBlur={validateCep}
          error={!!dataFormErrors?.cep}
          errorText={dataFormErrors?.cep}
          errorPosition="bottom"
        />
      </div>
      <div className="container-inputs">
        <Input
          value={dataForm.estado}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              estado: ev.target.value,
            })
          }
          label="Estado*"
          name="estado"
          onBlur={(ev: any) => validateEmpty('estado', ev.target.value)}
          error={!!dataFormErrors?.estado}
          errorText={dataFormErrors?.estado}
          errorPosition="bottom"
        />
        <Input
          value={dataForm.cidade}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              cidade: ev.target.value,
            })
          }
          label="Cidade*"
          name="cidade"
          onBlur={(ev: any) => validateEmpty('cidade', ev.target.value)}
          error={!!dataFormErrors?.cidade}
          errorText={dataFormErrors?.cidade}
          errorPosition="bottom"
        />
      </div>
      <div className="container-inputs">
        <Input
          value={dataForm.bairro}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              bairro: ev.target.value,
            })
          }
          label="Bairro*"
          name="bairro"
          onBlur={(ev: any) => validateEmpty('bairro', ev.target.value)}
          error={!!dataFormErrors?.bairro}
          errorText={dataFormErrors?.bairro}
          errorPosition="bottom"
        />
        <Input
          value={dataForm.rua}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              rua: ev.target.value,
            })
          }
          label="Rua*"
          name="rua"
          onBlur={(ev: any) => validateEmpty('rua', ev.target.value)}
          error={!!dataFormErrors?.rua}
          errorText={dataFormErrors?.rua}
          errorPosition="bottom"
        />
      </div>
      <div className="container-inputs">
        <Input
          value={dataForm.numero}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              numero: ev.target.value,
            })
          }
          label="Número*"
          name="numero"
          onBlur={(ev: any) => validateEmpty('numero', ev.target.value)}
          error={!!dataFormErrors?.numero}
          errorText={dataFormErrors?.numero}
          errorPosition="bottom"
        />
        <Input
          value={dataForm.complemento}
          onChange={(ev) =>
            setDataForm({ ...dataForm, complemento: ev.target.value })
          }
          label="Complemento"
          name="complemento"
          maxLength={100}
        />
      </div>
      <div className="container-inputs">
        <Input
          value={dataForm.numeroResidenciaLado}
          onChange={(ev) =>
            setDataForm({
              ...dataForm,
              numeroResidenciaLado: ev.target.value,
            })
          }
          label="Número ao lado*"
          name="numeroResidenciaLado"
          onBlur={(ev: any) =>
            validateEmpty('numeroResidenciaLado', ev.target.value)
          }
          error={!!dataFormErrors?.numeroResidenciaLado}
          errorText={dataFormErrors?.numeroResidenciaLado}
          errorPosition="bottom"
        />
        <Input
          value={dataForm.pontoReferencia}
          onChange={(ev) =>
            setDataForm({ ...dataForm, pontoReferencia: ev.target.value })
          }
          label="Ponto de referência"
          name="pontoReferencia"
          maxLength={100}
        />
      </div>
    </BoxPropertyDetails>
  );
};

export default FormImmobile;
