/* eslint-disable react/jsx-no-useless-fragment */
import React, { FormHTMLAttributes } from 'react';
import LogoGreen from '~/assets/Images/LogoGreen.png';
import { CardFormStyled } from './styles';

interface Props extends FormHTMLAttributes<any> {
  children: React.ReactNode;
  label?: string;
  logoHidden?: boolean;
}

export const CardForm: React.FC<Props> = ({
  children,
  label,
  logoHidden,
  ...props
}) => {
  return (
    <CardFormStyled {...props}>
      {label && <h1 className="title-form">{label}</h1>}
      <div className="logo-wrapper">
        {!logoHidden && !label && <img src={LogoGreen} alt="digiigua" />}
      </div>
      {children}
    </CardFormStyled>
  );
};
