import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '~/utils/constants';

export const MyProtocol = styled.div`
  padding: 56px 24px;

  @media (max-width: ${BREAKPOINTS.mobilePortrait}) {
    padding-inline: 0px;
  }
`;

export const Container = styled.div`
  padding: 60px 80px;

  align-self: center;

  margin: 0px auto;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 32px;
`;

export const Wrapper = styled.div`
  padding: 50px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.5;
  font-weight: 300;
`;

export const Message = styled.div`
  padding: 40px;
  background: ${COLORS.gray200};
  border-radius: 4px;
  font-weight: inherit;
  text-align: center;
`;

export const Detail = styled.div`
  font-weight: inherit;
  text-align: center;

  > * {
    font-weight: inherit;
  }

  span {
    font-size: calc(18 / 16 * 1rem);
    font-weight: 500;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const DateBox = styled.div`
  text-align: center;
  p {
    font-size: 14px;
    margin-bottom: 4px;
  }
  span {
    font-size: calc(18 / 16 * 1rem);
    font-weight: 500;
  }
`;
