import React, { FC } from 'react';
import { ArrowIcon } from '../Icons';
import { Button, TextButton } from './styles';

interface IContinueButtonProps {
  label: string;
  backgroundColor: string;
  onClick: () => void;
}

const ContinueButton: FC<IContinueButtonProps> = ({
  label,
  backgroundColor,
  onClick,
}) => {
  return (
    <Button background={backgroundColor} type="button" onClick={onClick}>
      <TextButton>{label}</TextButton>
      <div>
        <ArrowIcon />
      </div>
    </Button>
  );
};

export default ContinueButton;
