import React, { FC } from 'react';
import ArrowDownButtonSVG from '~/assets/Icons/ArrowDownButton';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';
import StatusInfoIconSVG from '~/assets/Icons/StatusInfo';
import StatusSuccessIconSVG from '~/assets/Icons/StatusSuccess';

import { Main } from './styles';
import StatusWaitIconSVG from '~/assets/Icons/StatusWait';

interface IProps {
  status: 'info' | 'wait' | 'warning' | 'success';
  children: React.ReactNode;
  onClick?: () => void;
  labelButton?: string;
  maxWidthButton?: number;
  title?: string;
}

const typeStatus = {
  info: {
    icon: <StatusInfoIconSVG />,
    title: 'Lembre-se',
  },
  wait: {
    icon: <StatusWaitIconSVG />,
    title: 'Aguarde',
  },
  warning: {
    icon: <StatusWarningIconSVG />,
    title: 'Atenção',
  },
  success: {
    icon: <StatusSuccessIconSVG />,
    title: 'Documentação aprovada',
  },
};

const StatusInfo: FC<IProps> = ({
  status,
  children,
  onClick,
  labelButton,
  maxWidthButton,
  title = typeStatus[status].title,
}) => {
  return (
    <Main status={status} maxWidthButton={maxWidthButton}>
      <div>{typeStatus[status].icon}</div>
      <div>
        <p>{title}</p>
        <p>{children}</p>
      </div>
      {onClick && (
        <button type="button" className="button-download" onClick={onClick}>
          <div>
            <ArrowDownButtonSVG />
          </div>
          <p>{labelButton}</p>
        </button>
      )}
    </Main>
  );
};

export default StatusInfo;
