import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsStatusInvoice {
  color: string;
}

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 24px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 24px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 24px 0 203px 0;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100%);
    padding: 24px 0 241px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  margin: 0 0 24px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  padding: 44px 16px 64px 16px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px;

  h1 {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 24px;
  }

  h3 {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 24px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #ccc;
    border-radius: 4px;
  }

  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .container input:checked ~ .checkmark {
    background: #37b4c8;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 2px;
    height: 7px;
    border-style: solid;
    border-color: white;
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(700px - 80px);
    padding: 25px 40px 64px 40px;

    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(1010px - 80px);
    max-width: calc(1010px - 80px);
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #004b55;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  div {
    flex-basis: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      padding-bottom: 20px;
    }
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
    flex-basis: 40%;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      flex-basis: 10%;
    }

    p {
      flex-basis: 30%;
    }
  }
`;

export const ContainerInvoice = styled.div<IPropsStatusInvoice>`
  width: 100%;
  height: 64px;
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  div {
    flex-basis: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 8px;
      background: ${({ color }) => color};
      border-radius: 8px 0 0 8px;
    }

    .container {
      padding-bottom: 20px;
    }
  }

  .status {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color};

    span {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
    flex-basis: 40%;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      flex-basis: 10%;
    }

    p {
      flex-basis: 30%;
    }
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 35px);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  h2 {
    color: ${Color.blueIntense};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    background: ${Color.white};
    padding: 10px 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h2 {
      position: absolute;
      left: 20px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 80px);

    h2 {
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  margin-top: 24px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    :first-of-type {
      order: 2;
      background: ${Color.white};
      color: ${Color.blueSeconde};
      border: 2px solid ${Color.blueSeconde};
    }

    :last-of-type {
      order: 1;
      svg {
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 0;

    button {
      max-width: 252px;

      :first-of-type {
        order: 1;
      }

      :last-of-type {
        order: 2;
      }
    }
  }
`;

export const ContainerAmount = styled.div`
  width: calc(100% + 32px);
  height: 24px;
  background: #f2f2f2;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    width: auto;
    text-align: center;
    color: ${Color.blueIntense};
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0 40px;

    p {
      text-align: right;
      margin-right: 50px;
    }
  }
`;
