import React, { FC, InputHTMLAttributes } from 'react';
import * as S from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  children: string;
  alignTop?: boolean;
}

const Checkbox: FC<IProps> = ({ children, title, alignTop, ...props }) => {
  const { checked } = props;

  return (
    <S.Checkbox checked={checked} alignTop={alignTop} {...props}>
      <S.CheckboxHidden checked={checked} onChange={() => null} />
      <S.CheckboxStyled checked={checked} />
      <S.CheckboxLabel checked={checked}>
        {title ? <span>{title}</span> : null}
        {children}
      </S.CheckboxLabel>
    </S.Checkbox>
  );
};

export { Checkbox };
