import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { MyContainer } from './stylex';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  size?: 'large' | 'medium' | 'small' | 'newLarge';
}

const Container: FC<IProps> = ({ children, size, ...props }) => {
  return (
    <MyContainer size={size} {...props}>
      {children}
    </MyContainer>
  );
};

export { Container };
