import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
  fillOpacity?: number;
}

function ArrowRightSVG({
  color = Color.grayLightSeconde,
  width = 10,
  height = 16,
  fillOpacity = 1,
}: IProp) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.382399 0.351696C0.137549 0.577268 0 0.883168 0 1.20213C0 1.52109 0.137549 1.82699 0.382399 2.05256L6.84743 8.00677L0.382399 13.961C0.144488 14.1879 0.0128435 14.4917 0.0158193 14.8071C0.018795 15.1225 0.156153 15.4242 0.398309 15.6472C0.640464 15.8702 0.968042 15.9967 1.31049 15.9995C1.65294 16.0022 1.98285 15.881 2.22918 15.6619L9.6176 8.8572C9.86245 8.63163 10 8.32573 10 8.00677C10 7.68782 9.86245 7.38192 9.6176 7.15634L2.22918 0.351696C1.98425 0.126193 1.65211 -0.000488281 1.30579 -0.000488281C0.959466 -0.000488281 0.627323 0.126193 0.382399 0.351696Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}
export default ArrowRightSVG;
