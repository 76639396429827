import { Reducer } from 'redux';
import { IRepositoriesStatePlans, RepositoriesTypesPlans } from './types';

const INITIAL_STATE: IRepositoriesStatePlans = {
  data: null,
  error: null,
  loading: false,
};

const reducer: Reducer<IRepositoriesStatePlans, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesPlans.PLANS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesPlans.PLANS_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
