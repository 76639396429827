import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 48px);
  padding: 0 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0;
  }
`;

export const ContainerCheckBox = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  label {
    :last-of-type {
      color: ${Color.blueIntense};
    }
  }
`;

export const DescriptionTypeText = styled.p`
  margin-left: 35px;
  display: flex;
  align-items: center;
  height: 32px;

  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${Color.gray};
`;

export const ContainerAlert = styled.div`
  max-width: 700px;
  margin: 16px auto;
  div {
    padding: 24px;
    p + p {
      margin-top: 4px;
    }
    div {
      padding: initial;
    }
  }
`;
