import React, { FC } from 'react';
import { ContentRow, Text } from '../styles';
import WarningBox from '~/components/WarningBox';

const InstallmentDetails: FC = () => {
  return (
    <>
      <WarningBox label="A negociação só será efetivada após o pagamento da entrada." />
      <ContentRow />
      <ContentRow isWhite>
        <Text>Tipo de negociação:</Text>
        <Text>Parcelamento</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor da última parcela:</Text>
        <Text>R$ 50,87</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Número total de parcelas:</Text>
        <Text>2</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor total com desconto:</Text>
        <Text>R$ 254,87</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data da negociação:</Text>
        <Text>03/02/2024</Text>
      </ContentRow>
      <ContentRow>
        <Text>Matrícula de origem:</Text>
        <Text>12345-6</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data do vencimento:</Text>
        <Text>03/02/2025</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor da parcela:</Text>
        <Text>R$ 0,</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Valor de entrada:</Text>
        <Text>R$ 254,87</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor total:</Text>
        <Text>R$ 50,87</Text>
      </ContentRow>
    </>
  );
};

export default InstallmentDetails;
