import { Reducer } from 'redux';

import { IRepositoriesStateDpaDpeMenager, RepositoriesTypesDpaDpe } from './types';

const INITIAL_STATE: IRepositoriesStateDpaDpeMenager = { alreadyTriedSend: false };

const reducer: Reducer<IRepositoriesStateDpaDpeMenager> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_SELECTED_FEATURE:
      return {
        ...state,
        loading: true,
        selectedFeature: action.payload.selectedFeature,
        type: action.payload.type,
      };
    case RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_VALUES_OBJECT:
      return {
        ...state,
        loading: true,
        ValuesObject: action.payload.data,
      };
    case RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_CASE_VALUES:
      return {
        ...state,
        loading: true,
        caseValues: action.payload.data,
      };
    case RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_TRIEND_SEND:
      return {
        ...state,
        alreadyTriedSend: action.payload.data.setAlreadyTriedSend,
      };
    default:
      return state;
  }
};

export default reducer;
