import React from 'react';

interface IProps {
  color?: string;
}

const ManagerUser = ({ color = '#37B4C8' }: IProps) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29846 33.5927H22.7546C21.7772 34.7774 21.147 36.2589 21.0182 37.8776L20.993 38.4937V51.0962C20.993 52.9586 21.6539 54.6669 22.7574 55.9972L22.3933 56C12.8154 56 5.74955 52.9978 1.42829 46.9374C0.498853 45.6323 -0.000422475 44.0699 2.68235e-07 42.4677V39.8912C2.68235e-07 38.2207 0.663586 36.6187 1.84478 35.4375C3.02597 34.2563 4.628 33.5927 6.29846 33.5927ZM35.6959 26.5913H44.0976C45.9124 26.5913 47.4023 27.9692 47.5815 29.7363L47.6011 30.092L47.5983 33.5927H51.099C53.8072 33.5927 56 35.7884 56 38.4937V51.0962C56 52.396 55.4836 53.6426 54.5645 54.5617C53.6454 55.4808 52.3988 55.9972 51.099 55.9972H28.6945C27.3947 55.9972 26.1481 55.4808 25.229 54.5617C24.3099 53.6426 23.7936 52.396 23.7936 51.0962V38.4937C23.7936 35.7884 25.9892 33.5927 28.6945 33.5927H32.1952V30.092C32.1952 28.2801 33.5731 26.7874 35.3403 26.6109L35.6959 26.5913H44.0976H35.6959ZM43.3975 30.7922H36.3961V33.5927H43.3975V30.7922ZM22.3961 0C26.1099 0 29.6715 1.47529 32.2976 4.10133C34.9236 6.72736 36.3989 10.289 36.3989 14.0028C36.3989 17.7166 34.9236 21.2782 32.2976 23.9043C29.6715 26.5303 26.1099 28.0056 22.3961 28.0056C18.6823 28.0056 15.1206 26.5303 12.4946 23.9043C9.86857 21.2782 8.39328 17.7166 8.39328 14.0028C8.39328 10.289 9.86857 6.72736 12.4946 4.10133C15.1206 1.47529 18.6823 0 22.3961 0Z"
        fill={color}
      />
    </svg>
  );
};

export default ManagerUser;
