/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Input } from '~/common/InputV3';
import { Margin } from '~/components/Margin';
import {
  postCloseErrorEnrollmentsUsersCreateRegister,
  postEnrollmentsUsersCreateRegisterRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { IState } from '~/store/modules/types';

import { Main, DescriptionTextStyled, NavWrapper } from './styles';
import { Breakpoints } from '~/styles/breakpoints';

import { Button } from '~/common/Button';
import { Card } from '~/common/Card';
import { ButtonLink } from '~/common';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { RouteName } from '~/routes/Routes.name';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import PasswordRequirements from '~/components/PasswordRequirements';
import { Color } from '~/styles';

import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';

const RegisterClientPassword: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataRegister = history.location.state as any;

  const loading = useSelector((item: IState) => item.enrollmentsUsers.loadingCreate);
  const error = useSelector((item: IState) => item.enrollmentsUsers.errorCreate);
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };

  const clearError = () => {
    dispatch(postCloseErrorEnrollmentsUsersCreateRegister());
  };

  useEffect(() => {
    return () => clearError();
  }, []);

  useEffect(() => {
    if (error && error.state && !showRecaptcha) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [errorPassword, error]);

  useEffect(() => {
    if (password && confirmPassword && isValidPassword) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword, isValidPassword]);

  const handleContinue = () => {
    if (['notClient', 'entrepreneur'].includes(dataRegister.clientType)) {
      if (dataRegister?.documentType === 'CPF') {
        return dispatch(
          postEnrollmentsUsersCreateRegisterRequest({
            ...recaptchaTokens,
            clientType: dataRegister?.clientType,
            documentType: dataRegister?.documentType,
            name: dataRegister?.name,
            documentNumber: dataRegister?.cnp,
            cnp: dataRegister?.cnp,
            email: dataRegister?.email || '',
            mobilePhone: dataRegister?.phone,
            phone: dataRegister?.telephone,
            password,
            token: dataRegister?.token,
            isNotClient: true,
            concession_terms_agree: true,
            acceptance_terms_agree: true,
          }),
        );
      }

      return dispatch(
        postEnrollmentsUsersCreateRegisterRequest({
          ...recaptchaTokens,
          clientType: dataRegister?.clientType,
          documentType: dataRegister?.documentType,
          name: dataRegister?.name,
          documentNumber: dataRegister?.cnp,
          cnp: dataRegister?.cnp,
          email: dataRegister?.email || '',
          mobilePhone: dataRegister?.phone || '',
          phone: dataRegister?.telephone || '',
          password,
          documentNumberResponsible: dataRegister?.documentNumberResponsible,
          nameResponsible: dataRegister?.nameResponsible,
          token: dataRegister?.token,
          isNotClient: true,
          concession_terms_agree: true,
          acceptance_terms_agree: true,
        }),
      );
    }

    return dispatch(
      postEnrollmentsUsersCreateRegisterRequest({
        ...recaptchaTokens,
        token: dataRegister?.token,
        cnp: dataRegister?.cnp,
        concession_terms_agree: true,
        acceptance_terms_agree: true,
        email: dataRegister?.email,
        mobilePhone: dataRegister?.phone,
        password,
      }),
    );
  };

  const handleClick = () => {
    if (disabled || loading) return;
    if (password !== confirmPassword) {
      setErrorPassword('Senhas não coincidem.');
    } else {
      handleContinue();
    }
  };

  return (
    <Main>
      <Recaptcha
        setRecaptchaTokenV3={setRecaptchaTokenV3}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        showRecaptchaV2={showRecaptcha}
        onClose={clearError}
      />
      <Modal open={open} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(postCloseErrorEnrollmentsUsersCreateRegister())}
        />
      </Modal>
      <Card title="Cadastrar senha">
        <Margin height={16} />
        <DescriptionTextStyled>
          Agora só falta criar sua senha. Para uma senha forte, siga os requisitos
          apresentados:
        </DescriptionTextStyled>
        <Margin height={isMobile ? 20 : 32} />
        <Input
          value={password}
          onChange={(ev) => {
            setPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Senha"
          type={!passwordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          maxLength={16}
        />
        <Margin height={isMobile ? 8 : 13} />
        <Input
          value={confirmPassword}
          onChange={(ev) => {
            setConfirmPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Confirmação de senha"
          type={!confirmPasswordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={confirmPasswordVisible}
          setPasswordVisible={setConfirmPasswordVisible}
          maxLength={16}
          error={!!errorPassword}
          errorText={errorPassword}
        />
        <Margin height={24} />
        <PasswordRequirements password={password} isValid={setIsValidPassword} />
        <Margin height={isMobile ? 20 : 24} />
        <NavWrapper>
          <Button
            onClick={handleClick}
            loading={loading}
            disabled={disabled}
            style={{ background: !disabled ? Color.green : undefined }}
          >
            Continuar
          </Button>
          <ButtonLink
            type="button"
            color="gray"
            onClick={() =>
              Navigation.navigate(RouteName.REGISTERTWOVERSIOVERIFYCODE, {
                ...dataRegister,
              })
            }
          >
            Voltar
          </ButtonLink>
        </NavWrapper>
        <Margin height={isMobile ? 20 : 24} />
      </Card>
    </Main>
  );
};

export default RegisterClientPassword;
