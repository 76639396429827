import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';

export const ContainerBackGroundImage = styled.div`
  position: fixed;
  height: 92px;
  width: 100%;
  margin: auto;
  bottom: -3px;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 260px;
  }

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    height: 325px;
  }
`;
