import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 22px;
  margin-bottom: 64px;
`;

export const Card = styled.div`
  width: calc(100% - 20px);
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 64px;
  background: #ffffff;
  border-top: 24px solid #004b55;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px 24px 0px 0px;

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 870px;
  }
`;

export const Column = styled.div`
  padding: 0 24px;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 80px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 24px auto 0 auto;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;
