import React from 'react';

const NegativeSVG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40863 14.3316V17.467H24.0853V14.3316H8.40863ZM16.247 0.222656C7.59345 0.222656 0.570312 7.24579 0.570312 15.8993C0.570312 24.5528 7.59345 31.5759 16.247 31.5759C24.9005 31.5759 31.9236 24.5528 31.9236 15.8993C31.9236 7.24579 24.9005 0.222656 16.247 0.222656ZM16.247 28.4406C9.33355 28.4406 3.70564 22.8127 3.70564 15.8993C3.70564 8.9859 9.33355 3.35798 16.247 3.35798C23.1603 3.35798 28.7883 8.9859 28.7883 15.8993C28.7883 22.8127 23.1603 28.4406 16.247 28.4406Z"
        fill="white"
      />
    </svg>
  );
};

export default NegativeSVG;
