import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 60px);
  min-height: 80px;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 99;

  p {
    color: ${Color.blueIntense};
    font-size: 14px;
    font-weight: 500;
    line-height: 161.4%;

    a {
      color: ${Color.blueSeconde};
      font-weight: 500;
    }
  }

  div {
    display: flex;
    gap: 0 8px;

    button {
      display: flex;
      width: 120px;
      height: 48px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      border: 2px solid ${Color.blueSeconde};
      background: ${Color.white};
      color: ${Color.blueSeconde};
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      :last-of-type {
        background: ${Color.blueSeconde};
        color: ${Color.white};
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    flex-direction: row;
    padding: 0 16px;
    max-width: 1010px;
    p {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    max-width: 1170px;
  }
`;
