import React from 'react';

const InvoiceChangeDueDateInfoIcon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.387939" width="44" height="43.2242" rx="8" fill="#FAB419" />
      <path
        d="M22.5 17.8031V23.5569M23.0754 27.0091C23.0754 27.3269 22.8178 27.5845 22.5 27.5845C22.1822 27.5845 21.9246 27.3269 21.9246 27.0091C21.9246 26.6914 22.1822 26.4338 22.5 26.4338C22.8178 26.4338 23.0754 26.6914 23.0754 27.0091ZM14.8046 31.6121H30.1954C31.9273 31.6121 33.0386 29.7713 32.232 28.2387L24.5366 13.6175C23.6738 11.9781 21.3262 11.9781 20.4634 13.6175L12.768 28.2387C11.9614 29.7713 13.0727 31.6121 14.8046 31.6121Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { InvoiceChangeDueDateInfoIcon };
