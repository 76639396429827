import styled, { css } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 26px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 38px 35px 203px 35px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 160px);
    padding: 55px 80px 241px 80px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 0 40px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  padding: 44px 16px 64px 16px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: center;
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 44px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(700px - 160px);
    padding: 44px 80px 64px 80px;

    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(1010px - 160px);
    max-width: calc(1010px - 160px);
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 35px);
  display: flex;
  align-items: center;
  margin-bottom: 54px;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    svg {
      left: 16px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    button {
      max-width: 252px;
    }
  }
`;

export const ContainerButtonsInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonInfo = styled.button<{ isActive: boolean }>`
  width: 100%;
  max-width: 600px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 14px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? Color.blueSeconde : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
  border: 2px solid ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  padding-left: 16px;

  svg {
    position: absolute;
    right: 16px;
    path {
      fill: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
    }
    ${({ isActive }) =>
      !isActive &&
      css`
        transform: rotate(180deg);
      `};
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding-left: 32px;
    svg {
      right: 32px;
    }
  }
`;

export const ContainerText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.blueIntense};
  margin: 20px 0 !important;

  h3 {
    line-height: 17px;
    font-size: 16px;
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  a {
    color: ${Color.blueSeconde};
    font-weight: 500;
  }

  ul {
    list-style: disc;
    margin-left: 12px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
  }

  div {
    display: flex;
    gap: 0 8px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};

    b {
      font-weight: 600;
    }
  }
`;

export const ButtonChat = styled.button`
  cursor: pointer;
  background: none;
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 500;
  line-height: 122.5%;
  letter-spacing: -0.35px;
  text-decoration-line: underline;
`;
