import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function ArrowRightLineSVG({ color = Color.grayLightSeconde }: IProp) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.90625L13 5.90625M13 5.90625L8 10.9062M13 5.90625H1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowRightLineSVG;
