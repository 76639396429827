/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Navigation from '~/utils/Navigation';

import { Breakpoints } from '~/styles/breakpoints';

import { BackGroundImage } from '~/components/BackGroundImage';
import { CardForm } from '~/components/CardForm';
import { Margin } from '~/components/Margin';

import {
  Main,
  Title,
  SubTitle,
  ProfileButton,
  ButtonTitles,
  ButtonTitle,
  ButtonSubtitle,
  CancelButton,
} from './styles';
import ArrowRightSVG from '~/assets/Icons/arrowRight';
import { Color } from '~/styles';
import HomeUser from '~/assets/Icons/HomeUser';
import ManagerUser from '~/assets/Icons/ManagerUser';
import { getEnrollmentsAuthDestroySessionRequest } from '~/store/modules/enrollmentsAuth/actions';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import EntrepreneurUser from '~/assets/Icons/EntrepreneurUser';

const AccessProfile: React.FC = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );

  const onCancel = () => {
    localStorage.clear();
    dispatch(getEnrollmentsAuthDestroySessionRequest());
  };

  const handleNavigation = (path: string): any => {
    if (path === RouteName.HOME && registrations && registrations.length > 1)
      return Navigation.navigate(RouteName.REGISTRATIONLIST);

    return Navigation.navigate(path);
  };

  return (
    <Main>
      <CardForm>
        <Margin height={isMobile ? 25 : 45} />
        <Title>Perfil de acesso</Title>
        <SubTitle>
          Oferecemos uma experiência personalizada às suas necessidades. Para
          começar, por favor, selecione o seu perfil.
        </SubTitle>

        <Margin height={isMobile ? 30 : 40} />

        <ProfileButton onClick={() => handleNavigation(RouteName.HOME)}>
          <HomeUser color={Color.blueSeconde} />
          <ButtonTitles>
            <ButtonTitle>PERFIL CLIENTE</ButtonTitle>
            <ButtonSubtitle>
              Para sua casa, seu condomínio e sua empresa.
            </ButtonSubtitle>
          </ButtonTitles>
          <ArrowRightSVG color={Color.blueSeconde} />
        </ProfileButton>

        <ProfileButton>
          <ManagerUser color={Color.grayLight} />
          <ButtonTitles>
            <ButtonTitle disabled>PERFIL GESTOR</ButtonTitle>
            <ButtonSubtitle>
              Para gestão de imobiliárias e condominios
            </ButtonSubtitle>
          </ButtonTitles>
          <ArrowRightSVG color={Color.grayLight} />
        </ProfileButton>
        <ProfileButton
          onClick={() => handleNavigation(RouteName.ENTERPRISE_VIABILITY)}
        >
          <EntrepreneurUser color={Color.blueSeconde} />
          <ButtonTitles>
            <ButtonTitle>PARA EMPREENDIMENTO</ButtonTitle>
            <ButtonSubtitle>
              Para solicitações de viabilidade (DPA e DPE)
            </ButtonSubtitle>
          </ButtonTitles>
          <ArrowRightSVG color={Color.blueSeconde} />
        </ProfileButton>

        <Margin height={isMobile ? 40 : 80} />

        <CancelButton onClick={onCancel}>Cancelar</CancelButton>

        <Margin height={isMobile ? 20 : 0} />
      </CardForm>
      <BackGroundImage />
    </Main>
  );
};

export default AccessProfile;
