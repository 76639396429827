/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */

import formatter from '~/utils/formatter';
import { api as apiv3 } from './api';

const pixRegisterCopyClipboard = (data: any) => {
  return new Promise((resolve, reject) => {
    try {
      const pixRegister: any = async () => {
        return apiv3.post('v3/metrics/', data);
      };
      resolve(pixRegister());
    } catch (error) {
      reject(error);
    }
  });
};

const metricRegisterCopyClipboard = (data: any, cnp: string) => {
  return new Promise((resolve, reject) => {
    try {
      const metricRegister: any = async () => {
        return apiv3.post(`v3/metrics/${formatter.formatarNumber(cnp)}`, data);
      };
      resolve(metricRegister());
    } catch (error) {
      reject(error);
    }
  });
};

export { pixRegisterCopyClipboard, metricRegisterCopyClipboard };
