import styled from 'styled-components';
import { Color } from '~/styles';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.white};
  box-sizing: border-box;
  left: 0%;
  top: 0%;
  z-index: 99;
  .spinner {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 4px solid ${Color.blueIntense};
    border-bottom: 4px solid transparent;
    animation: spin 1s ease infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
