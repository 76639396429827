import styled from 'styled-components';
import { Breakpoints } from '~/styles';

interface Props {
  open: boolean;
  fullScreen?: boolean;
}

export const Backdrop = styled.div<Props>`
  padding: ${({ fullScreen }) => (fullScreen ? '0' : '24px')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 900;
  background: #00000030;
  display: ${(p) => (p.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  div {
    z-index: 901;
    position: relative;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px;
  }
`;
