import React from 'react';
import { ConfigureAlert } from '../ConfigureAlert';
import { CloseIconButton, Container } from './styles';
import { CloseModalIcon } from '../ConfigureAlert/icons';

interface IProps {
  onClose: (status: boolean) => void;
}

const ModalAlert: React.FC<IProps> = ({ onClose }) => {
  return (
    <Container>
      <CloseIconButton type="button" onClick={() => onClose(false)}>
        <CloseModalIcon />
      </CloseIconButton>
      <ConfigureAlert />
    </Container>
  );
};

export { ModalAlert };
