export const RouteName = {
  LOGIN: '/login',
  FORGOTPASSWORD: '/recuperar-senha',
  FORGOTPASSWORDPHONE: '/recuperar-senha/metodo-verificacao',
  FORGOTPASSWORDCODE: '/recuperar-senha/verificar-codigo',
  FORGOTPASSWORDUPDATE: '/recuperar-senha/atualizar-senha',
  FORGOTPASSWORDUPDATESUCCESS: '/recuperar-senha/atualizacao-realizada',
  REGISTERPROFILE: '/perfil-cadastro',
  REGISTER: '/cadastro',
  REGISTERVERIFICATIONMETHOD: '/cadastro/metodo-verificacao',
  REGISTERENTREPRENEUR: '/cadastro/empreendedor',
  REGISTERMANAGER: '/cadastro/gestor',
  REGISTERNOTCLIENT: '/cadastro/naosaneamento',
  REGISTERUMBERPHONE: '/cadastro/validar/sms',
  REGISTEREMAIL: '/cadastro/validar/email',
  REGISTERTWOVERSIOVERIFYCODE: '/cadastro/validar-codigo',
  REGISTERPASSWORDCLIENT: '/cadastro/senha',
  REGISTERINFOCLIENT: '/cadastro/informacoes-cadastrais',
  REGISTERUPDATEINFOCLIENT: '/cadastro/atualizar-dados',
  REGISTRATIONLIST: '/enderecos',
  HOME: '/dashboard',
  NEGATIVECERTIFICATE: '/certidao-negativa',
  INVOICES: '/faturas',
  INVOICESCHANGEEXPIRATION: '/faturas/alterar-vencimento',
  INVOICESCHANGEDELIVERYTYPE: '/faturas/alterar-tipo-entrega',
  CHANGEPASSOWORD: '/atualizar-senha',
  SIMPLIFIEDINVOICE: '/fatura-completa',
  SIMPLIFIEDINVOICEPIX: '/fatura-completa/pix',
  SIMPLIFIEDINVOICECREDITCARD: '/fatura-completa/credit-card',
  SIMPLIFIEDINVOICEPAYMENTMETHODS: '/fatura-completa/pagamento-fatura',
  INVOICERECEIP: '/comprovante',
  ACCOUNTCONFIG: '/configuracao-conta',
  DETAILEDHISTORY: '/historico',
  CHANGEPHONE: '/alterar-telefone',
  CHANGEMOBILE: '/alterar-celular',
  CHANGEEMAIL: '/alterar-email',
  ACCESSPROFILE: '/perfil-acesso',
  NOTIFICATIONS: '/notificacoes',
  SMARTMETER: '/medicao-inteligente',
  SMARTMETERPLANS: '/medicao-inteligente/planos',
  SMARTMETERSUBSCRIPTION: '/medicao-inteligente/assinatura',
  WALLET: '/carteira',
  WALLETADDCARD: '/carteira/adicionar-cartao',
  WALLETDETAILCARD: '/carteira/detalhes-cartao',
  SUPPORTCENTER: '/central-de-reparos',
  SUPPORTCENTERCREATEORDER: '/central-de-reparos/solicitar-reparo',
  SUPPORTCENTERSERVICEORDER: '/central-de-reparos/ordem-de-servico',
  AUTOMATICDEBIT: '/debito-automatico',
  OWNERSHIPMANAGEMENT: '/troca-de-titularidade',
  OWNERSHIPMANAGEMENTCREATEORDER: '/troca-de-titularidade/solicitar-troca',
  OWNERSHIPMANAGEMENTVIEWORDER: '/troca-de-titularidade/solicitacao',
  OWNERSHIPMANAGEMENTPROTOCOL: '/troca-de-titularidade/protocolo',
  LANDING: '/',
  SOCIALTARIFF: '/tarifa-social',
  SOCIALTARIFFSOLICITATION: '/tarifa-social/solicitacao',
  SOCIALTARIFFREQUEST: '/tarifa-social/solicitacao/acompanhamento',
  EASYDUPLICATE: '/segunda-via',
  EASYDUPLICATETOKEN: '/segunda-via/:token',
  EASYDUPLICATESINGLEPAYMENT: '/segunda-via/pagamento-unico',
  EASYDUPLICATESINGLEPAYMENTDATAPAYMENT:
    '/segunda-via/pagamento-unico/dados-pagamento',
  EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT:
    '/segunda-via/pagamento-unico/dados-pagamento/comprovante',
  LINKENROLLMENT: '/vincular-matricula',
  LINKENROLLMENTFORM: '/vincular-matricula/recadastramento',
  SINGLEPAYMENT: '/pagamento-unico',
  SINGLEPAYMENTDATAPAYMENT: '/pagamento-unico/dados-pagamento',
  SINGLEPAYMENTDATAPAYMENTRECEIPT: '/pagamento-unico/dados-pagamento/comprovante',
  PASSWORDEXPIRATION: '/senha-expirada',
  PASSWORDEXPIRATIONVERIFYCODE: '/senha-expirada/validar-codigo',
  PASSWORDEXPIRATIONNEWPASSWORD: '/senha-expirada/nova-senha',
  DISCHARGEDEBTS: '/quitar-dividas',
  DISCHARGEDEBTSSINGLEPAYMENT: '/quitar-dividas/pagamento-unico',
  DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENT:
    '/quitar-dividas/pagamento-unico/dados-pagamento',
  DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT:
    '/quitar-dividas/pagamento-unico/dados-pagamento/comprovante',
  DEBTNEGOTIATION: '/quitar-dividas/negociacao-de-divida',
  DEBTNEGOTIATIONSINGLEPAYMENT:
    '/quitar-dividas/negociacao-de-divida/pagamento-unico',
  DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT:
    '/quitar-dividas/negociacao-de-divida/pagamento-unico/dados-pagamento',
  DEBTNEGOTIATIONTYPEPAYMENT: '/quitar-dividas/negociacao-de-divida/tipo-pagamento',
  DEBTNEGOTIATIONSIMULATION:
    '/quitar-dividas/negociacao-de-divida/tipo-pagamento/simulacao',
  DEBTNEGOTIATIONDETAIL: '/quitar-dividas/negociacao-de-divida/detalhamento',
  NEGOTIATIONS: '/minhas-negociacoes',
  NEGOTIATIONSDETAIL: '/minhas-negociacoes/detalhamento',
  SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING: '/dpa-dpe',
  ENTERPRISE_VIABILITY: '/viabilidade-empreendimento',
  ENTERPRISE_VIABILITY_PANEL_SLIPS: '/viabilidade-empreendimento/boletos',
  ENTERPRISE_VIABILITY_SLIPS_PAYMENT:
    '/viabilidade-empreendimento/boletos/pagamento',
  ENTERPRISE_VIABILITY_KNOW_MORE: '/viabilidade-empreendimento/saiba-mais',
  ENTERPRISE_VIABILITY_NEW_PROCESS: '/viabilidade-empreendimento/novo-processo',
  SUPPLYPOSSIBILITYCONSULTATION: '/viabilidade-empreendimento/cpae',
  SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE:
    '/viabilidade-empreendimento/cpae/tabela-valores',
  CUSTOMEROBLIGATION: '/viabilidade-empreendimento/obrigacao-controle',
  PROPERTYREGISTRATION: '/viabilidade-empreendimento/ficha-cadastral-predial',
  VERSIONS: '/versions',
  SOCIALTARIFFOUTSIDE: '/tarifa-social',
  SOCIALTARIFFOUTSIDEDETAIL: '/tarifa-social/detalhamento',
  SOCIALTARIFFOUTSIDESOLICITATION: '/tarifa-social/solicitacao',
  REPORTLACKWATER: '/reportar-falta-de-agua',
  SUPPORTCENTERPROTOCOL: '/central-de-reparos/protocolo',
};

export const TitleRoutes = {
  [RouteName.LOGIN]: 'Login',
  [RouteName.FORGOTPASSWORD]: 'Recuperar senha',
  [RouteName.FORGOTPASSWORDPHONE]: 'Recuperar senha - Método de recuperação',
  [RouteName.FORGOTPASSWORDCODE]: 'Recuperar senha - Verificar Código',
  [RouteName.FORGOTPASSWORDUPDATE]: 'Recuperar senha - Atualizar Senha',
  [RouteName.FORGOTPASSWORDUPDATESUCCESS]: 'Recuperar senha - Atualização realizada',
  [RouteName.REGISTER]: 'Cadastro',
  [RouteName.REGISTERUMBERPHONE]: 'Cadastro - Validar Telefone',
  [RouteName.REGISTEREMAIL]: 'Cadastro - Validar Email',
  [RouteName.REGISTERTWOVERSIOVERIFYCODE]: 'Cadastro - Validar Código',
  [RouteName.REGISTERPASSWORDCLIENT]: 'Cadastro - Alterar Senha',
  [RouteName.REGISTERINFOCLIENT]: 'Cadastro - Dados Cadastrais',
  [RouteName.REGISTERUPDATEINFOCLIENT]: 'Cadastro - Atualização de dados',
  [RouteName.REGISTRATIONLIST]: 'Seleção de Endereço',
  [RouteName.HOME]: 'Home',
  [RouteName.NEGATIVECERTIFICATE]: 'Certidão Negativa',
  [RouteName.INVOICES]: 'Faturas',
  [RouteName.INVOICESCHANGEEXPIRATION]: 'Faturas - Alterar Vencimento',
  [RouteName.INVOICESCHANGEDELIVERYTYPE]: 'Faturas - Alterar Tipo de Entrega',
  [RouteName.CHANGEPASSOWORD]: 'Atualização de Senha',
  [RouteName.SIMPLIFIEDINVOICE]: 'Fatura Completa',
  [RouteName.SIMPLIFIEDINVOICEPIX]: 'Fatura Completa - Pagamento Pix',
  [RouteName.SIMPLIFIEDINVOICECREDITCARD]: 'Fatura Completa - Pagamento Cartão',
  [RouteName.SIMPLIFIEDINVOICEPAYMENTMETHODS]:
    'Fatura Completa - Pagamento de fatura',
  [RouteName.INVOICERECEIP]: 'Comprovante',
  [RouteName.ACCOUNTCONFIG]: 'Configuração de Conta',
  [RouteName.DETAILEDHISTORY]: 'Histórico detalhado',
  [RouteName.CHANGEPHONE]: 'Atualização de Telefone',
  [RouteName.CHANGEMOBILE]: 'Atualização de Celular',
  [RouteName.CHANGEEMAIL]: 'Atualização de E-mail',
  [RouteName.SUPPLYPOSSIBILITYCONSULTATION]: 'DPA/DPE',
  [RouteName.ENTERPRISE_VIABILITY]: 'Viabilidade',
  [RouteName.ACCESSPROFILE]: 'Perfil de Acesso',
  [RouteName.NOTIFICATIONS]: 'Notificações',
  [RouteName.SMARTMETER]: 'Medição Inteligente',
  [RouteName.SMARTMETERPLANS]: 'Medição Inteligente - Escolha seu plano',
  [RouteName.SMARTMETERSUBSCRIPTION]: 'Medição Inteligente - Assinatura',
  [RouteName.WALLET]: 'Carteira',
  [RouteName.WALLETADDCARD]: 'Carteira - Adicionar forma de pagamento',
  [RouteName.WALLETDETAILCARD]: 'Carteira - Detalhes do cartão',
  [RouteName.SUPPORTCENTER]: 'Minhas solicitações',
  [RouteName.SUPPORTCENTERCREATEORDER]: 'Minhas solicitações - Solicitar Reparo',
  [RouteName.SUPPORTCENTERSERVICEORDER]: 'Minhas solicitações - Ordem de Serviço',
  [RouteName.AUTOMATICDEBIT]: 'Débito automático',
  [RouteName.REGISTERPROFILE]: 'Perfil de cadastro',
  [RouteName.REGISTERENTREPRENEUR]: 'Cadastro de Empreendedor',
  [RouteName.REGISTERMANAGER]: 'Cadastro de gestor',
  [RouteName.REGISTERNOTCLIENT]: 'Cadastro de cliente não saneamento',
  [RouteName.REGISTERVERIFICATIONMETHOD]: 'Metodo de verificação',
  [RouteName.OWNERSHIPMANAGEMENT]: 'Troca de Titularidade',
  [RouteName.OWNERSHIPMANAGEMENTCREATEORDER]: 'Troca de Titularidade',
  [RouteName.OWNERSHIPMANAGEMENTVIEWORDER]: 'Troca de Titularidade',
  [RouteName.OWNERSHIPMANAGEMENTCREATEORDER]:
    'Troca de Titularidade - Solicitar troca',
  [RouteName.OWNERSHIPMANAGEMENTPROTOCOL]: 'Troca de Titularidade - Protocolo',
  [RouteName.LANDING]: 'Página inicial',
  [RouteName.SOCIALTARIFF]: 'Tarifa social',
  [RouteName.SOCIALTARIFFSOLICITATION]: 'Tarifa social - Solicitação',
  [RouteName.SOCIALTARIFFREQUEST]: 'Tarifa social - Acompanhamento',
  [RouteName.CUSTOMEROBLIGATION]: 'Obrigação Cliente',
  [RouteName.EASYDUPLICATE]: 'Segunda Via Fácil',
  [RouteName.EASYDUPLICATETOKEN]: 'Segunda Via Fácil',
  [RouteName.EASYDUPLICATESINGLEPAYMENT]: 'Segunda Via Fácil - Pagamento Único',
  [RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT]:
    'Segunda Via Fácil - Pagamento Único - Dados de pagamento',
  [RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT]:
    'Segunda Via Fácil - Pagamento Único - Comprovante',
  [RouteName.LINKENROLLMENT]: 'Vincular Matrícula',
  [RouteName.LINKENROLLMENTFORM]: 'Vincular Matrícula - Recadastramento',
  [RouteName.PROPERTYREGISTRATION]: 'Ficha Cadastral Predial',
  [RouteName.SINGLEPAYMENT]: 'Pagamento Único',
  [RouteName.SINGLEPAYMENTDATAPAYMENT]: 'Pagamento Único - Dados de pagamento',
  [RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT]: 'Pagamento Único - Comprovante',
  [RouteName.PASSWORDEXPIRATION]: 'Expiração de Senha',
  [RouteName.PASSWORDEXPIRATIONVERIFYCODE]: 'Expiração de Senha - Verificar Código',
  [RouteName.PASSWORDEXPIRATIONNEWPASSWORD]: 'Expiração de Senha - Atualizar Senha',
  [RouteName.DISCHARGEDEBTS]: 'Quitação de Dívidas',
  [RouteName.DISCHARGEDEBTSSINGLEPAYMENT]: 'Quitação de Dívidas - Pagamento Único',
  [RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENT]:
    'Quitação de Dívidas - Pagamento Único - Dados de pagamento',
  [RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT]:
    'Quitação de Dívidas - Pagamento Único - Comprovante',
  [RouteName.DEBTNEGOTIATION]: 'Negociação de dívidas',
  [RouteName.DEBTNEGOTIATIONSINGLEPAYMENT]:
    'Negociação de dívidas - Pagamento Único',
  [RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT]:
    'Negociação de dívidas Pagamento Único - Dados de pagamento',
  [RouteName.DEBTNEGOTIATIONTYPEPAYMENT]:
    'Negociação de dívidas Pagamento Único - Tipo de Pagamento',
  [RouteName.DEBTNEGOTIATIONSIMULATION]:
    'Negociação de dívidas Pagamento Único - Simulação',
  [RouteName.DEBTNEGOTIATIONDETAIL]: 'Negociação de dívidas - Detalhamento',
  [RouteName.NEGOTIATIONS]: 'Minhas Negociações',
  [RouteName.NEGOTIATIONSDETAIL]: 'Minhas Negociações - Detalhamento',
  [RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING]: 'DPA e DPE',
  [RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS]: 'DPA e DPE - Novo Processo',
  [RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS]: 'DPA e DPE - Boletos',
  [RouteName.ENTERPRISE_VIABILITY_SLIPS_PAYMENT]: 'DPA e DPE - Boletos',
  [RouteName.SUPPLYPOSSIBILITYCONSULTATION]: 'CPAE - Formulário',
  [RouteName.SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE]: 'CPAE - Tabela de Valores',
  [RouteName.ENTERPRISE_VIABILITY_KNOW_MORE]: 'CPAE - Saiba mais',
  [RouteName.SOCIALTARIFFOUTSIDE]: 'Tarifa Social',
  [RouteName.SOCIALTARIFFOUTSIDEDETAIL]: 'Tarifa Social - Detalhes da tarifa social',
  [RouteName.SOCIALTARIFFOUTSIDESOLICITATION]: 'Tarifa Social - Solicitação',
  [RouteName.REPORTLACKWATER]: 'Reportar Falta D’água',
  [RouteName.SUPPORTCENTERPROTOCOL]: 'Minhas solicitações - Protocolo',
};

export const metaDescriptions = {
  '/': {
    description:
      'Com o Digi Igua você consulta suas faturas e paga na hora de qualquer lugar usando PIX ou Cartão de Credito.',
  },
  [RouteName.INVOICES]: {
    description:
      'Aqui você pode acompanhar suas faturas pendentes, quitadas e vencidas.',
  },
};
