import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IProps {
  selected?: boolean;
}

const opt = {
  selected: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${Color.gray};
    font-size: 12px;
    font-weight: 300;
    border: none;
    justify-content: center;
    margin-left: 14px;
  `,
};

export const Wrapper = styled.div`
  width: 100%;
  max-height: 64px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 36px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 300px;
  }
`;

export const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  .left {
    border-right: none;
  }
  .right {
    border-left: none;
  }
  .empty {
    background: none;
    border: none;
  }
`;

export const UploadLabel = styled.label`
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const Upload = styled.button<IProps>`
  padding: 0;
  width: 100%;
  min-height: 64px;
  background: none;
  outline: none;
  font-size: 12px;
  line-height: 17px;
  color: ${Color.blueIntense};
  font-weight: 500;
  line-height: 1.2;
  border: 1px solid ${Color.grayLightSeconde};
  white-space: pre-wrap;
  text-align: center;

  i {
    font-style: italic;
  }

  &:hover {
    cursor: pointer;
  }
  ${(p) => (p.selected ? opt.selected : null)}
  span {
    color: ${Color.blueIntense};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const UploadSpecs = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.grayLightSeconde};
`;

export const Box = styled.div``;

export const Button = styled.button`
  min-width: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.grayLight};
  border: 1px solid ${Color.grayLightSeconde};
  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 64px;
    max-height: 64px;
  }

  embed {
    width: 64px;
    height: 64px;
  }
`;
