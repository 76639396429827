import styled from 'styled-components';
import { Color } from '~/styles';

interface IPropsMain {
  status: 'info' | 'wait' | 'warning' | 'success';
  maxWidthButton?: number;
}

export const Main = styled.div<IPropsMain>`
  min-height: calc(102px - 48px);
  background: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid
    ${({ status }) =>
      status === 'info'
        ? Color.blueIntense
        : status === 'wait'
        ? Color.blueSeconde
        : status === 'warning'
        ? Color.yellow
        : status === 'success'
        ? Color.green
        : Color.blueIntense};
  border-radius: 16px;
  padding: 24px;

  div:last-of-type {
    margin-left: 24px;

    p {
      color: #000000;
    }

    p:first-of-type {
      color: ${({ status }) =>
        status === 'info'
          ? Color.blueIntense
          : status === 'wait'
          ? Color.blueSeconde
          : status === 'warning'
          ? Color.yellow
          : status === 'success'
          ? Color.green
          : Color.blueIntense};
      font-weight: 700;
      font-size: 16px;
      line-height: 122.5%;
    }

    p:last-of-type {
      margin-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 122.5%;
      letter-spacing: -0.025em;
    }

    b,
    a {
      font-weight: 700;
      color: #000000;
    }
  }

  div:has(+ button) {
    margin-right: 15px;
  }

  .button-download {
    max-width: ${({ maxWidthButton }) => `${maxWidthButton}px` || 'inherit'};
    height: 72px;
    padding: 12px 16px;
    background: #37b482;
    border-radius: 8px;
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    div {
      width: 48px;
      height: 48px;
      margin: 0 12px 0 0;
      background: #e9ecef;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 48px;
      }
    }

    p {
      color: #ffffff;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
  }
`;
