import React, { FC, useState, HTMLAttributes } from 'react';
import { Wrapper, Icon, Message } from './styles';

const HelpTag: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Wrapper {...props}>
      <Icon onMouseOut={handleMouseOut} onMouseEnter={handleMouseEnter}>
        ?
      </Icon>
      {isHovering && (
        <Message>
          A matrícula é o registro do cliente e pode ser encontrado na fatura do
          imóvel!
        </Message>
      )}
    </Wrapper>
  );
};

export { HelpTag };
