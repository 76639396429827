import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const ContainerQRCode = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 256px;
  }
`;
