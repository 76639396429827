import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsContainer {
  isActive: 'daily' | 'monthly';
  iot?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;

  #top-filter {
    display: flex;
    button {
      width: 100%;
      height: 28px;
      color: ${Color.blueSeconde};
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      background: none;
      border: none;
      border-bottom: 2px solid ${Color.blueSeconde};
      cursor: pointer;
    }

    .hidden-mobile {
      display: initial;
      color: ${Color.BlueLight};
      border-color: ${Color.BlueLight};
    }
  }

  #bottom-filter {
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    gap: 13px;
    width: 100%;
    max-width: 553px;
    margin-top: 22px;

    button {
      width: 100%;
      height: 28px;
      border-radius: 8px;
      border: 1px solid ${Color.blueSeconde};
      background: ${Color.white};
      color: ${Color.blueSeconde};
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      cursor: pointer;
    }

    .hidden-mobile {
      color: ${Color.BlueLight};
      border-color: ${Color.BlueLight};
    }

    .active {
      color: ${Color.white};
      background: ${Color.blueSeconde};
    }
  }

  .visible-mobile {
    display: initial;
  }

  .hidden-mobile {
    display: none;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: ${({ iot }) => (iot ? '100%' : '50%')};

    #top-filter {
      gap: 15px;
      button {
        max-width: ${({ iot }) => iot && '269px'};
      }
    }

    .hidden-mobile {
      display: initial;
    }
  }
`;
