import styled from 'styled-components';
import { Color } from '~/styles';

import { Breakpoints } from '../../styles/breakpoints';

export const CardFormStyled = styled.div`
  width: calc(100% - 36px);
  border-radius: 16px;
  min-height: 440px;
  padding: 0 18px;
  background: linear-gradient(360deg, #ffffff 65.16%, #37b4c8 100%);

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .title-form {
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    color: ${Color.blueIntense};
    text-transform: uppercase;
    margin-top: 26px;
  }

  svg {
    margin-top: 26px;
  }
  /* .logo-wrapper {
    width: 100%;
    border: 1px solid;
  } */

  img {
    margin-top: 48px;
    height: 96px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(609px - 56px);
    min-height: 680px;
    padding: 0 28px;

    .title-form {
      font-size: 24px;
      margin-top: 60px;
    }

    svg {
      margin-top: 39px;
    }
  }
`;
