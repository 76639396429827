import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 69px 10px 93px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 94px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 160px);
    padding: 55px 80px 241px 80px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 596px;
  margin: 0 auto 40px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 1140px;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  min-height: calc(514px - 148px);
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 84px 16px 64px 16px;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.blueIntense};
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.gray};
    text-align: center;
    margin-top: 34px;
  }

  .container-input {
    width: 100%;
    display: flex;
    align-items: end;
    input {
      height: 36px;
    }
    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 48px;
    border-radius: 8px;

    :last-of-type {
      background: transparent;
      color: ${Color.gray};
      width: fit-content;
      height: fit-content;
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 84px 24px 64px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(596px - 160px);
    padding: 84px 80px 64px 80px;
  }
`;

export const BoxQuestion = styled.div`
  width: calc(100% - 48px);
  height: calc(456px - 48px);
  padding: 24px;
  background: #f2f2f2;
  border: 2px solid ${Color.blueIntense};
  border-radius: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      margin-bottom: 24px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 122.5%;
    color: ${Color.blueIntense};
    text-align: center;
  }

  img {
    width: 100%;
    height: 95px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 122.5%;
    color: #000;
    text-align: left;
    margin: 0;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    img {
      height: 152px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: calc(415px - 48px);

    div {
      justify-content: space-between;
      flex-direction: row;

      svg {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }

    h2 {
      text-align: left;
    }

    img {
      height: 167px;
    }
  }
`;
