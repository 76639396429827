import React from 'react';

function BalloonInfoSVG({ color }: { color: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5C3.58214 0.5 0 4.08214 0 8.5C0 12.9179 3.58214 16.5 8 16.5C12.4179 16.5 16 12.9179 16 8.5C16 4.08214 12.4179 0.5 8 0.5ZM8 13.1429C7.60536 13.1429 7.28571 12.8232 7.28571 12.4286C7.28571 12.0339 7.60536 11.7143 8 11.7143C8.39464 11.7143 8.71429 12.0339 8.71429 12.4286C8.71429 12.8232 8.39464 13.1429 8 13.1429ZM9.12321 9.22322C8.9615 9.28566 8.82235 9.3954 8.72392 9.5381C8.62549 9.68081 8.57235 9.84986 8.57143 10.0232V10.4286C8.57143 10.5071 8.50714 10.5714 8.42857 10.5714H7.57143C7.49286 10.5714 7.42857 10.5071 7.42857 10.4286V10.0446C7.42857 9.63214 7.54821 9.225 7.78393 8.88571C8.01429 8.55357 8.33571 8.3 8.71429 8.15536C9.32143 7.92143 9.71429 7.4125 9.71429 6.85714C9.71429 6.06964 8.94464 5.42857 8 5.42857C7.05536 5.42857 6.28571 6.06964 6.28571 6.85714V6.99286C6.28571 7.07143 6.22143 7.13571 6.14286 7.13571H5.28571C5.20714 7.13571 5.14286 7.07143 5.14286 6.99286V6.85714C5.14286 6.15536 5.45 5.5 6.00714 5.0125C6.54286 4.54286 7.25 4.28571 8 4.28571C8.75 4.28571 9.45714 4.54464 9.99286 5.0125C10.55 5.5 10.8571 6.15536 10.8571 6.85714C10.8571 7.88929 10.1768 8.81786 9.12321 9.22322Z"
        fill={color}
      />
    </svg>
  );
}

export default BalloonInfoSVG;
