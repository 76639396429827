/* eslint-disable no-useless-escape */
/* eslint operator-assignment: off */
/* eslint eqeqeq: off */
/* eslint radix: off */

import { CpaeNotRequiredInputNames } from '~/enums/cpaeEnum';

interface IPropsForm {
  value: string;
  isRequired: boolean;
  error: string;
}

export const checkPassword = (value: string) => {
  if (!value) return 'Por favor, digite uma senha para acessar o Digi Iguá';

  const hasNumber = value.replace(/\D/g, '').length > 0;
  if (!hasNumber) return 'Sua senha precisa ter pelo menos um número!';

  const hasUp = value.replace(/[^A-ZÀÁÂÃÄÅÇČĆÈÉÊËÒÓÔÕÖØSSÙÚÛÜŸÆ]/g, '').length > 0;
  if (!hasUp) return 'Sua senha precisa ter pelo menos uma letra maiúscula!';

  const hasLower =
    value.replace(/[^a-zàáâãäåçčćèéêëòóôõöøssùúûüÿæ]/g, '').length > 0;
  if (!hasLower) return 'Sua senha precisa ter pelo menos uma letra minúscula!';

  const hasSpecial =
    value.replace(/[^-!\@\#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g, '').length > 0;
  if (!hasSpecial) return 'Sua senha precisa ter pelo menos um caracter especial!';
  if (value.length < 8) return 'Sua senha precisa ter pelo menos 8 caracteres!';

  return '';
};

export const checkCnp = (value: string) => {
  if (!value) return true;
  const cnp = value.replace(/[^\d]+/g, '');

  if (cnp.length === 11) return checkCPF(cnp);
  if (cnp.length === 14) return checkCNPJ(cnp);

  return false;
};

export const checkCPF = (cpf: string): boolean => {
  if (
    [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ].includes(cpf)
  )
    return false;

  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const checkCNPJ = (cnpj: string): boolean => {
  if (
    [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ].includes(cnpj)
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;

  const number = phoneNumber.replace(/\D/g, '');
  const regex = /^\d{11}$/;

  return regex.test(number);
};

export const validateFile = (value: any) => {
  return !!value;
};

export const validateFileSize = (value: any) => {
  if (!value) return false;
  return value.size <= 10240000;
};

export const validateBase64FileSize = (value: any) => {
  if (!value) return false;
  return value.filesize <= 10240000;
};

export const validateBase64FileData = (value: any) => {
  if (!value) return false;
  return !!value.filedata && value.filesize > 102;
};

export const validateEmptyInputsFormCpae = () => {
  const inputs = document.querySelectorAll(
    'form[name="cpaeForm"] input:not([type="file"]):not([type="hidden"]), form[name="cpaeForm"] select:not([type="hidden"])',
  ) as any;

  for (let i = 0; i < inputs.length; i++) {
    if (
      inputs[i].value.trim() === '' &&
      !CpaeNotRequiredInputNames.includes(inputs[i].name)
    )
      return true;
  }
  return false;
};

export const validateIsEmpty = (item: string | Object): boolean => {
  if (typeof item === 'string') {
    return item.trim().length === 0;
  }

  if (item instanceof Object) {
    return Object.entries(item).length === 0;
  }

  return false;
};

export const validateForm = (state: {
  [key: string]: IPropsForm;
}): { [key: string]: IPropsForm } => {
  const inputsWithError: { [key: string]: IPropsForm } = {};

  Object.entries(state).forEach((input: any) => {
    const [key, { value, isRequired }] = input;

    if (validateIsEmpty(value) && isRequired) {
      inputsWithError[key] = {
        isRequired,
        value,
        error: 'Campo obrigatório',
      };
    }
  });

  return inputsWithError;
};

export const isArrayContained = (subArray: any[], mainArray: any[]) => {
  return subArray.every((element) => mainArray.includes(element));
};
