import React from 'react';

function NegotiationSVG() {
  return (
    <svg
      width="32"
      height="21"
      viewBox="0 0 32 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.17 1.96088L11.33 5.88088C10.525 6.53088 10.37 7.70088 10.98 8.53588C11.625 9.42588 12.88 9.60088 13.745 8.92588L18.71 5.06588C19.06 4.79588 19.56 4.85588 19.835 5.20588C20.11 5.55588 20.045 6.05588 19.695 6.33088L18.65 7.14088L27.51 15.3009H29.6C30.925 15.3009 32 14.2259 32 12.9009V6.50088C32 5.17588 30.925 4.10088 29.6 4.10088H25.565L25.37 3.97588L21.74 1.65088C20.975 1.16088 20.08 0.900879 19.17 0.900879C18.08 0.900879 17.02 1.27588 16.17 1.96088ZM17.31 8.18088L14.725 10.1909C13.15 11.4209 10.865 11.1009 9.685 9.48088C8.575 7.95588 8.855 5.82588 10.32 4.64088L14.48 1.27588C13.9 1.03088 13.275 0.905879 12.64 0.905879C11.7 0.900879 10.785 1.18088 10 1.70088L6.4 4.10088H2.4C1.075 4.10088 0 5.17588 0 6.50088V12.9009C0 14.2259 1.075 15.3009 2.4 15.3009H7.81L12.38 19.4709C13.36 20.3659 14.875 20.2959 15.77 19.3159C16.045 19.0109 16.23 18.6559 16.325 18.2859L17.175 19.0659C18.15 19.9609 19.67 19.8959 20.565 18.9209C20.79 18.6759 20.955 18.3909 21.06 18.0959C22.03 18.7459 23.35 18.6109 24.165 17.7209C25.06 16.7459 24.995 15.2259 24.02 14.3309L17.31 8.18088Z"
        fill="#004B55"
      />
    </svg>
  );
}
export default NegotiationSVG;
