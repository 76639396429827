/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, Fragment } from 'react';
import { Checkbox } from '~/components/CheckboxV3';
import { Data } from '..';
import { ContainerCheckBox } from './styles';
import { DescriptionText } from '../styles';

import { Container } from './styles';
import FormDataEnrollment from './FormDataEnrollment';

interface IProps {
  data: Data | null;
  setData: (type: any) => void;
}

export const typesReportLackWaterStepOne = [
  {
    id: 1,
    label: 'Usar os dados da minha matrícula',
    content: <FormDataEnrollment />,
  },
  {
    id: 2,
    label: 'Usar outro endereço',
    content: null,
  },
];

const ReportLackWaterStepOne: FC<IProps> = ({ data, setData }) => {
  return (
    <Container>
      <DescriptionText>
        Preencha os dados referentes ao local do reparo
      </DescriptionText>
      <ContainerCheckBox>
        {typesReportLackWaterStepOne?.map((item) => (
          <Fragment key={item?.id}>
            <Checkbox
              checked={data ? data?.local === item.id : false}
              // onClick={() => setData({ ...data, local: item?.id })}
            >
              {item.label}
            </Checkbox>
            {data?.local === item.id && item?.content && item.content}
          </Fragment>
        ))}
      </ContainerCheckBox>
    </Container>
  );
};

export default ReportLackWaterStepOne;
