import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  margin-block: 80px;
  margin-inline: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextRegisterStyled = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${Color.gray};

  a {
    color: ${Color.blueSeconde};
    text-decoration: underline;
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 16px;
  }
`;

export const ContainerForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  max-width: 560px;
  margin-inline: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
