import React, { useState, useRef } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { getStatusDetail } from '~/utils/ownership';
import Navigation from '~/utils/Navigation';
import { ContainerCard, Button, Loading } from '~/common';
import Modal from '~/components/Modal';
import { BtnMedium } from '~/components/Btn';

import {
  ModalNav,
  Main,
  Wrapper,
  StatusMessage,
  Divider,
  OrderBottom,
  DateBox,
  StatusOpt,
  StatusInfo,
  OrderWrapper,
  StatusWrapper,
  OrderDate,
  CloseButton,
} from './styles';

import { useSelector, useDispatch } from 'react-redux';
import { IState } from '~/store/modules/types';
import { CopyButton } from '~/views/SupportCenter/Material/CopyButton';
import { deleteOwnershipOrderRequest } from '~/store/modules/ownership/actions';
import { RouteName } from '~/routes/Routes.name';
import { OrderStatusSection } from '../Common';

interface IModal {
  children: any;
  title: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

interface OrderDateProps {
  createdAt?: string;
  closedAt?: string;
  order: any;
}

export const OrderDateSection: React.FC<OrderDateProps> = ({
  createdAt,
  closedAt,
  order,
}) => {
  return (
    <OrderDate>
      <DateBox>
        <p>Data de solicitação</p>
        <div>{moment(createdAt).format('DD/MM/YYYY')}</div>
      </DateBox>
      {closedAt && closedAt?.length > 0 && (
        <DateBox>
          <p>{getStatusDetail(order?.status || '').msgButton}</p>
          <div>{moment(closedAt).format('DD/MM/YYYY')}</div>
        </DateBox>
      )}
    </OrderDate>
  );
};

const OwnershipViewOrder = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<IModal>();
  const printRef = useRef(null);

  const dispatch = useDispatch();

  const order = useSelector((item: IState) => item.ownership.detail.data);
  const loading = useSelector((item: IState) => item.ownership.loading);
  const orderId = order?.case_id;

  const handleCloseOrder = () => {
    setModal({
      children: (
        <ModalNav>
          <BtnMedium onClick={() => setOpen(false)}>Voltar</BtnMedium>
          <BtnMedium
            color="error"
            onClick={() => {
              orderId ? dispatch(deleteOwnershipOrderRequest(orderId)) : null;
              setOpen(false);
            }}
          >
            Sim, cancelar
          </BtnMedium>
        </ModalNav>
      ),
      type: 'info',
      title: 'Deseja mesmo cancelar a solicitação?',
    });
    setOpen(true);
    ReactGA.event({
      category: 'Troca de Titularidade',
      action: 'Encerramento da troca de titularidade',
    });
  };

  return (
    <Main>
      <Modal open={open} type={modal ? modal.type : 'success'} title={modal?.title}>
        {modal?.children}
      </Modal>
      <ContainerCard title="Troca de Titularidade">
        <Wrapper ref={printRef}>
          {loading && <Loading />}
          <OrderWrapper>
            <OrderStatusSection status={order?.status ?? ''} />
            <OrderDateSection
              createdAt={order?.createdDate}
              closedAt={order?.closedDate}
              order={order}
            />
            <OrderBottom>
              <StatusMessage>
                {getStatusDetail(order?.status || '').message}
              </StatusMessage>
            </OrderBottom>
          </OrderWrapper>
          <Divider />
          <StatusWrapper>
            {order?.status === 'Em aberto' || order?.status === 'Novo' ? (
              <CloseButton onClick={handleCloseOrder}>
                Cancelar solicitação
              </CloseButton>
            ) : null}
            <StatusInfo>
              <p>O número do seu protocolo é:</p>
              <CopyButton>{order?.caseNumber ?? ''}</CopyButton>
            </StatusInfo>
            <StatusOpt>
              <Button
                variant="secondary"
                onClick={() =>
                  Navigation.navigate(RouteName.OWNERSHIPMANAGEMENTPROTOCOL)
                }
              >
                Visualizar protocolo
              </Button>
              <Button
                variant="primary"
                onClick={() => Navigation.navigate(RouteName.HOME)}
              >
                Voltar para home
              </Button>
            </StatusOpt>
          </StatusWrapper>
        </Wrapper>
      </ContainerCard>
    </Main>
  );
};

export default OwnershipViewOrder;
