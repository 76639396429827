import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const ProfileFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  align-items: flex-start;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

export const ProfileFormGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  & .large {
    grid-column: span 2;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    & .large {
      grid-column: auto;
    }
    grid-template-columns: repeat(1, 1fr);
  }
`;
