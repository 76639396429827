import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function ArrowUpSVG({ color = Color.white }: IProp) {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.352185 8.43395C0.577757 8.64382 0.883657 8.76172 1.20262 8.76172C1.52157 8.76172 1.82747 8.64382 2.05305 8.43395L8.00726 2.89249L13.9615 8.43395C14.1883 8.63787 14.4922 8.75071 14.8076 8.74816C15.123 8.74561 15.4247 8.62787 15.6477 8.42031C15.8707 8.21275 15.9972 7.93197 16 7.63844C16.0027 7.34492 15.8815 7.06213 15.6623 6.85099L8.85769 0.518061C8.63212 0.30819 8.32622 0.19029 8.00726 0.19029C7.6883 0.19029 7.3824 0.30819 7.15683 0.518061L0.352185 6.851C0.126681 7.06093 -6.49944e-08 7.34562 -5.137e-08 7.64247C-3.77456e-08 7.93932 0.126681 8.22401 0.352185 8.43395Z"
        fill={color}
      />
    </svg>
  );
}
export default ArrowUpSVG;
