import { Dispatch } from 'redux';
import {
  getSubscriptionService,
  postSubscriptionService,
  deleteSubscriptionService,
} from '~/services/subscribe';
import {
  RepositoriesTypesSubscription,
  IResponseGetSubscription,
  IRequestPostSubscription,
  IRequestDeleteSubscription,
} from './types';

const getSubscriptionRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_REQUEST,
    });
    const response = (await getSubscriptionService()) as IResponseGetSubscription;

    return dispatch({
      type: RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_SUCCESS,
      payload: response,
      loading: false,
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
        loading: false,
      },
    });
  }
};

const postSubscriptionRequest =
  (data: IRequestPostSubscription) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_REQUEST,
      });

      await postSubscriptionService(data);

      return dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
          },
        },
      });
    }
  };

const postSuccessCloseSubscriptionRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_SUCCESS_CLOSE,
  });
};

const postErrorCloseSubscriptionRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_FAILURE_CLOSE,
  });
};

const deleteSubscriptionRequest =
  (data: IRequestDeleteSubscription) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_REQUEST,
      });
      await deleteSubscriptionService(data);

      const response = (await getSubscriptionService()) as IResponseGetSubscription;

      dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_SUCCESS,
        payload: response,
        loading: false,
      });

      return dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const deleteErrorCloseSubscriptionRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_FAILURE_CLOSE,
  });
};

export {
  getSubscriptionRequest,
  postSubscriptionRequest,
  postSuccessCloseSubscriptionRequest,
  postErrorCloseSubscriptionRequest,
  deleteSubscriptionRequest,
  deleteErrorCloseSubscriptionRequest,
};
