import React from 'react';
import MailSVG from '~/assets/Icons/Mail';
import PhoneSVG from '~/assets/Icons/PhoneSMS';

import Navigation from '~/utils/Navigation';

import { Main, MethodButton, MethodDiv, Wrapper } from './styles';
import { RouteName } from '~/routes/Routes.name';
import { Card } from '~/common/Card';
import { ButtonLink } from '~/common';
import { history } from '~/store';
import { IDataRegister } from '../Register';
import formatter from '~/utils/formatter';
import { Margin } from '~/components/Margin';
import { useDispatch } from 'react-redux';
import { postEnrollmentsUsersSendSmsRegisterRequest } from '~/store/modules/enrollmentsUsers/actions';

const RegisterVerificationMethod: React.FC = () => {
  const dispatch = useDispatch();

  const dataRegister = history.location.state as IDataRegister;

  return (
    <Main>
      <Card
        title="CÓDIGO DE VERIFICAÇÃO"
        label="Para seguir com o cadastro, escolha por onde receber o código de verificação:"
      >
        <Wrapper>
          <MethodDiv>
            <MethodButton
              onClick={() => {
                dispatch(
                  postEnrollmentsUsersSendSmsRegisterRequest(
                    {
                      cnp: dataRegister?.cnp || '',
                      email: dataRegister?.email,
                      phone: dataRegister?.phone,
                      isEntrepreneur: false,
                      isManager: false,
                    },
                    {
                      ...dataRegister,
                      method: 'sms',
                    },
                  ),
                );
              }}
            >
              <PhoneSVG layoutv3 />
              Receber por <br />
              SMS
              <Margin height={15} />
              <p>{formatter.obscurePhone(dataRegister?.phone || '')}</p>
            </MethodButton>
            <MethodButton
              onClick={() => {
                dispatch(
                  postEnrollmentsUsersSendSmsRegisterRequest(
                    {
                      cnp: dataRegister?.cnp || '',
                      email: dataRegister?.email,
                      phone: dataRegister?.phone,
                      isEntrepreneur: false,
                      isManager: false,
                    },
                    {
                      ...dataRegister,
                      method: 'email',
                    },
                  ),
                );
              }}
            >
              <MailSVG layoutv3 />
              Receber por <br />
              e-mail
              <Margin height={15} />
              <p>{formatter.obscureEmail(dataRegister?.email || '')}</p>
            </MethodButton>
          </MethodDiv>
          <ButtonLink
            type="button"
            color="gray"
            onClick={() =>
              Navigation.navigate(
                dataRegister.clientType === 'client'
                  ? RouteName.REGISTER
                  : RouteName.REGISTERNOTCLIENT,
                { ...dataRegister },
              )
            }
          >
            Voltar
          </ButtonLink>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default RegisterVerificationMethod;
