export enum RepositoriesTypesPropertyRegistration {
  PROPERTY_REGISTRATION_GET_LIST_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_GET_LIST_METHOD_REQUEST',
  PROPERTY_REGISTRATION_GET_LIST_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_GET_LIST_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_GET_LIST_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_GET_LIST_METHOD_FAILURE',

  PROPERTY_REGISTRATION_GET_ITEM_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_GET_ITEM_METHOD_REQUEST',
  PROPERTY_REGISTRATION_GET_ITEM_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_GET_ITEM_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_GET_ITEM_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_GET_ITEM_METHOD_FAILURE',

  PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_REQUEST',
  PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_FAILURE',
  PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_CLEAR = '@repositories/PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_CLEAR',

  PROPERTY_REGISTRATION_CREATE_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_CREATE_METHOD_REQUEST',
  PROPERTY_REGISTRATION_CREATE_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_CREATE_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_CREATE_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_CREATE_METHOD_FAILURE',
  PROPERTY_REGISTRATION_CREATE_METHOD_CLEAR = '@repositories/PROPERTY_REGISTRATION_CREATE_METHOD_CLEAR',

  PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_REQUEST',
  PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_FAILURE',

  PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_REQUEST',
  PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_FAILURE',

  PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_REQUEST',
  PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_FAILURE',

  PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_REQUEST',
  PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_FAILURE',

  PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_REQUEST',
  PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_FAILURE',

  PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_REQUEST',
  PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_FAILURE',

  PROPERTY_REGISTRATION_PATCH_STEP_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_PATCH_STEP_METHOD_REQUEST',
  PROPERTY_REGISTRATION_PATCH_STEP_METHOD_SUCCESS = '@repositories/PROPERTY_REGISTRATION_PATCH_STEP_METHOD_SUCCESS',
  PROPERTY_REGISTRATION_PATCH_STEP_METHOD_FAILURE = '@repositories/PROPERTY_REGISTRATION_PATCH_STEP_METHOD_FAILURE',

  PROPERTY_REGISTRATION_PATCH_STEP_LOCAL_METHOD_REQUEST = '@repositories/PROPERTY_REGISTRATION_PATCH_STEP_LOCAL_METHOD_REQUEST',

  PROPERTY_REGISTRATION_METHOD_CLEAR = '@repositories/PROPERTY_REGISTRATION_METHOD_CLEAR',
}

export interface IRequestGetDownloadFilePropertyRegistration {
  idFile: string;
}

export interface IResponseGetDownloadFilePropertyRegistration {
  file: string;
}

export interface IRequestCreate {
  operation: string;
  cpaeCase: string;
  type: string;
}

export interface IRequestPostUploadFile {
  caseIdSales: string;
  type: string;
  step: string;
  filename: string;
  filedata: string;
}

export interface IRequestGetValidate {
  case: string;
}

export interface IRequestSetStep {
  case: string;
  step: string;
}

export interface IResponseGetValidate {
  id: string;
  case: string;
  owner: string;
  address: string;
  type: string;
  operation: string;
  FCPA: boolean;
  FCPE: boolean;
  savings: number;
}

export interface IStep {
  name: string;
  number: 1 | 2 | 3 | 4 | 5 | 6;
  substep: string;
  status: string;
}

export interface IResponseGetList {
  id: string;
  caseNumber: string;
  step: IStep;
  status: string;
  type: string;
  caseCpae: {
    id: string;
    caseNumber: string;
  };
}

export interface IResponseGetItem {
  id: string;
  caseNumber: string;
  step: {
    name: string;
    number: 1 | 2;
    substep: string;
  };
}

export interface IError {
  message?: string;
  status: number;
  state: boolean;
}

export interface IWaterProjectInformation {
  dateDeliveryImovel: string;
  lowerReservoir: {
    has: boolean | null;
    flow?: string;
    justification?: string;
  };
  superiorReservoir: {
    has: boolean | null;
    flow?: string;
    justification?: string;
  };
  waterCastle: {
    has: boolean | null;
    flow?: string;
    justification?: string;
  };
  enterpriseWaterTotalFlow: {
    servesThreeDays: boolean | null;
    justification?: string;
  };
  waterDistribution: {
    madeByGravity: boolean | null;
    justification?: string;
  };
  extensionEntrySupply: {
    DN: string;
    justification?: string;
  };
}

export interface ISewageProjectInformation {
  dateDeliveryImovel: string;
  toiletsNumber: string;
  hasKitchen: boolean;
  hasWashingTank: boolean;
  hasWashingMachine: boolean;
  internalHouseholdExtension: {
    diameter: string;
    justification?: string;
  };
  greaseTrap: {
    length: string;
    width: string;
    depth: string;
  };
  soapBox: {
    length: string;
    width: string;
    depth: string;
  };
  internalInspectionBox: {
    length: string;
    width: string;
    depth: string;
  };
  rainWaterTank: {
    length: string;
    width: string;
    depth: string;
  };
}

interface IInvolvedData {
  propertyTechnicalResponsible: {
    name: string;
    phone: string;
    address: string;
    email: string;
  };
  workPermitApplicant: {
    name: string;
    email: string;
  };
  technicalManagerFCP: {
    name: string;
    email: string;
  };
  installationsExecutionTechnicalResponsible: {
    name: string;
    email: string;
  };
}

interface ILicensing {
  processNumber: string;
  licenseNumber: string;
  justification?: string;
}

export interface IProjectAndLicenseInformationFCP {
  fcpId?: string;
  isRevision?: boolean;
  projectInformation: IWaterProjectInformation | ISewageProjectInformation;
  involvedData: IInvolvedData;
  licensing: ILicensing;
  accordingly: boolean;
  type: FCPTypes;
}
export interface IApprovedDocuments {
  PROJECT_APPROVED_SUPPLY?: string;
  PROJECT_APPROVED_EXHAUSTION?: string;
  AIO?: string;
  SETTLE_IN?: string;
}

export type FCPTypes = 'FCPA' | 'FCPE';

export interface IGetProjectAndLicenseInformationFCP {
  id: string;
  step: IStep;
  caseNumber: string;
  realStep: IStep;
  projectInformation: IWaterProjectInformation | ISewageProjectInformation;
  involvedData: IInvolvedData;
  licensing: ILicensing;
  accordingly: boolean;
  approvedDocuments: IApprovedDocuments;
  attachments: IAttachmentsResponseListCasesFCP[];
  reviewDescription?: string;
  type: FCPTypes;
  caseCpae: {
    id: string;
    caseNumber: string;
  };
  details: {
    materialInspectionDates: {
      initial?: string;
      final?: string;
      chosen?: string;
    };
    fcp: any;
  };
}

export interface IAttachmentsResponseListCasesFCP {
  id: string;
  url: string;
  key: string;
  type: string;
  review?: boolean;
  review_description?: string;
  step: string;
}

export interface IRequestPatchProtocolPropertyRegistration {
  caseId: string;
  caseFcp: string;
  materialInspectionDate?: string;
}

export interface IRequestGetFileCodesPropertyRegistration {
  case: string;
  type: string;
  documentType:
    | 'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL'
    | 'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL'
    | 'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT'
    | 'CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL'
    | 'CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE'
    | 'FINAL_INTERNAL_SUPPLY_PROJECT';
}

export interface IRequestCreateFileCodesPropertyRegistration {
  caseIdSales: string;
  type: string;
  processNumber: string;
  documentType:
    | 'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL'
    | 'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL'
    | 'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT'
    | 'CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL'
    | 'CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE'
    | 'FINAL_INTERNAL_SUPPLY_PROJECT';
}

export interface IResponseGetFileCodesPropertyRegistration {
  id: string;
  caseIdSales: string;
  type: FCPTypes;
  documentType:
    | 'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL'
    | 'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL'
    | 'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT'
    | 'CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL'
    | 'CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE'
    | 'FINAL_INTERNAL_SUPPLY_PROJECT';
  code: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRepositoriesStatePropertyRegistration {
  readonly dataList: IResponseGetList[] | null;
  readonly loadingList: boolean | null;
  readonly erroList: IError | null;

  readonly data: IResponseGetList | null;
  readonly loadingData: boolean | null;
  readonly erroData: IError | null;

  readonly dataItem: IGetProjectAndLicenseInformationFCP | null;
  readonly loadingItem: boolean | null;

  readonly loadingUploadFile: boolean | null;
  readonly errorUploadFile: IError | null;

  readonly loadingPatch: boolean | null;
  readonly errorPatch: IError | null;

  readonly dataValidate: IResponseGetValidate | null;
  readonly loadingValidate: boolean | null;
  readonly erroValidate: IError | null;

  readonly fileCodes: {
    CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL: IResponseGetFileCodesPropertyRegistration[];
    CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL: IResponseGetFileCodesPropertyRegistration[];
    DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT: IResponseGetFileCodesPropertyRegistration[];

    CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL: IResponseGetFileCodesPropertyRegistration[];
    CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE: IResponseGetFileCodesPropertyRegistration[];
    FINAL_INTERNAL_SUPPLY_PROJECT: IResponseGetFileCodesPropertyRegistration[];
  };
  readonly errorFileCodes: IError | null;
  readonly loadingFileCodes: boolean | null;

  readonly errorDownloadFile: IError | null;
  readonly loadingDownloadFile: boolean | null;
}
