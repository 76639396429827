import React, { FC } from 'react';
import ReactGA from 'react-ga4';

import {
  ButtonNegotiationPayment,
  ButtonPromotionalDiscounts,
  Container,
  ContainerButtons,
  NextLabelButtonPayment,
  Text,
  TextButtonDiscounts,
  TextButtonPayment,
} from './styles';
import formatter from '~/utils/formatter';
import { ArrowIcon, DiscountIcon } from '../../Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const SummaryDetails: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );

  return (
    <Container>
      <Text>
        Valor total:{' '}
        {formatter.formatCurrency(dataDischargeDebts?.info?.total?.amount || 0)}
      </Text>
      {(dataDischargeDebts?.info?.total?.amount || 0) > 0 && (
        <ContainerButtons>
          <ButtonPromotionalDiscounts type="button">
            <NextLabelButtonPayment>
              <div>
                <DiscountIcon />
              </div>
              <TextButtonDiscounts>
                Descontos {!isMobile && 'promocionais'} de até 50%
              </TextButtonDiscounts>
            </NextLabelButtonPayment>
          </ButtonPromotionalDiscounts>
          <ButtonNegotiationPayment
            type="button"
            onClick={() => {
              ReactGA.event({
                category: 'Negociação de dívidas',
                action:
                  '[Negociação de dívidas][Tela de dívidas] Negociar pendências',
              });
              Navigation.navigate(RouteName.DEBTNEGOTIATIONTYPEPAYMENT);
            }}
          >
            <NextLabelButtonPayment>
              <TextButtonPayment>Negociar pendências</TextButtonPayment>
              <div>
                <ArrowIcon />
              </div>
            </NextLabelButtonPayment>
          </ButtonNegotiationPayment>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default SummaryDetails;
