import React from 'react';

const SuccessIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5129 1.2381C13.7894 0.983631 14.1573 0.842952 14.5391 0.845744C14.9208 0.848536 15.2865 0.994582 15.559 1.25306C15.8315 1.51155 15.9894 1.86225 15.9995 2.23116C16.0096 2.60008 15.871 2.95836 15.6131 3.2304L7.78171 12.6986C7.64705 12.8388 7.48452 12.9513 7.30384 13.0294C7.12317 13.1075 6.92805 13.1496 6.73017 13.1532C6.53229 13.1567 6.3357 13.1216 6.15215 13.05C5.96861 12.9785 5.80188 12.8718 5.66194 12.7365L0.468524 7.71592C0.323895 7.58564 0.207893 7.42853 0.127436 7.25397C0.0469793 7.07941 0.00371646 6.89097 0.000229091 6.69989C-0.00325828 6.50881 0.0331013 6.31902 0.107138 6.14182C0.181175 5.96462 0.291372 5.80366 0.431156 5.66852C0.570941 5.53339 0.737448 5.42686 0.920745 5.35529C1.10404 5.28371 1.30037 5.24856 1.49803 5.25194C1.69568 5.25531 1.89061 5.29713 2.07118 5.37491C2.25175 5.45269 2.41427 5.56483 2.54903 5.70465L6.65902 9.67597L13.4756 1.27984C13.4879 1.26523 13.501 1.2513 13.5149 1.2381H13.5129Z"
        fill="#37B482"
      />
    </svg>
  );
};

export { SuccessIcon };
