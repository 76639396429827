import React from 'react';

interface IProps {
  color?: string;
}

const EntrepreneurUser = ({ color = '#37B4C8' }: IProps) => {
  return (
    <svg
      width="56"
      height="67"
      viewBox="0 0 56 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 45.8421C12.53 45.8421 0 52.1542 0 59.9474V67H56V59.9474C56 52.1542 43.47 45.8421 28 45.8421ZM14 24.6842C14 28.4252 15.475 32.0129 18.1005 34.6581C20.726 37.3034 24.287 38.7895 28 38.7895C31.713 38.7895 35.274 37.3034 37.8995 34.6581C40.525 32.0129 42 28.4252 42 24.6842M26.25 0C25.2 0 24.5 0.740526 24.5 1.76316V12.3421H21V3.52632C21 3.52632 13.125 6.55895 13.125 16.75C13.125 16.75 10.5 17.2437 10.5 21.1579H45.5C45.325 17.2437 42.875 16.75 42.875 16.75C42.875 6.55895 35 3.52632 35 3.52632V12.3421H31.5V1.76316C31.5 0.740526 30.835 0 29.75 0H26.25Z"
        fill={color}
      />
    </svg>
  );
};

export default EntrepreneurUser;
