/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import configsAppJsonImport from '~/configs/app.config';

import { IState } from '~/store/modules/types';
import { Breakpoints } from '~/styles/breakpoints';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { Margin } from '~/components/Margin';

import { Color } from '~/styles';
import {
  Main,
  ContainerCardInvoice,
  HeaderStatus,
  TitlePaymentMethods,
  ContentWrapper,
  ContainerBreadCrumb,
  InvoiceAmount,
  ContainerButtons,
  ContainerStatus,
  TextStatus,
  ContainerQRCode,
  ContainerPositionBorderQRCode,
  ContainerMessage,
  ButtonCodeInvoice,
  ContainerButtonsInvoice,
  ContentSinglePayment,
} from './styles';
import { statusColor } from '~/utils/invoiceStatus';

import {
  getEnrollmentsInvoicesDownloadRequest,
  getEnrollmentsInvoicesGroupRequest,
  getErrorCloseEnrollmentsInvoicesDownload,
} from '~/store/modules/enrollmentsInvoices/actions';
import {
  getEnrollmentsPaymentReceiptRequest,
  getEnrollmentsSituationPixRequest,
  postErrorCloseEnrollmentsPayment,
} from '~/store/modules/enrollmentsPayment/actions';
import DownloadSVG from '~/assets/Icons/DownloadIcon';
import { BreadCrumb } from '~/components/BreadCrumb';
import formatter from '~/utils/formatter';
import { BorderQrCode } from './icons';
import { QRCode } from '~/components/QRCode';
import { ButtonText } from '~/components/ButtonText';
import { InvoiceDetails } from './InvoiceDetails';
import { PixCodeCopy } from './PixCodeCopy';
import StatusInfo from './StatusInfo';
import { CopySuccessIcon } from '../EasyDuplicate/Icons';
import { ContainerSinglePayment, PaymentButton } from '../Invoices/styles';
import { InvoiceReceiptIcon, PaymentIcon } from '../Invoices/icons';
import LoadingComponent from '~/components/Loading';
import { checkToggle } from '~/utils/toggles';
import { TextInvoicePixCode } from './PixCodeCopy/styles';

const configsAppJson = configsAppJsonImport as any;

const SimplifiedInvoiceNew: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataDetail = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataDetail,
  );
  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loading = useSelector((item: IState) => item.enrollmentsInvoices.loading);
  const loadingDownload = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDownload,
  );
  const loadingVoucher = useSelector(
    (item: IState) => item.enrollmentsPayment.loadingVoucher,
  );
  const infoGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.info,
  );
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);

  const [isClickedDownload, setIsClickedDownload] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [isClickedDownloadReceipt, setIsClickedDownloadReceipt] =
    useState<boolean>(false);
  const [downloadedReceipt, setDownloadedReceipt] = useState<boolean>(false);
  const [groupPayment, setGroupPayment] = useState<boolean>(true);

  const verifyToggles = async () => {
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const handleDownload = () => {
    ReactGA.event({
      category: 'Faturas',
      action: '[AL][Web][Home][Fatura Detalhada]Baixar 2V',
    });

    setIsClickedDownload(true);
    dispatch(getEnrollmentsInvoicesDownloadRequest(dataDetail?.invoice_id || 0));
  };

  const handleDownloadReceipt = () => {
    ReactGA.event({
      category: 'Faturas',
      action: '[AL][Web][Home][Fatura Detalhada]Baixar Comprovante',
    });

    setIsClickedDownloadReceipt(true);
    dispatch(
      getEnrollmentsPaymentReceiptRequest({
        enrollmentId: registration || '',
        invoiceId: dataDetail?.invoice_id || 0,
      }),
    );
  };

  let stopAction = false;
  let countVerificationAttempts = 0;

  const checkedPix = async () => {
    if (
      dataDetail?.status.label === 'PENDENTE' ||
      dataDetail?.status.label === 'VENCIDA'
    ) {
      await new Promise((resolve) => {
        if (stopAction === true) return resolve(true);
        if (configsAppJson?.pixVerificationAttempts === countVerificationAttempts)
          Navigation.navigate(RouteName.INVOICES);

        dispatch(
          getEnrollmentsSituationPixRequest({
            enrollmentId: registration || '',
            barCodeNumber: dataDetail?.bar_code_number || '',
          }),
        );

        countVerificationAttempts++;
        setTimeout(() => checkedPix(), 10000);
        resolve(false);
      });
    }
  };

  useEffect(() => {
    checkedPix();
    dispatch(postErrorCloseEnrollmentsPayment());
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    return () => {
      stopAction = true;
    };
  }, []);

  useEffect(() => {
    if (!loadingDownload && isClickedDownload) {
      setDownloaded(true);

      setTimeout(() => {
        setIsClickedDownload(false);
        setDownloaded(false);
      }, 5000);
    }
  }, [loadingDownload, isClickedDownload]);

  useEffect(() => {
    if (!loadingVoucher && isClickedDownloadReceipt) {
      setDownloadedReceipt(true);

      setTimeout(() => {
        setIsClickedDownloadReceipt(false);
        setDownloadedReceipt(false);
      }, 5000);
    }
  }, [loadingVoucher, isClickedDownloadReceipt]);

  const verifyIsPayment = () => {
    return (
      dataDetail?.status.label === 'PROCESSANDO' ||
      dataDetail?.status.label === 'QUITADA'
    );
  };

  const verifyPixCode = () => dataDetail?.qr_code_key;

  return (
    <>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Faturas',
              active: false,
              onClick: () => Navigation.navigate(RouteName.INVOICES),
            },
            {
              label: 'Fatura completa',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Main>
        <ContainerCardInvoice>
          <HeaderStatus color={statusColor(dataDetail?.status?.label || '', true)}>
            ‎
          </HeaderStatus>
          <ContainerStatus>
            <TextStatus color={statusColor(dataDetail?.status?.label || '', true)}>
              {dataDetail?.status?.label.toLowerCase()}
            </TextStatus>
            <InvoiceAmount
              color={statusColor(dataDetail?.status?.label || '', true)}
            >
              {formatter.formatCurrency(dataDetail?.amount)}
            </InvoiceAmount>
          </ContainerStatus>
          <TitlePaymentMethods>
            <h1>Dados de pagamento</h1>
          </TitlePaymentMethods>
          {infoGroup && infoGroup?.opened > 1 && groupPayment && (
            <ContentSinglePayment>
              <ContainerSinglePayment>
                <div>
                  <p>{infoGroup?.opened} faturas em aberto!</p>
                  <p>Parcele suas faturas em aberto em até 12X sem juros!</p>
                </div>
                <PaymentButton
                  type="button"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Pagamento Único',
                      action: '[AL][Web][Home][Fatura Detalhada]Pg.Único',
                    });
                    dispatch(getEnrollmentsInvoicesGroupRequest());
                  }}
                >
                  Pagamento único
                  <PaymentIcon />
                </PaymentButton>
              </ContainerSinglePayment>
            </ContentSinglePayment>
          )}
          <ContentWrapper hasPix={!!verifyPixCode() && !verifyIsPayment()}>
            {verifyPixCode() && !verifyIsPayment() && (
              <div className="pix-code">
                <TextInvoicePixCode>
                  Escaneie o Qr code ou copie e cole o código abaixo:
                </TextInvoicePixCode>
                <Margin height={16} />
                <ContainerQRCode>
                  <ContainerPositionBorderQRCode position="top-left">
                    <BorderQrCode />
                  </ContainerPositionBorderQRCode>
                  <ContainerPositionBorderQRCode position="top-right">
                    <BorderQrCode />
                  </ContainerPositionBorderQRCode>
                  <QRCode codeValue={dataDetail?.qr_code_key || ''} />
                  <ContainerPositionBorderQRCode position="bottom-left">
                    <BorderQrCode />
                  </ContainerPositionBorderQRCode>
                  <ContainerPositionBorderQRCode position="bottom-right">
                    <BorderQrCode />
                  </ContainerPositionBorderQRCode>
                </ContainerQRCode>
                <PixCodeCopy qr_code_key={dataDetail?.qr_code_key || ''} />
              </div>
            )}
            <div>
              <InvoiceDetails data={dataDetail} />
              <ContainerButtonsInvoice
                fullButton={!verifyIsPayment()}
                receiptExists={dataDetail?.type_payment === 'CARD_DIGIIGUA'}
              >
                {dataDetail?.type_payment !== 'CARD_DIGIIGUA' && <br />}
                <ButtonCodeInvoice
                  type="button"
                  onClick={handleDownload}
                  clicked={loadingDownload}
                  copied={downloaded}
                  receiptExists
                >
                  {!loadingDownload && (
                    <>
                      <p>
                        {downloaded ? 'Baixado com sucesso!' : 'Baixar segunda via'}
                      </p>
                      <Margin width={8} />
                      <div>
                        {downloaded ? (
                          <CopySuccessIcon />
                        ) : (
                          <DownloadSVG color="#000" />
                        )}
                      </div>
                    </>
                  )}
                </ButtonCodeInvoice>
                {dataDetail?.type_payment === 'CARD_DIGIIGUA' && (
                  <ButtonCodeInvoice
                    type="button"
                    onClick={handleDownloadReceipt}
                    clicked={loadingVoucher}
                    copied={downloadedReceipt}
                    receiptExists
                  >
                    {!loadingVoucher && (
                      <>
                        <p>
                          {downloadedReceipt
                            ? 'Baixado com sucesso!'
                            : 'Baixar comprovante '}
                        </p>
                        <Margin width={8} />
                        <div>
                          {downloadedReceipt ? (
                            <CopySuccessIcon />
                          ) : (
                            <InvoiceReceiptIcon />
                          )}
                        </div>
                      </>
                    )}
                  </ButtonCodeInvoice>
                )}
              </ContainerButtonsInvoice>
            </div>
          </ContentWrapper>
          {!verifyIsPayment() && (
            <ContainerMessage>
              <StatusInfo status="warning">
                Após o pagamento PIX, sua fatura constará como paga em até 24h.
              </StatusInfo>
            </ContainerMessage>
          )}
          <Margin height={isMobile ? 23 : 36} />
          <ContainerButtons>
            <ButtonText
              text="Voltar"
              typeBtn="content"
              Color={Color.blueSeconde}
              backgroundColor={Color.white}
              onClick={() => Navigation.goBack()}
            />
            {!verifyIsPayment() && (
              <ButtonText
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.blueSeconde}
                onClick={() => {
                  ReactGA.event({
                    category: 'Faturas',
                    action: '[AL][Web][Fatura Detalhada]Ir para o pagamento',
                  });

                  Navigation.navigate(RouteName.SIMPLIFIEDINVOICEPAYMENTMETHODS, {
                    ...dataDetail,
                  });
                }}
                nextIcon
              >
                Formas de pagamento
              </ButtonText>
            )}
          </ContainerButtons>
        </ContainerCardInvoice>
        <Margin height={31} />
      </Main>
    </>
  );
};

export default SimplifiedInvoiceNew;
