import React, { FC, ButtonHTMLAttributes } from 'react';
import { Wrapper, ButtonIconLeft, Msg } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  color?: 'primary' | 'secondary' | 'white' | 'error';
  msg?: string;
  showMsg?: boolean;
}

const ButtonIcon: FC<IProps> = ({ children, color, showMsg, msg, ...props }) => {
  return (
    <Wrapper>
      <ButtonIconLeft {...props} color={color}>
        {children}
      </ButtonIconLeft>
      {showMsg ? <Msg>{msg}</Msg> : null}
    </Wrapper>
  );
};

export { ButtonIcon };
