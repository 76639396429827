import React, { FC } from 'react';

import { Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';

interface IProps {
  onClose: () => void;
}

const ModalError: FC<IProps> = ({ onClose }) => {
  return (
    <Main>
      <Margin height={39} />
      <p>
        Não foi possivel processar sua solicitação. <br />
        Por favor tente mais tarde!
      </p>
      <Margin height={70} />
      <ButtonText
        text="Entendi"
        typeBtn="content"
        backgroundColor={Color.blueSeconde}
        Color={Color.white}
        onClick={onClose}
        style={{ maxWidth: '340px', height: '68px' }}
      />
    </Main>
  );
};

export default ModalError;
