import React, { CSSProperties, FC } from 'react';
import { CardStyled } from './styles';
import LoginSVG from '~/assets/Icons/Login';

interface Props {
  children: React.ReactNode;
  title?: string;
  label?: string;
  iconType?: 'login';
  isLarge?: boolean;
  style?: CSSProperties;
}

const icons = {
  login: <LoginSVG />,
};

export const Card: FC<Props> = ({
  children,
  title,
  label,
  iconType,
  isLarge,
  style,
}) => {
  return (
    <CardStyled isLarge={isLarge} style={{ ...style }}>
      {title && (
        <h1>
          {iconType && icons[iconType]}
          {title}
        </h1>
      )}
      {label && <p className="description-card">{label}</p>}
      {children}
    </CardStyled>
  );
};
