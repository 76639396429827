import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: ${Color.blueIntense};
    text-align: center;
    font-size: 12px;
    font-weight: 600;

    :last-of-type {
      display: none;
      font-weight: 500;
      height: fit-content;
      max-width: 180px;

      a {
        color: ${Color.blueSeconde};
        font-weight: 500;
      }
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    p,
    a {
      font-size: 14px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;

      :last-of-type {
        display: inline-block;
      }
    }
  }
`;
