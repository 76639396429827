import React from 'react';
import styled from 'styled-components';

interface IProp {
  className?: 'left' | 'right';
  onClick?: () => void;
  children?: React.ReactNode;
}

const Btn = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 45%;
  z-index: 10;
  &:before {
    content: none !important;
  }
  &.slick-disabled {
    display: none;
  }
  &.slick-prev {
    left: 0px;
  }
  &.slick-next {
    right: 0px;
  }
  @media (max-width: 768px) {
    top: 45%;
    &.slick-prev {
      left: 0px;
    }
    &.slick-next {
      right: 10px;
    }
  }
`;

const BtnArrow = ({ className, onClick, children }: IProp) => {
  return (
    <Btn className={`btn-${className}`} onClick={onClick}>
      {children}
    </Btn>
  );
};

export default BtnArrow;
