import styled from 'styled-components';
import { Color } from '~/styles';

interface Props {
  checked: boolean | undefined;
  alignTop?: boolean;
}

export const Checkbox = styled.div<Props>`
  display: flex;
  align-items: ${(p) => (p.alignTop ? 'flex-start' : 'center')};
`;

export const CheckboxHidden = styled.input.attrs({ type: 'checkbox' })<Props>`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const CheckboxLabel = styled.label<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${Color.gray};
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;
  span {
    color: ${Color.blueIntense};
    font-weight: 400;
  }
`;

export const CheckboxStyled = styled.label<Props>`
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(Props) => (Props.checked ? Color.blueIntense : Color.gray)};
  border: 2px solid ${Color.gray};
  border-radius: 50%;
  cursor: pointer;
`;
