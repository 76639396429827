import React, { FC, useEffect, useState } from 'react';

import { Container } from '~/common';
import {
  Card,
  ContainerBreadCrumb,
  ContainerButton,
  ContainerTitle,
  Content,
  Line,
  Main,
  Row,
  SelectContainer,
  Subtitle,
  TitleLine,
  Wrapper,
} from './styles';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import StatusInfo from '../../components/StatusInfo';
import ButtonBar from '../../components/ButtonBar';
import { InputSelect } from '~/components/InputSelect';
import { listOperationsRequst, setLocation } from '~/store/modules/dpadpe/actions';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';

export const EnterpriseViabilityNewProcess: FC = () => {
  const dispatch = useDispatch();
  const [location, setInputLocation] = useState('');
  const [process, setProcess] = useState('');
  const [locationsOptions, setLocationsOptions] = useState<any>([
    { label: 'Selecione', value: null },
  ]);
  const [locationId, setLocationId] = useState<number | undefined>(0);
  const loading = useSelector((item: IState) => item.dpadpe.loading);
  const operations = useSelector((item: IState) => item.dpadpe.operations);

  useEffect(() => {
    dispatch(listOperationsRequst());
  }, []);

  useEffect(() => {
    if (operations)
      setLocationsOptions([{ label: 'Selecione', value: '', id: 0 }, ...operations]);
  }, [operations]);

  useEffect(() => {
    if (location !== '') {
      setLocationId(operations.find((e) => e.name === location)?.id);
    } else {
      setLocationId(0);
    }
  }, [location]);

  return (
    <Main>
      {loading && <LoadingComponent />}
      <Container size="newLarge">
        <Wrapper>
          <Margin height={18} />
          <ContainerBreadCrumb>
            <BreadCrumb
              links={[
                {
                  label: 'Home',
                  active: false,
                  onClick: () => Navigation.navigate(RouteName.HOME),
                },
                {
                  label: 'Viabilidade de empreendimento',
                  active: true,
                },
                {
                  label: 'CPAE',
                  active: true,
                },
              ]}
            />
          </ContainerBreadCrumb>
          <Margin height={24} />
          <Card>
            <Margin height={30} />
            <Content>
              <h5>Consulta de possibilidade de abastecimento (CPAE)</h5>
            </Content>
            <Margin height={40} />
            <ContainerTitle>
              <Line />
              <TitleLine>Dados do empreendimento</TitleLine>
            </ContainerTitle>
            <Margin height={34} />
            <Content>
              <StatusInfo status="info">
                Para demais regiões, favor entrar em contato com a sua operação.
              </StatusInfo>
              <Margin height={24} />
              <Subtitle>
                Para começar, favor informe em qual cidade estará localizado o seu
                empreendimento:
              </Subtitle>
              <Margin height={24} />
              <Row>
                <SelectContainer>
                  <InputSelect
                    style={{ fontWeight: 400, fontSize: '14px' }}
                    label="Localidade"
                    values={locationsOptions}
                    onChange={(ev: any) => setInputLocation(ev.target.value)}
                  />
                </SelectContainer>{' '}
                <Margin width={40} />
                <SelectContainer>
                  <InputSelect
                    style={{
                      fontWeight: 400,
                      fontSize: '14px',
                      backgroundColor: locationId === 0 ? '#F2F2F2' : '#fff',
                    }}
                    label="Processo"
                    disabled={locationId === 0}
                    values={
                      operations && locationId && locationId !== 0
                        ? [
                            { label: 'Selecione' },
                            ...operations[locationId - 1].processTypes,
                          ]
                        : [{ label: 'Selecione' }]
                    }
                    onChange={(ev: any) => setProcess(ev.target.value)}
                  />
                </SelectContainer>
              </Row>
              <ContainerButton>
                <ButtonBar
                  backOnClick={() =>
                    Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)
                  }
                  nextOnClick={() => {
                    dispatch(setLocation(location));

                    const options: any = {
                      cpae: () =>
                        Navigation.navigate(RouteName.SUPPLYPOSSIBILITYCONSULTATION),
                      oc: () => Navigation.navigate(RouteName.CUSTOMEROBLIGATION),
                      fcp: () => Navigation.navigate(RouteName.PROPERTYREGISTRATION),
                      default: () => null,
                    };

                    const execute = options[process] || options.default;

                    return execute();
                  }}
                  nextBackground={
                    locationId === 0 || process === ''
                      ? Color.grayLightSeconde
                      : Color.blueSeconde
                  }
                  nextDisabled={locationId === 0 || process === ''}
                  nextLoading={false}
                />
              </ContainerButton>
            </Content>
            <Margin width={24} />
          </Card>
        </Wrapper>
      </Container>
    </Main>
  );
};
