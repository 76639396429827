import { Dispatch } from 'redux';
import { RepositoriesTypesCookies } from './types';

const saveCookies = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCookies.COOKIES_SAVE_ACCEPTED,
  });
};

const deleteCookies = () => async (dispatch: Dispatch) => {
  console.log('deletear');

  return dispatch({
    type: RepositoriesTypesCookies.COOKIES_DELETE_ACCEPTED,
  });
};

export { saveCookies, deleteCookies };
