import React from 'react';

const SubscribesInfoIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.373047" width="48" height="48" rx="8" fill="#37B482" />
      <path
        d="M15.0752 18.4502C13.1114 20.415 12.0056 23.0781 12 25.8566C11.9944 28.635 13.0893 31.3026 15.0452 33.2753C17.2502 28.1736 21.1801 23.9121 26.0851 21.3762C21.8515 24.9597 18.9936 29.903 18.0001 35.3611C21.9001 37.2067 26.7001 36.5315 29.9251 33.3053C35.145 28.0835 36 12.373 36 12.373C36 12.373 20.2951 13.2283 15.0752 18.4502Z"
        fill="white"
      />
    </svg>
  );
};

export { SubscribesInfoIcon };
