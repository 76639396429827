import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

const InfoIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#004B55" />
      <path
        d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 48L40 38"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0117 32L39.9938 32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const WarningIcon = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <svg
      width={isMobile ? '50' : '64'}
      height={isMobile ? '50' : '64'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32.0039" r="32" fill="#FAB419" />
      <path
        d="M32 27.2051V35.2051"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9991 47.0611H22.3031C16.7511 47.0611 14.4311 43.0931 17.1191 38.2451L22.1111 29.2531L26.8151 20.8051C29.6631 15.6691 34.3351 15.6691 37.1831 20.8051L41.8871 29.2691L46.8791 38.2611C49.5671 43.1091 47.2311 47.0771 41.6951 47.0771H31.9991V47.0611Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9844 40.0039H31.9987"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { InfoIcon, WarningIcon };
