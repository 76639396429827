import styled from 'styled-components';
import { Breakpoints } from '~/styles';

interface IPropsContainer {
  backgroundColor: string;
}

export const Container = styled.div<IPropsContainer>`
  height: 194px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.035em;
  color: #004b55;
  padding: 0 24px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      width: fit-content;
    }
  }

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    margin-top: 26px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 88px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px 0 70px;

    button {
      max-width: 200px;
      margin-top: 0;
    }
  }
`;
