import React from 'react';
import { Container } from './styles';
import SummaryDetails from './SummaryDetails';
import PendingList from './PendingList';

const PendingIssuesAvailableNegotiation = () => {
  return (
    <Container>
      <SummaryDetails />
      <PendingList />
    </Container>
  );
};

export default PendingIssuesAvailableNegotiation;
