/* eslint-disable no-unsafe-optional-chaining */
import React, { FC } from 'react';

import {
  BoxInput,
  Container,
  ContainerInputs,
  DeadlineConclusionText,
  ProtocolText,
  Separator,
  StatusText,
} from './styles';
import { history } from '~/store';
import {
  IOrderService,
  IOrderServiceInput,
} from '~/store/modules/orderServices/types';
import moment from 'moment';
import { InputTextArea } from '~/components/InputTextArea';
import { Input } from '~/components/Input';
import { hasTankerTruckInputs } from '~/views/ReportLackWater';

const FormDataProtocol: FC = () => {
  const data = history?.location?.state as IOrderService;

  const renderInputField = (input: IOrderServiceInput) => {
    const supply_tanker_truck__value = data?.info?.inputs.find(
      (dataInput) => dataInput?.name === 'supply_tanker_truck',
    )?.value;

    const supply_tanker_truck_hidden_inputs =
      hasTankerTruckInputs(input) &&
      supply_tanker_truck__value?.toUpperCase() !== 'SIM';

    if (supply_tanker_truck_hidden_inputs && input?.name !== 'supply_tanker_truck') {
      return null;
    }

    switch (input?.type) {
      case 'select':
        return supply_tanker_truck_hidden_inputs &&
          input?.name === 'supply_tanker_truck' ? (
          <Input
            key={input?.name}
            label={input?.label}
            name={input?.name}
            value={input.value}
            disabled
          />
        ) : (
          <BoxInput key={input?.name}>
            <Input
              label={input?.label}
              name={input?.name}
              value={input.value}
              disabled
            />
          </BoxInput>
        );
      case 'input':
        return (
          <BoxInput key={input?.name}>
            <Input
              label={input?.label}
              name={input?.name}
              value={input.value}
              disabled
            />
          </BoxInput>
        );

      case 'textarea':
        return (
          <InputTextArea
            key={input?.name}
            label={input?.label}
            name={input?.name}
            value={input.value}
            disabled
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container statusColor={data?.status.color}>
      <StatusText statusColor={data?.status.color}>{data?.status.label}</StatusText>
      <ProtocolText>
        Protocolo #{data?.protocol}
        {data?.expectedDate && (
          <>
            <br />
            <DeadlineConclusionText>
              Prazo para conclusão: {moment(data?.expectedDate).format('DD/MM/YYYY')}
            </DeadlineConclusionText>
          </>
        )}
      </ProtocolText>
      <Separator />
      <ContainerInputs>
        <BoxInput>
          <Input
            value={data?.serviceName}
            label="Tipo de problema"
            name="serviceName"
            disabled
          />
        </BoxInput>
        <BoxInput>
          <Input
            value={moment(data?.createdDate).format('DD/MM/YYYY')}
            label="Data da solicitação"
            name="createdDate"
            disabled
          />
        </BoxInput>
      </ContainerInputs>
      <ContainerInputs>
        <Input
          value={data?.info?.address.full_address}
          label="Endereço"
          name="full_address"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        <Input
          value={data?.info?.address.complement}
          label="Complemento"
          name="complement"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        {data?.info?.inputs?.map((input) => renderInputField(input))}
      </ContainerInputs>
    </Container>
  );
};

export default FormDataProtocol;
