import React from 'react';

function WalletEloSVG() {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 0C48.1207 0 62 13.8793 62 31C62 48.121 48.1207 62 31 62C13.8793 62 0 48.121 0 31C0 13.8793 13.8793 0 31 0ZM21.7997 28.7716L12.291 32.8694C12.276 32.704 12.2684 32.538 12.2682 32.3719C12.2682 29.364 14.7063 26.9259 17.714 26.9259C19.3415 26.9259 20.8019 27.6401 21.7997 28.7716ZM17.714 22.853C22.201 22.853 25.9622 25.9579 26.968 30.1361L23.1318 31.8135L23.1308 31.8055L19.2057 33.5298L9.79013 37.647C8.74753 36.0856 8.19244 34.2495 8.19532 32.3719C8.19532 27.1148 12.4569 22.853 17.714 22.853ZM24.3655 39.1813C20.9574 42.4159 16.4483 42.7335 12.4866 40.3283L14.7252 36.9248C16.9795 38.2724 19.2444 38.0539 21.5195 36.2675L24.3655 39.1813ZM28.2414 36.8248L28.2172 18.8242H31.6277V36.339C31.6277 36.5068 31.6485 36.6548 31.8689 36.7405L34.8357 37.8943L33.4991 41.3697L30.0237 39.8994C28.7059 39.3416 28.2438 38.5337 28.2414 36.8248ZM38.9851 32.2314C38.9851 33.9762 39.8083 35.5274 41.0866 36.5215L39.0062 40.1335C36.4906 38.4075 34.8398 35.5128 34.8398 32.2314C34.8398 29.1874 36.2617 26.4768 38.476 24.7227L40.6787 28.2925C39.6361 29.2823 38.9851 30.6805 38.9851 32.2314ZM42.5844 27.1249C43.1741 26.9129 43.796 26.8047 44.4226 26.8052C46.7483 26.8052 48.7319 28.2678 49.5066 30.3227L53.7275 29.9701C52.7086 25.7754 48.9305 22.6602 44.4226 22.6602C43.1884 22.6593 41.9657 22.8979 40.8223 23.3625L42.5844 27.1249ZM44.4181 37.6617C47.3302 37.6617 49.7065 35.3703 49.8441 32.4922L53.9857 32.6227C53.7789 37.7295 49.5757 41.8067 44.4184 41.8067C43.4065 41.8069 42.4011 41.6467 41.4395 41.332L43.0953 37.4984C43.5186 37.6043 43.9616 37.6617 44.4181 37.6617Z"
        fill="white"
      />
    </svg>
  );
}
export default WalletEloSVG;
