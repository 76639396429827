import React, { FC } from 'react';
import { CloseIcon } from '~/assets/Icons/CloseIcon';
import { Wrapper, Content, Header, IconButton, Box } from './styles';

interface IProps {
  children: any;
  handleClose?(): void;
}

const ModalPreview: FC<IProps> = ({ children, handleClose }) => {
  return (
    <Wrapper>
      <Content>
        <Header>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Box>{children}</Box>
      </Content>
    </Wrapper>
  );
};

export { ModalPreview };
