import React from 'react';
import * as S from './styles';
import CardContainer from '../../components/CardContainer';

const Notifications: React.FC = () => {
  return (
    <S.Main>
      <CardContainer title="Notificações">
        <div>hello</div>
      </CardContainer>
    </S.Main>
  );
};

export default Notifications;
