import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#004B55" />
      <path
        d="M24 12C17.3732 12 12 17.3732 12 24C12 30.6268 17.3732 36 24 36C30.6268 36 36 30.6268 36 24C36 17.3732 30.6268 12 24 12ZM24 30.9643C23.408 30.9643 22.9286 30.4848 22.9286 29.8929C22.9286 29.3009 23.408 28.8214 24 28.8214C24.592 28.8214 25.0714 29.3009 25.0714 29.8929C25.0714 30.4848 24.592 30.9643 24 30.9643ZM25.6848 25.0848C25.4422 25.1785 25.2335 25.3431 25.0859 25.5572C24.9382 25.7712 24.8585 26.0248 24.8571 26.2848V26.8929C24.8571 27.0107 24.7607 27.1071 24.6429 27.1071H23.3571C23.2393 27.1071 23.1429 27.0107 23.1429 26.8929V26.317C23.1429 25.6982 23.3223 25.0875 23.6759 24.5786C24.0214 24.0804 24.5036 23.7 25.0714 23.483C25.9821 23.1321 26.5714 22.3687 26.5714 21.5357C26.5714 20.3545 25.417 19.3929 24 19.3929C22.583 19.3929 21.4286 20.3545 21.4286 21.5357V21.7393C21.4286 21.8571 21.3321 21.9536 21.2143 21.9536H19.9286C19.8107 21.9536 19.7143 21.8571 19.7143 21.7393V21.5357C19.7143 20.483 20.175 19.5 21.0107 18.7688C21.8143 18.0643 22.875 17.6786 24 17.6786C25.125 17.6786 26.1857 18.067 26.9893 18.7688C27.825 19.5 28.2857 20.483 28.2857 21.5357C28.2857 23.0839 27.2652 24.4768 25.6848 25.0848Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export { InfoIcon };
