import React, { FC } from 'react';

import moment from 'moment';

import { Input } from '~/components/Input';
import formatter from '~/utils/formatter';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Border, ContainerBorder } from '../styles';

interface IProps {
  dataForm: any;
  setDataForm: any;
  dataFormErrors: any;
  setDataFormErrors: any;
}

const FormClient: FC<IProps> = ({
  dataForm,
  setDataForm,
  dataFormErrors,
  setDataFormErrors,
}) => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const userData = useSelector((item: IState) => item.enrollmentsUsers.data);

  const validateTel = () => {
    if (!dataForm.telefone)
      return setDataFormErrors({ ...dataFormErrors, telefone: '' });

    if (dataForm.telefone.length < 14) {
      return setDataFormErrors({ ...dataFormErrors, telefone: 'Telefone inválido' });
    }
    return setDataFormErrors({ ...dataFormErrors, telefone: '' });
  };

  const validateDate = () => {
    const isValidDate = Date.parse(dataForm.dataNascimento);

    if (!dataForm.dataNascimento || !isValidDate)
      return setDataFormErrors({
        ...dataFormErrors,
        dataNascimento: 'Campo obrigatório',
      });

    return setDataFormErrors({ ...dataFormErrors, dataNascimento: '' });
  };

  const validateRg = () => {
    if (!dataForm.rg)
      return setDataFormErrors({ ...dataFormErrors, rg: 'Campo obrigatório' });

    if (dataForm.rg.length < 3) {
      return setDataFormErrors({ ...dataFormErrors, rg: 'RG inválido' });
    }
    return setDataFormErrors({ ...dataFormErrors, rg: '' });
  };

  const validateEmpty = (key: string, value: string) => {
    if (!value)
      return setDataFormErrors({ ...dataFormErrors, [key]: 'Campo obrigatório' });

    return setDataFormErrors({ ...dataFormErrors, [key]: '' });
  };

  return (
    <>
      {userData?.cnp?.length === 11 ? (
        <>
          <div className="container-inputs">
            <Input
              value={dataForm.nomeCompleto}
              onChange={(ev) =>
                setDataForm({ ...dataForm, nomeCompleto: ev.target.value })
              }
              label="Nome completo*"
              name="nomeCompleto"
              disabled
            />
          </div>
          <div className="container-inputs">
            <Input
              value={dataForm.cnp}
              onChange={(ev) => {
                setDataForm({
                  ...dataForm,
                  cnp: formatter.cpfMask(ev.target.value),
                });
              }}
              label="CPF*"
              name="cpf"
              disabled
            />
            <Input
              value={dataForm.rg}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  rg: formatter.formatarNumber(ev.target.value) || '',
                })
              }
              label="RG*"
              name="rg"
              onBlur={validateRg}
              error={!!dataFormErrors?.rg}
              errorText={dataFormErrors?.rg}
              errorPosition="bottom"
              maxLength={9}
            />
          </div>
          <div className="container-inputs">
            <Input
              value={dataForm.dataNascimento}
              onChange={(ev) =>
                setDataForm({ ...dataForm, dataNascimento: ev.target.value })
              }
              label="Data de nascimento*"
              type="date"
              name="dataNascimento"
              max={moment(new Date()).format('YYYY-MM-DD')}
              onBlur={validateDate}
              error={!!dataFormErrors?.dataNascimento}
              errorText={dataFormErrors?.dataNascimento}
              errorPosition="bottom"
            />
            <Input
              value={dataForm.nomeMae}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  nomeMae: ev.target.value,
                })
              }
              label="Nome da mãe*"
              name="nomeMae"
              maxLength={100}
              onBlur={(ev: any) => validateEmpty('nomeMae', ev.target.value)}
              error={!!dataFormErrors?.nomeMae}
              errorText={dataFormErrors?.nomeMae}
              errorPosition="bottom"
            />
          </div>
        </>
      ) : (
        <div className="container-inputs">
          <Input
            value={dataForm.cnp}
            onChange={(ev) =>
              setDataForm({
                ...dataForm,
                cnp: formatter.cnpjMask(ev.target.value),
              })
            }
            label="CNPJ*"
            name="CNPJ"
            disabled
          />
          <Input
            value={dataForm.nomeCompleto}
            onChange={(ev) =>
              setDataForm({ ...dataForm, nomeCompleto: ev.target.value })
            }
            label="Razão social*"
            name="razaoSocial"
            onBlur={(ev: any) => validateEmpty('nomeCompleto', ev.target.value)}
            error={!!dataFormErrors?.nomeCompleto}
            errorText={dataFormErrors?.nomeCompleto}
            errorPosition="bottom"
          />
        </div>
      )}
      <ContainerBorder>
        <Border />
        <h2>Dados de contato</h2>
        <Border />
      </ContainerBorder>
      {userData?.cnp?.length === 14 ? (
        <>
          <div className="container-inputs">
            <Input
              value={dataForm.dataNascimento}
              onChange={(ev) =>
                setDataForm({ ...dataForm, dataNascimento: ev.target.value })
              }
              label="Data de nascimento*"
              type="date"
              name="dataNascimento"
              max={moment(new Date()).format('YYYY-MM-DD')}
            />
            <Input
              value={dataForm.email}
              onChange={(ev) => setDataForm({ ...dataForm, email: ev.target.value })}
              label="Email*"
              name="email"
              disabled
            />
          </div>
          <div className="container-inputs">
            <Input
              value={dataForm.celular}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  celular: formatter.formatarPhone(ev.target.value),
                })
              }
              label="Celular principal*"
              name="celular"
              disabled
            />
            <Input
              value={dataForm.telefone}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  telefone: formatter.formatarTel(ev.target.value),
                })
              }
              label="Telefone"
              name="telefone"
              onBlur={validateTel}
              error={!!dataFormErrors?.telefone}
              errorText={dataFormErrors?.telefone}
              errorPosition="bottom"
            />
          </div>
        </>
      ) : (
        <>
          <div className="container-inputs">
            <Input
              value={dataForm.email}
              onChange={(ev) => setDataForm({ ...dataForm, email: ev.target.value })}
              label="Email*"
              name="email"
              disabled
            />
            <Input
              value={dataForm.celular}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  celular: formatter.formatarPhone(ev.target.value),
                })
              }
              label="Celular principal*"
              name="celular"
              disabled
            />
          </div>
          <div className="container-inputs">
            <Input
              value={dataForm.telefone}
              onChange={(ev) =>
                setDataForm({
                  ...dataForm,
                  telefone: formatter.formatarTel(ev.target.value),
                })
              }
              label="Telefone"
              name="telefone"
              style={{ width: isLaptop ? 'calc(50% - 48px)' : undefined }}
              styleLabelError={{
                width: isLaptop ? 'calc(50% - 32px)' : undefined,
              }}
              onBlur={validateTel}
              error={!!dataFormErrors?.telefone}
              errorText={dataFormErrors?.telefone}
              errorPosition="bottom"
            />
          </div>
        </>
      )}
    </>
  );
};

export default FormClient;
