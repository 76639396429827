import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  label {
    margin-right: 24px;
    :last-of-type {
      margin-right: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: ${Color.blueIntense};
    }
  }
`;
