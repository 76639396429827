import React, { FC, ReactNode } from 'react';
import {
  CheckboxContainer,
  CheckmarkSVG,
  HiddenCheckbox,
  StyledCheckbox,
} from './styles';

interface IProps {
  label?: string;
  checked: boolean;
  onChange?: (ev: any) => void;
  children?: ReactNode;
}

const InputCheckedV3: FC<IProps> = ({ label, checked, onChange, children }) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked}>
        <CheckmarkSVG checked={checked} width="13" height="13" viewBox="0 0 12 12">
          <path d="M2 6l3 2 5-5" />
        </CheckmarkSVG>
      </StyledCheckbox>
      {label || children}
    </CheckboxContainer>
  );
};

export default InputCheckedV3;
