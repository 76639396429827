import React, { useState } from 'react';
import { CopyButtonStyle, SuccessStyle } from './styles';
import CopySVG from '~/assets/Icons/Copy';

interface CopyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ children, ...props }) => {
  const [message, setMessage] = useState<string>();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    try {
      navigator.clipboard.writeText(children);
      setMessage('Código copiado!');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      setMessage('Falha ao copiar.');
    }
  };

  return (
    <>
      <CopyButtonStyle onClick={handleClick} {...props}>
        {children}
        <CopySVG v3 />
      </CopyButtonStyle>
      {showSuccess ? <SuccessStyle>{message}</SuccessStyle> : null}
    </>
  );
};

export { CopyButton };
