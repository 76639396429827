import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { IState } from '~/store/modules/types';
import { getEnrollmentsConsumptionHistoryRequest } from '~/store/modules/enrollmentsConsumptionHistory/actions';
import moment from 'moment';

interface IProps {
  filterActiveType: 'daily' | 'monthly';
  setFilterActiveType: (type: 'daily' | 'monthly') => void;
  filterValue: number;
  setFilterValue: (value: number) => void;
}

const Filters: FC<IProps> = ({
  filterActiveType,
  setFilterActiveType,
  filterValue,
  setFilterValue,
}) => {
  const dispatch = useDispatch();

  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  // const isIotClient = eletronic_exists && dataSubscribe && dataSubscribe[0];
  const isIotClient = !!eletronic_exists;

  const verifyClassMobile = (iot?: boolean, dateRange?: number) => {
    if (filterValue === dateRange)
      return `${
        filterActiveType === (iot ? 'daily' : 'monthly') ? 'visible' : 'hidden'
      }-mobile active`;

    return `${
      filterActiveType === (iot ? 'daily' : 'monthly') ? 'visible' : 'hidden'
    }-mobile`;
  };

  const getDataFilter = async (dateRange: number, iot?: boolean) => {
    if (filterValue === dateRange) return;

    setFilterActiveType(iot ? 'daily' : 'monthly');
    setFilterValue(dateRange);

    if (iot) {
      dispatch(
        getEnrollmentsConsumptionHistoryRequest({
          start: moment(new Date()).subtract(dateRange, 'd').format('DD/MM/YYYY'),
          end: moment(new Date()).format('DD/MM/YYYY'),
        }),
      );
    } else {
      dispatch(
        getEnrollmentsConsumptionHistoryRequest({
          start: moment(new Date())
            .subtract(dateRange, 'months')
            .format('DD/MM/YYYY'),
          end: moment(new Date()).format('DD/MM/YYYY'),
        }),
      );
    }
  };

  useEffect(() => {
    if (isIotClient) {
      dispatch(
        getEnrollmentsConsumptionHistoryRequest({
          start: moment(new Date()).subtract(45, 'd').format('DD/MM/YYYY'),
          end: moment(new Date()).format('DD/MM/YYYY'),
        }),
      );
    } else {
      dispatch(
        getEnrollmentsConsumptionHistoryRequest({
          start: moment(new Date()).subtract(12, 'months').format('DD/MM/YYYY'),
          end: moment(new Date()).format('DD/MM/YYYY'),
        }),
      );
    }
  }, []);

  return (
    <Container isActive={filterActiveType} iot={!!isIotClient}>
      <div id="top-filter">
        {isIotClient && (
          <button
            type="button"
            onClick={() => setFilterActiveType('daily')}
            className={verifyClassMobile(eletronic_exists)}
          >
            Consumo diário
          </button>
        )}
        <button
          type="button"
          onClick={() => setFilterActiveType('monthly')}
          className={verifyClassMobile()}
        >
          Consumo mensal
        </button>
      </div>
      <div id="bottom-filter">
        {isIotClient && (
          <>
            <button
              type="button"
              className={verifyClassMobile(eletronic_exists, 15)}
              onClick={() => getDataFilter(15, eletronic_exists)}
            >
              15 dias
            </button>
            <button
              type="button"
              className={verifyClassMobile(eletronic_exists, 30)}
              onClick={() => getDataFilter(30, eletronic_exists)}
            >
              30 dias
            </button>
            <button
              type="button"
              className={verifyClassMobile(eletronic_exists, 45)}
              onClick={() => getDataFilter(45, eletronic_exists)}
            >
              45 dias
            </button>
          </>
        )}
        <button
          type="button"
          className={verifyClassMobile(false, 3)}
          onClick={() => getDataFilter(3)}
        >
          3 meses
        </button>
        <button
          type="button"
          className={verifyClassMobile(false, 6)}
          onClick={() => getDataFilter(6)}
        >
          6 meses
        </button>
        <button
          type="button"
          className={verifyClassMobile(false, 12)}
          onClick={() => getDataFilter(12)}
        >
          12 meses
        </button>
      </div>
    </Container>
  );
};

export default Filters;
