/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';

import { Border, Description, TextOption } from '../styles';
import InputSimulation from '../InputSimulation';
import { Color } from '~/styles';
import ContinueButton from '../ContinueButton';
import formatter from '~/utils/formatter';
import { ButtonChangeAmount, ContainerInputAmount, TextAmount } from './styles';
import BackButton from '../BackButton';

interface IStepTwoProps {
  setStep: Dispatch<SetStateAction<number>>;
}

const StepTwo: FC<IStepTwoProps> = ({ setStep }) => {
  const amount = 2.0;
  const minLimit = 2.0;
  const maxLimit = 5.0;

  const [value, setValue] = useState<number>(minLimit);

  const handleBackOnClick = () => {
    setStep(0);
  };

  const handleOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 02 – Continuar para confirmação.',
    });

    setStep(2);
  };

  return (
    <>
      <Description>
        Defina em quantas parcelas deseja pagar o valor restante.
        <br />
        <b>O parcelamento será somado às próximas faturas.</b>
      </Description>
      <TextOption>ENTRADA</TextOption>
      <ContainerInputAmount>
        <TextAmount>{formatter.formatCurrency(amount)}</TextAmount>
        <ButtonChangeAmount type="button" onClick={handleBackOnClick}>
          Alterar entrada
        </ButtonChangeAmount>
      </ContainerInputAmount>
      <TextOption>PARCELAS</TextOption>
      <InputSimulation
        minLimit={minLimit}
        maxLimit={maxLimit}
        value={value}
        setValue={setValue}
      />
      <Border />
      <ContinueButton
        label="Continuar"
        backgroundColor={Color?.blueSeconde}
        onClick={handleOnClick}
      />
      <BackButton label="voltar" onClick={handleBackOnClick} />
    </>
  );
};

export default StepTwo;
