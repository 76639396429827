import axios from 'axios';
import { api } from './api';
import formatter from '~/utils/formatter';

function getCepService(cep: string) {
  return new Promise((resolve, reject) => {
    try {
      const getCep: any = async () => {
        const response = await api.get(`v3/cases/cep/${cep}`);

        return response.data;
      };
      resolve(getCep());
    } catch (error) {
      reject(error);
    }
  });
}

function getCepViaCep(cep: string) {
  return new Promise((resolve, reject) => {
    try {
      const getCep: any = async () => {
        const response = await axios.get(
          `https://viacep.com.br/ws/${formatter.formatarNumber(cep)}/json/`,
        );

        return response.data;
      };
      resolve(getCep());
    } catch (error) {
      reject(error);
    }
  });
}

export { getCepService, getCepViaCep };
