import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';
import { COLORS } from '~/utils/constants';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 560px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    :first-of-type {
      min-height: inherit;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-block: 24px;
  display: flex;
  flex-direction: column;
`;

export const ContainerTermsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TextTerm = styled.p`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  color: ${Color.blueIntense};
  width: fit-content;

  a {
    text-decoration: none;
    font-weight: 700;
    color: ${Color.red};
  }

  /* @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 18px;
  } */
`;

export const TooltipMsg = styled.p`
  font-weight: inherit;
  > a {
    font-weight: 600;
    /* text-decoration: underline; */
    color: ${Color.blueIntense};
  }
`;

export const ErrorMsg = styled.p`
  margin-bottom: 24px;
  max-width: 400px;
  color: ${COLORS.gray600};
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
`;

export const NavWrap = styled.div`
  margin-block-start: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const TextInfo = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #999999;
`;
