import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.button<{
  types?:
    | 'buttons'
    | 'newProcess'
    | 'filters-pending'
    | 'filters-cancel'
    | 'filters-paid';
  select?: boolean;
}>`
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  ${({ types, select }) => {
    if (types === 'buttons') {
      return `
        border: 1px solid ${Color.gray};
        color: ${Color.gray};
        padding: 4px 12px;
        ${select ? `background: #f2f2f2;` : `background: ${Color.white};`}
      `;
    }
    if (types === 'newProcess') {
      return `
        padding: 4px 16px;
        background: ${Color.blueSeconde};
        color: ${Color.white};
        font-weight: 700;
        padding: 4px 12px;
        svg {
          position: static;
          margin-right: 4px;
        }
      `;
    }
    if (types === 'filters-pending') {
      return `
        background: ${Color.white};
        border: 1px solid ${Color.yellow};
        color: ${Color.blueIntense};
        padding: 4px 8px;
        & + & {
          margin-left: 24px;
        }
        ${
          select
            ? `
            background: ${Color.yellow};
            color: ${Color.white};`
            : `
            background: ${Color.white};
            color: ${Color.yellow};`
        }
      `;
    }
    if (types === 'filters-paid') {
      return `
        background: ${Color.white};
        border: 1px solid ${Color.green};
        color: ${Color.blueIntense};
        padding: 4px 8px;
        & + & {
          margin-left: 24px;
        }
        ${
          select
            ? `
            background: ${Color.green};
            color: ${Color.white};`
            : `
            background: ${Color.white};
            color: ${Color.green};`
        }
      `;
    }
    if (types === 'filters-cancel') {
      return `
        background: ${Color.white};
        border: 1px solid ${Color.gray};
        color: ${Color.blueIntense};
        padding: 4px 8px;
        & + & {
          margin-left: 24px;
        }
        ${
          select
            ? `
            background: ${Color.gray};
            color: ${Color.white};`
            : `
            background: ${Color.white};
            color: ${Color.gray};`
        }
      `;
    }
    return null;
  }}

  svg {
    position: static;
  }
`;
