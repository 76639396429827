import { IRequestEnrollmentsSessions } from '~/store/modules/enrollmentsAuth/types';
import { api } from './api';

function getEnrollmentsSessionsService({
  cnp,
  password,
}: IRequestEnrollmentsSessions) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSessions: any = async () => {
        const response = await api.post('v3/sessions', {
          cnp,
          password,
        });
        return response.data;
      };
      resolve(getEnrollmentsSessions());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsMeService() {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsMe: any = async () => {
        const response = await api.get('v3/enrollments/me');
        return response.data;
      };

      resolve(getEnrollmentsMe());
    } catch (error) {
      reject(error);
    }
  });
}

export { getEnrollmentsSessionsService, getEnrollmentsMeService };
