import styled from 'styled-components';

interface MarginProps {
  height?: number;
  width?: number;
}

export const Margin = styled.div<MarginProps>`
  height: ${(props) => (props.height ? props.height : 0)}px;
  min-width: ${(props) => (props.width ? props.width : 0)}px;
`;
