import styled from 'styled-components';
import { Breakpoints } from '~/styles';
import { Color } from '~/styles/colors';

interface ITitlePros {
  disabled?: boolean;
}

export const Main = styled.main`
  padding: 24px;
  max-width: 570px;
  max-height: 520px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 128px 20px 120px 20px;
  }
`;

export const Wrapper = styled.div`
  padding: 0 20px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ProfileButton = styled.div`
  box-sizing: border-box;
  width: 100%;
  box-shadow: 1px 5px 3px rgba(0, 0, 0, 0.3);
  border-radius: 9.76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 16px;
  cursor: pointer;

  svg {
    margin-top: 0;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const ButtonTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ButtonTitle = styled.h2<ITitlePros>`
  color: ${(props) => {
    return props.disabled ? Color.grayLight : '#37b4c8';
  }};
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
`;

export const ButtonSubtitle = styled.p`
  color: ${Color.grayLightSeconde};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;
