import styled from 'styled-components';

// import { Breakpoints } from '../../../styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  p {
    color: ${Color.black};
  }
`;
