export enum RepositoriesTypesUsers {
  ENROLLMENTS_GET_USERS_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_USERS_METHOD_REQUEST',
  ENROLLMENTS_GET_USERS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_USERS_METHOD_SUCCESS',
  ENROLLMENTS_GET_USERS_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_USERS_METHOD_FAILURE',
  ENROLLMENTS_GET_USERS_METHOD_CLEAR = '@repositories/ENROLLMENTS_GET_USERS_METHOD_CLEAR',

  ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_REQUEST = '@repositories/ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_REQUEST',
  ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS',
  ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE = '@repositories/ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE',
  ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PUT_PASSWORD_USERS_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_PUT_USERS_METHOD_REQUEST = '@repositories/ENROLLMENTS_PUT_USERS_METHOD_REQUEST',
  ENROLLMENTS_PUT_USERS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PUT_USERS_METHOD_SUCCESS',
  ENROLLMENTS_PUT_USERS_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_PUT_USERS_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PUT_USERS_METHOD_FAILURE = '@repositories/ENROLLMENTS_PUT_USERS_METHOD_FAILURE',
  ENROLLMENTS_PUT_USERS_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PUT_USERS_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_REQUEST',
  ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_SUCCESS',
  ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE',
  ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_USERS_RECOVERY_VALIDATE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_RECOVERY_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE',
  ENROLLMENTS_PUT_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_UPDATE_PASSWORD_RECOVERY_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST = '@repositories/ENROLLMENTS_UPDATE_RECOVERY_PASSWORD_METHOD_REQUEST',

  ENROLLMENTS_GET_USERS_VALIDATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_USERS_VALIDATE_METHOD_REQUEST',
  ENROLLMENTS_GET_USERS_VALIDATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_USERS_VALIDATE_METHOD_SUCCESS',
  ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE',
  ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_USERS_VALIDATE_METHOD_FAILURE_CLOSE',

  CREATEMETOTH = '@repositories/CREATEMETOTH',

  ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_SEND_SMS_REGISTER_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_VALIDATE_SMS_REGISTER_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_CREATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_CREATE_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_CREATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_CREATE_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_CREATE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_REQUEST',
  ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_SUCCESS',
  ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE',
  ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_USERS_OBFUSCATED_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_REQUEST',
  ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_SUCCESS',
  ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE',
  ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_USERS_SEND_RECOVERY_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_REQUEST = '@repositories/ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_REQUEST',
  ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS',
  ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE = '@repositories/ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE',
  ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PUT_USERS_PASSWORD_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST = '@repositories/ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST',

  ENROLLMENTS_POST_DATA_NOT_CLIENT_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_DATA_NOT_CLIENT_METHOD_REQUEST',
  CLEAN = '@repositories/CLEAN',
}

export interface IResponseUsersInfo {
  fullName: string;
  cnp: string;
  consumer_type: number;
  phone: {
    primary: string;
    secondary: string;
  };
  mobile: {
    primary: string;
    secondary: string;
  };
  email: string;
  firstName: string;
  lastName: string;
}

export interface IRequestUpdatePassword {
  password: string;
  newPassword: string;
}

export interface IRequestUpdateUser {
  phone: {
    primary: string;
    secondary: string;
  };
  mobile: {
    primary: string;
    secondary: string;
  };
  email: string;
}

export interface IRequestRecoveryPassword {
  token?: string;
  newPassword?: string;
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
}

export interface IRequestSendSms {
  cnp: string;
  phone?: string;
  email?: string;
  isManager?: boolean;
  isEntrepreneur?: boolean;
}

export interface IRequestValidateSms {
  cnp: string;
  token: string;
}

export interface IRequestUpdatePasswordRecovery {
  password: string;
  newPassword: string;
}

export interface IRequestValidateRegister {
  cnp: string;
  recaptchaTokenV2: string;
  recaptchaTokenV3: string;
}

export interface IResponseValidateRegister {
  message: string;
  type: 'USER_ALREADY_EXISTS' | 'USER_NOT_EXISTS_IN_PARTNERS';
  token: string;
}

export interface IRequestSendRecovery {
  cnp: string;
  shippingChannel: 'phone' | 'email';
}

export interface IResponseSendCodeRecovery {
  message: string;
  expiresDate: number;
}

export interface IRequestCreateUserClient {
  cnp?: string;
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
  concession_terms_agree: boolean;
  acceptance_terms_agree: boolean;
  token?: string;
  password?: string;
  phone?: string;
  name?: string;
  email?: string;
  mobile_phone?: string;
  cnpj?: string;
  cpf?: string;
  company_name?: string;
  creci?: string;
  isNotClient?: boolean;
  isManager?: boolean;
  isEntrepreneur?: boolean;
  category?: string;
  clientType?: string;
}

export interface IRequestCreateUserNotClient {
  documentType: string;
  name: string;
  documentNumber: string;
  birthDate: string;
  email: string;
  mobilePhone: string;
  phone: string;
  password: string;
  token?: string;
  documentNumberResponsible?: string;
  firstNameResponsible?: string;
  lastNameResponsible?: string;
  nameResponsible?: string;
  isNotClient?: boolean;
  clientType?: string;
}

export interface IResponseObfuscated {
  email: string;
  phone: string;
}

export interface IRepositoriesStateEnrollmentsUsers {
  readonly data: IResponseUsersInfo | null;
  readonly dataCreateClient: IRequestCreateUserClient | null;
  readonly dataCreateNotClient: IRequestCreateUserNotClient | null;
  readonly dataSendCode: IResponseSendCodeRecovery | null;
  readonly dataRecoveryPassword: IRequestRecoveryPassword | null;
  readonly dataObfuscated: IResponseObfuscated | null;
  readonly rediRectRegister: boolean;
  readonly registerMetothEmail: boolean;
  readonly error: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorUpdatePassword: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorUpdate: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorSendCodeRecovery: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorValidateRecovery: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorSendSms: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorValidateSms: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorUpdatePasswordRecovery: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorValidateRegister: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorRegisterSendSms: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorValidateSmsRegister: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreateRegister: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreate: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorObfuscated: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPutPasswordRecovery: {
    type?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingUpdatePassword: boolean;
  readonly loadingUpdate: boolean;
  readonly loadingValidateRecovery: boolean;
  readonly loadingSendSms: boolean;
  readonly loadingSendCodeRecovery: boolean;
  readonly loadingValidateSms: boolean;
  readonly loadingUpdatePasswordRecovery: boolean;
  readonly loadingValidateRegister: boolean;
  readonly loadingRegisterSendSms: boolean;
  readonly loadingValidateSmsRegister: boolean;
  readonly loadingCreateRegister: boolean;
  readonly loadingCreate: boolean;
  readonly loadingObfuscated: boolean;
  readonly loadingPutPasswordRecovery: boolean;
  readonly successUpdatePassword: boolean;
  readonly successUpdate: boolean;
  readonly successUpdatePasswordRecovery: boolean;
}
