import styled from 'styled-components';
import { Color } from '~/styles';
// import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;

  margin-top: 30px;

  align-items: center;

  justify-content: flex-start;
`;

export const Separator = styled.div`
  height: 0;

  border-top: 1px solid ${Color.grayLightSeconde};
`;
