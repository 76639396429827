import styled from 'styled-components/';

export const Container = styled.div`
  margin-top: 40px;
  padding: 0 80px;
  display: flex;
`;

export const Fields = styled.div`
  width: 70%;
`;

export const Comments = styled.div`
  width: 30%;
`;

export const Title = styled.h2`
  color: #004b55;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Text = styled.h2`
  color: #ff3b3b;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
