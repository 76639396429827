import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Banner = styled.button`
  background: none;
  display: flex;
  align-items: flex-end;

  img {
    width: 300px;
    height: 375px;
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    display: none;
  }
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  color: ${Color.grayLightSeconde};
  font-weight: 500;
`;
