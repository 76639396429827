import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  width?: number;
  height?: number;
}
const SkeletonCustom: FC<IProps> = ({ width, height }) => {
  return <Skeleton width={width || '100%'} height={height} />;
};

export default SkeletonCustom;
