import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { Button } from '~/common';
import { HowItWorksWrapper, Info, InfoItems, InfoTitle, Item } from './styles';

interface IProps {
  items: {
    id: number;
    text: string;
  }[];
}

const HowItWorks: FC<IProps> = ({ items }) => {
  return (
    <HowItWorksWrapper>
      <Info>
        <InfoTitle>Como funciona a troca de titularidade?</InfoTitle>
        <InfoItems>
          {items.map((item) => (
            <Item key={item.id}>
              <div className="number">{item.id}</div>
              <div className="text">{item.text}</div>
            </Item>
          ))}
        </InfoItems>
      </Info>
      <Button
        onClick={() => {
          Navigation.navigate(RouteName.OWNERSHIPMANAGEMENTCREATEORDER);
          ReactGA.event({
            category: 'Troca de Titularidade',
            action: 'Solicitação da troca de titularidade',
          });
        }}
      >
        Solicitar troca de titularidade
      </Button>
    </HowItWorksWrapper>
  );
};

export { HowItWorks };
