import styled, { css } from 'styled-components';

import { Color } from '../../styles';

interface IPropsButton {
  modalType?: 'error' | 'info' | 'success';
}

const modalTypes = {
  info: css`
    height: 68px;
    max-width: 340px;
    background: ${Color.blueSeconde};
    border-radius: 16px;
  `,
  error: css`
    height: 68px;
    max-width: 363px;
    background: ${Color.red};
    border-radius: 16px;
  `,
  success: css`
    height: 68px;
    max-width: 363px;
    background: ${Color.green};
    border-radius: 16px;
  `,
};

export const ButtonStyled = styled.button<IPropsButton>`
  width: 100%;
  height: 30px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: ${Color.white};
  background: #c7c9c7;
  border: none;
  border-radius: 12px;
  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));

  ${({ modalType }: IPropsButton): any => modalType && modalTypes[modalType]}
`;
