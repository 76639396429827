import styled from 'styled-components';
import { Column, Row, TitleCard } from '../styles';
import { Color, Breakpoints } from '~/styles';
import { Text } from '~/components/TextInfo';

export { Column, Row, TitleCard };

export const HistoryConsumer = styled.div`
  max-width: 1010px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: ${Color.white};
  width: -webkit-fill-available;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: -webkit-fill-available;
  }
`;

export const RowHistoryTitle = styled(Row)`
  @media (max-width: ${Breakpoints.laptop.max}px) {
    padding: 0;
  }
`;

export const HistoryInfo = styled(Text).attrs({
  color: Color.gray,
  size: 10,
  weight: 600,
  textAlign: 'left',
})`
  @media (max-width: ${Breakpoints.mobile.max}px) {
    font-size: 9px;
  }
`;

export const RowChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    align-items: stretch;
  }
`;

export const Content = styled.div`
  padding: 60px 24px;
  display: grid;
  grid-template-columns: min-content auto;
  gap: 24px;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const SmartBanner = styled.div`
  width: 400px;
  /* object-fit: cover; */
`;

export const TrialImg = styled.img`
  width: 304px;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  align-self: center;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: 100%;
    height: 304px;
  }
`;

export const SubtitleHistory = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: ${Color.gray};
  }

  #subtitle-total {
    margin-left: auto;
    margin-top: 5px;
    line-height: 13px;
  }

  @media (min-width: ${Breakpoints.tablet.max}px) {
    padding-left: 6.5rem;

    #subtitle-total {
      margin: inherit;
      margin-left: 18px;
    }
  }
`;
