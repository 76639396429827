import React from 'react';

import { useSelector } from 'react-redux';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  Container,
  ContainerHeader,
  ContainerColumn,
  HeaderText,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ContainerNegotiation from './ContainerNegotiation';
// import Pagination from './ContainerInvoice/Pagination';
import EmptyMessage from '~/components/EmptyMessage';
import { mockNegotiation } from './mock';
import { BannerApp } from '~/components/BannerApp';

const Negotiations: React.FC = () => {
  // const pagination = useSelector(
  //   (item: IState) => item?.enrollmentsInvoices?.data?.pagination,
  // );
  const loading = useSelector((item: IState) => item.enrollmentsInvoices.loading);

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas negociações',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <BannerApp />
        <Card>
          <h1>Minhas negociações</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <ContainerHeader>
            <ContainerColumn flexBasis={20}>
              <HeaderText>ID</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={25}>
              <HeaderText>Tipo</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={15}>
              <HeaderText>Valor</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={40}>
              <HeaderText>Status</HeaderText>
            </ContainerColumn>
          </ContainerHeader>
          {mockNegotiation && mockNegotiation?.length > 0 ? (
            mockNegotiation?.map((negotiation) => (
              <ContainerNegotiation key={negotiation.id} data={negotiation} />
            ))
          ) : (
            <>
              <Margin height={27} />
              <EmptyMessage textMessage="Você ainda não possui negociações!" />
            </>
          )}
          {/* {pagination && pagination?.maxPages > 1 && <Pagination />} */}
        </Card>
      </Container>
    </Main>
  );
};

export default Negotiations;
