import React, { FC, useEffect } from 'react';
import { useFormikContext, FormikValues } from 'formik';

import ownershipManagementForm from '../../FormModel';

import { InputField } from '~/common';
import { FormLabel, Checkbox, HelpTag } from '../../../Common';
import { LocationFormWrapper, LocationFormGroup, FieldWrap } from './styles';

import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import InputSelect from './selectInput';

interface ILocationStep {
  reasons: {
    id: number;
    label: string;
    upload: {
      name: string;
      label: string;
    }[];
  }[];
}

const LocationStep: FC<ILocationStep> = ({ reasons }) => {
  const ownership = useSelector((item: IState) => item.ownership);
  const operations = ownership.operations.data ? [...ownership.operations.data] : [];

  const {
    formFields: { city, enrollment },
  } = ownershipManagementForm;

  const { values, setFieldValue, validateField, errors, touched }: FormikValues =
    useFormikContext();

  useEffect(() => {
    validateField('city');
  }, []);

  const handleSelectReason = (id: number, label: string) => {
    id === values.reason.id
      ? setFieldValue('reason', '')
      : setFieldValue('reason', { id, label });
  };

  const setFilesByReason = (
    files: {
      name: string;
      label: string;
    }[],
  ) => {
    setFieldValue(`files[0].name`, files[0].name);
    setFieldValue(`files[1].name`, files[1].name);
  };

  const formatEnrollment = (value: string) => {
    if (!value) return value;
    if (value.length < 2) return value;
    const number = value.replace(/[^\d]/g, '');
    const enrollment = number.replace(/([\d]*)(.)$/g, '$1-$2');
    return enrollment;
  };

  const handleChange = (e: any) => {
    const selected = e && e?.label;

    const cities = [...operations];
    const arr = cities.filter((city) => city.label === selected);
    if (arr.length) {
      const option = arr[0];
      return setFieldValue('city', option);
    }
    return setFieldValue('city', { id: null, label: '' });
  };

  /*  const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  }; */

  return (
    <LocationFormWrapper>
      <LocationFormGroup>
        <FormLabel className="large">
          Digite a cidade e a matrícula do imóvel:
        </FormLabel>
        <InputSelect
          name="city"
          placeholder={city.label}
          error={errors[city.name]}
          touched={touched[city.name]}
          options={operations}
          onChange={handleChange}
        />

        <FieldWrap>
          <InputField
            name={enrollment.name}
            placeholder={enrollment.label}
            inputMode="numeric"
            error={errors[enrollment.name]}
            touched={touched[enrollment.name]}
            onChange={(e) => {
              const formatted = formatEnrollment(e.target.value);
              setFieldValue(enrollment.name, formatted);
            }}
          />
          <HelpTag className="tag" />
        </FieldWrap>
      </LocationFormGroup>
      <LocationFormGroup>
        <FormLabel className="large">
          Selecione o motivo da troca de titularidade:
        </FormLabel>
        {reasons.map((item) => (
          <Checkbox
            checked={values.reason.id === item.id}
            key={item.id}
            onClick={() => {
              handleSelectReason(item.id, item.label);
              setFilesByReason(item.upload);
            }}
          >
            {item.label}
          </Checkbox>
        ))}
      </LocationFormGroup>
    </LocationFormWrapper>
  );
};

export { LocationStep };
