/* eslint-disable prefer-destructuring */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import creditCardType from 'credit-card-type';
import { useDispatch, useSelector } from 'react-redux';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerPayment,
  BoxDivider,
  ContainerCardTemplate,
} from './styles';
import CreditCardTemplate from './CardTemplate';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import formatter from '~/utils/formatter';
import { useMediaQuery } from 'react-responsive';
import { IState } from '~/store/modules/types';
import {
  postEnrollmentsInvoiceGroupPaymentRequest,
  postErrorCloseInvoiceGroupPayment,
} from '~/store/modules/enrollmentsInvoices/actions';
import { IRequestInvoiceGroupPayment } from '~/store/modules/enrollmentsInvoices/types';
import WarningBox from '~/components/WarningBox';

interface IInstallment {
  label?: string;
  value?: number;
}

interface IError {
  message: string;
  error: boolean;
}

interface IErrors {
  number: IError;
  validity: IError;
}

const SinglePaymentDataPayment: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const dispatch = useDispatch();

  const dataInvoiceInstallmentGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataInvoiceInstallmentGroup,
  );
  const loading = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.loadingPaymentGroup,
  );
  const loadingPaymentGroupVoucher = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.loadingPaymentGroupVoucher,
  );
  const error = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.errorPaymentGroup,
  );
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [dataPayment, setDataPayment] = useState<any>({
    installment: '',
    cardNumber: '',
    cardHolder: '',
    validity: '',
    securityCode: '',
  });
  const [brandCard, setBrandCard] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'Validade do cartão inválida',
    },
  });
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    const array: IInstallment[] = [
      {
        label: 'Selecione',
        value: undefined,
      },
    ];

    if (dataInvoiceInstallmentGroup) {
      for (
        let index = 0;
        index < dataInvoiceInstallmentGroup?.installments;
        index++
      ) {
        array.push({
          label: `${index + 1} x sem juros`,
          value: index + 1,
        });
      }
    }

    setInstallments(array || []);
    dispatch(postErrorCloseInvoiceGroupPayment());
  }, []);

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },
    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },
    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType(
      (dataPayment.cardNumber || '').replace(/\D/g, ''),
    ) as any;

    if (brandType.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand.issuer === brandType?.type,
    );

    if (brand?.brand) setBrandCard(brand.issuer);
    else setBrandCard('');
  };

  useEffect(() => {
    findBrand();
  }, [dataPayment.cardNumber]);

  useEffect(() => {
    if (
      dataPayment.installment &&
      dataPayment.cardNumber &&
      dataPayment.cardHolder &&
      dataPayment.validity &&
      dataPayment.securityCode &&
      !errors.number.error &&
      !errors.validity.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [dataPayment, errors]);

  const validateCardNumber = () => {
    if (
      (dataPayment.cardNumber && dataPayment.cardNumber.length < 19) ||
      (dataPayment.cardNumber && dataPayment.cardNumber.length > 0 && !brandCard)
    ) {
      setErrors(({ number, validity }: any) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (dataPayment.validity && dataPayment.validity.length < 7) {
      setErrors(({ number, validity }: any) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  return (
    <Main>
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => {
            ReactGA.event({
              category: 'Pagamento Único',
              action: '[Pg. Único][WEB] Pagamento sem Sucesso',
            });
            dispatch(postErrorCloseInvoiceGroupPayment());
          }}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Pagamento único',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento único</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Dados de pagamento</h2>
          <Border />
        </ContainerBorder>
        <Margin height={24} />
        <ContainerAmount>
          <p>
            Valor total:{' '}
            {formatter.formatCurrency(dataInvoiceInstallmentGroup?.totalValue)}
          </p>
        </ContainerAmount>
        <Margin height={24} />
        <ContainerPayment>
          <BoxDivider>
            <ContainerCardTemplate>
              <CreditCardTemplate
                holder={dataPayment.cardHolder}
                cardNumber={dataPayment.cardNumber}
                securityCode={dataPayment.securityCode}
                validity={dataPayment.validity}
                issuer={brandCard}
              />
            </ContainerCardTemplate>
          </BoxDivider>
          <BoxDivider>
            <div className="container-inputs">
              <InputSelect
                label="Parcelas"
                name="installment"
                values={installments}
                onChange={(ev) => {
                  setDataPayment({ ...dataPayment, installment: ev.target.value });
                }}
                errorPosition="bottom"
              />
            </div>
            <div className="container-inputs">
              <Input
                label="Número do cartão"
                name="cardNumber"
                value={dataPayment.cardNumber}
                onChange={(ev) =>
                  setDataPayment({
                    ...dataPayment,
                    cardNumber: formatter.creditCard(ev.target.value) || '',
                  })
                }
                onBlur={validateCardNumber}
                errorText={errors.number.message}
                error={errors.number.error}
                errorPosition="bottom"
                maxLength={19}
              />
            </div>
            <div className="container-inputs">
              <Input
                label="Nome do titular"
                name="cardHolder"
                value={dataPayment.cardHolder}
                onChange={(ev) =>
                  setDataPayment({
                    ...dataPayment,
                    cardHolder: ev.target.value,
                  })
                }
              />
            </div>
            <div className="container-inputs container-inputs-box">
              <Input
                label="Validade"
                name="validity"
                value={dataPayment.validity}
                onChange={(ev) =>
                  setDataPayment({
                    ...dataPayment,
                    validity: formatter.creditCardValidity(ev.target.value),
                  })
                }
                onBlur={validateCardValidity}
                errorText={errors.validity.message}
                error={errors.validity.error}
                errorPosition="bottom"
              />
              <Input
                label={isMobile ? 'Cód. de segurança' : 'Código de segurança'}
                name="securityCode"
                value={dataPayment.securityCode}
                onChange={(ev) =>
                  setDataPayment({
                    ...dataPayment,
                    securityCode: formatter.formatarNumber(ev.target.value),
                  })
                }
                errorPosition="bottom"
                maxLength={3}
              />
            </div>
          </BoxDivider>
        </ContainerPayment>
        <Margin height={24} />
        <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
        <Margin height={10} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() => Navigation.goBack()}
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={disabled ? Color.gray : Color.blueSeconde}
            onClick={() => {
              if (disabled || loading || loadingPaymentGroupVoucher) return;

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[Pag. Único] [WEB] Confirmar Pagamento',
              });
              const data: IRequestInvoiceGroupPayment = {
                paymentCardNumber: dataPayment.cardNumber,
                paymentCardHolder: dataPayment.cardHolder,
                paymentExpirationDate: dataPayment.validity,
                paymentSecurityCode: dataPayment.securityCode,
                paymentBrand: brandCard,
                installments: Number(dataPayment.installment),
              };

              dispatch(postEnrollmentsInvoiceGroupPaymentRequest({ ...data }));
            }}
            loading={loading || loadingPaymentGroupVoucher}
            nextIcon
          >
            Confirmar pagamento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SinglePaymentDataPayment;
