/* eslint-disable consistent-return */
import React, { Dispatch, FC, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
import { ButtonPaymentMethod, ContainerPaymentMethods } from './styles';
import { CreditCardIcon } from '~/views/EasyDuplicate/Icons';
import { Breakpoints, Color } from '~/styles';
import PixSVG from '~/assets/Icons/pix';
import BarCodeSVG from '~/assets/Icons/BarCode';
import { useMediaQuery } from 'react-responsive';
import { ModalPaymentUnavailableTypeInfo } from '../ModalPaymentUnavailable';
import { IPaymentUnavailable } from '../types';

type PaymentMethodType = 'credit_card' | 'pix' | 'billet';

interface IDescriptionPaymentProps {
  type: PaymentMethodType;
  setType: Dispatch<SetStateAction<PaymentMethodType>>;
  setPaymentUnavailableTypeInfo: Dispatch<
    SetStateAction<ModalPaymentUnavailableTypeInfo>
  >;
  paymentUnavailable?: IPaymentUnavailable;
  setOpenPaymentUnavailable: Dispatch<SetStateAction<boolean>>;
}

export const PaymentMethods: FC<IDescriptionPaymentProps> = ({
  type,
  setType,
  setPaymentUnavailableTypeInfo,
  paymentUnavailable,
  setOpenPaymentUnavailable,
}) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const activePaymentMethod = (activeType: PaymentMethodType) => {
    if (paymentUnavailable?.[activeType]) {
      setPaymentUnavailableTypeInfo(activeType);
      return setOpenPaymentUnavailable(true);
    }

    setType(activeType);
  };

  return (
    <ContainerPaymentMethods>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Cartão',
          });
          activePaymentMethod('credit_card');
        }}
        active={type === 'credit_card'}
      >
        <CreditCardIcon color={Color.blueIntense} />
        Cartão de {isMobile && <br />} crédito
      </ButtonPaymentMethod>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Pix',
          });
          activePaymentMethod('pix');
        }}
        active={type === 'pix'}
      >
        <PixSVG v3 />
        PIX
      </ButtonPaymentMethod>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Boleto',
          });
          activePaymentMethod('billet');
        }}
        active={type === 'billet'}
      >
        <BarCodeSVG width={38} heigth={30} />
        Boleto
      </ButtonPaymentMethod>
    </ContainerPaymentMethods>
  );
};
