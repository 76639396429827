import React, { FC } from 'react';
import Sewage from './Sewage';
import Water from './Water';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { FCPTypes } from '~/store/modules/propertyRegistration/types';

const ProjectandLicensingInformation: FC = () => {
  const dataItem = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const state = dataItem?.type;

  const typesMap: Map<FCPTypes | undefined, React.JSX.Element> = new Map([
    ['FCPE', <Sewage />],
    ['FCPA', <Water />],
    [undefined, <Water />],
  ]);

  return typesMap.get(state);
};

export default ProjectandLicensingInformation;
