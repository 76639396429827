export enum RepositoriesTypesInvoices {
  ENROLLMENTS_GET_INVOICES_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_INVOICES_METHOD_REQUEST',
  ENROLLMENTS_GET_INVOICES_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_INVOICES_METHOD_SUCCESS',
  ENROLLMENTS_GET_INVOICES_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_INVOICES_METHOD_FAILURE',

  ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_REQUEST',
  ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_SUCCESS',
  ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_FAILURE',

  ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_REQUEST',
  ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_SUCCESS',
  ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE',
  ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_MATURITY_DAYS_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_METHOD_REQUEST',
  ENROLLMENTS_GET_MATURITY_DAYS_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_METHOD_SUCCESS',
  ENROLLMENTS_GET_MATURITY_DAYS_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_METHOD_FAILURE',

  ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST',
  ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS',
  ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE',

  ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST = '@repositories/ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST',
  ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS',
  ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS_CLOSE = '@repositories/ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE = '@repositories/ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE',
  ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_TYPES_INVOICES_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_TYPES_INVOICES_METHOD_REQUEST',
  ENROLLMENTS_GET_TYPES_INVOICES_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_TYPES_INVOICES_METHOD_SUCCESS',
  ENROLLMENTS_GET_TYPES_INVOICES_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_TYPES_INVOICES_METHOD_FAILURE',

  ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_REQUEST = '@repositories/ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_REQUEST',
  ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS = '@repositories/ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS',
  ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS_CLOSE = '@repositories/ ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS_CLOSE',
  ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE = '@repositories/ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE',
  ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_REQUEST',
  ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_SUCCESS',
  ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE',
  ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_DOWNLOAD_INVOICE_CREATE_GROUP_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_REQUEST',
  ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_SUCCESS',
  ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE',
  ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_REQUEST',
  ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_SUCCESS',
  ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE',
  ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_REQUEST',
  ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_SUCCESS',
  ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_FAILURE',
  ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_FAILURE_CLOSE',
}

export interface IRequestQueryInvoices {
  page: number;
  size: number;
}

export interface IInvoices {
  reference: string;
  invoice_id: number;
  amount: number;
  issue_date_hour: string;
  billed_consumption: string;
  maturity_date: string;
  overdue: false;
  status: {
    id: number;
    label: string;
  };
  type_payment?: string;
}

export interface IResponseInvoices {
  invoices: IInvoices[] | [];
  next_reading_prediction: string;
  pagination?: {
    totalItens: number;
    maxPages: number;
    page: number;
  };
  info?: {
    opened: number;
  };
}

export interface IResponseInvoicesDetail {
  reference: string;
  invoice_id: number;
  fmt_amount: string;
  amount: number;
  issue_date_hour: string;
  billed_consumption: string;
  maturity_date: string;
  read_date_hour: string;
  consumption_days: string;
  meter_reader: string;
  colected_read: string;
  average_consumption: string;
  read_type: string;
  rate_type: string;
  reader: string;
  invoice_type: string;
  has_payment_integrator: false;
  status: {
    id: number;
    label: string;
  };
  qr_code: string;
  qr_code_key: string;
  pix_key: string;
  possible_quantity_installments: number;
  bar_code_number: string;
  consumer: {
    name: string;
    address: string;
  };
  items: [
    {
      description: string;
      fmt_amount: string;
      amount: number;
    },
  ];
  type_payment: string;
}

export interface IResponseInvoicesDownload {
  buffer: string;
}

export interface IResponseInvoicesMaturityDays {
  day: number;
  competence: string;
}

export interface IRequestInvoicesMaturityDays {
  day: number;
}

export interface IResponseTypesInvoices {
  FÍSICO: {
    id: number;
    label: string;
  };
  'E-MAIL': {
    id: number;
    label: string;
  };
  'E-MAIL/FÍSICO': {
    id: number;
    label: string;
  };
}

export interface IResponsePutTypesInvoices {
  message: string;
}

interface IInvoiceCreateGroup {
  invoice_id: number;
  amount: number;
}

export interface IRequestInvoiceCreateGroup {
  invoices: Array<IInvoiceCreateGroup>;
}

export interface IResponseInvoiceCreateGroup {
  invoice_group_id: number;
}

export interface IRequestInvoiceInstallmentGroup {
  invoice_group_id: any;
}

export interface IResponseInstallmentGroup {
  totalValue: number;
  installments: number;
  installmentValue: number;
}

export interface IRequestInvoiceGroupPayment {
  paymentCardNumber: string;
  paymentCardHolder: string;
  paymentExpirationDate: string;
  paymentSecurityCode: number;
  paymentBrand: string;
  installments: number;
}

interface IInvoiceGroupVoucherDetailItem {
  description: string;
  amount: number;
}

interface IInvoiceGroupVoucherDetail {
  reference: string;
  invoice_id: number;
  maturity_date: string;
  issue_date_hour: string;
  amount: number;
  items: Array<IInvoiceGroupVoucherDetailItem>;
}

export interface IResponseInvoiceGroupVoucher {
  header: {
    name: string;
    cnp: string;
    adrress: string;
    amount_total: number;
    payment_id: string;
    received_date: string;
    type: string;
  };
  details: Array<IInvoiceGroupVoucherDetail>;
}

export interface IRepositoriesStateEnrollmentsInvoices {
  readonly data: IResponseInvoices | null;
  readonly dataDetail: IResponseInvoicesDetail | null;
  readonly dataMaturityDays: IResponseInvoicesMaturityDays | null;
  readonly dataMaturityDaysAlternate: Array<number> | [];
  readonly dataTypesInvoices: IResponseTypesInvoices | null;
  readonly dataInvoiceGroupId: IResponseInvoiceCreateGroup | null;
  readonly dataInvoiceInstallmentGroup: IResponseInstallmentGroup | null;
  readonly dataInvoiceGroupVoucher: IResponseInvoiceGroupVoucher | null;
  readonly loading: boolean;
  readonly loadingDetail: boolean;
  readonly loadingDownload: boolean;
  readonly loadingMaturityDays: boolean;
  readonly loadingMaturityDaysAlternate: boolean;
  readonly loadingPutMaturityDaysAlternate: boolean;
  readonly loadingTypesInvoices: boolean;
  readonly loadingPutTypesInvoices: boolean;
  readonly loadingInvoiceGroupId: boolean;
  readonly loadingInstallmentGroup: boolean;
  readonly loadingPaymentGroup: boolean;
  readonly loadingPaymentGroupVoucher: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDownload: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorMaturityDays: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorMaturityDaysAlternate: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPutMaturityDaysAlternate: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorTypesInvoices: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPutTypesInvoices: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorInvoiceGroupId: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorInstallmentGroup: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPaymentGroup: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPaymentGroupVoucher: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly successPutMaturityDaysAlternate: boolean;
  readonly successPutTypesInvoices: boolean;
}
