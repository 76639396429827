import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  border: 1px solid ${Color.gray};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .container-border {
    flex-basis: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 8px;
      background: ${Color.gray};
      border-radius: 8px 0 0 8px;
    }
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    flex-basis: 35%;

    &.small {
      flex-basis: 30%;
    }
  }
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: 104px;
  border: 1px solid ${Color.gray};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  .container-border {
    flex-basis: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 8px;
      background: ${Color.gray};
      border-radius: 8px 0 0 8px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p,
    span {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }

    p {
      width: calc(100% - 32px);
      padding: 0 16px;
      display: flex;
      align-items: center;

      b {
        color: #000;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
`;
