import React, { FC } from 'react';
import {
  ButtonBalloon,
  Container,
  ContainerInfoBox,
  Divisor,
  Title,
} from './styles';
import BalloonInfoSVG from '~/assets/Icons/BalloonInfo';
import { Color } from '~/styles';
import InfoBox from '~/components/InfoBox';

interface ICardTitleProps {
  icon?: React.ReactNode;
  label: string;
  showBallonInfo: boolean;
  message: string;
  onClick: () => void;
}

const CardTitle: FC<ICardTitleProps> = ({
  icon,
  label,
  showBallonInfo,
  message,
  onClick,
}) => {
  return (
    <>
      <Container>
        {icon && <div>{icon}</div>}
        <Title>{label}</Title>
        <ButtonBalloon onClick={onClick} type="button">
          <BalloonInfoSVG color={!showBallonInfo ? Color.blueSeconde : Color.gray} />
        </ButtonBalloon>
      </Container>
      {showBallonInfo && (
        <ContainerInfoBox>
          <InfoBox hiddenTitle label={message} />
        </ContainerInfoBox>
      )}
      <Divisor />
    </>
  );
};

export default CardTitle;
