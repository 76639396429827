import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 22px 54px 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 118px 20px 19px 20px;
  }
`;

export const ContainerWallet = styled.div`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  }
`;

export const Header = styled.div`
  padding: 24px 0 27px 0;
  background: ${Color.green};
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: ${Color.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 24px;
    padding: 33px 0;
    font-weight: 700;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 33px 0 228px 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 32px 40px 80px 135px;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TitleCard = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${Color.blueIntense};
`;

export const ContainerCreditCardWallet = styled.div`
  width: 100%;
  margin-top: 9px;
`;

export const CreditCardWalletMobile = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 14px 14px 14px;
  border: 1px solid ${Color.gray};
`;

export const CardNumberText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.black};
`;

export const DefaultText = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.gray};
  margin-left: auto;
`;

export const ContainerLimitCards = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
  }
`;

export const ContainerButtonAddCard = styled.div`
  width: 100%;
  padding: 0 30px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0;
  }
`;
