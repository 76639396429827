import styled from 'styled-components';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${Color.green};
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: ${Color.gray};
    b {
      font-weight: 700;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
      color: ${Color.blueSeconde};
    }
  }
`;
