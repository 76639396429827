/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React, { FC, useState } from 'react';

import { Main } from './styles';
import StatusInfo from '../../../components/StatusInfo';
import {
  getDataDownloadFileCustomerObligationRequest,
  patchCustomerObligationStepRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { Margin } from '~/components/Margin';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';

interface IProps {
  setActualStep: (state: 1 | 2 | 3 | 4 | 5) => void;
}

const DocumentationLicensingDownload: FC<IProps> = () => {
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loadingDownloadFile = useSelector(
    (item: IState) => item.customerObligation.loadingDownloadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );

  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    if (caseOC?.realStep.number === 3) {
      return dispatch(
        patchCustomerObligationStepRequest({
          case: idCreate || caseOC?.id || '',
          step: 'FOUR',
        }),
      );
    }
    if (caseOC?.realStep.number === 4) {
      return dispatch(
        patchStatusStepCustomerObligationRequest({
          name: '',
          number: 4,
          substep: '',
          status: caseOC?.realStep.status,
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 4,
        substep: '',
        status: stepStatusEnum.SUCCESS,
      }),
    );
  };

  return (
    <>
      {loadingDownloadFile && <LoadingComponent labelWait="baixando..." />}
      <Main>
        {caseOC?.step.status === stepStatusEnum.SUCCESS && (
          <StatusInfo
            status="success"
            onClick={() => {
              if (loadingDownloadFile) return;
              dispatch(
                getDataDownloadFileCustomerObligationRequest({
                  idFile: caseOC?.approvedDocuments?.LICENSING || '',
                }),
              );
              setDisabled(false);
            }}
            labelButton="Documento Para Licenciamento"
          >
            Os documentos foram analisados e assinados.
            <br /> Faça o download da documentação e se direcione a PMRJ para dar
            entrada no licenciamento de execução de obras em vias publicas, após isto
            você pode seguir para a próxima etapa.
          </StatusInfo>
        )}
        {caseOC?.step.status === stepStatusEnum.WAIT && (
          <StatusInfo status="wait">
            Os documentos foram enviados para análise. Você pode acompanhar o
            andamento na tela inicial da <b>Viabilidade de Empreendimento</b>.
          </StatusInfo>
        )}
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 2,
                substep: 'B',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingAttachFiles}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default DocumentationLicensingDownload;
