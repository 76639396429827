import React, { FC } from 'react';
import { Color } from '~/styles';

interface IProps {
  color?: string;
  colorNewIcon?: string;
  newIcon?: boolean;
}

const UploadFileSVG: FC<IProps> = ({
  color = Color.grayLightSeconde,
  colorNewIcon = Color.gray,
  newIcon = false,
}) => {
  return newIcon ? (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1.73828L11 14.2383M11 1.73828L15.1667 5.90495M11 1.73828L6.83333 5.90495M1 15.9049L1.621 18.4935C1.72915 18.9442 1.97882 19.3443 2.33033 19.6303C2.68184 19.9162 3.11501 20.0715 3.561 20.0716H18.439C18.885 20.0715 19.3182 19.9162 19.6697 19.6303C20.0212 19.3443 20.2708 18.9442 20.379 18.4935L21 15.9049"
        stroke={colorNewIcon}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <svg
        width="13"
        height="18"
        viewBox="0 0 13 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 2L2 6.66667M6.5 2L6.5 16L6.5 2ZM6.5 2L11 6.66667L6.5 2Z"
          stroke={color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        width="27"
        height="9"
        viewBox="0 0 27 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L2.71415 5.10625C2.83853 5.6471 3.12565 6.12724 3.52988 6.47037C3.93411 6.8135 4.43226 6.99992 4.94515 7H22.0548C22.5677 6.99992 23.0659 6.8135 23.4701 6.47037C23.8744 6.12724 24.1615 5.6471 24.2858 5.10625L25 2"
          stroke={color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UploadFileSVG;
