import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { InfoIcon, LoadingIcon, SuccessIcon, WarningIcon } from './icons';
import { Icon, MyMessageBox, Text, Title, Wrapper } from './styles';

interface MessageBoxProps extends HTMLAttributes<HTMLDivElement> {
  children: string | ReactNode;
  title: string;
  variant?: 'warning' | 'loading' | 'success' | 'info';
  icon?: 'warning' | 'loading' | 'success' | 'info';
}

const MessageBox: FC<MessageBoxProps> = ({
  children,
  title,
  variant,
  icon,
  ...props
}) => {
  const renderIcon = () => {
    const MyIcons = {
      warning: <WarningIcon />,
      loading: <LoadingIcon />,
      info: <InfoIcon />,
      success: <SuccessIcon />,
    };
    if (!icon) {
      return MyIcons[(variant as keyof typeof MyIcons) || 'warning'];
    }
    return MyIcons[(icon as keyof typeof MyIcons) || 'warning'];
  };

  return (
    <MyMessageBox variant={variant} {...props}>
      <Icon>{renderIcon()}</Icon>
      <Wrapper>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </Wrapper>
    </MyMessageBox>
  );
};

export { MessageBox };
