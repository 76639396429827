import React from 'react';

interface IProp {
  color?: string;
  v3?: boolean;
}
function ErrorIconSVG({ color = '#E42313', v3 = false }: IProp) {
  return v3 ? (
    <svg
      width="72"
      height="73"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0003 72.3346C55.7803 72.3346 71.8337 56.2813 71.8337 36.5013C71.8337 16.7213 55.7803 0.667969 36.0003 0.667969C16.2203 0.667969 0.166992 16.7213 0.166992 36.5013C0.166992 56.2813 16.2203 72.3346 36.0003 72.3346ZM36.0003 7.83464C51.8387 7.83464 64.667 20.663 64.667 36.5013C64.667 43.1305 62.4095 49.2221 58.6112 54.0596L18.442 13.8905C23.2795 10.0921 29.3712 7.83464 36.0003 7.83464ZM13.3895 18.943L53.5587 59.1121C48.7212 62.9105 42.6295 65.168 36.0003 65.168C20.162 65.168 7.33366 52.3396 7.33366 36.5013C7.33366 29.8721 9.59116 23.7805 13.3895 18.943Z"
        fill="#872D2D"
      />
    </svg>
  ) : (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0013 71.8333C55.7813 71.8333 71.8346 55.78 71.8346 36C71.8346 16.22 55.7813 0.166626 36.0013 0.166626C16.2213 0.166626 0.167969 16.22 0.167969 36C0.167969 55.78 16.2213 71.8333 36.0013 71.8333ZM36.0013 7.33329C51.8396 7.33329 64.668 20.1616 64.668 36C64.668 42.6291 62.4105 48.7208 58.6121 53.5583L18.443 13.3891C23.2805 9.59079 29.3721 7.33329 36.0013 7.33329ZM13.3905 18.4416L53.5596 58.6108C48.7221 62.4091 42.6305 64.6666 36.0013 64.6666C20.163 64.6666 7.33464 51.8383 7.33464 36C7.33464 29.3708 9.59214 23.2791 13.3905 18.4416Z"
        fill={color}
      />
    </svg>
  );
}
export default ErrorIconSVG;
