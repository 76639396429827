import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import Sewage from './Sewage';
import Water from './Water';

const ConnectionRequest: FC = () => {
  const dataItem = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const type = dataItem?.type;

  return (
    <>
      {type === 'FCPE' && <Sewage />}
      {type === 'FCPA' && <Water />}
    </>
  );
};

export default ConnectionRequest;
