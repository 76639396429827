import styled from 'styled-components';

import { Color } from '../../../styles/colors';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 80px);
  max-width: calc(570px - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 17px;
  background: ${Color.white};
  box-shadow: 0px 4px 26px 0px rgba(52, 187, 218, 0.3);
  padding: 36px 20px 30px 20px;

  h1 {
    color: ${Color.blueSeconde};
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    margin-top: 23px;
  }

  p {
    height: 161px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Color.black};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  button {
    border-radius: 8px;
    background: ${Color.blueSeconde};
    height: 48px;
    filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 36px 40px 50px 40px;
  }
`;
