/* eslint-disable no-unsafe-optional-chaining */
import React, { FC, ReactNode } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isBefore } from 'date-fns';

import { Container, Divisor } from './styles';
import { IState } from '~/store/modules/types';
import { ConnectionIcon, FreeDaysIcon } from './icons';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Margin } from '~/components/Margin';

export interface IPropsIcon {
  icon: ReactNode;
  title: string;
  children: ReactNode;
}

const parametersMonth = {
  monthly: 'mês',
  bimonthly: 'bimestre',
  quarterly: 'trimestre',
  semmiannual: 'semestre',
  annual: 'ano',
};

const ContainerSubscribe: FC = () => {
  const dataPlan = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.dataPlans,
  );
  const amount = dataPlan ? dataPlan[0]?.amount / 100 : 0;

  const data = useSelector((item: IState) => item?.enrollmentsSubscribes.data);
  const dataEnrollment = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.dataEnrollment,
  );
  const isSubscribeTrial = isBefore(
    new Date(),
    new Date(dataEnrollment?.start_date || ''),
  );

  const interval = parametersMonth[dataEnrollment?.plan?.interval || 'monthly'];

  return (
    <Container id="subscribe-card" isSubscribeTrial={isSubscribeTrial}>
      <div>
        <ConnectionIcon />
      </div>
      <Margin height={8} />
      <p>Medição digital</p>
      <Divisor>
        {(!data || !data[0]) && (
          <>
            <div>
              <FreeDaysIcon />
            </div>
            <p>{dataPlan && dataPlan[0]?.trial} DIAS GRÁTIS!</p>
          </>
        )}
      </Divisor>
      {data && data[0] ? (
        <>
          {isSubscribeTrial && (
            <>
              <p className="description">
                Período de teste válido até{' '}
                {moment(dataEnrollment?.createdAt)
                  .add(data[0]?.plan.trial, 'd')
                  .format('DD/MM/YYYY')}
                .
                <Margin height={8} />
                Preço do plano começará a contar a partir do término do teste
                gratuito. Você poderá cancelar sem compromisso antes desta data.
              </p>
              <Divisor />
            </>
          )}
          <p className="description">
            Seu plano será renovado automaticamente em{' '}
            {moment(dataEnrollment?.next_recurrency).format('DD/MM/YYYY')}.
            {interval === 'mês' ? (
              <>
                <Margin height={8} />A cobrança será de{' '}
                {formatter.formatCurrency(amount)} por {interval}.
              </>
            ) : (
              <>
                <Margin height={8} />A cobrança será de parcelas{' '}
                {data && data[0] && formatter.formatCurrency((amount || 0) / 1 || 0)}{' '}
                por mês ({formatter.formatCurrency(amount)} por {{ interval }}).
              </>
            )}
          </p>
        </>
      ) : (
        <>
          <p className="description">
            Você ganhou {dataPlan && dataPlan[0]?.trial} dias para experimentar
            gratuitamente a medição digital!
          </p>
          <button
            type="button"
            onClick={() => {
              if (!dataPlan || !dataPlan[0]) return;
              ReactGA.event({
                category: 'Medição digital',
                action: 'Teste Gratuitamente',
              });
              Navigation.navigate(RouteName.SMARTMETERSUBSCRIPTION, {
                plan_id: dataPlan && dataPlan[0]?.id,
                amount,
              });
            }}
          >
            TESTE GRATUITAMENTE
          </button>
        </>
      )}
    </Container>
  );
};

export default ContainerSubscribe;
