import styled from 'styled-components';
import { Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

// export const ContainerTitle = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// export const Title = styled.h1`
//   font-weight: 700;
//   font-size: 18px;
//   line-height: 24px;
//   color: ${Color.blueIntense};
//   position: absolute;
//   text-align: center;
//   background: white;
//   left: 105px;
//   padding: 0 30px 0 30px;
// `;

// export const SeparatorTitle = styled.hr`
//   width: 100%;
//   border-top: 1px solid ${Color.grayLightSeconde};
// `;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  margin-bottom: 24px;

  :last-of-type {
    margin: 0;
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 50%;
  max-width: 335px;
`;

export const Label = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.gray};
  font-style: italic;

  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }
`;
