import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px 0;

  img {
    width: 100%;
    height: 392px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 22px 24px 90px 24px;

    img {
      height: 504px;
    }
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 800px;

  img {
    height: 328px;
  }

  .banner {
    display: none;

    position: relative;

    button {
      position: absolute;
      bottom: 36px;
      right: 41px;
      width: 300px;
      height: 48px;
      padding: 0px 16px;
      border-radius: 8px;
      gap: 6px;
      background: var(--verde-verde-intenso, #003c28);
      text-align: center;
      font-size: 14px;
      font-weight: 500;

      svg {
        right: 16px;
      }
    }

    @media (min-width: ${Breakpoints.desktopLarge.min}px) {
      display: inherit;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1170px;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${Color.blueIntense};
  padding: 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0;
  }
`;

export const ContainerText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 14px 0 0 0;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.blueIntense};
  padding: 0 24px;

  h3 {
    line-height: 17px;
    font-size: 16px;
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  a {
    color: ${Color.blueSeconde};
  }

  ul {
    list-style: disc;
    margin-left: 12px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0;
  }
`;

export const ContainerButtons = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 64px 24px;

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }

  button:first-of-type {
    svg {
      right: 16px;
    }
  }

  button:last-of-type {
    svg {
      left: 16px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    padding: 0 0 40px 0;

    button {
      max-width: 259px;
    }

    button:first-of-type {
      svg {
        left: 16px;
      }
    }

    button:last-of-type {
      svg {
        right: 16px;
        left: auto;
      }
    }
  }
`;

export const ButtonChat = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 400;
  line-height: 122.5%;
  letter-spacing: -0.35px;
  text-decoration-line: underline;
`;
