export default {
  city: {
    id: null,
    label: '',
  },
  enrollment: '',
  reason: {
    id: null,
    label: '',
  },
  name: 'Clarice dos Reis',
  email: 'clarice@igua.com',
  phone: '21 94576 4389',
  cnp: '127.938.846-90',
  contact: '',
  delivery: '',
  files: [],
  caseId: null,
};
