import styled from 'styled-components';

export const BarChartContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 80px;
  height: 67px;
  padding-bottom: 4px;
  border-bottom: 2px solid #c7c9c7;
  gap: 13px;
`;

export const Bar = styled.div<{ value: number; maxValue: number }>`
  width: 24px;
  height: ${({ value, maxValue }) => (value / maxValue) * 100}%;
  background-color: ${({ color }) => color};
  transition: height 0.3s ease-in-out;
  border-radius: 2px;
`;
