import styled, { css } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface Props {
  color?: 'primary' | 'secondary' | 'error';
  disabled?: boolean;
}

const options = {
  primary: css`
    background-color: ${Color.blueSeconde};
    &:hover {
      background-color: ${Color.blueIntense};
    }
  `,
  secondary: css`
    background-color: ${Color.green};
    &:hover {
      background-color: ${Color.greenDark};
    }
  `,
  disabled: css`
    background-color: ${Color.gray};
    &:hover {
      background-color: ${Color.gray};
      cursor: auto;
    }
  `,
  error: css`
    background-color: ${Color.red};
    &:hover {
      background-color: ${Color.red};
      cursor: auto;
    }
  `,
};

export const ButtonIcon = styled.button`
  height: 48px;
  min-width: 200px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: white;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 8px;
  ${({ color }: Props): any => (color ? options[color] : options.secondary)};
  &:hover {
    cursor: pointer;
  }
  transition: background-color 200ms;
  transition-timing-function: ease;
  ${({ disabled }: Props): any => disabled && options.disabled};
  @media (max-width: ${Breakpoints.tablet.max}px) {
    width: 100%;
    min-width: 144px;
  }
`;

export const Loading = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid white;
  border-bottom: 2px solid transparent;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
