import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 69px 10px 93px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 94px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 160px);
    padding: 55px 80px 241px 80px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 596px;
  margin: 0 auto 40px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 1140px;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  min-height: calc(514px - 148px);
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 84px 16px 64px 16px;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.blueIntense};
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.gray};
    text-align: center;
    margin-top: 24px;
  }

  .container-input {
    width: 100%;
    display: flex;
    align-items: end;

    label {
      font-size: 19px;
      font-weight: 500;
      letter-spacing: -1.14px;
      margin-bottom: 16px;
    }

    select {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      padding-left: 12px;
      border-radius: 8px;
      border: 1px solid #c7c9c7;
    }
  }

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 48px;
    border-radius: 8px;

    :last-of-type {
      background: transparent;
      color: ${Color.gray};
      width: fit-content;
      height: fit-content;
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 84px 24px 64px 24px;

    h1 {
      white-space: nowrap;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(560px - 190px);
    padding: 64px 95px;
  }
`;

export const ContainerMaturityDay = styled.div`
  width: calc(100% + 32px);
  min-height: 32px;
  background: #f2f2f2;
  margin-top: 24px;

  p {
    width: 100%;
    text-align: center;
    color: ${Color.blueIntense};
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 5px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 48px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: 32px;
    width: calc(100% + 190px);

    p {
      width: auto;
    }
  }
`;
