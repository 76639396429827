import React, { FC, HTMLAttributes, ReactNode } from 'react';
import {
  Card,
  CardContainer,
  CardSubtitle,
  CardTitle,
  CardWrapper,
  Divider,
} from './styles';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  type?: '' | 'link' | 'full';
}

const MyCard: FC<CardProps> = ({ children, title, subtitle, type }) => {
  return (
    <Card type={type}>
      <CardContainer>
        <CardWrapper>
          {title && <CardTitle type={type}>{title}</CardTitle>}
          {type === 'full' && <Divider />}
          {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
          {children}
        </CardWrapper>
      </CardContainer>
    </Card>
  );
};

export default MyCard;
