import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

export const ContainerLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LabelText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #004b55;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AmountText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #004b55;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonVisible = styled.button`
  height: 19px;
  border: none;
  background: none;
  cursor: pointer;
`;
