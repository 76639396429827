import {
  IProjectAndLicenseInformationFCP,
  IRequestCreate,
  IRequestCreateFileCodesPropertyRegistration,
  IRequestGetDownloadFilePropertyRegistration,
  IRequestGetFileCodesPropertyRegistration,
  IRequestGetValidate,
  IRequestPatchProtocolPropertyRegistration,
  IRequestPostUploadFile,
  IRequestSetStep,
} from '~/store/modules/propertyRegistration/types';
import { api } from './api';

function listPropertyRegistrationService() {
  return new Promise((resolve, reject) => {
    try {
      const listPropertyRegistration: any = async () => {
        const response = await api.get('/v3/property-registration/cases/');

        return response.data;
      };
      resolve(listPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function getItemPropertyRegistrationService(data: IRequestGetValidate) {
  return new Promise((resolve, reject) => {
    try {
      const getItemPropertyRegistration: any = async () => {
        const response = await api.get(
          `/v3/property-registration/case/${data.case}`,
        );

        return response.data;
      };
      resolve(getItemPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function getStepPropertyRegistrationService(data: IRequestGetValidate) {
  return new Promise((resolve, reject) => {
    try {
      const getStepPropertyRegistration: any = async () => {
        const response = await api.get(
          `/v3/property-registration/case/${data.case}/step`,
        );

        return response.data;
      };
      resolve(getStepPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function setStepPropertyRegistrationService(data: IRequestSetStep) {
  return new Promise((resolve, reject) => {
    try {
      const setStepPropertyRegistration: any = async () => {
        const response = await api.patch(
          `/v3/property-registration/case/${data.case}/step`,
          {
            step: data.step,
          },
        );

        return response.data;
      };
      resolve(setStepPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function getValidatePropertyRegistrationService(data: IRequestGetValidate) {
  return new Promise((resolve, reject) => {
    try {
      const getValidatePropertyRegistration: any = async () => {
        const response = await api.get(
          `/v3/property-registration/cases/parent/${data.case}/validate`,
        );

        return response.data;
      };
      resolve(getValidatePropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function createPropertyRegistrationService(data: IRequestCreate) {
  return new Promise((resolve, reject) => {
    try {
      const createPropertyRegistration: any = async () => {
        const response = await api.post('/v3/property-registration/cases', data);

        return response.data;
      };
      resolve(createPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function patchPropertyRegistrationService(
  caseId: string,
  data: IProjectAndLicenseInformationFCP,
) {
  return new Promise((resolve, reject) => {
    try {
      const patchPropertyRegistration: any = async () => {
        const response = await api.patch(
          `/v3/property-registration/cases/${caseId}/project-license-information`,
          data,
        );

        return response.data;
      };
      resolve(patchPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function attachFilesPropertyRegistrationService(caseId: string, step: string) {
  return new Promise((resolve, reject) => {
    try {
      const attachFilesPropertyRegistration: any = async () => {
        const response = await api.patch(
          `/v3/property-registration/attach-files/case/${caseId}/step/${step}`,
        );

        return response.data;
      };
      resolve(attachFilesPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function uploadFilePropertyRegistrationService(data: IRequestPostUploadFile) {
  return new Promise((resolve, reject) => {
    try {
      console.log(data);
      const uploadFilePropertyRegistration: any = async () => {
        const response = await api.post('/v3/property-registration/file', data, {
          timeout: 360000,
        });

        return response.data;
      };
      resolve(uploadFilePropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function fileCodesPropertyRegistrationService(
  data: IRequestGetFileCodesPropertyRegistration,
) {
  return new Promise((resolve, reject) => {
    try {
      const fileCodesPropertyRegistration: any = async () => {
        const response = await api.get(
          `/v3/property-registration/file/code/case/${data.case}/type/${data.type}/document/${data.documentType}/`,
        );

        return response.data;
      };
      resolve(fileCodesPropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function getDataDownloadFilePropertyRegistrationService(
  data: IRequestGetDownloadFilePropertyRegistration,
) {
  return new Promise((resolve, reject) => {
    try {
      const getDataDownloadFilePropertyRegistration: any = async () => {
        const response = await api.get(
          `/v3/property-registration/file/${data.idFile}`,
        );

        return response.data;
      };
      resolve(getDataDownloadFilePropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function createFileCodePropertyRegistrationService(
  data: IRequestCreateFileCodesPropertyRegistration,
) {
  return new Promise((resolve, reject) => {
    try {
      const createFileCodePropertyRegistration: any = async () => {
        const response = await api.post('/v3/property-registration/file/code', {
          ...data,
        });

        return response.data;
      };
      resolve(createFileCodePropertyRegistration());
    } catch (error) {
      reject(error);
    }
  });
}

function patchProtocolPropertyRegistrationService(
  data: IRequestPatchProtocolPropertyRegistration,
) {
  return new Promise((resolve, reject) => {
    try {
      const patchProtocolCustomerObligation: any = async () => {
        const response = await api.patch(
          `/v3/property-registration/case/${data.caseId}/fcp/${data.caseFcp}`,
          {
            materialInspectionDate: data.materialInspectionDate,
          },
        );

        return response.data;
      };
      resolve(patchProtocolCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  listPropertyRegistrationService,
  createPropertyRegistrationService,
  getValidatePropertyRegistrationService,
  patchPropertyRegistrationService,
  uploadFilePropertyRegistrationService,
  getItemPropertyRegistrationService,
  getStepPropertyRegistrationService,
  attachFilesPropertyRegistrationService,
  fileCodesPropertyRegistrationService,
  getDataDownloadFilePropertyRegistrationService,
  createFileCodePropertyRegistrationService,
  patchProtocolPropertyRegistrationService,
  setStepPropertyRegistrationService,
};
