import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsColorStatus {
  color: string;
}

export const Container = styled.div`
  width: calc(100% - 8px);
  height: 102px;
  padding-right: 8px;
  background: ${Color.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  display: flex;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 88px;
  }
`;

export const Border = styled.div<IPropsColorStatus>`
  width: 8px;
  height: 100%;
  background: ${({ color }) => color};
  border-radius: 8px 0 0 8px;
  position: absolute;
`;

export const ContainerInfo = styled.div`
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  padding: 6px 0 6px 0;
  position: relative;
  margin-left: auto;
  justify-content: space-around;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: inherit;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.gray};
  margin-left: 8px;
  text-transform: uppercase;
  line-height: normal;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-left: 11px;
  }
`;

export const PaymentText = styled.p`
  width: 100%;
  border-top: 1px solid ${Color.grayLight};
  h2 {
    text-align: center;
    width: 50%;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${Color.black};
    text-transform: uppercase;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 16px 8px;
    span {
      padding: 0px 24px;
      background: ${Color.white};
      font-weight: inherit;
    }
  }
`;

export const AmountText = styled.p<IPropsColorStatus>`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${({ color }) => color};
  margin-left: 11px;
`;

export const DueDateText = styled.p<IPropsColorStatus>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({ color }) => color};
  margin-left: 11px;
  text-align: right;
`;

export const ButtonPayment = styled.button`
  width: 89px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 14px;
    width: 53px;
    line-height: 17px;
    color: #000;
  }

  svg {
    width: 12px;
    height: 10px;
    transform: rotate(180deg);
  }
`;

export const ButtonWrap = styled.div`
  position: absolute !important;
  bottom: 8px;
  right: 0;
`;
