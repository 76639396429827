import React from 'react';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}

const ConfirmedSVG = ({ color = '#37B482', width = 19, height = 15 }: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0466 0.47823C16.3749 0.168099 16.8118 -0.0033534 17.2652 4.97078e-05C17.7185 0.00345282 18.1527 0.181446 18.4763 0.496471C18.7998 0.811495 18.9874 1.23891 18.9994 1.68853C19.0114 2.13815 18.8468 2.5748 18.5405 2.90635L9.24078 14.4457C9.08087 14.6166 8.88787 14.7537 8.67331 14.8489C8.45876 14.9441 8.22707 14.9954 7.99208 14.9997C7.75709 15.004 7.52364 14.9613 7.30568 14.874C7.08773 14.7868 6.88974 14.6568 6.72356 14.4919L0.556372 8.37308C0.384626 8.2143 0.246873 8.02282 0.15133 7.81008C0.0557879 7.59733 0.0044133 7.36767 0.000272046 7.13479C-0.00386921 6.90192 0.0393078 6.6706 0.127226 6.45464C0.215145 6.23868 0.346004 6.0425 0.511998 5.87781C0.677992 5.71312 0.875719 5.58328 1.09338 5.49606C1.31105 5.40883 1.54419 5.36599 1.77891 5.3701C2.01362 5.3742 2.2451 5.42518 2.45953 5.51997C2.67395 5.61476 2.86694 5.75144 3.02698 5.92184L7.90759 10.7619L16.0023 0.529106C16.0169 0.511302 16.0324 0.494316 16.0489 0.47823H16.0466Z"
        fill={color}
      />
    </svg>
  );
};

export default ConfirmedSVG;
