import styled, { css } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
`;

interface IProps {
  color?: 'primary' | 'secondary' | 'white' | 'error';
  disabled?: boolean;
}

const options = {
  primary: css`
    background-color: ${Color.blueSeconde};
    color: white;
    &:hover {
      background-color: ${Color.blueIntense};
    }
  `,
  secondary: css`
    background-color: ${Color.green};
    color: white;
    &:hover {
      background-color: ${Color.greenDark};
    }
  `,
  white: css`
    background-color: ${Color.white};
    color: ${Color.blueSeconde};
    &:hover {
      background-color: ${Color.white};
    }
  `,
  disabled: css`
    background-color: ${Color.gray};
    color: white;
    &:hover {
      background-color: ${Color.gray};
      cursor: auto;
    }
  `,
  error: css`
    background-color: ${Color.red};
    color: white;
    &:hover {
      background-color: ${Color.red};
      cursor: auto;
    }
  `,
};

export const ButtonIconLeft = styled.button<IProps>`
  padding: 0px 36px;
  height: 48px;
  min-width: 280px;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px ${Color.black}25;
  ${({ color }: IProps): any => (color ? options[color] : options.primary)};
  &:hover {
    cursor: pointer;
  }
  transition: background-color 200ms;
  transition-timing-function: ease;
  ${({ disabled }: IProps): any => disabled && options.disabled};
  @media (max-width: ${Breakpoints.tablet.max}px) {
    min-width: 144px;
  }
  svg {
    height: 24px;
  }
  :disabled {
    opacity: 0.3;
  }
`;

export const Msg = styled.div`
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 400;
`;
