/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from './api';

function togglesMeServices() {
  return new Promise((resolve, reject) => {
    try {
      const getTogglesMe: any = async () => {
        // realizando a busca dos toggles disponíveis
        const response = await api.get('v3/users/me/toggles');
        return response.data;
      };
      resolve(getTogglesMe());
    } catch (error) {
      reject(error);
    }
  });
}

function togglesEnrollmentServices(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getTogglesEnrollment: any = async () => {
        // realizando a busca dos toggles disponíveis
        const response = await api.get(`v3/enrollments/${enrollmentId}/toggles`);
        return response.data;
      };
      resolve(getTogglesEnrollment());
    } catch (error) {
      reject(error);
    }
  });
}

export { togglesMeServices, togglesEnrollmentServices };
