export interface IFormFields {
  [field: string]: {
    name: string;
    label: string;
    requiredError: string;
  };
}

export default {
  formId: 'ownershipManagementForm',
  formFields: {
    city: {
      name: 'city',
      label: 'Cidade',
      requiredError: 'Campo obrigatório.',
    },
    enrollment: {
      name: 'enrollment',
      label: 'Matrícula',
      requiredError: 'Campo obrigatório.',
    },
    reason: {
      name: 'reason',
      label: 'Motivo da troca de titularidade',
      requiredError: 'Campo obrigatório.',
    },
    name: {
      name: 'name',
      label: 'Nome completo',
      requiredError: 'Campo obrigatório.',
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredError: 'Campo obrigatório.',
    },
    cnp: {
      name: 'cnp',
      label: 'CPF/CNPJ',
      requiredError: 'Campo obrigatório.',
    },
    phone: {
      name: 'phone',
      label: 'Celular*',
      requiredError: 'Campo obrigatório.',
    },
    contact: {
      name: 'contact',
      label: 'Telefone',
      requiredError: 'Campo obrigatório.',
    },
    delivery: {
      name: 'delivery',
      label: 'Entrega da fatura',
      requiredError: 'Campo obrigatório.',
    },
    files: {
      name: 'files',
      label: 'Arquivos',
      requiredError: 'Campo obrigatório.',
    },
  },
};
