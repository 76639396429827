import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsStyled {
  success: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div<IPropsStyled>`
  width: 100%;
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${({ success }) => (success ? Color.green : Color.blueIntense)};
  background: ${Color.white};
  overflow: hidden;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${({ success }) => (success ? Color.green : Color.blueIntense)};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  a {
    color: ${Color.blueSeconde};
    font-size: 16px;
    font-weight: 500;
  }

  button {
    height: 48px;
    background: ${Color.red};
    border-radius: 8px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: auto;
    border-radius: 16px;
    max-width: calc(392px - 58px);
    min-height: calc(336px - 64px);
    padding: 32px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const ContainerButtons = styled.div<IPropsStyled>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    filter: none;
    box-shadow: none;
    background: ${Color.green};

    ${({ success, disabled }) =>
      !success &&
      css`
        button {
          border: 1px solid #3f3131;
        }

        :first-of-type {
          background: ${disabled ? Color.grayLightSeconde : Color.blueIntense};
        }

        :last-of-type {
          border: none;
          background: ${Color.white};
          color: ${Color.blueIntense};
          text-decoration: underline;
          max-width: fit-content;
          margin: 0 auto;
        }
      `}
  }
`;
