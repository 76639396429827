import React from 'react';
import PendingDetails from './PendingDetails';
import { Container } from './styles';
import SummaryPendingIssues from './SummaryPendingIssues';

const MyPendingIssues = () => {
  return (
    <Container>
      <SummaryPendingIssues />
      <PendingDetails />
    </Container>
  );
};

export default MyPendingIssues;
