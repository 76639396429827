import React from 'react';

interface IProp {
  color?: string;
}

const SuccessSVG = ({ color = '#37B482' }: IProp) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M46.2244 24.7002L46.4244 22.5752L46.8744 17.7002L42.1244 16.6252L40.0244 16.1502L38.9244 14.3002L36.4494 10.1002L31.9744 12.0002L29.9994 12.8502L28.0244 12.0002L23.5494 10.0752L21.0744 14.2752L19.9744 16.1252L17.8744 16.6002L13.1244 17.6752L13.5744 22.5252L13.7744 24.6502L12.3744 26.2752L9.14941 29.9752L12.3744 33.6502L13.7744 35.2752L13.5744 37.4002L13.1244 42.3002L17.8744 43.3752L19.9744 43.8502L21.0744 45.7002L23.5494 49.8752L27.9994 47.9502L29.9994 47.1252L31.9744 47.9752L36.4244 49.9002L38.8994 45.7002L39.9994 43.8502L42.0994 43.3752L46.8494 42.3002L46.3994 37.4252L46.1994 35.3002L47.5994 33.6752L50.8244 30.0002L47.5994 26.3252L46.2244 24.7002ZM25.2244 41.8002L15.7244 32.2752L19.4244 28.5752L25.2244 34.4002L39.8494 19.7252L43.5494 23.4252L25.2244 41.8002Z"
        fill={color}
      />
      <path
        d="M57.499 29.9745L51.399 22.9995L52.249 13.7745L43.224 11.7245L38.499 3.74951L29.999 7.39951L21.499 3.74951L16.774 11.7245L7.74902 13.7495L8.59902 22.9995L2.49902 29.9745L8.59902 36.9495L7.74902 46.1995L16.774 48.2495L21.499 56.2495L29.999 52.5745L38.499 56.2245L43.224 48.2495L52.249 46.1995L51.399 36.9745L57.499 29.9745ZM47.624 33.6745L46.224 35.2995L46.424 37.4245L46.874 42.2995L42.124 43.3745L40.024 43.8495L38.924 45.6995L36.449 49.8995L31.999 47.9745L29.999 47.1245L28.024 47.9745L23.574 49.8995L21.099 45.7245L19.999 43.8745L17.899 43.3995L13.149 42.3245L13.599 37.4245L13.799 35.2995L12.399 33.6745L9.17402 29.9995L12.399 26.2995L13.799 24.6745L13.574 22.5245L13.124 17.6745L17.874 16.5995L19.974 16.1245L21.074 14.2745L23.549 10.0745L27.999 11.9995L29.999 12.8495L31.974 11.9995L36.424 10.0745L38.899 14.2745L39.999 16.1245L42.099 16.5995L46.849 17.6745L46.399 22.5495L46.199 24.6745L47.599 26.2995L50.824 29.9745L47.624 33.6745Z"
        fill={color}
      />
      <path
        d="M25.2246 34.3748L19.4246 28.5498L15.7246 32.2748L25.2246 41.7998L43.5746 23.3998L39.8746 19.6748L25.2246 34.3748Z"
        fill={color}
      />
    </svg>
  );
};

export default SuccessSVG;
