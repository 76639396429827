import React, { FC } from 'react';
import { AmountText, Container, LabelText } from './styles';
import formatter from '~/utils/formatter';

interface IBoxDetailProps {
  icon: React.ReactNode;
  label: string;
  amount: number;
}

const BoxDetail: FC<IBoxDetailProps> = ({ icon, label, amount }) => {
  return (
    <Container>
      <div>{icon}</div>
      <LabelText>{label}</LabelText>
      <AmountText>{formatter.formatCurrency(amount)}</AmountText>
    </Container>
  );
};

export default BoxDetail;
