import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsMain {
  status: 'info' | 'wait' | 'warning' | 'success';
  maxWidthButton?: number;
}

export const Main = styled.div<IPropsMain>`
  width: calc(100% - 40px);
  min-height: calc(102px - 48px);
  background: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid
    ${({ status }) =>
      status === 'info'
        ? Color.blueIntense
        : status === 'wait'
        ? Color.blueSeconde
        : status === 'warning'
        ? Color.yellow
        : status === 'success'
        ? Color.green
        : Color.blueIntense};
  border-radius: 16px;
  padding: 24px;

  button {
    color: ${Color.blueSeconde};
    font-size: 14px;
    font-weight: 500;
    line-height: 122.5%;
    letter-spacing: -0.35px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  div:last-of-type {
    margin-left: 24px;

    p {
      color: #000000;
    }

    p:first-of-type {
      color: ${({ status }) =>
        status === 'info'
          ? Color.blueIntense
          : status === 'wait'
          ? Color.blueSeconde
          : status === 'warning'
          ? Color.yellow
          : status === 'success'
          ? Color.green
          : Color.blueIntense};
      font-weight: 700;
      font-size: 16px;
      line-height: 122.5%;
    }

    p:last-of-type {
      margin-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 122.5%;
      letter-spacing: -0.025em;
    }

    b,
    a {
      font-weight: 700;
      color: #000000;
    }
  }

  div:has(+ button) {
    margin-right: 15px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 60px);
  }
`;
