import React from 'react';

const WarningIcon = () => {
  return (
    <svg viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6.41521V12.169M11.5754 15.6212C11.5754 15.939 11.3178 16.1966 11 16.1966C10.6822 16.1966 10.4246 15.939 10.4246 15.6212C10.4246 15.3034 10.6822 15.0458 11 15.0458C11.3178 15.0458 11.5754 15.3034 11.5754 15.6212ZM3.30463 20.2242H18.6954C20.4273 20.2242 21.5386 18.3834 20.732 16.8508L13.0366 2.22958C12.1738 0.590141 9.82623 0.590137 8.96336 2.22958L1.268 16.8508C0.46137 18.3834 1.57273 20.2242 3.30463 20.2242Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7.66667V7.66778M11 11V15.4444M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const LoadingIcon = () => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1V5.28571M11 16.7143V21M1 11H5.28571M18.1429 3.85714L14.5714 7.42857M3.85714 18.1429L7.42857 14.5714M16.7143 11H21M3.85714 3.85714L7.42857 7.42857M18.1429 18.1429L14.5714 14.5714"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.5L9.75 15.25L16 9M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C14.158 1.5 16.9741 2.96384 18.8067 5.25C20.1791 6.962 21 9.13513 21 11.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { WarningIcon, InfoIcon, LoadingIcon, SuccessIcon };
