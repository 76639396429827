import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import WalletVisaSVG from '~/assets/Icons/WalletVisa';
import WalletHiperCardSVG from '~/assets/Icons/WalletHiperCard';
import WalletDiscoverSVG from '~/assets/Icons/WalletDiscover';
import WalletMasterCardSVG from '~/assets/Icons/WalletMasterCard';
import WalletEloSVG from '~/assets/Icons/WalletElo';
import WalletAmericanExpressSVG from '~/assets/Icons/WalletAmericanExpress';

import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints } from '~/styles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';

import {
  CardNameText,
  CardNumberText,
  CardValidityText,
  ContainerInfo,
  DefaultTitle,
  EmptyText,
  EmptyTitle,
  HeaderCard,
  Main,
  TitleEmptyText,
} from './styles';

interface IProps {
  emptyCard?: boolean;
  isPrincipal?: boolean;
  id?: number;
  brand?: string;
  holder?: string;
  cardNumber?: string;
  validity?: string;
  type?: string;
  backgroundColor?: string;
}

const CreditCardWallet: FC<IProps> = ({
  emptyCard,
  isPrincipal,
  id,
  brand,
  holder,
  cardNumber,
  validity,
  type,
  backgroundColor,
}) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const brandCardIcon = {
    VISA: <WalletVisaSVG />,
    HIPERCARD: <WalletHiperCardSVG />,
    DISCOVER: <WalletDiscoverSVG />,
    MASTERCARD: <WalletMasterCardSVG />,
    ELO: <WalletEloSVG />,
    AMERICAN_EXPRESS: <WalletAmericanExpressSVG />,
  } as any;

  return (
    <Main
      backgroundColor={backgroundColor}
      onClick={() => {
        if (!id) return;
        Navigation.navigate(RouteName.WALLETDETAILCARD, {
          id,
          brand,
          holder,
          cardNumber,
          validity,
          type,
          isPrincipal,
        });
      }}
    >
      {emptyCard ? (
        <>
          <EmptyTitle>
            NENHUM CARTÃO <br />
            HABILITADO
          </EmptyTitle>
          <Margin height={isMobile ? 74 : 85} />
          <TitleEmptyText>-</TitleEmptyText>
          <ContainerInfo>
            <EmptyText>-</EmptyText>
            <CardValidityText>
              MÊS/ANO
              <br />
              <span>-</span>
            </CardValidityText>
          </ContainerInfo>
        </>
      ) : (
        <>
          <HeaderCard>
            <DefaultTitle>{isPrincipal && 'CARTÃO PRINCIPAL'}</DefaultTitle>
            {brandCardIcon[brand || '']}
          </HeaderCard>
          <Margin height={isMobile ? 74 : 85} />
          <CardNumberText>{`**** **** **** ${formatter.formatarNumber(
            cardNumber || '',
          )}`}</CardNumberText>
          <Margin height={13} />
          <ContainerInfo>
            <CardNameText>{holder}</CardNameText>
            <CardValidityText>
              MÊS/ANO
              <br />
              <span>{validity}</span>
            </CardValidityText>
          </ContainerInfo>
        </>
      )}
    </Main>
  );
};

export default CreditCardWallet;
