import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function ExitSVG({ color = Color.gray }: IProp) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 10V8H5V5L0 9L5 13V10H14Z" fill={color} />
      <path
        d="M18 0H9C7.897 0 7 0.897 7 2V6H9V2H18V16H9V12H7V16C7 17.103 7.897 18 9 18H18C19.103 18 20 17.103 20 16V2C20 0.897 19.103 0 18 0Z"
        fill={color}
      />
    </svg>
  );
}
export default ExitSVG;
