import React, { FC } from 'react';
import { Container } from './styles';
import { InvoiceChangeDueDateInfoIcon } from './icons';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface IProps {
  phone: string;
}

export const InvoiceChangeDueDateInfo: FC<IProps> = ({ phone }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  return (
    <Container>
      {isMobile ? (
        <>
          <div>
            <InvoiceChangeDueDateInfoIcon />
            <p className="title">Atenção</p>
          </div>
          <div>
            <p className="subtitle">
              Ao alterar a data de vencimento, ele será atualizado e deverá ocorrer
              entre 15 e 21 dias após a leitura do hidrômetro. Com isso, poderá haver
              2 faturas vencendo no próximo mês.
              <br />
              <br />
              Dúvidas? Entre em contato com a nossa Central de Atendimento (Telefone
              e WhatsApp): <span>{phone}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div>
            <InvoiceChangeDueDateInfoIcon />
          </div>
          <div>
            <p className="title">Atenção</p>
            <p className="subtitle">
              Ao alterar a data de vencimento, ele será atualizado e deverá ocorrer
              entre 15 e 21 dias após a leitura do hidrômetro. Com isso, poderá haver
              2 faturas vencendo no próximo mês.
              <br />
              <br />
              Dúvidas? Entre em contato com a nossa Central de Atendimento (Telefone
              e WhatsApp): <span>0800 400 0509</span>
            </p>
          </div>
        </>
      )}
    </Container>
  );
};
