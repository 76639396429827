import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';

import { IState } from '~/store/modules/types';
import { getErrorCloseEnrollmentsInvoicesDownload } from '~/store/modules/enrollmentsInvoices/actions';
import Payment from './Payment';
import { postErrorCloseEnrollmentsPayment } from '~/store/modules/enrollmentsPayment/actions';
import { mockPayment } from './mock';

const DebtNegotiationSinglePaymentDataPayment: FC = () => {
  const invoiceDetail = history.location?.state as any;

  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item?.enrollmentsPayment?.loading);
  const error = useSelector((item: IState) => item.enrollmentsPayment.error);

  const [openModalError, setOpenModalError] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '[AL][Web][Pagamento de Fatura]Escolher Pix',
    });
  }, []);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Pagamento único',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Dados de pagamento',
      active: true,
    },
  ];

  const loadingLabel = useMemo(() => {
    if (loading) return 'Processando...';
    return '';
  }, [loading]);

  useEffect(() => {
    dispatch(postErrorCloseEnrollmentsPayment());
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '“[AL][Web][Pagamento de Fatura]Escolher Pix”',
    });
  }, []);

  useEffect(() => {
    if (error && error?.state) {
      if (invoiceDetail?.typeView === 'detail') {
        ReactGA.event({
          category: 'Pagamento',
          action:
            '[QD][C4][WEB] – Cartão – Pg. de uma única dívida - Recusa no Pagamento',
        });
      } else {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[QD][C4][WEB] – Cartão – PG. Único - Recusa no Pagamento',
        });
      }

      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Pagamento sem Sucesso',
      });
      setOpenModalError(true);
    } else {
      setOpenModalError(false);
    }
  }, [error]);

  const handleDownloadBillet = (invoice_id: number) => {
    console.log(`baixar boleto${invoice_id}`);
  };

  return (
    <Payment
      links={links}
      loading={loading}
      loadingLabel={loadingLabel}
      openModalError={openModalError}
      paymentData={{ ...mockPayment, typeView: '' }}
      handleDownloadBillet={handleDownloadBillet}
    />
  );
};

export default DebtNegotiationSinglePaymentDataPayment;
