import { action } from 'typesafe-actions';

import {
  ICaseValues,
  IDataTriendSend,
  IFieldObject,
  ISelectedCharacters,
  RepositoriesTypesDpaDpe,
} from './types';

export const setSelectedFeature = (
  selectedFeature: ISelectedCharacters,
  type?: string,
) =>
  action(RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_SELECTED_FEATURE, {
    selectedFeature,
    type,
  });

export const setValuesObject = (data?: IFieldObject[]) => {
  return action(RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_VALUES_OBJECT, {
    data,
  });
};

export const setCaseValues = (data?: ICaseValues) =>
  action(RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_CASE_VALUES, {
    data,
  });

export const setAlreadyTriedSend = (data: IDataTriendSend) =>
  action(RepositoriesTypesDpaDpe.DPADPE_MENAGER_DATA_SET_TRIEND_SEND, {
    data,
  });
