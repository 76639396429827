import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';

interface IPageNumber {
  isActive: boolean;
}

interface IArrowButton {
  isNext?: boolean;
}

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const ContainerPageNumber = styled.div`
  display: flex;
  max-width: 140px;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 300px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
  }
`;

export const PageNumber = styled.button<IPageNumber>`
  width: 33px;
  height: 32px;
  color: #000;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #004b55;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #aad7e1;
    `}
`;

export const ArrowButton = styled.button<IArrowButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: #e9ecef;
  border-radius: 8px;

  ${({ isNext }) =>
    isNext &&
    css`
      transform: rotate(180deg);
    `}
`;
