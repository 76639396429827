/* eslint-disable no-nested-ternary */
import styled, { css, keyframes } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 16px 28px 16px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 35px 156px 35px;
    width: calc(100% - 70px);
    max-width: 870px;
    margin: 0 auto;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 24px auto;
  display: none;
  padding: 0 20px;
  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;

export const ContainerCardInvoice = styled.div`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

interface IPropsColorStatus {
  color: string;
}

export const HeaderStatus = styled.div<IPropsColorStatus>`
  background: ${({ color }) => color};
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: ${Color.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 20px;
    text-align: right;
    padding: 0 60px;
  }
`;

export const ContainerStatus = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    padding: 34px 60px;
  }
`;

export const TextStatus = styled.div<IPropsColorStatus>`
  color: ${({ color }) => color};
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    font-size: 28px;
  }
`;

export const ContainerAutomaticDebit = styled.div`
  width: 37%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${Color.green};
  }
`;

export const ContainerPaymentInvoice = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 37%;
  }
`;

export const TitlePayment = styled.section`
  width: 100%;
  border-top: 1px solid ${Color.grayLight};
  position: relative;
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueSeconde};
    width: fit-content;
    position: absolute;
    top: -10px;
    background: ${Color.white};
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 10px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    border-top: 3px solid ${Color.grayLight};
    h2 {
      font-size: 20px;
      left: auto;
      right: 48px;
      padding: 0 25px;
    }
  }
`;

export const QrCodeInfo = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: ${Color.black};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: ${Color.blueSeconde};
    }
  }
`;

export const QrCodeLabelInfo = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: ${Color.black};

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 12px;
  }
`;

export const CopyCodeBar = styled.button`
  height: 33px;
  border: 1px solid ${Color.gray};
  border-radius: 4px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.white};
  cursor: pointer;
  p {
    padding: 10px 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${Color.black};
    white-space: nowrap;
    width: 75vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 39px;
    p {
      font-size: 16px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const TitlePaymentMethods = styled.section`
  width: 100%;
  height: 48px;
  border-top: 1px solid ${Color.grayLightSeconde};
  position: relative;

  h1,
  h2 {
    width: fit-content;
    position: absolute;
    top: -10px;
    background: ${Color.white};
    margin: auto;
    padding: 0 10px;
    left: 0;
    right: 0;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: ${Color.blueIntense};
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    color: ${Color.blueIntense};
    width: 157px;
    text-align: center;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    h1,
    h2 {
      padding: 0 20px;
      left: inherit;
      right: inherit;
    }

    h1 {
      font-size: 20px;
      left: 70px;
    }

    h2 {
      font-size: 16px;
      left: 70px;
      width: fit-content;
    }
  }
`;

export const ContainerProcessing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${Color.blueSeconde};
  text-align: center;
`;

export const ContainerPaymentProcess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${Color.green};
  text-align: center;
`;

export const InvoiceWrapper = styled.div`
  padding: 40px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  > :nth-child(1) {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    grid-template-columns: 1fr;
    button {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div<{ hasPix: boolean }>`
  display: grid;
  padding: 0 20px;
  grid-template-columns: ${({ hasPix }) => (hasPix ? '200px 1fr' : '1fr')};
  gap: 40px;
  margin-bottom: 16px;

  .pix-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    display: inherit;
    grid-template-columns: 1fr;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 40px;
    grid-template-columns: ${({ hasPix }) => (hasPix ? '200px 1fr' : '1fr')};

    .pix-code {
      max-width: 256px;
      flex-direction: column;
    }
  }

  @media (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    padding: 0 60px;
    grid-template-columns: ${({ hasPix }) => (hasPix ? '256px 1fr' : '1fr')};

    .pix-code {
      max-width: 256px;
    }
  }
`;

interface IInvoiceAmountProps {
  color: string;
}

export const InvoiceAmount = styled.div<IInvoiceAmountProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    font-size: 28px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 0 20px;
  margin-bottom: 64px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid ${Color.blueSeconde};

    :first-of-type {
      order: 2;
    }

    :last-of-type {
      order: 1;

      svg {
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 0;
    padding: 0 40px;

    button {
      max-width: 252px !important;

      :first-of-type {
        order: 1;
      }

      :last-of-type {
        order: 2;
      }
    }
  }
`;

export const ContainerQRCode = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 256px;
  }
`;

export const ContainerPositionBorderQRCode = styled.div<{
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}>`
  position: absolute;
  ${({ position }) =>
    position === 'top-left' &&
    css`
      top: 0;
      left: 0;
    `}

  ${({ position }) =>
    position === 'top-right' &&
    css`
      top: 0;
      right: 0;
      transform: rotate(90deg);
    `}

    ${({ position }) =>
    position === 'bottom-left' &&
    css`
      bottom: 0;
      left: 0;
      transform: rotate(270deg);
    `}

    ${({ position }) =>
    position === 'bottom-right' &&
    css`
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    `}
`;

export const ContainerMessage = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0 auto;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 60px);
  }

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.max}px) {
    width: calc(100% - 80px);
  }
`;

export const ContainerButtonsInvoice = styled.div<{
  fullButton: boolean;
  receiptExists: boolean;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 16px;

  button {
    margin: 0 auto !important;
  }

  ${({ fullButton }) =>
    fullButton &&
    css`
      grid-template-columns: 1fr;
    `}

  ${({ receiptExists }) =>
    receiptExists
      ? css`
          grid-template-columns: 1fr 1fr;
        `
      : css`
          grid-template-columns: 0.5fr;

          @media screen and (min-width: ${Breakpoints.laptop.min}px) {
            grid-template-columns: 0.5fr;
          }
        `}
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface IPropsContainerCodeBarLabelInvoice {
  copied: boolean;
  clicked: boolean;
  receiptExists?: boolean;
}

export const ButtonCodeInvoice = styled.button<IPropsContainerCodeBarLabelInvoice>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ copied }) => (copied ? Color.green : '#e9ecef')};
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & + & {
    margin: 8px 16px 0 0;
  }

  ${({ clicked }) =>
    clicked &&
    css`
      width: 32px;
      height: 32px;
      margin: 8px auto 0 auto !important;
      padding: 0;
      border: 1px solid ${Color.grayDark};
      border-color: ${Color.grayDark};
      border-radius: 50px;
      border-width: 3px;
      font-size: 0;
      border-left-color: ${Color.green};
      animation: ${rotating} 2s 0.25s linear infinite;
    `}

  ${({ copied }) =>
    copied &&
    css`
      background: ${Color.green};
    `}

  
    ${({ receiptExists }) =>
    receiptExists &&
    css`
      height: auto;
      min-height: 32px;
      flex-direction: column;
      padding: 9.5px;
      p {
        order: 2;
      }
      @media screen and (min-width: ${Breakpoints.laptop.min}px) {
        flex-direction: row;

        p {
          order: inherit;
        }
      }
    `}

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 36px;

    ${({ clicked }) =>
      clicked &&
      css`
        height: 32px;
      `}
  }
`;

export const ContentSinglePayment = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 60px);
  }

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.max}px) {
    width: calc(100% - 80px);
  }
`;
