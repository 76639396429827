import React, { FC } from 'react';

const EyeVisibleIcon = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.542 8C13.542 8.79565 13.2259 9.55871 12.6633 10.1213C12.1007 10.6839 11.3376 11 10.542 11C9.74634 11 8.98328 10.6839 8.42067 10.1213C7.85806 9.55871 7.54199 8.79565 7.54199 8C7.54199 7.20435 7.85806 6.44129 8.42067 5.87868C8.98328 5.31607 9.74634 5 10.542 5C11.3376 5 12.1007 5.31607 12.6633 5.87868C13.2259 6.44129 13.542 7.20435 13.542 8Z"
        stroke="#37B4C8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.054 8C3.28947 11.4965 6.62431 14 10.542 14C14.4597 14 17.7945 11.4965 19.03 8C17.7945 4.50354 14.4597 2 10.542 2C6.62522 2 3.2895 4.50359 2.054 8ZM0.0459352 7.7004C1.44708 3.23851 5.61577 0 10.542 0C15.4693 0 19.6369 3.23856 21.0381 7.7004C21.0993 7.89544 21.0993 8.10456 21.0381 8.2996C19.6369 12.7614 15.4693 16 10.542 16C5.61471 16 1.44706 12.7614 0.0459352 8.2996C-0.0153117 8.10456 -0.0153117 7.89544 0.0459352 7.7004Z"
        fill="#37B4C8"
      />
    </svg>
  );
};

const EyeInvisibleIcon = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.954545 1.34056L2.17636 0L18.1364 17.6489L16.9241 19L13.9841 15.7489C12.8864 16.15 11.7218 16.3611 10.5 16.3611C5.72727 16.3611 1.65136 13.0783 0 8.44444C0.658636 6.58667 1.70864 4.95056 3.045 3.65222L0.954545 1.34056ZM10.5 5.27778C11.2595 5.27778 11.9879 5.61141 12.5249 6.20527C13.0619 6.79914 13.3636 7.60459 13.3636 8.44444C13.3641 8.80393 13.3092 9.16088 13.2014 9.5L9.54545 5.45722C9.85212 5.33793 10.1749 5.27724 10.5 5.27778ZM10.5 0.527778C15.2727 0.527778 19.3486 3.81056 21 8.44444C20.2205 10.6325 18.8967 12.5319 17.1818 13.9228L15.8264 12.4133C17.1465 11.4036 18.2111 10.0374 18.9191 8.44444C18.1475 6.70264 16.9494 5.23517 15.4611 4.20887C13.9728 3.18258 12.254 2.63864 10.5 2.63889C9.45955 2.63889 8.43818 2.82889 7.48364 3.16667L6.01364 1.55167C7.38818 0.897222 8.90591 0.527778 10.5 0.527778ZM2.08091 8.44444C2.85251 10.1863 4.05057 11.6537 5.53888 12.68C7.02719 13.7063 8.74603 14.2503 10.5 14.25C11.1586 14.25 11.8077 14.1761 12.4091 14.0283L10.2327 11.6111C9.56852 11.5324 8.9487 11.2046 8.47634 10.6823C8.00398 10.1599 7.70756 9.4745 7.63636 8.74L4.39091 5.14056C3.44591 6.03778 2.65364 7.15667 2.08091 8.44444Z"
        fill="#37B4C8"
      />
    </svg>
  );
};

const EllipseIcon: FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="6"
      height="7"
      viewBox="0 0 6 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3.5" r="3" fill={color} />
    </svg>
  );
};

export { EyeVisibleIcon, EyeInvisibleIcon, EllipseIcon };
