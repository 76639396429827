/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { Container } from './styles';
import { SuccessIcon } from './icons';

interface IProps {
  password: string;
  isValid: (check: boolean) => void;
}

const PasswordRequirements: React.FC<IProps> = ({ password, isValid }) => {
  const [strengthLevelLabel, setStrengthLevelLabel] = useState<string>('fraca');
  const [strengthLevel, setStrengthLevel] = useState<number>(0);
  const [mandatoryRequirement, setMandatoryRequirement] = useState<boolean>(false);

  const checkRequirements = () => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#()$%¨&*]/;
    const minLengthRegex = /.{8,}/;

    let strengthLevelCount = 0;

    if (minLengthRegex.test(password)) strengthLevelCount++;
    if (uppercaseRegex.test(password)) strengthLevelCount++;
    if (lowercaseRegex.test(password)) strengthLevelCount++;
    if (numberRegex.test(password)) strengthLevelCount++;
    if (specialCharRegex.test(password)) strengthLevelCount++;

    setMandatoryRequirement(minLengthRegex.test(password));
    setStrengthLevel(strengthLevelCount);
  };

  useEffect(() => {
    checkRequirements();
  }, [password]);

  useEffect(() => {
    const strengthLabel =
      mandatoryRequirement && strengthLevel >= 4 && strengthLevel < 5
        ? 'moderada'
        : mandatoryRequirement && strengthLevel === 5
        ? 'forte'
        : 'fraca';

    setStrengthLevelLabel(strengthLabel);

    if (mandatoryRequirement && strengthLevel >= 4) {
      isValid(true);
    } else {
      isValid(false);
    }
  }, [mandatoryRequirement, strengthLevel]);

  return (
    <>
      <PasswordStrengthIndicator
        strengthLevel={strengthLevel}
        mandatoryRequirement={mandatoryRequirement}
      />
      <Container>
        {password && mandatoryRequirement && strengthLevel >= 2 && (
          <p>
            Senha {strengthLevelLabel}{' '}
            {mandatoryRequirement && strengthLevel >= 4 && <SuccessIcon />}
          </p>
        )}
        {!(mandatoryRequirement && strengthLevel >= 4) && (
          <ul>
            <li>
              Aumente a segurança da sua senha <br /> utilizando:
            </li>
            <li>8-16 caracteres</li>
            <li>Letra maiúscula</li>
            <li>Letra minúscula</li>
            <li>Numeral</li>
            <li>Símbolo (!@#$%¨&*())</li>
          </ul>
        )}
      </Container>
    </>
  );
};

export default PasswordRequirements;
