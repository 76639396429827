import React, { FC } from 'react';
import { Bar, BarChartContainer } from './styles';
import { Color } from '~/styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const BarChart: FC = () => {
  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const overdueInvoices = dataDischargeDebts?.info?.total?.expiredQuantity || 0;
  const pendingInvoices = dataDischargeDebts?.info?.total?.pendingQuantity || 0;

  const maxValue = Math.max(overdueInvoices, pendingInvoices);

  return (
    <BarChartContainer>
      <Bar color={Color.red} value={overdueInvoices} maxValue={maxValue} />
      <Bar color={Color.yellow} value={pendingInvoices} maxValue={maxValue} />
    </BarChartContainer>
  );
};

export default BarChart;
