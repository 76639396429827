import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsCard {
  isBorder?: boolean;
  height?: number;
  hiddenPadding?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 22px 10px 68px 10px;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 24px 24px 47px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
    padding: 24px 0 90px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 0 24px 0;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;
export const Card = styled.section<IPropsCard>`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  border-top: 25px solid ${Color.blueIntense};
  border-radius: 24px;
  padding-bottom: 40px;

  h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    color: ${Color.blueIntense};
    margin-top: 24px;
  }

  .info {
    color: ${Color.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 870px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    margin: 0;
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.blueIntense};
    text-align: left;
    background: ${Color.white};
    padding: 0 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h2 {
      position: absolute;
      left: 20px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    h2 {
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const Content = styled.div`
  padding: 0 8px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 80px;
  }
`;

export const ContainerDocuments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowDocument = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  :last-of-type {
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    max-width: 335px;
  }
`;

export const Label = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.gray};
  font-style: italic;

  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    margin-top: 0;
    margin-left: 24px;
  }
`;

export const ContainerButtons = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: none;
    border-radius: 9px;
    svg {
      right: 16px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-top: 56px;

    button {
      max-width: 252px;
    }
  }
`;
