import styled from 'styled-components';
import { Field } from 'formik';
import { Color } from '~/styles';

export const InputWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputLabel = styled.label`
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const CustomField = styled(Field)<{ error: boolean }>`
  -webkit-appearance: none;
  border-radius: 0px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  padding: 0px 12px;
  height: 36px;
  width: 100%;
  outline: none;
  border: ${(p) =>
    p.error ? `2px solid ${Color.red}` : `1px solid ${Color.grayLightSeconde}`};
  box-sizing: border-box;
`;

export const HelperMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.red};
  align-self: flex-end;
`;
