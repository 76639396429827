import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Wrapper = styled.div`
  position: relative;
  height: min-content;
`;

export const Icon = styled.div`
  min-height: 24px;
  height: 24px;
  min-width: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.blueSeconde};
  color: ${Color.white};
  font-size: 16px;
  font-weight: 500;
  border-radius: 50%;
  position: relative;
  z-index: 9;
  &:hover {
    cursor: pointer;
  }
`;

export const Message = styled.div`
  padding: 12px 12px 40px 12px;
  width: 240px;
  line-height: 1.2;
  color: ${Color.white};
  background: ${Color.grayLightSeconde};
  border-radius: 12px;
  position: absolute;
  bottom: 0%;
  box-shadow: 0px 4px 4px 0px #00000025;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    right: 0;
  }
`;
