import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const PixSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8515 14.8906C12.1156 14.6157 12.5703 14.6157 12.8343 14.8906L16.599 18.8105C17.2933 19.5334 18.2174 19.9305 19.1952 19.9305H19.9335L15.186 24.8737C13.7046 26.3754 11.2991 26.3754 9.81762 24.8737L5.05058 19.9152H5.50529C6.48314 19.9152 7.40721 19.5181 8.10148 18.7952L11.8515 14.8906ZM12.8343 11.1438C12.5214 11.4237 12.1205 11.4288 11.8515 11.1438L8.10148 7.2391C7.40721 6.47039 6.48314 6.11912 5.50529 6.11912H5.05058L9.81273 1.15864C11.2991 -0.386214 13.7046 -0.386214 15.186 1.15864L19.9384 6.10385H19.1952C18.2174 6.10385 17.2933 6.50093 16.599 7.22383L12.8343 11.1438ZM5.50529 7.26456C6.18 7.26456 6.80094 7.54964 7.3192 8.04854L11.0693 11.9532C11.4213 12.2739 11.8809 12.503 12.3454 12.503C12.805 12.503 13.2645 12.2739 13.6166 11.9532L17.3813 8.03327C17.8605 7.53946 18.5205 7.25437 19.1952 7.25437H21.0385L23.8889 10.2223C25.3704 11.7648 25.3704 14.2695 23.8889 15.812L21.0385 18.78H19.1952C18.5205 18.78 17.8605 18.4949 17.3813 17.996L13.6166 14.0761C12.937 13.3684 11.7489 13.3684 11.0693 14.0811L7.3192 17.9807C6.80094 18.4796 6.18 18.7647 5.50529 18.7647H3.94952L1.11277 15.812C-0.370924 14.2695 -0.370924 11.7648 1.11277 10.2223L3.94952 7.26456H5.50529Z"
        fill={fill || '#37B4C8'}
      />
    </svg>
  );
};

export default PixSVG;
