import React from 'react';

function IguaLogoSVG() {
  return (
    <svg
      width="170"
      height="56"
      viewBox="0 0 170 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.8432 7.87344C87.1304 17.108 83.9242 26.9619 80.9012 36.8134C80.4763 38.1966 80.4983 40.2253 81.519 41.3856C82.5543 42.5624 83.575 41.1554 83.8729 40.1873C86.7568 30.789 89.7822 21.4074 94.2753 12.5999C94.981 11.2142 94.9883 9.2591 94.2753 7.87344C93.6502 6.66098 92.4658 6.65387 91.8432 7.87344Z"
        fill="black"
      />
      <path
        d="M112.129 30.9019C112.406 28.4693 111.952 25.9091 110.663 23.9753C108.914 21.3488 105.724 20.6751 102.942 22.0037C101.128 22.8713 101.128 28.2542 103.386 28.5426C105.192 28.7766 107.488 28.675 108.422 30.4267C108.058 31.0296 107.564 31.5615 107.027 31.9988C103.984 34.4669 98.7803 35.2068 95.9337 32.0981C93.5031 29.4409 95.7634 25.7743 97.8511 23.7223C100.703 20.9257 104.968 19.5332 108.848 19.077C111.259 18.7909 111.035 12.1598 108.848 12.4175C103.216 13.0818 97.3286 15.5404 94.1344 20.4481C90.5619 25.9327 90.2616 35.0437 95.8793 39.3037C99.7993 42.2753 105.426 40.7411 108.857 37.5094C108.838 37.8475 108.826 38.1808 108.826 38.5047C108.819 39.4479 109.108 41.8332 110.491 41.8332C111.883 41.8332 112.148 39.4479 112.155 38.5047C112.17 35.9988 112.363 33.4172 112.129 30.9019Z"
        fill="black"
      />
      <path
        d="M115.678 14.1733C115.685 14.4355 115.683 14.6954 115.676 14.9576C115.657 15.3167 115.664 15.2482 115.694 14.7521C115.678 15.0025 115.655 15.2506 115.63 15.501C115.541 16.4744 115.416 17.443 115.296 18.4116C115.025 20.5804 114.754 22.7492 114.6 24.9321C114.283 29.4256 114.072 34.961 116.957 38.6843C120.521 43.2888 126.491 39.2914 128.555 35.2799C130.238 32.0055 131.255 28.19 132.238 24.6392C133.364 20.5709 134.215 16.4271 135.041 12.2856C135.275 11.1138 135.088 8.85763 133.921 8.19377C132.65 7.46612 132.11 9.72231 131.951 10.5161C130.654 17.0248 129.187 23.6115 126.666 29.7445C125.79 31.8755 123.995 33.7821 121.682 34.0514C120.911 34.1412 120.135 33.9923 119.445 33.6474C118.977 33.4135 118.444 32.7969 118.358 32.6481C117.958 31.9488 118.017 31.9771 117.803 31.0368C117.937 31.6275 117.739 30.4533 117.723 30.3399C117.7 30.1296 117.68 29.9194 117.664 29.7067C117.685 30.054 117.687 30.021 117.666 29.6122C117.573 27.4411 117.707 26.1819 117.907 24.2257C118.246 20.9064 118.97 17.5233 118.883 14.1733C118.861 13.2306 118.635 10.8445 117.282 10.8445C115.96 10.8445 115.655 13.2306 115.678 14.1733Z"
        fill="black"
      />
      <path
        d="M168.263 20.2838C163.493 20.628 158.57 20.6779 153.698 21.0459C153.495 18.7169 153.174 16.3974 152.669 14.1064C152.277 12.3282 151.724 10.3055 150.454 8.94041C148.763 7.12424 146.832 8.41811 145.41 9.90429C143.186 12.2285 141.674 15.303 140.173 18.1186C138.964 20.3811 137.745 22.6365 136.529 24.8943C136.352 24.975 136.175 25.0533 135.998 25.1388C135.197 25.5234 134.815 26.8007 134.789 28.1254C133.984 29.6235 133.178 31.1239 132.38 32.6267C131.665 33.9752 131.707 36.0026 132.38 37.3559C132.999 38.5999 134.102 38.5429 134.732 37.3559C135.854 35.2429 136.987 33.1347 138.121 31.0289C142.111 29.3196 146.35 28.4578 150.669 27.9806C150.683 29.1415 150.685 30.3428 150.688 31.6129C150.692 34.6328 150.548 37.6503 150.534 40.6701C150.529 41.6174 150.813 44.0128 152.196 44.0128C153.585 44.0128 153.854 41.615 153.859 40.6701C153.88 36.3682 154.05 32.0165 153.998 27.6815C158.778 27.3372 163.602 27.3087 168.261 26.9716C170.648 26.8007 170.511 20.1224 168.263 20.2838ZM142.553 22.76C143.625 20.7301 144.728 18.7169 146.097 16.8722C146.614 16.1766 147.488 14.8353 148.43 14.6691C148.853 14.5955 148.621 14.6596 148.666 14.6786C148.763 14.7213 148.824 14.7427 148.862 14.7546C148.853 14.7712 148.867 14.8163 148.959 14.9231C148.978 14.9469 148.992 14.9682 149.004 14.9848C149.046 15.0679 149.089 15.1487 149.131 15.2317C149.167 15.303 149.2 15.3742 149.233 15.4478C149.207 15.3956 149.349 15.7683 149.379 15.8609C149.5 16.2336 149.594 16.6111 149.686 16.9886C149.701 17.0503 149.712 17.0954 149.722 17.1334C149.724 17.1548 149.729 17.169 149.734 17.1951C149.788 17.4634 149.833 17.7317 149.878 17.9999C150.076 19.1585 150.225 20.2672 150.338 21.3593C147.703 21.656 145.098 22.0905 142.553 22.76ZM149.264 15.5166C149.257 15.5 149.25 15.4858 149.245 15.4739C149.252 15.4882 149.259 15.5024 149.264 15.5166Z"
        fill="black"
      />
      <path
        d="M152.564 6.7292C154.206 4.39685 155.627 0.906199 160.172 0.511839C161.462 0.399164 163.491 0.275223 164.168 0.949015C164.605 1.38168 164.678 2.69772 164.339 3.1687C163.558 4.24587 160.112 5.0774 158.521 5.66331C156.603 6.36865 154.801 6.5985 152.73 6.96131"
        fill="#37B4C8"
      />
      <path
        d="M1.47009 48.1944C-1.17483 49.4083 0.20451 43.369 2.04837 42.5201C13.6448 37.1924 26.6585 33.8552 39.5252 34.2343C52.0151 34.6017 63.3721 38.7133 74.9425 42.9411C86.6929 47.234 99.7469 51.7269 112.445 49.506C123.542 47.5642 132.853 41.3411 143.357 37.7691C146.201 36.8017 144.701 42.7899 142.779 43.4434C132.266 47.02 122.845 53.4013 111.744 55.3454C99.5407 57.4802 87.1266 54.0524 75.7151 49.9758C63.7798 45.7131 51.8895 40.6318 38.9919 40.2504C26.0304 39.8667 13.185 42.8155 1.47009 48.1944Z"
        fill="#37B4C8"
      />
      <path
        d="M5.83006 28.599C17.5485 23.3525 30.3979 20.4775 43.3634 20.8494C48.6714 21.0013 53.8064 21.9377 58.8466 23.2663C58.1614 22.6632 57.4194 22.1009 56.5778 21.584C47.5477 16.0336 43.7806 15.6595 37.4579 15.6799C30.8602 15.6799 15.844 18.8405 6.40615 23.0668C4.5641 23.8944 3.18434 29.7848 5.83006 28.599Z"
        fill="#E6735F"
      />
      <path
        d="M60.9609 27.8042C54.3164 25.8232 48.187 24.6342 41.0483 24.4255C28.3956 24.0556 15.5959 27.314 4.19467 32.5103C2.37914 33.3385 1.02506 39.2313 3.626 38.0469C15.1461 32.7984 27.7779 29.9212 40.524 30.2934C53.0812 30.661 64.6666 35.5214 76.2822 39.6534C68.7358 31.7342 65.6594 29.2859 60.9609 27.8042Z"
        fill="#37B482"
      />
    </svg>
  );
}
export default IguaLogoSVG;
