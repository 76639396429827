import React, { FC } from 'react';
import { Container } from './styles';
import { BannerApp } from '~/components/BannerApp';

const Banner: FC = () => {
  return (
    <Container>
      <BannerApp />
    </Container>
  );
};

export { Banner };
