import styled, { css } from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface Props {
  color?: 'primary' | 'secondary' | 'error';
  disabled?: boolean;
}

const options = {
  primary: css`
    background-color: ${Color.blueSeconde};
    &:hover {
      background-color: ${Color.blueIntense};
    }
  `,
  secondary: css`
    background-color: ${Color.green};
    &:hover {
      background-color: ${Color.greenDark};
    }
  `,
  disabled: css`
    background-color: ${Color.gray};
    &:hover {
      background-color: ${Color.gray};
      cursor: auto;
    }
  `,
  error: css`
    background-color: ${Color.red};
    &:hover {
      background-color: ${Color.red};
      cursor: auto;
    }
  `,
};

export const BtnLarge = styled.button<Props>`
  padding: 0px 12px;
  height: 48px;
  min-width: 200px;
  color: white;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 9px;
  ${({ color }: Props): any => (color ? options[color] : options.primary)};
  &:hover {
    cursor: pointer;
  }
  transition: background-color 200ms;
  transition-timing-function: ease;
  ${({ disabled }: Props): any => disabled && options.disabled};
  @media (max-width: ${Breakpoints.tablet.max}px) {
    min-width: 144px;
  }
`;

export const BtnMedium = styled.button`
  height: 40px;
  max-width: 240px;
  min-width: 144px;
  padding: 8px 24px;
  color: white;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  ${({ color }: Props): any => (color ? options[color] : options.primary)};
  &:hover {
    cursor: pointer;
  }
  transition: background-color 200ms;
  transition-timing-function: ease;
  ${({ disabled }: Props): any => disabled && options.disabled};
`;

export const BtnSmall = styled.button<Props>`
  min-width: 144px;
  padding: 8px 12px;
  color: white;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 16px;
  ${({ color }: Props): any => (color ? options[color] : options.primary)};
  &:hover {
    cursor: pointer;
  }
  transition: background-color 200ms;
  transition-timing-function: ease;
  ${({ disabled }: Props): any => disabled && options.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BtnText = styled.button<Props>`
  background: none;
  border: none;
  color: ${Color.blueSeconde};
  font-family: 'Montserrat';
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;
