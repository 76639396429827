import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  max-width: calc(320px - 80px);
  padding: 40px;
  border-radius: 24px;
  background: ${Color.white};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;

export const CloseIconButton = styled.button`
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  background: transparent;
  z-index: 99999999;
`;
