import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  label {
    margin-right: 24px;
    :last-of-type {
      margin-right: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: ${Color.blueIntense};
    }
  }
`;

export const ContainerAlert = styled.div`
  margin: 24px auto;

  button {
    color: ${Color.blueSeconde};
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  div {
    padding: 24px;
    p + p {
      margin-top: 4px;
    }

    div {
      padding: initial;
    }
  }
`;
