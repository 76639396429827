import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import EmptyMessage from '~/components/EmptyMessage';
import SkeletonCustom from '~/components/Skeleton';

import { Breakpoints } from '~/styles';
import Chart from '~/components/ChartV3';
import HistorySubtitleEllipseIconSVG from '~/assets/Icons/HistorySubtitleEllipse';
import HistorySubtitleLineBoldIconSVG from '~/assets/Icons/HistorySubtitleLineBold';
import HistorySubtitleLineIconSVG from '~/assets/Icons/HistorySubtitleLine';
import {
  Container,
  ContainerEmpty,
  ContainerHistoryConsumer,
  HistoryConsumer,
  RowChart,
  SubtitleHistory,
} from './styles';
import Filters from '../Filters';

interface IProps {
  filterActiveType: 'daily' | 'monthly';
  setFilterActiveType: (type: 'daily' | 'monthly') => void;
  filterValue: number;
  setFilterValue: (value: number) => void;
}

const ConsumptionHistorySession = ({
  filterActiveType,
  setFilterActiveType,
  filterValue,
  setFilterValue,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const loading = useSelector(
    (item: IState) => item.enrollmentsConsumptionHistory.loading,
  );
  const consumptionData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data,
  );

  const average = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.average,
  );
  const averageIot = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.averageIot,
  );
  const totalHistoryData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.totalHistoryData,
  );
  const totalHistoryIotData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data?.totalHistoryIotData,
  );

  return (
    <>
      <Filters
        filterActiveType={filterActiveType}
        setFilterActiveType={setFilterActiveType}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
      <Margin height={31} />
      <Container>
        <ContainerHistoryConsumer>
          <HistoryConsumer>
            <RowChart>
              {loading ? (
                <div style={{ width: '100%' }}>
                  <SkeletonCustom height={isMobile ? 155 : 260} />
                </div>
              ) : consumptionData && consumptionData?.historyData?.length > 0 ? (
                <>
                  <Chart
                    dataChart={
                      filterActiveType === 'daily'
                        ? consumptionData?.iotData
                        : consumptionData?.historyData
                    }
                    average={filterActiveType === 'daily' ? averageIot : average}
                  />
                  <SubtitleHistory>
                    <div id="legend">
                      <p>
                        <div>
                          <HistorySubtitleEllipseIconSVG />
                        </div>
                        Consumo real
                      </p>
                      <p>
                        <div>
                          <HistorySubtitleLineBoldIconSVG />
                        </div>
                        Média do período
                      </p>
                      <p>
                        <div>
                          <HistorySubtitleLineIconSVG />
                        </div>
                        Alerta de consumo
                      </p>
                    </div>
                    <div id="total">
                      <p>
                        Valor total consumido no período:{' '}
                        {filterActiveType === 'daily'
                          ? totalHistoryIotData
                          : totalHistoryData}
                      </p>
                    </div>
                  </SubtitleHistory>
                </>
              ) : (
                <ContainerEmpty>
                  <Margin height={150} />
                  <EmptyMessage>
                    Você ainda não possui <br /> histórico detalhado!
                  </EmptyMessage>
                  <Margin height={60} />
                </ContainerEmpty>
              )}
            </RowChart>
          </HistoryConsumer>
        </ContainerHistoryConsumer>
      </Container>
    </>
  );
};

export default ConsumptionHistorySession;
