import React, { FC } from 'react';
import { ContainerQRCode } from './styles';
import { ContainerPositionBorderQRCode } from '~/views/SimplifiedInvoiceV4/styles';
import { BorderQrCode } from '~/views/SimplifiedInvoiceV4/icons';
import { QRCode } from '~/components/QRCode';

interface IDescriptionPaymentProps {
  qr_code_key: string;
}

export const QrCode: FC<IDescriptionPaymentProps> = ({ qr_code_key }) => {
  return (
    <ContainerQRCode>
      <ContainerPositionBorderQRCode position="top-left">
        <BorderQrCode />
      </ContainerPositionBorderQRCode>
      <ContainerPositionBorderQRCode position="top-right">
        <BorderQrCode />
      </ContainerPositionBorderQRCode>
      <QRCode codeValue={qr_code_key} />
      <ContainerPositionBorderQRCode position="bottom-left">
        <BorderQrCode />
      </ContainerPositionBorderQRCode>
      <ContainerPositionBorderQRCode position="bottom-right">
        <BorderQrCode />
      </ContainerPositionBorderQRCode>
    </ContainerQRCode>
  );
};
