export const mockNegotiation = [
  {
    id: 8374169,
    type_payment: 'Parcelamento',
    amount: '4x R$ 267,02',
    status: 'Negociação efetivada',
  },
  {
    id: 8374169,
    type_payment: 'À vista',
    amount: 'R$ 1068,08',
    status: 'Quitado',
  },
  {
    id: 8374169,
    type_payment: 'Entrada',
    amount: 'R$ 200,00',
    status: 'Pendente',
  },
  {
    id: 8374169,
    type_payment: 'Entrada',
    amount: 'R$ 200,00',
    status: 'Cancelado',
  },
];
