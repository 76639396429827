import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const ArrowBackCurvedSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.7832 1L1.00442 4.16397L4.7832 7.32793"
        stroke={fill || '#37B4C8'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999897 4.16602L7.04595 4.16602C11.22 4.16602 14.6035 6.99903 14.6035 10.4939V11.1267"
        stroke={fill || '#37B4C8'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowBackCurvedSVG;
