import React, { FC, InputHTMLAttributes } from 'react';
import { Wrapper, Label, Input } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const InputLabel: FC<IProps> = ({ label, ...props }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input {...props} readOnly />
    </Wrapper>
  );
};

export { InputLabel };
