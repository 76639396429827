import styled, { css } from 'styled-components';

const variants = {
  large: css`
    max-width: 1170px;
  `,
  medium: css`
    max-width: 992px;
  `,
  small: css`
    max-width: 560px;
  `,
  newLarge: css`
    max-width: 1010px;
  `,
};

export const MyContainer = styled.div<{
  size?: 'large' | 'medium' | 'small' | 'newLarge';
}>`
  width: 100%;
  margin: 0 auto;

  ${({ size }) => (size ? variants[size] : variants.large)}
`;
