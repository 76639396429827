import React from 'react';
import {
  ImageContainer,
  Wrapper,
  Container,
  Title,
  ContainerText,
  Text,
} from './styles';
import Header from '~/components/Header';

const UnavailableServicePage: React.FC = () => {
  return (
    <Wrapper>
      <Header fullHeader={false} />
      <Container>
        <ContainerText>
          <Title>OPS! Desculpe-nos.</Title>
          <Text>
            Estamos melhorando nossos serviços. Tente novamente mais tarde.
          </Text>
        </ContainerText>
        <ImageContainer />
      </Container>
    </Wrapper>
  );
};

export default UnavailableServicePage;
