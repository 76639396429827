import React from 'react';

const CheckedIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2375 2.0151L8.82083 2.59844L3.5125 7.90677L1.17917 5.57344L1.7625 4.9901L3.5125 6.7401L8.2375 2.0151ZM8.2375 0.835938L3.5125 5.56094L1.7625 3.81094L0 5.57344L3.5125 9.08594L10 2.59844L8.2375 0.835938Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedIcon;
