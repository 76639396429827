import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function FaturasSVG({ color = Color.blueSeconde }: IProp) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.416 4.37431C17.3701 5.33295 18.0655 6.50881 18.4395 7.79596H21V12.2011H18.4395C18.0659 13.4893 17.3705 14.6662 16.416 15.6257L17.6985 17.7982L13.8015 20L12.522 17.8275C11.1953 18.155 9.80623 18.155 8.4795 17.8275L7.1985 20L3.3015 17.7982L4.584 15.6257C3.62948 14.6662 2.93406 13.4893 2.5605 12.2011H0V7.79743H2.5605C2.93406 6.50923 3.62948 5.33231 4.584 4.37284L3.3 2.20183L7.1955 0H7.2L8.481 2.16954C9.80773 1.84209 11.1968 1.84209 12.5235 2.16954L13.8 0H13.803L17.7 2.20183L16.4175 4.37431H16.416ZM15 9.99927C15 8.83134 14.5259 7.71125 13.682 6.8854C12.8381 6.05955 11.6935 5.5956 10.5 5.5956C9.30653 5.5956 8.16193 6.05955 7.31802 6.8854C6.47411 7.71125 6 8.83134 6 9.99927C6 11.1672 6.47411 12.2873 7.31802 13.1131C8.16193 13.939 9.30653 14.4029 10.5 14.4029C11.6935 14.4029 12.8381 13.939 13.682 13.1131C14.5259 12.2873 15 11.1672 15 9.99927Z"
        fill={color}
      />
    </svg>
  );
}
export default FaturasSVG;
