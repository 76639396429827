import styled from 'styled-components';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Color.gray};
  }

  h2 {
    max-width: 513px;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    color: ${Color.blueSeconde};
    margin-top: -25px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${Color.black};
`;
