import styled from 'styled-components';
import { Color } from '~/styles';

export const Wrapper = styled.div`
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
  background: transparent;
  height: 100%;

  button {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const CloseIconButton = styled.button`
  background: transparent;
  position: absolute;
  height: auto !important;

  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 9999;
  padding: 10px;

  div {
    width: 32px;
    height: 32px;
    border: 2px solid ${Color.grayLightSeconde};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
