import React, { FC } from 'react';
import EmptySVG from '~/assets/Icons/Empty';
import { Margin } from '../Margin';
import { Container } from './styles';

interface IProps {
  textMessage?: string;
  children?: any;
  marginHeight?: number;
}

const EmptyMessage: FC<IProps> = ({ textMessage, children, marginHeight }) => {
  return (
    <Container>
      <EmptySVG />
      <Margin height={marginHeight || 24} />
      {textMessage || children}
    </Container>
  );
};

export default EmptyMessage;
