import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const SupportCenterSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.52961 18.8559C1.52961 19.3143 1.90919 19.6847 2.37903 19.6847H17.6156C18.0855 19.6847 18.4651 19.3143 18.4651 18.8559V12.8209C18.4651 8.25721 14.6745 4.55856 9.99733 4.55856C5.32017 4.55856 1.52961 8.25721 1.52961 12.8209V18.8559ZM3.44082 12.8209C3.44082 9.28806 6.37665 6.42342 9.99733 6.42342C13.618 6.42342 16.5538 9.28806 16.5538 12.8209V17.8198H7.13052V13.3908C7.13052 13.2483 7.01107 13.1318 6.86507 13.1318H5.69711C5.55111 13.1318 5.43166 13.2483 5.43166 13.3908V17.8198H3.44082V12.8209ZM2.16402 6.28097L3.21519 5.25529C3.29748 5.175 3.29748 5.04291 3.21519 4.96261L1.41281 3.20394C1.3729 3.16538 1.319 3.14375 1.26283 3.14375C1.20667 3.14375 1.15277 3.16538 1.11286 3.20394L0.0616898 4.22962C0.0221696 4.26856 0 4.32115 0 4.37596C0 4.43076 0.0221696 4.48335 0.0616898 4.5223L1.86407 6.28097C1.94636 6.36126 2.07908 6.36126 2.16402 6.28097ZM19.9383 4.22962L18.8871 3.20394C18.8472 3.16538 18.7933 3.14375 18.7371 3.14375C18.681 3.14375 18.6271 3.16538 18.5872 3.20394L16.7848 4.96261C16.7453 5.00156 16.7231 5.05415 16.7231 5.10895C16.7231 5.16376 16.7453 5.21635 16.7848 5.25529L17.836 6.28097C17.9182 6.36126 18.0536 6.36126 18.1359 6.28097L19.9383 4.5223C20.0206 4.43941 20.0206 4.30991 19.9383 4.22962ZM18.4916 21.3423H1.50306C1.03322 21.3423 0.653634 21.7127 0.653634 22.1712V22.7928C0.653634 22.9068 0.749195 23 0.865991 23H19.1287C19.2455 23 19.341 22.9068 19.341 22.7928V22.1712C19.341 21.7127 18.9614 21.3423 18.4916 21.3423ZM9.25408 2.9009H10.7406C10.8574 2.9009 10.9529 2.80766 10.9529 2.69369V0.207207C10.9529 0.0932432 10.8574 0 10.7406 0H9.25408C9.13729 0 9.04173 0.0932432 9.04173 0.207207V2.69369C9.04173 2.80766 9.13729 2.9009 9.25408 2.9009Z"
        fill={fill || '#004B55'}
      />
    </svg>
  );
};

export default SupportCenterSVG;
