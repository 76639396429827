import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}

const AlertSVG = ({ color = Color.redAscendet, width = 28, height = 24 }: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5947 17.894L17.4715 2.57668C17.1026 1.99243 16.5943 1.5116 15.9936 1.17854C15.3928 0.845477 14.7189 0.670898 14.0339 0.670898C13.349 0.670898 12.6751 0.845477 12.0743 1.17854C11.4736 1.5116 10.9653 1.99243 10.5964 2.57668L1.47323 17.894C1.15095 18.4379 0.975779 19.0581 0.965339 19.6922C0.9549 20.3264 1.10955 20.9521 1.41375 21.5066C1.76545 22.1307 2.27452 22.649 2.88892 23.0087C3.50333 23.3684 4.20105 23.5564 4.91077 23.5537H23.1571C23.8622 23.5613 24.5567 23.3805 25.1707 23.0296C25.7847 22.6786 26.2964 22.1699 26.6541 21.5547C26.9673 20.9945 27.1269 20.3598 27.1164 19.7162C27.1059 19.0726 26.9258 18.4435 26.5947 17.894ZM14.0339 18.7369C13.7987 18.7369 13.5687 18.6663 13.3731 18.534C13.1775 18.4017 13.025 18.2136 12.935 17.9936C12.845 17.7735 12.8214 17.5314 12.8673 17.2978C12.9132 17.0642 13.0265 16.8497 13.1929 16.6812C13.3592 16.5128 13.5712 16.3982 13.8019 16.3517C14.0326 16.3052 14.2718 16.3291 14.4891 16.4202C14.7065 16.5114 14.8922 16.6657 15.0229 16.8637C15.1536 17.0618 15.2234 17.2946 15.2234 17.5327C15.2234 17.8521 15.0981 18.1584 14.875 18.3842C14.652 18.6101 14.3494 18.7369 14.0339 18.7369ZM15.2234 13.9202C15.2234 14.2395 15.0981 14.5458 14.875 14.7717C14.652 14.9975 14.3494 15.1244 14.0339 15.1244C13.7185 15.1244 13.4159 14.9975 13.1929 14.7717C12.9698 14.5458 12.8445 14.2395 12.8445 13.9202V9.1034C12.8445 8.78403 12.9698 8.47774 13.1929 8.25191C13.4159 8.02608 13.7185 7.89921 14.0339 7.89921C14.3494 7.89921 14.652 8.02608 14.875 8.25191C15.0981 8.47774 15.2234 8.78403 15.2234 9.1034V13.9202Z"
        fill={color}
      />
    </svg>
  );
};

export default AlertSVG;
