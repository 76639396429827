export const Color = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#27251F',
  blackSeconde: '#1F1F1F',
  realBlack: '#000',
  red: '#872D2D',
  redLight: '#f0c3af',
  redSecond: '#E6735F',
  redAscendet: '#FF5F5F',
  redAscendetTwo: '#FF5F5F',
  Blue: '#AAD7FF',
  BlueLight: '#AAD7E1',
  blueAscednet: '#25A2F2',
  blueIntense: '#004b55',
  gray: '#999999',
  graySeconde: '#EEEEEE',
  grayLight: '#E9E9E9',
  grayLightSeconde: '#C7C9C7',
  grayLightThird: '#e3e4e3',
  grayLightFourth: '#D9D9D9',
  grayRgba: 'rgba(199, 201, 199, 0.5)',
  grayDark: '#999999',
  blueSeconde: '#37B4C8',
  greenLight: '#AFD7C3',
  green: '#37B482',
  greenMedium: '#89CAA9',
  greenDark: '#003C28',
  greenDarkerLight: '#147750',
  yellow: '#FAB419',
  yellowSeconde: '#FFD79B',
};
