import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const FileIconSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="25"
      height="31"
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 21.7H18.75V24.8H6.25V21.7ZM6.25 15.5H18.75V18.6H6.25V15.5ZM15.625 0H3.125C1.40625 0 0 1.395 0 3.1V27.9C0 29.605 1.39062 31 3.10937 31H21.875C23.5938 31 25 29.605 25 27.9V9.3L15.625 0ZM21.875 27.9H3.125V3.1H14.0625V10.85H21.875V27.9Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default FileIconSVG;
