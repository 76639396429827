import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Content = styled.div`
  width: fit-content;
  padding: 20px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 139px 0 95px 0;
    max-width: 1010px;
    margin: 0 auto;
    padding: 70px 123px;
  }
`;
