import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import SelectImage from '~/assets/Images/Select.png';

export const ContainerFooter = styled.footer`
  margin-top: auto;
  padding-inline: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
`;

export const ContainerLogo = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 63px 0 26px 0;

  img {
    width: 72px;
    height: 55px;
  }

  div {
    display: flex;
  }

  select {
    height: 32px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
    border: 1px solid ${Color.grayLightSeconde};
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.5;
    color: ${Color.gray};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${SelectImage});
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }

  @media (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
    gap: 30px;

    div {
      flex-direction: column;
    }
  }
`;

export const ContainerOperation = styled.div`
  display: flex;
  align-items: flex-start;

  div {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: ${Color.gray};
    }

    p:first-of-type {
      font-size: 11px;
      line-height: 13px;
    }
  }
`;

export const ContainerSocialNetwork = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${Color.grayLightSeconde};
  padding: 32px 0 0px 0;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${Color.gray};
  }

  div {
    display: flex;
  }
`;

export const ContainerLinks = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  padding: 2px 0 72px 0;
  flex-wrap: wrap;

  @media (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
  }

  div {
    display: flex;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${Color.blueSeconde};
  }
  p {
    color: ${Color.gray};
  }
`;
