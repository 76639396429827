interface File {
  data: string;
  fileName: string;
}

const onShareBlob = ({ data, fileName }: File) => {
  const anchorElement = document.createElement('a');

  document.body.appendChild(anchorElement);

  anchorElement.style.display = 'none';

  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  const url = window.URL.createObjectURL(blob);

  anchorElement.href = url;
  anchorElement.download = fileName;
  anchorElement.click();

  window.URL.revokeObjectURL(url);
};

export default onShareBlob;
