import React, { FC, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import ownershipManagementForm from '../../FormModel';

import { InputField, UploadField } from '~/common';
import { Dialog } from '~/components/Dialog';
import { ModalPreview } from '../../../Common';
import { ProfileFormWrapper, ProfileFormGroup } from './styles';
import formatter from '~/utils/formatter';
import { RE_NON_DIGIT } from '~/utils/constants';

interface IProfileStep {
  reasons: any;
}

const ProfileStep: FC<IProfileStep> = ({ reasons }) => {
  const [preview, setPreview] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const {
    formFields: { name, email, cnp, phone, contact },
  } = ownershipManagementForm;
  const { values, setFieldValue, errors, touched }: FormikValues =
    useFormikContext();

  const verifyFields = () => {
    const arr = reasons.filter((item: any) => {
      return item.id === values.reason.id;
    });
    return arr[0].upload;
  };

  const handleUpload = (field: string, fileLabel: string, image: File | null) => {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onloadend = () => {
        const base64String = String(reader.result)
          .replace('data:', '')
          .replace(/^.+,/, '');

        setFieldValue(field, {
          name: fileLabel,
          file: image,
          base64: base64String,
        });
      };
    }
    setFieldValue(field, {
      name: fileLabel,
      file: image,
    });
  };

  const handleDelete = (field: string, fileLabel: string) => {
    setFieldValue(field, {
      name: fileLabel,
    });
  };

  const handlePreview = (field: string) => {
    const number = field.replace(/[^\d]/g, '');
    const { file } = values.files[number];
    setPreview({
      type: file.type,
      url: URL.createObjectURL(file),
    });
    setOpen(true);
  };

  return (
    <ProfileFormWrapper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ModalPreview handleClose={() => setOpen(false)}>
          {preview && preview.type === 'image/jpeg' ? (
            <img src={preview.url} alt="doc_prev" />
          ) : (
            preview && <embed src={preview.url} type="application/pdf" />
          )}
        </ModalPreview>
      </Dialog>
      <ProfileFormGroup>
        <InputField
          className="large"
          name={name.name}
          label={name.label}
          touched={touched}
          error={errors[name.name]}
          readOnly
        />
        <InputField
          name={email.name}
          label={email.label}
          touched={touched}
          error={errors[email.name]}
          inputMode="email"
          // readOnly
        />
        <InputField
          name={cnp.name}
          label={cnp.label}
          touched={touched}
          error={errors[cnp.name]}
          inputMode="numeric"
          onChange={(e) => {
            const { value } = e.target;
            setFieldValue(
              'cnp',
              formatter.cpfCnpjMask(value.toString().replace(RE_NON_DIGIT, '')),
            );
          }}
          readOnly
        />
        <InputField
          name={phone.name}
          label={phone.label}
          touched={touched}
          error={errors[phone.name]}
          inputMode="tel"
          onChange={(e) => {
            const { value } = e.target;
            setFieldValue(
              'phone',
              formatter.formatarPhone(value.toString().replace(RE_NON_DIGIT, '')),
            );
          }}
        />
        <InputField
          name={contact.name}
          label={contact.label}
          touched={touched}
          error={errors[contact.name]}
          inputMode="tel"
          onChange={(e) => {
            const { value } = e.target;
            setFieldValue(
              'contact',
              formatter.formatarTel(value.toString().replace(RE_NON_DIGIT, '')),
            );
          }}
        />
      </ProfileFormGroup>
      <ProfileFormGroup>
        <UploadField
          name="files[0]"
          fileLabel={values.files[0].name}
          content={values.files[0].file || null}
          handleUpload={handleUpload}
          className="large"
          label="Favor anexar os seguintes documentos:"
          handlePreview={handlePreview}
          touched={touched}
          error={errors['files[0]']}
          handleDelete={handleDelete}
        >
          {verifyFields()[0].label}
        </UploadField>
        <UploadField
          name="files[1]"
          fileLabel={values.files[1].name}
          content={values.files[1].file || null}
          handleUpload={handleUpload}
          className="large"
          label="Favor anexar os seguintes documentos:"
          handlePreview={handlePreview}
          touched={touched}
          error={errors['files[1]']}
          handleDelete={handleDelete}
        >
          {verifyFields()[1].label}
        </UploadField>
        {/* <UploadField
          name="files[1]"
          // fileLabel={verifyFields()[1].name}
          fileLabel="purchase_contract"
          content={values.files[1].file}
          handleUpload={handleUpload}
          className="large"
          handlePreview={handlePreview}
          touched={touched}
          error={errors['files[1]']}
        >
          {verifyFields()[1].label}
        </UploadField> */}
      </ProfileFormGroup>
    </ProfileFormWrapper>
  );
};

export { ProfileStep };
