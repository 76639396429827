import styled from 'styled-components';
import { Text } from '~/components/TextInfo';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.main``;

export const TitleCard = styled(Text).attrs({
  color: Color.blueIntense,
  textAlign: 'left',
})`
  width: 407.11px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;

  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 99%;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    font-size: 40px;
  }
`;

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1010px;
  border: none;
  padding: 0;
  margin: 0 auto;
  cursor: pointer;

  background-color: #fafafa;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 200px;
  @media (max-width: ${Breakpoints.desktopExtraLarge.max}px) {
    padding: 0 100px;
  }
  @media (max-width: ${Breakpoints.laptop.max}px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 17px 30px;
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Swipper = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
