import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 570px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 56px 10px;

  p {
    color: #999;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 60px 0;
  }

  div {
    :first-of-type {
      min-height: inherit;

      h1 {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 102px 0;

    p {
      color: #999;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 148px 0 124px 0;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  margin: 0;
  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
`;
