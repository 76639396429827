import React, { FC } from 'react';
import { Data } from '..';
import { ContainerForm } from './styles';
import { DescriptionText } from '../styles';

import { Container } from './styles';
import FormDataConfirmation from './FormDataConfirmation';

export interface IReportLackWaterStepFourProps extends Data {
  typeProblem?: string;
  requestDate?: string;
  enrollment?: string;
  address?: string;
  complement?: string;
}

const ReportLackWaterStepFour: FC<IReportLackWaterStepFourProps> = (data) => {
  return (
    <Container>
      <DescriptionText>Confirme os dados do reparo:</DescriptionText>
      <ContainerForm>
        <FormDataConfirmation {...data} />
      </ContainerForm>
    </Container>
  );
};

export default ReportLackWaterStepFour;
