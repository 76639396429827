import React from 'react';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import { Margin } from '~/components/Margin';
import { CloseIconButton, Separator, Wrapper } from './styles';

interface IProps {
  onClose: (status: boolean) => void;
  file: File | null;
}

const ModalFile: React.FC<IProps> = ({ onClose, file }) => {
  return (
    <Wrapper>
      <CloseIconButton onClick={() => onClose(false)} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <Separator />
      <Margin height={32} />
      {file &&
        (file.type === 'application/pdf' ? (
          <embed src={URL.createObjectURL(file)} type="application/pdf" />
        ) : (
          <img src={URL.createObjectURL(file)} alt={file.name} />
        ))}
      <Margin height={32} />
      <Separator />
    </Wrapper>
  );
};

export default ModalFile;
