import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const UploadSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7826 1L10.7826 13.5217M10.7826 1L15.0869 5.17391M10.7826 1L6.47822 5.17391M1 15.0842L1.6075 17.5152C1.7133 17.9385 1.95754 18.3143 2.30141 18.5828C2.64528 18.8513 3.06903 18.9972 3.50533 18.9973H18.0599C18.4962 18.9972 18.9199 18.8513 19.2638 18.5828C19.6077 18.3143 19.8519 17.9385 19.9577 17.5152L20.5652 15.0842"
        stroke={fill || '#004B55'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadSVG;
