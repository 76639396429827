import React from 'react';

const StatusInfoIconSVG = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.90625" width="44" height="44" rx="8" fill="#004B55" />
      <path
        d="M22 19.5729V19.574M22 22.9063V27.3507M32 22.9063C32 28.4291 27.5228 32.9063 22 32.9063C16.4772 32.9063 12 28.4291 12 22.9063C12 17.3834 16.4772 12.9062 22 12.9062C27.5228 12.9062 32 17.3834 32 22.9063Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default StatusInfoIconSVG;
