import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  ButtonCodeInvoice,
  ContainerBox,
  ContainerInvoiceCodeBox,
  ContainerInvoicePixCode,
  LabelBox,
  TextBox,
} from './styles';
import { CopySuccessIcon } from '~/views/EasyDuplicate/Icons';
import CopyIcon from '~/assets/Icons/Copy';
import { Margin } from '~/components/Margin';
import clipboardCopy from 'clipboard-copy';

interface IPixCodeProps {
  code_copy: string;
  type: 'credit-card' | 'pix' | 'billet';
}

export const CopyCode: FC<IPixCodeProps> = ({ code_copy, type }) => {
  const [isClickedDownload, setIsClickedDownload] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const handleCopy = () => {
    if (isClickedDownload || downloaded) return;

    if (type === 'pix') {
      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Pagamento de Fatura]Copiar Pix',
      });
    } else {
      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Pagamento de Fatura]Copiar Boleto”',
      });
    }

    setIsClickedDownload(true);
    setTimeout(() => {
      clipboardCopy(code_copy);
      setIsClickedDownload(false);
      setDownloaded(true);

      setTimeout(() => {
        setDownloaded(false);
      }, 5000);
    }, 2000);
  };

  return (
    <ContainerInvoicePixCode>
      <ContainerInvoiceCodeBox>
        <LabelBox>{type === 'pix' ? 'CÓDIGO PIX' : 'CÓDIGO DE BARRAS'}</LabelBox>
        <ContainerBox>
          <TextBox>{code_copy}</TextBox>
        </ContainerBox>
      </ContainerInvoiceCodeBox>
      <ButtonCodeInvoice
        type="button"
        onClick={handleCopy}
        clicked={isClickedDownload}
        copied={downloaded}
        maxWidth={false}
      >
        {!isClickedDownload && (
          <>
            <p>{downloaded ? 'Código copiado!' : 'Copiar'}</p>
            <Margin width={8} />
            <div>
              {downloaded ? <CopySuccessIcon /> : <CopyIcon color="#000" v3 />}
            </div>
          </>
        )}
      </ButtonCodeInvoice>
    </ContainerInvoicePixCode>
  );
};
