import React from 'react';

interface IProp {
  color?: string;
  v3?: boolean;
  newStyle?: boolean;
}

const InfoSVG = ({ color = '#37B4C8', v3 = false, newStyle = false }: IProp) => {
  if (newStyle)
    return (
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="40" cy="40" r="40" fill="#004B55" />
        <path
          d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 48L40 38"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0117 32L39.9938 32"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  return v3 ? (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M43.0026 14.334C27.1643 14.334 14.3359 27.1623 14.3359 43.0006C14.3359 58.839 27.1643 71.6673 43.0026 71.6673C58.8409 71.6673 71.6693 58.839 71.6693 43.0006C71.6693 27.1623 58.8409 14.334 43.0026 14.334ZM46.5859 60.9173H39.4193V53.7506H46.5859V60.9173ZM46.5859 46.584H39.4193V25.084H46.5859V46.584Z"
        fill="#37B4C8"
      />
      <path
        d="M42.9616 7.16602C23.1816 7.16602 7.16406 23.2193 7.16406 42.9994C7.16406 62.7794 23.1816 78.8327 42.9616 78.8327C62.7774 78.8327 78.8307 62.7794 78.8307 42.9994C78.8307 23.2193 62.7774 7.16602 42.9616 7.16602ZM42.9974 71.666C27.1591 71.666 14.3307 58.8377 14.3307 42.9994C14.3307 27.161 27.1591 14.3327 42.9974 14.3327C58.8357 14.3327 71.6641 27.161 71.6641 42.9994C71.6641 58.8377 58.8357 71.666 42.9974 71.666ZM39.4141 53.7494H46.5807V60.916H39.4141V53.7494ZM39.4141 25.0827H46.5807V46.5827H39.4141V25.0827Z"
        fill="#37B4C8"
      />
    </svg>
  ) : (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M30 10C18.95 10 10 18.95 10 30C10 41.05 18.95 50 30 50C41.05 50 50 41.05 50 30C50 18.95 41.05 10 30 10ZM32.5 42.5H27.5V37.5H32.5V42.5ZM32.5 32.5H27.5V17.5H32.5V32.5Z"
        fill={color}
      />
      <path
        d="M29.975 5C16.175 5 5 16.2 5 30C5 43.8 16.175 55 29.975 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 29.975 5ZM30 50C18.95 50 10 41.05 10 30C10 18.95 18.95 10 30 10C41.05 10 50 18.95 50 30C50 41.05 41.05 50 30 50ZM27.5 37.5H32.5V42.5H27.5V37.5ZM27.5 17.5H32.5V32.5H27.5V17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoSVG;
