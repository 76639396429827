import React from 'react';

function AlertPixFraudSVG() {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.222 11.067h-1.778v1.778h1.778m0-5.334h-1.778V9.29h1.778M16 14.623H8.889v-1.778h1.778v-1.778H8.889V9.29h1.778V7.512H8.889V5.733H16M7.111 3.956H5.333V2.178h1.778m0 5.334H5.333V5.733h1.778m0 5.333H5.333V9.29h1.778m0 5.334H5.333v-1.778h1.778m-3.555-8.89H1.778V2.179h1.778m0 5.334H1.778V5.733h1.778m0 5.333H1.778V9.29h1.778m0 5.334H1.778v-1.778h1.778m5.333-8.89V.4H0v16h17.778V3.956h-8.89z"
        fill="#999"
      />
    </svg>
  );
}
export default AlertPixFraudSVG;
