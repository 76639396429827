import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  transform: translateY(-50%);
  left: 4px;
  padding: 0px 4px;
  background: ${Color.white};
  color: ${Color.blueIntense};
  font-size: 12px;
  font-weight: 400;
  line-height: 122.5%;
  letter-spacing: -0.3px;
  text-transform: uppercase;
`;

export const Input = styled.input`
  padding: 0px 6px;
  width: 100%;
  height: 33px;
  outline: none;
  font-size: 12px;
  border: 1px solid ${Color.blueIntense};
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    border: 1px solid ${Color.blueIntense};
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 14px;
  }
`;
