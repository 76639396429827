/* eslint-disable no-alert */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import { stepStatusEnum } from '~/enums/customerObligationEnum';

import Form from './Form';
import Status from './Status';
import Revision from './Revision';

export const RequestNetworkAcceptance: FC = () => {
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const typesMap: Map<stepStatusEnum | undefined, React.JSX.Element> = new Map([
    [stepStatusEnum.FORM, <Form />],
    [stepStatusEnum.WAIT, <Status />],
    [stepStatusEnum.REVIEW, <Revision />],
  ]);

  return typesMap.get(caseOC?.step.status);
};
