import styled from 'styled-components';
import { Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export const HowItWorksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  button {
    width: 100%;
    max-width: none;
  }
`;

export const Info = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: ${COLORS.gray200};
  border-radius: 4px;
  box-sizing: border-box;
`;

export const InfoTitle = styled.div`
  color: ${Color.blueIntense};
  font-weight: 500;
  line-height: 1.2;
`;

export const InfoItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-size: 15px;
`;

export const Item = styled.div`
  display: flex;
  .number {
    min-width: 32px;
    color: ${Color.green};
    text-align: center;
    font-weight: 500;
  }
  .text {
    color: ${Color.grayDark};
    letter-spacing: 0.05em;
    font-weight: 400;
  }
`;
