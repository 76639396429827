import styled, { css } from 'styled-components';
import SelectImage from '~/assets/Images/SelectArrow.png';
import SelectImageForm from '~/assets/Images/Select.png';

interface IPropsContainer {
  character?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  flex: 1;

  ${({ character }) =>
    character &&
    css`
      flex: none;
      width: 100%;
    `};
`;

interface IProps {
  select?: boolean;
  selectForm?: boolean;
  character?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const InputText = styled.input<IProps>`
  display: flex;
  width: calc(100% - 22px);
  height: 30px;

  border: 1px solid #c7c9c7;
  border-radius: 8px;
  background: ${({ disabled }) => (disabled ? '#f2f2f2' : 'transparent')};

  padding: 0 10px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
  appearance: textfield;

  ${({ select, selectForm }) =>
    (select || selectForm) &&
    `
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${select ? SelectImage : SelectImageForm});
    background-repeat: no-repeat;
    background-position: 98% 50%;
 `}

  ${({ selectForm }) =>
    selectForm &&
    `
      width: 100%;
      height: 32px;
  `}
`;

export const InputSelect = styled.select<IProps>`
  display: flex;
  width: 100%;
  height: 32px;
  border: 1px solid #c7c9c7;
  border-radius: 8px;
  background-color: transparent;

  padding: 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${SelectImageForm});
  background-repeat: no-repeat;
  background-position: 98% 50%;
`;

export const Label = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;

  color: #004b55;
`;
