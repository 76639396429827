import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function VisiblePasswordSVG({ color = Color.black }: IProp) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9987 2.99984C13.157 2.99984 15.9737 4.77484 17.3487 7.58317C16.857 8.59984 16.1654 9.47484 15.3404 10.1832L16.5154 11.3582C17.6737 10.3332 18.5904 9.04984 19.1654 7.58317C17.7237 3.92484 14.1654 1.33317 9.9987 1.33317C8.94036 1.33317 7.9237 1.49984 6.96536 1.80817L8.34036 3.18317C8.88203 3.07484 9.43203 2.99984 9.9987 2.99984ZM11.8987 6.7415L13.6237 8.46651C13.6904 8.18317 13.7404 7.88317 13.7404 7.57484C13.7487 5.50817 12.0654 3.83317 9.9987 3.83317C9.69036 3.83317 9.3987 3.88317 9.10703 3.94984L10.832 5.67484C11.3154 5.88317 11.6904 6.2665 11.8987 6.7415ZM1.6737 1.22484L3.90703 3.45817C2.5487 4.52484 1.4737 5.9415 0.832031 7.58317C2.2737 11.2415 5.83203 13.8332 9.9987 13.8332C11.2654 13.8332 12.482 13.5915 13.5987 13.1498L16.4487 15.9998L17.6237 14.8248L2.8487 0.0415039L1.6737 1.22484ZM7.9237 7.47484L10.0987 9.64984C10.0654 9.65817 10.032 9.6665 9.9987 9.6665C8.8487 9.6665 7.91536 8.73317 7.91536 7.58317C7.91536 7.5415 7.9237 7.5165 7.9237 7.47484ZM5.09036 4.6415L6.5487 6.09984C6.35703 6.55817 6.2487 7.05817 6.2487 7.58317C6.2487 9.64984 7.93203 11.3332 9.9987 11.3332C10.5237 11.3332 11.0237 11.2248 11.4737 11.0332L12.2904 11.8498C11.557 12.0498 10.7904 12.1665 9.9987 12.1665C6.84036 12.1665 4.0237 10.3915 2.6487 7.58317C3.23203 6.3915 4.08203 5.40817 5.09036 4.6415Z"
        fill={color}
      />
    </svg>
  );
}
export default VisiblePasswordSVG;
