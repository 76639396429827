import styled from 'styled-components';
import { Color } from '~/styles';

export const Orders = styled.div`
  width: 100%;
`;

export const OrdersWrapper = styled.div`
  max-height: 520px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  > :first-child {
    margin-bottom: 12px;
  }
`;

export const Order = styled.button`
  height: 80px;
  min-height: 80px;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  border: 1px solid ${Color.grayLight};
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
`;

interface OrderBarProps {
  bgColor: keyof typeof Color;
}
interface IOrderbar {
  background: string;
}

export const OrderBar = styled.div<OrderBarProps>`
  height: 100%;
  width: 12px;
  background: ${(p) => Color[p.bgColor]};
`;

export const BarStatus = styled.div<IOrderbar>`
  background-color: ${(props) => props.background || Color.gray};
  width: 16px;
  height: 100%;
`;

export const OrderContent = styled.div`
  padding: 8px;

  .status {
    margin-bottom: 12px;
    color: ${Color.blueIntense};
    font-size: 12px;
    text-align: start;
    font-weight: 400;
  }
  .protocol {
    color: ${Color.blueIntense};
    margin-bottom: 4px;
    font-weight: 500;
  }
  .description {
    font-size: 12px;
    color: ${Color.gray};
  }
`;

export const OrderEmpty = styled.div`
  padding: 24px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  color: ${Color.gray};
  p {
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.05em;
    font-weight: 400;
    line-height: 1.2;
  }
`;

export const Title = styled.div`
  color: ${Color.blueIntense};
  font-weight: 500;
  /* align-self: center; */
`;
