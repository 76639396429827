import React, { FC } from 'react';

import { BoxTerm, BoxTermAccept, CloseIconButton, Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import InputCheckedV3 from '~/common/InputCheckedV3';
import { ContainerTerms } from '../styles';

interface IProps {
  onClose: () => void;
  data: any;
  setData: (type: any) => void;
}

const ModalTerm: FC<IProps> = ({ onClose, data, setData }) => {
  return (
    <Main>
      <CloseIconButton onClick={onClose} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <h3>Termo de responsabilidade</h3>
      <p>Para finalizar a solicitação, leia com atenção os termos a seguir:</p>
      <Margin height={16} />
      <BoxTerm>
        <p>
          Declaro estar ciente de que se constatado desabastecimento durante a
          vistoria de Verificação de Falta de Água imprevista, havendo
          disponibilidade de abastecimento emergencial por caminhão pipa o volume de
          água em m³ por mim requerido e fornecido pela Iguá Rio de Janeiro por
          caminhão pipa será acrescido ao consumo registrado no hidrômetro na leitura
          subsequente (conforme o cronograma de leitura) à execução do abastecimento,
          e será cobrado conforme tabela tarifária vigente obedecendo às faixas
          tarifárias previamente determinadas pela Agência Reguladora para serviços
          de água e esgoto e cobrado posteriormente em próxima fatura com a descrição
          do serviço de tarifa de fornecimento de água por caminhão pipa conforme
          previsto no Contrato de Concessão e em consonância com a Lei 11.445/2007.
        </p>
      </BoxTerm>
      <Margin height={30} />
      <BoxTermAccept>
        <ContainerTerms>
          <InputCheckedV3
            checked={data?.accept_term}
            onChange={(e) => setData({ ...data, accept_term: e.target.checked })}
          >
            <p className="term">Li e aceito o termo de responsabilidade.</p>
          </InputCheckedV3>
        </ContainerTerms>
        <ButtonText
          text="Ok"
          typeBtn="content"
          onClick={onClose}
          className="button-green"
        />
      </BoxTermAccept>
    </Main>
  );
};

export default ModalTerm;
