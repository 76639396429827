import { Reducer } from 'redux';
import {
  IRepositoriesStateOwnership,
  RepositoriesTypesOwnershipOrders,
} from './types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  operations: { data: null },
  detail: { data: null },
};

const reducer: Reducer<IRepositoriesStateOwnership> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: {
          message: action.payload.error.type,
          status: action.payload.error.status,
          state: true,
        },
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_SUCCESS:
      return {
        ...state,
        operations: {
          data: action.payload.data,
        },
        loading: false,
        error: null,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_FAILURE:
      return {
        ...state,
        operations: {
          data: null,
        },
        loading: false,
        error: {
          message: action.payload.error.type,
          status: action.payload.error.status,
          state: true,
        },
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_SUCCESS:
      return {
        ...state,
        detail: {
          data: action.payload.data,
        },
        loading: false,
        error: null,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_FAILURE:
      return {
        ...state,
        detail: {
          data: null,
        },
        loading: false,
        error: {
          message: action.payload.error.type,
          status: action.payload.error.status,
          state: true,
        },
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_SUCCESS:
      return {
        ...state,
        detail: {
          data: action.payload.data,
        },
        loading: false,
        error: null,
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_FAILURE:
      return {
        ...state,
        detail: {
          data: null,
        },
        loading: false,
        error: {
          message: action.payload.error.type,
          status: action.payload.error.status,
          state: true,
        },
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ERROR_OPEN:
      return {
        ...state,
        error: {
          message: action.payload.error.type,
          status: action.payload.error.status,
          state: true,
        },
      };
    case RepositoriesTypesOwnershipOrders.OWNERSHIP_ERROR_CLOSE:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
