import React, { FC, HTMLAttributes } from 'react';
import { CrumbLink, List, MyBreadcrumb, Wrapper } from './styles';

export interface IDatePage {
  label: string;
  onClick?: () => void;
  active: boolean;
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
  links: IDatePage[];
}

const BreadCrumb: FC<IProps> = ({ links, ...props }) => {
  return (
    <MyBreadcrumb aria-label="Breadcrumb" {...props}>
      <List>
        {links.map((link) => (
          <Wrapper key={link.label}>
            <CrumbLink
              onClick={link.onClick}
              aria-current={link.active ? 'page' : undefined}
            >
              {link.label}
            </CrumbLink>
          </Wrapper>
        ))}
      </List>
    </MyBreadcrumb>
  );
};

export { BreadCrumb };
