/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Button } from '~/common/Button';
import { ButtonLink, InputToken } from '~/common';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Breakpoints } from '~/styles/breakpoints';
import { Main, ResendTextStyled, ContainerInputs, NavWrapper } from './styles';
import {
  postEnrollmentsUsersSendSmsRegisterRequest,
  postEnrollmentsUsersValidateSmsRegisterRequest,
  postCloseErrorEnrollmentsValidateSmsRegister,
  postCloseErrorEnrollmentsUsersSendSmsRegister,
} from '~/store/modules/enrollmentsUsers/actions';
import { Card } from '~/common/Card';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { IDataRegister } from '../Register';
import { RouteName } from '~/routes/Routes.name';
import WarningNumberAttemptsExceeded from '~/components/WarningNumberAttemptsExceeded';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';

const RegisterCheckCode: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataRegister = history.location.state as IDataRegister;

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateSmsRegister,
  );
  const loadingResendSms = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingRegisterSendSms,
  );
  const dataSendCode = useSelector(
    (item: IState) => item.enrollmentsUsers.dataSendCode,
  );
  const errorRegisterSendSms = useSelector(
    (item: IState) => item.enrollmentsUsers.errorRegisterSendSms,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateSmsRegister,
  );
  const [code, setCode] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [tokenSent, setTokenSent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(dataSendCode?.expiresDate || 60);
  const errorNumberAttemptsExceeded =
    error?.status && error?.type === EnumResponseCode.EXCESS_ATTEMPTS;

  useEffect(() => {
    dispatch(postCloseErrorEnrollmentsValidateSmsRegister());
  }, []);

  useEffect(() => {
    if (code.length >= 6 && !errorNumberAttemptsExceeded) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [code, errorNumberAttemptsExceeded]);

  useEffect(() => {
    const interval: any = setInterval(() => {
      if (tokenSent && counter > 0) {
        return setCounter(counter - 1);
      }
      return setTokenSent(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [tokenSent, counter]);

  const handleClick = async () => {
    await dispatch(
      postEnrollmentsUsersSendSmsRegisterRequest(
        {
          cnp: dataRegister?.cnp || '',
          email: dataRegister?.email || '',
          isEntrepreneur: false,
          isManager: false,
          phone: dataRegister?.phone || '',
        },
        {
          ...dataRegister,
        },
      ),
    );
    setTokenSent(true);
    setCounter(dataSendCode?.expiresDate || 60);
  };

  return (
    <Main>
      <Modal open={!!errorRegisterSendSms?.state} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(postCloseErrorEnrollmentsUsersSendSmsRegister())}
        />
      </Modal>
      <Card
        title={
          dataRegister && dataRegister?.method === 'email'
            ? 'Confirmação via email'
            : 'Confirmação via SMS'
        }
        label={
          dataRegister && dataRegister?.method === 'email'
            ? 'Para seguir, favor informar o código de confirmação enviado para seu email.'
            : 'Para seguir, favor informar o código de confirmação enviado para o número.'
        }
      >
        <Margin height={isMobile ? 13 : 64} />
        <ContainerInputs>
          <InputToken
            valueLength={6}
            value={code}
            handleChange={(e) => setCode(formatter.formatarNumber(e) || '')}
            error={error && error.state ? 'Código incorreto' : ''}
          />
        </ContainerInputs>
        <Margin height={38} />
        {errorNumberAttemptsExceeded ? (
          <WarningNumberAttemptsExceeded onClick={handleClick} />
        ) : (
          <ResendTextStyled>
            Caso não tenha recebido o{' '}
            {dataRegister && dataRegister?.method === 'email' ? 'e-mail' : 'SMS'},
            clique em:{' '}
            <ButtonLink
              style={{ textDecoration: 'underline' }}
              type="button"
              onClick={handleClick}
              disabled={tokenSent}
            >
              {tokenSent ? `Tentar novamente em ${counter}s` : 'Reenviar código'}
            </ButtonLink>
          </ResendTextStyled>
        )}
        <Margin height={38} />
        <NavWrapper>
          <Button
            onClick={() => {
              if (disabled || loading) return;
              dispatch(
                postEnrollmentsUsersValidateSmsRegisterRequest(
                  {
                    cnp: dataRegister?.cnp || '',
                    token: code,
                  },
                  {
                    ...dataRegister,
                    token: code,
                  },
                ),
              );
            }}
            loading={!!(loading || loadingResendSms)}
            disabled={disabled}
          >
            Continuar
          </Button>
          <ButtonLink
            type="button"
            color="gray"
            onClick={() => {
              if (!dataRegister?.email || !dataRegister?.phone) {
                Navigation.navigate(
                  dataRegister.clientType === 'client'
                    ? RouteName.REGISTER
                    : RouteName.REGISTERNOTCLIENT,
                  { ...dataRegister },
                );
              } else {
                Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD, {
                  ...dataRegister,
                });
              }
            }}
          >
            Voltar
          </ButtonLink>
        </NavWrapper>
        <Margin height={isMobile ? 15 : 56} />
      </Card>
    </Main>
  );
};

export default RegisterCheckCode;
