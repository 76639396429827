import React from 'react';

function HistorySubtitleLineBoldIconSVG() {
  return (
    <svg
      width="11"
      height="3"
      viewBox="0 0 11 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.44531"
        x2="10.7394"
        y2="1.44531"
        stroke="#004B55"
        strokeWidth="2"
      />
    </svg>
  );
}
export default HistorySubtitleLineBoldIconSVG;
