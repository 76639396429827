import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import {
  getAutomaticDebitsRequest,
  postErrorCloseAutomaticDebitsRequest,
  postSuccessCloseAutomaticDebitsRequest,
} from '~/store/modules/automaticDebits/actions';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  setOpen: (status: boolean) => void;
}

const ModalCreateDebtAccount: FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch();

  const successCreateAccount = useSelector(
    (item: IState) => item.automaticDebits.successCreate,
  );

  return (
    <Main>
      <Margin height={39} />
      <p>
        {successCreateAccount ? (
          'Débito automático cadastrado com sucesso!'
        ) : (
          <>
            Não foi possivel processar suas solicitação. <br />
            Por favor tente mais tarde!
          </>
        )}
      </p>
      <Margin height={70} />
      <ButtonText
        text="Entendi"
        typeBtn="content"
        backgroundColor={Color.blueSeconde}
        Color={Color.white}
        onClick={() => {
          setOpen(false);
          dispatch(postSuccessCloseAutomaticDebitsRequest());
          dispatch(postErrorCloseAutomaticDebitsRequest());
          if (successCreateAccount) {
            dispatch(getAutomaticDebitsRequest());
          } else {
            Navigation.navigate(RouteName.HOME);
          }
        }}
        style={{ maxWidth: '340px', height: '68px' }}
      />
    </Main>
  );
};

export default ModalCreateDebtAccount;
