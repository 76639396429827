import styled, { css } from 'styled-components';
import { COLORS } from '~/utils/constants';

const VARIANTS = {
  warning: css`
    --bg: #ffd79b;
  `,
  error: css`
    --bg: #f0c3af;
  `,
  info: css`
    --bg: #aad7ff;
  `,
};

const ICON_TOOGLE = {
  on: css`
    --display: revert;
    --text-align: revert;
  `,
  off: css`
    --display: none;
    --text-align: center;
  `,
};

export const MyTooltip = styled.div<{
  variant?: 'error' | 'warning' | 'info';
  icon?: boolean;
}>`
  ${(props) => (props.variant ? VARIANTS[props.variant] : VARIANTS.warning)};

  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  background: var(--bg);
  border-radius: 4px;
  color: ${COLORS.black};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: var(--text-align);

  ${(props) => (props.icon ? ICON_TOOGLE.on : ICON_TOOGLE.off)}
  > svg {
    width: 32px;
    display: var(--display);
  }
  a {
    display: inline;
  }
`;
