import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import OwerServices from '../OwerServices';

import { Margin } from '~/components/Margin';

import { Breakpoints } from '~/styles';
import { Container, Main, Swipper } from '../styles';
import EmptyMessage from '~/components/EmptyMessage';

const HomeNotClient: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <Main>
      <Margin height={isMobile ? 0 : 56} />
      <Container>
        <Swipper />
      </Container>

      <Margin height={150} />
      <EmptyMessage textMessage="Você ainda não possui nenhum imóvel relacionado ao seu CPF/CNPJ" />
      <Margin height={60} />

      <OwerServices />
    </Main>
  );
};

export default HomeNotClient;
