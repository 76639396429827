import React from 'react';

interface IProps {
  layoutv3?: boolean;
  color?: string;
}

const PhoneSVG = ({ layoutv3, color = '#004B55' }: IProps) => {
  return layoutv3 ? (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13175_5977)">
        <path
          opacity="0.3"
          d="M11.334 11.333H39.6673V56.6663H11.334V11.333Z"
          fill={color}
        />
        <path
          d="M39.666 2.83301H11.3327C8.21602 2.83301 5.66602 5.38301 5.66602 8.49967V59.4997C5.66602 62.6163 8.21602 65.1663 11.3327 65.1663H39.666C42.7827 65.1663 45.3327 62.6163 45.3327 59.4997V8.49967C45.3327 5.38301 42.7827 2.83301 39.666 2.83301ZM39.666 56.6663H11.3327V11.333H39.666V56.6663ZM56.9494 21.8163L54.116 24.6497C59.216 29.7497 59.216 37.683 54.116 43.0663L56.9494 45.8997C64.0327 39.383 64.0327 28.6163 56.9494 21.8163ZM48.166 30.5997C49.5827 32.583 49.5827 35.133 48.166 37.1163L50.9994 39.9497C54.3994 36.5497 54.3994 31.4497 50.9994 27.7663L48.166 30.5997Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13175_5977">
          <rect width="68" height="68" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="58"
      height="64"
      viewBox="0 0 58 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6667 0.833252H6.33342C3.21675 0.833252 0.666748 3.38325 0.666748 6.49992V57.4999C0.666748 60.6166 3.21675 63.1666 6.33342 63.1666H34.6667C37.7834 63.1666 40.3334 60.6166 40.3334 57.4999V6.49992C40.3334 3.38325 37.7834 0.833252 34.6667 0.833252ZM34.6667 54.6666H6.33342V9.33325H34.6667V54.6666ZM51.9501 19.8166L49.1168 22.6499C54.2168 27.7499 54.2168 35.6832 49.1168 41.0666L51.9501 43.8999C59.0334 37.3833 59.0334 26.6166 51.9501 19.8166ZM43.1668 28.5999C44.5834 30.5833 44.5834 33.1333 43.1668 35.1166L46.0001 37.9499C49.4001 34.5499 49.4001 29.4499 46.0001 25.7666L43.1668 28.5999Z"
        fill="#37B4C8"
      />
    </svg>
  );
};

export default PhoneSVG;
