import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  p {
    color: ${Color.blueSeconde};
    font-weight: 500;
    font-size: 15px;
  }
`;

export const Label = styled.label`
  position: absolute;
  transform: translateY(-50%);
  left: 8px;
  padding: 0px 4px;
  background: ${Color.white};
  color: ${Color.blueIntense};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 122.5%;
  letter-spacing: -0.66px;
`;

export const Button = styled.button`
  padding: 0px 6px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  outline: none;
  font-size: 14px;
  border: 1px solid ${Color.blueIntense};
  border-radius: 4px;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
    border: 1px solid ${Color.gray};
  }
  svg {
    height: 21px;
  }

  @media (max-width: ${Breakpoints.tablet.max}px) {
    line-break: anywhere;
    min-height: 50px;
  }
`;
