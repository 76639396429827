/* eslint-disable import/no-unresolved */
import React, { FC } from 'react';

import { TableContainer, Tr, Td } from '../styles';
import { Margin } from '~/components/Margin';

export const RioCpaeValuesTable: FC = () => {
  return (
    <>
      <TableContainer>
        <Tr style={{ height: '38px' }} color="gray">
          <th
            style={{ padding: '6px 20px', fontWeight: 600, fontSize: '18px' }}
            colSpan={5}
          >
            Domiciliar
          </th>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>UNIDADES</Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '220px' }}>
            VALOR DO REGULAMENTO DO SERVIÇO
          </Td>
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>
            REAJUSTE IGUÁ 2022
          </Td>
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>
            REAJUSTE IGUÁ 2023
          </Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>1-10</Td>
          <Td>-</Td>
          <Td>-</Td>
          <Td>-</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>11-20</Td>
          <Td>R$ 1.146,00</Td>
          <Td>R$ 1.281,46</Td>
          <Td>R$ 1.412,63</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>21-35</Td>
          <Td>R$ 2.005,51</Td>
          <Td>R$ 2.242,56</Td>
          <Td>R$ 2.472,12</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>36-50</Td>
          <Td>R$ 2.865,00</Td>
          <Td>R$ 3.203,64</Td>
          <Td>R$ 3.531,58</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>
            A partir de 51 <br />
            (por unidade)
          </Td>
          <Td>R$ 57,31</Td>
          <Td>R$ 64,08</Td>
          <Td>R$ 70,64</Td>
        </Tr>
      </TableContainer>

      <Margin height={24} />

      <TableContainer>
        <Tr style={{ height: '38px' }} color="gray">
          <th style={{ padding: '12px 20px', fontWeight: 600 }} colSpan={5}>
            Industrial e Comercial
          </th>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>UNIDADES</Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '220px' }}>
            VALOR DO REGULAMENTO DO SERVIÇO
          </Td>
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>
            REAJUSTE IGUÁ 2022
          </Td>
          <Td style={{ textAlign: 'center', fontWeight: '500' }}>
            REAJUSTE IGUÁ 2023
          </Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>1-50</Td>
          <Td>R$ 2.865,00</Td>
          <Td>R$ 3.203,64</Td>
          <Td>R$ 3.531,58</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>
            A partir de 51 <br />
            (por unidade)
          </Td>
          <Td>R$ 57,31</Td>
          <Td>R$ 64,08</Td>
          <Td>R$ 70,64</Td>
        </Tr>
      </TableContainer>
    </>
  );
};
