import React, { FC } from 'react';

import ArrowSVG from '~/assets/Icons/Arrow';
import { ContainerPagination, ContainerPageNumber, ArrowButton } from './styles';
import { Margin } from '~/components/Margin';
import { PaginationResolver } from './Paginations';

export interface IResponsePagination {
  totalItens: number;
  maxPages: number;
  page: number;
}

interface IProps {
  setPage: (e: number) => void;
  pagination: IResponsePagination;
  chunkPagesSize?: number;
}

const Pagination: FC<IProps> = ({
  setPage,
  pagination,
  chunkPagesSize = 5,
}: IProps) => {
  if (pagination && pagination.maxPages > 1) {
    return (
      <ContainerPagination>
        {pagination.page > 1 && (
          <>
            <ArrowButton
              onClick={() => {
                if (pagination && pagination.page) {
                  setPage(pagination.page - 1);
                }
              }}
            >
              <ArrowSVG />
            </ArrowButton>
            <Margin width={16} />
          </>
        )}

        <ContainerPageNumber>
          <PaginationResolver
            pagination={pagination}
            setPage={setPage}
            chunkPagesSize={chunkPagesSize}
          />
        </ContainerPageNumber>

        {pagination.page < pagination.maxPages && (
          <>
            <Margin width={16} />
            <ArrowButton
              isNext
              onClick={() => {
                if (pagination && pagination.page) {
                  setPage(pagination.page + 1);
                }
              }}
            >
              <ArrowSVG />
            </ArrowButton>
          </>
        )}
      </ContainerPagination>
    );
  }
  return null;
};

export default Pagination;
