import React, { FC, ButtonHTMLAttributes } from 'react';
import CopyIcon from '~/assets/Icons/Copy';
import { Wrapper, CopyCodeBar, CopyMsg } from './styles';

import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  showCopyMsg: boolean;
}

const ButtonCopyCode: FC<IProps> = ({ children, showCopyMsg, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  return (
    <Wrapper>
      <CopyCodeBar {...props}>
        {isMobile ? children.slice(0, 20) : children.slice(0, 48)} ...
        <CopyIcon />
      </CopyCodeBar>
      <CopyMsg showCopyMsg={showCopyMsg}>Código copiado!</CopyMsg>
    </Wrapper>
  );
};

export { ButtonCopyCode };
