import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';

import ResolveHome from './ResolveHome';
import { getEnrollmentsInvoicesRequest } from '~/store/modules/enrollmentsInvoices/actions';
import { getEnrollmentsConsumptionHistoryRequest } from '~/store/modules/enrollmentsConsumptionHistory/actions';
import { getSubscriptionRequest } from '~/store/modules/subscription/actions';
import { getEnrollmentsAlertsRequest } from '~/store/modules/enrollmentsAlerts/actions';
import {
  clearModalAutomaticDebits,
  getAutomaticDebitsRequest,
} from '~/store/modules/automaticDebits/actions';
import moment from 'moment';
import { getEnrollmentsSubscribesMeRequest } from '~/store/modules/enrollmentsSubscribes/actions';
import { checkToggle } from '~/utils/toggles';

const HomeVersionTwo: FC = () => {
  const dispatch = useDispatch();

  const loadingDetailInvoice = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDetail,
  );
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  const enrollment = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const [homeV3, setHomeV3] = useState<boolean>(true);
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);

  useEffect(() => {
    dispatch(clearModalAutomaticDebits());

    if (enrollment) {
      dispatch(getEnrollmentsInvoicesRequest());
      dispatch(getSubscriptionRequest());
      dispatch(getAutomaticDebitsRequest());
      dispatch(getEnrollmentsSubscribesMeRequest());
    }

    if (eletronic_exists && homeV3) {
      dispatch(getEnrollmentsAlertsRequest());
      dispatch(
        getEnrollmentsConsumptionHistoryRequest({
          start: moment(new Date()).subtract(30, 'days').format('DD/MM/YYYY'),
          end: moment(new Date()).format('DD/MM/YYYY'),
        }),
      );
    } else {
      enrollment && dispatch(getEnrollmentsConsumptionHistoryRequest());
    }
  }, [dispatch]);

  const verifyToggles = async () => {
    setHomeV3(await checkToggle(toggles, 'HOME_V3'));
  };

  useEffect(() => {
    if (toggles && toggles?.length > 0) verifyToggles();
  }, [toggles]);

  return (
    <>
      {loadingDetailInvoice && <LoadingComponent labelWait="Carregando..." />}
      <ResolveHome />
    </>
  );
};

export default HomeVersionTwo;
