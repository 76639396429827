export enum RepositoriesTypesLoginCredentials {
  ENROLLMENTS_LOGIN_CREDENTIALS = '@repositories/ENROLLMENTS_LOGIN_CREDENTIALS',
}

export interface IDataLoginCredentials {
  remember: boolean;
  cnp: string;
  password: string;
}

export interface IRepositoriesStateEnrollmentsLoginCredentials {
  readonly data: IDataLoginCredentials | null;
}
