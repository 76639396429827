const parseStatus = (status?: string) => {
  switch (status?.toUpperCase()) {
    case 'EM ANÁLISE':
      return 'Em andamento';
    case 'EM TRÂMITE':
      return 'Em andamento';
    case 'EM ANDAMENTO':
      return 'Em andamento';

    case 'FINALIZADO':
      return 'Resolvido';
    case 'FINALIZADO COM SUCESSO':
      return 'Resolvido';
    case 'RESOLVIDO PELO ATENDIMENTO':
      return 'Resolvido';
    case 'RESOLVIDO PELA OUVIDORIA':
      return 'Resolvido';
    case 'DECLARAÇÃO EMITIDA':
      return 'Resolvido';

    case 'CANCELADO.':
      return 'Cancelado';
    case 'FECHADO POR FALTA DE CONTATO':
      return 'Cancelado';

    case 'FINALIZADO SEM SUCESSO':
      return 'Recusado';
    case 'CANCELADO':
      return 'Recusado';

    case 'NOVO':
      return 'Em aberto';
    case 'EM ABERTO':
      return 'Em aberto';
    case 'AGUARDANDO APROVAÇÃO':
      return 'Em aberto';
    case 'AGUARDANDO RESPOSTA DO CLIENTE':
      return 'Em aberto';
    case 'REVISAR DOCUMENTOS':
      return 'Em aberto';

    default:
      return 'Encerrado';
  }
};

export const getStatusDetail = (status?: string) => {
  const newStatus = parseStatus(status);
  switch (newStatus) {
    case 'Em aberto':
      return {
        label: 'Em aberto',
        color: '#37B4C8',
        message: 'Aguarde, sua solicitação está sendo analisada.',
      };
    case 'Em andamento':
      return {
        label: 'Em análise',
        color: '#FAB419',
        message: 'Aguarde, sua solicitação está sendo analisada.',
      };

    case 'Resolvido':
      return {
        label: 'Resolvido',
        color: '#37B482',
        message: 'Sua solicitação foi concluida com sucesso!',
        msgButton: 'Data de conclusão',
      };
    case 'Recusado':
      return {
        label: 'Recusado',
        color: '#E15454',
        message: 'Sua solicitação foi recusada',
        msgButton: 'Data de conclusão',
      };
    case 'Cancelado':
      return {
        label: 'Cancelado',
        color: '#C7C9C7',
        message: 'Você cancelou essa solicitação',
        msgButton: 'Data de encerramento',
      };
    // case 'Encerrado':
    //   return {
    //     label: 'Encerrado',
    //     color: 'gray',
    //     message: 'Você encerrou essa solicitação.',
    // };
    default:
      return {
        label: '',
        color: 'gray',
        message: '',
      };
  }
};
