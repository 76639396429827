import React, { FC } from 'react';
import { Container } from './styles';

interface IProps {
  onAccept: () => void;
  onDecline: () => void;
}

const AlertCookies: FC<IProps> = ({ onAccept, onDecline }) => {
  return (
    <Container>
      <p>
        Nosso site utiliza cookies para melhorar a navegação. Confira nossa{' '}
        <a
          href="https://igua.com.br/politica-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de privacidade
        </a>{' '}
        e{' '}
        <a
          href="https://igua.com.br/pdf/politica-de-cookies.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de cookies
        </a>{' '}
      </p>
      <div>
        <button type="button" onClick={onDecline}>
          Rejeitar
        </button>
        <button type="button" onClick={onAccept}>
          Aceitar
        </button>
      </div>
    </Container>
  );
};

export default AlertCookies;
