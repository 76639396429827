import moment from 'moment';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import { Color } from '~/styles';
import {
  BorderStatus,
  Container,
  ContainerBorderStatus,
  ContainerDates,
  ContainerDescription,
  StatusText,
  Text,
} from './styles';

const Protocol: FC = () => {
  const data = useSelector((item: IState) => item.socialTariff.dataCreate);

  return (
    <Container>
      <ContainerDescription>
        <p>Por favor, aguarde! Estamos avaliando sua solicitação.</p>
      </ContainerDescription>
      <Margin height={48} />
      <Text>
        O número do seu protocolo é: <br /> <b>{data?.protocol}</b>
      </Text>
      <Margin height={48} />
      <ContainerBorderStatus>
        <BorderStatus backgroundColor={Color.blueSeconde} />
        <Margin width={11} />
        <BorderStatus backgroundColor={Color.grayLightSeconde} />
        <Margin width={11} />
        <BorderStatus backgroundColor={Color.grayLightSeconde} />
      </ContainerBorderStatus>
      <Margin height={17} />
      <StatusText>Solicitação aberta</StatusText>
      <Margin height={24} />
      <ContainerDates>
        <Text>
          Data de solicitação: <br />{' '}
          <b>{moment(new Date()).format('DD/MM/YYYY')}</b>
        </Text>
      </ContainerDates>
    </Container>
  );
};

export default Protocol;
