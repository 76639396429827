export enum RepositoriesTypesEnrollmentsConsumptionHistory {
  ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_REQUEST',
  ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_SUCCESS',
  ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_FAILURE',
  ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_CLOSE = '@repositories/ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_CLOSE',
}

interface IIotData {
  consumption_value: number;
  savings_number: number;
  latitude: number;
  supply_system: string;
  cityId: number;
  fare_category: string;
  reading_negative_value: number;
  moving_average_value: number;
  system: string;
  enrollment_id: number;
  client: string;
  reading_date: string;
  reading_value: number;
  longitude: number;
}

export interface IHistoryData {
  reference: string;
  billed_consumption: number;
  real_consumption: number;
  read_date_hour: string;
  colected_read: number;
  average_consumption: number;
  read_date_reference: string;
  formatedReference?: string;
}

interface IResponseAlert {
  id: number;
  user_id: number;
  cubic_meter: number;
  enrollment: number;
  is_active: boolean;
  deletedAt: null;
  createdAt: string;
  updatedAt: string;
}

export interface IResponseEnrollmentsConsumptionHistory {
  iotData: IIotData[] | [];
  eletronicExists: boolean;
  historyData: IHistoryData[] | [];
  totalHistoryData: number;
  average?: number;
  averageIot?: number;
  historyDataAverage: number;
  iotDataAverage: number;
  totalHistoryIotData: number;
  alert: IResponseAlert | null;
}

export interface IDateQuery {
  start: string;
  end: string;
}

export interface IRepositoriesStateEnrollmentsConsumptionHistory {
  readonly data: IResponseEnrollmentsConsumptionHistory | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
}
