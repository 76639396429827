import { Reducer } from 'redux';
import { RepositoriesTypesCookies, IRepositoriesStateCookies } from './types';

const INITIAL_STATE: IRepositoriesStateCookies = {
  accepted: false,
};

const reducer: Reducer<IRepositoriesStateCookies, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesCookies.COOKIES_SAVE_ACCEPTED:
      return {
        ...state,
        accepted: true,
      };

    case RepositoriesTypesCookies.COOKIES_DELETE_ACCEPTED:
      return {
        ...state,
        accepted: false,
      };

    default:
      return state;
  }
};

export default reducer;
