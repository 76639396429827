import React from 'react';

interface IProp {
  width?: number;
  height?: number;
  isBold?: boolean;
}

function CloseModalSVG({ width = 18, height = 17, isBold = false }: IProp) {
  return isBold ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.293787 0.293787C0.386678 0.200661 0.49703 0.126775 0.61852 0.0763617C0.74001 0.0259488 0.870253 0 1.00179 0C1.13332 0 1.26356 0.0259488 1.38505 0.0763617C1.50654 0.126775 1.6169 0.200661 1.70979 0.293787L7.00179 5.58779L12.2938 0.293787C12.3868 0.200811 12.4971 0.127058 12.6186 0.0767403C12.7401 0.0264221 12.8703 0.000523567 13.0018 0.000523567C13.1333 0.000523567 13.2635 0.0264221 13.385 0.0767403C13.5064 0.127058 13.6168 0.200811 13.7098 0.293787C13.8028 0.386763 13.8765 0.497141 13.9268 0.61862C13.9772 0.740099 14.003 0.870299 14.003 1.00179C14.003 1.13327 13.9772 1.26348 13.9268 1.38495C13.8765 1.50643 13.8028 1.61681 13.7098 1.70979L8.41579 7.00179L13.7098 12.2938C13.8028 12.3868 13.8765 12.4971 13.9268 12.6186C13.9772 12.7401 14.003 12.8703 14.003 13.0018C14.003 13.1333 13.9772 13.2635 13.9268 13.385C13.8765 13.5064 13.8028 13.6168 13.7098 13.7098C13.6168 13.8028 13.5064 13.8765 13.385 13.9268C13.2635 13.9772 13.1333 14.003 13.0018 14.003C12.8703 14.003 12.7401 13.9772 12.6186 13.9268C12.4971 13.8765 12.3868 13.8028 12.2938 13.7098L7.00179 8.41579L1.70979 13.7098C1.61681 13.8028 1.50643 13.8765 1.38495 13.9268C1.26348 13.9772 1.13327 14.003 1.00179 14.003C0.870299 14.003 0.740099 13.9772 0.61862 13.9268C0.497141 13.8765 0.386763 13.8028 0.293787 13.7098C0.200811 13.6168 0.127058 13.5064 0.0767403 13.385C0.0264221 13.2635 0.000523567 13.1333 0.000523567 13.0018C0.000523567 12.8703 0.0264221 12.7401 0.0767403 12.6186C0.127058 12.4971 0.200811 12.3868 0.293787 12.2938L5.58779 7.00179L0.293787 1.70979C0.200661 1.6169 0.126775 1.50654 0.0763617 1.38505C0.0259488 1.26356 0 1.13332 0 1.00179C0 0.870253 0.0259488 0.74001 0.0763617 0.61852C0.126775 0.49703 0.200661 0.386678 0.293787 0.293787Z"
        fill="#C7C9C7"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1.35355" y1="0.646447" x2="16.8616" y2="16.1545" stroke="#C7C9C7" />
      <line x1="0.646447" y1="16.1543" x2="16.1545" y2="0.646196" stroke="#C7C9C7" />
    </svg>
  );
}
export default CloseModalSVG;
