import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from '~/components/Button';
import { Margin } from '~/components/Margin';
import { updateErrorCloseEnrollmentsUsers } from '~/store/modules/enrollmentsUsers/actions';

import { Main } from './styles';

const ModalError: FC = () => {
  const dispatch = useDispatch();
  return (
    <Main>
      <p>Parece que houve um erro, por favor tente novamente</p>
      <Margin height={39} />
      <Button
        modalType="error"
        text="Entendi"
        type="button"
        onClick={() => dispatch(updateErrorCloseEnrollmentsUsers())}
      />
    </Main>
  );
};

export default ModalError;
