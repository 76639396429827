import React, { FC, useEffect, useState } from 'react';
import { PageNumber } from '../styles';

export interface IResponsePagination {
  totalItens: number;
  maxPages: number;
  page: number;
}

interface IProps {
  setPage: (e: number) => void;
  pagination: IResponsePagination;
  chunkPagesSize: number;
}

export const PaginationStart: FC<IProps> = ({
  setPage,
  pagination,
  chunkPagesSize,
}: IProps) => {
  const [pages, setPages] = useState<Array<number>>([]);

  useEffect(() => {
    if (pagination && pagination?.maxPages > 0) {
      const arrayPages: any = [];

      for (let index = 1; index <= chunkPagesSize; index++) {
        arrayPages.push(index);
      }

      console.log(arrayPages);
      setPages(arrayPages);
    }
  }, [pagination]);

  return (
    <>
      {pages.map((page) => (
        <PageNumber
          key={page}
          isActive={page === pagination?.page}
          type="button"
          onClick={() => {
            if (pagination?.page === page) return;

            if (pagination?.page) {
              setPage(page);
            }
          }}
        >
          {page}
        </PageNumber>
      ))}
      ...
      <PageNumber
        key={pagination?.maxPages}
        isActive={pagination?.maxPages === pagination?.page}
        type="button"
        onClick={() => {
          if (pagination?.maxPages === pagination?.page) return;

          if (pagination?.page) {
            setPage(pagination?.maxPages);
          }
        }}
      >
        {pagination?.maxPages}
      </PageNumber>
    </>
  );
};
