import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  ContainerDrop,
  ContainerDropError,
  ContainerInfo,
  ContainerSelectedFileName,
} from './styles';
import UploadFileSVG from '~/assets/Icons/UploadFile';
import { Color } from '~/styles';
import TrashSVG from '~/assets/Icons/Trash';
import { LabelError } from '~/common/InputV3/styles';

interface Props {
  onFileUploaded: (file: File | null) => void;
  infoFileName?: string | '';
  disabled?: boolean;
  disabledDeleteFile?: boolean;
  name?: string;
  content?: File | null;
  type?:
    | 'default'
    | 'statementInterested'
    | 'statementResponsibility'
    | 'listMaterials'
    | 'CSV'
    | 'image';
  error?: boolean;
  isRevision?: boolean;
}

const DropZone: React.FC<Props> = ({
  onFileUploaded,
  infoFileName,
  disabled,
  disabledDeleteFile,
  name,
  content,
  type,
  error,
  isRevision,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  useEffect(() => {
    content && setSelectedFileUrl(content.name);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      setSelectedFileUrl(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const getAcceptType = (): string[] => {
    switch (type) {
      case 'default':
        return ['.pdf'];
      case 'statementInterested':
        return ['.docx'];
      case 'statementResponsibility':
        return ['.docx'];
      case 'listMaterials':
        return ['.xlsx'];
      case 'image':
        return ['.jpg', '.png', '.pdf'];
      default:
        return ['.pdf'];
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/pdf': [...getAcceptType()],
    },
    disabled,
  });

  return selectedFileUrl ? (
    <ContainerDrop error={selectedFileUrl ? false : error || isRevision}>
      <ContainerSelectedFileName>
        {' '}
        <p>{selectedFileUrl}</p>
        <button
          type="button"
          onClick={() => {
            if (disabledDeleteFile) return;
            setSelectedFileUrl('');
            onFileUploaded(null);
          }}
        >
          <TrashSVG width={20} height={24} />
        </button>
      </ContainerSelectedFileName>
    </ContainerDrop>
  ) : (
    <ContainerDropError>
      <ContainerDrop
        {...getRootProps()}
        error={selectedFileUrl ? false : error || isRevision}
      >
        <input {...getInputProps()} name={name} />
        <ContainerInfo error={content ? false : error}>
          <p>{infoFileName}</p>
          <div>
            <UploadFileSVG
              colorNewIcon={isRevision ? '#000000' : Color.gray}
              newIcon
            />
          </div>
        </ContainerInfo>
      </ContainerDrop>
      {error && (
        <LabelError
          style={{
            fontWeight: 500,
            fontSize: '11px',
          }}
        >
          Campo obrigatório
        </LabelError>
      )}
    </ContainerDropError>
  );
};

export default DropZone;
