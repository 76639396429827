/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import moment from 'moment';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  Container,
  MiniBar,
  OrderStatus,
  OrderStatusBar,
  OrderTitle,
  Content,
  Divider,
  OrderDate,
  DateContainer,
  ContentDivider,
  StatusOpt,
  ProtocolInfo,
  ContainerProtocol,
  DateDescription,
  DateText,
  DateSeparator,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { IOrderService, Ibar } from '~/store/modules/orderServices/types';
import { CopyButton } from './CopyButton';
import Protocol from './Protocol';

export interface IPropsStatus {
  status: {
    label: string;
    description: string;
    color: string;
    bar: Array<Ibar>;
  };
}

export const Borders: FC<IPropsStatus> = ({ status }) => {
  return (
    <>
      {status?.bar?.map((bar, index) => (
        <MiniBar key={index && index.toString()} backgroundColor={bar?.color} />
      ))}
    </>
  );
};

interface OrderStatusProps {
  status: {
    label: string;
    description: string;
    color: string;
    bar: Array<Ibar>;
  };
}

export const OrderStatusSection: FC<OrderStatusProps> = ({ status }) => {
  return (
    <OrderStatus>
      <OrderStatusBar>
        <Borders status={status} />
      </OrderStatusBar>
      <OrderTitle backgroundColor={status?.color}>{status?.label}</OrderTitle>
    </OrderStatus>
  );
};

interface IDateOrderProps {
  description: string;
  text: string;
}

const DateOrder: FC<IDateOrderProps> = ({ description, text }) => {
  return (
    <div>
      <DateDescription>{description}</DateDescription>
      <DateText>{text}</DateText>
    </div>
  );
};

interface IOrderDateProps {
  createdAt: string;
  closedAt?: string;
  status: string;
  expectedDate?: string;
}

const OrderDateSection: FC<IOrderDateProps> = ({
  createdAt,
  closedAt,
  status,
  expectedDate,
}) => {
  return (
    <OrderDate style={{ justifyContent: !closedAt ? 'center' : 'space-between' }}>
      <DateContainer>
        <DateOrder description="Data de solicitação" text={createdAt} />
        {(closedAt || expectedDate) && <DateSeparator />}
        {!closedAt && expectedDate && (
          <DateOrder description="Prazo para conclusão" text={expectedDate} />
        )}
        {closedAt && (
          <DateOrder
            description={` Data de ${
              status === 'Encerrado' ? 'encerramento' : 'conclusão'
            }`}
            text={closedAt}
          />
        )}
      </DateContainer>
    </OrderDate>
  );
};

const SupportCenterDetail: React.FC = () => {
  const dataOs = history.location.state as IOrderService;

  const loading = useSelector((item: IState) => item.orderServices.loading);
  const [downloadProtocol, setDownloadProtocol] = useState<boolean>(false);
  const printRef = useRef(null);

  const handleDownload = async () => {
    const element = printRef.current;
    const canvas = element ? await html2canvas(element) : null;

    const data = canvas?.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      data ? (link.href = data) : null;
      link.download = 'protocolo.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }

    setDownloadProtocol(!downloadProtocol);
  };

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas solicitações',
              active: false,
              onClick: () => Navigation.navigate(RouteName.SUPPORTCENTER),
            },
            {
              label: dataOs?.serviceName,
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Container>
        <Card>
          <h1>{dataOs?.serviceName}</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <Content>
            {downloadProtocol ? (
              <div style={{ width: '100%' }}>
                <ContainerProtocol ref={printRef}>
                  <Protocol {...dataOs} />
                </ContainerProtocol>
                <ContentDivider>
                  <StatusOpt>
                    <button type="button" onClick={handleDownload}>
                      Baixar protocolo
                    </button>
                    <button
                      type="button"
                      onClick={() => setDownloadProtocol(!downloadProtocol)}
                    >
                      Voltar
                    </button>
                  </StatusOpt>
                </ContentDivider>
              </div>
            ) : (
              <>
                <ContentDivider>
                  <OrderStatusSection status={dataOs?.status} />
                  <OrderDateSection
                    createdAt={moment(dataOs?.createdDate).format('DD/MM/YYYY')}
                    closedAt={
                      dataOs?.closedDate
                        ? moment(dataOs?.closedDate).format('DD/MM/YYYY')
                        : undefined
                    }
                    status={dataOs?.status?.label}
                    expectedDate={
                      dataOs?.expectedDate
                        ? moment(dataOs?.expectedDate).format('DD/MM/YYYY')
                        : undefined
                    }
                  />
                </ContentDivider>
                <Divider />
                <ContentDivider>
                  <ProtocolInfo>
                    <p>O número do seu protocolo é:</p>
                    <CopyButton>{dataOs?.protocol}</CopyButton>
                  </ProtocolInfo>
                  <StatusOpt>
                    <button
                      type="button"
                      onClick={() => setDownloadProtocol(!downloadProtocol)}
                    >
                      Baixar protocolo
                    </button>
                    <button
                      type="button"
                      onClick={() => Navigation.navigate(RouteName.HOME)}
                    >
                      Voltar para home
                    </button>
                  </StatusOpt>
                </ContentDivider>
              </>
            )}
          </Content>
        </Card>
      </Container>
    </Main>
  );
};

export default SupportCenterDetail;
