import React from 'react';
import { ContainerIndicators, Indicator } from './styles';
import { Color } from '~/styles';

interface IProps {
  strengthLevel: number;
  mandatoryRequirement: boolean;
}

const PasswordStrengthIndicator: React.FC<IProps> = ({
  strengthLevel,
  mandatoryRequirement,
}) => {
  return (
    <ContainerIndicators>
      <Indicator
        backgroundColor={
          strengthLevel >= 2 && mandatoryRequirement ? Color.redSecond : ''
        }
      />
      <Indicator
        backgroundColor={
          strengthLevel >= 4 && mandatoryRequirement ? Color.yellow : ''
        }
      />
      <Indicator
        backgroundColor={
          strengthLevel === 5 && mandatoryRequirement ? Color.green : ''
        }
      />
    </ContainerIndicators>
  );
};

export default PasswordStrengthIndicator;
