import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsContainer {
  successCreate?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  height: 286px;
  border-radius: 16px;
  border: 2px solid ${Color.green};
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 22px;

  svg {
    margin-right: 24px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    p {
      line-height: 122.5%;
      text-align: left;
      margin: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.4px;
      color: ${Color.green};
      margin-bottom: 4px;
    }

    .subtitle {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 122.5%;
      letter-spacing: -0.35px;
      margin-top: 9.5px;
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    height: 246px;
    padding: 0 24px;

    svg {
      margin-right: 0;
    }

    div {
      width: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
        max-width: calc(350px + 48px);
      }

      .title {
        margin-top: 24px;
        white-space: nowrap;
      }

      .subtitle {
        margin-top: 0;
      }
    }
  }
`;
