import React from 'react';

import {
  TContentHeader,
  TableBody,
  TableName,
  TableHeader,
  TableContainer,
  TableNameText,
  TableHeaderText,
  Table,
} from './styles';

import Row from './Row';
import EmptyMessage from '~/components/EmptyMessage';
import SkeletonCustom from '~/components/Skeleton';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Margin } from '~/components/Margin';

const TableCustom = () => {
  const loading = useSelector(
    (item: IState) => item.enrollmentsNegativeCertificates.loading,
  );
  const data = useSelector(
    (item: IState) => item?.enrollmentsNegativeCertificates?.data,
  );

  const item = {
    title: 'CERTIDÃO NEGATIVA',
    headers: [
      { name: 'Ano Quitação', id: 0 },
      { name: 'Referência Emissão', id: 1 },
      { name: 'Data Emissão', id: 2 },
      { name: 'Impresso', id: 3 },
      { name: '', id: 4 },
    ],
  };

  const skeletonLength = [0, 1, 2, 3, 4, 5, 6];

  return (
    <TableContainer>
      <TableName>
        <TableNameText>{item.title}</TableNameText>
      </TableName>
      <Table>
        <TContentHeader>
          <TableHeader>
            {item?.headers?.map((header) => (
              <TableHeaderText key={header.id}>{header.name}</TableHeaderText>
            ))}
          </TableHeader>
        </TContentHeader>
        <TableBody emptyData={data?.length < 1}>
          {loading ? (
            skeletonLength?.map((skeletonItem) => (
              <tr
                style={{ width: '100%', height: 40 }}
                key={skeletonItem.toString()}
              >
                <td style={{ width: '5000px', height: 40 }} colSpan={5}>
                  <Margin height={20} />
                  <SkeletonCustom height={32} />
                </td>
              </tr>
            ))
          ) : data?.length > 0 ? (
            data.map((item) => <Row {...item} key={item.id} />)
          ) : (
            <EmptyMessage textMessage="Você ainda não possui Certidão Negativa!" />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCustom;
