/* eslint-disable no-alert */
import React, { FC, useEffect, useState } from 'react';

import { Label, Main, Row, SenFiles } from './styles';

import DropZone from '../../../../components/DropZone';
import { Margin } from '~/components/Margin';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import convertFileToBase64 from '~/utils/convertBase64';
import {
  patchAttachmentPropertyRegistrationRequest,
  patchStepLocalPropertyRegistratioRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/StatusInfo';
import { stepStatusEnum } from '~/enums/customerObligationEnum';

const Form: FC = () => {
  const dispatch = useDispatch();

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );

  const [file, setFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [revisionDescription, setRevisionDescription] = useState<string>('');

  useEffect(() => {
    if (caseFCP) {
      const thisIsRevision = caseFCP?.step.status === stepStatusEnum.REVIEW;
      setIsRevision(thisIsRevision);

      if (thisIsRevision) {
        const fileStep = caseFCP?.attachments.find(
          (attachment) => attachment.type === 'INSPECTION_BOX_PHOTO',
        );

        if (fileStep && fileStep.review) {
          setRevisionDescription(fileStep?.review_description || '');
        }
      }
    }
  }, [caseFCP]);

  const handleClick = () => {
    dispatch(
      patchAttachmentPropertyRegistrationRequest({
        caseId: caseFCP?.id || '',
        step: '5',
      }),
    );
  };

  useEffect(() => {
    if (file && !loadingUploadFile) return setDisabled(false);

    return setDisabled(true);
  }, [file, disabled]);

  return (
    <Main>
      <StatusInfo status="info">
        Os arquivos devem ser anexados em <b>formato JPG, PNG ou PDF</b>.
      </StatusInfo>
      <Margin height={40} />
      {isRevision && (
        <>
          <StatusInfo style={{ width: 'calc(100% - 50px)' }} status="warning">
            As informações precisam ser revisadas. Por favor, preencha novamente.
          </StatusInfo>
          <Margin height={40} />

          {caseFCP?.reviewDescription && (
            <>
              <Label
                style={{
                  width: '100%',
                  color: Color.red,
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {caseFCP?.reviewDescription}
              </Label>
              <Margin height={40} />
            </>
          )}
        </>
      )}
      <Subtitle>
        Nesta etapa é necessário o anexo de todos os documentos e informações
        solicitados a seguir para a solicitação de início de obra.
      </Subtitle>
      <Margin height={64} />
      <Row>
        <SenFiles>
          <DropZone
            disabled={!!file}
            infoFileName="Foto da CI (Caixa de Inspeção) na calçada*"
            name="Foto da CI (Caixa de Inspeção) na calçada*"
            content={file || null}
            isRevision={isRevision}
            onFileUploaded={(file: File | null) => {
              setFile(file);

              if (file) {
                convertFileToBase64(file, (base64: string) => {
                  dispatch(
                    postUploadFilePropertyRegistrationRequest({
                      caseIdSales: caseFCP?.id || '',
                      type: 'INSPECTION_BOX_PHOTO',
                      step: '5',
                      filename: file?.name || '',
                      filedata: base64,
                    }),
                  );
                });
              }
            }}
            type="image"
          />
        </SenFiles>
        {isRevision && <Label isRevision>{revisionDescription}</Label>}
      </Row>
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStepLocalPropertyRegistratioRequest({
                name: '',
                number: 4,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{
            opacity: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleClick}
          disabled={disabled}
          loading={!!loadingUploadFile || !!loadingAttachFiles}
        >
          Solicitar ligação de <br />
          esgoto
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Form;
