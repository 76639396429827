import React, { FC } from 'react';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import NegotiationDetail from '~/components/NegotiationDetail';

const DebtNegotiationDetail: FC = () => {
  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Detalhes da negociação',
      active: true,
    },
  ];

  return <NegotiationDetail links={links} loading={false} />;
};

export default DebtNegotiationDetail;
