export enum stepStatusEnum {
  FORM = 'form',
  WAIT = 'wait',
  SUCCESS = 'success',
  APPROVED_LICENSE = 'approved_license',
  WAITING_FOR_CONNECTION = 'connecting',
  ACCEPTED_DATE = 'accepted_date',
  REVIEW = 'review',
}

export const subStepEnum = {
  A: 'A',
  B: 'B',
};

export const EnumCustomerObligationFilesStep = {
  ONE: [],
  TWO: [],
  TWO_A: [
    'owner_proxy_giving_powers_to_interested',
    'all_involved_identification_document',
    'interested_statement_informing_who_execute_work',
    'responsibility_term_company_execute_work',
    'oc_constant_work_art',
    'legal_entity_registration_certificate_executing_company',
    'individual_registration_certificate_technician_charge',
    'technical_manager_identification',
    'legal_entities_contractors_national_register',
    'executing_company_national_register_of_legal_entities',
    'social_contract_contracting_company',
    'social_contract_executing_company',
    'property_purchase_and_sale_deed',
    'materials_list',
    'performing_company_registered_with_recad',
  ],
  TWO_B: [
    'executed_work_descriptive_memorial',
    'executed_work_calculation_memorial',
    'executed_work_executive_project',
  ],
  THREE: [
    'executive_project',
    'signaling_plan',
    'scheduled_works_or_repairs_request_form',
    'timeline',
    'commitment_term',
    'responsibility_and_waiver_term',
    'design_elements_declaration',
    'technical_justification_letter',
  ],
  FOUR: [],
  FIVE_A: [],
  FIVE_B: [],
  SEVEN: [],
  EIGHT: [],
};
