import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 32px;
  background: #f2f2f2;
  border-radius: 8px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  input {
    font-size: 16px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 210px;
  height: 48px;
  background: #ffffff;
  border: 2px solid #37b4c8;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #37b4c8;
`;
