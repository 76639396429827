import {
  IRequestRegisterEnrollmentNotClient,
  IRequestRegisterEnrollmentNotClientValidate,
} from '~/store/modules/enrollmentsUsersNotClient/types';
import { api } from './api';
import formatter from '~/utils/formatter';

function getEnrollmentsUsersNotClientValidateService({
  enrollment,
}: IRequestRegisterEnrollmentNotClientValidate) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsUsersNotClientValidate: any = async () => {
        const response = await api.get(`v3/enrollments/${enrollment}/enrollment`);

        return response.data;
      };

      resolve(getEnrollmentsUsersNotClientValidate());
    } catch (error) {
      reject(error);
    }
  });
}

function patchEnrollmentsUsersNotClientRegisterService({
  cnp,
  enrollment,
  operationId,
  nomeCompleto,
  rg,
  celular,
  telefone,
  email,
  dataNascimento,
  nomeMae,
  vinculoImovel,
  tipoReservatorio,
  rua,
  numero,
  bairro,
  complemento,
  pontoReferencia,
  numeroResidenciaLado,
  cidade,
  estado,
  cep,
  deAcordo,
  files,
}: IRequestRegisterEnrollmentNotClient) {
  return new Promise((resolve, reject) => {
    try {
      const patchEnrollmentsUsersNotClientRegister: any = async () => {
        const response = await api.patch(`v3/users/me/register`, {
          cnp: formatter.formatarNumber(cnp),
          operationId,
          enrollment,
          nomeCompleto,
          rg: formatter.formatarNumber(rg),
          celular,
          telefone,
          email,
          dataNascimento,
          nomeMae,
          vinculoImovel,
          tipoReservatorio,
          rua,
          numero,
          bairro,
          complemento,
          pontoReferencia,
          numeroResidenciaLado,
          cidade,
          estado,
          cep,
          deAcordo,
          files,
        });

        return response.data;
      };

      resolve(patchEnrollmentsUsersNotClientRegister());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsUsersNotClientVerifyService({
  enrollment,
  location,
}: IRequestRegisterEnrollmentNotClientValidate) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsUsersNotClientVerify: any = async () => {
        const response = await api.get(
          `/v3/reregistrations/enrollment/${enrollment}/operation/${location}/verify`,
        );

        return response.data;
      };

      resolve(getEnrollmentsUsersNotClientVerify());
    } catch (error) {
      reject(error);
    }
  });
}

function getLocationsForReregistrationService() {
  return new Promise((resolve, reject) => {
    try {
      const getLocationsForReregistration: any = async () => {
        const response = await api.get(`v3/reregistrations/locations`);

        return response.data;
      };

      resolve(getLocationsForReregistration());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsUsersNotClientValidateService,
  patchEnrollmentsUsersNotClientRegisterService,
  getEnrollmentsUsersNotClientVerifyService,
  getLocationsForReregistrationService,
};
