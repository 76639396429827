import {
  IRequestPostAttachments,
  IRequestPostCreate,
} from '~/store/modules/socialTariff/types';
import { api } from './api';

const url = 'v3/social-tariff';

function getSocialTariffService(registration: string) {
  return new Promise((resolve, reject) => {
    try {
      const getSocialTariff = async () => {
        const response = await api.get(`${url}/enrollment/${registration}`);
        return response.data;
      };
      resolve(getSocialTariff());
    } catch (error) {
      reject(error);
    }
  });
}

function postSocialTariffAttachmentsService(data: IRequestPostAttachments[]) {
  return new Promise((resolve, reject) => {
    try {
      const postSocialTariffAttachments = async () => {
        const formData = new FormData();

        await data.map((item: any) => {
          return formData.append(item.name, item.file);
        });

        const response = await api.post(`${url}/attachments`, formData, {
          timeout: 120000,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      };
      resolve(postSocialTariffAttachments());
    } catch (error) {
      reject(error);
    }
  });
}

function postSocialTariffCreateService(data: IRequestPostCreate) {
  return new Promise((resolve, reject) => {
    try {
      const postSocialTariffCreate = async () => {
        const response = await api.post(`${url}`, data, {
          timeout: 360000,
        });
        return response.data;
      };

      resolve(postSocialTariffCreate());
    } catch (error) {
      reject(error);
    }
  });
}

function getSocialTariffRenovationService(registration: string) {
  return new Promise((resolve, reject) => {
    try {
      const getSocialTariffRenovation = async () => {
        const response = await api.get(
          `${url}/renovation/enrollment/${registration}`,
        );
        return response.data;
      };
      resolve(getSocialTariffRenovation());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getSocialTariffService,
  postSocialTariffAttachmentsService,
  postSocialTariffCreateService,
  getSocialTariffRenovationService,
};
