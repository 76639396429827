import React from 'react';
import ReactDom from 'react-dom';
import * as S from './styles';

interface Props {
  children: React.ReactNode;
  onClose: any;
  open: boolean;
  fullScreen?: boolean;
}

export const Dialog: React.FC<Props> = ({ children, open, onClose, fullScreen }) => {
  return ReactDom.createPortal(
    <S.Backdrop
      fullScreen={fullScreen}
      open={open}
      className="backdrop"
      onClick={(e) => {
        if ((e.target as Element).classList.contains('backdrop')) {
          onClose();
        }
      }}
    >
      {children}
    </S.Backdrop>,
    document.getElementById('modal-root')!,
  );
};
