/* eslint-disable prefer-const */
import React, { FC } from 'react';
import Sewage from './Sewage';
import Water from './Water';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

const DocumentationApproval: FC = () => {
  const dataItem = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const state = dataItem?.type;

  return (
    <>
      {state === 'FCPE' && <Sewage />}
      {state === 'FCPA' && <Water />}
    </>
  );
};

export default DocumentationApproval;
