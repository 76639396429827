interface File {
  buffer: string;
  fileName?: string;
}

const onShareFile = ({ buffer, fileName = 'fatura.pdf' }: File) => {
  const linkSource = `data:application/pdf;base64, ${buffer}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export default onShareFile;
