import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Status from './Status';
import Form from './Form';

const SewageConnectionRequest: FC = () => {
  const dataItem = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.step,
  );

  const status = (dataItem?.status || '') as stepStatusEnum;
  const { FORM, WAIT, SUCCESS, WAITING_FOR_CONNECTION, REVIEW } = stepStatusEnum;

  return (
    <>
      {[FORM, REVIEW].includes(status) && <Form />}
      {[WAIT, SUCCESS, WAITING_FOR_CONNECTION].includes(status) && <Status />}
    </>
  );
};

export default SewageConnectionRequest;
