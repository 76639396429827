import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  max-width: 239px;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TransactionText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
`;

export const InfoText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #004b55;
`;
