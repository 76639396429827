/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import configsAppJsonImport from '~/configs/app.config';

import { BackGroundImage } from '~/components/BackGroundImage';
import { ButtonText } from '~/components/ButtonText';
import { CardForm } from '~/components/CardForm';
import { Input } from '~/components/Input';
import { InputChecked } from '~/components/InputChecked';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';

import { Color } from '~/styles';
import { Breakpoints } from '../../styles/breakpoints';
import {
  Main,
  DescriptionTextStyled,
  ContainerTermsStyled,
  TextTerm,
  TitleTextStyled,
  ActionTextStyled,
  ContainerInputStyled,
} from './styles';

import { getCloseErrorEnrollmentsUsersValidate } from '~/store/modules/enrollmentsUsers/actions';
import { Link } from 'react-router-dom';
import { RepositoriesTypesUsers } from '~/store/modules/enrollmentsUsers/types';
import { UserCategoriesEnum } from '~/enums/userCategoriesEnum';

const configsAppJson = configsAppJsonImport as any;

const RegisterManager: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRegister,
  );
  const dataCreateClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateClient,
  );

  const [cnpj, setCnpj] = useState<string>(
    dataCreateClient?.cnpj ? dataCreateClient?.cnpj : '',
  );
  const [razaoSocial, setRazaoSocial] = useState<string>(
    dataCreateClient?.razaoSocial ? dataCreateClient?.razaoSocial : '',
  );
  const [name, setName] = useState<string>(
    dataCreateClient?.name ? dataCreateClient?.name : '',
  );
  const [cpf, setCpf] = useState<string>(
    dataCreateClient?.cpf ? dataCreateClient?.cpf : '',
  );
  const [creci, setCreci] = useState<string>(
    dataCreateClient?.creci ? dataCreateClient?.creci : '',
  );
  const [email, setEmail] = useState<string>(
    dataCreateClient?.email ? dataCreateClient?.email : '',
  );
  const [phone, setPhone] = useState<string>(
    dataCreateClient?.phone ? dataCreateClient?.phone : '',
  );
  const [telephone, setTelephone] = useState<string>(
    dataCreateClient?.telephone ? dataCreateClient?.telephone : '',
  );
  const [systemTerms, setSystemTerms] = useState<boolean>(
    dataCreateClient?.acceptance_terms_agree
      ? dataCreateClient?.acceptance_terms_agree
      : false,
  );
  const [productTerms, setProductTerms] = useState<boolean>(
    dataCreateClient?.concession_terms_agree
      ? dataCreateClient?.concession_terms_agree
      : false,
  );
  const [enableSubmit, setEnableSubmit] = useState<boolean>(
    configsAppJson?.NODE_ENV !== 'PRD',
  );
  const [disabled, setDisabled] = useState<boolean>(true);

  const clearError = () => {
    dispatch(getCloseErrorEnrollmentsUsersValidate());
  };

  useEffect(() => {
    return () => clearError();
  }, []);

  useEffect(() => {
    if (
      cnpj &&
      razaoSocial &&
      name &&
      email &&
      phone &&
      cpf &&
      systemTerms &&
      productTerms &&
      enableSubmit
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    cnpj,
    razaoSocial,
    name,
    email,
    phone,
    cpf,
    systemTerms,
    productTerms,
    enableSubmit,
  ]);

  const handleContinue = () => {
    dispatch({
      type: RepositoriesTypesUsers.ENROLLMENTS_UPDATE_CREATE_CLIENT_METHOD_REQUEST,
      payload: {
        data: {
          cnp: formatter.formatarNumber(cnpj),
          cnpj: formatter.formatarNumber(cnpj),
          razaoSocial,
          name,
          cpf: formatter.formatarNumber(cpf),
          creci,
          email,
          phone,
          telephone,
          isManager: true,
          category: UserCategoriesEnum.MANAGER,
          acceptance_terms_agree: true,
          concession_terms_agree: true,
        },
      },
    });

    return Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD);
  };

  return (
    <Main>
      <CardForm>
        <Margin height={isMobile ? 38 : 59} />
        <TitleTextStyled>Novo cadastro</TitleTextStyled>
        <DescriptionTextStyled>
          Para começar o cadastro, precisamos que informe os seguintes dados:
        </DescriptionTextStyled>
        <ActionTextStyled>
          Caso já seja cadastrado, <Link to={RouteName.LANDING}>faça login</Link>
        </ActionTextStyled>
        <Input
          value={formatter.cnpjMask(cnpj)}
          onChange={(ev) => setCnpj(formatter.cpfCnpjMask(ev.target.value))}
          label="CNPJ*"
        />
        <Margin height={isMobile ? 12 : 18} />
        <Input
          value={razaoSocial}
          onChange={(ev) => setRazaoSocial(ev.target.value)}
          label="Razão Social*"
        />
        <Margin height={isMobile ? 12 : 18} />
        <Input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          label="Nome completo*"
        />
        <Margin height={isMobile ? 12 : 18} />
        <ContainerInputStyled>
          <Input
            value={formatter.cpfMask(cpf)}
            onChange={(ev) => setCpf(formatter.cpfMask(ev.target.value))}
            label="CPF*"
          />
          <Input
            value={creci}
            onChange={(ev) => setCreci(ev.target.value)}
            label="CRECI"
          />
        </ContainerInputStyled>
        <Margin height={isMobile ? 12 : 18} />
        <Input
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          label="Email*"
        />
        <Margin height={isMobile ? 12 : 18} />
        <ContainerInputStyled>
          <Input
            value={formatter.formatarPhone(phone)}
            onChange={(ev) => setPhone(formatter.formatarPhone(ev.target.value))}
            label="Celular*"
          />
          <Input
            value={formatter.formatarTel(telephone)}
            onChange={(ev) => setTelephone(formatter.formatarTel(ev.target.value))}
            label="Telefone"
          />
        </ContainerInputStyled>
        <Margin height={isMobile ? 12 : 18} />
        <ContainerTermsStyled>
          <InputChecked
            checked={systemTerms}
            onChange={(e) => setSystemTerms(e.target.checked)}
          >
            <TextTerm>
              Li o{' '}
              <a
                href="https://digiigua.igua.com.br/trm1.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Termo de Adesão
              </a>{' '}
              para utilização dos sistemas do Grupo Iguá
            </TextTerm>
          </InputChecked>
          <Margin height={isMobile ? 2 : 3} />
          <InputChecked
            checked={productTerms}
            onChange={(e) => setProductTerms(e.target.checked)}
          >
            <TextTerm>
              Li o{' '}
              <a
                href="https://digiigua.igua.com.br/trm2.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Termo de Adesão
              </a>{' '}
              para oferecimento de produtos e serviços
            </TextTerm>
          </InputChecked>
        </ContainerTermsStyled>
        {configsAppJson?.NODE_ENV === 'PRD' && (
          <div
            style={{
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            <ReCAPTCHA
              sitekey={configsAppJson?.GOOGLE_RECAPTCHA[configsAppJson?.NODE_ENV]}
              onErrored={() => setEnableSubmit(false)}
              onExpired={() => setEnableSubmit(false)}
              onChange={(j) => setEnableSubmit(!!j)}
              size="normal"
            />
          </div>
        )}

        <Margin height={isMobile ? 16 : 24} />
        <ButtonText
          text="Continuar"
          typeBtn="content"
          Color={Color.white}
          backgroundColor={disabled ? Color.gray : Color.blueSeconde}
          onClick={() => handleContinue()}
          loading={loading}
        />
        <Margin height={isMobile ? 11 : 16} />
        <ButtonText
          text="Cancelar"
          typeBtn="outlined"
          Color="#E42313"
          onClick={() => Navigation.navigate(RouteName.REGISTERPROFILE)}
        />
        <Margin height={isMobile ? 15 : 25} />
      </CardForm>
      <BackGroundImage />
    </Main>
  );
};

export default RegisterManager;
