import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const Header = styled.div`
  width: 100%;
  border-top: 26px solid ${Color.blueIntense};
  padding: 24px 0;
  text-align: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 50px 0;
  }
`;
