import React, { FC } from 'react';
import { Wrapper, Step, StepNumber, StepLabel, StepDivider } from './styles';

interface Props {
  steps: {
    label: string;
    id: number;
  }[];
  activeStep: number;
}

export const Stepper: FC<Props> = ({ steps, activeStep }) => {
  return (
    <Wrapper>
      {steps.map((step) => {
        const stepsCount = steps.length;
        const stepPosition = steps.indexOf(step);
        let isActive = false;
        stepPosition === activeStep ? (isActive = true) : (isActive = false);

        return (
          <React.Fragment key={step.id}>
            <Step>
              <StepNumber isActive={isActive}>{stepPosition + 1}</StepNumber>
              <StepLabel isActive={isActive}>{step.label}</StepLabel>
            </Step>
            {stepPosition < stepsCount - 1 ? <StepDivider /> : null}
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};
