import React, { FC } from 'react';
import {
  Border,
  Container,
  ContainerEmpty,
  ContainerHeader,
  ContainerInfoMobile,
  ContainerInvoice,
  ContainerMobileInvoice,
  TextAmountMobile,
  TextDueMobile,
  TextInvoice,
  TextStatusInvoice,
  TextTypeMobile,
} from './styles';
import { statusColor } from '~/utils/invoiceStatus';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import moment from 'moment';
import formatter from '~/utils/formatter';
import EmptyMessage from '~/components/EmptyMessage';
import { mockDebtNegotiation } from '../../mock';
import { Margin } from '~/components/Margin';

const PendingList: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.laptop.min });

  return (
    <Container>
      {!isMobile && (
        <ContainerHeader>
          <Border color="transparente" />
          <p>Status/Tipo</p>
          <p>Vencimento</p>
          <p>Valor</p>
          <p>Referência</p>
        </ContainerHeader>
      )}
      {mockDebtNegotiation ? (
        mockDebtNegotiation?.negotiableDebts?.items?.map(
          (debt: any, index: number) =>
            isMobile ? (
              <ContainerMobileInvoice
                key={index && index.toString()}
                color={statusColor(debt?.status, true)}
              >
                <Border
                  color={
                    debt?.type === 'Fatura'
                      ? statusColor(debt?.status, true)
                      : '#999999'
                  }
                />
                <ContainerInfoMobile>
                  <TextTypeMobile>{debt?.type}</TextTypeMobile>
                  <TextAmountMobile
                    color={
                      debt?.type === 'Fatura'
                        ? statusColor(debt?.status, true)
                        : '#999999'
                    }
                  >
                    {formatter.formatCurrency(debt?.amount)}
                  </TextAmountMobile>
                  {debt?.type === 'Fatura' && (
                    <TextDueMobile color={statusColor(debt?.status, true)}>
                      Vence em{' '}
                      {debt?.dueDate && moment(debt?.dueDate).format('DD/MM/YYYY')}
                    </TextDueMobile>
                  )}
                </ContainerInfoMobile>
              </ContainerMobileInvoice>
            ) : (
              <ContainerInvoice
                key={index && index.toString()}
                color={statusColor(debt?.status, true)}
              >
                <Border color={statusColor(debt?.status, true)} />
                {debt?.type === 'Fatura' ? (
                  <TextStatusInvoice color={statusColor(debt?.status, true)}>
                    {debt?.status}
                    <br />
                    <TextInvoice color="#1F1F1F">{debt?.type}</TextInvoice>
                  </TextStatusInvoice>
                ) : (
                  <TextInvoice color="#1F1F1F">{debt?.type}</TextInvoice>
                )}
                <TextInvoice>
                  {debt?.dueDate && moment(debt?.dueDate).format('DD/MM/YYYY')}
                </TextInvoice>
                <TextInvoice>{formatter.formatCurrency(debt?.amount)}</TextInvoice>
                <TextInvoice>{debt?.reference}</TextInvoice>
              </ContainerInvoice>
            ),
        )
      ) : (
        <ContainerEmpty>
          <Margin height={24} />
          <EmptyMessage textMessage="Você não possui pendências disponíveis para negociação!" />
        </ContainerEmpty>
      )}
    </Container>
  );
};

export default PendingList;
