import styled from 'styled-components';

import { Color } from '~/styles';

export interface TextProps {
  color?: string;
  size?: number;
  textAlign?: 'center' | 'left' | 'right';
  weight?: number;
  textDecoration?: boolean;
  upcase?: boolean;
  fontFamily?:
    | 'Montserrat-Black'
    | 'Montserrat-BlackItalic'
    | 'Montserrat-Bold'
    | 'Montserrat-BoldItalic'
    | 'Montserrat-ExtraLight'
    | 'Montserrat-ExtraLight'
    | 'Montserrat-ExtraLight'
    | 'Montserrat-Light'
    | 'Montserrat-Italic'
    | 'Montserrat-LightItalic'
    | 'Montserrat-Medium'
    | 'Montserrat-MediumItalic'
    | 'Montserrat-Regular'
    | 'Montserrat-SemiBold'
    | 'Montserrat-SemiBoldItalic'
    | 'Montserrat-Thin'
    | 'Montserrat-ThinItalic';
}

export const Text = styled.div.attrs({ allowFontScaling: false })<TextProps>`
  color: ${({ color }) => color || Color.black};
  font-size: ${({ size }) => size || 12}px;
  font-weight: ${({ weight }) => weight || 400};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-variant: lining-nums;
  text-transform: ${({ upcase }) => (upcase ? 'uppercase' : 'none')};
`;
