import { Dispatch } from 'redux';
import {
  IResponseSubscribePlans,
  IResponseSubscribeMe,
  RepositoriesTypesEnrollmentsSubscribes,
  IRequestSubscribeCreate,
  IRequestSubscribeDelete,
  IResponseSubscribeMeEnrollment,
} from './types';
import {
  getEnrollmentsSubscribesMeService,
  getEnrollmentsSubscribesAllPlansService,
  postEnrollmentsSubscribesMeService,
  deleteEnrollmentsSubscribesMeService,
  getEnrollmentsSubscribesMeEnrollmentService,
} from '~/services/enrollmentsSubscribes';

const getEnrollmentsSubscribesMeRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_REQUEST,
    });

    const response =
      (await getEnrollmentsSubscribesMeService()) as IResponseSubscribeMe;

    return dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_SUCCESS,
      payload: {
        data: {
          ...response,
        },
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      },
    });
  }
};

const getErrorCloseEnrollmentsSubscribesMe = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE_CLOSE,
  });
};

const getEnrollmentsSubscribesMeEnrollmentRequest =
  (enrollment: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsSubscribesMeEnrollmentService(
        enrollment,
      )) as IResponseSubscribeMeEnrollment;

      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getErrorCloseEnrollmentsSubscribesMeEnrollment =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE_CLOSE,
    });
  };

const getEnrollmentsSubscribesAllPlansRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_REQUEST,
    });

    const response =
      (await getEnrollmentsSubscribesAllPlansService()) as IResponseSubscribePlans;

    return dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_SUCCESS,
      payload: {
        data: {
          ...response,
        },
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      },
    });
  }
};

const getErrorCloseEnrollmentsSubscribesAllPlans =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE_CLOSE,
    });
  };

const postEnrollmentsSubscribesMeRequest =
  (data: IRequestSubscribeCreate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_REQUEST,
      });

      await postEnrollmentsSubscribesMeService({
        ...data,
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const postErrorCloseEnrollmentsSubscribesMe = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE_CLOSE,
  });
};

const deleteEnrollmentsSubscribesMeRequest =
  (data: IRequestSubscribeDelete, enrollment: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_REQUEST,
      });

      await deleteEnrollmentsSubscribesMeService({
        ...data,
      });

      const responseEnrollment = (await getEnrollmentsSubscribesMeEnrollmentService(
        enrollment,
      )) as IResponseSubscribeMeEnrollment;

      dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_SUCCESS,
        payload: {
          data: {
            ...responseEnrollment,
          },
        },
      });

      const response =
        (await getEnrollmentsSubscribesMeService()) as IResponseSubscribeMe;

      dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const deleteErrorCloseEnrollmentsSubscribesMe = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE_CLOSE,
  });
};

export {
  getEnrollmentsSubscribesMeRequest,
  getErrorCloseEnrollmentsSubscribesMe,
  getEnrollmentsSubscribesMeEnrollmentRequest,
  getErrorCloseEnrollmentsSubscribesMeEnrollment,
  getEnrollmentsSubscribesAllPlansRequest,
  getErrorCloseEnrollmentsSubscribesAllPlans,
  postEnrollmentsSubscribesMeRequest,
  postErrorCloseEnrollmentsSubscribesMe,
  deleteEnrollmentsSubscribesMeRequest,
  deleteErrorCloseEnrollmentsSubscribesMe,
};
