import React from 'react';

const StatusWaitIconSVG = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.320312" width="44" height="44" rx="8" fill="#37B4C8" />
      <path
        d="M22 12.3203V16.606M22 28.0346V32.3203M12 22.3203H16.2857M29.1429 15.1775L25.5714 18.7489M14.8571 29.4632L18.4286 25.8917M27.7143 22.3203H32M14.8571 15.1775L18.4286 18.7489M29.1429 29.4632L25.5714 25.8917"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default StatusWaitIconSVG;
