/* eslint-disable no-unsafe-optional-chaining */
import React, { FC } from 'react';

import { Input } from '~/components/Input';
import { BoxInput, Container, ContainerInputs } from './styles';
import { InputTextArea } from '~/components/InputTextArea';
import { IReportLackWaterStepFourProps } from '..';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';
import { IResponseInputsLackWater } from '~/store/modules/orderServices/types';
import { hasTankerTruckInputs } from '../..';

const FormDataConfirmation: FC<IReportLackWaterStepFourProps> = (dataForm) => {
  const data = useSelector(
    (item: IState) => item.orderServices.dataAddressLackWater,
  );
  const dataInputs = useSelector((state: IState) => state.orderServices.dataInputs);

  const renderInputField = (input: IResponseInputsLackWater) => {
    const supply_tanker_truck_hidden_inputs =
      hasTankerTruckInputs(input) &&
      (dataForm?.supply_tanker_truck?.toUpperCase() === 'NÃO' ||
        !dataForm?.supply_tanker_truck);

    if (
      (supply_tanker_truck_hidden_inputs && input?.name !== 'supply_tanker_truck') ||
      (input?.name === 'supply_tanker_truck' && !dataForm?.supply_tanker_truck)
    ) {
      return null;
    }

    switch (input?.type) {
      case 'select':
        return supply_tanker_truck_hidden_inputs &&
          input?.name === 'supply_tanker_truck' ? (
          <Input
            key={input?.id}
            style={{ resize: 'none' }}
            label={input?.label}
            name={input?.name}
            value={dataForm?.[input?.name]}
            disabled
          />
        ) : (
          <BoxInput key={input?.id}>
            <Input
              style={{ resize: 'none' }}
              label={input?.label}
              name={input?.name}
              value={dataForm?.[input?.name]}
              disabled
            />
          </BoxInput>
        );
      case 'textarea':
        return (
          <InputTextArea
            key={input?.id}
            style={{ resize: 'none' }}
            label={input?.label}
            name={input?.name}
            value={dataForm?.[input?.name]}
            disabled
          />
        );
      case 'input':
        return (
          <BoxInput key={input?.id}>
            <Input
              style={{ resize: 'none' }}
              label={input?.label}
              name={input?.name}
              value={dataForm?.[input?.name]}
              disabled
            />
          </BoxInput>
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      <ContainerInputs>
        <BoxInput>
          <Input
            value={dataForm?.typeProblem}
            label="Tipo de problema"
            name="typeProblem"
            disabled
          />
        </BoxInput>
        <BoxInput>
          <Input
            value={dataForm?.requestDate}
            label="Data da solicitação"
            name="requestDate"
            disabled
          />
        </BoxInput>
      </ContainerInputs>
      <ContainerInputs>
        <Input
          value={dataForm?.enrollment}
          label="Matrícula"
          name="enrollment"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        <Input
          value={data?.complete_address}
          label="Endereço"
          name="address"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        <Input
          value={data?.complement}
          label="Complemento"
          name="complement"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        {dataInputs?.map((input) => renderInputField(input))}
      </ContainerInputs>
    </Container>
  );
};

export default FormDataConfirmation;
