import styled from 'styled-components';
import { ReactComponent as VetorSVG } from './vetorSVG.svg';
import { Color } from '~/styles';

export const Wrapper = styled.div`
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const ContainerText = styled.div`
  background: #f2f2f2;
  border-radius: 24px;
  border: 2px solid ${Color.blueIntense};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px 32px 20px;
  width: 80%;
  max-width: 680px;
`;

export const Title = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${Color.blueIntense};
  text-align: center;
`;

export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 16px;
  color: ${Color.black};
  text-align: center;
`;

export const ImageContainer = styled(VetorSVG)`
  max-height: 70vh;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;
