export enum RepositoriesTypesPlans {
  PLANS_GET_METHOD_REQUEST = '@repositories/PLANS_GET_METHOD_REQUEST',
  PLANS_GET_METHOD_SUCCESS = '@repositories/PLANS_GET_METHOD_SUCCESS',
  PLANS_GET_METHOD_FAILURE = '@repositories/PLANS_GET_METHOD_FAILURE',
}

export interface IResponseGetPlans {
  id: number;
  name: string;
  description: string;
  trial: number;
  amount: number;
  interval: string;
  amount_compare: any;
  interval_compare: any;
  is_advantageous: boolean;
}

export interface IRepositoriesStatePlans {
  readonly data: IResponseGetPlans[] | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
}
