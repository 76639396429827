import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${Breakpoints.tablet.max}px) {
    flex-direction: column;
  }
`;

export const CopyCodeBar = styled.button`
  width: min-content;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background-color: ${Color.white};
  color: ${Color.blueIntense};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2em;
  border: 1px solid ${Color.gray};
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`;

interface ICopyMsgProps {
  showCopyMsg: boolean;
}

export const CopyMsg = styled.div<ICopyMsgProps>`
  display: ${({ showCopyMsg }) => (showCopyMsg ? 'block' : 'none')};
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
`;
