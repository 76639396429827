import React, { FC, useState } from 'react';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerAlert,
  ContainerBorder,
  Border,
  Content,
  ContainerBreadCrumb,
  ContainerHeader,
  ContainerAlertLarge,
} from './styles';
import ConsumptionHistorySession from './ConsumptionHistory';
import { ConfigureAlert } from './ConfigureAlert';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { isMobile } from 'react-device-detect';
import ContainerConsumption from './ContainerConsumption';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import {
  patchErrorCloseEnrollmentsCreateAlertRequest,
  postErrorCloseEnrollmentsCreateAlertRequest,
} from '~/store/modules/enrollmentsAlerts/actions';
import { IHistoryData } from '~/store/modules/enrollmentsConsumptionHistory/types';

const DetailsHistory: FC = () => {
  const dispatch = useDispatch();

  const error = useSelector(
    (state: IState) => state.enrollmentsAlerts.errorCreateAlert,
  );
  const errorPatchAlert = useSelector(
    (state: IState) => state.enrollmentsAlerts.errorPatchAlert,
  );
  const consumptionData = useSelector(
    (item: IState) => item?.enrollmentsConsumptionHistory?.data,
  );
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );
  const dataSubscribe = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.data,
  );
  const isIotClient = eletronic_exists;
  const [filterActiveType, setFilterActiveType] = useState<'daily' | 'monthly'>(
    isIotClient ? 'daily' : 'monthly',
  );
  const [filterValue, setFilterValue] = useState<number>(isIotClient ? 45 : 12);

  return (
    <Main>
      <Modal
        open={
          !!((error && error.state) || (errorPatchAlert && errorPatchAlert.state))
        }
        type="error"
        title="Ops!"
        v3
      >
        <ModalError
          onClose={() => {
            dispatch(postErrorCloseEnrollmentsCreateAlertRequest());
            dispatch(patchErrorCloseEnrollmentsCreateAlertRequest());
          }}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Histórico detalhado',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      {isIotClient && dataSubscribe && dataSubscribe[0] && (
        <ContainerAlertLarge>
          <ConfigureAlert />
        </ContainerAlertLarge>
      )}
      <Card>
        <Content>
          <h1>Histórico detalhado</h1>
          <Margin height={24} />
        </Content>
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <Content>
          <ConsumptionHistorySession
            filterActiveType={filterActiveType}
            setFilterActiveType={setFilterActiveType}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />{' '}
        </Content>
        <Margin height={34} />
        {isIotClient && dataSubscribe && dataSubscribe[0] && (
          <Content>
            <ContainerAlert>
              <ConfigureAlert />
            </ContainerAlert>
          </Content>
        )}
        {consumptionData && consumptionData?.historyData?.length > 0 && (
          <>
            <Margin height={34} />
            <ContainerBorder>
              <Border />
              <h2>Histórico em lista</h2>
              <Border />
            </ContainerBorder>
            <Content>
              <p>
                Últimos {filterValue}{' '}
                {filterActiveType === 'monthly' ? 'meses' : 'dias'}
              </p>
              <Margin height={isMobile ? 24 : 28} />
              <ContainerHeader>
                <div>
                  <span />
                </div>
                <p>Referência</p>
                <p className="small">Consumo real (m³)</p>
                <p> Consumo faturado (m³)</p>
              </ContainerHeader>
              {consumptionData &&
                consumptionData?.historyData?.length > 0 &&
                consumptionData?.historyData?.map((item: IHistoryData) => (
                  <ContainerConsumption key={item.reference} data={item} />
                ))}
            </Content>
          </>
        )}
      </Card>
    </Main>
  );
};

export default DetailsHistory;
