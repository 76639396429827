import styled from 'styled-components';
import { Color } from '~/styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 40px 36px;
  display: grid;
  grid-template-columns: 1.5fr auto 1fr;
  gap: 24px;
`;

export const Divider = styled.div`
  width: 1px;
  background: ${Color.grayLight};
`;

export const OrderWrapper = styled.div`
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`;
export const OrderWrapperProtocoll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrderTitle = styled.div`
  font-size: 19px;
  font-weight: 500;
`;

export const OrderDate = styled.div`
  text-align: center;
  p {
    font-size: 14px;
  }
  div {
    font-weight: 500;
  }
`;

export const OrderBottom = styled.div`
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const OrderSlide = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.grayLight};
`;

export const OrderDescription = styled.div`
  color: ${Color.gray};
  font-size: 14px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
`;

export const StatusMessage = styled.div`
  padding: 36px 24px;
  background: ${Color.grayLight};
  border-radius: 4px;
  text-align: center;
  line-height: 1.2;
`;

export const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  div {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const StatusOpt = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Bar = styled.div`
  height: 8px;
  width: 333px;

  margin-top: 50px;
  margin-bottom: 17px;

  background: #c7c9c7;
  border-radius: 9.76px;
`;

export const WrapperProtocoll = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  padding: 50px 60px;
  background-color: white;
`;

export const OrderTitleProtocoll = styled.div`
  font-size: 19px;
  font-weight: 500;

  color: ${Color.gray};
`;

export const OrderDateProtocoll = styled.div`
  text-align: center;
  p {
    font-size: 14px;
  }
  div {
    font-weight: 500;
  }
`;

export const StatusWrapperProtocoll = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
`;

export const StatusMessageProtocoll = styled.div`
  padding: 36px 24px;
  background: ${Color.grayLight};
  border-radius: 4px;
  text-align: center;
  line-height: 1.2;
`;

export const StatusInfoProtocoll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;

  margin-top: 60px;
  div {
    font-size: 16px;
    font-weight: 500;
  }
`;
