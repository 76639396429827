import { Dispatch } from 'redux';
import {
  getSocialTariffRenovationService,
  getSocialTariffService,
  postSocialTariffCreateService,
} from '~/services/socialTariff';
import { IState } from '../types';
import {
  RepositoriesTypesSocialTariff,
  IRequestPostCreate,
  IResponsePostCreate,
  IResponseGetSocialTariff,
  IResponseGetRenovationSocialTariff,
} from './types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const getSocialTariffRequest = () => async (dispatch: Dispatch, getState: any) => {
  try {
    dispatch({
      type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_REQUEST,
    });

    const { enrollmentsAuth } = getState() as IState;

    const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

    const response = (await getSocialTariffService(
      registration,
    )) as IResponseGetSocialTariff;

    return dispatch({
      type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

const postSocialTariffAttachmentsRequest =
  (data: IRequestPostCreate, isCuiaba?: boolean) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_REQUEST,
      });

      const responseCreate = (await postSocialTariffCreateService(
        data,
      )) as IResponsePostCreate;

      if (isCuiaba) {
        const { enrollmentsAuth } = getState() as IState;

        const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

        const response = (await getSocialTariffService(
          registration,
        )) as IResponseGetSocialTariff;

        dispatch({
          type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_SUCCESS,
          payload: {
            data: response,
          },
        });

        Navigation.navigate(RouteName.SOCIALTARIFFREQUEST, {
          ...response,
        });

        return dispatch({
          type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS,
        });
      }

      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS,
        payload: {
          data: responseCreate,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseSocialTariffAttachmentsRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_CLOSE,
    });
  };

const getRenovationSocialTariffRequest =
  (enrollment: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_REQUEST,
      });

      const response = (await getSocialTariffRenovationService(
        enrollment,
      )) as IResponseGetRenovationSocialTariff;

      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS,
        payload: {
          needRenovation: response.needRenovation,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS,
        payload: {
          needRenovation: false,
        },
      });
    }
  };

const viewedBannerSocialTariffRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_VIEWED_BANNER_METHOD_SUCCESS,
  });
};

export {
  getSocialTariffRequest,
  postSocialTariffAttachmentsRequest,
  postErrorCloseSocialTariffAttachmentsRequest,
  getRenovationSocialTariffRequest,
  viewedBannerSocialTariffRequest,
};
