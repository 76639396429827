import React, { FC } from 'react';

const FilterSVG: FC = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 177">
        <g id="Group">
          <path
            id="Vector"
            d="M14 13.4436H4.66667M0 2.55469H3.11111H0ZM14 2.55469H6.22222H14ZM0 7.99913H9.33333H0ZM14 7.99913H12.4444H14ZM0 13.4436H1.55556H0Z"
            stroke="#999999"
          />
          <path
            id="Vector_2"
            d="M4.66884 4.11111C5.52795 4.11111 6.22439 3.41467 6.22439 2.55556C6.22439 1.69645 5.52795 1 4.66884 1C3.80973 1 3.11328 1.69645 3.11328 2.55556C3.11328 3.41467 3.80973 4.11111 4.66884 4.11111Z"
            stroke="#999999"
          />
          <path
            id="Vector_3"
            d="M10.8895 9.55642C11.7486 9.55642 12.4451 8.85998 12.4451 8.00087C12.4451 7.14176 11.7486 6.44531 10.8895 6.44531C10.0304 6.44531 9.33398 7.14176 9.33398 8.00087C9.33398 8.85998 10.0304 9.55642 10.8895 9.55642Z"
            stroke="#999999"
          />
          <path
            id="Vector_4"
            d="M3.11024 15.0017C3.96935 15.0017 4.6658 14.3053 4.6658 13.4462C4.6658 12.5871 3.96935 11.8906 3.11024 11.8906C2.25113 11.8906 1.55469 12.5871 1.55469 13.4462C1.55469 14.3053 2.25113 15.0017 3.11024 15.0017Z"
            stroke="#999999"
          />
        </g>
      </g>
    </svg>
  );
};

export default FilterSVG;
