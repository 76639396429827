import React, { Dispatch, FC, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
import { ButtonPaymentMethod, ContainerPaymentMethods } from './styles';
import { CreditCardIcon } from '~/views/EasyDuplicate/Icons';
import { Breakpoints, Color } from '~/styles';
import PixSVG from '~/assets/Icons/pix';
import BarCodeSVG from '~/assets/Icons/BarCode';
import { useMediaQuery } from 'react-responsive';

interface IDescriptionPaymentProps {
  type: 'credit-card' | 'pix' | 'billet';
  setType: Dispatch<SetStateAction<'credit-card' | 'pix' | 'billet'>>;
}

export const PaymentMethods: FC<IDescriptionPaymentProps> = ({ type, setType }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  return (
    <ContainerPaymentMethods>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Cartão',
          });
          setType('credit-card');
        }}
        active={type === 'credit-card'}
      >
        <CreditCardIcon color={Color.blueIntense} />
        Cartão de {isMobile && <br />} crédito
      </ButtonPaymentMethod>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Pix',
          });
          setType('pix');
        }}
        active={type === 'pix'}
      >
        <PixSVG v3 />
        PIX
      </ButtonPaymentMethod>
      <ButtonPaymentMethod
        type="button"
        onClick={() => {
          ReactGA.event({
            category: 'Pagamento de fatura',
            action: '[AL][Web][Pagamento de Fatura]Escolher Boleto',
          });
          setType('billet');
        }}
        active={type === 'billet'}
      >
        <BarCodeSVG width={38} heigth={30} />
        Boleto
      </ButtonPaymentMethod>
    </ContainerPaymentMethods>
  );
};
