import styled from 'styled-components';
import { Color } from '~/styles';

export const CopyButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: none;
  font-size: 19px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  transition: ease opacity 160ms;
`;

export const SuccessStyle = styled.p`
  color: ${Color.blueSeconde} !important;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 122.5%;
`;
