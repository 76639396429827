import styled from 'styled-components';
import { Color } from '~/styles';

export const OrderStatus = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  color: ${(props) => props.color}};
`;

export const OrderStatusBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

export const MiniBar = styled.div<{ bg: string }>`
  height: 8px;
  background: ${(props) => props.bg || Color.gray};
  border-radius: 8px;
`;

export const OrderTitle = styled.div`
  font-size: 19px;
  font-weight: 500;
`;
