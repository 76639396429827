/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { Stepper } from './Stepper';

import {
  Border,
  BoxContent,
  Container,
  ContainerBorder,
  ContainerBreadCrumb,
  ContainerButtons,
  Content,
  Header,
  Main,
} from './styles';
import { BannerApp } from '~/components/BannerApp';
import { BreadCrumb } from '~/components/BreadCrumb';
import { RouteName } from '~/routes/Routes.name';
import ReportLackWaterStepOne from './ReportLackWaterStepOne';
import ReportLackWaterStepTwo, {
  typesReportLackWaterStepTwo,
} from './ReportLackWaterStepTwo';
import ReportLackWaterStepThree from './ReportLackWaterStepThree';
import ReportLackWaterStepFour from './ReportLackWaterStepFour';
import ReportLackWaterSuccess from './ReportLackWaterSuccess';
import {
  getOrderServicesAddressLackWaterRequest,
  getOrderServicesDownloadLackWaterRequest,
  postErrorCloseServiceCreateLackWaterRequest,
  postOrderCreateLackWaterRequest,
} from '~/store/modules/orderServices/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import moment from 'moment';
import LoadingComponent from '~/components/Loading';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import {
  IOrderServiceInput,
  IResponseInputsLackWater,
} from '~/store/modules/orderServices/types';
import ModalTerm from './ModalTerm';
import { inputsNameFormEnum } from './inputsEnum';

const steps = [
  {
    id: 0,
    label: 'Local',
  },
  {
    id: 1,
    label: 'Triagem',
  },
  {
    id: 2,
    label: 'Detalhes',
  },
  {
    id: 3,
    label: 'Confirmação',
  },
];

export interface Data {
  local: number;
  screening: number;
  [key: string]: any;
  errorMessages?: { [key: string]: string };
}

export const hasTankerTruckInputs = (
  input: IResponseInputsLackWater | IOrderServiceInput,
) =>
  input?.nameForm === inputsNameFormEnum?.cuiaba ||
  input?.nameForm === inputsNameFormEnum?.rio_de_janeiro;

const ReportLackWater: FC = () => {
  const dispatch = useDispatch();

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck?.enrollment,
  );
  const code = useSelector(
    (item: IState) => item.enrollmentsAuth?.registrationCheck?.code,
  );
  const dataAddressLackWater = useSelector(
    (item: IState) => item.orderServices.dataAddressLackWater,
  );
  const loadingCreateLackWater = useSelector(
    (item: IState) => item.orderServices.loadingCreateLackWater,
  );
  const loadingDownloadLackWater = useSelector(
    (item: IState) => item.orderServices.loadingDownloadLackWater,
  );
  const success = useSelector(
    (item: IState) => item.orderServices.successCreateLackWater,
  );
  const error = useSelector(
    (item: IState) => item.orderServices.errorCreateLackWater,
  );
  const caseNumber = useSelector(
    (item: IState) => item.orderServices.dataCreateLackWater?.caseNumber,
  );
  const dataInputs = useSelector((state: IState) => state.orderServices.dataInputs);

  const [activeStep, setActiveStep] = useState<number>(2);
  const [data, setData] = useState<Data>({
    local: 1,
    screening: 2,
    time_without_water: '',
    neighbors_have_supply: '',
    problem_description: '',
  });
  const [openTerm, setOpenTerm] = useState<boolean>(false);

  const supply_tanker_truck = data?.supply_tanker_truck?.toUpperCase() || '';
  const inputsTankerTruck =
    dataInputs?.filter((inputs) => inputs.nameForm === inputsNameFormEnum?.cuiaba) ||
    [];
  const inputsTankerTruckRJ =
    dataInputs?.filter(
      (inputs) => inputs.nameForm === inputsNameFormEnum?.rio_de_janeiro,
    ) || [];

  const validateFields = () => {
    let isValid = true;
    let newErrorMessages: any = {};

    dataInputs?.forEach((input) => {
      if (input?.required) {
        if (hasTankerTruckInputs(input) && supply_tanker_truck === 'NÃO') {
          return;
        }

        if (!data[input.name]) {
          isValid = false;
          newErrorMessages[input.name] = 'Preenchimento obrigatório';
        }
      }
    });

    if (
      inputsTankerTruckRJ?.length > 0 &&
      supply_tanker_truck === 'SIM' &&
      !data?.accept_term
    ) {
      isValid = false;
      newErrorMessages = {
        ...newErrorMessages,
        term: true,
      };
    }

    setData((prevData) => ({
      ...prevData,
      errorMessages: newErrorMessages,
    }));

    return isValid;
  };

  const valuesFields = () => {
    let dataRequest: { [key: string]: string } = {};

    dataInputs?.forEach((input) => {
      if (supply_tanker_truck === 'SIM' || !hasTankerTruckInputs(input)) {
        dataRequest = { ...dataRequest, [input?.name]: data[input?.name] };
      } else {
        dataRequest = { ...dataRequest, supply_tanker_truck };
      }
    });

    if (
      inputsTankerTruckRJ?.length > 0 &&
      supply_tanker_truck === 'SIM' &&
      data?.accept_term
    ) {
      dataRequest = { ...dataRequest, accept_term: data?.accept_term };
    }

    return dataRequest;
  };

  const inputsProtocol = (): IOrderServiceInput[] => {
    let inputs: IOrderServiceInput[] = [];

    dataInputs?.forEach((input) => {
      inputs.push({
        nameForm: input?.nameForm,
        label: input?.label,
        type: input?.type,
        name: input?.name,
        value: data[input?.name],
      });
    });

    return inputs;
  };

  const updateDataState = (inputs: IResponseInputsLackWater[]) => {
    let newData = { ...data };
    let newErrorMessages: any = {};

    inputs?.forEach((input: IResponseInputsLackWater) => {
      newData[input.name] = '';
      newErrorMessages[input.name] = '';
    });

    setData({ ...newData, newErrorMessages });
  };

  useEffect(() => {
    if (dataInputs?.length) {
      updateDataState(dataInputs);
    }
  }, [dataInputs]);

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return {
          title: steps[0].label,
          content: <ReportLackWaterStepOne data={data} setData={setData} />,
          backAction: () => Navigation.goBack(),
          nextAction: () => setActiveStep(activeStep + 1),
          disabled: false,
        };
      case 1:
        return {
          title: steps[1].label,
          content: <ReportLackWaterStepTwo data={data} setData={setData} />,
          backAction: () => setActiveStep(activeStep - 1),
          nextAction: () => setActiveStep(activeStep + 1),
          disabled: false,
        };
      case 2:
        return {
          title: steps[2].label,
          content: (
            <ReportLackWaterStepThree
              data={data}
              setData={setData}
              setOpenTerm={setOpenTerm}
              inputsTankerTruck={inputsTankerTruck}
              inputsTankerTruckRJ={inputsTankerTruckRJ}
            />
          ),
          backAction: () => setActiveStep(activeStep - 1),
          nextAction: () => {
            if (!validateFields()) {
              return;
            }

            ReactGA.event({
              category: 'Falta d’água',
              action: '[WEB][Falta D’água][AL][Detalhes][confirmação]',
            });
            setActiveStep(activeStep + 1);
          },
          disabled: false,
        };
      case 3:
        return {
          title: steps[3].label,
          content: (
            <ReportLackWaterStepFour
              {...data}
              typeProblem={
                typesReportLackWaterStepTwo[Number(data?.screening - 1)]?.label
              }
              requestDate={moment(new Date()).format('DD/MM/YYYY')}
              enrollment={code}
            />
          ),
          backAction: () => setActiveStep(activeStep - 1),
          nextAction: () => {
            ReactGA.event({
              category: 'Falta d’água',
              action: '[WEB][Falta D’água][AL][Confirmação][Enviar solicitação]',
            });

            dispatch(
              postOrderCreateLackWaterRequest({
                ...valuesFields(),
                enrollment: enrollment || '',
              }),
            );
          },
          disabled: false,
        };

      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(getOrderServicesAddressLackWaterRequest());
  }, []);

  return (
    <Main>
      {(loadingCreateLackWater || loadingDownloadLackWater) && (
        <LoadingComponent labelWait="Carregando..." />
      )}
      <Modal open={!!error?.state} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(postErrorCloseServiceCreateLackWaterRequest())}
        />
      </Modal>
      <Modal open={openTerm} type="info" v3 hidden maxWidth={670}>
        <ModalTerm
          onClose={() => setOpenTerm(false)}
          data={data}
          setData={setData}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Reportar falta d’água',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <Header />
        <BoxContent>
          <Content>
            <h1>Reportar falta d’água</h1>
            {!success && <Stepper steps={steps} activeStep={activeStep} />}
          </Content>
          <ContainerBorder>
            <Border />
            <h2>{success ? 'Sucesso!' : getStep()?.title}</h2>
            <Border />
          </ContainerBorder>
          <Content>
            {success ? <ReportLackWaterSuccess /> : getStep()?.content}
          </Content>
        </BoxContent>
        <Margin height={56} />
        {success ? (
          <Content>
            <ContainerButtons>
              <ButtonText
                text="Baixar protocolo"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.green}
                onClick={() => {
                  ReactGA.event({
                    category: 'Falta d’água',
                    action: '[WEB][Falta D’água][AL][Baixar protocolo]',
                  });

                  dispatch(
                    getOrderServicesDownloadLackWaterRequest(caseNumber || ''),
                  );
                }}
              />
              <ButtonText
                text="Ver solicitação"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.blueSeconde}
                nextIcon
                onClick={() => {
                  ReactGA.event({
                    category: 'Falta d’água',
                    action: '[WEB][Falta D’água][AL][Ver solicitação]"',
                  });

                  Navigation.navigate(RouteName.SUPPORTCENTERPROTOCOL, {
                    caseId: '',
                    createdDate: new Date(),
                    closedDate: null,
                    protocol: caseNumber,
                    serviceName:
                      typesReportLackWaterStepTwo[Number(data?.screening - 1)]
                        ?.label,
                    status: {
                      label: 'Em Aberto',
                      description:
                        'Por favor, aguarde! Estamos avaliando sua solicitação.',
                      color: Color.blueSeconde,
                    },
                    info: {
                      address: {
                        full_address: dataAddressLackWater?.complete_address,
                        complement: dataAddressLackWater?.complement,
                      },
                      inputs: inputsProtocol(),
                    },
                  });
                }}
              />
            </ContainerButtons>
          </Content>
        ) : (
          <Content>
            <ContainerButtons>
              <ButtonText
                text="Voltar"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.green}
                backIcon
                onClick={() => {
                  getStep()?.backAction();
                }}
              />
              <ButtonText
                text={activeStep === 3 ? 'Enviar solicitação' : 'Continuar'}
                typeBtn="content"
                Color={Color.white}
                backgroundColor={
                  getStep()?.disabled ? Color.grayLightSeconde : Color.blueSeconde
                }
                nextIcon
                onClick={async () => {
                  if (getStep()?.disabled) return;

                  getStep()?.nextAction();
                }}
              />
            </ContainerButtons>
          </Content>
        )}
        <Margin height={64} />
        <BannerApp />
      </Container>
    </Main>
  );
};

export default ReportLackWater;
