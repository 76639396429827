export type IEnumResponseCode = keyof typeof EnumResponseCode;

export const EnumResponseCode = {
  GENERIC: 'B999',
  ALERT_NOT_EXISTS: 'B098',
  USER_NOT_FOUND_IN_PARTNES: 'B099',
  USER_NOT_HAVE_ACCESS_REGISTRATION: 'B072',
  USER_NOT_REGISTERED: 'B097',
  NOTIFICATION_NOT_EXISTS: 'B071',
  CARD_NOT_EXISTS: 'B087',
  FAILURE_CIELO: 'B088',
  MISSING_FILES: 'B048',
  VALIDATION_FAILED: 'B993',
  DEVICE_ALREADY_EXISTS: 'B080',
  DEVICE_NOT_EXISTS: 'B078',
  NOT_FOUND_FILE: 'B082',
  NOT_FOUND_USER: 'B067',
  USER_NOT_EXISTS: 'B990',
  PHONE_NOT_EXISTS: 'B019',
  UPDATE_ALREADY_EXISTS: 'B016',
  UNAUTHORIZED_TRANSACTION: 'B060',
  UNAUTHORIZED: 'B013',
  UPDATE_FAILED: 'B056',
  CASE_ALREADY_OPENED: 'B040',
  BAD_REQUEST_INVALID_OPERATION: 'B054',
  ENROLLMENT_OR_OPERATION_INVALID: 'B997',
  VALIDATE: 'B052',
  CPAE_INVALID: 'B050',
  CASE_NOT_OPEN: 'B041',
  FCP_ALREADY_REQUESTED: 'B049',
  SUBSCRIBE_EXISTS: 'B036',
  SIGNATURE_FAILED: 'B034',
  UNSUBSCRIBE_FAILURE: 'B033',
  NOT_FOUND_INVOICE: 'B069',
  NOT_FOUND_PLAN: 'B051',
  NOT_FOUND_SLIP: 'B032',
  NOT_FOUND_SOCIAL_TARIFF: 'B039',
  SUBSCRIBE_NOT_EXISTS: 'B038',
  REGISTRATION_NOT_FOUND: 'B037',
  NOT_FOUND_ORDER_SERVICE: 'B068',
  USER_NOT_EXISTS_IN_PARTNERS: 'B066',
  CARD_NOT_FOUND: 'B998',
  ROUTE_NOT_FOUND: 'B012',
  ENROLLMENT_NOT_FOUND: 'B064',
  PAYMENT_NOT_FOUND: 'B063',
  CASES_NOT_FOUND: 'B081',
  BAD_REQUEST_INVOICE_PAID: 'B061',
  NOT_FOUND_CATEGORY: 'B027',
  DIRECT_DEBIT_NOT_FOUND: 'B009',
  CASE_NOT_FOUND: 'B995',
  BAD_REQUEST_TOTAL_MANDATORY_VALUE: 'B090',
  BAD_REQUEST_FAILURE_SALES: 'B086',
  BAD_REQUEST_TOGGLE: 'B030',
  USER_ALREADY_EXISTS: 'B028',
  NOT_ALLOWED_CEP: 'B085',
  INVALID_CEP: 'B084',
  LOCATION_INCONSISTENT_CEP: 'B031',
  OC_ALREADY_REQUESTED: 'B083',
  OC_NOT_ALLOWED: 'B996',
  BAD_REQUEST: 'B000',
  RECENTLY_USED_PASSWORD: 'B994',
  EXCESS_ATTEMPTS: 'B995',
  NOT_FOUND: 'B015',
  BAD_REQUEST_TOKEN: 'B014',
  BAD_REQUEST_EMAIL: 'B011',
  BAD_GATEWAY_OPERATION: 'B010',
  VALIDATIONS_FAILED_CARD: 'B007',
  BAD_REQUEST_FAILURTE_SMS: 'B923',
  INCORRECT_PHONE: 'B924',
  INCORRECT_EMAIL: 'B922',
  EMAIL_NOT_EXISTS: 'B018',
  SHIPPING_CHANNEL_NOT_EXISTS: 'B020',
  BAD_GATEWAY: 'B100',
  BAD_GATEWAY_CASE_NOT_FOUND: 'B101',
  OS_ALREADY_OPENED: 'B102',
  BAD_GATEWAY_SANSYS: 'B104',
  ENROLLMENT_NOT_FIND: 'B112',
  OWNERSHIP_ALREADY_OPENED: 'B105',
  PROCESSING_HALTED: 'B106',
  PROCESSING_HALTED_CPF: 'B107',
  MAINTENANCE: 'B108',
  BAD_GATEWAY_SANSYS_STATUS: 'B109',
  BAD_GATEWAY_SANSYS_SERVICE: 'B110',
  AWS_UPLOAD_ERROR: 'B111',
  INVALID_TOKEN: 'B002',
  TOKEN_MISSING: 'B003',
  UNATHORIZED_CNP_PASS: 'B095',
  UNATHORIZED_USER: 'B992',
  UNATHORIZED_PASSWORD: 'B992',
  EXPIRED_PASSWORD: 'B095',
  INVALID_REQUEST: 'B004',
  INVALID_REQUEST_ENROLLMENT: 'B008',
  INVALID_REQUEST_INSTALLMENTS: 'B062',
  INCOMPLETE_CUSTOMER_DATA_IN_MANAGER: 'B058',
  APPLICATION_VERSION_IS_BELOW_MINIUM: 'B079',
  VALIDATIONS_FAILED: 'B094',
  VALIDATIONS_FAILED_EXPIRATION_DATE: 'B089',
  CARD_NOT_SUPPORTED: 'B006',
  FORMAT_INVALID: 'B005',
  UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR: 'B989',
  VALIDATIONS_FAILED_FCP: 'B047',
  FCP_NOT_ALLOWED: 'B046',
  OC_NOT_REQUESTED: 'B045',
  ADMIN_ALREADY_EXISTS: 'B092',
  USER_NOT_FOUND: 'B093',
  BAD_REQUEST_INCOMPLETE_FIELDS: 'B075',
  USER_NOT_UPDATE: 'B065',
  USER_NOT_UPDATE_DUE_INFORMATION: 'B057',
  BAD_REQUEST_ENROLLMENT_NOT_IN_USER: 'B001',
  BAD_REQUEST_CASE_EXISTS_IN_ENROLLMENT: 'B070',
  BAD_REQUEST_ENROLLMENT_NOT_IN_SALES: 'B076',
  BAD_REQUEST_ENROLLMENT_NOT_IN_OPERATION: 'B044',
  BAD_REQUEST_ENROLLMENT_ALREADY_CLIENT: 'B043',
  INCORRECT_PLATAFORM: 'B042',
  BAD_REQUEST_GROUP_ENROLLMENT_NOT_EXISTS: 'B077',
  BAD_REQUEST_ALERT_ALREADY_EXISTS: 'B091',
  ALREADY_EXISTS: 'B073',
  TOKENS_RECAPTCHA_MISSING: 'B021',
  APPLICATION_NOT_PERMITTED: 'B074',
  TOKEN_V3_RECAPTCHA_MISSING: 'B022',
  TOKEN_V2_RECAPTCHA_MISSING: 'B022',
  TOKEN_V3_RECAPTCHA_INVALID: 'B023',
  TOKEN_V2_RECAPTCHA_INVALID: 'B024',
  RECAPTCHA_GENERIC: 'B025',
};
