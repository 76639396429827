import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/components/ButtonV3';
import LoadingComponent from '~/components/Loading';
import { Margin } from '~/components/Margin';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import {
  getDataDownloadFileCustomerObligationRequest,
  patchCustomerObligationStepRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { IState } from '~/store/modules/types';
import { Color } from '~/styles';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Main } from './styles';

const Status: FC = () => {
  const dispatch = useDispatch();
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loadingDownloadFile = useSelector(
    (item: IState) => item.customerObligation.loadingDownloadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );

  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    if (caseOC?.realStep.number === 4) {
      return dispatch(
        patchCustomerObligationStepRequest({
          case: idCreate || caseOC?.id || '',
          step: 'FIVE',
        }),
      );
    }
    if (caseOC?.realStep.number === 5) {
      return dispatch(
        patchStatusStepCustomerObligationRequest({
          name: '',
          number: 5,
          substep: 'A',
          status: caseOC?.realStep.status,
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 5,
        substep: 'A',
        status: stepStatusEnum.SUCCESS,
      }),
    );
  };

  return (
    <Main>
      {loadingDownloadFile && <LoadingComponent labelWait="Carregando..." />}
      {caseOC?.step.status === stepStatusEnum.WAIT && (
        <StatusInfo status="wait">
          Os documentos foram enviados para análise. Você pode acompanhar o andamento
          na tela inicial da <b>Viabilidade de Empreendimento</b>.
        </StatusInfo>
      )}
      {caseOC?.step.status === stepStatusEnum.SUCCESS && (
        <StatusInfo
          status="success"
          onClick={() => {
            setDisabled(false);
            dispatch(
              getDataDownloadFileCustomerObligationRequest({
                idFile: caseOC?.approvedDocuments.DARM || '',
              }),
            );
          }}
          labelButton="Darm PMRJ"
        >
          Os documentos foram analisados. Faça o download da documentação antes de
          seguir com sua solicitação.
        </StatusInfo>
      )}
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 3,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingAttachFiles}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Status;
