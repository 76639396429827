export default function convertFileToBase64(file: File, callback: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String = String(reader.result)
      .replace('data:', '')
      .replace(/^.+,/, '');

    callback(base64String);
  };
  reader.onerror = (error) => {
    console.log('Error: ', error);
  };
}
