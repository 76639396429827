import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  display: flex;

  flex: 1;

  flex-direction: row;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
`;

export const SenFiles = styled.div`
  display: flex;
  width: 40%;

  div {
    max-height: 58px;
    p {
      font-weight: 400;
      color: ${Color.gray};
    }
    div:last-child {
      padding: 0px 11px;
    }
  }
`;

export const Label = styled.div`
  width: 40%;
  margin-left: 24px;
  font-size: 12px;
  color: ${Color.gray};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 122.5%; /* 14.7px */
  letter-spacing: -0.3px;

  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
    font-weight: 500;
  }
`;

export const ContainerFile = styled.a`
  width: 100%;
  height: 58px;
  border: 1px solid #c7c9c7;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;

  svg {
    margin-left: 20px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.06em;
    text-decoration: underline;
    color: ${Color.blueSeconde};

    @media screen and (min-width: ${Breakpoints.laptop.min}px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;
