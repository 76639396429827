/* eslint-disable prefer-const */
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { clearProtocolCustomerObligationRequest } from '~/store/modules/customerObligation/actions';
import Form from './Form';
import Status from './Status';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Revision from './Revision';

interface IProps {
  setActualStep: (e: 1 | 2 | 3 | 4 | 5) => void;
}

const DarmRequest: FC<IProps> = ({ setActualStep }) => {
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  useEffect(
    () => () => {
      dispatch(clearProtocolCustomerObligationRequest());
    },
    [],
  );

  return (
    <>
      {caseOC?.step.status === stepStatusEnum.FORM && (
        <Form setActualStep={setActualStep} />
      )}
      {(caseOC?.step.status === stepStatusEnum.WAIT ||
        caseOC?.step.status === stepStatusEnum.SUCCESS) && (
        <Status setActualStep={setActualStep} />
      )}
      {caseOC?.step?.status === stepStatusEnum.REVIEW && (
        <Revision setActualStep={setActualStep} />
      )}
    </>
  );
};

export default DarmRequest;
