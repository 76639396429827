import React from 'react';
import moment from 'moment';
import { Container, ContainerButtons } from './styles';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
}

const ModalCancel: React.FC<IProps> = ({ onClose, onSubmit }) => {
  const dataEnrollment = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.dataEnrollment,
  );

  return (
    <Container>
      <p>
        Tem certeza que deseja cancelar a recorrência do plano anual?
        <br /> <br />
        Sua assinatura ainda estará vigente até <br />
        {moment(dataEnrollment?.next_recurrency).format('DD/MM/YYYY')}
      </p>
      <ContainerButtons>
        <button type="button" onClick={onClose}>
          Não, voltar
        </button>
        <button type="button" onClick={onSubmit}>
          Sim, cancelar
        </button>
      </ContainerButtons>
    </Container>
  );
};

export { ModalCancel };
