import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsMain {
  backgroundColor?: string;
}

export const Main = styled.div<IPropsMain>`
  width: calc(100% - 50px);
  margin: auto;
  max-width: 307px;
  min-height: 176px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor || Color.greenLight};
  padding: 21px 7px 28px 21px;
  border-radius: 16px;
  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    margin: inherit;
    margin-right: 70px;
    margin-bottom: 31px;
    max-width: 280px;
    min-height: 194px;
    padding: 24px 28px 32px 22px;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TitleEmptyText = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: ${Color.greenDark};
`;

export const EmptyTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.white};
`;

export const DefaultTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.greenLight};
`;

export const EmptyText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.greenDark};
`;

export const CardNumberText = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: ${Color.greenDark};
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CardNameText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: ${Color.greenDark};
`;

export const CardValidityText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: ${Color.white};

  span {
    color: ${Color.greenDark};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
`;
