import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

function VisiblePasswordSVG({ color = Color.black }: IProp) {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9987 0.333496C5.83203 0.333496 2.2737 2.92516 0.832031 6.5835C2.2737 10.2418 5.83203 12.8335 9.9987 12.8335C14.1654 12.8335 17.7237 10.2418 19.1654 6.5835C17.7237 2.92516 14.1654 0.333496 9.9987 0.333496ZM9.9987 11.1668C6.84036 11.1668 4.0237 9.39183 2.6487 6.5835C4.0237 3.77516 6.84036 2.00016 9.9987 2.00016C13.157 2.00016 15.9737 3.77516 17.3487 6.5835C15.9737 9.39183 13.157 11.1668 9.9987 11.1668ZM9.9987 2.8335C7.93203 2.8335 6.2487 4.51683 6.2487 6.5835C6.2487 8.65016 7.93203 10.3335 9.9987 10.3335C12.0654 10.3335 13.7487 8.65016 13.7487 6.5835C13.7487 4.51683 12.0654 2.8335 9.9987 2.8335ZM9.9987 8.66683C8.8487 8.66683 7.91536 7.7335 7.91536 6.5835C7.91536 5.4335 8.8487 4.50016 9.9987 4.50016C11.1487 4.50016 12.082 5.4335 12.082 6.5835C12.082 7.7335 11.1487 8.66683 9.9987 8.66683Z"
        fill={color}
      />
    </svg>
  );
}
export default VisiblePasswordSVG;
