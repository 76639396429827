import React from 'react';

interface IProps {
  width?: string;
  heigth?: string;
}

const SearchSVG = ({ width, heigth }: IProps) => {
  return (
    <svg
      width={width || '27'}
      height={heigth || '27'}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0992 0.339844C9.45706 0.340722 7.83808 0.726822 6.37233 1.46711C4.90658 2.2074 3.63494 3.28123 2.65962 4.6023C1.68431 5.92337 1.03251 7.45482 0.756632 9.07357C0.480751 10.6923 0.588477 12.3532 1.07115 13.9228C1.55382 15.4923 2.39798 16.9267 3.53578 18.1107C4.67358 19.2947 6.07327 20.1953 7.62237 20.7401C9.17147 21.2848 10.8268 21.4585 12.4552 21.2473C14.0837 21.036 15.6398 20.4457 16.9987 19.5237L23.486 26.0058C23.6662 26.1992 23.8835 26.3543 24.1249 26.4619C24.3664 26.5695 24.627 26.6273 24.8913 26.632C25.1556 26.6366 25.4181 26.588 25.6632 26.489C25.9082 26.39 26.1309 26.2427 26.3178 26.0558C26.5047 25.8689 26.652 25.6463 26.751 25.4012C26.85 25.1561 26.8986 24.8936 26.8939 24.6293C26.8893 24.365 26.8314 24.1044 26.7239 23.863C26.6163 23.6215 26.4612 23.4042 26.2678 23.224L19.7857 16.7367C20.8581 15.1584 21.48 13.3176 21.5845 11.4123C21.6891 9.50696 21.2723 7.60919 20.379 5.92301C19.4857 4.23684 18.1497 2.82604 16.5147 1.84232C14.8796 0.858594 13.0073 0.339152 11.0992 0.339844V0.339844ZM4.53831 10.8372C4.53831 9.09714 5.22954 7.42837 6.45994 6.19797C7.69033 4.96758 9.35911 4.27635 11.0992 4.27635C12.8392 4.27635 14.508 4.96758 15.7384 6.19797C16.9688 7.42837 17.66 9.09714 17.66 10.8372C17.66 12.5772 16.9688 14.246 15.7384 15.4764C14.508 16.7068 12.8392 17.398 11.0992 17.398C9.35911 17.398 7.69033 16.7068 6.45994 15.4764C5.22954 14.246 4.53831 12.5772 4.53831 10.8372Z"
        fill="#C7C9C7"
      />
    </svg>
  );
};

export default SearchSVG;
