import { Dispatch } from 'redux';
import { RouteName } from '~/routes/Routes.name';
import {
  deleteEnrollmentsCardService,
  getEnrollmentsCardsService,
  patchEnrollmentsCardDefaultService,
  postEnrollmentsCreateCardService,
} from '~/services/cards';
import Navigation from '~/utils/Navigation';
import {
  IRequestCreateCard,
  IResponseGetCards,
  RepositoriesTypesEnrollmentsCards,
} from './types';

const getEnrollmentsCardsRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_REQUEST,
    });

    const response = (await getEnrollmentsCardsService()) as IResponseGetCards;

    return dispatch({
      type: RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

const postEnrollmentsCreateCardRequest =
  (data: IRequestCreateCard) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_REQUEST,
      });

      await postEnrollmentsCreateCardService(data);

      return dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
          },
        },
      });
    }
  };

const postSuccessCloseEnrollmentsCreateCardRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_SUCCESS_CLOSE,
    });
  };

const postErrorCloseEnrollmentsCreateCardRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_FAILURE_CLOSE,
    });
  };

const deleteEnrollmentsCardRequest =
  (cardId: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_REQUEST,
      });

      await await deleteEnrollmentsCardService(cardId);

      dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.WALLET);
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const deleteErrorCloseEnrollmentsCardRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_FAILURE_CLOSE,
  });
};

const patchEnrollmentsCardDefaultRequest =
  (cardId: number, walletDetail?: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_REQUEST,
      });

      await patchEnrollmentsCardDefaultService(cardId);

      if (walletDetail) {
        Navigation.navigate(RouteName.WALLET);
      } else {
        const response = (await getEnrollmentsCardsService()) as IResponseGetCards;

        dispatch({
          type: RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_SUCCESS,
          payload: {
            data: response,
          },
        });
      }

      return dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

export {
  getEnrollmentsCardsRequest,
  postEnrollmentsCreateCardRequest,
  postSuccessCloseEnrollmentsCreateCardRequest,
  postErrorCloseEnrollmentsCreateCardRequest,
  deleteEnrollmentsCardRequest,
  deleteErrorCloseEnrollmentsCardRequest,
  patchEnrollmentsCardDefaultRequest,
};
