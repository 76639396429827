import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 22px 54px 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 118px 20px 19px 20px;
  }
`;

export const ContainerAutomaticDebit = styled.div`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  }
`;

export const Header = styled.div`
  padding: 24px 0 27px 0;
  background: ${Color.blueSeconde};
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: ${Color.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 24px;
    padding: 33px 0;
    font-weight: 700;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  max-width: 400px;
`;

export const EmptyText = styled.p`
  max-width: 370px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${Color.gray};
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    max-width: 190px;
  }
`;

export const ReasonRequestDeniedText = styled.p`
  width: calc(100% - 60px);
  background: rgba(199, 201, 199, 0.25);
  border: 1px solid #c7c9c7;
  padding: 17px 40px 17px 17px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.red};
`;
