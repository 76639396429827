import styled, { css } from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 570px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    :first-of-type {
      min-height: inherit;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 128px 0 120px 0;
  }
`;

export const MethodDiv = styled.div`
  display: flex;
  gap: 24px;
`;

interface IMethodButton {
  disabled?: boolean;
}

export const MethodButton = styled.button<IMethodButton>`
  width: 212px;
  height: 237px;
  padding: 40px 0 34px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.19);
  border-radius: 29px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000;

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000;
  }

  svg {
    margin-bottom: 13px;
  }

  :disabled {
    opacity: 0.4;
  }

  ${(props) => {
    if (props.disabled) {
      return css`
        color: Color.grayLight;
      `;
    }

    return css`
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    `;
  }}
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
