/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import {
  BoxPropertyDetails,
  ContainerInputs,
  DescriptionTypeImmobile,
} from '../styles';
import formatter from '~/utils/formatter';
import { Residence } from '~/store/modules/outsides/types';
import { IDataHistory, IFormData } from '..';
import { getCepViaCep } from '~/services/cep';

interface ISelectValues {
  value: string;
  label: string;
}

export interface ISelectTypesValues extends ISelectValues {
  description?: string;
}
interface IProps {
  typesSocialTariffSelected: ISelectTypesValues | null;
  setTypesSocialTariffSelected: Dispatch<SetStateAction<ISelectTypesValues | null>>;
  residence: Residence;
  setFormDataResidenceType: (data: string) => void;
  setFormDataResidenceBond: (data: string) => void;
  errors: {
    type?: string;
    bond?: string;
    state?: string;
    city?: string;
    zipCode?: string;
    neighborhood?: string;
    street?: string;
    number?: string;
  };
  setFormDataErrors: Dispatch<SetStateAction<any>>;
  renovation: boolean;
  dataHistory?: IDataHistory;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  formData: IFormData;
  setFormData: Dispatch<SetStateAction<IFormData>>;
  setOpenInfoAddress: Dispatch<SetStateAction<boolean>>;
  valuesStates: Array<any>;
  valuesCities: Array<any>;
  handleGetCitiesAddress: (state: any) => void;
  handleUpdateCitieAddress: (state: any) => void;
  hasTypeSolicitation: boolean;
}

const FormImmobile: FC<IProps> = ({
  typesSocialTariffSelected,
  setTypesSocialTariffSelected,
  residence,
  setFormDataResidenceType,
  setFormDataResidenceBond,
  errors,
  renovation,
  dataHistory,
  handleChange,
  formData,
  setFormData,
  setFormDataErrors,
  setOpenInfoAddress,
  valuesStates,
  valuesCities,
  handleGetCitiesAddress,
  handleUpdateCitieAddress,
  hasTypeSolicitation,
}) => {
  let typesSocialTariff: ISelectTypesValues[] = [
    { value: '', label: 'Selecione' },
    {
      value: 'benefitRenewal',
      label: 'Renovação do benefício',
      description: 'Apenas para casos onde não houve alteração de dados do imóvel.',
    },
    {
      value: 'favelaHousing',
      label: 'Habitação em favela',
      description:
        'Conjunto de habitações populares desprovidas de infraestrutura (rede de esgoto, de energia, de posto de saúde,  etc.).',
    },
    {
      value: 'areaSocialInterest',
      label: 'Área de interesse social',
      description:
        'Lotes irregulares, posse, periferias de favelas e áreas de risco.',
    },
    {
      value: 'housing',
      label: 'Conjunto habitacional',
      description: 'Uso de sistema financeiro de habitação, renda familiar.',
    },
    {
      value: 'publicHousing',
      label: 'Habitação popular',
      description: 'Terrenos cedidos por órgãos públicos.',
    },
  ];
  const valuesVinculoImovel: ISelectValues[] = [
    { value: '', label: 'Selecione' },
    { value: 'Locatário', label: 'Locatário' },
    { value: 'Proprietário', label: 'Proprietário' },
    { value: 'Responsável Financeiro', label: 'Responsável Financeiro' },
  ];

  !renovation && delete typesSocialTariff[1];

  const getCep = async (cep: string) => {
    const dataCep: any = await getCepViaCep(cep);

    if (dataCep?.erro) {
      setFormData({
        ...formData,
        residence: {
          ...formData.residence,
          neighborhood: '',
          complement: '',
          street: '',
        },
      });
    } else {
      setFormData({
        ...formData,
        residence: {
          ...formData?.residence,
          neighborhood: dataCep?.bairro,
          complement: dataCep?.complemento,
          street: dataCep?.logradouro,
        },
      });

      setFormDataErrors((prev: any) => {
        return {
          ...prev,
          residence: {
            ...prev?.residence,
            neighborhood: dataCep?.bairro ? '' : prev?.residence?.neighborhood,
            complement: dataCep?.complemento ? '' : prev?.residence?.complement,
            street: dataCep?.logradouro ? '' : prev?.residence?.street,
          },
        };
      });

      if (
        dataCep &&
        dataCep?.localidade &&
        dataCep?.localidade?.toUpperCase() !== 'RIO DE JANEIRO'
      ) {
        setOpenInfoAddress(true);
      }
    }
  };

  useEffect(() => {
    if (!dataHistory?.address?.zipCode && residence?.zipCode.length > 8)
      getCep(residence?.zipCode);
  }, [residence?.zipCode]);

  const hasResidenceInfos =
    !!residence?.zipCode && !!residence.state && !!residence.city;

  return (
    <BoxPropertyDetails>
      <ContainerInputs>
        <InputSelect
          label="Tipo de tarifa social*"
          name="vinculoImovel"
          values={typesSocialTariff}
          onChange={(ev) => {
            if (ev.target.value) {
              const selectedFind = typesSocialTariff?.find(
                (item) => item?.value === ev?.target?.value,
              ) as ISelectTypesValues;
              setTypesSocialTariffSelected({ ...selectedFind });
              setFormDataResidenceType(selectedFind?.label);
            } else {
              setFormDataResidenceType('');
              setTypesSocialTariffSelected(null);
            }
          }}
          error={!!errors?.type}
          errorText={errors?.type}
          errorPosition="bottom"
        />
        <DescriptionTypeImmobile
          style={{
            width: '100%',
            visibility: !typesSocialTariffSelected?.description
              ? 'hidden'
              : undefined,
          }}
        >
          {typesSocialTariffSelected?.description}
        </DescriptionTypeImmobile>
      </ContainerInputs>
      {typesSocialTariffSelected && (
        <>
          <ContainerInputs>
            <InputSelect
              label="Vínculo com o imóvel*"
              name="vinculoImovel"
              values={valuesVinculoImovel}
              onChange={(ev) => {
                if (ev.target.value) {
                  const selectedFind = valuesVinculoImovel?.find(
                    (item) => item?.value === ev.target.value,
                  ) as ISelectTypesValues;
                  setFormDataResidenceBond(selectedFind?.label);
                } else {
                  setFormDataResidenceBond('');
                }
              }}
              error={!!errors?.bond}
              errorText={errors?.bond}
              errorPosition="bottom"
            />
            <Input
              label="CEP*"
              name="residence.zipCode"
              value={residence?.zipCode}
              onChange={handleChange}
              disabled={!!dataHistory?.address?.zipCode && !hasTypeSolicitation}
              error={!!errors?.zipCode}
              errorText={errors?.zipCode}
              errorPosition="bottom"
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputSelect
              label="Estado*"
              name="residence.state"
              values={valuesStates}
              onChange={(ev) => {
                const selectedFind = valuesStates?.find(
                  (item) => item?.value.toString() === ev.target.value,
                ) as ISelectTypesValues;
                handleGetCitiesAddress(selectedFind);
              }}
              error={!!errors?.state}
              errorText={errors?.state}
              errorPosition="bottom"
            />
            <InputSelect
              label="Cidade*"
              name="residence.city"
              disabled={!residence.state}
              values={valuesCities}
              onChange={(ev) => {
                const selectedFind = valuesCities?.find(
                  (item) => item?.value.toString() === ev.target.value,
                ) as ISelectTypesValues;

                handleUpdateCitieAddress(selectedFind);
              }}
              error={!!errors?.city}
              errorText={errors?.city}
              errorPosition="bottom"
            />
          </ContainerInputs>
          <ContainerInputs>
            <Input
              label="Bairro*"
              name="residence.neighborhood"
              value={formatter.capitalize(residence?.neighborhood || '')}
              onChange={handleChange}
              disabled={
                (!!dataHistory?.address?.neighborhood && !hasTypeSolicitation) ||
                !hasResidenceInfos
              }
              error={!!errors?.neighborhood}
              errorText={errors?.neighborhood}
              errorPosition="bottom"
            />
            <Input
              label="Rua*"
              name="residence.street"
              value={formatter.capitalize(residence?.street) || ''}
              onChange={handleChange}
              disabled={
                (!!dataHistory?.address?.street && !hasTypeSolicitation) ||
                !hasResidenceInfos
              }
              error={!!errors?.street}
              errorText={errors?.street}
              errorPosition="bottom"
            />
          </ContainerInputs>
          <ContainerInputs>
            <Input
              label="Número*"
              name="residence.number"
              value={residence?.number}
              onChange={handleChange}
              disabled={
                (!!dataHistory?.address?.number &&
                  dataHistory?.address?.number.toString() !== 'S/N' &&
                  !hasTypeSolicitation) ||
                !hasResidenceInfos
              }
              error={!!errors?.number}
              errorText={errors?.number}
              errorPosition="bottom"
            />
            <Input
              label="Complemento"
              name="residence.complement"
              value={formatter.capitalize(residence?.complement || '')}
              onChange={handleChange}
              disabled={
                (!!dataHistory?.address?.complement && !hasTypeSolicitation) ||
                !hasResidenceInfos
              }
              errorPosition="bottom"
            />
          </ContainerInputs>
        </>
      )}
    </BoxPropertyDetails>
  );
};

export default FormImmobile;
