import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

export const LabelText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #004b55;
`;

export const AmountText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #004b55;
`;
