import React, { FC } from 'react';
import { IOrderService } from '~/store/modules/orderServices/types';
import { Container } from './styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  orderService: IOrderService;
}

const ContainerOrderService: FC<IProps> = ({ orderService }) => {
  return (
    <Container
      color={orderService.status.color}
      onClick={() =>
        Navigation.navigate(
          orderService?.info
            ? RouteName.SUPPORTCENTERPROTOCOL
            : RouteName.SUPPORTCENTERSERVICEORDER,
          orderService,
        )
      }
    >
      <div className="container-border">
        <span />
      </div>
      <div>
        <p className="status" style={{ textTransform: 'capitalize' }}>
          {orderService.status.label}
        </p>
        <p className="protocol">Protocolo: {orderService?.protocol}</p>
        <p className="serviceName">{orderService?.serviceName}</p>
      </div>
    </Container>
  );
};

export default ContainerOrderService;
