import React from 'react';
import styled from 'styled-components';

import FooterApp from '../Footer';
import HeaderApp from '../Header';

interface IProps {
  children: any;
}

const LayoutApp = ({ children }: IProps) => {
  return (
    <LayoutContainer className="body">
      <HeaderApp />
      <Main>{children}</Main>
      <FooterApp />
    </LayoutContainer>
  );
};

export default LayoutApp;

// styled-components
const LayoutContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
const Main = styled.main`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 1fr;
  position: relative;
`;
