import React, { HTMLAttributes } from 'react';
import { Backdrop } from './styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  open: boolean;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  styleArrow?: React.CSSProperties;
}

export const DialogBalloon: React.FC<Props> = ({
  children,
  open,
  top,
  bottom,
  left,
  right,
  styleArrow,
  ...props
}) => {
  return (
    <Backdrop
      className={open ? 'backdrop-ballon' : 'disabled-ballon'}
      open={open}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      {...props}
    >
      <div className="arrow" style={{ ...styleArrow }} />
      {children}
    </Backdrop>
  );
};
