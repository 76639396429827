import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  Container,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ContainerInvoice from './ContainerOrderService';
import Pagination from './ContainerOrderService/Pagination';
import EmptyMessage from '~/components/EmptyMessage';
import StatusInfo from './StatusInfo';
import {
  getErrorCloseServiceOrdersListRequest,
  getOrderServicesListRequest,
  postErrorCloseServiceOrderAttachmentsRequest,
} from '~/store/modules/orderServices/actions';

const SupportCenter: React.FC = () => {
  const dispatch = useDispatch();

  const data = useSelector((item: IState) => item.orderServices.data);
  const loading = useSelector((item: IState) => item.orderServices.loading);

  useEffect(() => {
    dispatch(getErrorCloseServiceOrdersListRequest());
    dispatch(postErrorCloseServiceOrderAttachmentsRequest());
    dispatch(getOrderServicesListRequest({ page: 1, size: 5 }));
  }, []);

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas solicitações',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Container>
        <Card>
          <h1>Minhas solicitações</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <StatusInfo status="info">
            Para solicitar reparos e vazamentos, entre em contato pelo fale conosco.
          </StatusInfo>
          <Margin height={12} />
          {data && data?.data && data?.data?.length > 0 ? (
            data?.data?.map((orderService: any) => (
              <ContainerInvoice
                key={orderService.caseId}
                orderService={orderService}
              />
            ))
          ) : (
            <>
              <Margin height={68} />
              <EmptyMessage>
                Você não possui <br /> solicitações anteriores!
              </EmptyMessage>
            </>
          )}
          {data && data?.pagination && data?.pagination?.maxPages > 1 && (
            <Pagination />
          )}
        </Card>
      </Container>
    </Main>
  );
};

export default SupportCenter;
