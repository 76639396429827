import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerHistoryConsumer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    flex-direction: row;
    gap: 37px;
  }
`;

export const ContainerConfigureAlert = styled.div`
  width: 240px;
`;

export const HistoryConsumer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const RowChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubtitleHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  position: relative;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.715px;
    min-height: 22px;
    color: ${Color.gray};
  }

  #legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      div {
        width: 10px;
        margin-right: 8px;
      }
    }
  }

  #total {
    align-self: center;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    #legend {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin-right: 10px;
        div {
          width: 10px;
          margin-right: 10px;
        }
      }
    }

    #total {
      align-self: flex-end;
      margin-right: 50px;
      p {
        font-weight: 500;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    justify-content: space-around;

    #legend {
      width: auto;
    }

    #total {
      margin: 0;
    }
  }
`;

export const ContainerEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
`;
