import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Wrapper } from './styles';

import { Button } from '~/components/Button';
import {
  clearModalEnrollmentNotExists,
  getEnrollmentsAuthDestroySessionRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { checkToggle } from '~/utils/toggles';
import { ErrorIconSVG } from '~/assets/Icons';

const ModalErrorLogin: React.FC = () => {
  const dispatch = useDispatch();

  const toggles = useSelector((item: IState) => item.toggles.data);

  const clearModal = async () => {
    dispatch(clearModalEnrollmentNotExists());
    dispatch(getEnrollmentsAuthDestroySessionRequest());
    Navigation.navigate(
      (await checkToggle(toggles, 'LAYOUT_V3_WEB'))
        ? RouteName.LOGIN
        : RouteName.LANDING,
    );
  };

  return (
    <Wrapper>
      <ErrorIconSVG v3 />
      <h1>Ops</h1>
      <p>
        Verificamos que essa matrícula precisa de atualização. Por favor, tente
        novamente e refaça seu login.
      </p>
      <Button
        modalType="error"
        text="Voltar para login"
        type="button"
        onClick={clearModal}
      />
    </Wrapper>
  );
};

export default ModalErrorLogin;
