import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useDispatch, useSelector } from 'react-redux';

import { ContainerButtons, Wrapper } from './styles';

import { Button } from '~/components/Button';
import { Input } from '~/common';
import {
  clearModalDataUpdate,
  postOutsidesRegistrationUpdateRequest,
} from '~/store/modules/outsides/actions';
import { InfoIcon, SuccessIcon } from './Icons';
import formatter from '~/utils/formatter';
import { IState } from '~/store/modules/types';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';

interface IData {
  email: string;
  phone: string;
}

const ModalCopyDataUpdate: FC = () => {
  const isEasyDuplicateDetail =
    history.location.pathname === RouteName.EASYDUPLICATE ||
    history.location.pathname === RouteName.DISCHARGEDEBTS;
  const dispatch = useDispatch();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const fromTypeRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.fromTypeRegistrationUpdate,
  );
  const loading = useSelector(
    (item: IState) => item.outsides.loadingRegistrationUpdate,
  );
  const success = useSelector(
    (item: IState) => item.outsides.successRegistrationUpdate,
  );

  const [data, setData] = useState<IData>({
    email: '',
    phone: '',
  });
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (fromTypeRegistrationUpdate) {
      if (fromTypeRegistrationUpdate === 'pixCode') {
        ReactGA.event({
          category: 'Atualização cadastral',
          action: '[WEB][AC][Copiar][PIX] Atualizar',
        });
      } else {
        ReactGA.event({
          category: 'Atualização cadastral',
          action: '[WEB][AC][Copiar][Boleto] Atualizar',
        });
      }
    } else {
      ReactGA.event({
        category: 'Atualização cadastral',
        action: '[WEB][AC][CP] Atualizar',
      });
    }
  }, []);

  useEffect(() => {
    if (success) {
      if (fromTypeRegistrationUpdate) {
        if (fromTypeRegistrationUpdate === 'download') {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][PIX] Atualizado com Sucesso',
          });
        } else if (fromTypeRegistrationUpdate === 'pixCode') {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][PIX] Atualizado com Sucesso',
          });
        } else {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][Boleto] Atualizado com Sucesso',
          });
        }
      } else {
        ReactGA.event({
          category: 'Atualização cadastral',
          action: '[WEB][AC][CP] Atualizado com Sucesso',
        });
      }
    }
  }, [success]);

  const clearModal = () => dispatch(clearModalDataUpdate());

  const handleChange = (e: any) => {
    if (e.target.name === 'email') {
      if (formatter.rejexEmail(e.target.value)) {
        setErrorEmail(false);
      } else {
        setErrorEmail(true);
      }
      if (!e.target.value) setErrorEmail(false);
    } else {
      e.target.value.length < 15 ? setErrorPhone(true) : setErrorPhone(false);
      if (!e.target.value) setErrorPhone(false);
    }

    setData({
      ...data,
      [e.target.name]:
        e.target.name === 'phone'
          ? formatter.formatarPhone(e.target.value)
          : e.target.value,
    });
  };

  const validationData = () => {
    if (
      (data.email ||
        data.phone ||
        (dataRegistrationUpdate?.email && data.phone && !data.email) ||
        (dataRegistrationUpdate?.phone && data.email && !data.phone)) &&
      !errorEmail &&
      !errorPhone
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    validationData();
  }, [data, errorEmail, errorPhone]);

  return (
    <Wrapper success={success}>
      {success ? (
        <>
          <SuccessIcon />
          <h1>Dados atualizados!</h1>
          <p>
            Sempre que necessário, faça{' '}
            <Link to={RouteName.LOGIN} onClick={clearModal}>
              login
            </Link>{' '}
            para alterar os seus dados de contato novamente.
          </p>
        </>
      ) : (
        <>
          <div>
            {' '}
            <InfoIcon />
          </div>
          <h1>
            {fromTypeRegistrationUpdate === 'download'
              ? 'Baixando arquivo!'
              : isEasyDuplicateDetail
              ? 'Código copiado!'
              : 'Pagamento realizado!'}
          </h1>
          <p>
            {fromTypeRegistrationUpdate === 'download' ? (
              <>
                Essa é uma versão simplificada para o pagamento da sua fatura.
                <br /> <br />
                Para ter acesso à segunda via completa, atualize seus dados e faça
                login!
              </>
            ) : isEasyDuplicateDetail ? (
              <>
                Você poderá efetuar o pagamento da fatura utilizando o seu internet
                banking!
                <br /> <br />
                Mantenha seus dados atualizados para ter uma experiência completa no
                Digi iguá.
              </>
            ) : (
              <>
                Atualize seus dados e tenha uma <br /> experiência completa no Digi
                iguá.
              </>
            )}
          </p>
          <Input
            name="email"
            label="Email"
            placeholder={dataRegistrationUpdate?.email || ''}
            value={data.email}
            onChange={handleChange}
            error={errorEmail}
            errorText={errorEmail ? 'Email inválido' : ''}
          />
          <Input
            name="phone"
            label="Celular"
            placeholder={dataRegistrationUpdate?.phone || ''}
            value={data.phone}
            onChange={handleChange}
            error={errorPhone}
            errorText={errorPhone ? 'Celular inválido' : ''}
            inputMode="numeric"
          />
        </>
      )}
      <ContainerButtons success={success} disabled={disabled}>
        {success ? (
          <Button text="Entendi" type="button" onClick={clearModal} />
        ) : (
          <>
            <Button
              text="Atualizar"
              type="button"
              onClick={() =>
                dispatch(
                  postOutsidesRegistrationUpdateRequest(
                    dataRegistrationUpdate?.cnp || '',
                    {
                      ...data,
                    },
                  ),
                )
              }
              disabled={disabled}
              loading={loading}
            />
            <Button
              text="Sair"
              type="button"
              onClick={() => {
                if (fromTypeRegistrationUpdate) {
                  if (fromTypeRegistrationUpdate === 'download') {
                    ReactGA.event({
                      category: 'Atualização cadastral',
                      action: ' [WEB][AC][Baixar][PIX] Sair',
                    });
                  } else if (fromTypeRegistrationUpdate === 'pixCode') {
                    ReactGA.event({
                      category: 'Atualização cadastral',
                      action: '[WEB][AC][Copiar][PIX] Sair',
                    });
                  } else {
                    ReactGA.event({
                      category: 'Atualização cadastral',
                      action: '[WEB][AC][Copiar][Boleto] Sair',
                    });
                  }
                } else {
                  ReactGA.event({
                    category: 'Atualização cadastral',
                    action: '[WEB][AC][CP] Sair',
                  });
                }

                clearModal();
              }}
            />
          </>
        )}
      </ContainerButtons>
    </Wrapper>
  );
};

export default ModalCopyDataUpdate;
