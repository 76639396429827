import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import { ContainerButtons, Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import {
  deleteAutomaticDebitsRequest,
  deleteErrorCloseAutomaticDebitsRequest,
  deleteSuccessCloseAutomaticDebitsRequest,
  getAutomaticDebitsRequest,
} from '~/store/modules/automaticDebits/actions';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  setOpen: (status: boolean) => void;
}

const ModalDeleteDebtAccount: FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch();

  const successDeleteAccount = useSelector(
    (item: IState) => item.automaticDebits.successDelete,
  );
  const errorDeleteAccount = useSelector(
    (item: IState) => item.automaticDebits.errorDelete,
  );

  const styleButton = {
    height: '68px',
    maxWidth: '240px',
    fontSize: '20px',
  };

  return (
    <Main>
      {successDeleteAccount || errorDeleteAccount ? (
        <>
          <Margin height={39} />
          <p>
            {successDeleteAccount ? (
              'Débito automático cancelado com sucesso!'
            ) : (
              <>
                Não foi possivel processar suas solicitação. <br />
                Por favor tente mais tarde!
              </>
            )}
          </p>
          <Margin height={70} />
        </>
      ) : (
        <>
          <h2>
            {successDeleteAccount ? (
              'Débito automático cancelado com sucesso!'
            ) : (
              <>Deseja mesmo cancelar o débito automático de suas faturas?</>
            )}
          </h2>
          <Margin height={70} />
        </>
      )}
      {successDeleteAccount || errorDeleteAccount ? (
        <ButtonText
          text="Entendi"
          typeBtn="content"
          backgroundColor={Color.blueSeconde}
          Color={Color.white}
          onClick={() => {
            setOpen(false);
            dispatch(deleteSuccessCloseAutomaticDebitsRequest());
            dispatch(deleteErrorCloseAutomaticDebitsRequest());
            if (successDeleteAccount) {
              dispatch(getAutomaticDebitsRequest());
            } else {
              Navigation.navigate(RouteName.HOME);
            }
          }}
          style={{ maxWidth: '340px', height: '68px' }}
        />
      ) : (
        <ContainerButtons>
          <ButtonText
            text="cancelar"
            typeBtn="content"
            backgroundColor={Color.red}
            Color={Color.white}
            onClick={() => setOpen(false)}
            style={styleButton}
          />
          <Margin width={23} />
          <ButtonText
            text="confirmar"
            typeBtn="content"
            backgroundColor={Color.blueSeconde}
            Color={Color.white}
            onClick={() => {
              dispatch(deleteAutomaticDebitsRequest());
              ReactGA.event({
                category: 'Débito em conta',
                action: 'Cancelamento Débito',
              });
            }}
            style={styleButton}
          />
        </ContainerButtons>
      )}
    </Main>
  );
};

export default ModalDeleteDebtAccount;
