import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  flex: 1;

  /*   padding: 118px 460px 100px 460px; */

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 18px 20px 19px 20px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;

  nav {
    max-width: 1010px;
    margin: 0 auto;
  }
`;

export const Main = styled.div`
  flex: 1;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);

  border-radius: 16px 16px 0 0;

  overflow: hidden;

  line-height: 25px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    margin: 0 auto;
  }
`;

export const Button = styled.button`
  background: #ffffff;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  padding: 22px 0;

  border: none;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);

  font-size: 20px;

  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
  cursor: pointer;
`;
