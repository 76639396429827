import React from 'react';

import { ContainerButtons, Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { InfoSVG } from '~/assets/Icons';

interface IProps {
  setOpen: (openStatus: boolean) => void;
}

const ModalInfo: React.FC<IProps> = ({ setOpen }) => {
  return (
    <Main>
      <InfoSVG />
      <h1>Atenção</h1>
      <p>
        Olá cliente! Seu cadastro está incompleto.
        <br />
        <br />
        Para recuperar a sua senha, <span>Fale conosco</span>.
      </p>
      <ContainerButtons>
        <ButtonText
          backgroundColor={Color.white}
          color={Color.blueSeconde}
          typeBtn="content"
          text="Voltar"
          onClick={() => setOpen(false)}
        />
      </ContainerButtons>
    </Main>
  );
};

export default ModalInfo;
