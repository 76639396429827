import { Dispatch } from 'redux';
import {
  IDataEasyInvoicesCredentials,
  RepositoriesTypesOutsidesCredentials,
} from './types';

const saveEasyInvoicesCredentials =
  (data: IDataEasyInvoicesCredentials) => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesOutsidesCredentials.OUTSIDES_EASY_INVOICES_CREDENTIALS,
      payload: {
        data: {
          remember: data?.remember ? data?.remember : false,
          cnp: data?.remember ? data?.cnp : '',
          enrollment: data?.remember ? data?.enrollment : '',
        },
      },
    });
  };

export { saveEasyInvoicesCredentials };
