/* eslint-disable array-callback-return */
import React, { FC } from 'react';

import {
  Main,
  CircleContainer,
  Circle,
  HalfCircle,
  StepContainer,
  StepTitle,
} from './styles';
import { Margin } from '~/components/Margin';
import Steps from '../../steps.json';

interface IStatusLine {
  actualStep: number;
}

const StatusLine: FC<IStatusLine> = ({ actualStep }: IStatusLine) => {
  return (
    <Main>
      {Steps.map(({ label, linePosition, step }, index) => (
        <StepContainer connect={index !== 0}>
          {linePosition === 'bottom' ? (
            <StepTitle active={actualStep === step}>{label}</StepTitle>
          ) : (
            <Margin height={55} />
          )}

          <CircleContainer position={linePosition}>
            <Circle active={actualStep >= step}>{step}</Circle>
            <HalfCircle
              index={Steps.length - index}
              active={actualStep >= step}
              position={linePosition}
            />
          </CircleContainer>

          {linePosition === 'top' ? (
            <StepTitle active={actualStep === step}>{label}</StepTitle>
          ) : (
            <Margin height={55} />
          )}
        </StepContainer>
      ))}
    </Main>
  );
};

export default StatusLine;
