import styled from 'styled-components';
import { Color } from '~/styles';

export const Wrapper = styled.div`
  max-width: calc(570px - 200px);
  padding: 64px 100px;
  background: ${Color.white};
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const CloseIconButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 33px;
  right: 38px;
  cursor: pointer;
  border: 2px solid ${Color.grayLightSeconde};
  border-radius: 50px;
`;

export const ContainerAddress = styled.div`
  width: calc(100% - 14px);
  min-height: 48px;
  padding: 7px;
  background-color: ${Color.Blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextAddress = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${Color.white};

  span {
    font-weight: 500;
    color: ${Color.blueSeconde};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;
  color: ${Color.blueIntense};
  font-weight: 700;
  text-transform: uppercase;
`;

export const ConfigWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  color: ${Color.gray};

  b {
    font-weight: 700;
  }
`;

export const ContainerWarning = styled.div`
  width: calc(100% - 32px);
  min-height: 80px;
  padding: 17px 17px 17px 15px;
  background: ${Color.yellowSeconde};
  border: 2px solid ${Color.yellow};
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const TextWarning = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.035em;
  color: ${Color.blueIntense};

  p {
    font-weight: 300;
  }
`;
