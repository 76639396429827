/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import CreditCardWallet from './CreditCardWallet';
import {
  CardNumberText,
  ContainerBody,
  ContainerButtonAddCard,
  ContainerCards,
  ContainerCreditCardWallet,
  ContainerLimitCards,
  ContainerWallet,
  CreditCardWalletMobile,
  DefaultText,
  Header,
  Main,
  TitleCard,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEnrollmentsCardsRequest,
  patchEnrollmentsCardDefaultRequest,
} from '~/store/modules/enrollmentsCards/actions';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { brandCardIcon } from '../WalletDetailCard';
import formatter from '~/utils/formatter';
import AlertSVG from '~/assets/Icons/alert';

const Wallet: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const cards = useSelector((state: IState) => state.enrollmentsCards.data);
  const loading = useSelector((state: IState) => state.enrollmentsCards.loading);
  const loadingPatchCard = useSelector(
    (state: IState) => state.enrollmentsCards.loadingPatchCard,
  );

  useEffect(() => {
    dispatch(getEnrollmentsCardsRequest());
  }, []);

  useEffect(() => {
    if (cards?.length === 1) {
      if (!cards[0].is_default) {
        dispatch(patchEnrollmentsCardDefaultRequest(Number(cards[0].id)));
      }
    }
  }, [cards]);

  return (
    <Main>
      {(loading || loadingPatchCard) && (
        <LoadingComponent labelWait="Carregando..." />
      )}
      <ContainerWallet>
        <Header>Carteira</Header>
        <ContainerBody>
          {!isMobile && (
            <>
              <TitleCard>Formas de pagamento</TitleCard>
              <Margin height={30} />
            </>
          )}
          <ContainerCards>
            {!loading && !loadingPatchCard && cards && cards?.length > 0 ? (
              cards.map((card, index) => (
                <>
                  {isMobile ? (
                    card.is_default && (
                      <CreditCardWallet
                        key={card.id}
                        id={card.id}
                        brand={card.brand}
                        cardNumber={card.card_number}
                        holder={card.holder_name}
                        type={card.type}
                        isPrincipal={card.is_default}
                        validity={card.expiration_date}
                        backgroundColor={
                          index % 2 === 0 ? Color.green : Color.blueSeconde
                        }
                      />
                    )
                  ) : (
                    <CreditCardWallet
                      key={card.id}
                      id={card.id}
                      brand={card.brand}
                      cardNumber={card.card_number}
                      holder={card.holder_name}
                      type={card.type}
                      isPrincipal={card.is_default}
                      validity={card.expiration_date}
                      backgroundColor={
                        index % 2 === 0 ? Color.green : Color.blueSeconde
                      }
                    />
                  )}
                </>
              ))
            ) : (
              <CreditCardWallet emptyCard />
            )}
            <ContainerCreditCardWallet>
              {!loading &&
                !loadingPatchCard &&
                isMobile &&
                cards &&
                cards?.length > 0 &&
                cards.map((card) => (
                  <CreditCardWalletMobile
                    key={card.id}
                    onClick={() =>
                      Navigation.navigate(RouteName.WALLETDETAILCARD, {
                        id: card.id,
                        brand: card.brand,
                        holder: card.holder_name,
                        cardNumber: card.card_number,
                        validity: card.expiration_date,
                        type: card.type,
                        isPrincipal: card.is_default,
                      })
                    }
                  >
                    {brandCardIcon[card.brand]}
                    <Margin width={12} />
                    <CardNumberText>{`**** ${formatter.formatarNumber(
                      card.card_number,
                    )}`}</CardNumberText>
                    {card.is_default && <DefaultText>Principal</DefaultText>}
                  </CreditCardWalletMobile>
                ))}
            </ContainerCreditCardWallet>
          </ContainerCards>
          <Margin height={isMobile ? 30 : 82} />
          {cards?.length === 10 && (
            <ContainerLimitCards>
              <AlertSVG color={Color.red} width={18} height={16} />
              <Margin width={12} />
              <p>
                Limite de 10 cartões atingido, para adicionar uma forma de pagamento
                clique para excluir um dos cartões cadastrados
              </p>
            </ContainerLimitCards>
          )}
          <ContainerButtonAddCard>
            <ButtonText
              typeBtn="content"
              Color={cards?.length === 10 ? Color.gray : Color.blueIntense}
              backgroundColor="transparent"
              style={{ display: 'flex', maxWidth: 'fit-content' }}
              onClick={() => {
                if (cards?.length === 10) return;
                Navigation.navigate(RouteName.WALLETADDCARD);
              }}
            >
              + <Margin width={30} /> Adicionar forma de pagamento
            </ButtonText>
          </ContainerButtonAddCard>
        </ContainerBody>
      </ContainerWallet>
    </Main>
  );
};

export default Wallet;
