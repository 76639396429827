import React, { FC, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  AlertFraudBox,
  ContainerBox,
  ContainerInvoicePixCode,
  LabelBox,
  TextBox,
} from './styles';
import { CopySuccessIcon } from '~/views/EasyDuplicate/Icons';
import CopyIcon from '~/assets/Icons/Copy';
import { Margin } from '~/components/Margin';
import clipboardCopy from 'clipboard-copy';
import { ButtonCodeInvoice } from '../styles';
import AlertPixFraud from '~/components/AlertPixFraud';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

interface IPixCodeProps {
  qr_code_key: string;
}

export const PixCodeCopy: FC<IPixCodeProps> = ({ qr_code_key }) => {
  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );

  const [isClickedDownload, setIsClickedDownload] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const hasAlert = !!(
    registrationCheck &&
    registrationCheck?.operation?.company_name &&
    registrationCheck?.operation?.cnp
  );

  const handleCopy = () => {
    if (isClickedDownload || downloaded) return;

    ReactGA.event({
      category: 'Faturas',
      action: '[AL][Web][Fatura Detalhada]Copiar Pix',
    });

    setIsClickedDownload(true);
    setTimeout(() => {
      clipboardCopy(qr_code_key);
      setIsClickedDownload(false);
      setDownloaded(true);

      setTimeout(() => {
        setDownloaded(false);
      }, 5000);
    }, 2000);
  };

  return (
    <ContainerInvoicePixCode hasAlert={hasAlert}>
      {hasAlert && (
        <AlertFraudBox>
          <AlertPixFraud
            company={registrationCheck?.operation?.company_name}
            cnpj={registrationCheck?.operation?.cnp}
          />
        </AlertFraudBox>
      )}
      <LabelBox>CÓDIGO PIX</LabelBox>
      <ContainerBox>
        <TextBox>{qr_code_key}</TextBox>
      </ContainerBox>
      <Margin height={12} />
      <ButtonCodeInvoice
        type="button"
        onClick={handleCopy}
        clicked={isClickedDownload}
        copied={downloaded}
      >
        {!isClickedDownload && (
          <>
            <p>{downloaded ? 'Código copiado!' : 'Copiar'}</p>
            <Margin width={8} />
            <div>
              {downloaded ? <CopySuccessIcon /> : <CopyIcon color="#000" v3 />}
            </div>
          </>
        )}
      </ButtonCodeInvoice>
    </ContainerInvoicePixCode>
  );
};
