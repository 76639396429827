import styled from 'styled-components';
import { Color } from '~/styles';

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionTitle = styled.h1`
  color: ${Color.gray};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 80px;
`;

export const BoxInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  input {
    height: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  select {
    padding: 6px 28px 6px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${Color.gray};
  }
`;

export const Row = styled.div<{ divider?: string }>`
  display: flex;
  width: 100%;
  margin 12px 0px;

  ${({ divider }) =>
    divider &&
    `div: first-child {
    width: ${divider}%;
    div {
      width: initial;
    }
  }`}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${Color.blueIntense};
  position: absolute;
  text-align: center;
  background: white;
  left: 105px;
  padding: 0 30px 0 30px;
`;

export const SeparatorTitle = styled.hr`
  width: 100%;
  border-top: 1px solid ${Color.grayLightSeconde};
`;

export const Label = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.gray};
  font-style: italic;
  margin: auto;
  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }
`;

export const Subtitle = styled.div`
  color: ${Color.blueIntense};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
`;

export const TextFooter = styled.p`
  color: ${Color.gray};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  text-align: center;
`;

export const CheckboxContaner = styled.div`
  color: ${Color.blueSeconde};
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  cursor: pointer;
  appearance: none;
  margin-right: 8px;
  border-radius: 4px;
  height: 15px;
  width: 15px;
  border: 1px solid ${Color.blueSeconde};

  &:checked {
    background: ${Color.blueSeconde};
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 50%;
  max-width: 335px;
`;
