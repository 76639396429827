import styled from 'styled-components';
import { Color } from '~/styles';

interface IPropsStatusInvoice {
  color: string;
}

export const Container = styled.button<IPropsStatusInvoice>`
  width: 100%;
  height: 88px;
  border: 1px solid ${Color.grayLightSeconde};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  outline: none;
  background: none;
  cursor: pointer;

  .container-border {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 28px;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 16px;
      background: ${({ color }) => color};
      border-radius: 8px 0 0 8px;
    }
  }

  div {
    p {
      text-align: left;
    }

    .status {
      color: ${Color.blueIntense};
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.42px;
    }

    .protocol {
      color: ${Color.blueIntense};
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.56px;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .serviceName {
      color: ${Color.grayLightSeconde};
      font-size: 12px;
      font-weight: 300;
      letter-spacing: -0.42px;
    }
  }
`;
