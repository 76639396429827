import {
  IRequestCreateAlert,
  IRequestPatchAlert,
} from '~/store/modules/enrollmentsAlerts/types';
import { api } from './api';

function getEnrollmentsAlertsService() {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsAlerts: any = async () => {
        const response = await api.get('v3/enrollments/me/alerts');

        return response.data;
      };

      resolve(getEnrollmentsAlerts());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsCreateAlertService(
  data: IRequestCreateAlert,
  registration: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsCreateAlert: any = async () => {
        const response = await api.post(`v3/enrollments/${registration}/alerts`, {
          ...data,
        });

        return response.data;
      };

      resolve(postEnrollmentsCreateAlert());
    } catch (error) {
      reject(error);
    }
  });
}

function deleteEnrollmentsAlertService(registration: string, alertId: number) {
  return new Promise((resolve, reject) => {
    try {
      const deleteEnrollmentsCard: any = async () => {
        const response = await api.delete(
          `v3/enrollments/${registration}/alerts/${alertId}`,
        );

        return response.data;
      };

      resolve(deleteEnrollmentsCard());
    } catch (error) {
      reject(error);
    }
  });
}

function patchEnrollmentsAlertService(
  registration: string,
  data: IRequestPatchAlert,
) {
  return new Promise((resolve, reject) => {
    try {
      const patchEnrollmentsCardDefault: any = async () => {
        const response = await api.patch(
          `v3/enrollments/${registration}/alerts/${data.alertId}`,
          {
            cubic_meter: data.cubic_meter,
            is_active: true,
          },
        );

        return response.data;
      };

      resolve(patchEnrollmentsCardDefault());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsAlertsService,
  postEnrollmentsCreateAlertService,
  deleteEnrollmentsAlertService,
  patchEnrollmentsAlertService,
};
