/* eslint-disable prefer-const */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  Banner,
  BorderText,
  Container,
  ContainerImgs,
  ContainerText,
  ContentBanner,
  ContentText,
} from './styles';
import slideDepaDpe from '~/assets/Images/silede-dpadpe.jpeg';
import slideApps from '~/assets/Images/slide-apps.png';
import appleStoreImg from '~/assets/Images/apple-store.png';
import googleStoreImg from '~/assets/Images/google-store.png';
import bannerImg from '~/assets/Images/banner-social-tariff.jpeg';
import bannerSimplePayment from '~/assets/Images/banner-simple-payment.png';
import bannerLoosen from '~/assets/Images/banner-desaperta.png';
import bannerReportLackWater from '~/assets/Images/banner-report-lack-water.png';
import { useDispatch, useSelector } from 'react-redux';
import { activateModalAuth } from '~/store/modules/enrollmentsAuth/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { checkToggle } from '~/utils/toggles';

const SlideShow: FC = () => {
  const dispatch = useDispatch();

  const toggles = useSelector((item: IState) => item.toggles.data);

  const [showSlides, setShowSlides] = useState<boolean>(false);

  let slideActive = 0;

  const [slideItems, setSlideItems] = useState([
    {
      id: 0,
      title: 'Tarifa social',
      description: 'É desconto para você! Clique e faça sua socilitação.',
      img: {
        desktop: bannerImg,
        mobile: '',
      },
      active: true,
      objectFit: false,
      onClick: () => {
        ReactGA.event({
          category: 'Tarifa Social',
          action: `[Ñ Logado][Banner] – Tarifa Social`,
        });
        Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE);
      },
    },
    {
      id: 1,
      title: 'DPA/DPE',
      description: 'Faça o seu cadastro e acompanhe o progresso das solicitações!',
      img: {
        desktop: slideDepaDpe,
        mobile: '',
      },
      active: false,
      objectFit: false,
      onClick: () => {
        Navigation.navigate(RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING);
      },
    },
    {
      id: 2,
      title: 'Pagamento único',
      description: 'Parcele suas faturas em até 12X no cartão de crédito',
      img: {
        desktop: bannerSimplePayment,
        mobile: '',
      },
      active: false,
      objectFit: false,
      onClick: () => dispatch(activateModalAuth(RouteName.INVOICES)),
    },
    {
      id: 3,
      title: 'Baixe o app!',
      description: 'Nossos serviços na palma da sua mão.',
      img: {
        desktop: slideApps,
        mobile: '',
      },
      active: false,
      objectFit: false,
    },
  ]);

  const verifyToggles = async () => {
    const hasReportLackWater = await checkToggle(toggles, 'REPORT_LACK_WATER');
    const hasLoosenToggle = await checkToggle(toggles, 'BANNER_DESAPERTA');

    setSlideItems((prevState) => {
      prevState[0] = {
        ...prevState[0],
        title: hasLoosenToggle ? 'De$aperta' : 'Tarifa social',
        description: hasLoosenToggle
          ? 'A Iguá ajuda você a dar uma folga para o bolso'
          : 'É desconto para você! Clique e faça sua socilitação',
        img: {
          desktop: hasLoosenToggle ? bannerLoosen : slideDepaDpe,
          mobile: '',
        },
        onClick: () => {
          if (hasLoosenToggle) {
            window.open(
              'https://www.igua.com.br/storage/74/db/12/campanha-de-renegoci-atfsz.pdf',
              '_blank',
            );
          } else {
            ReactGA.event({
              category: 'Tarifa Social',
              action: `[Ñ Logado][Banner] – Tarifa Social`,
            });
            Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE);
          }
        },
      };

      prevState[1] = {
        ...prevState[1],
        title: hasReportLackWater ? 'Falta d’água' : 'DPA/DPE',
        description: hasReportLackWater
          ? 'Clique e solicite o reparo na sua região'
          : 'Faça o seu cadastro e acompanhe o progresso das solicitações!',
        img: {
          desktop: hasReportLackWater ? bannerReportLackWater : slideDepaDpe,
          mobile: '',
        },
        onClick: () => {
          if (hasReportLackWater) {
            dispatch(activateModalAuth(RouteName.INVOICES));
          } else {
            Navigation.navigate(
              RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING,
            );
          }
        },
      };

      return prevState;
    });

    // setSlideItems(slides);
    setShowSlides(true);
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  useEffect(() => {
    if (showSlides) checkedSlide();
  }, [showSlides]);

  const updateSlideActive = async (index: number) => {
    const array = await slideItems.map((item: any, indexItem) => {
      item.active = false;
      if (index === indexItem) item.active = true;
      return item;
    });

    setSlideItems(array);
    slideActive = index + 1 === slideItems.length ? 0 : index + 1;
  };

  const checkedSlide = async () => {
    updateSlideActive(slideActive);
    setTimeout(() => checkedSlide(), 5000);
  };

  return (
    <Container>
      <ContentBanner>
        {slideItems.map((item: any, index) => (
          <Banner
            key={index && index.toString()}
            src={item.img.desktop}
            active={item.active}
            objectFit={item.objectFit}
            alt="banner"
            onClick={item?.onClick}
          />
        ))}
        <ContainerImgs active={slideItems[3].active}>
          <a
            href="https://play.google.com/store/apps/details?id=com.iguaapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleStoreImg} alt="ícone - loja google" />
          </a>
          <a
            href="https://apps.apple.com/br/app/digi-igu%C3%A1/id1588069396"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appleStoreImg} alt="ícone - loja apple" />
          </a>
        </ContainerImgs>
      </ContentBanner>
      <ContainerText>
        {slideItems.map((item: any, index) => (
          <ContentText
            key={index && index}
            type="button"
            onClick={() => {
              updateSlideActive(index);
            }}
            active={item.active}
          >
            <BorderText active={item.active} />
            <div className="box-text">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </ContentText>
        ))}
      </ContainerText>
    </Container>
  );
};

export default SlideShow;
