import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCaseValues } from '~/store/modules/dpadpeDataMenager/actions';

import FormDpaDpeWeb from './web';
import { resetSelectedCase } from '~/store/modules/dpadpe/actions';

const SupplyPossibilityConsultation: FC = () => {
  const dispatch = useDispatch();

  const resetData = () => {
    dispatch(
      setCaseValues({
        owner: '',
        email: '',
        cpf_cnpj: '',
        cep: '',
        street: '',
        neighborhood: '',
        number: '',
        complement: '',
        city: '',
        state: '',
        qualification: '',
        phone: '',
        name: '',
        address: '',
        interestedEmail: '',
        identification_document_file: null,
        location_plan_file: null,
        situation_plan_file: null,
      }),
    );
    dispatch(resetSelectedCase());
  };

  useEffect(() => {
    return () => resetData();
  }, []);

  return <FormDpaDpeWeb />;
};

export default SupplyPossibilityConsultation;
