import React from 'react';

export const PixSVG: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M15.9332 1.54993L20.0776 5.85615C19.0666 5.94852 18.1258 6.4032 17.4026 7.15506L13.5025 11.2099C13.4229 11.2769 13.3565 11.2923 13.3176 11.2924C13.2817 11.2925 13.238 11.2808 13.1891 11.229L13.1891 11.229L13.186 11.2257L9.29104 7.17626C8.62442 6.44202 7.78062 6.0326 6.88177 5.90145L11.0653 1.55014C11.0654 1.55002 11.0655 1.54991 11.0656 1.54979C11.0656 1.54977 11.0657 1.54976 11.0657 1.54974C12.4152 0.149618 14.5894 0.150621 15.9328 1.54954L15.9332 1.54993ZM11.6517 12.7595L11.6632 12.7716L11.6756 12.7828C12.1043 13.1728 12.6969 13.4839 13.3392 13.4839C13.977 13.4839 14.5696 13.1722 14.9977 12.7828L15.01 12.7716L15.0216 12.7595L18.9351 8.69069C18.9354 8.6904 18.9357 8.6901 18.936 8.68981C19.343 8.27143 19.901 8.03339 20.463 8.03339H22.1672L24.9841 10.9621C26.3386 12.3704 26.3386 14.6653 24.9841 16.0736L22.1672 19.0023H20.463C19.9009 19.0023 19.3435 18.7642 18.9369 18.3415L15.0216 14.2708C14.1181 13.3315 12.5548 13.3313 11.6511 14.2767C11.6509 14.2769 11.6507 14.2772 11.6504 14.2774L7.75879 18.3182C7.29822 18.7583 6.77436 18.9864 6.2255 18.9864H4.82028L2.01759 16.0735L2.01738 16.0733C0.660872 14.6651 0.660872 12.3706 2.01738 10.9624L2.01788 10.9618L4.82053 8.04396H6.2255C6.77431 8.04396 7.29812 8.27206 7.75866 8.71204L11.6517 12.7595ZM13.186 15.8099C13.2638 15.729 13.4094 15.729 13.4873 15.8099L17.4026 19.8806C18.1248 20.6314 19.064 21.0859 20.0734 21.1791L15.9355 25.4812C14.5911 26.8396 12.4126 26.8396 11.0682 25.4811L6.87921 21.1304C7.78776 20.9879 8.62714 20.5497 9.28591 19.8647L13.186 15.8099Z"
        fill="#37B4C8"
        stroke="#37B4C8"
      />
    </svg>
  );
};
