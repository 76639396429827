import moment from 'moment';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import { socialTariffStatus } from '~/utils/socialTariffStatus';
import { Borders, getDescription } from '..';
import { ContainerBorderStatus, DateSeparator } from '../styles';
import {
  Container,
  ContainerDates,
  ContainerDescription,
  StatusText,
  Text,
} from './styles';

const Protocol: FC = () => {
  const data = useSelector((item: IState) => item.socialTariff.data);

  return (
    <Container>
      <ContainerDescription>
        <p>{getDescription({ status: data?.status || '' })}</p>
      </ContainerDescription>
      <Margin height={48} />
      <Text>
        O número do seu protocolo é:
        <Margin height={6} />
        <b>{data?.protocol}</b>
      </Text>
      <Margin height={48} />
      <ContainerBorderStatus>
        {data && <Borders status={data?.status} />}
      </ContainerBorderStatus>
      <Margin height={17} />
      <StatusText
        style={{
          color: socialTariffStatus(data?.status || '').colorRequest,
        }}
      >
        {data && socialTariffStatus(data?.status).labelRequest}
      </StatusText>
      <Margin height={24} />
      <ContainerDates
        style={{ justifyContent: data?.closedDate ? 'space-between' : 'center' }}
      >
        <Text>
          Data de solicitação
          <br />{' '}
          <b>{moment(data?.createdDate || new Date()).format('DD/MM/YYYY')}</b>
        </Text>
        {(data?.expectedDate || data?.closedDate) && (
          <>
            <DateSeparator />
            {data?.closedDate ? (
              <Text>
                Data de encerramento <br />{' '}
                <b>{moment(data?.closedDate || new Date()).format('DD/MM/YYYY')}</b>
              </Text>
            ) : (
              <Text>
                Prazo para conclusão <br />{' '}
                <b>{moment(data?.expectedDate).format('DD/MM/YYYY')}</b>
              </Text>
            )}
          </>
        )}
      </ContainerDates>
    </Container>
  );
};

export default Protocol;
