import styled from 'styled-components';
import { Color } from '~/styles';

export const Row = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
`;

export const ErrorsText = styled.div`
  color: ${Color.red};

  font-size: 10px;
`;
