import React, { ButtonHTMLAttributes, FC } from 'react';
import { MyButtonLink } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  color?: 'secondary' | 'gray';
  variant?: 'underline';
}

const ButtonLink: FC<IProps> = ({ children, color, variant, ...props }) => {
  return (
    <MyButtonLink {...props} color={color} variant={variant}>
      {children}
    </MyButtonLink>
  );
};

export { ButtonLink };
