import {
  IRequestDeleteUploadFileCustomerObligation,
  IRequestGetCustomerObligation,
  IRequestGetDownloadFileCustomerObligation,
  IRequestGetFileCodesCustomerObligation,
  IRequestGetValidateCustomerObligation,
  IRequestPatchProtocolCustomerObligation,
  IRequestPatchUploadFileCustomerObligation,
  IRequestPostAttachFilesCustomerObligation,
  IRequestPostCreateCustomerObligation,
  IRequestPostFileCodesCustomerObligation,
  IRequestPostUploadFileCustomerObligation,
} from '~/store/modules/customerObligation/types';
import { api } from './api';

function listCustomerObligationService() {
  return new Promise((resolve, reject) => {
    try {
      const listCustomerObligation: any = async () => {
        const response = await api.get('v3/customer-obligation');

        return response.data;
      };
      resolve(listCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function getCustomerObligationService(data: IRequestGetCustomerObligation) {
  return new Promise((resolve, reject) => {
    try {
      const getCustomerObligation: any = async () => {
        const response = await api.get(`v3/customer-obligation/case/${data.caseID}`);

        return response.data;
      };
      resolve(getCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function validateCustomerObligationService(
  data: IRequestGetValidateCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const validateCustomerObligation: any = async () => {
        const response = await api.get(
          `/v3/customer-obligation/validate/case/${data.case}`,
        );

        return response.data;
      };
      resolve(validateCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function createCustomerObligationService(
  data: IRequestPostCreateCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const createCustomerObligation: any = async () => {
        const response = await api.post('/v3/customer-obligation/', { ...data });

        return response.data;
      };
      resolve(createCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function uploadFileCustomerObligationService(
  data: IRequestPostUploadFileCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const uploadFileCustomerObligation: any = async () => {
        const response = await api.post('/v3/customer-obligation/file', data, {
          timeout: 360000,
        });

        return response.data;
      };
      resolve(uploadFileCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function uploadUpdateFileCustomerObligationService(
  data: IRequestPatchUploadFileCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const uploadUpdateFileCustomerObligation: any = async () => {
        const response = await api.patch(
          `/v3/customer-obligation/file/${data.idFile}`,
          {
            caseIdSales: data.caseIdSales,
            attachmentKey: data.attachmentKey,
            type: data.type,
            step: data.step,
            filename: data.filename,
            filedata: data.filedata,
          },
        );

        return response.data;
      };
      resolve(uploadUpdateFileCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function deleteUploadFileCustomerObligationService(
  data: IRequestDeleteUploadFileCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const deleteUploadFileCustomerObligation: any = async () => {
        const response = await api.delete(`/v3/customer-obligation/file/${data.id}`);

        return response.data;
      };
      resolve(deleteUploadFileCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function attachFilesCustomerObligationService(
  data: IRequestPostAttachFilesCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      console.log(
        `/v3/customer-obligation/attach-files/case/${data.case}/step/${data.step}`,
      );
      const attachFilesCustomerObligation: any = async () => {
        const response = await api.patch(
          `/v3/customer-obligation/attach-files/case/${data.case}/step/${data.step}`,
        );

        return response.data;
      };
      resolve(attachFilesCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function getStepCustomerObligationService(data: IRequestGetCustomerObligation) {
  return new Promise((resolve, reject) => {
    try {
      const getStepCustomerObligation: any = async () => {
        const response = await api.get(
          `/v3/customer-obligation/case/${data.caseID}/step`,
        );

        return response.data;
      };
      resolve(getStepCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function patchCustomerObligationStepService(
  data: IRequestPostAttachFilesCustomerObligation,
): any {
  return new Promise((resolve, reject) => {
    try {
      const patchCustomerObligationStep: any = async () => {
        const response = await api.patch(
          `v3/customer-obligation/case/${data.case}/step`,
          {
            step: data.step,
          },
        );

        return response.data;
      };
      resolve(patchCustomerObligationStep());
    } catch (error) {
      reject(error);
    }
  });
}

function fileCodesCustomerObligationService(
  data: IRequestGetFileCodesCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const fileCodesCustomerObligation: any = async () => {
        const response = await api.get(
          `/v3/customer-obligation/file/code/case/${data.case}/type/${data.type}/document/${data.documentType}/`,
        );

        return response.data;
      };
      resolve(fileCodesCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function createFileCodeCustomerObligationService(
  data: IRequestPostFileCodesCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const createFileCodeCustomerObligation: any = async () => {
        const response = await api.post('/v3/customer-obligation/file/code', {
          ...data,
        });

        return response.data;
      };
      resolve(createFileCodeCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function getDataDownloadFileCustomerObligationService(
  data: IRequestGetDownloadFileCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const getDataDownloadFileCustomerObligation: any = async () => {
        const response = await api.get(
          `/v3/customer-obligation/file/${data.idFile}`,
        );

        return response.data;
      };
      resolve(getDataDownloadFileCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

function patchProtocolCustomerObligationService(
  data: IRequestPatchProtocolCustomerObligation,
) {
  return new Promise((resolve, reject) => {
    try {
      const patchProtocolCustomerObligation: any = async () => {
        const response = await api.patch(
          `/v3/customer-obligation/case/${data.case}`,
          {
            cityHallCaseNumber: data.cityHallCaseNumber,
            plenaryApprovalDate: data.plenaryApprovalDate,
            startWorkDate: data.startWorkDate,
            materialInspectionDate: data.materialInspectionDate,
          },
        );

        return response.data;
      };
      resolve(patchProtocolCustomerObligation());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  listCustomerObligationService,
  getCustomerObligationService,
  validateCustomerObligationService,
  createCustomerObligationService,
  uploadFileCustomerObligationService,
  uploadUpdateFileCustomerObligationService,
  deleteUploadFileCustomerObligationService,
  attachFilesCustomerObligationService,
  getStepCustomerObligationService,
  patchCustomerObligationStepService,
  fileCodesCustomerObligationService,
  createFileCodeCustomerObligationService,
  getDataDownloadFileCustomerObligationService,
  patchProtocolCustomerObligationService,
};
