import React from 'react';
import * as S from './styles';

interface Props extends React.FormHTMLAttributes<any> {
  title: string;
  children: React.ReactNode;
}

const CardContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <S.Container>
      <S.Card>
        <S.CardTitle>{title}</S.CardTitle>
        <S.CardContent>{children}</S.CardContent>
      </S.Card>
    </S.Container>
  );
};

export default CardContainer;
