import styled from 'styled-components';
import { Color } from '~/styles';

export const Content = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;

  input {
    height: 36px;
    border-radius: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;

  :last-of-type {
    margin: 0;
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 50%;
  max-width: 335px;
`;

export const Label = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.red};
  font-style: italic;

  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
  }
`;
