import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import { postOwnershipOrderService } from '~/services/ownership';

import { CloseIcon } from '~/assets/Icons/CloseIcon';
import { Button } from '~/common';
import { Checkbox as CheckboxV2 } from '~/components/Checkbox';
import { Wrapper, Content, Header, Texts, IconButton, Box, Submit } from './styles';

import { useDispatch } from 'react-redux';
import { setOwnershipErrorOpen } from '~/store/modules/ownership/actions';

interface Props {
  handleClose?(): void;
  handleSubmit: () => void;
  setVerifyTerms?: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

export const Terms = `SOLICITO a transferência de titularidade de morador e/ou proprietário do imóvel acima citado, cadastrado junto à concessionária acima citada, conforme documentação apresentada.

DECLARO estar plenamente de acordo com as normas estabelecidas na Lei Municipal para a fixação do valor das tarifas e preços dos serviços, e a manutenção do respectivo equilíbrio econômico e financeiro, bem como as exigências, obrigações, restrições, vedações, proibições e demais normas gerais e condições de responsabilidade do consumidor, estabelecidos na Legislação Municipal e na sua regulamentação.

DECLARO estar ciente que a prestação de serviços de fornecimento de água e esgotamento sanitário envolve a coleta e tratamento de meus dados pessoais por parte da concessionária acima citada, a qual é responsável por usá-los e armazená-los nos termos da Lei nº 13.709/18 (LGPD). Em caso de dúvidas sobre os direitos do usuário ou os deveres da concessionária acima citada em relação ao tratamento dos dados pessoais, pode-se entrar em contato por meio dos nossos canais de atendimentos no site https://igua.com.br/canais-de-atendimento.
`;

const TermsModal: FC<Props> = ({
  handleClose,
  setVerifyTerms,
  disabled,
  handleSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const dispatch = useDispatch();

  const handleClick = async () => {
    setIsSubmitting(true);
    try {
      const res: any = await postOwnershipOrderService({
        // MATRÍCULA SEM O "-" E DÍGITO
        enrollment: values.enrollment.replace(/.{2}$/g, ''),
        operation: values.city.id,
        reason: values.reason.label,
        files: {
          [values.files[0].name]: {
            filename: values.files[0].file.name,
            filedata: values.files[0].base64,
          },
          [values.files[1].name]: {
            filename: values.files[1].file.name,
            filedata: values.files[1].base64,
          },
        },
      });
      setFieldValue('caseId', res.sales_id);
      // dispatch(getOwnershipOrderDetailRequest(res.sales_id));
      setIsSubmitting(false);
      handleSubmit();
      return handleClose?.();
    } catch (error: any) {
      dispatch(setOwnershipErrorOpen(error));
      setIsSubmitting(false);
      return null;
    }
  };

  return (
    <Wrapper>
      <Content>
        <Header>
          <Texts>
            <div className="title">Termos de uso</div>
            <div className="subtitle">
              Para seguir com a assinatura, leia com atenção os termos a seguir:
            </div>
          </Texts>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Box>{Terms}</Box>
        <Submit>
          <CheckboxV2 handleCheck={setVerifyTerms}>
            Li e aceito o termo de troca de titularidade.
          </CheckboxV2>
          <Button
            variant="primary"
            onClick={handleClick}
            disabled={disabled}
            loading={isSubmitting}
          >
            Ok
          </Button>
        </Submit>
      </Content>
    </Wrapper>
  );
};

export { TermsModal };
