import React from 'react';

interface IProps {
  color?: string;
}

const HomeUser = ({ color = '#37B4C8' }: IProps) => {
  return (
    <svg
      width="53"
      height="57"
      viewBox="0 0 53 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6925 1.90501C26.6172 0.998766 25.2562 0.50174 23.85 0.50174C22.4438 0.50174 21.0828 0.998766 20.0075 1.90501L2.12 16.9941C1.45607 17.5537 0.922356 18.2515 0.556175 19.0388C0.189994 19.8261 0.000179481 20.6838 0 21.5521V47.5301C0 50.09 2.0776 52.1676 4.6375 52.1676H12.5875C13.8174 52.1676 14.997 51.679 15.8667 50.8093C16.7364 49.9396 17.225 48.76 17.225 47.5301V34.2801C17.225 33.7529 17.4344 33.2474 17.8071 32.8747C18.1799 32.502 18.6854 32.2926 19.2125 32.2926H31.8928C32.1402 30.5809 32.8612 28.9724 33.9743 27.6488C35.0874 26.3251 36.5483 25.3389 38.1922 24.8015C39.836 24.2641 41.5974 24.1969 43.2774 24.6075C44.9574 25.0181 46.4893 25.8901 47.7 27.1251V21.5521C47.6998 20.6838 47.51 19.8261 47.1438 19.0388C46.7776 18.2515 46.2439 17.5537 45.58 16.9941L27.6925 1.90501ZM47.7 33.6176C47.7 35.3746 47.002 37.0597 45.7596 38.3021C44.5172 39.5446 42.8321 40.2426 41.075 40.2426C39.3179 40.2426 37.6328 39.5446 36.3904 38.3021C35.148 37.0597 34.45 35.3746 34.45 33.6176C34.45 31.8605 35.148 30.1754 36.3904 28.933C37.6328 27.6905 39.3179 26.9926 41.075 26.9926C42.8321 26.9926 44.5172 27.6905 45.7596 28.933C47.002 30.1754 47.7 31.8605 47.7 33.6176ZM53 47.8613C53 51.9847 49.5921 56.1426 41.075 56.1426C32.5579 56.1426 29.15 52.0006 29.15 47.8613V47.5884C29.15 44.9914 31.2541 42.8926 33.8484 42.8926H48.3015C50.8985 42.8926 53 44.994 53 47.5884V47.8613Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeUser;
