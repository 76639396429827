import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px 15px 0 16px;
  background: ${Color.white};
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 71px;
    height: 71px;
  }

  h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    color: ${Color.blueSeconde};
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.gray};
    text-align: center;
    height: 161px;
    display: flex;
    align-items: center;
  }

  button {
    max-width: 233px;
    height: 48px;
    background: ${Color.blueSeconde};
    border-radius: 8px;
    border: 2px solid #37b4c8;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: none;
    filter: none;
    :first-of-type {
      background: ${Color.white};
      color: ${Color.blueSeconde};
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: auto;
    border-radius: 16px;
    max-width: calc(541px - 58px);
    min-height: calc(431px - 64px);
    padding: 32px 30px 32px 28px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;
