import React from 'react';
import { Info } from './styled';
import { Text } from '~/components/TextInfo';
import { Margin } from '~/components/Margin';

// import { Container } from './styles';

interface IProps {
  label?: string;
}

const BoxInfo: React.FC<IProps> = ({ label }) => {
  return (
    <Info>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.388672" width="44" height="43.2242" rx="8" fill="#FAB419" />
        <path
          d="M22 17.8039V23.5576M22.5754 27.0099C22.5754 27.3276 22.3178 27.5852 22 27.5852C21.6822 27.5852 21.4246 27.3276 21.4246 27.0099C21.4246 26.6921 21.6822 26.4345 22 26.4345C22.3178 26.4345 22.5754 26.6921 22.5754 27.0099ZM14.3046 31.6129H29.6954C31.4273 31.6129 32.5386 29.772 31.732 28.2395L24.0366 13.6183C23.1738 11.9788 20.8262 11.9788 19.9634 13.6183L12.268 28.2394C11.4614 29.772 12.5727 31.6129 14.3046 31.6129Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>

      <Margin width={24} />

      <div style={{ flex: 1, justifyContent: 'flex-start' }}>
        <Text color="#FAB419" weight={700} textAlign="left">
          Atenção
        </Text>
        <Margin height={5} />

        <Text weight={500}>{label}</Text>
      </div>
    </Info>
  );
};

export default BoxInfo;
