import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 14px;
  flex: 1;
  border: 2px solid #37b482;
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  color: #37b482;
  text-align: center;

  svg {
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 18px 24px;

    svg {
      margin-bottom: 0;
    }
  }
`;
