import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { Text } from '~/components/TextInfo';

export const Container = styled.div`
  display: flex;
  padding: 0 5px 20px 5px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 18px 18px 18px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
`;

interface ICardInvoiceTitle {
  isLoading?: boolean;
}

export const CardInvoiceTitle = styled.div<ICardInvoiceTitle>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 11px;
  background-color: ${({ isLoading }) => (isLoading ? Color.gray : Color.yellow)};
  border-radius: 10px 10px 0 0;
`;

export const CardInvoiceTitleText = styled(Text)`
  width: 137px;
  text-align: center;
  margin-left: auto;
  color: ${Color.white};
  font-size: 11px;
  font-weight: 500;
`;

export const CardInvoHeader = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: 36px;
  }
`;
export const CardInvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
`;

export const ContainerInvoiceDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  height: 32px;
  margin: 0 29px;
  border: 1px solid ${Color.grayLight};
`;

export const InvoiceBtnDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.blueSeconde};
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  height: 30px;
  cursor: pointer;
`;

export const ContainerAutomaticDebit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${Color.green};
  }
`;

export const CardIColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  & button.first {
    margin-top: 8px;
  }
`;

export const CardIText = styled.span`
  font-size: 11px;
  color: ${Color.gray};
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 12px;
  }
`;

export const CardIValueText = styled(CardIText)<ICardInvoiceTitle>`
  color: ${({ isLoading }) => (isLoading ? Color.gray : Color.yellow)};
  font-size: 24px;
  height: 47px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;

  &.value-empty {
    justify-content: right;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 26px;
  }
`;

export const PayText = styled(CardIText)`
  color: ${Color.blueIntense};
  font-size: 12px;
  font-weight: 300;
  margin-top: -8px;
  width: 115px;
  background-color: ${Color.white};

  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 60%;
  }
`;

export const CRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const CCol = styled(CardIColumn)`
  text-align: left;

  & span {
    font-size: 12px;
    color: ${Color.gray};
    font-weight: 500;
    line-height: 15px;
    width: 70%;
  }
  & span.value {
    color: ${Color.blueIntense};
    font-weight: 600;
    white-space: pre;
  }
`;

interface ICardPayProps {
  bg?: 'secondary';
}

export const CardPay = styled.button<ICardPayProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 11px;
  cursor: pointer;
  width: 115px;
  height: 48px;
  padding: 4px 0;
  background: ${({ bg }) => (bg ? Color.blueSeconde : Color.white)};
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.19);
  border-radius: 4px;

  & span {
    color: ${({ bg }) => (bg ? Color.white : Color.green)};
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 90.9%;
  }

  &.pix {
    svg {
      width: 21px;
      height: 23px;
    }
  }

  &.credit-card {
    svg {
      width: 13px;
      height: 15px;
    }
  }

  :disabled {
    cursor: auto;
    opacity: 0.3;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    &.pix {
      svg {
        width: 25px;
        height: 25px;
      }
    }

    &.credit-card {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const CardInvoiceValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    & svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ContainerSeeInvoices = styled.div`
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeInvoicesButton = styled.button`
  width: 115px;
  height: 44px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  color: ${Color.white};
  cursor: pointer;
  border-radius: 8px;
  background: ${Color.green};
`;
