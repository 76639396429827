import React from 'react';

function ArroButtonwBackSVG() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32"
        y="32.5"
        width="32"
        height="32"
        rx="8"
        transform="rotate(180 32 32.5)"
        fill="#E9ECEF"
      />
      <path
        d="M15 21.5L10 16.5M10 16.5L15 11.5M10 16.5L22 16.5"
        stroke="#212529"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArroButtonwBackSVG;
