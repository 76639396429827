import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Wrapper = styled.div`
  padding: 36px 40px;
  height: 100%;
  max-width: 760px;
  max-height: 400px;
  background: white;
  border-radius: 16px;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .title {
    font-weight: 500;
  }
  .subtitle {
    color: ${Color.gray};
    font-weight: 400;
    font-size: 15px;
  }
`;

export const Submit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    justify-content: center;
  }
`;

export const Box = styled.div`
  padding: 16px 24px;
  line-height: 1.5;
  text-align: justify;
  overflow: scroll;
  border: 1px solid #00000050;
  white-space: pre-line;
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;
