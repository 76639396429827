import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const ContainerPaymentMethods = styled.div`
  width: 100%;
  display: flex;
  gap: 35px;
  margin: 24px 0;
`;

export const ButtonPaymentMethod = styled.button<{ active: boolean }>`
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 700;
  color: ${Color.blueIntense};
  background: #f2f2f2;
  cursor: pointer;
  padding: 5px 0;

  ${({ active }) =>
    !active &&
    css`
      background: ${Color.white};
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 2px;
    `};

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 16px;
  }
`;
