import React, { useEffect } from 'react';

import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { ContainerCard, Loading, Button } from '~/common';
import Modal from '~/components/Modal';
import { OrderList } from './Common/OrderList';
import { HowItWorks } from './Common/HowItWorks';
import { Main, ErrorMsg, ListWrap, InfoWrap } from './styles';

import { useSelector, useDispatch } from 'react-redux';
import { IState } from '~/store/modules/types';
import { getOwnershipOrdersRequest } from '~/store/modules/ownership/actions';

const OwnershipManagement = () => {
  const dispatch = useDispatch();
  const ownership = useSelector((item: IState) => item.ownership);

  useEffect(() => {
    dispatch(getOwnershipOrdersRequest());
  }, []);

  const howItWorksItems = [
    {
      id: 1,
      text: 'Preencha os dados do imóvel e do solicitante',
    },
    {
      id: 2,
      text: 'Selecione o tipo de entrega da fatura',
    },
    {
      id: 3,
      text: 'Acompanhe o status da sua solicitação',
    },
  ];

  return (
    <ContainerCard title="Troca de titularidade">
      <Modal
        type="error"
        open={(!ownership.loading && ownership.error?.state) || false}
        title="Ops"
      >
        <ErrorMsg>
          Não foi possivel processar sua solicitação. Por favor tente mais tarde!
        </ErrorMsg>
        <Button color="primary" onClick={() => Navigation.navigate(RouteName.HOME)}>
          Entendi
        </Button>
      </Modal>
      <Main>
        {ownership.loading && <Loading />}
        <ListWrap>
          <OrderList orders={ownership.data} />
        </ListWrap>
        <InfoWrap>
          <HowItWorks items={howItWorksItems} />
        </InfoWrap>
      </Main>
    </ContainerCard>
  );
};

export default OwnershipManagement;
