import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { Text } from '~/components/TextInfo';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 492px;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 2.76838px 17.9945px rgba(0, 0, 0, 0.19);
`;

interface ICardInvoiceTitle {
  isLoading?: boolean;
}

export const CardInvoiceTitle = styled.div<ICardInvoiceTitle>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
  background-color: ${({ isLoading }) => (isLoading ? Color.gray : Color.yellow)};
  border-radius: 10px 10px 0 0;
`;

export const CardInvoiceTitleText = styled(Text).attrs({
  color: Color.white,
  size: 12,
  weight: 300,
  textAlign: 'right',
})`
  width: 100%;
  padding-right: 15%;
  text-transform: uppercase;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    font-size: 12px;
  }
`;

export const CardInvoHeader = styled.div`
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    height: 38px;
  }
`;
export const CardInvoiceBody = styled.div`
  height: 219px;
  display: flex;
  flex-direction: row;

  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    height: 154px;
  }
`;

export const ContainerInvoiceDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  height: 32px;
  margin: 0 29px;
  border: 1px solid ${Color.grayLight};
`;

export const InvoiceBtnDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.blueSeconde};
  font-size: 16px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  height: 52.3px;
  cursor: pointer;
  letter-spacing: 0.6px;
`;

export const ContainerAutomaticDebit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${Color.green};
  }
`;

export const CardIColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;

  & button.first {
    margin-top: 25px;
    @media (max-width: ${Breakpoints.mobile.max}px) {
      margin-top: 10px;
    }
  }
`;

export const CardIText = styled.span`
  font-size: 18px;
  color: ${Color.gray};
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 550px) {
    font-size: 12px;
  }
`;

export const CardIValueText = styled(CardIText)<ICardInvoiceTitle>`
  color: ${({ isLoading }) => (isLoading ? Color.gray : Color.yellow)};
  font-size: 36px;
  height: 80px;
  font-weight: 500;
  display: flex;
  align-items: center;

  width: 100%;

  @media (max-width: ${Breakpoints.laptop.max}px) {
    font-size: 26px;
  }
`;

export const PayText = styled(CardIText)`
  color: ${Color.black};
  font-size: 14px;
  font-weight: 400;
  margin-top: -8px;
  width: 150px;
  background-color: ${Color.white};
  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 100%;
  }
`;

export const CRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: row;
    margin: 0 0px 0px 60px;
    padding: 6px 0;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CCol = styled(CardIColumn)`
  text-align: left;

  & span {
    font-size: 16px;
    color: ${Color.gray};
    font-weight: 300;
    line-height: 26px;
    width: 70%;
    @media (max-width: 550px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  & span.value {
    color: ${Color.blueIntense};
    font-weight: 700;
    white-space: pre;
  }
`;

interface ICardPayProps {
  bg?: 'secondary';
}

export const CardPay = styled.button<ICardPayProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 11px;
  cursor: pointer;
  width: 158.22px;
  height: 79.11px;
  background: ${({ bg }) => (bg ? Color.blueSeconde : Color.white)};
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  & span {
    color: ${({ bg }) => (bg ? Color.white : Color.green)};
    margin-top: 3px;
    text-align: center;
    font-weight: 400;
  }
  & svg {
    width: 32px;
    height: 32px;
  }
  :disabled {
    cursor: auto;
    opacity: 0.3;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 98px;
    height: 49px;
    & span {
      font-size: 11px;
      font-weight: 300;
    }
    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CardInvoiceValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${Breakpoints.mobile.max}px) {
    & svg {
      width: 18px;
      height: 18px;
    }
  }
`;
