import styled from 'styled-components';
import { Color } from '~/styles';

export const Row = styled.div`
  display: flex;

  justify-content: center;
`;

export const ErrorsText = styled.div`
  color: ${Color.red};

  font-size: 10px;
`;

export const Tiltle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  /* Azul intenso */

  color: #004b55;
`;
