import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 24px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 24px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 24px 0 203px 0;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100%);
    padding: 24px 0 241px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  margin: 0 0 24px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 80px);
  padding: 40px;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${Color.white};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  border-top: 16px solid ${Color.blueIntense};
  border-radius: 24px;
  max-width: 700px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${Color.blueIntense};
  text-align: left;
`;

export const TextId = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: ${Color.blueIntense};
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: #f2f2f2;
  padding: 32px 24px;
`;

export const ContentRow = styled.div<{ isWhite?: boolean }>`
  min-height: 24px;
  background: ${({ isWhite }) => (isWhite ? Color.white : '#f2f2f2')};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${Color.blueIntense};
  padding: 0 16px;
`;
