import { Reducer } from 'redux';
import {
  RepositoriesTypesEnrollmentsAlerts,
  IRepositoriesStateEnrollmentsAlerts,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsAlerts = {
  data: null,
  loading: false,
  loadingCreateAlert: false,
  loadingDeleteAlert: false,
  loadingPatchAlert: false,
  successCreateAlert: false,
  successDeleteAlert: false,
  successPatchAlert: false,
  error: null,
  errorCreateAlert: null,
  errorDeleteAlert: null,
  errorPatchAlert: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsAlerts, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_REQUEST:
      return {
        ...state,
        loadingCreateAlert: true,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_SUCCESS:
      return {
        ...state,
        successCreateAlert: true,
        loadingCreateAlert: false,
        errorCreateAlert: null,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successCreateAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        errorCreateAlert: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingCreateAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreateAlert: null,
      };

    case RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_REQUEST:
      return {
        ...state,
        loadingDeleteAlert: true,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_SUCCESS:
      return {
        ...state,
        successDeleteAlert: true,
        loadingDeleteAlert: false,
        errorDeleteAlert: null,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successDeleteAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_FAILURE:
      return {
        ...state,
        errorDeleteAlert: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingDeleteAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDeleteAlert: null,
      };

    case RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_REQUEST:
      return {
        ...state,
        loadingPatchAlert: true,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_SUCCESS:
      return {
        ...state,
        successPatchAlert: true,
        loadingPatchAlert: false,
        errorPatchAlert: null,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successPatchAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_FAILURE:
      return {
        ...state,
        errorPatchAlert: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingPatchAlert: false,
      };
    case RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPatchAlert: null,
      };

    default:
      return state;
  }
};

export default reducer;
