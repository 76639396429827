import React, { FC, useState, useEffect } from 'react';
import { Formik } from 'formik';

import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import initialValues from './FormModel/initialValue';
import { Values } from './FormModel/types';

import Modal from '~/components/Modal';
import { ContainerCard, ButtonFoward, ButtonBackward, Button } from '~/common';
import { ResumeStep, LocationStep, ProfileStep } from './Steps';
import { Dialog } from '~/components/Dialog';
import { Stepper, TermsModal } from '../Common';
import { Wrapper, Divider, NavWrapper, ErrorMsg } from './styles';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  getOwnershipOperationsRequest,
  setOwnershipErrorClose,
  setOwnershipErrorOpen,
} from '~/store/modules/ownership/actions';
import { getOwnershipValidateEnrollmentService } from '~/services/ownership';
import { putEnrollmentsUsersService } from '~/services/enrollmentsUsers';
import formatter from '~/utils/formatter';

const steps = [
  {
    id: 0,
    label: 'Dados do imóvel',
  },
  {
    id: 1,
    label: 'Dados do solicitante',
  },
  {
    id: 2,
    label: 'Solicitação realizada',
  },
];

const reasons = [
  {
    id: 1,
    label: 'Aluguei este imóvel',
    upload: [
      {
        name: 'lease_agreement',
        label: 'Contrato de locação*',
      },
      {
        name: 'identification_document',
        label: 'Documento de identificação* (CPF, RG ou CNH)',
      },
    ],
  },
  {
    id: 2,
    label: 'Comprei este imóvel',
    upload: [
      {
        name: 'purchase_contract',
        label: 'Contrato de compra e venda*',
      },
      {
        name: 'identification_document',
        label: 'Documento de identificação* (CPF, RG ou CNH)',
      },
    ],
  },
  {
    id: 3,
    label: 'Desmembramento do imóvel',
    upload: [
      {
        name: 'purchase_contract',
        label: 'Contrato de compra e venda*',
      },
      {
        name: 'identification_document',
        label: 'Documento de identificação* (CPF, RG ou CNH)',
      },
    ],
  },
  {
    id: 4,
    label: 'Retorno ao proprietário',
    upload: [
      {
        name: 'photo_hydrometer',
        label: 'Foto do hidrômetro que mostre a leitura atual do imóvel.*',
      },
      {
        name: 'document_proving_departure_property',
        label: `Documento que comprove a saída do imóvel (documento de entrega das chaves ou distrato de contrato) 
ou 
Procuração do proprietário do imóvel com firma reconhecida em cartório (caso o proprietário não for o solicitante)`,
      },
    ],
  },
  {
    id: 5,
    label: 'Falecimento do titular',
    upload: [
      {
        name: 'identification_document',
        label: 'Documento de identificação* (CPF, RG ou CNH)',
      },
      {
        name: 'death_certificate',
        label:
          'Certidão de óbito do titular cadastrado ou Documento que comprove vínculo com o falecido (certidão de nascimento/casamento)',
      },
    ],
  },
];

const OwnershipCreateOrder: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [verifyTerms, setVerifyTerms] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const ownership = useSelector((item: IState) => item.ownership);
  const userInfo = useSelector((item: IState) => item.enrollmentsUsers.data);

  useEffect(() => {
    dispatch(getOwnershipOperationsRequest());
    document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  const handleBack = () => {
    if (activeStep === 0) Navigation.goBack();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 2) Navigation.navigate(RouteName.HOME);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (values: Values, actions: any) => {
    switch (activeStep) {
      case 0: {
        // VALIDATE OPERATION NAME (CITY)
        const check = ownership.operations.data?.filter(
          (item) => item.label === values.city.label,
        );
        if (check && check.length <= 0)
          return actions.setFieldError('city', 'Cidade inválida');
        // VALIDATE ENROLLMENT
        try {
          await getOwnershipValidateEnrollmentService({
            enrollment: values.enrollment,
            operation: values.city.id,
          });
          actions.setSubmitting(false);
          return handleNext();
        } catch (error: any) {
          if (error.status === 400) {
            return actions.setFieldError('enrollment', 'Matrícula não encontrada');
          }
          dispatch(setOwnershipErrorOpen(error));
        }
        return null;
      }
      case 1: {
        try {
          await putEnrollmentsUsersService({
            phone: {
              primary: values.contact,
              secondary: userInfo?.phone.secondary || '',
            },
            mobile: {
              primary: values.phone,
              secondary: userInfo?.mobile.secondary || '',
            },
            email: values.email,
          });
          // OPEN TERMS MODAL
          return setOpen(true);
        } catch (error) {
          dispatch(setOwnershipErrorOpen(error));
        }
        return null;
      }
      case 2:
        return handleNext();
      default:
        return null;
    }
  };

  const verifySecondStep = (formProps: any) => {
    const { name, email, cnp, phone, files } = formProps.values;
    switch (activeStep) {
      case 1:
        return name && email && cnp && phone && files[0]?.base64 && files[1]?.base64;
      default:
        return false;
    }
  };

  const verifyFoward = (formProps: any) => {
    const { city, enrollment, reason } = formProps.values;
    const { name, email, cnp, phone } = formProps.values;

    switch (activeStep) {
      case 0:
        return city.id && enrollment && reason.id;
      case 1:
        return name && email && cnp && phone;
      default:
        return false;
    }
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return {
          content: <LocationStep reasons={reasons} />,
          fowardLabel: 'Continuar',
          disabled: false,
        };
      case 1:
        return {
          content: <ProfileStep reasons={reasons} />,
          fowardLabel: 'Continuar',
          disabled: false,
        };
      case 2:
        return {
          content: <ResumeStep />,
          fowardLabel: 'Continuar',
          disabled: false,
        };
      default:
        return null;
    }
  };

  return (
    <ContainerCard title="Troca de titularidade">
      <Modal
        type="error"
        open={(!ownership.loading && ownership.error?.state) || false}
        title="Ops"
      >
        <ErrorMsg>
          Não foi possivel processar sua solicitação. Por favor tente mais tarde!
        </ErrorMsg>
        <Button color="primary" onClick={() => dispatch(setOwnershipErrorClose())}>
          Entendi
        </Button>
      </Modal>
      <Stepper steps={steps} activeStep={activeStep} />
      <Divider />
      <Formik
        initialValues={{
          ...initialValues,
          name: userInfo?.fullName || '',
          email: userInfo?.email || '',
          phone: formatter.formatarPhone(userInfo?.mobile.primary || ''),
          cnp: formatter.cpfCnpjMask(userInfo?.cnp || ''),
          contact: formatter.formatarTel(userInfo?.phone.primary || ''),
        }}
        // validationSchema={validationSchemaArr[activeStep]}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Wrapper>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <TermsModal
                handleSubmit={handleNext}
                handleClose={() => setOpen(false)}
                disabled={!verifyTerms}
                setVerifyTerms={setVerifyTerms}
              />
            </Dialog>
            {getStep()?.content}
            {activeStep !== 2 && (
              <NavWrapper>
                <ButtonBackward
                  type="button"
                  className="back"
                  color="primary"
                  onClick={handleBack}
                >
                  Voltar
                </ButtonBackward>
                {activeStep === 1 ? (
                  <ButtonFoward
                    type="submit"
                    className="next"
                    loading={formProps.isSubmitting}
                    disabled={!verifySecondStep(formProps)}
                  >
                    {getStep()?.fowardLabel}
                  </ButtonFoward>
                ) : (
                  <ButtonFoward
                    type="submit"
                    className="next"
                    loading={formProps.isSubmitting}
                    disabled={!verifyFoward(formProps)}
                  >
                    {getStep()?.fowardLabel}
                  </ButtonFoward>
                )}
              </NavWrapper>
            )}
          </Wrapper>
        )}
      </Formik>
    </ContainerCard>
  );
};

export default OwnershipCreateOrder;
