export enum RepositoriesTypesNegativeCertificatesHistory {
  ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_REQUEST',
  ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_SUCCESS',
  ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_FAILURE',

  ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_REQUEST = '@repositories/ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_REQUEST',
  ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_SUCCESS = '@repositories/ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_SUCCESS',
  ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_FAILURE = '@repositories/ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_FAILURE',
}

export interface IResponseNegativeCertificates {
  id: number;
  issue_date: string;
  issue_ref: string;
  year: number;
  printed: boolean;
}

export interface IResponseNegativeCertificateDownload {
  buffer: string;
}

export interface IRepositoriesStateEnrollmentsNegativeCertificates {
  readonly data: IResponseNegativeCertificates[] | [];
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
}
