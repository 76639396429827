/* eslint-disable no-unsafe-optional-chaining */
import React, { FC, ReactNode } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { Container, ContainerAmount, ContainerInfo, Divisor } from './styles';
import { DescriptionPlanIcon } from '../icons';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';

export interface IPropsIcon {
  icon: ReactNode;
  title: string;
  children: ReactNode;
}

const ContainerPlan: FC = () => {
  const data = useSelector((item: IState) => item?.enrollmentsSubscribes.dataPlans);

  const planDescription = data ? data[0]?.description.split('/n') : [''];
  const amount = data ? data[0]?.amount / 100 : 0;

  return (
    <Container id="plan-card">
      <ContainerAmount>
        <p>
          12x
          <span>{formatter.formatCurrency(amount / 12)}</span>
          <br /> <br /> à vista {formatter.formatCurrency(amount)}
        </p>
      </ContainerAmount>
      <Divisor />
      <ContainerInfo>
        {planDescription?.map((description, index) => (
          <p key={index && index.toString()}>
            <div>
              {' '}
              <DescriptionPlanIcon />{' '}
            </div>
            {description}
          </p>
        ))}
      </ContainerInfo>
      <button
        type="button"
        onClick={() => {
          if (!data || !data[0]) return;
          ReactGA.event({
            category: 'Medição digital',
            action: 'Assine Já',
          });
          Navigation.navigate(RouteName.SMARTMETERSUBSCRIPTION, {
            plan_id: data && data[0]?.id,
            amount,
          });
        }}
      >
        ASSINE JÁ
      </button>
    </Container>
  );
};

export default ContainerPlan;
