export const mockDebtNegotiation = {
  totalDebt: 30.0,
  items: [
    {
      type: 'Faturas',
      status: 'Vencidos',
      amount: 10.0,
      quantity: 5,
    },
    {
      type: 'Outros serviços',
      status: 'Pendentes',
      amount: 20.0,
      quantity: 10,
    },
  ],
  summary: {
    overdueInvoices: 10,
    pendingInvoices: 5,
  },
  paymentOptions: {
    installments: 'Até 12x sem juros no cartão',
  },
  negotiableDebts: {
    totalAmount: 10.0,
    promotionalDiscount: 'Descontos promocionais de até 50%',
    items: [
      {
        status: 'Vencida',
        type: 'Parcela a vista de serviço',
        dueDate: '02/09/2021',
        amount: 2.0,
        reference: '493289',
      },
      {
        status: 'Vencida',
        type: 'Parcela a vista de serviço',
        dueDate: '02/09/2021',
        amount: 2.0,
        reference: '493289',
      },
      {
        status: 'Vencida',
        type: 'Fatura',
        dueDate: '02/09/2021',
        amount: 2.0,
        reference: '493289',
      },
      {
        status: 'Pendente',
        type: 'Fatura',
        dueDate: '02/09/2021',
        amount: 2.0,
        reference: '493289',
      },
      {
        status: 'Pendente',
        type: 'Parcela a vista de serviço',
        dueDate: '02/09/2021',
        amount: 2.0,
        reference: '493289',
      },
    ],
  },
};
