import React, { FC } from 'react';

import { ContainerBackGroundImage } from './styles';

import BackgroundSVG from '~/assets/Images/Background.png';

export const BackGroundImage: FC = () => {
  return (
    <ContainerBackGroundImage>
      <img src={BackgroundSVG} alt="imagem de fundo" />
    </ContainerBackGroundImage>
  );
};
