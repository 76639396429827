import React from 'react';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

function ChangeAccountSVG({ color = '#37B4C8', width = 22, height = 22 }: IProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14982 12.125V10.1413H9.70596V12.125C9.70596 12.3432 9.88103 12.5217 10.095 12.5217H11.2621C11.4761 12.5217 11.6511 12.3432 11.6511 12.125V9.34782H12.3125C12.4915 9.34782 12.577 9.12168 12.4409 9.00266L9.18855 6.01523C9.04071 5.88034 8.81507 5.88034 8.66724 6.01523L5.41491 9.00266C5.28264 9.12168 5.36434 9.34782 5.54329 9.34782H6.20465V12.125C6.20465 12.3432 6.37972 12.5217 6.59368 12.5217H7.76079C7.97476 12.5217 8.14982 12.3432 8.14982 12.125Z"
        fill={color}
      />
      <path
        d="M13.5002 7.87187L13.499 7.8707C13.1564 7.53203 12.6173 7.53203 12.2747 7.8707L12.2747 7.8707L12.2735 7.87188L9.02255 11.1069C8.79882 11.3269 8.77039 11.6303 8.84791 11.8587C8.92644 12.0901 9.1512 12.3366 9.50228 12.3366H9.66363V14.8452C9.66363 15.3097 10.0168 15.775 10.5527 15.775H11.7198C12.2556 15.775 12.6088 15.3097 12.6088 14.8452V13.1962H13.1649V14.8452C13.1649 15.3097 13.5182 15.775 14.054 15.775H15.2211C15.7569 15.775 16.1101 15.3097 16.1101 14.8452V12.3366H16.2715C16.6199 12.3366 16.8462 12.0932 16.9264 11.8614C17.006 11.6312 16.9771 11.3252 16.7493 11.105L13.5002 7.87187Z"
        fill={color}
        stroke="white"
      />
      <path
        d="M10.6981 21C16.2417 21 20.803 16.8573 21.3476 11.55C21.406 10.9868 20.9489 10.5 20.375 10.5C19.879 10.5 19.4608 10.8627 19.4122 11.34C18.9843 15.6927 15.2399 19.0909 10.6981 19.0909C7.66374 19.0909 4.98921 17.5732 3.4234 15.2727H4.86278C5.39768 15.2727 5.83533 14.8432 5.83533 14.3182C5.83533 13.7932 5.39768 13.3636 4.86278 13.3636H0.972556C0.43765 13.3636 0 13.7932 0 14.3182V18.1364C0 18.6614 0.43765 19.0909 0.972556 19.0909C1.50746 19.0909 1.94511 18.6614 1.94511 18.1364V16.5423C3.8805 19.2341 7.08021 21 10.6981 21ZM10.6981 4.75176e-07C5.15455 4.75176e-07 0.593259 4.14273 0.0486277 9.45C-4.70997e-08 10.0132 0.447376 10.5 1.02118 10.5C1.51719 10.5 1.93539 10.1373 1.98401 9.66C2.41194 5.30727 6.15628 1.90909 10.6981 1.90909C13.7325 1.90909 16.407 3.42682 17.9728 5.72727H16.5334C15.9985 5.72727 15.5609 6.15682 15.5609 6.68182C15.5609 7.20682 15.9985 7.63636 16.5334 7.63636H20.4237C20.9586 7.63636 21.3962 7.20682 21.3962 6.68182V2.86364C21.3962 2.33864 20.9586 1.90909 20.4237 1.90909C19.8888 1.90909 19.4511 2.33864 19.4511 2.86364V4.45773C18.4634 3.08012 17.1525 1.95594 15.6291 1.1801C14.1057 0.404247 12.4144 -0.000506235 10.6981 4.75176e-07Z"
        fill={color}
      />
    </svg>
  );
}

export default ChangeAccountSVG;
