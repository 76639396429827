import React, { FC } from 'react';
import { ContentRow, Text } from '../styles';

const IsPaid: FC = () => {
  return (
    <>
      <ContentRow>
        <Text>Situação atual:</Text>
        <Text>Quitada</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Tipo de negociação:</Text>
        <Text>À vista</Text>
      </ContentRow>
      <ContentRow>
        <Text>Valor total com desconto:</Text>
        <Text>R$ 254,87</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data da negociação:</Text>
        <Text>03/02/2024</Text>
      </ContentRow>
      <ContentRow>
        <Text>Matrícula de origem:</Text>
        <Text>12345-6</Text>
      </ContentRow>
      <ContentRow isWhite>
        <Text>Data situação:</Text>
        <Text>02/04/2022 17:48:59</Text>
      </ContentRow>
    </>
  );
};

export default IsPaid;
