import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { Breakpoints } from '~/styles';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import ModalError from './components/ModalError';
import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';
import { clearPropertyRegistrationRequest } from '~/store/modules/propertyRegistration/actions';
import { RouteName } from '~/routes/Routes.name';

import StatusLine from './components/StatusLine';
import {
  ContainerBody,
  ContainerTitle,
  ContainerViability,
  Header,
  Main,
  SeparatorTitle,
  TextTutorial,
  Title,
} from './styles';

import steps from './steps.json';
import ProcessIdentification from './steps/ProcessIdentification';
import ProjectandLicensingInformation from './steps/ProjectandLicensingInformation';
import DocumentationApproval from './steps/DocumentationApproval';
import MaterialInspection from './steps/MaterialInspection';
import ConnectionRequest from './steps/ConnectionRequest';
import SettleIn from './steps/SettleIn';

const PropertyRegistration: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const errorValidate = useSelector(
    (item: IState) => item.propertyRegistration.erroValidate,
  );
  const errorCreate = useSelector(
    (item: IState) => item.propertyRegistration.erroData,
  );
  const dataItem = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const loading = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );
  const dispatch = useDispatch();
  const [actualStep, setActualStep] = useState<1 | 2 | 3 | 4 | 5 | 6>(
    dataItem?.step?.number || 1,
  );

  const stepsContent = {
    1: <ProcessIdentification />,
    2: <ProjectandLicensingInformation />,
    3: <DocumentationApproval />,
    4: <MaterialInspection />,
    5: <ConnectionRequest />,
    6: <SettleIn />,
  };

  useEffect(() => {
    if (dataItem?.step.number) {
      setActualStep(dataItem?.step.number);
    }
  }, [dataItem?.step.number]);

  useEffect(
    () => () => {
      dispatch(clearPropertyRegistrationRequest());
    },
    [],
  );

  return (
    <>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Main>
        <Modal
          open={
            !!(
              (errorValidate &&
                (errorValidate.status === 500 || errorValidate?.status === 502)) ||
              (errorCreate &&
                (errorCreate.status === 500 || errorCreate?.status === 502))
            )
          }
          type="error"
          title="Ops!"
        >
          <ModalError />
        </Modal>
        <ContainerViability>
          <Header>Ficha cadastral predial (FCP)</Header>
          {!isMobile && (
            <>
              <Margin height={22} />
              <TextTutorial>
                A FCP é o processo gerado para regularização do sistema interno de
                abastecimento e esgotamento, o qual busca certificar que o
                empreendimento esta sendo abastecido e esgotado seguindo a Norma
                Brasileira – NBR. Para este processo você necessita de uma <b>DPA</b>{' '}
                e uma <b>DPE</b>, caso não possua,{' '}
                <Link to={RouteName.CPAESTEPONE}>solicite aqui.</Link>
                <br />
                <b>Abastecimento</b>: para empreendimentos que não possuam vias
                internas, o processo busca calcular o ramal necessário para atender a
                vazão solicitada e verificar a reservação adotada. Já para os que
                possuem, busca-se regulamentar e aprovar o projeto executivo de
                distribuição de água.
                <br />
                <b>Esgotamento</b>: Para empreendimentos que não possuam vias
                internas, o processo busca fiscalizar o projeto das caixas internas.
                Já para os que possuem, busca-se regulamentar e aprovar o projeto
                executivo de esgotamento sanitário.
              </TextTutorial>
              <Margin height={40} />
              <ContainerTitle>
                <SeparatorTitle />
                <Title>{steps[actualStep - 1]?.title || steps[0]?.title}</Title>
              </ContainerTitle>
              <Margin height={50} />
            </>
          )}
          <ContainerBody>
            {isMobile && (
              <>
                <TextTutorial>
                  A FCP é o processo gerado para regularização do sistema interno de
                  abastecimento e esgotamento, o qual busca certificar que o
                  empreendimento esta sendo abastecido e esgotado seguindo a Norma
                  Brasileira – NBR. Para este processo você necessita de uma{' '}
                  <b>DPA</b> e uma <b>DPE</b>, caso não possua,{' '}
                  <a href="/">solicite aqui.</a>
                  <br />
                  <b>Abastecimento</b>: para empreendimentos que não possuam vias
                  internas, o processo busca calcular o ramal necessário para atender
                  a vazão solicitada E VERIFICAR A RESERVAÇÃO ADOTADA. Já para os que
                  possuem, busca-se regulamentar e aprovar o projeto executivo de
                  distribuição de água.
                  <br />
                  <b>Esgotamento</b>: Para empreendimentos que não possuam vias
                  internas, o processo busca fiscalizar o projeto das caixas
                  internas. Já para os que possuem, busca-se regulamentar e aprovar o
                  projeto executivo de esgotamento sanitário.
                </TextTutorial>
                <Margin height={22} />
                <ContainerTitle>
                  <SeparatorTitle />
                  <Title>{steps[actualStep - 1]?.label || steps[0]?.label}</Title>
                </ContainerTitle>
                <Margin height={35} />
              </>
            )}
            <StatusLine actualStep={actualStep} />
            <Margin height={50} />
            {stepsContent[actualStep]}
          </ContainerBody>
        </ContainerViability>
      </Main>
    </>
  );
};

export default PropertyRegistration;
