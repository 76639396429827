import React, {
  InputHTMLAttributes,
  HTMLInputTypeAttribute,
  CSSProperties,
} from 'react';

import {
  ContainerInputStyled,
  InputStyled,
  LabelStyled,
  ContainerIconStyled,
  LabelRow,
  LabelError,
  ContainerTextStyled,
} from './styles';

import InvisiblePassword from '~/assets/Icons/InvisiblePassword';
import VisiblePasswordSVG from '~/assets/Icons/VisiblePassword';
import EditSVG from '~/assets/Icons/editIcon';
import { Margin } from '../Margin';

interface Props extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  name?: string;
  value?: string;
  label?: string;
  type?: string;
  onChange?: (ev: any) => void;
  iconPassword?: boolean;
  isRevision?: boolean;
  error?: boolean;
  errorText?: string;
  passwordVisible?: boolean;
  setPasswordVisible?: (statusVisible: boolean) => void;
  editIcon?: boolean;
  maxLength?: number;
  payment?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: any;
  styleLabelError?: CSSProperties;
  styleLabel?: CSSProperties;
  onBlur?: (ev: any) => void;
  refEl?: React.MutableRefObject<any>;
  disabled?: boolean;
  isNewHeigthLabelInput?: boolean;
  errorPosition?: 'top' | 'bottom';
  autoFocus?: boolean;
  labelErroWeigth?: number;
  marginLabelError?: number;
  className?: string;
  textInsideInput?: string;
  rigthTextInsideInput?: string;
  colorTextInsideInput?: string;
}

export const Input: React.FC<Props> = ({
  value,
  label,
  type,
  onChange,
  iconPassword,
  isRevision,
  error,
  errorText,
  passwordVisible,
  setPasswordVisible,
  editIcon,
  maxLength,
  payment,
  placeholder,
  style,
  styleInput,
  name,
  onBlur,
  refEl,
  disabled,
  marginLabelError,
  isNewHeigthLabelInput,
  errorPosition = 'top',
  autoFocus,
  className,
  styleLabelError,
  textInsideInput,
  rigthTextInsideInput,
  colorTextInsideInput,
  styleLabel,
  ...props
}) => {
  return (
    <ContainerInputStyled style={styleInput}>
      <LabelRow>
        {label && (
          <LabelStyled style={styleLabel} payment={payment}>
            {label}
          </LabelStyled>
        )}
      </LabelRow>
      {isNewHeigthLabelInput && <Margin height={4} />}

      {error && errorPosition === 'top' && (
        <LabelError style={styleLabelError}>{errorText}</LabelError>
      )}
      <InputStyled
        name={name}
        value={value}
        type={type}
        isRevision={isRevision}
        error={error}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        payment={payment}
        autoComplete="off"
        ref={refEl}
        disabled={disabled}
        style={style}
        inputMode={props.inputMode}
        autoFocus={autoFocus}
        min={props.min}
        max={props.max}
        className={className}
      />
      {error && errorPosition === 'bottom' && (
        <LabelError
          style={{
            marginTop: marginLabelError || undefined,
            ...styleLabelError,
          }}
        >
          {errorText}
        </LabelError>
      )}
      {iconPassword && (
        <ContainerIconStyled
          error={!!(error && errorText)}
          errorPosition={errorPosition}
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          {passwordVisible ? <VisiblePasswordSVG /> : <InvisiblePassword />}
        </ContainerIconStyled>
      )}
      {editIcon && (
        <ContainerIconStyled
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          <EditSVG />
        </ContainerIconStyled>
      )}
      {!!textInsideInput && (
        <ContainerTextStyled
          rigth={rigthTextInsideInput}
          color={colorTextInsideInput}
          error={error}
          errorPosition={errorPosition}
        >
          {textInsideInput}
        </ContainerTextStyled>
      )}
    </ContainerInputStyled>
  );
};
