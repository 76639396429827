import React, { FC } from 'react';
import WarningAlertSVG from '~/assets/Icons/WarningAlert';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { socialTariffStatus } from '~/utils/socialTariffStatus';
import { Container } from './styles';

interface IProps {
  status: string;
}

const SocialTariffStatus: FC<IProps> = ({ status }) => {
  return (
    <Container backgroundColor={socialTariffStatus(status).color}>
      <div>
        <WarningAlertSVG />
        <Margin width={24} />
        <p>{socialTariffStatus(status).label}</p>
      </div>
      <ButtonText
        text={
          socialTariffStatus(status).label ===
          'Atenção! Solicitação expirada. Para dar continuidade ao benefício é necessário recadastramento!'
            ? 'Solicitar recadastramento'
            : 'Ver solicitação'
        }
        typeBtn="content"
        Color={Color.white}
        backgroundColor={Color.green}
        onClick={() => {
          if (
            socialTariffStatus(status).label ===
            'Atenção! Solicitação expirada. Para dar continuidade ao benefício é necessário recadastramento!'
          ) {
            Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION);
          } else {
            Navigation.navigate(RouteName.SOCIALTARIFFREQUEST);
          }
        }}
      />
    </Container>
  );
};

export default SocialTariffStatus;
