import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
  v3?: boolean;
}

const AutomaticDebitSVG = ({
  color = Color.green,
  width = 29,
  height = 25,
  v3 = false,
}: IProp) => {
  return v3 ? (
    <svg
      width="14142"
      height="12124"
      viewBox="0 0 14142 12124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10432 12124C12481 12124 14142 10463 14142 8414C14142 6365.02 12481 4704 10432 4704C8383.02 4704 6722 6365.02 6722 8414C6722 10463 8383.02 12124 10432 12124ZM10118 6380C10297.5 6380 10443 6525.51 10443 6705V8414H11798C11977.5 8414 12123 8559.51 12123 8739C12123 8918.49 11977.5 9064 11798 9064H10118C9960.94 9064 9829.91 8952.6 9799.6 8804.5C9795.27 8783.34 9793 8761.44 9793 8739V8738.76V6705C9793 6525.51 9938.51 6380 10118 6380Z"
        fill="#004B55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2200 1000H11243C11905.7 1000 12443 1537.26 12443 2200V3024H1000V2200C1000 1537.26 1537.26 1000 2200 1000ZM1000 4024V7207C1000 7869.74 1537.26 8407 2200 8407H6095.06C6096.78 8751.38 6138.89 9086.21 6216.89 9407H2200C984.974 9407 0 8422.03 0 7207V2200C0 984.974 984.974 0 2200 0H11243C12458 0 13443 984.974 13443 2200V5327.77C13148.4 5035.05 12811.8 4784.62 12443 4586.33V4024H1000Z"
        fill="#004B55"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4881 0C3.29778 0 2.15621 0.475569 1.31453 1.32209C0.472851 2.16861 0 3.31673 0 4.51389V14.9306C0 16.1277 0.472851 17.2758 1.31453 18.1224C2.15621 18.9689 3.29778 19.4444 4.4881 19.4444H12.6689C12.5088 18.7617 12.4282 18.0626 12.4286 17.3611H4.4881C3.84716 17.3611 3.23247 17.105 2.77925 16.6492C2.32604 16.1934 2.07143 15.5752 2.07143 14.9306V8.33333H27.619V4.51389C27.619 3.92112 27.503 3.33415 27.2774 2.7865C27.0519 2.23885 26.7213 1.74124 26.3045 1.32209C25.8878 0.902934 25.393 0.570444 24.8485 0.3436C24.304 0.116755 23.7203 0 23.131 0H4.4881ZM25.5476 4.51389V6.25H2.07143V4.51389C2.07143 3.17222 3.1541 2.08333 4.4881 2.08333H23.131C24.465 2.08333 25.5476 3.17222 25.5476 4.51389ZM21.4048 9.72222C23.4191 9.72222 25.351 10.527 26.7754 11.9596C28.1998 13.3922 29 15.3352 29 17.3611C29 19.3871 28.1998 21.3301 26.7754 22.7626C25.351 24.1952 23.4191 25 21.4048 25C19.3904 25 17.4585 24.1952 16.0341 22.7626C14.6097 21.3301 13.8095 19.3871 13.8095 17.3611C13.8095 15.3352 14.6097 13.3922 16.0341 11.9596C17.4585 10.527 19.3904 9.72222 21.4048 9.72222ZM24.1667 17.3611H21.4048V13.8889C21.4048 13.7047 21.332 13.5281 21.2025 13.3978C21.073 13.2676 20.8974 13.1944 20.7143 13.1944C20.5312 13.1944 20.3555 13.2676 20.226 13.3978C20.0966 13.5281 20.0238 13.7047 20.0238 13.8889V18.0556C20.0238 18.2397 20.0966 18.4164 20.226 18.5466C20.3555 18.6768 20.5312 18.75 20.7143 18.75H24.1667C24.3498 18.75 24.5254 18.6768 24.6549 18.5466C24.7844 18.4164 24.8571 18.2397 24.8571 18.0556C24.8571 17.8714 24.7844 17.6947 24.6549 17.5645C24.5254 17.4343 24.3498 17.3611 24.1667 17.3611Z"
        fill={color}
      />
    </svg>
  );
};

export default AutomaticDebitSVG;
