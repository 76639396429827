import styled from 'styled-components';

import { Color } from '~/styles/colors';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 560px;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    :first-of-type {
      min-height: inherit;
    }
  }
`;

export const DescriptionTextStyled = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${Color.gray};

  b {
    font-weight: 700;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
