import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 26px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 38px 35px 203px 35px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 160px);
    padding: 55px 80px 241px 80px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 0 40px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  padding: 44px 16px 64px 16px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: center;
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 44px;

    b {
      font-weight: 700;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      color: ${Color.blueSeconde};
      font-weight: 500;
    }
  }

  .label {
    width: 100%;
    text-align: left;
    color: ${Color.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(700px - 160px);
    padding: 44px 80px 64px 80px;

    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(1010px - 160px);
    max-width: calc(1010px - 160px);
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 35px);
  display: flex;
  align-items: center;
  margin-bottom: 38px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.blueIntense};
    text-align: left;
    background: ${Color.white};
    padding: 0 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);

    h2 {
      position: absolute;
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const ContentInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 40px;

    div {
      max-width: 406px;
    }
  }
`;

export const DescriptionTypeImmobile = styled.div`
  height: 100%;
  color: ${Color.blueIntense};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-content: center;
  margin: auto;
`;

export const RowInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 40px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      max-width: 406px;
    }
  }
`;

export const BoxPropertyDetails = styled.div`
  width: calc(100% - 64px);
  background: #f2f2f2;
  border: 2px solid #37b4c8;
  border-radius: 8px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  input {
    color: #27251f !important;
    font-weight: 400 !important;
  }

  input,
  select {
    background-color: #fff;
    font-size: 16px;

    :disabled {
      background-color: #f2f2f2;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    :first-of-type {
      order: 2;
      svg {
        left: 16px;
      }
    }

    :last-of-type {
      order: 1;
      svg {
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 0;

    button {
      max-width: 252px;

      :first-of-type {
        order: 1;
      }

      :last-of-type {
        order: 2;
      }
    }
  }
`;

export const ButtonChat = styled.button`
  cursor: pointer;
  background: none;
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 500;
  line-height: 122.5%;
  letter-spacing: -0.35px;
  text-decoration-line: underline;
`;

export const ContainerDocuments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RowDocument = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  :last-of-type {
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const SenFiles = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    max-width: 335px;
  }
`;

export const Label = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 12px;
  color: ${Color.gray};
  font-style: italic;

  a {
    cursor: pointer;
    color: ${Color.blueSeconde};
    text-decoration: underline;
    font-weight: 500;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
    margin-top: 0;
    margin-left: 24px;
  }
`;

export const BoxAccepteds = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Color.grayLightSeconde};
  margin-top: 24px;
`;

export const AcceptedWarning = styled.p`
  color: ${Color.grayDark};
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
`;

export const ContainerTerms = styled.div`
  width: 100%;
  display: flex;

  .term {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 122.5%;
    color: ${Color.blueIntense};
    display: inline-block;
    letter-spacing: -0.49px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
      color: ${Color.blueSeconde};
      text-align: left;
    }
  }
`;
