import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 24px;
  border-top: 24px solid var(--azul-gua, #37b4c8);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 32px;
    height: 32px;
    margin-right: 24px;
  }

  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: ${Color.blueIntense};
  }

  p {
    max-width: 240px;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.5px;
    text-align: center;
    color: ${Color.gray};
    margin-top: 16px;

    strong {
      color: ${Color.blueIntense};
      font-weight: 600;
    }

    :last-of-type {
      font-size: 11px;
    }
  }

  #create-alert {
    width: 100%;
    max-width: 240px;
    height: 48px;
    padding: 4px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid ${Color.green};
    background: ${Color.blueSeconde};
    color: ${Color.white};
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
    cursor: pointer;
  }

  @media (min-width: 1340px) {
    width: 100%;
    display: inherit;
    padding: 0;

    svg {
      width: 54px;
      height: 54px;
      margin-bottom: 16px;
      margin-right: 0;
    }

    .header {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const AlertForm = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
`;

export const AlertInput = styled.input`
  width: calc(97px - 42px);
  height: calc(29px - 14px);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${Color.gray};
  border-radius: 4px;
  border: 1px solid ${Color.gray};
  padding: 7px 35px 7px 7px;
  outline: none;

  &:focus {
    border: 1px solid ${Color.gray};
  }
`;

export const AlertBtn = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${Color.gray};
  border-radius: 100%;
  background: transparent;
  color: ${Color.gray};
  font-size: 24px;
  cursor: pointer;
`;

export const AlertInputWrapper = styled.div`
  position: relative;
  display: flex;
  > * {
    &:first-child {
      flex-grow: 1;
    }
  }
`;

export const AlertInputLabel = styled.label`
  color: ${Color.gray};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  opacity: 0.7;
  margin-right: 12px;
  position: absolute;
  top: 5px;
  right: 0;
`;
