import React, { ReactNode } from 'react';

import { Main } from './styles';

interface IProps {
  children: ReactNode;
}
const WarningBox: React.FC<IProps> = ({ children }) => {
  return (
    <Main>
      <div>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.387939" width="44" height="43.2242" rx="8" fill="#FAB419" />
          <path
            d="M22 17.8031V23.5569M22.5754 27.0091C22.5754 27.3269 22.3178 27.5845 22 27.5845C21.6822 27.5845 21.4246 27.3269 21.4246 27.0091C21.4246 26.6914 21.6822 26.4338 22 26.4338C22.3178 26.4338 22.5754 26.6914 22.5754 27.0091ZM14.3046 31.6121H29.6954C31.4273 31.6121 32.5386 29.7713 31.732 28.2387L24.0366 13.6175C23.1738 11.9781 20.8262 11.9781 19.9634 13.6175L12.268 28.2387C11.4614 29.7713 12.5727 31.6121 14.3046 31.6121Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div>
        <p>Atenção</p>
        <p>{children}</p>
      </div>
    </Main>
  );
};

export default WarningBox;
