import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 14px;
  flex: 1;
  border: 2px solid ${Color.yellow};
  background-color: #f2f2f2;
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  text-align: center;

  div:last-of-type {
    margin-top: 16px;

    p {
      color: #000000;
    }

    p:first-of-type {
      color: ${Color.yellow};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 122.5%;
      letter-spacing: -0.4px;
    }

    p:last-of-type {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 122.5%;
      letter-spacing: -0.35px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 18px 24px;
    text-align: left;

    div:last-of-type {
      margin-top: 0;
      margin-left: 24px;
    }
  }
`;
