import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { SuccessIcon } from '~/components/ModalCopyDataUpdate/Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import moment from 'moment';

interface IProps {
  onClose: () => void;
}

const ModalSuccess: FC<IProps> = ({ onClose }) => {
  const data = useSelector((item: IState) => item.outsides.dataSocialTariff);

  const dataSocialTariffValidEnrollment = useSelector(
    (item: IState) => item.outsides.dataSocialTariffValidEnrollment,
  );

  return (
    <Main>
      <SuccessIcon />
      <h1>Solicitação realizada com sucesso!</h1>
      {dataSocialTariffValidEnrollment?.typeSolicitation === 'default' ? (
        <p>
          Sua solicitação foi enviada.
          <br />
          <br />
          Número do protocolo <b>{data?.protocol}</b> <br />
          {data?.expectedDate && (
            <>
              Prazo para conclusão{' '}
              <b>{moment(data?.expectedDate).format('DD/MM/YYYY')}</b> <br />
            </>
          )}
          <br />
          Para acompanhar o progresso, faça{' '}
          <button
            type="button"
            onClick={() => {
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Login`,
              });
              Navigation.navigate(RouteName.LOGIN);
            }}
          >
            Login
          </button>{' '}
          ou Fale conosco.
        </p>
      ) : (
        <p>
          Sua solicitação foi enviada.
          <br />
          Números de protocolo:
          <br />
          <br />
          Tarifa social: <b>#{data?.protocol}</b>
          <br />
          {dataSocialTariffValidEnrollment?.typeSolicitation ===
          'changeOwnership' ? (
            <>
              Troca de titularidade: <b>#{data?.protocolExchange}</b>
            </>
          ) : (
            <>
              Recadastramento: <b>#{data?.protocolReRegistration}</b>
            </>
          )}
          {data?.expectedDate && (
            <>
              <br />
              Prazo para conclusão{' '}
              <b>{moment(data?.expectedDate).format('DD/MM/YYYY')}</b> <br />
            </>
          )}
          <br />
          <br />
          Para acompanhar o progresso, faça{' '}
          <button
            type="button"
            onClick={() => {
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Login`,
              });
              Navigation.navigate(RouteName.LOGIN);
            }}
          >
            Login
          </button>{' '}
          ou Fale conosco.
        </p>
      )}
      <ButtonText
        id="ok-modal-sucesso"
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="Ok"
        onClick={() => {
          ReactGA.event({
            category: 'Tarifa Social',
            action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Botão OK`,
          });
          onClose();
        }}
      />
    </Main>
  );
};

export default ModalSuccess;
