import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsContainer {
  isSubscribeTrial?: boolean;
}

export const Container = styled.div<IPropsContainer>`
  min-height: 200px;
  max-height: calc(
    ${({ isSubscribeTrial }) => (isSubscribeTrial ? '410px' : '270px')} - 48px
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 24px;
  background: ${({ isSubscribeTrial }) =>
    isSubscribeTrial ? Color.green : Color.blueSeconde};
  padding: 24px 40px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-height: calc(
      ${({ isSubscribeTrial }) => (isSubscribeTrial ? '297px' : '270px')} - 48px
    );
  }

  p {
    color: ${Color.white};
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    &.description {
      font-size: 12px;
      line-height: 161.4%;
      letter-spacing: -0.06px;
    }
  }

  button {
    width: 100%;
    height: 48px;
    cursor: pointer;
    border-radius: 8px;
    background: ${Color.green};
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin-top: 24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
  margin: 16px 0;
  position: relative;

  svg {
    position: absolute;
    right: -45px;
    top: -10px;
  }

  p {
    color: ${Color.greenDark};
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    right: -25px;
    top: -5px;
  }
`;
