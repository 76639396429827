/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import ConsumeSVG from '~/assets/Icons/consume';
import CalendarSVG from '~/assets/Icons/calendar';
import { Color } from '~/styles';

import {
  CardIColumn,
  CardInvoHeader,
  CardInvoiceBody,
  CardInvoiceTitle,
  CardInvoiceTitleText,
  CardIValueText,
  CCol,
  Container,
  ContainerCard,
  CRow,
  PayText,
} from '../CardInvoice/styles';
import SkeletonCustom from '~/components/Skeleton';
import { Margin } from '~/components/Margin';
import ReloadSVG from '~/assets/Icons/reload';

export default function SkeletonCardInvoice() {
  return (
    <Container>
      <ContainerCard>
        <CardInvoiceTitle
          isLoading={false}
          style={{
            backgroundColor: Color.gray,
          }}
        >
          <CardInvoiceTitleText>Sem faturas</CardInvoiceTitleText>
        </CardInvoiceTitle>
        <CardInvoHeader>
          <CardIColumn />
        </CardInvoHeader>
        <CardInvoiceBody>
          <CardIColumn style={{ justifyContent: 'flex-start' }}>
            <div>
              <CardIValueText
                isLoading={false}
                style={{
                  color: Color.gray,
                }}
                className="value"
              >
                <SkeletonCustom width={100} height={20} />
              </CardIValueText>
              <CRow>
                <ConsumeSVG />
                <CCol>
                  <span>Consumo:</span>
                  <span className="value">
                    <SkeletonCustom width={70} height={15} />
                  </span>
                </CCol>
              </CRow>
              <CRow>
                <CalendarSVG />
                <CCol>
                  <span>Vencimento:</span>
                  <span className="value">
                    {' '}
                    <SkeletonCustom width={70} height={15} />
                  </span>
                </CCol>
              </CRow>
            </div>
          </CardIColumn>
          <CardIColumn style={{ justifyContent: 'flex-start' }}>
            <PayText>AGUARDE!</PayText>
            <Margin height={50} />
            <ReloadSVG width="61" height="61" />
            <Margin height={42} />
          </CardIColumn>
        </CardInvoiceBody>
      </ContainerCard>
    </Container>
  );
}
