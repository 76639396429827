import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0 auto;
  padding: 18px 0 134px 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1170px;
    width: 100%;
    padding: 0 0 64px 0;
    margin: 0 auto;
  }
`;
