import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
}

const WarningAlertSVG = ({
  color = Color.white,
  width = 32,
  height = 33,
}: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16364 0 0 7.31937 0 16.3478C0 25.3763 7.16364 32.6957 16 32.6957C24.8364 32.6957 32 25.3763 32 16.3478C32 7.31937 24.8364 0 16 0ZM17.4545 8.917C17.4545 8.52284 17.3013 8.14483 17.0285 7.86612C16.7557 7.58741 16.3858 7.43083 16 7.43083C15.6142 7.43083 15.2443 7.58741 14.9715 7.86612C14.6987 8.14483 14.5455 8.52284 14.5455 8.917V17.834C14.5455 18.2281 14.6987 18.6062 14.9715 18.8849C15.2443 19.1636 15.6142 19.3202 16 19.3202C16.3858 19.3202 16.7557 19.1636 17.0285 18.8849C17.3013 18.6062 17.4545 18.2281 17.4545 17.834V8.917ZM17.4545 23.0356C17.4545 22.6414 17.3013 22.2634 17.0285 21.9847C16.7557 21.706 16.3858 21.5494 16 21.5494C15.6142 21.5494 15.2443 21.706 14.9715 21.9847C14.6987 22.2634 14.5455 22.6414 14.5455 23.0356V23.7787C14.5455 24.1728 14.6987 24.5508 14.9715 24.8295C15.2443 25.1082 15.6142 25.2648 16 25.2648C16.3858 25.2648 16.7557 25.1082 17.0285 24.8295C17.3013 24.5508 17.4545 24.1728 17.4545 23.7787V23.0356Z"
        fill={color}
      />
    </svg>
  );
};

export default WarningAlertSVG;
