import styled from 'styled-components';
import { Color } from '~/styles';

export const Main = styled.main`
  padding: 56px 24px;
`;

export const NotificationsWrapper = styled.div`
  max-width: 520px;
`;

// Start Notification

export const Notification = styled.div`
  padding: 12px 0px;
  background-color: ${Color.blueSeconde}15;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${Color.gray};
`;

export const NotificationStatus = styled.div`
  padding: 0px 16px;
  font-size: 12px;
`;

export const NotificationMessage = styled.div`
  padding: 0px 4px;
  font-size: 15px;
`;

export const NotificationDate = styled.div`
  padding: 0px 16px;
  font-size: 12px;
`;

// End Notification
