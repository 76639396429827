import React from 'react';
import './stylesLogo.css';

function LoginSVG() {
  return (
    <svg
      width="27"
      height="32"
      viewBox="0 0 27 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7.27273C20 8.92625 19.3229 10.5185 18.1072 11.6974C16.8904 12.8773 15.2337 13.5455 13.5 13.5455C11.7663 13.5455 10.1096 12.8773 8.89285 11.6974C7.6771 10.5185 7 8.92625 7 7.27273C7 5.6192 7.6771 4.02694 8.89285 2.84803C10.1096 1.66811 11.7663 1 13.5 1C15.2337 1 16.8904 1.66811 18.1072 2.84803C19.3229 4.02694 20 5.6192 20 7.27273ZM26 22.9091C26 24.871 25.1721 26.852 23.2475 28.3661C21.3061 29.8933 18.1735 31 13.5 31C8.82557 31 5.69309 29.8972 3.75205 28.3722C1.8284 26.8608 1 24.8804 1 22.9091V22.6095C1 20.3407 2.90393 18.4545 5.319 18.4545H21.681C24.0983 18.4545 26 20.3429 26 22.6095V22.9091Z"
        stroke="#37B4C8"
        strokeWidth="2"
      />
    </svg>
  );
}
export default LoginSVG;
