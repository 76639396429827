import React from 'react';

const SubscribesInfoIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="48" height="48" rx="8" fill="#37B482" />
      <path
        d="M17.8 23.8L14 34.5L24.7 30.71M16 15.5H16.01M34 20.5H34.01M27 14.5H27.01M34 32.5H34.01M34 14.5L31.76 15.25C31.1224 15.4624 30.5783 15.8896 30.2208 16.4587C29.8633 17.0278 29.7146 17.7034 29.8 18.37C29.9 19.23 29.23 20 28.35 20H27.97C27.11 20 26.37 20.6 26.21 21.44L26 22.5M34 25.5L33.18 25.17C32.32 24.83 31.36 25.37 31.2 26.28C31.09 26.98 30.48 27.5 29.77 27.5H29M23 14.5L23.33 15.32C23.67 16.18 23.13 17.14 22.22 17.3C21.52 17.4 21 18.02 21 18.73V19.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0005 25.4966C24.9305 27.4266 25.8305 29.6666 25.0005 30.4966C24.1705 31.3266 21.9305 30.4266 20.0005 28.4966C18.0705 26.5666 17.1705 24.3266 18.0005 23.4966C18.8305 22.6666 21.0705 23.5666 23.0005 25.4966Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SubscribesInfoIcon };
