import React from 'react';

const SocialNetworkYoutubeSVG: React.FC = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8072 5.29923C29.8072 2.48681 27.8675 0.224369 25.4708 0.224369C22.2243 0.0624981 18.9133 0 15.5297 0H14.4748C11.0994 0 7.78259 0.0624982 4.53608 0.224994C2.14516 0.224994 0.20546 2.49993 0.20546 5.31236C0.0589575 7.53667 -0.00315971 9.76161 0.000356358 11.9865C-0.00550376 14.2115 0.0609109 16.4385 0.1996 18.6676C0.1996 21.48 2.1393 23.7612 4.53022 23.7612C7.94081 23.93 11.4393 24.005 14.9964 23.9987C18.5593 24.0112 22.0481 23.9321 25.4626 23.7612C27.8593 23.7612 29.799 21.48 29.799 18.6676C29.9397 16.4364 30.0041 14.2115 29.9983 11.9803C30.0116 9.75536 29.9479 7.52834 29.8072 5.29923ZM12.1308 18.1176V5.83672L20.628 11.974L12.1308 18.1176Z"
        fill="#999999"
      />
    </svg>
  );
};

export default SocialNetworkYoutubeSVG;
