import { Reducer } from 'redux';

import {
  RepositoriesTypesInvoices,
  IRepositoriesStateEnrollmentsInvoices,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsInvoices = {
  data: null,
  dataDetail: null,
  dataMaturityDays: null,
  dataMaturityDaysAlternate: [],
  dataTypesInvoices: null,
  dataInvoiceGroupId: null,
  dataInvoiceInstallmentGroup: null,
  dataInvoiceGroupVoucher: null,
  loading: false,
  loadingDetail: false,
  loadingDownload: false,
  loadingMaturityDays: false,
  loadingMaturityDaysAlternate: false,
  loadingPutMaturityDaysAlternate: false,
  loadingTypesInvoices: false,
  loadingPutTypesInvoices: false,
  loadingInvoiceGroupId: false,
  loadingInstallmentGroup: false,
  loadingPaymentGroup: false,
  loadingPaymentGroupVoucher: false,
  error: null,
  errorDownload: null,
  errorMaturityDays: null,
  errorMaturityDaysAlternate: null,
  errorPutMaturityDaysAlternate: null,
  errorTypesInvoices: null,
  errorPutTypesInvoices: null,
  errorInvoiceGroupId: null,
  errorInstallmentGroup: null,
  errorPaymentGroup: null,
  errorPaymentGroupVoucher: null,
  successPutMaturityDaysAlternate: false,
  successPutTypesInvoices: false,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsInvoices, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_SUCCESS:
      return {
        ...state,
        data: action?.payload?.data ? { ...action.payload.data } : { ...state.data },
        loading: false,
        error: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICES_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_REQUEST:
      return {
        ...state,
        loadingDetail: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_SUCCESS:
      return {
        ...state,
        dataDetail: action.payload.data,
        loadingDetail: false,
        error: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DETAIL_INVOICE_METHOD_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingDetail: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_REQUEST:
      return {
        ...state,
        loadingDownload: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_SUCCESS:
      return {
        ...state,
        loadingDownload: false,
        errorDownload: null,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE:
      return {
        ...state,
        errorDownload: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingDownload: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_DOWNLOAD_INVOICE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDownload: null,
        loadingDownload: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_REQUEST:
      return {
        ...state,
        loadingMaturityDays: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_SUCCESS:
      return {
        ...state,
        dataMaturityDays: {
          ...action.payload.data,
        },
        loadingMaturityDays: false,
        errorMaturityDays: null,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_METHOD_FAILURE:
      return {
        ...state,
        dataMaturityDays: null,
        errorMaturityDays: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingMaturityDays: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST:
      return {
        ...state,
        loadingMaturityDaysAlternate: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS:
      return {
        ...state,
        dataMaturityDaysAlternate: action.payload.data,
        loadingMaturityDaysAlternate: false,
        errorMaturityDaysAlternate: null,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE:
      return {
        ...state,
        dataMaturityDaysAlternate: [],
        errorMaturityDaysAlternate: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingMaturityDaysAlternate: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_REQUEST:
      return {
        ...state,
        loadingPutMaturityDaysAlternate: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS:
      return {
        ...state,
        loadingPutMaturityDaysAlternate: false,
        errorPutMaturityDaysAlternate: null,
        successPutMaturityDaysAlternate: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successPutMaturityDaysAlternate: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE:
      return {
        ...state,
        errorPutMaturityDaysAlternate: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingPutMaturityDaysAlternate: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_MATURITY_DAYS_ALTERNATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPutMaturityDaysAlternate: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_REQUEST:
      return {
        ...state,
        loadingTypesInvoices: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_SUCCESS:
      return {
        ...state,
        dataTypesInvoices: {
          ...action.payload.data,
        },
        loadingTypesInvoices: false,
        errorTypesInvoices: null,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_TYPES_INVOICES_METHOD_FAILURE:
      return {
        ...state,
        dataTypesInvoices: null,
        errorTypesInvoices: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingTypesInvoices: false,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_REQUEST:
      return {
        ...state,
        loadingPutTypesInvoices: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS:
      return {
        ...state,
        successPutTypesInvoices: true,
        loadingPutTypesInvoices: false,
        errorPutTypesInvoices: null,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successPutTypesInvoices: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE:
      return {
        ...state,
        errorPutTypesInvoices: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingPutTypesInvoices: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_PUT_TYPES_INVOICES_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPutTypesInvoices: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_REQUEST:
      return {
        ...state,
        loadingInvoiceGroupId: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_SUCCESS:
      return {
        ...state,
        loadingInvoiceGroupId: false,
        dataInvoiceGroupId: { ...action.payload.data },
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE:
      return {
        ...state,
        dataInvoiceGroupId: null,
        loadingInvoiceGroupId: false,
        errorInvoiceGroupId: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_CREATE_GROUP_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorInvoiceGroupId: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_REQUEST:
      return {
        ...state,
        loadingInstallmentGroup: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_SUCCESS:
      return {
        ...state,
        loadingInstallmentGroup: false,
        dataInvoiceInstallmentGroup: { ...action.payload.data },
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE:
      return {
        ...state,
        dataInvoiceInstallmentGroup: null,
        loadingInstallmentGroup: false,
        errorInstallmentGroup: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_GET_INVOICE_INSTALLMENT_GROUP_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorInstallmentGroup: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingPaymentGroup: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingPaymentGroup: false,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loadingPaymentGroup: false,
        loadingPaymentGroupVoucher: false,
        errorPaymentGroup: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_PAYMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPaymentGroup: null,
      };

    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_REQUEST:
      return {
        ...state,
        loadingPaymentGroupVoucher: true,
      };
    case RepositoriesTypesInvoices.ENROLLMENTS_POST_INVOICE_GROUP_VOUCHER_METHOD_SUCCESS:
      return {
        ...state,
        loadingPaymentGroupVoucher: false,
        dataInvoiceGroupVoucher: { ...action.payload.data },
      };

    default:
      return state;
  }
};

export default reducer;
