import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Container, Button, ContainerOptions } from './styles';
import Contact from './Contact';
import Acces from './Access';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { updateSuccessCloseEnrollmentsPasswordUsers } from '~/store/modules/enrollmentsUsers/actions';

interface IProps {
  state: { item: string };
}

const Options: React.FC = () => {
  const dispatch = useDispatch();

  const [item, setItem] = useState({
    contact: true,
    acces: false,
  });

  const [succes, setSucces] = useState({ item: '', state: true });
  const succesPassword = useSelector(
    (item: IState) => item.enrollmentsUsers.successUpdatePassword,
  );
  const { state }: IProps = useLocation();

  useEffect(() => {
    if (state?.item === 'password') {
      setItem({
        contact: false,
        acces: true,
      });
      setSucces({ item: 'password', state: succesPassword });
    }
  }, [state]);

  useEffect(() => {
    if (succesPassword) {
      ReactGA.event({
        category: 'Perfil',
        action: 'Sucesso na Alteração de Senha',
      });
    }
  }, [succesPassword]);

  return (
    <Container>
      <ContainerOptions>
        <Button
          onClick={() => {
            dispatch(updateSuccessCloseEnrollmentsPasswordUsers());
            setSucces({ item: 'password', state: false });
            setItem({
              acces: false,
              contact: true,
            });
          }}
          active={item.contact}
        >
          Contato
        </Button>
        <Button
          onClick={() =>
            setItem({
              acces: true,
              contact: false,
            })
          }
          active={item.acces}
        >
          Acesso
        </Button>
      </ContainerOptions>
      {item.contact && <Contact />}
      {item.acces && (
        <Acces succes={succes.item === 'password' ? succes.state : false} />
      )}
    </Container>
  );
};

export default Options;
