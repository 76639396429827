import React, { FC } from 'react';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';
import StatusInfoIconSVG from '~/assets/Icons/StatusInfo';
import StatusSuccessIconSVG from '~/assets/Icons/StatusSuccess';

import { Main } from './styles';
import StatusWaitIconSVG from '~/assets/Icons/StatusWait';

interface IProps {
  status: 'info' | 'wait' | 'warning' | 'success';
  children: React.ReactNode;
  maxWidthButton?: number;
}

const typeStatus = {
  info: {
    icon: <StatusInfoIconSVG />,
    title: 'Lembre-se',
  },
  wait: {
    icon: <StatusWaitIconSVG />,
    title: 'Aguarde',
  },
  warning: {
    icon: <StatusWarningIconSVG />,
    title: 'Atenção',
  },
  success: {
    icon: <StatusSuccessIconSVG />,
    title: 'Documentação aprovada',
  },
};

const StatusInfo: FC<IProps> = ({ status, children, maxWidthButton }) => {
  return (
    <Main status={status} maxWidthButton={maxWidthButton}>
      <div>{typeStatus[status].icon}</div>
      <div>
        <p>{typeStatus[status].title}</p>
        <p>{children}</p>
      </div>
    </Main>
  );
};

export default StatusInfo;
