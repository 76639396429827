import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Container = styled.div`
  width: 100%;
  max-width: 1010px;
  margin-right: auto;
  margin-left: auto;
`;

export const Main = styled.main`
  padding: 56px 24px;
`;

export const Card = styled.div`
  box-shadow: 0px 4px 26px 0px #00000019;
  overflow: hidden;
  border-radius: 16px;
`;

export const CardTitle = styled.div`
  padding: 36px 16px;
  background-color: ${Color.blueSeconde};
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 24px 16px;
    font-size: 19px;
  }
`;

export const CardContent = styled.div``;
