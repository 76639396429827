import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsMain {
  background?: string;
}

export const Main = styled.div<IPropsMain>`
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  display: flex;
  border-radius: 15px;
  box-shadow: cardSmall;
  justify-content: flex-end;
  flex-direction: column;
  background: ${({ background }) => background};

  svg {
    max-width: 100px;
    height: 70px;
  }

  p {
    font-size: 12px;
    color: ${Color.white};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 32px;

    p {
      font-size: 16px;
      color: ${Color.white};
    }
  }
`;
