import styled from 'styled-components/';
import { Color } from '~/styles';

export const Description = styled.div`
  color: ${Color.blueIntense};
  font-weight: 500;
  margin-top: 40px;
  padding: 0 80px;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  a {
    color: ${Color.blueSeconde};
    font-weight: 500;
  }

  & + & {
    margin-top: 16px;
  }
`;
