import React from 'react';

const SocialNetworkFacebookSVG: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.67 0H1.33C0.978071 0.00261668 0.641298 0.143581 0.39244 0.39244C0.143581 0.641298 0.00261668 0.978071 0 1.33V22.67C0.00261668 23.0219 0.143581 23.3587 0.39244 23.6076C0.641298 23.8564 0.978071 23.9974 1.33 24H12.82V14.72H9.7V11.09H12.82V8.42C12.82 5.32 14.71 3.63 17.49 3.63C18.42 3.63 19.35 3.63 20.28 3.77V7H18.37C16.86 7 16.57 7.72 16.57 8.77V11.08H20.17L19.7 14.71H16.57V24H22.67C23.0219 23.9974 23.3587 23.8564 23.6076 23.6076C23.8564 23.3587 23.9974 23.0219 24 22.67V1.33C23.9974 0.978071 23.8564 0.641298 23.6076 0.39244C23.3587 0.143581 23.0219 0.00261668 22.67 0Z"
        fill="#999999"
      />
    </svg>
  );
};

export default SocialNetworkFacebookSVG;
