import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

interface IMethod {
  method: boolean;
}

interface IPageNumber {
  isActive: boolean;
}

export const Main = styled.div`
  padding: 20px 9px 63px 9px;
  width: 100%;
  background: ${COLORS.white};
  box-sizing: border-box;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    padding: 48px 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    padding: 22px 80px 22px 51px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 22px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    gap: 22px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: ${Breakpoints.laptop.max - 1}px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }

  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    max-width: 300px;
  }

  @media screen and (max-width: ${Breakpoints.tablet.min}px) {
    max-width: 480px;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 356px;
  background: ${COLORS.gray400};
  max-height: 356px;
  margin: 0 46px;
`;

interface IPropsContainerCardFull {
  detailInvoice?: boolean;
}

export const ContainerBreadCrumb = styled.div`
  width: 100%;
`;

export const ContainerCardFull = styled.div<IPropsContainerCardFull>`
  width: 100%;
  max-width: ${({ detailInvoice }) => (detailInvoice ? '560px' : '540px')};
  margin: 0 auto;
`;

export const ContainerMessage = styled.div`
  width: 100%;
  max-width: calc(300px - 32px);
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 384px;
    padding: 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
    padding: 0;
  }
`;

export const ContaineInvoices = styled.div`
  width: 100%;
  max-width: calc(300px - 32px);
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 384px;
    padding: 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
    padding: 0;
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .change-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: #e9ecef;
    border-radius: 8px;

    :last-of-type {
      transform: rotate(180deg);
    }
  }
`;

export const ContainerPageNumber = styled.div`
  display: flex;
  max-width: 140px;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 300px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
  }
`;

export const PageNumber = styled.button<IPageNumber>`
  width: 33px;
  height: 32px;
  color: #000;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #004b55;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  /* margin-right: 16px; */
  cursor: pointer;

  /* :last-of-type {
    margin-right: 0;
  } */

  ${({ isActive }) =>
    isActive &&
    css`
      background: #aad7e1;
    `}
`;

export const ButtonPayment = styled.button<IMethod>`
  width: 85px;
  height: 56px;
  box-shadow: 0px 2.72542px 4.08813px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ method }) => (method ? COLORS.gray200 : COLORS.white)};
  cursor: pointer;
`;

export const ContainerButtonBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: fit-content;
    background: transparent;
  }
`;

export const ContainerMethods = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.gray600};
  }

  div {
    display: flex;
  }
`;

export const ContainerSinglePayment = styled.div`
  width: 100%;
  max-width: calc(100% - 48px);
  min-height: 74px;
  background: #f2f2f2;
  border: 1px solid #004b55;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 11px 8px 10px 8px;
  margin: 0 auto;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.blueIntense};
    text-align: left;

    :last-of-type {
      font-weight: 500;
      color: #000;
      margin-bottom: 14px;
    }
  }

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: calc(100% - 96px);
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    width: initial;
    max-width: 100%;
    flex-direction: row;
    padding: 0 16px 0 8px;
    gap: 30px;

    p {
      text-align: left;

      :last-of-type {
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
`;

export const PaymentButton = styled.button`
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${Color.white};
  background: ${Color.blueIntense};
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    max-width: 196px;
  }
`;

export const ContainerWarningBox = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
  }
`;

export const TextList = styled.p`
  color: ${Color.gray};
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 384px;
    padding: 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
  }
  margin-top: 16px;
`;

interface IContainerButtonsListProps {
  typeList: 'invoices' | 'others';
}

export const ContainerButtonsList = styled.div<IContainerButtonsListProps>`
  height: 28px;
  display: flex;
  justify-content: space-between;
  gap: 0 12px;
  margin: 0 auto;
  padding: 0 16px;

  button {
    width: 100%;
    border-radius: 8px;
    background: ${({ typeList }) =>
      typeList === 'invoices' ? Color.blueIntense : Color.white};
    color: ${({ typeList }) =>
      typeList === 'invoices' ? Color.white : Color.blueIntense};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border: 1px solid ${Color.blueIntense};
    cursor: pointer;

    :last-of-type {
      background: ${({ typeList }) =>
        typeList === 'invoices' ? Color.white : Color.blueIntense};
      color: ${({ typeList }) =>
        typeList === 'invoices' ? Color.blueIntense : Color.white};
    }
  }

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    max-width: 384px;
    padding: 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 540px;
  }
`;

export const ContainerForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;
