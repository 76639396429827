import { InputHTMLAttributes, HTMLInputTypeAttribute } from 'react';

import styled from 'styled-components';

import { Color } from '../../styles';
// import { Breakpoints } from '../../styles/breakpoints';

export const ContainerInputStyled = styled.div`
  width: auto;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export const InputCheckedStyled = styled.input<
  InputHTMLAttributes<HTMLInputTypeAttribute>
>`
  cursor: pointer;
  color: ${Color.blueSeconde};
  border: 1px solid ${Color.blueSeconde};
  border-radius: 8px;
  padding-left: 8px;

  :focus-visible {
    outline: none;
  }

  :checked {
    border-color: ${Color.blueSeconde};
    color: ${Color.blueSeconde};
    background-color: ${Color.blueSeconde};
  }
  :active {
    border-color: ${Color.blueSeconde};
    color: ${Color.blueSeconde};
    background-color: ${Color.blueSeconde};
  }
`;

export const LabelStyled = styled.label`
  color: ${Color.blueSeconde};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const ContainerIconStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 8px;
`;
