import styled from 'styled-components';
import { Color } from '~/styles';

export const TableContainer = styled.table`
  width: 100%;
  color: #495057;
`;

export const Tr = styled.tr<{ color: 'gray' | 'lightGray' | 'white' }>`
  background: ${(props) => {
    const colors: Record<'gray' | 'lightGray' | 'white', string> = {
      gray: '#E9ECEF',
      lightGray: '#F8F9FA',
      white: Color.white,
    };

    return colors[props.color] || colors.white;
  }};
  color: '#495057';
`;

export const Td = styled.td`
  font-weight: 400;
  padding: 12px 20px;
  width: 25%;
`;

export const Th = styled.th`
  padding: 12px 20px;
  font-weight: 500;
  text-align: left;
`;

export const Input = styled.input<{ error: boolean }>`
  width: calc(100% - 84px);
  height: 100%;
  background: transparent;
  border: 1px solid #c7c9c7;
  border-radius: 8px;
  margin: -8px;
  padding: 8px 92px 8px 8px;

  ${({ error }) => error && `border: 1px solid ${Color.red}`}
`;

export const Label = styled.label<{ error: boolean }>`
  position: absolute;
  right: 0px;
  bottom: -1px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #c7c9c7;
  ${({ error }) => error && `color: ${Color.red}`}
`;

export const Container = styled.div`
  position: relative;
`;
