import React, { FC } from 'react';
import { Container, Divisor, DivisorMobile } from './styles';
import BoxDetail from './BoxDetail';
import { InvoicesIcon, OtherServicesIcon } from './BoxDetail/Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

const PendingDetails: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );

  return (
    <Container>
      {isMobile && <DivisorMobile />}
      <BoxDetail
        icon={<InvoicesIcon />}
        label={`${dataDischargeDebts?.info?.invoices?.totalItens || 0} Faturas`}
        amount={dataDischargeDebts?.info?.invoices?.amount || 0}
      />
      {isLaptop && <Divisor />}
      {isMobile && <DivisorMobile />}
      <BoxDetail
        icon={<OtherServicesIcon />}
        label={`${
          dataDischargeDebts?.info?.charges?.totalItens || 0
        } Outros serviços`}
        amount={dataDischargeDebts?.info?.charges?.amount || 0}
      />
    </Container>
  );
};

export default PendingDetails;
