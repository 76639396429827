import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const PlusSVG: FC<IProps> = ({ color = 'white' }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 10.9062H11M11 10.9062H14.75M11 10.9062V7.15625M11 10.9062V14.6562M21 10.9062C21 16.4291 16.5228 20.9062 11 20.9062C5.47715 20.9062 1 16.4291 1 10.9062C1 5.3834 5.47715 0.90625 11 0.90625C16.5228 0.90625 21 5.3834 21 10.9062Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PlusSVG;
