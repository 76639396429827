import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface ICard {
  miniCard?: boolean;
  hiddenBorder?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 18px 8px 134px 8px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 22px 0 64px 0;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 16px);

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: auto;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    flex-direction: row;
    display: inherit;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
    padding: 0 63px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
    padding: 0;
  }
`;

export const Card = styled.div<ICard>`
  width: calc(100% - 28px);
  min-height: 470px;
  padding: 24px 14px 64px 14px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 23px 23px 0 0;
  border-top: 24px solid ${Color.blueIntense};

  @media (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 640px;
    max-width: calc(870px - 160px);
    padding: 24px 80px 64px 80px;
    align-self: flex-start;
  }

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 40px;
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 28px);
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);
    margin-bottom: 54px;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;
