import { Reducer } from 'redux';

import {
  RepositoriesTypesPayment,
  IRepositoriesStateEnrollmentsPayment,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsPayment = {
  pixChecked: false,
  paymentVoucher: null,
  loading: false,
  loadingVoucher: false,
  error: null,
  errorVoucher: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsPayment, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_REQUEST:
      return {
        ...state,
        pixChecked: false,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_SUCCESS:
      return {
        ...state,
        pixChecked: action.payload.data.isPaid,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_FAILURE:
      return {
        ...state,
        pixChecked: false,
      };

    case RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentVoucher: { ...action.payload.data },
        loading: false,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentVoucher: null,
        loading: false,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
      };
    case RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_REQUEST:
      return {
        ...state,
        loadingVoucher: true,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_SUCCESS:
      return {
        ...state,
        paymentVoucher: { ...action.payload.data },
        loadingVoucher: false,
      };
    case RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE:
      return {
        ...state,
        paymentVoucher: null,
        loadingVoucher: false,
        errorVoucher: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
      };
    case RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorVoucher: null,
      };

    default:
      return state;
  }
};

export default reducer;
