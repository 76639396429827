import React, { FC, ButtonHTMLAttributes } from 'react';
import * as S from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'error';
  children: any;
  disabled?: boolean;
}

const BtnLarge: FC<Props> = ({ color, disabled, children, ...props }) => {
  return (
    <S.BtnLarge color={color} disabled={disabled} {...props}>
      {children}
    </S.BtnLarge>
  );
};

const BtnMedium: FC<Props> = ({ color, disabled, children, ...props }) => {
  return (
    <S.BtnMedium color={color} disabled={disabled} {...props}>
      {children}
    </S.BtnMedium>
  );
};

const BtnSmall: FC<Props> = ({ color, disabled, children, ...props }) => {
  return (
    <S.BtnSmall color={color} disabled={disabled} {...props}>
      {children}
    </S.BtnSmall>
  );
};

export const BtnText: FC<Props> = ({ disabled, children, ...props }) => {
  return (
    <S.BtnText disabled={disabled} {...props}>
      {children}
    </S.BtnText>
  );
};

export { BtnLarge, BtnSmall, BtnMedium };
