/* eslint-disable prefer-destructuring */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerSuccessText,
  ContainerClientInfo,
  ContainerTerms,
} from './styles';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Input } from '~/common/InputV3';
import { InputChecked } from '~/common/InputChecked';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import formatter from '~/utils/formatter';
import { useMediaQuery } from 'react-responsive';
import { IState } from '~/store/modules/types';
import { history } from '~/store';
import { SubscribesInfo } from './SubscribesInfo';
import {
  postEnrollmentsSubscribesMeRequest,
  postErrorCloseEnrollmentsSubscribesMe,
} from '~/store/modules/enrollmentsSubscribes/actions';
import { IRequestSubscribeCreate } from '~/store/modules/enrollmentsSubscribes/types';
import Payment from './Payment';
import { updateEnrollmentsUsersRequest } from '~/store/modules/enrollmentsUsers/actions';
import ModalTerm from './ModalTerm';

interface IInstallment {
  label?: string;
  value?: number;
}

interface IError {
  message: string;
  error: boolean;
}

interface IErrors {
  number: IError;
  validity: IError;
}

const SmartMeterSubscribesPayment: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const dispatch = useDispatch();

  const historyState = history.location.state as any;

  const InstallmentsLength = 1;
  const enrollmentData = useSelector((item: IState) => item?.enrollmentsUsers.data);
  const enrollment_id = useSelector(
    (item: IState) => item?.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loading = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.loadingCreate,
  );
  const dataEnrollment = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.dataEnrollment,
  );
  const successCreate = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.successCreate,
  );
  const loadingUpdate = useSelector(
    (item: IState) => item?.enrollmentsUsers.loadingUpdate,
  );
  const error = useSelector(
    (item: IState) => item?.enrollmentsSubscribes?.errorCreate,
  );
  const [email, setEmail] = useState<string>(
    enrollmentData && enrollmentData?.email ? enrollmentData.email : '',
  );
  const [errorEmail, setErrorEmail] = useState(false);
  const [phone, setPhone] = useState<string>(
    enrollmentData && enrollmentData?.phone?.primary
      ? formatter.formatarPhone(enrollmentData?.phone.primary)
      : '',
  );
  const [phoneError, setPhoneError] = useState<string>('');
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [dataPayment, setDataPayment] = useState<any>({
    installment: '',
    cardNumber: '',
    cardHolder: '',
    validity: '',
    securityCode: '',
  });
  const [brandCard, setBrandCard] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'Validade do cartão inválida',
    },
  });
  const [termUse, setTermUse] = useState<boolean>(false);
  const [acceptNews, setAcceptNews] = useState<boolean>(false);
  const [openTerm, setOpenTerm] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    const array: IInstallment[] = [
      {
        label: 'Selecione',
        value: undefined,
      },
    ];

    if (InstallmentsLength) {
      for (let index = 0; index < InstallmentsLength; index++) {
        array.push({
          label: `${index + 1} x sem juros`,
          value: index + 1,
        });
      }
    }

    setInstallments(array || []);
    dispatch(postErrorCloseEnrollmentsSubscribesMe());
  }, []);

  useEffect(() => {
    if (
      email &&
      !errorEmail &&
      phone &&
      !phoneError &&
      dataPayment.installment &&
      dataPayment.cardNumber &&
      dataPayment.cardHolder &&
      dataPayment.validity &&
      dataPayment.securityCode &&
      !errors.number.error &&
      !errors.validity.error &&
      termUse
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, errorEmail, phone, phoneError, dataPayment, errors, termUse]);

  const validatePhone = () => {
    if (!phone) return setPhoneError('');

    if (phone.length < 15) {
      return setPhoneError('Celular inválido');
    }
    return setPhoneError('');
  };

  return (
    <Main>
      <Modal open={openTerm} type="info" v3 hidden maxWidth={670}>
        <ModalTerm onClose={() => setOpenTerm(false)} />
      </Modal>
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => {
            dispatch(postErrorCloseEnrollmentsSubscribesMe());
          }}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Assinatura da medição digital',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Assinatura da medição digital</h1>
        <Margin height={isMobile ? 48 : 24} />
        <ContainerBorder>
          <Border />
          <h2>{successCreate ? 'Sucesso!' : 'Dados cadastrais'}</h2>
          <Border />
        </ContainerBorder>
        {successCreate && (
          <ContainerSuccessText>
            <p>
              Agora você é um cliente digital!
              <br /> <br />
              Você já pode configurar seus alertas e acompanhar seu consumo diário no
              seu histórico detalhado.
            </p>
          </ContainerSuccessText>
        )}
        {(((!dataEnrollment || dataEnrollment?.deletedAt === null) &&
          !successCreate) ||
          successCreate) && <SubscribesInfo />}
        {!successCreate && (
          <>
            <Margin height={24} />
            <ContainerClientInfo>
              <p>Confirme seus dados cadastrais</p>
              <div className="inputs">
                <Input
                  value={email}
                  onChange={(ev) => {
                    if (ev.target.value) {
                      if (formatter.rejexEmail(ev.target.value)) {
                        setErrorEmail(false);
                        setEmail(ev.target.value);
                      } else {
                        setErrorEmail(true);
                        setEmail(ev.target.value);
                      }
                    } else {
                      setEmail('');
                    }
                  }}
                  label="Email*"
                  name="email"
                  error={errorEmail}
                  errorText="Email inválido"
                />
                <Input
                  value={phone}
                  onChange={(ev) =>
                    setPhone(formatter.formatarPhone(ev.target.value))
                  }
                  onBlur={validatePhone}
                  label="Celular*"
                  name="cnp"
                  error={!!phoneError}
                  errorText={phoneError}
                />
              </div>
            </ContainerClientInfo>
            <ContainerBorder>
              <Border />
              <h2>Dados de pagamento</h2>
              <Border />
            </ContainerBorder>
            <Margin height={24} />
            <ContainerAmount>
              <p>Valor total: {formatter.formatCurrency(historyState?.amount)}</p>
            </ContainerAmount>
            <Margin height={24} />
            <Payment
              dataPayment={dataPayment}
              setDataPayment={setDataPayment}
              installments={installments}
              brandCard={brandCard}
              setBrandCard={setBrandCard}
              errors={errors}
              setErrors={setErrors}
            />
            <Margin height={32} />
          </>
        )}
        {!successCreate && (
          <ContainerTerms>
            <InputChecked
              checked={termUse}
              onChange={(e) => setTermUse(e.target.checked)}
            >
              <p>
                <span>Li e aceito o </span>
                <button type="button" onClick={() => setOpenTerm(true)}>
                  termo de uso
                </button>
              </p>
            </InputChecked>
            <InputChecked
              checked={acceptNews}
              onChange={(e) => setAcceptNews(e.target.checked)}
            >
              <p>
                <span>Desejo receber novidades via email/sms. </span>
              </p>
            </InputChecked>
          </ContainerTerms>
        )}
        <ContainerButtons successCreate={successCreate}>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() => Navigation.goBack()}
          />
          {successCreate ? (
            <ButtonText
              typeBtn="content"
              backgroundColor={Color.blueSeconde}
              onClick={() => Navigation.navigate(RouteName.HOME)}
              nextIcon
            >
              Ir para home
            </ButtonText>
          ) : (
            <ButtonText
              typeBtn="content"
              backgroundColor={disabled ? Color.gray : Color.blueSeconde}
              onClick={() => {
                if (disabled || loading || loadingUpdate) return;
                ReactGA.event({
                  category: 'Medição digital',
                  action: 'Confirmar pagamento',
                });
                const data: IRequestSubscribeCreate = {
                  plan_id: historyState?.plan_id,
                  enrollment_id: enrollment_id || '',
                  payment: {
                    card_number:
                      formatter.formatarNumber(dataPayment.cardNumber) || '',
                    card_holder: dataPayment.cardHolder,
                    expiration_date: dataPayment.validity,
                    security_code: Number(dataPayment.securityCode),
                    brand: brandCard,
                    installments: Number(dataPayment.installment),
                  },
                };

                dispatch(
                  updateEnrollmentsUsersRequest({
                    phone: {
                      primary: phone,
                      secondary: enrollmentData?.phone.secondary || '',
                    },
                    mobile: {
                      primary: enrollmentData?.mobile.primary || '',
                      secondary: enrollmentData?.mobile.secondary || '',
                    },
                    email,
                  }),
                );

                dispatch(postEnrollmentsSubscribesMeRequest({ ...data }));
              }}
              loading={loading || loadingUpdate}
              nextIcon
            >
              Confirmar pagamento
            </ButtonText>
          )}
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SmartMeterSubscribesPayment;
