import styled, { css, keyframes } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsColorStatus {
  color: string;
}

export const Container = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  background: ${Color.white};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
  }
`;

export const Header = styled.div<IPropsColorStatus>`
  background: ${({ color }) => color};
  border-radius: 8px 8px 0 0;
  text-align: end;
  color: ${Color.white};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  padding-right: 50px;
`;

export const ContainerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    padding: 6px 24px 0 24px;
    padding: 6px 0 0 0;
  }
`;

export const MonthText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.grayLightSeconde};
  margin: 7px 0 7px 24px;
  text-transform: uppercase;
`;

export const PaymentText = styled.p`
  width: calc(100% - 24px);
  margin: 0 auto;
  border-top: 1px solid ${Color.grayLight};
  position: relative;

  h2 {
    max-width: 125px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    text-transform: uppercase;
    position: absolute;
    top: -10px;
    background: ${Color.white};
    right: 15px;
    margin: auto;
    padding: 0 10px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    h2 {
      max-width: inherit;
    }

    .center-text {
      right: 50px;
    }
  }
`;

export const AmountText = styled.p<IPropsColorStatus>`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${({ color }) => color};
`;

export const DueDateText = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: ${Color.gray};
  display: flex;
  align-items: flex-start;
  gap: 24px;
  p {
    font-weight: 500;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    color: ${Color.blueIntense};
  }
  svg {
    height: 16px;
  }
`;

export const InfoPaymentText = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${Color.blueSeconde};
  margin-top: auto;
  display: flex;
  align-items: flex-start;

  span {
    width: 85%;
    margin-left: 24px;
    color: ${Color.blueIntense};
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    a {
      font-weight: 500;
      color: ${Color.blueSeconde};
    }
  }
`;

export const BarCodeText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${Color.blueIntense};
  margin: 0 16px 16px 17px;

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    font-weight: 700;
    color: ${Color.blueSeconde};
    text-align: center;
    font-size: 10px;
    margin-bottom: 32px;
  }
`;

export const Divisor = styled.div`
  border: 1px solid #e9e9e9;
  width: calc(100% - 24px);
  margin: 0 auto;
`;

export const ContainerCodeBarLabelInvoice = styled.div`
  width: calc(100% - 64px);
  margin: 36px auto 0 auto;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid ${Color.grayDark};
  border-radius: 8px;
  padding: 0 16px;
  text-align: center;

  p {
    white-space: nowrap;
    width: 75vw;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 14px;
    height: 16px;
    cursor: pointer;
  }

  button {
    border: none;
    background-color: ${Color.white};
  }

  span {
    position: absolute;
    top: -22px;
    left: 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 122.5%;
    color: #000;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    height: 36px;

    span {
      font-size: 14px;
    }

    span {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
`;

export const MiddleWrapper = styled.div<{ isPix?: boolean }>`
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 22px;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    grid-template-columns: ${({ isPix }) => (isPix ? '1.5fr 1fr' : '1fr')};
  }
`;

export const MiddleLeft = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0 24px 0 24px;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 0 24px 0 24px;
  }
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface IPropsContainerCodeBarLabelInvoice {
  copied: boolean;
  clicked: boolean;
  maxWidth: boolean;
}

export const ButtonCodeInvoice = styled.button<IPropsContainerCodeBarLabelInvoice>`
  width: calc(50% - 24px);
  margin: 8px 16px 0 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ copied }) => (copied ? Color.green : '#e9ecef')};
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  ${({ maxWidth }) => maxWidth && `width: 100%;`}

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 14px;
    height: 16px;
    cursor: pointer;
  }

  & + & {
    margin: 8px 16px 0 0;
  }

  ${({ clicked }) =>
    clicked &&
    css`
      width: 32px;
      height: 32px;
      margin: 8px auto 0 auto !important;
      padding: 0;
      border: 1px solid ${Color.grayDark};
      border-color: ${Color.grayDark};
      border-radius: 50px;
      border-width: 3px;
      font-size: 0;
      border-left-color: ${Color.green};
      animation: ${rotating} 2s 0.25s linear infinite;
    `}

  ${({ copied }) =>
    copied &&
    css`
      background: ${Color.green};
    `}

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    height: 36px;

    ${({ clicked }) =>
      clicked &&
      css`
        height: 32px;
      `}
  }
`;

export const Row = styled.div`
  display: flex;
`;
