import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const ContainerDrop = styled.div`
  width: 100%;
  height: 78px;
  border: 1px solid #c7c9c7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;

export const SelectedFileName = styled.div<{ v3?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);

  ${({ v3 }) =>
    v3 &&
    `
    p {
      color: #000 !important; 
      text-overflow: ellipsis;
      max-width: calc(100% - 30px);
      font-weight: 500 !important;
      text-wrap: nowrap;
      overflow: hidden;
    }
  `}

  p {
    letter-spacing: -0.06em;
    color: #c7c9c7;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  button {
    background: transparent;
    cursor: pointer;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    letter-spacing: -0.84px;
    color: #c7c9c7;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 34px 0 11px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.06em;
      color: #c7c9c7;
    }
  }
`;
