import styled, { css } from 'styled-components';
import { Color } from '~/styles';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 570px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 56px 10px;

  p {
    color: ${Color.gray};
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    line-height: 22px;
    text-align: center;
  }
  div {
    :first-of-type {
      min-height: inherit;

      h1 {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 102px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-block: 40px;
    gap: 40px;
  }
`;

export const MethodDiv = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    gap: 16px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: space-between;
    gap: 44px;
  }
`;
interface IMethodButton {
  empty?: boolean;
}

export const MethodButton = styled.button<IMethodButton>`
  width: 100%;
  max-width: 140px;
  height: 146px;
  padding: 40px 0 34px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${Color.white};
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.19);
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;

  p {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    margin-top: 10px;
  }

  svg {
    margin-bottom: 5px;
    width: 40px;
    height: 40px;
  }

  :disabled {
    opacity: 0.4;
  }

  ${({ empty }) => {
    if (empty) {
      return css`
        color: Color.grayLight;
        background: ${Color.graySeconde};
      `;
    }

    return css`
      :hover {
        opacity: 0.8;
      }
    `;
  }}

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 212px;
    height: 237px;
    font-size: 20px;
    font-weight: 500;

    p {
      font-weight: 400;
      font-size: 15px;
      color: #000;
    }
  }
`;
