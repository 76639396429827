import React, { FC } from 'react';
import {
  Container,
  ContainerAlert,
  ContainerSuccess,
  ContainerSuccessText,
} from './styles';

import SuccessBox from '~/components/SuccessBox';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import moment from 'moment';

const ReportLackWaterSuccess: FC = () => {
  const data = useSelector((item: IState) => item.orderServices.dataCreateLackWater);

  return (
    <Container>
      <ContainerAlert>
        <SuccessBox
          title="Solicitação enviada com sucesso!"
          label="Para acompanhar o progresso da solicitação "
        />
      </ContainerAlert>
      <ContainerSuccess>
        <ContainerSuccessText>
          PROTOCOLO: <span>{data?.caseNumber}</span>{' '}
        </ContainerSuccessText>
        <ContainerSuccessText>
          DATA DE SOLICITAÇÃO: <span>{moment(new Date()).format('DD/MM/YYYY')}</span>
        </ContainerSuccessText>
      </ContainerSuccess>
    </Container>
  );
};

export default ReportLackWaterSuccess;
