/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';

import { Border, Description, TextOption } from '../styles';
import InputSimulation from '../InputSimulation';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ContinueButton from '../ContinueButton';
import SuccessBox from '~/components/SuccessBox';

interface IStepOneProps {
  setStep: Dispatch<SetStateAction<number>>;
}

const StepOne: FC<IStepOneProps> = ({ setStep }) => {
  const minLimit = 2.0;
  const maxLimit = 5.0;

  const [value, setValue] = useState<number>(minLimit);
  const hasMaximumValue = maxLimit === value;
  const backgroundColor = hasMaximumValue ? Color?.green : Color?.blueSeconde;

  const handleOnClick = () => {
    if (hasMaximumValue) {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action:
          '[Negociação de dívidas][Simule uma negociação] Etapa 01 – Continuar com pagamento a vista.',
      });

      Navigation.navigate(RouteName.DEBTNEGOTIATIONTYPEPAYMENT);
    } else {
      ReactGA.event({
        category: 'Negociação de dívidas',
        action:
          '[Negociação de dívidas][Simule uma negociação] Etapa 01 – Continuar para escolher parcela.',
      });

      setStep(1);
    }
  };

  return (
    <>
      <Description>
        Defina o valor a ser pago de entrada e receba o restante parcelado nas
        próximas faturas.
      </Description>
      <TextOption> VALOR DE ENTRADA</TextOption>
      <InputSimulation
        minLimit={minLimit}
        maxLimit={maxLimit}
        value={value}
        setValue={setValue}
      />
      <Border />
      {hasMaximumValue && (
        <SuccessBox title="Ganhe ainda mais desconto!" isCongratulationsIcon>
          Você selecionou o valor total da dívida e pode ganhar ainda mais desconto!
          <br />
          <br />
          Clique em "continuar" para pagar à vista ou diminua o valor da entrada para
          continuar na simulação de parcelamento.
        </SuccessBox>
      )}
      <ContinueButton
        label="Continuar"
        backgroundColor={backgroundColor}
        onClick={handleOnClick}
      />
    </>
  );
};

export default StepOne;
