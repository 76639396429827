import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 560px;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0; */
  position: relative;

  div {
    :first-of-type {
      min-height: inherit;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    /* padding: 128px 0 120px 0; */
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const NavWrap = styled.div`
  margin-block-start: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
