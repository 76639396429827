import React, { FC, HTMLAttributes, ReactNode } from 'react';
import WarningAlertSVG from '~/assets/Icons/WarningAlert';
import { MyTooltip } from './styles';

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  children?: string | ReactNode;
  variant?: 'error' | 'warning' | 'info';
  icon?: boolean;
}

const Icon = () => {
  const MyIcon = <WarningAlertSVG />;
  return MyIcon;
};

const Tooltip: FC<TooltipProps> = ({ children, variant, ...props }) => {
  if (children) {
    return (
      <MyTooltip variant={variant} {...props}>
        {children} <Icon />
      </MyTooltip>
    );
  }
  return null;
};

export { Tooltip };
