/* eslint-disable radix */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/extensions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import creditCardType from 'credit-card-type';

import { Color } from '~/styles';
import formatter from '~/utils/formatter';

import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import CreditCardTemplate from './CardTemplate';

import ArrowBackCurvedSVG from '~/assets/Icons/ArrowBackCurvedSGV';
import {
  Container,
  ContainerButtons,
  ContainerCardTemplate,
  ContainerInfoCard,
  ContainerInputs,
  Header,
  RowSummary,
  SummaryContainer,
  TitleSummary,
  CustomButtonIcon,
} from './styles';

import { Breakpoints } from '~/styles/breakpoints';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { postEnrollmentsPaymentRequest } from '~/store/modules/enrollmentsPayment/actions';
import { checkToggle } from '~/utils/toggles';

interface IProps {
  paymentAmount: number;
  infoInvoiceId?: number;
  infoReference?: string;
  possibleQuantityInstallments: number;
}

interface Card {
  number?: string;
  cvc?: string;
  validity?: string;
  brand?: string;
  name?: string;
}

interface IInstallment {
  label?: string;
  value?: number;
}

interface IError {
  message: string;
  error: boolean;
}

interface IErrors {
  number: IError;
  validity: IError;
}

const CreditCard: FC<IProps> = ({
  paymentAmount,
  infoInvoiceId,
  infoReference,
  possibleQuantityInstallments,
}) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const dispatch = useDispatch();

  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const loading = useSelector((state: IState) => state.enrollmentsPayment.loading);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [card, setCard] = useState<Card>({
    number: '',
    cvc: '',
    validity: '',
    brand: '',
    name: '',
  });
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'validade do cartão inválida',
    },
  });
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [selectedPlot, setSelectedPlot] = useState<IInstallment>({
    label: '1',
    value: 1,
  });
  const [installmentExists, setInstallmentExists] = useState<boolean>(false);
  const [braspagExists, setBraspagExists] = useState<boolean>(false);

  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);
  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );

  const validateCardNumber = () => {
    if (
      (card.number && card.number.length < 19) ||
      (card.number && card.number.length > 0 && !card.brand)
    ) {
      setErrors(({ number, validity }) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (card.validity && card.validity.length < 7) {
      setErrors(({ number, validity }) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },

    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },

    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType((card.number || '').replace(/\D/g, '')) as any;

    if (brandType.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand.issuer === brandType?.type,
    );

    if (brand?.brand) setCard({ ...card, brand: brand.issuer });
    else setCard({ ...card, brand: '' });
  };

  useEffect(() => {
    if (card.number && card.cvc && card.validity && card.brand && card.name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [card]);

  useEffect(() => {
    findBrand();
  }, [card.number]);

  const verifyToggles = async () => {
    setBraspagExists(await checkToggle(toggles, 'BRASPAG'));
  };

  const verifyTogglesInstallments = async () => {
    setInstallmentExists(await checkToggle(toggles, 'INSTALLMENTS'));
  };

  useEffect(() => {
    const array: IInstallment[] = [];

    for (let index = 0; index < possibleQuantityInstallments; index++) {
      array.push({
        label: `${index + 1} x sem juros`,
        value: index + 1,
      });
    }
    setInstallments(array || []);
    verifyToggles();
  }, []);

  useEffect(() => {
    if (braspagExists) verifyTogglesInstallments();
  }, [braspagExists]);

  return (
    <Container>
      {isMobile && (
        <SummaryContainer>
          <TitleSummary>Resumo do pagamento</TitleSummary>
          {installmentExists && (
            <>
              <Margin height={7} />
              <InputSelect
                disabled={!installmentExists}
                values={installments}
                value={selectedPlot?.value?.toString() || ''}
                name="installment"
                onChange={(ev) => {
                  const installmentInfo = installments.find(
                    (item) => item.value === Number(ev.target.value),
                  );

                  setSelectedPlot(
                    installmentInfo || {
                      label: '1',
                      value: 1,
                    },
                  );
                }}
              />
            </>
          )}
          <Margin height={25} />
          <RowSummary>
            Referência <span>{infoReference}</span>
          </RowSummary>
          <Margin height={8} />
          <RowSummary>
            Valor fatura <span>{formatter.formatCurrency(paymentAmount)}</span>
          </RowSummary>
          <Margin height={8} />
          <RowSummary style={{ fontWeight: 700 }}>
            Valor total{' '}
            <span style={{ fontWeight: 700 }}>
              {formatter.formatCurrency(paymentAmount)}
            </span>
          </RowSummary>
        </SummaryContainer>
      )}
      {isMobile && (
        <>
          <Margin height={45} />
          <Header>Cartões aceitos:</Header>
        </>
      )}
      <ContainerInfoCard>
        {isMobile ? (
          <>
            <Margin height={22} />
            <ContainerCardTemplate>
              <CreditCardTemplate
                holder={card.name}
                cardNumber={card.number}
                securityCode={card.cvc}
                validity={card.validity}
                issuer={card.brand}
              />
            </ContainerCardTemplate>
            <Margin height={14} />
            <Input
              value={card.number}
              name="cardNumber"
              onChange={(ev) =>
                setCard({
                  ...card,
                  number: formatter.creditCard(ev.target.value) || '',
                })
              }
              label="Número do cartão"
              errorText={errors.number.message}
              error={errors.number.error}
              errorPosition="bottom"
              onBlur={validateCardNumber}
              maxLength={19}
              payment
            />
            <Margin height={isMobile ? 7 : 10} />
            <Input
              value={card.name}
              name="cardName"
              onChange={(ev) => setCard({ ...card, name: ev.target.value || '' })}
              label="Nome do titular"
              payment
            />
            <Margin height={isMobile ? 7 : 10} />
            <Input
              value={card.validity || ''}
              name="cardValidity"
              onChange={(ev) => {
                const clean = String(ev?.target?.value)
                  .substring(0, 7)
                  .replace(/\D$/g, '')
                  .replace(/(\d{2})/, '$1')
                  .replace(/(\d{2})(\d{4})/, '$1/$2');
                setCard({ ...card, validity: clean || '' });
              }}
              label="Validade"
              errorText={errors.validity.message}
              error={errors.validity.error}
              errorPosition="bottom"
              onBlur={validateCardValidity}
              placeholder="mm/aaaa"
              payment
            />
            <Margin height={isMobile ? 7 : 10} />
            <Input
              value={card.cvc}
              name="cardCvc"
              onChange={(ev) =>
                setCard({
                  ...card,
                  cvc: formatter.formatarNumber(ev.target.value) || '',
                })
              }
              label="Código de segurança"
              placeholder="000"
              type={!openPassword ? 'password' : 'text'}
              iconPassword
              passwordVisible={openPassword}
              setPasswordVisible={setOpenPassword}
              maxLength={3}
              payment
            />
            <Margin height={isMobile ? 24 : 46} />
            <ButtonText
              className="payment"
              text="Confirmar pagamento"
              typeBtn="content"
              Color={Color.white}
              backgroundColor={disabled ? Color.gray : Color.green}
              onClick={() => {
                if (!disabled) {
                  const dataPayment = {
                    infoInvoiceId,
                    paymentCardNumber: card.number || '',
                    paymentCardHolder: card.name || '',
                    paymentExpirationDate: card.validity,
                    paymentSecurityCode: card.cvc || '',
                    paymentBrand: card.brand || '',
                    installments: selectedPlot.value,
                  };

                  if (
                    registration &&
                    dataPayment?.infoInvoiceId &&
                    dataPayment?.infoInvoiceId &&
                    dataPayment?.paymentExpirationDate &&
                    selectedPlot?.value
                  ) {
                    dispatch(
                      postEnrollmentsPaymentRequest(
                        {
                          enrollmentId: registration,
                          invoiceId: dataPayment?.infoInvoiceId.toString(),
                          paymentCardNumber: dataPayment?.paymentCardNumber,
                          paymentCardHolder: dataPayment?.paymentCardHolder,
                          paymentExpirationDate: dataPayment?.paymentExpirationDate,
                          paymentSecurityCode: dataPayment?.paymentSecurityCode,
                          paymentBrand: dataPayment?.paymentBrand,
                          installments: selectedPlot?.value,
                        },
                        braspagExists,
                      ),
                    );
                  }
                }
              }}
              loading={loading}
            />
            <Margin height={14} />
            <CustomButtonIcon
              type="button"
              onClick={() => Navigation.navigate(RouteName.HOME)}
            >
              <ArrowBackCurvedSVG />
              Voltar
            </CustomButtonIcon>
            <Margin height={25} />
          </>
        ) : (
          <>
            <div
              style={{
                width: '45%',
              }}
            >
              <ContainerCardTemplate>
                <CreditCardTemplate
                  holder={card.name}
                  cardNumber={card.number}
                  securityCode={card.cvc}
                  validity={card.validity}
                  issuer={card.brand}
                />
              </ContainerCardTemplate>
              <Margin height={26} />
              <ContainerInputs>
                <Input
                  value={card.number}
                  name="cardNumber"
                  onChange={(ev) =>
                    setCard({
                      ...card,
                      number: formatter.creditCard(ev.target.value) || '',
                    })
                  }
                  label="Número do cartão"
                  errorText={errors.number.message}
                  error={errors.number.error}
                  errorPosition="bottom"
                  onBlur={validateCardNumber}
                  maxLength={19}
                  payment
                />
                <Margin height={isMobile ? 7 : 10} />
                <Input
                  value={card.name}
                  name="cardName"
                  onChange={(ev) =>
                    setCard({ ...card, name: ev.target.value || '' })
                  }
                  label="Nome do titular"
                  payment
                />
                <Margin height={isMobile ? 7 : 10} />
                <div style={{ display: 'flex' }}>
                  <Input
                    value={card.validity}
                    name="cardValidity"
                    onChange={(ev) => {
                      const clean = String(ev?.target?.value)
                        .substring(0, 7)
                        .replace(/\D$/g, '')
                        .replace(/(\d{2})/, '$1')
                        .replace(/(\d{2})(\d{4})/, '$1/$2');
                      setCard({ ...card, validity: clean || '' });
                    }}
                    label="Validade"
                    errorText={errors.validity.message}
                    error={errors.validity.error}
                    errorPosition="bottom"
                    onBlur={validateCardValidity}
                    placeholder="mm/aaaa"
                    payment
                    styleInput={{ width: '48%' }}
                  />
                  <Margin width={30} />
                  <Input
                    value={card.cvc}
                    name="cardCvc"
                    onChange={(ev) =>
                      setCard({
                        ...card,
                        cvc: formatter.formatarNumber(ev.target.value) || '',
                      })
                    }
                    label="Código de segurança"
                    placeholder="000"
                    type={!openPassword ? 'password' : 'text'}
                    iconPassword
                    passwordVisible={openPassword}
                    setPasswordVisible={setOpenPassword}
                    maxLength={3}
                    payment
                    styleInput={{ width: '48%' }}
                  />
                </div>
              </ContainerInputs>
              <Margin height={63} />
            </div>
            <div
              style={{
                width: '45%',
                borderLeft: '1px solid #C7C9C7',
              }}
            >
              <SummaryContainer>
                <TitleSummary>Resumo do pagamento</TitleSummary>
                {installmentExists && (
                  <InputSelect
                    disabled={!installmentExists}
                    values={installments}
                    value={selectedPlot?.value?.toString() || ''}
                    name="installment"
                    onChange={(ev) => {
                      const installmentInfo = installments.find(
                        (item) => item.value === Number(ev.target.value),
                      );

                      setSelectedPlot(
                        installmentInfo || {
                          label: '1',
                          value: 1,
                        },
                      );
                    }}
                  />
                )}
                <Margin height={39} />
                <RowSummary>
                  Referência <span>{infoReference}</span>
                </RowSummary>
                <Margin height={20} />
                <RowSummary>
                  Valor fatura <span>{formatter.formatCurrency(paymentAmount)}</span>
                </RowSummary>
                <Margin height={102} />
                <RowSummary style={{ fontWeight: 700 }}>
                  Valor total{' '}
                  <span style={{ fontWeight: 700 }}>
                    {formatter.formatCurrency(paymentAmount)}
                  </span>
                </RowSummary>
              </SummaryContainer>
              <ContainerButtons>
                <Margin height={49} />
                <ButtonText
                  text="Confirmar pagamento"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={disabled ? Color.gray : Color.green}
                  onClick={() => {
                    if (!disabled) {
                      const dataPayment = {
                        infoInvoiceId,
                        paymentCardNumber: card.number || '',
                        paymentCardHolder: card.name || '',
                        paymentExpirationDate: card.validity,
                        paymentSecurityCode: card.cvc || '',
                        paymentBrand: card.brand || '',
                        installments: selectedPlot.value,
                      };

                      if (
                        registration &&
                        dataPayment?.infoInvoiceId &&
                        dataPayment?.infoInvoiceId &&
                        dataPayment?.paymentExpirationDate &&
                        selectedPlot?.value
                      ) {
                        dispatch(
                          postEnrollmentsPaymentRequest(
                            {
                              enrollmentId: registration,
                              invoiceId: dataPayment?.infoInvoiceId.toString(),
                              paymentCardNumber: dataPayment?.paymentCardNumber,
                              paymentCardHolder: dataPayment?.paymentCardHolder,
                              paymentExpirationDate:
                                dataPayment?.paymentExpirationDate,
                              paymentSecurityCode: dataPayment?.paymentSecurityCode,
                              paymentBrand: dataPayment?.paymentBrand,
                              installments: selectedPlot?.value,
                            },
                            braspagExists,
                          ),
                        );
                      }
                    }
                  }}
                  loading={loading}
                />
                <Margin height={16} />
                <CustomButtonIcon
                  type="button"
                  onClick={() => Navigation.navigate(RouteName.HOME)}
                >
                  <ArrowBackCurvedSVG />
                  Voltar
                </CustomButtonIcon>
                <Margin height={15} />
              </ContainerButtons>
            </div>
          </>
        )}
      </ContainerInfoCard>
    </Container>
  );
};

export default CreditCard;
