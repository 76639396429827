import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Status from './Status';
import Form from './Form';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import DocumentationApprovalRevision from './Revision';

interface IProps {
  setActualStep: (state: 1 | 2 | 3 | 4 | 5) => void;
}

const DocumentationWorkLicensing: FC<IProps> = ({ setActualStep }) => {
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  return (
    <>
      {caseOC?.step.status === stepStatusEnum.FORM && (
        <Form setActualStep={setActualStep} />
      )}
      {(caseOC?.step.status === stepStatusEnum.SUCCESS ||
        caseOC?.step.status === stepStatusEnum.WAIT) && (
        <Status setActualStep={setActualStep} />
      )}
      {caseOC?.step?.status === stepStatusEnum.REVIEW && (
        <DocumentationApprovalRevision setActualStep={setActualStep} />
      )}
    </>
  );
};

export default DocumentationWorkLicensing;
