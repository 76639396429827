import React, { FC } from 'react';
import { Main } from './styles';
import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { getErrorCloseEasyDuplicateInvoices } from '~/store/modules/outsides/actions';
import { useDispatch } from 'react-redux';

interface IProps {
  setOpen: (status: boolean) => void;
}

const ModalErrorEasyDuplicate: FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch();

  return (
    <Main>
      <p>
        Pedimos desculpas, mas no momento nossos canais de atendimento 0800, chat e
        WhatsApp estão passando por uma instabilidade, podendo haver demora no
        atendimento ou indisponibilidade. Estamos trabalhando para resolver a
        situação o mais rápido possível. Agradecemos sua compreensão.
      </p>
      <Margin height={20} />
      <ButtonText
        text="Entendi"
        typeBtn="content"
        backgroundColor={Color.blueSeconde}
        Color={Color.white}
        onClick={() => {
          dispatch(getErrorCloseEasyDuplicateInvoices());
          setOpen(false);
        }}
        style={{ maxWidth: '340px', height: '68px' }}
      />
    </Main>
  );
};

export default ModalErrorEasyDuplicate;
