import React, { FC } from 'react';
import { Margin } from '~/components/Margin';
import { SituationAccount } from '~/store/modules/automaticDebits/types';
import { Border, Container, ContainerInfo, TextNameBank } from './styles';

interface IProps extends SituationAccount {
  bankName: string;
  onClick: () => void;
}

const DebitAccount: FC<IProps> = ({ situation, bankName, onClick }) => {
  return (
    <Container situation={situation} type="button" onClick={onClick}>
      <Border situation={situation} />
      <Margin width={30} />
      <ContainerInfo>
        <span>Débito em conta</span>
        <TextNameBank situation={situation}>{bankName}</TextNameBank>
      </ContainerInfo>
    </Container>
  );
};

export default DebitAccount;
