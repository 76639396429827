import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { SuccessSVG } from '~/assets/Icons';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

interface IProps {
  onClose: () => void;
}

const ModalSuccess: FC<IProps> = ({ onClose }) => {
  const protocol = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.protocol,
  );

  useEffect(() => {
    ReactGA.event({
      category: 'Recadastramento',
      action: `[Cliente Não Saneamento] – Sucesso na Solicitação de Recadastramento`,
    });
  }, []);

  return (
    <Main>
      <SuccessSVG />
      <Margin height={15} />
      <h3>Solicitação realizada com sucesso!</h3>
      <Margin height={30} />
      <p>
        Sua solicitação foi enviada.
        <br />
        Número do protocolo <b>{protocol}</b>. Para acompanhar o progresso
        <br />
        Fale conosco.
      </p>
      <Margin height={30} />
      <ButtonText
        text="OK"
        typeBtn="content"
        backgroundColor={Color.blueSeconde}
        Color={Color.white}
        onClick={onClose}
        style={{ maxWidth: '493px', height: '48px' }}
      />
    </Main>
  );
};

export default ModalSuccess;
