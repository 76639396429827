/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-const */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerInputs,
  ContentInputs,
  ContainerDocuments,
  RowDocument,
  SenFiles,
  Label,
  ContainerTerms,
  BoxAccepteds,
  AcceptedWarning,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { Input } from '~/common/InputV3';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { BreadCrumb } from '~/components/BreadCrumb';
import StatusInfo from './StatusInfo';
import FormImmobile, { ISelectTypesValues } from './FormImmobile';
import { Dialog } from '~/components/Dialog';
import ModalSuccess from './ModalSuccess';
import convertFileToBase64 from '~/utils/convertBase64';
import DropZone from './DropZone';
import { Redirect } from 'react-router-dom';
import { history } from '~/store';
import {
  getErrorCloseOutsidesSocialTariffEnrollments,
  postErrorCloseOutsidesSocialTariff,
  postOutsidesSocialTariffRequest,
} from '~/store/modules/outsides/actions';
import {
  Holder,
  IRequestSocialTariff,
  Residence,
} from '~/store/modules/outsides/types';
import moment from 'moment';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { subYears } from 'date-fns';
import ModalTerm from './ModalTerm';
import InputCheckedV3 from '~/common/InputCheckedV3';
import ModalInfoAddress from './ModalInfoAddress';
import {
  getCitiesAddressService,
  getStatesAddressService,
} from '~/services/outsides';

export interface IDocuments {
  key: string;
  label: string;
  description: string;
  file: null | File;
}

interface Address {
  street: string;
  number: number;
  neighborhood: string;
  complement: string;
  city: string;
  zipCode: string;
}

interface Operation {
  id: number;
  name: string;
}

export interface IDataHistory {
  code: string;
  enrollment: string;
  consumer: string;
  address_connection: string;
  address: Address;
  operation: Operation;
  cnp: string;
  renovation: boolean;
  typeSolicitation?: string;
}

export interface IFormData extends IRequestSocialTariff {
  [key: string]: any;
}

interface IListStates {
  id?: number;
  sigla?: string;
  nome?: string;
  isActive?: boolean;
  value?: string;
  label?: string;
}
interface IListCities {
  id?: number;
  nome?: string;
  uf_id?: number;
  isActive?: boolean;
  value?: string;
  label?: string;
}

const SocialTariffOutsideSolicitation: FC = () => {
  const dataHistory = history?.location?.state as IDataHistory;

  const typeSolicitation =
    (dataHistory && dataHistory?.typeSolicitation === 'changeOwnership') ||
    (dataHistory && dataHistory?.typeSolicitation === 're-registration')
      ? dataHistory?.typeSolicitation
      : '';

  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item.outsides.loadingSocialTariff);
  const successSocialTariff = useSelector(
    (item: IState) => item.outsides.successSocialTariff,
  );
  const error = useSelector((item: IState) => item.outsides.errorSocialTariff);

  const [formData, setFormData] = useState<IFormData>({
    cnp: formatter.cpfCnpjMask(dataHistory?.cnp || ''),
    enrollment:
      dataHistory?.enrollment || formatter.enrollment(dataHistory?.code) || '',
    holder: {
      fullName: formatter.capitalize(dataHistory?.consumer || '') || '',
      document: '',
      birthDate: '',
      email: '',
      cellphone: '',
    },
    residence: {
      type: '',
      bond: '',
      state: '',
      city: '',
      zipCode: !typeSolicitation ? dataHistory?.address?.zipCode : '',
      neighborhood: !typeSolicitation ? dataHistory?.address?.neighborhood : '',
      street: !typeSolicitation ? dataHistory?.address?.street : '',
      number: !typeSolicitation
        ? formatter.formatarNumber(dataHistory?.address?.number?.toString()) || ''
        : '',
      complement: !typeSolicitation ? dataHistory?.address?.complement : '',
    },
    files: {
      identification_document: { filename: '', filedata: '' },
    },
    terms: {
      termsOfResponsibility: true,
      termsOfVeracity: true,
    },
  });
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [rgError, setRgError] = useState<boolean>(false);
  const [birthDateError, setBirthDateError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [typesSocialTariffSelected, setTypesSocialTariffSelected] =
    useState<ISelectTypesValues | null>(null);
  const [documents, setDocuments] = useState<IDocuments[]>([
    {
      key: 'identification_document',
      label: 'Documento de identificação*',
      description:
        'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
      file: null,
    },
  ]);
  const allDocuments: any = {
    benefitRenewal: [
      {
        key: 'identification_document',
        label: 'Documento de identificação*',
        description:
          'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
    ],
    favelaHousing: [
      {
        key: 'identification_document',
        label: 'Documento de identificação*',
        description:
          'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
      {
        key: 'faferj',
        label: 'Documentação FAFERJ (local de favela)',
        description:
          'Documentação FAFERJ (local de favela) ou vistoria de localização realizada pela Iguá. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
    ],
    areaSocialInterest: [
      {
        key: 'identification_document',
        label: 'Documento de identificação*',
        description:
          'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
      {
        key: 'localization_inspection',
        label: 'Vistoria de localização',
        description: (
          <>
            Vistoria de localização (na ausência de documentação).
            <br /> Documentos devem ser anexados em formato PDF ou JPG.
          </>
        ),
        file: null,
      },

      {
        key: 'iptu',
        label: 'IPTU',
        description: (
          <>
            IPTU (ou outro documento que comprove a posse). <br />
            Documentos devem ser anexados em formato PDF ou JPG.
          </>
        ),
        file: null,
      },
    ],
    housing: [
      {
        key: 'identification_document',
        label: 'Documento de identificação*',
        description:
          'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
      {
        key: 'localization_inspection',
        label: 'Documento do órgão financiador',
        description: (
          <>
            Documento do órgão financiador com o nome do titular. <br /> Documentos
            devem ser anexados em formato PDF ou JPG.
          </>
        ),
        file: null,
      },
      {
        key: 'iptu',
        label: 'IPTU',
        description:
          'IPTU (casas isoladas). Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
      {
        key: 'condominium_act',
        label: 'Ata do condomínio onde conste a eleição do síndico',
        description: (
          <>
            Ata do condomínio onde conste a eleição do síndico (ou documento que
            conste o responsável pelo conjunto).
            <br /> Documentos devem ser anexados em formato PDF ou JPG.
          </>
        ),
        file: null,
      },
    ],
    publicHousing: [
      {
        key: 'identification_document',
        label: 'Documento de identificação*',
        description:
          'CPF, RG ou CNH. Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
      {
        key: 'ceasing_land_document',
        label:
          'Documento comprobatório da cessão do terreno emitido pelo órgão público',
        description:
          'Documento comprobatório da cessão do terreno emitido pelo órgão público (incluindo nome do titular). Documentos devem ser anexados em formato PDF ou JPG.',
        file: null,
      },
    ],
  };
  const [termResponsibility, setTermResponsibility] = useState<boolean>(false);
  const [termDeclaration, setTermDeclaration] = useState<boolean>(false);
  const [openTerm, setOpenTerm] = useState<boolean>(false);
  const [openInfoAddress, setOpenInfoAddress] = useState<boolean>(false);
  const [valuesStates, setValuesStates] = useState<Array<IListStates>>([
    {
      value: '',
      label: 'Selecione',
    },
  ]);
  const [valuesCities, setValuesCities] = useState<Array<IListCities>>([
    { value: '', label: 'Selecione' },
  ]);

  const get18 = subYears(new Date(), 18);
  const get100 = subYears(new Date(), 100);

  useEffect(() => {
    handleGetStatesAddress();
    dispatch(postErrorCloseOutsidesSocialTariff());
  }, []);

  useEffect(() => {
    if (typesSocialTariffSelected) {
      setDocuments(allDocuments[typesSocialTariffSelected.value]);
    }
  }, [typesSocialTariffSelected]);

  useEffect(() => {
    if (successSocialTariff) {
      ReactGA.event({
        category: 'Tarifa Social',
        action: `[Ñ Logado][Tarifa Social][Formulário] – Sucesso na abertura do caso`,
      });
    }
  }, [successSocialTariff]);

  useEffect(() => {
    if (error) {
      ReactGA.event({
        category: 'Tarifa Social',
        action: `[Ñ Logado][Tarifa Social][Formulário] – Insucesso na abertura do caso`,
      });
    }
  }, [error]);

  if (!dataHistory) {
    return <Redirect to={RouteName.LANDING} />;
  }

  const handleFormattValue = (name: string, value: string): string => {
    const lengthValue = formatter.formatarNumber(value || '')?.length || 0;

    if (name === 'holder.fullName')
      return formatter.capitalize(value).replace(/[^a-zA-Z\s]/g, '');
    if (name === 'holder.document') {
      setRgError(value ? lengthValue < 3 : false);
      return formatter.formatarNumber(value || '') || '';
    }
    if (name === 'holder.cellphone') {
      setPhoneError(value ? lengthValue < 11 : false);
      return formatter.formatarPhone(value);
    }
    if (name === 'residence.zipCode') {
      return formatter.cepMask(value);
    }
    if (name === 'residence.number') {
      return formatter.formatarNumber(value) || '';
    }

    return value;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const keys = name.split('.');

    if (keys?.length === 1) {
      setFormData({ ...formData, [name]: handleFormattValue(name, value) });
    } else {
      let data = { ...formData };
      let current: any = data;
      let currentErrors: any = { ...formDataErrors };

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
        currentErrors = currentErrors[keys[i]];
      }

      current[keys[keys.length - 1]] = handleFormattValue(name, value);

      setFormData(data);

      if (currentErrors && currentErrors[keys[keys.length - 1]]) {
        delete currentErrors[keys[keys.length - 1]];
      }
    }
  };

  const handleValidForm = (validEmpty?: boolean) => {
    const errors: any = {};

    const messageEmpty = validEmpty ? 'Preencher campo' : '';

    if (!formData.cnp || formData.cnp.trim() === '') {
      errors.cnp = messageEmpty;
    }

    if (!formData.enrollment || formData.enrollment.trim() === '') {
      errors.enrollment = messageEmpty;
    }

    const holderFields: (keyof Holder)[] = [
      'fullName',
      'document',
      'birthDate',
      'email',
      'cellphone',
    ];

    holderFields.forEach((field) => {
      if (field === 'birthDate') {
        const inputDate = new Date(formData.holder[field]);
        if (inputDate > get18 || inputDate < get100) {
          setBirthDateError(true);
        } else {
          setBirthDateError(false);
        }
      }

      const isError =
        !formData.holder[field] ||
        formData.holder[field].trim() === '' ||
        (field === 'document' && formData.holder[field].length < 3) ||
        (field === 'cellphone' && formData.holder[field].length < 15);

      if (isError) {
        if (field === 'cellphone' && formData.holder[field].length < 15)
          errors.holder = { ...errors.holder, [field]: messageEmpty };
        errors.holder = { ...errors.holder, [field]: messageEmpty };
      }
    });

    const residenceFields: (keyof Residence)[] = [
      'type',
      'bond',
      'state',
      'city',
      'zipCode',
      'neighborhood',
      'street',
      'number',
    ];

    residenceFields.forEach((field) => {
      const isError =
        !formData?.residence[field] || formData?.residence[field]?.trim() === '';

      if (isError) {
        errors.residence = {
          ...errors.residence,
          [field]:
            field === 'bond'
              ? !formData.residence.type
                ? ''
                : messageEmpty
              : messageEmpty,
        };
      }
    });

    documents?.forEach((field) => {
      if (!field.file) {
        if (field.key === 'identification_document') {
          errors.files = {
            ...errors?.files,
            [field.key]: messageEmpty,
          };
        }
      }
    });

    setFormDataErrors({ ...errors });

    return Object.keys(errors).length !== 0;
  };

  const handleSolicitation = async () => {
    if (!termResponsibility || !termDeclaration) return;

    if (loading || handleValidForm(true)) {
      document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    ReactGA.event({
      category: 'Tarifa Social',
      action: `[Ñ Logado][Tarifa Social][Formulário][Solicitar recadastramento] - ${formData?.residence?.type}`,
    });

    let files: any = {};

    const convertToBase64Promise = (file: File) => {
      return new Promise((resolve) => {
        convertFileToBase64(file, (base64: string) => {
          resolve(base64);
        });
      });
    };

    for (const document of documents) {
      if (document?.file) {
        try {
          const base64 = await convertToBase64Promise(document.file);
          files[document.key] = {
            filename: document.file.name,
            filedata: base64,
          };
        } catch (error) {
          console.log('Erro ao converter arquivo:', error);
        }
      }
    }

    dispatch(
      postOutsidesSocialTariffRequest({
        ...formData,
        residence: {
          ...formData.residence,
          complement: formData?.residence?.complement
            ? formData?.residence?.complement
            : undefined,
        },
        files,
      }),
    );
  };

  const handleGetStatesAddress = async () => {
    try {
      const data = (await getStatesAddressService(dataHistory?.cnp || '', {
        active: 'true',
        state: 'rio',
      })) as Array<any>;

      let array: any = [{ value: '', label: 'Selecione' }];

      data.map((state) => {
        array?.push({
          value: state?.id,
          label: state?.nome,
        });
      });

      setValuesStates(array);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleGetCitiesAddress = async (state: any) => {
    try {
      if (!state.value) {
        setFormData({
          ...formData,
          residence: { ...formData.residence, state: '', city: '' },
        });

        setValuesCities([{ value: '', label: 'Selecione' }]);

        let hiddenErrors = { ...formDataErrors };

        delete hiddenErrors?.residence?.city;

        setFormDataErrors(hiddenErrors);

        return;
      }

      setFormData({
        ...formData,
        residence: { ...formData.residence, state: state?.label },
      });

      let hiddenErrors = { ...formDataErrors };

      delete hiddenErrors?.residence?.state;

      setFormDataErrors(hiddenErrors);

      const data = (await getCitiesAddressService(dataHistory?.cnp || '', {
        active: 'true',
        state_id: state?.value?.toString(),
      })) as Array<any>;

      let array: any = [{ value: '', label: 'Selecione' }];

      data.map((state) => {
        array?.push({
          label: state?.nome,
          value: state?.id,
        });
      });

      setValuesCities(array);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleUpdateCitieAddress = async (city: any) => {
    setFormData({
      ...formData,
      residence: { ...formData.residence, city: city.value ? city?.label : '' },
    });

    let hiddenErrors = { ...formDataErrors };

    delete hiddenErrors?.residence?.city;

    setFormDataErrors(hiddenErrors);
  };

  return (
    <Main>
      {openInfoAddress && (
        <Dialog
          open={openInfoAddress}
          onClose={() => Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE)}
          fullScreen
        >
          <ModalInfoAddress
            onClose={() => Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE)}
          />
        </Dialog>
      )}
      {successSocialTariff && (
        <Dialog
          open={successSocialTariff}
          onClose={() => {
            dispatch(postErrorCloseOutsidesSocialTariff());
            Navigation.navigate(RouteName.LANDING);
          }}
          fullScreen
        >
          <ModalSuccess
            onClose={() => {
              dispatch(postErrorCloseOutsidesSocialTariff());
              Navigation.navigate(RouteName.LANDING);
            }}
          />
        </Dialog>
      )}
      {error && (
        <Modal open type="error" title="Ops!" v3>
          <ModalError
            onClose={() => dispatch(postErrorCloseOutsidesSocialTariff())}
          />
        </Modal>
      )}
      {openTerm && (
        <Modal open={openTerm} type="info" v3 hidden maxWidth={670}>
          <ModalTerm
            onClose={() => setOpenTerm(false)}
            termResponsibility={termResponsibility}
            setTermResponsibility={setTermResponsibility}
          />
        </Modal>
      )}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.LANDING),
            },
            {
              label: 'Tarifa social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Tarifa social</h1>
        <Margin height={34} />
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <p className="description">
          A tarifa social nos serviços públicos de abastecimento de água e
          esgotamento sanitário é um benefício concedido à clientes consumidores das
          concessionárias do Grupo Iguá, onde há normativa oficial, publicada pelo
          poder concedente, para tal aplicação.
          <br /> <br />
          Para as matrículas localizadas no Rio de Janeiro e Cuiabá, o cliente
          titular poderá fazer a solicitação do benefício clicando em "Solicitar
          tarifa social”. Para demais regiões, deve ser consultado a operação através
          dos nossos canais de atendimento. <br /> <br />
          <button
            type="button"
            onClick={() => {
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Formulário] – Detalhes de cada região`,
              });
              Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDEDETAIL);
            }}
          >
            Confira mais detalhes de cada região{' '}
          </button>
        </p>
        <ContainerBorder>
          <Border />
          <h2>Dados do titular</h2>
          <Border />
        </ContainerBorder>
        <Margin height={8} />
        <ContentInputs>
          <ContainerInputs>
            <Input label="CPF/CNPJ*" name="cnp" value={formData?.cnp} disabled />
            <Input
              label="Matrícula*"
              name="code"
              value={dataHistory?.code}
              disabled
            />
          </ContainerInputs>
          <Input
            label="Nome completo*"
            name="holder.fullName"
            value={formData?.holder?.fullName}
            onChange={(ev) => handleChange(ev)}
            maxLength={50}
            error={!!formDataErrors?.holder?.fullName}
            errorText={formDataErrors?.holder?.fullName}
          />
          <ContainerInputs>
            <Input
              label="RG*"
              name="holder.document"
              value={formData?.holder?.document}
              onChange={(ev) => handleChange(ev)}
              error={
                !!(formData?.holder?.document && rgError) ||
                !!formDataErrors?.holder?.document
              }
              errorText={
                (formDataErrors?.holder?.document ? 'Preencher campo' : '') ||
                (rgError ? 'RG inválido' : 'Preencher campo' || '')
              }
              maxLength={9}
            />
            <Input
              label="Data de nascimento*"
              type="date"
              name="holder.birthDate"
              value={formData?.holder?.birthDate}
              onChange={(ev) => handleChange(ev)}
              min={moment(get100).format('YYYY-MM-DD')}
              max={moment(get18).format('YYYY-MM-DD')}
              error={
                !!(formData?.holder?.birthDate && birthDateError) ||
                !!formDataErrors?.holder?.birthDate
              }
              errorText={
                (formDataErrors?.holder?.birthDate ? 'Preencher campo' : '') ||
                (birthDateError ? 'Data de nascimento inválido' : '')
              }
            />
          </ContainerInputs>
        </ContentInputs>
        <Margin height={58} />
        <ContainerBorder>
          <Border />
          <h2>Dados de contato</h2>
          <Border />
        </ContainerBorder>
        <Margin height={8} />
        <ContainerInputs>
          <Input
            label="Email*"
            name="holder.email"
            value={formData?.holder?.email}
            onChange={(ev) => {
              handleChange(ev);
              setIsEmail(formatter.emailValidation(ev.target.value));
            }}
            error={
              !!(formData?.holder?.email && !isEmail) ||
              !!formDataErrors?.holder?.email
            }
            errorText={
              (formDataErrors?.holder?.email ? 'Preencher campo' : '') ||
              (!isEmail ? 'E-mail inválido' : 'Preencher campo' || '')
            }
          />
          <Input
            label="Celular principal*"
            name="holder.cellphone"
            value={formData?.holder?.cellphone}
            onChange={(ev) => handleChange(ev)}
            error={
              !!(formData?.holder?.cellphone && phoneError) ||
              !!formDataErrors?.holder?.cellphone
            }
            errorText={
              (formDataErrors?.holder?.cellphone ? 'Preencher campo' : '') ||
              (phoneError ? 'Celular inválido' : 'Preencher campo' || '')
            }
          />
        </ContainerInputs>
        <Margin height={58} />
        <ContainerBorder>
          <Border />
          <h2>Dados do imóvel</h2>
          <Border />
        </ContainerBorder>
        <Margin height={8} />
        <span className="label">Preencha os dados referentes ao imóvel:</span>
        <Margin height={24} />
        <FormImmobile
          typesSocialTariffSelected={typesSocialTariffSelected}
          setTypesSocialTariffSelected={setTypesSocialTariffSelected}
          residence={formData?.residence}
          setFormDataResidenceType={(dataResidenceType: string) => {
            let hiddenErrors = { ...formDataErrors };
            delete hiddenErrors?.residence?.bond;
            delete hiddenErrors.files;

            if (dataResidenceType) delete hiddenErrors?.residence?.type;
            setFormDataErrors(hiddenErrors);

            setFormData({
              ...formData,
              residence: {
                ...formData.residence,
                type: dataResidenceType,
                bond: dataResidenceType ? formData?.residence?.bond : '',
              },
            });
          }}
          setFormDataResidenceBond={(dataResidenceBond: string) => {
            if (dataResidenceBond) {
              let hiddenErrors = { ...formDataErrors };

              delete hiddenErrors?.residence?.bond;
              setFormDataErrors(hiddenErrors);
            }

            setFormData({
              ...formData,
              residence: { ...formData.residence, bond: dataResidenceBond },
            });
          }}
          errors={{
            type: formDataErrors?.residence?.type,
            bond: formDataErrors?.residence?.bond,
            state: formDataErrors?.residence?.state,
            city: formDataErrors?.residence?.city,
            zipCode: formDataErrors?.residence?.zipCode,
            neighborhood: formDataErrors?.residence?.neighborhood,
            street: formDataErrors?.residence?.street,
            number: formDataErrors?.residence?.number,
          }}
          setFormDataErrors={setFormDataErrors}
          renovation={dataHistory?.renovation}
          dataHistory={dataHistory}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          setOpenInfoAddress={setOpenInfoAddress}
          valuesStates={valuesStates}
          valuesCities={valuesCities}
          handleGetCitiesAddress={handleGetCitiesAddress}
          handleUpdateCitieAddress={handleUpdateCitieAddress}
          hasTypeSolicitation={!!typeSolicitation}
        />
        {typesSocialTariffSelected?.value === 'benefitRenewal' && (
          <>
            <Margin height={24} />
            <StatusInfo status="Atenção">
              Ao selecionar essa opção, estará declarando que não houve alteração dos
              dados de moradia informados na solicitação anterior. Em caso de
              alteração, selecione a opção referente ao tipo de tarifa social atual.
            </StatusInfo>
          </>
        )}
        {typesSocialTariffSelected && (
          <>
            <Margin height={58} />
            <ContainerBorder>
              <Border />
              <h2>Anexos</h2>
              <Border />
            </ContainerBorder>
            <Margin height={8} />
            <span className="label">
              O responsável pela família deve apresentar os seguintes documentos, de
              acordo com as instruções ao lado de cada campo:
            </span>
            <Margin height={24} />
            <ContainerDocuments>
              {React.Children.toArray(
                documents.map((document, index: number) => (
                  <RowDocument>
                    <SenFiles>
                      <DropZone
                        disabled={!!document?.file}
                        infoFileName={document?.label}
                        name={document?.label}
                        content={document?.file || null}
                        error={
                          formDataErrors?.files && document?.key
                            ? formDataErrors.files[document.key] || ''
                            : ''
                        }
                        onFileUploaded={(file: File | null) => {
                          const array = [...documents];

                          array[index].file = file;

                          setDocuments(array);

                          let hiddenErrors = { ...formDataErrors };

                          if (hiddenErrors && hiddenErrors?.files) {
                            delete hiddenErrors?.files[array[index]?.key];
                          }

                          setFormDataErrors(hiddenErrors);
                        }}
                      />
                    </SenFiles>
                    <Label>{document.description}</Label>
                  </RowDocument>
                )),
              )}
            </ContainerDocuments>
          </>
        )}
        <BoxAccepteds>
          <Margin height={24} />
          <AcceptedWarning>
            Para finalizar a solicitação, leia com atenção os termos a seguir:
          </AcceptedWarning>
          <Margin height={48} />
          <ContainerTerms>
            <InputCheckedV3
              checked={termResponsibility}
              onChange={(e) => setTermResponsibility(e.target.checked)}
            >
              <p className="term">
                Li e aceito o{' '}
                <button type="button" onClick={() => setOpenTerm(true)}>
                  termo de responsabilidade
                </button>
              </p>
            </InputCheckedV3>
          </ContainerTerms>
          <Margin height={10} />
          <ContainerTerms>
            <InputCheckedV3
              checked={termDeclaration}
              onChange={(e) => setTermDeclaration(e.target.checked)}
            >
              <p className="term">
                Declaro que são verdadeiras as informações aqui prestadas, para ﬁns
                de análise de viabilidade de concessão de Tarifa Social, no que se
                refere aos serviços de abastecimento de água e esgotamento sanitário
                prestados pela Iguá Rio de Janeiro.
              </p>
            </InputCheckedV3>
          </ContainerTerms>
        </BoxAccepteds>
        <Margin height={56} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.green}
            onClick={() => {
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Formulário] - Voltar`,
              });
              dispatch(getErrorCloseOutsidesSocialTariffEnrollments());
              Navigation.goBack();
            }}
            backIcon
            id="voltar-da-tela-de-formulario"
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={
              !termResponsibility || !termDeclaration
                ? Color.grayLightSeconde
                : Color.blueSeconde
            }
            onClick={handleSolicitation}
            nextIcon
            loading={loading}
            id="solicitar-recadastramento-da-tela-de-formulario"
          >
            Solicitar <br /> recadastramento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SocialTariffOutsideSolicitation;
