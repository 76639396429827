import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  border-radius: 24px;
  background: ${Color.white};
  position: relative;

  p {
    color: ${Color.gray};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  gap: 28px;

  button {
    width: 100%;
    max-width: 201px;
    border-radius: 8px;
    border: 2px solid ${Color.blueSeconde};
    background: ${Color.white};
    height: 48px;
    padding: 0px 16px;
    color: ${Color.blueSeconde};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    :last-of-type {
      background: ${Color.blueSeconde};
      color: ${Color.white};
    }
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    margin-top: 40px;
  }
`;
