import React, { FC } from 'react';
import {
  Container,
  ContainerDescription,
  InfoText,
  TransactionText,
} from './styles';
import AlertPixFraudSVG from '~/assets/Icons/AlertPixFraud';

interface IProps {
  company: string;
  cnpj: string;
}

const AlertPixFraud: FC<IProps> = ({ company, cnpj }) => {
  return (
    <Container>
      <AlertPixFraudSVG />
      <ContainerDescription>
        <TransactionText>Dados da transação</TransactionText>
        <InfoText>{company}</InfoText>
        <InfoText>{cnpj}</InfoText>
      </ContainerDescription>
    </Container>
  );
};

export default AlertPixFraud;
