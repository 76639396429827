import { IHistoryData } from '~/store/modules/enrollmentsConsumptionHistory/types';

const sortConsumptionHistoryData = (data?: IHistoryData[], asc = true) => {
  if (!data) return [];

  if (asc)
    return data.sort(
      (a: IHistoryData, b: IHistoryData) =>
        new Date(a.read_date_reference).getTime() -
        new Date(b.read_date_reference).getTime(),
    );

  return data.sort(
    (a: IHistoryData, b: IHistoryData) =>
      new Date(b.read_date_reference).getTime() -
      new Date(a.read_date_reference).getTime(),
  );
};

export { sortConsumptionHistoryData };
