import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Button } from '~/common';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import {
  BoxUser,
  BoxUserInfo,
  BoxUserName,
  ButtonWrapper,
  Container,
  ContainerLinkButton,
  ContainerUserInfo,
  Text,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import ChangeAccountSVG from '~/assets/Icons/changeAccount';
import ConfiguracoesConta from '~/assets/Icons/ConfiguracoesConta';
import {
  clearModalAuth,
  getEnrollmentsAuthDestroySessionRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import { Margin } from '~/components/Margin';
import ExitSVG from '~/assets/Icons/Exit';
import { Color } from '~/styles';
import { checkToggle } from '~/utils/toggles';
import { clearRouteReportLackWater } from '~/store/modules/outsides/actions';

const ModalSession: FC = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: IState) => state.enrollmentsAuth.token);
  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );
  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const [reRegistration, setReRegistration] = useState<boolean>(false);

  const verifyTogglesMe = async () => {
    setReRegistration(await checkToggle(togglesMe, 'RE_REGISTRATION_2'));
  };

  useEffect(() => {
    verifyTogglesMe();
  }, [togglesMe]);

  const navigateRoute = (route: string) => {
    dispatch(clearModalAuth());
    Navigation.navigate(route);
  };

  return (
    <Container token={!!token}>
      {token ? (
        <ContainerUserInfo>
          <BoxUser>
            <BoxUserName>
              {enrollmentsUserInfo?.fullName?.substring(0, 1)}
            </BoxUserName>
            <Margin width={16} />
            <BoxUserInfo>
              <p>{enrollmentsUserInfo?.fullName?.toLocaleLowerCase()}</p>
              {isClient && (
                <>
                  <Margin height={6} />
                  <p>
                    Matrícula: <span>{registrationCheck?.code}</span>
                  </p>
                  <p>{registrationCheck?.address_connection}</p>
                </>
              )}
            </BoxUserInfo>
          </BoxUser>
          <Margin height={32} />
          {!isClient && !isEntrepreneur && !isManager && reRegistration && (
            <ContainerLinkButton
              type="button"
              onClick={() => {
                Navigation.navigate(RouteName.LINKENROLLMENT);
                ReactGA.event({
                  category: 'Recadastramento',
                  action: `[Cliente Não Saneamento] – Vincular Matricula`,
                });
              }}
            >
              <ChangeAccountSVG color={Color.blueSeconde} />
              Vincular matrícula
            </ContainerLinkButton>
          )}
          {isClient && (
            <ContainerLinkButton
              onClick={() => navigateRoute(RouteName.REGISTRATIONLIST)}
              type="button"
            >
              <ChangeAccountSVG color="#02505B" />
              Trocar matrícula
            </ContainerLinkButton>
          )}
          <ContainerLinkButton
            onClick={() => navigateRoute(RouteName.ACCOUNTCONFIG)}
            type="button"
          >
            <ConfiguracoesConta color="#02505B" />
            Alterar dados cadastrais
          </ContainerLinkButton>
          <ContainerLinkButton
            onClick={() => {
              ReactGA.event({
                category: 'Perfil',
                action: 'Saindo da Conta',
              });

              localStorage.clear();
              dispatch(getEnrollmentsAuthDestroySessionRequest());
              Navigation.navigate(RouteName.LOGIN);
            }}
            type="button"
            style={{ color: Color.gray }}
          >
            <ExitSVG />
            Sair da conta
          </ContainerLinkButton>
        </ContainerUserInfo>
      ) : (
        <>
          <Text>
            Para ter uma experiência <br />
            personalizada, acesse sua conta!
          </Text>
          <ButtonWrapper>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch(clearRouteReportLackWater());
                navigateRoute(RouteName.LOGIN);
              }}
            >
              Entrar
            </Button>
            <Button
              onClick={() => {
                dispatch(clearRouteReportLackWater());
                navigateRoute(RouteName.REGISTERPROFILE);
              }}
            >
              Cadastrar
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Container>
  );
};

export default ModalSession;
