import React from 'react';

import { history } from '~/store';
import { RegisterClient } from './RegisterClient';

export interface IDataRegister {
  clientType?: 'client' | 'notClient' | 'entrepreneur';
  cnp?: string;
  phone?: string;
  email?: string;
  systemTerms?: boolean;
  productTerms?: boolean;
  method?: 'sms' | 'email';
  token?: string;
  name?: string;
  documentType?: 'CPF' | 'CNPJ';
  telephone?: string;
}

const Register: React.FC = () => {
  const dataRegister = history.location.state as IDataRegister;

  const typesMap: Map<string | undefined, React.JSX.Element> = new Map([
    ['client', <RegisterClient />],
  ]);

  return typesMap.get(dataRegister?.clientType) || typesMap.get('client');
};

export default Register;
