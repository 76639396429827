import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 22px 54px 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 118px 20px 19px 20px;
  }
`;

export const ContainerWalletDetail = styled.div`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  }
`;

export const Header = styled.div`
  height: 81px;
  background: ${Color.green};
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: ${Color.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 112px;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 32px 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    align-items: flex-start;
    padding: 0 85px 343px 55px;
  }
`;

export const ContainerIconBack = styled.button`
  margin-left: 70px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ContainerSeparator = styled.div`
  width: 330px;
  padding: 0 70px;
  margin-left: 45px;
`;

export const Separator = styled.div`
  width: 100%;
  border: 1px solid ${Color.grayLight};
`;

export const ContainerInfoCard = styled.div`
  width: calc(100% - 14px);
  display: flex;
  justify-content: space-between;
  padding: 0 7px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 70px;
    justify-content: flex-start;
  }
`;

export const BrandName = styled.p`
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.035em;
  color: ${Color.black};
  text-transform: capitalize;
`;

export const ContainerCardNumber = styled.div`
  width: calc(100% - 140px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
`;

export const CardNumber = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.035em;
  color: #000000;
`;

export const ItemInfoCard = styled.div`
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid ${Color.gray};
  padding: 14px 7px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 70px;
    border: none;
  }
`;

export const TitleItemInfoCard = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.035em;
  color: ${Color.black};
`;

export const LabelItemInfoCard = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.035em;
  color: ${Color.gray};
`;

export const ContainerChangeCard = styled.div`
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;

  button {
    font-size: 14px;
    font-weight: 500;
  }
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 70px;
    justify-content: flex-start;
  }
`;
