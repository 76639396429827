import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Main, Button, ValueDisplay, LimitText, ContainerLimitText } from './styles';
import { DecrementButtonIcon, IncrementButtonIcon } from '../Icons';
import formatter from '~/utils/formatter';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface ITypePaymentProps {
  minLimit: number;
  maxLimit: number;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
}

const InputSimulation: FC<ITypePaymentProps> = ({
  minLimit,
  maxLimit,
  value,
  setValue,
}) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const handleDecrement = () => {
    if (value > minLimit) setValue(value - 1);
  };

  const handleIncrement = () => {
    if (value < maxLimit) setValue(value + 1);
  };

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    console.log({ ev: formatter.formatarNumber(ev.target.value) });
    // setValue(Number(formatter.formatarNumber(ev.target.value)));
  };

  return (
    <>
      {isMobile && (
        <ContainerLimitText>
          <LimitText>Min: {formatter.formatCurrency(minLimit)}</LimitText>
          <LimitText>Máx: {formatter.formatCurrency(maxLimit)}</LimitText>
        </ContainerLimitText>
      )}
      <Main>
        {!isMobile && (
          <LimitText>Min: {formatter.formatCurrency(minLimit)}</LimitText>
        )}
        <Button onClick={handleDecrement}>
          <DecrementButtonIcon />
        </Button>
        <ValueDisplay
          value={formatter.formatCurrency(value)}
          onChange={handleOnChange}
        />
        <Button onClick={handleIncrement}>
          <IncrementButtonIcon />
        </Button>
        {!isMobile && (
          <LimitText>Máx: {formatter.formatCurrency(maxLimit)}</LimitText>
        )}
      </Main>
    </>
  );
};

export default InputSimulation;
