import { Color } from '~/styles';

export const verifyStatus = (status: string) => {
  const statuses: any = {
    'Em análise': { color: Color.yellow },
    'Revisar documentos': { color: Color.red },
    'Aguardando pagamento': { color: Color.blueSeconde },
    'Boleto vencido': { color: Color.red },
    'Em elaboração de informe técnico': { color: Color.blueSeconde },
    'Em trâmite': { color: Color.blueSeconde },
    'Declaração Emitida': { color: Color.green },
    'Declaração emitida': { color: Color.green },
    'Cancelado.': { color: Color.gray },
    default: { color: Color.gray },
  };

  if (!Object.keys(statuses).includes(status)) return statuses.default;

  return statuses[status];
};
