import React from 'react';
import { useSelector } from 'react-redux';

import {
  Main,
  ProfileButton,
  ButtonTitles,
  ButtonTitle,
  ButtonSubtitle,
  Wrapper,
} from './styles';
import Navigation from '~/utils/Navigation';
import ArrowRightSVG from '~/assets/Icons/arrowRight';
import { Color } from '~/styles';
import HomeUser from '~/assets/Icons/HomeUser';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import EntrepreneurUser from '~/assets/Icons/EntrepreneurUser';
import { Card } from '~/common/Card';
import { ButtonLink } from '~/common';
import { Margin } from '~/components/Margin';
import ArrowBackCurvedSVG from '~/assets/Icons/ArrowBackCurvedSGV';
import { history } from '~/store';
// import ManagerUser from '~/assets/Icons/ManagerUser';

const RegisterProfile: React.FC = () => {
  const dataLogin = history?.location?.state as any;

  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );

  const handleNavigation = (path: string, state: any): any => {
    if (path === RouteName.HOME && registrations && registrations.length > 1)
      return Navigation.navigate(RouteName.REGISTRATIONLIST);

    return Navigation.navigate(path, state);
  };

  return (
    <Main>
      <Card
        title="CRIAR SEU CADASTRO"
        label="Acesse nossos serviços de forma fácil e tenha uma experiência personalizada!"
        style={{ maxWidth: '570px', maxHeight: '520px', minHeight: '440px' }}
      >
        <Wrapper>
          <Margin height={30} />
          <ProfileButton
            onClick={() =>
              handleNavigation(RouteName.REGISTER, {
                clientType: 'client',
                cnp: dataLogin?.cnp || '',
              })
            }
          >
            <HomeUser color={Color.blueSeconde} />
            <ButtonTitles>
              <ButtonTitle>PARA CLIENTE</ButtonTitle>
              <ButtonSubtitle>
                Para sua casa, seu condomínio e <br /> sua empresa.
              </ButtonSubtitle>
            </ButtonTitles>
            <ArrowRightSVG color={Color.blueSeconde} />
          </ProfileButton>

          {/* <ProfileButton>
            <ManagerUser color={Color.grayLight} />
            <ButtonTitles>
              <ButtonTitle disabled>PARA GESTOR</ButtonTitle>
              <ButtonSubtitle>
                Para gestão de imobiliárias e <br /> condominios
              </ButtonSubtitle>
            </ButtonTitles>
            <ArrowRightSVG color={Color.grayLight} />
          </ProfileButton> */}

          <ProfileButton
            onClick={() =>
              handleNavigation(RouteName.REGISTER, {
                clientType: 'entrepreneur',
                cnp: dataLogin?.cnp || '',
              })
            }
          >
            <EntrepreneurUser color={Color.blueSeconde} />
            <ButtonTitles>
              <ButtonTitle>PARA EMPREENDIMENTO</ButtonTitle>
              <ButtonSubtitle>
                Para solicitações de viabilidade <br /> (DPA e DPE)
              </ButtonSubtitle>
            </ButtonTitles>
            <ArrowRightSVG color={Color.blueSeconde} />
          </ProfileButton>

          <Margin height={52} />

          <ButtonLink
            type="button"
            color="gray"
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: Color.blueIntense,
              textDecoration: 'underline',
              display: 'flex',
            }}
            onClick={() => Navigation.navigate(RouteName.LOGIN)}
          >
            <>
              <ArrowBackCurvedSVG fill={Color.blueIntense} />
              <Margin width={4} />
              Voltar
            </>
          </ButtonLink>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default RegisterProfile;
