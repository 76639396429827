import { Reducer } from 'redux';

import {
  RepositoriesTypesOutsidesCredentials,
  IRepositoriesStateOutsidesCredentials,
} from './types';

const INITIAL_STATE: IRepositoriesStateOutsidesCredentials = {
  data: null,
};

const reducer: Reducer<IRepositoriesStateOutsidesCredentials, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesOutsidesCredentials.OUTSIDES_EASY_INVOICES_CREDENTIALS:
      return {
        ...state,
        data: { ...action.payload.data },
      };

    default:
      return state;
  }
};

export default reducer;
