import React, { FC } from 'react';

import { BoxTerm, CloseIconButton, Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import CloseModalSVG from '~/assets/Icons/CloseModal';

interface IProps {
  onClose: () => void;
}

const ModalTerm: FC<IProps> = ({ onClose }) => {
  return (
    <Main>
      <CloseIconButton onClick={onClose} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <h3>Termo de cadastro de unidade imobiliária</h3>
      <p>Para finalizar a solicitação, leia com atenção os termos a seguir:</p>
      <Margin height={16} />
      <BoxTerm>
        <p>
          1. CONDIÇÕES GERAIS
          <br />
          <br />
          Declaro que sou o responsável legal e financeiro pelos serviços de
          fornecimento de água e esgotamento sanitário do imóvel localizado no
          endereço informando neste documento.
          <br />
          <br />
          Estou ciente das condições da prestação dos serviços fornecidos pela Iguá e
          dos custos envolvidos, inclusive nas hipóteses de liação suspensão e
          religação.
          <br />
          <br />
          Assumo a integral responsabilidade pelo pagamento das faturas mensais e
          todos os custos correlatos.
          <br />
          <br />
          Reconheço a possibilidade de ter os serviços interrompidos, caso não faça o
          pagamento da fatura mensal nos prazos de vencimento.
          <br />
          <br />
          As informações fornecidas neste documento são de minha responsabilidade e
          elas serão utilizadas para o cadastro do imóvel junto a concessionária Iguá
          Rio de Janeiro. Caso esses dados sejam contestados (por qualquer motivo ou
          por qualquer meio) ou sejam ilegais, caberá à concessionária o direito de
          suprimir a ligação dos serviços. A prestação dos serviços de abastecimento
          de água e esgotamento sanitário é regida pelo Contrato de Concessão nº0
          34/2021 e demais normas técnicas brasileiras, as quais a prestadora dos
          serviços está submetida. Os documentos estão disponíveis no site da Iguá:{' '}
          <a
            href="https://www.iguasa.com.br/rio-de-janeiro"
            target="_blank"
            rel="noreferrer"
          >
            www.iguasa.com.br/rio-de-janeiro
          </a>
          <br />
          <br />
          <br />
          2. RESPONSABILIDADE DO CLIENTE (USUÁRIO DO SERVIÇO)
          <br />
          <br />
          A responsabilidade pela manutenção e conservação da rede interna do imóvel,
          necessária ao fornecimento de água e coleta do esgoto;
          <br />
          <br />
          Qualquer reparação de defeito da rede interna, que ocasione perda ou
          vazamento de água e esgoto;
          <br />
          <br />
          Permitir o livre acesso de representantes da prestadora dos serviços ao
          medidor de consumo;
          <br />
          <br />
          A instalação prévia de abrigo do cavalete de ligação de água.
          <br />
          3. NÃO É PERMITIDO
          <br />
          <br />
          Conectar as instalações prediais de água em tubulações que não sejam de
          propriedade da Iguá Rio de Janeiro;
          <br />
          <br />
          Executar derivação em canalizações da instalação predial de água para
          abastecimento de outro imóvel, mesmo que seja de sua propriedade;
          <br />
          <br />
          Usar nas instalações prediais quaisquer dispositivos que possam prejudicar
          o sistema de abastecimento de água;
          <br />
          <br />
          Usar dispositivos no medidor de água que possam comprometer a precisão na
          medição do consumo;
          <br />
          <br />
          Violar o selo do medidor de água, bem como o lacre de instalação colocado
          no cavalete;
          <br />
          <br />
          Instalar dispositivo ramal predial ou na instalação que provoque sucção na
          rede distribuidora.
        </p>
      </BoxTerm>
      <Margin height={30} />
      <ButtonText
        text="Ok"
        typeBtn="content"
        onClick={onClose}
        className="button-green"
      />
    </Main>
  );
};

export default ModalTerm;
