/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';

import formatter from '~/utils/formatter';

import { BoxInputs, Main } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  clearProtocolCustomerObligationRequest,
  patchProtocolCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { ContainerButton, Subtitle } from '../../../styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';

const Form: FC = () => {
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const loadingDarm = useSelector(
    (item: IState) => item.customerObligation.loadingDarmCode,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);

  const [process, setProcess] = useState<string>('');
  const [datePlenaryApproval, setDatePlenaryApproval] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const [disabledDate, setDisabledDate] = useState(true);

  const handleClick = () => {
    dispatch(
      patchProtocolCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        cityHallCaseNumber: process,
        plenaryApprovalDate: datePlenaryApproval,
      }),
    );
  };

  useEffect(() => {
    if (
      datePlenaryApproval.length === 10 &&
      process.length === 14 &&
      !disabledDate
    ) {
      return setDisabled(false);
    }
    return setDisabled(true);
  }, [process, datePlenaryApproval, disabledDate]);

  useEffect(
    () => () => {
      dispatch(clearProtocolCustomerObligationRequest());
    },
    [],
  );

  return (
    <>
      {!isRevision && (
        <>
          <Subtitle>
            Preencha e confira as informações abaixo para a solicitação de DARM.
          </Subtitle>
          <Margin height={64} />
        </>
      )}
      <Main>
        <BoxInputs>
          <Input
            styleInput={{ width: `35%` }}
            label="Número do processo da prefeitura"
            value={process}
            onChange={(ev: any) => {
              setProcess(formatter.darmProcessNumber(ev.target.value));
            }}
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: `35%` }}
            label="Data de aprovação de plenário"
            value={datePlenaryApproval}
            type="date"
            max={new Date().toISOString().split('T')[0]}
            onChange={(ev: any) => {
              const date = formatter.formatDate(ev.target.value);
              if (new Date(date) > new Date()) {
                setDatePlenaryApproval(date);
                return setDisabledDate(true);
              }

              setDatePlenaryApproval(date);
              return setDisabledDate(false);
            }}
          />
        </BoxInputs>
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 3,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingDarm}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default Form;
