import { Dispatch } from 'redux';
import {
  deleteEnrollmentsAlertService,
  getEnrollmentsAlertsService,
  patchEnrollmentsAlertService,
  postEnrollmentsCreateAlertService,
} from '~/services/alerts';
import { IState } from '../types';
import {
  IRequestCreateAlert,
  IRequestPatchAlert,
  IResponseGetAlerts,
  RepositoriesTypesEnrollmentsAlerts,
} from './types';

const getEnrollmentsAlertsRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_REQUEST,
    });

    const response = (await getEnrollmentsAlertsService()) as IResponseGetAlerts;

    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

const postEnrollmentsCreateAlertRequest =
  (data: IRequestCreateAlert) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await postEnrollmentsCreateAlertService(data, registration);

      const response = (await getEnrollmentsAlertsService()) as IResponseGetAlerts;

      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
          },
        },
      });
    }
  };

const postSuccessCloseEnrollmentsCreateAlertRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_SUCCESS_CLOSE,
    });
  };

const postErrorCloseEnrollmentsCreateAlertRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_CREATE_POST_METHOD_FAILURE_CLOSE,
    });
  };

const deleteEnrollmentsAlertRequest =
  (alertId: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await deleteEnrollmentsAlertService(registration, alertId);

      const response = (await getEnrollmentsAlertsService()) as IResponseGetAlerts;

      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const deleteSuccessCloseEnrollmentsAlertRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_SUCCESS_CLOSE,
    });
  };

const deleteErrorCloseEnrollmentsAlertRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesEnrollmentsAlerts.ALERTS_DELETE_METHOD_FAILURE_CLOSE,
  });
};

const patchEnrollmentsAlertRequest =
  (data: IRequestPatchAlert) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await patchEnrollmentsAlertService(registration, data);

      const response = (await getEnrollmentsAlertsService()) as IResponseGetAlerts;

      dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_GET_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const patchSuccessCloseEnrollmentsAlertRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_SUCCESS_CLOSE,
    });
  };

const patchErrorCloseEnrollmentsCreateAlertRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesEnrollmentsAlerts.ALERTS_UPDATE_PATCH_METHOD_FAILURE_CLOSE,
    });
  };

export {
  getEnrollmentsAlertsRequest,
  postEnrollmentsCreateAlertRequest,
  postSuccessCloseEnrollmentsCreateAlertRequest,
  postErrorCloseEnrollmentsCreateAlertRequest,
  deleteEnrollmentsAlertRequest,
  deleteSuccessCloseEnrollmentsAlertRequest,
  deleteErrorCloseEnrollmentsAlertRequest,
  patchEnrollmentsAlertRequest,
  patchSuccessCloseEnrollmentsAlertRequest,
  patchErrorCloseEnrollmentsCreateAlertRequest,
};
