import styled, { css } from 'styled-components';
import { Color } from '~/styles';
import { SituationAccount } from '~/store/modules/automaticDebits/types';

export const Container = styled.button<SituationAccount>`
  width: 100%;
  height: 89px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  border: 1px solid #c7c9c7;
  position: relative;
  cursor: pointer;
  background: none;
`;

export const Border = styled.div<SituationAccount>`
  width: 16px;
  height: 100%;
  position: absolute;

  ${({ situation }) =>
    situation === 'Aguardando Confirmação' &&
    css`
      background: ${Color.yellow};
    `};

  ${({ situation }) =>
    (situation === 'Cadastrado' || situation === 'Confirmado') &&
    css`
      background: ${Color.green};
    `};

  ${({ situation }) =>
    (situation === 'Recusado' || situation === 'Enviado Exclusão') &&
    css`
      background: ${Color.red};
    `};

  ${({ situation }) =>
    situation === 'Excluído' &&
    css`
      background: ${Color.grayLightSeconde};
    `};
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  span {
    position: absolute;
    top: 9px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.035em;
    color: #003c28;
  }
`;

export const TextNameBank = styled.p<SituationAccount>`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  ${({ situation }) =>
    situation === 'Aguardando Confirmação' &&
    css`
      color: ${Color.yellow};
    `};

  ${({ situation }) =>
    (situation === 'Cadastrado' || situation === 'Confirmado') &&
    css`
      color: ${Color.green};
    `};

  ${({ situation }) =>
    (situation === 'Recusado' || situation === 'Enviado Exclusão') &&
    css`
      color: ${Color.red};
    `};

  ${({ situation }) =>
    situation === 'Excluído' &&
    css`
      color: ${Color.grayLightSeconde};
    `};
`;
