import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { Card, Main } from './styles';
import EmptyMessage from '../EmptyMessage';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';

interface Props {
  title: string;
}

export const CardNotClient: FC<Props> = ({ title }) => {
  return (
    <Main>
      <Card>
        <h1>{title}</h1>
        <div>
          <EmptyMessage />
          <p>
            Nenhuma matrícula vinculada ao seu CPF/
            <br />
            CNPJ
            <br />
            <button
              type="button"
              onClick={() => {
                Navigation.navigate(RouteName.LINKENROLLMENT);
                ReactGA.event({
                  category: 'Recadastramento',
                  action: `[Cliente Não Saneamento] – Vincular Matricula`,
                });
              }}
            >
              Vincular matrícula
            </button>
          </p>
        </div>
      </Card>
    </Main>
  );
};
