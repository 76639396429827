/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import ReactGA from 'react-ga4';

import AlertSVG from '~/assets/Icons/alert';
import ConsumeSVG from '~/assets/Icons/consume';
import CalendarSVG from '~/assets/Icons/calendar';
import PixSVG from '~/assets/Icons/pix';
import CreditCardSVG from '~/assets/Icons/CreditCardSVG';
import ReloadSVG from '~/assets/Icons/reload';
import { Margin } from '~/components/Margin';
import { statusColor } from '~/utils/invoiceStatus';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { IState } from '~/store/modules/types';
import { IInvoices } from '~/store/modules/enrollmentsInvoices/types';
import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';

import {
  CardIColumn,
  CardInvoHeader,
  CardInvoiceBody,
  CardInvoiceTitle,
  CardInvoiceTitleText,
  CardInvoiceValue,
  CardIText,
  CardIValueText,
  CardPay,
  CCol,
  Container,
  ContainerAutomaticDebit,
  ContainerInvoiceDetails,
  CRow,
  InvoiceBtnDetails,
  PayText,
  Separator,
} from './styles';
import SkeletonCustom from '~/components/Skeleton';
import moment from 'moment';
import formatter from '~/utils/formatter';
import AutomaticDebitSVG from '~/assets/Icons/AutomaticDebit';
import { activateModalAutomaticDebits } from '~/store/modules/automaticDebits/actions';
import { existsAccountDebt } from '~/utils/verifyAccountExists';
import { checkToggle } from '~/utils/toggles';

interface IProp extends IInvoices {
  open?: any;
}

export default function CardInvoice({
  open,
  invoice_id,
  status,
  amount,
  billed_consumption,
  maturity_date,
  issue_date_hour,
}: IProp) {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item.enrollmentsInvoices.loading);
  const toggles = useSelector((item: IState) => item.togglesMe.data);
  const data = useSelector((item: IState) => item.automaticDebits.data);
  const [existPix, setExistPix] = useState<boolean>(false);

  const isOpen = open ? open - 1 : 0;

  const verifyToggles = async () => {
    setExistPix(await checkToggle(toggles, 'PIX'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const detailInvoice = (paymentMethod: string) => {
    if (existsAccountDebt(data, maturity_date)) {
      dispatch(activateModalAutomaticDebits({ invoice_id }));
    } else {
      dispatch(getEnrollmentsInvoicesDetailRequest(invoice_id, paymentMethod));
    }
  };

  return (
    <div
      style={{
        padding: '25px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {loading ? (
        <div style={{ width: '100%' }}>
          <SkeletonCustom height={isMobile ? 216 : 353} />
        </div>
      ) : (
        <Container>
          <CardInvoiceTitle
            isLoading={!invoice_id}
            style={{
              backgroundColor: !invoice_id ? Color.gray : statusColor(status?.label),
            }}
          >
            <CardInvoiceTitleText>
              {status?.label ? status?.label : 'Sem faturas'}
            </CardInvoiceTitleText>
          </CardInvoiceTitle>
          <CardInvoHeader>
            <CardIColumn>
              <CardIText>
                {issue_date_hour &&
                  `${format(new Date(issue_date_hour), 'MMMM', {
                    locale: pt,
                  })} de ${format(new Date(issue_date_hour), 'yyyy')}`}
              </CardIText>
            </CardIColumn>
            {status?.label && status?.label !== 'QUITADA' && (
              <CardIColumn>
                {isOpen !== 0 && (
                  <CardInvoiceValue>
                    {status?.label === 'PROCESSANDO' ? (
                      <>
                        <CardIText style={{ marginLeft: 20 }}>
                          Processando pagamento
                        </CardIText>
                      </>
                    ) : (
                      <>
                        {invoice_id && <AlertSVG />}
                        <CardIText style={{ marginLeft: 20 }}>
                          + {open && isOpen} EM ABERTO
                        </CardIText>
                      </>
                    )}
                  </CardInvoiceValue>
                )}
              </CardIColumn>
            )}
          </CardInvoHeader>
          <CardInvoiceBody>
            <CardIColumn style={{ justifyContent: 'flex-start' }}>
              <div>
                <CardIValueText
                  isLoading={!invoice_id}
                  style={{
                    color: !invoice_id ? Color.gray : statusColor(status?.label),
                  }}
                  className="value"
                >
                  {!invoice_id ? '-' : formatter.formatCurrency(amount)}
                </CardIValueText>
                <CRow>
                  <ConsumeSVG />
                  <CCol>
                    <span>Consumo:</span>
                    <span className="value">
                      {!invoice_id ? '-' : billed_consumption}
                    </span>
                  </CCol>
                </CRow>
                <CRow>
                  <CalendarSVG />
                  <CCol>
                    <span>Vencimento:</span>
                    <span className="value">
                      {!invoice_id ? '-' : moment(maturity_date).format('DD/MM/YY')}
                    </span>
                  </CCol>
                </CRow>
              </div>
            </CardIColumn>
            {status?.label !== 'QUITADA' ? (
              <CardIColumn style={{ justifyContent: 'flex-start' }}>
                <PayText>{!invoice_id ? 'AGUARDE!' : 'Pagar com'}</PayText>
                {!invoice_id ? (
                  <>
                    <Margin height={50} />
                    <ReloadSVG width="110" height="110" />
                  </>
                ) : (
                  <>
                    <CardPay
                      className="first"
                      onClick={() => {
                        detailInvoice('pix');
                        ReactGA.event({
                          category: 'Pagamento',
                          action: 'Pagar - PIX',
                        });
                      }}
                      bg="secondary"
                      disabled={!existPix}
                    >
                      <PixSVG fill="#ffffff" />
                      <span>PIX</span>
                    </CardPay>
                    <CardPay onClick={() => detailInvoice('')}>
                      <CreditCardSVG />
                      <span>Outras formas de pagamento</span>
                    </CardPay>
                  </>
                )}
              </CardIColumn>
            ) : (
              <CardIColumn style={{ justifyContent: 'flex-start' }}>
                <PayText>Obrigado!</PayText>
                <CardPay style={{ flex: 1, boxShadow: 'none' }}>
                  <button
                    type="button"
                    onClick={() => {
                      Navigation.navigate(RouteName.INVOICES);
                    }}
                    style={{
                      cursor: 'pointer',
                      padding: '21px 20px',
                      borderRadius: '8px',
                      background: '#37B482',
                      textAlign: 'center',
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      fontWeight: 500,
                      fontSize: 15,
                      border: 'none',
                    }}
                  >
                    Ver faturas
                  </button>
                </CardPay>
              </CardIColumn>
            )}
          </CardInvoiceBody>
          {invoice_id > 0 && (
            <ContainerInvoiceDetails>
              <InvoiceBtnDetails onClick={() => detailInvoice('')}>
                Fatura completa
              </InvoiceBtnDetails>
              {existsAccountDebt(data, maturity_date) && (
                <>
                  <Separator />
                  <ContainerAutomaticDebit>
                    <AutomaticDebitSVG color={Color.green} width={29} height={25} />
                    <Margin width={10} />
                    <p>
                      Débito em conta <br />
                      cadastrado
                    </p>
                  </ContainerAutomaticDebit>
                </>
              )}
            </ContainerInvoiceDetails>
          )}
        </Container>
      )}
    </div>
  );
}
