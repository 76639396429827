import { format, subYears } from 'date-fns';
import { IDateQuery } from '~/store/modules/enrollmentsConsumptionHistory/types';
import { api } from './api';

function getEnrollmentsConsumptionHistoryService(
  enrollmentId: string,
  query?: IDateQuery,
) {
  return new Promise((resolve, reject) => {
    try {
      const dateDefault = {
        start: format(subYears(new Date(), 1), 'dd/MM/yyyy'),
        end: format(new Date(), 'dd/MM/yyyy'),
      };

      const urlQuery = `?start_date=${
        query ? query.start : dateDefault.start
      }&end_date=${query ? query.end : dateDefault.end}`;

      const getEnrollments: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/consumption-history${urlQuery}`,
        );

        return response.data;
      };

      resolve(getEnrollments());
    } catch (error) {
      reject(error);
    }
  });
}

export { getEnrollmentsConsumptionHistoryService };
