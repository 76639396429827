import styled from 'styled-components';
import { Color } from '~/styles';

interface IBtnStyledProps {
  typeBtn?: 'content' | 'outlined' | 'canceled' | undefined;
  color?: string;
  backgroundColor?: string;
}

export const ButtonStyled = styled.button<IBtnStyledProps>`
  width: 100%;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ color, typeBtn }) =>
    // eslint-disable-next-line no-nested-ternary
    color || (typeBtn === 'content' ? Color.white : Color.black)};

  background: ${({ backgroundColor, typeBtn }) =>
    // eslint-disable-next-line no-nested-ternary
    backgroundColor ||
    (typeBtn === 'content' ? Color.grayLightSeconde : Color.white)};
  text-decoration: ${({ typeBtn }) =>
    typeBtn === 'outlined' ? 'underline' : 'none'};
  border: none;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 49px;
  }
  :disabled {
    cursor: auto;
    opacity: 0.3;
  }

  div {
    svg {
      left: 49px;
      transform: rotate(180deg);
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
    height: 30px;
    filter: ${({ typeBtn }) =>
      typeBtn !== 'outlined'
        ? 'drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25))'
        : 'none'};
  }
`;
