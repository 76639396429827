import { Color } from '~/styles/colors';

export const stepCustomerObligation = (statusLabel: string) => {
  switch (statusLabel?.toUpperCase()) {
    case 'IDENTIFICAÇÃO DO PROCESSO':
      return { step: 1 };
    case 'DOCUMENTAÇÃO PARA APROVACÃO DE OC':
      return { step: 2 };
    case 'DOCUMENTAÇÃO PARA LICENCIAMENTO DA OBRA NA PMRJ':
      return { step: 3 };
    case 'SOLICITAÇÃO DE DARM':
      return { step: 4 };
    case 'SOLICITAÇÃO DE INÍCIO DE OBRA':
      return { step: 5 };
    case 'SOLICITAÇÃO DE INTERLIGAÇÃO DE REDE DE ABASTECIMENTO/ESGOTAMENTO':
      return { step: 6 };
    case 'SOLICITAÇÃO DE ENCERRAMENTO DA LICENÇA DE OBRAS JUNTO A PMRJ':
      return { step: 7 };
    case 'SOLICITAÇÃO DE ACEITE DE REDE':
      return { step: 8 };
    default:
      return { step: 1 };
  }
};

export const statusCustomerObligation = (statusLabel: string) => {
  switch (statusLabel?.toUpperCase()) {
    case 'EM ANÁLISE':
      return { color: Color.yellow, label: 'Em Análise' };
    case 'DARM DISPONÍVEL':
      return { color: Color.blueSeconde, label: 'Darm Disponível' };
    case 'DOCUMENTAÇÃO ASSINADA':
      return { color: Color.blueSeconde, label: 'Documentação Assinada' };
    case 'INTERLIGAÇÃO PROGRAMADA':
      return { color: Color.blueSeconde, label: 'Interligação Programada' };
    case 'CANCELADO':
      return { color: Color.gray, label: 'Cancelado' };
    case 'AIO EMITIDA':
      return { color: Color.green, label: 'AIO Emitida' };
    case 'CARTA EMITIDA':
      return { color: Color.green, label: 'Carta Emitida' };
    case 'DECLARAÇÃO EMITIDA':
      return { color: Color.green, label: 'Declaração Emitida' };
    case 'DOCUMENTAÇÃO APROVADA':
      return { color: Color.green, label: 'Documentação Aprovada' };
    case 'INTERLIGAÇÃO EXECUTADA':
      return { color: Color.green, label: 'Interligação Executada' };
    case 'REPAVIMENTAÇÃO APROVADA':
      return { color: Color.green, label: 'Repavimentação Aprovada' };
    case 'REPAVIMENTAÇÃO REPROVADA':
      return { color: Color.red, label: 'Repavimentação Reprovada' };
    case 'REVISAR AS BUILT':
      return { color: Color.red, label: 'Revisar As Built' };
    case 'REVISAR AS BUILT E DOCUMENTOS':
      return { color: Color.red, label: 'Revisar As Built e Documentos' };
    case 'REVISAR DADOS':
      return { color: Color.red, label: 'Revisar Dados' };
    case 'REVISAR DOCUMENTOS E PROJETO':
      return { color: Color.red, label: 'Revisar Documento e Projeto' };
    case 'REVISAR DOCUMENTOS':
      return { color: Color.red, label: 'Revisar Documentos' };
    case 'REVISAR PROJETO':
      return { color: Color.red, label: 'Revisar Projeto' };
    case 'REVISAR RELATÓRIO FOTOGRÁFICO':
      return { color: Color.red, label: 'Revisar Relatório Fotográfico' };
    default:
      return { color: Color.yellow, label: 'Em Análise' };
  }
};
