import styled from 'styled-components';
import { Color } from '~/styles';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Color.blueSeconde};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface IPropsStyledCheckbox {
  checked: boolean;
}

export const StyledCheckbox = styled.div<IPropsStyledCheckbox>`
  min-width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: ${({ checked }) =>
    checked ? 'rgb(55, 180, 200)' : 'transparent'};
  border: 1px solid ${Color.blueSeconde};
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckmarkSVG = styled.svg<IPropsStyledCheckbox>`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: round;
  visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  display: ${({ checked }) => (checked ? 'inherit' : 'none')};
`;
