import React, { FC } from 'react';
import { Checkbox } from '~/components/CheckboxV3';
import { Margin } from '~/components/Margin';
import { Types } from '..';
import { ContainerAlert, ContainerCheckBox, DescriptionTypeText } from './styles';
import { TypeText } from '../styles';

import { Container } from './styles';
import WarningBox from '~/components/WarningBox';

interface IProps {
  type: Types | null;
  setType: (type: any) => void;
}

const SocialTariffStepType: FC<IProps> = ({ type, setType }) => {
  const types = [
    {
      id: 1,
      label: 'Renovação do benefício',
      description: 'Renovar benefício com os mesmos dados enviados anteriormente.',
      files: [
        {
          label: `Documento de identificação*\n(CPF, RG ou CNH)`,
          note: '',
          file: null,
          name: 'identification_document',
          base64: '',
        },
      ],
    },
    {
      id: 2,
      label: 'Habitação em favela',
      description:
        'Conjunto de habitações populares desprovidas de infraestrutura (rede de esgoto, de energia, de posto de saúde, etc.)',
      files: [
        {
          label: 'Documento de identidade e CPF',
          note: '',
          file: null,
          name: 'identification_document',
          base64: '',
        },
        {
          label: 'Declaração de moradia',
          note: '',
          file: null,
          name: 'housing_declaration',
          base64: '',
        },
        {
          label: 'Declaração da associação dos moradores ou documentação FAFERJ',
          note: '(local de favela)',
          file: null,
          name: 'residents_association_statement',
          base64: '',
        },
      ],
    },
    {
      id: 3,
      label: 'Área de interesse social',
      description:
        'Lotes irregulares, posse, periferias de favelas e áreas de risco.',
      files: [
        {
          label: 'Documento do órgão financiador',
          note: '(com nome do titular)',
          file: null,
          name: 'funding_body_document',
          base64: '',
        },
        {
          label: 'IPTU',
          note: '(casas isoladas)',
          file: null,
          name: 'iptu',
          base64: '',
        },
        {
          label: 'Documento de identidade e CPF',
          note: '',
          file: null,
          name: 'identification_document',
          base64: '',
        },
        {
          label: 'Declaração de moradia',
          note: '',
          file: null,
          name: 'housing_declaration',
          base64: '',
        },
        {
          label: 'Ata do condomínio onde conste a eleição do síndico ',
          note: '(ou documento que conste o responsável pelo conjunto)',
          file: null,
          name: 'condominium_act',
          base64: '',
        },
      ],
    },
    {
      id: 4,
      label: 'Conjunto habitacional',
      description:
        'Uso de sistema financeiro de habitação, renda familiar de até 5 salários mínimos.',
      files: [
        {
          label: 'Vistoria de localização',
          note: '(Na ausência de documentação)',
          file: null,
          name: 'localization_inspection',
          base64: '',
        },
        {
          label: 'IPTU',
          note: '(ou outro documento que comprove a posse)',
          file: null,
          name: 'iptu',
          base64: '',
        },
        {
          label: 'Documento de identidade e CPF',
          note: '',
          file: null,
          name: 'identification_document',
          base64: '',
        },
        {
          label: 'Declaração de moradia',
          note: '',
          file: null,
          name: 'housing_declaration',
          base64: '',
        },
      ],
    },
    {
      id: 5,
      label: 'Habitação popular',
      description: 'Terrenos cedidos por órgãos públicos.',
      files: [
        {
          label:
            'Documento comprobatório da cessão do terreno emitido pelo órgão público',
          note: '(incluindo nome do titular)',
          file: null,
          name: 'ceasing_land_document',
          base64: '',
        },
        {
          label: 'Declaração de moradia',
          note: '',
          file: null,
          name: 'housing_declaration',
          base64: '',
        },
        {
          label: 'Documento de identidade e CPF',
          note: '',
          file: null,
          name: 'identification_document',
          base64: '',
        },
      ],
    },
  ];

  return (
    <Container>
      <Margin height={32} />
      <TypeText>Selecione o tipo de tarifa social:</TypeText>
      <Margin height={16} />
      {types.map((item) => (
        <>
          <ContainerCheckBox key={item.id}>
            <Checkbox
              checked={type ? type.id === item.id : false}
              onClick={() => setType(item)}
            >
              {item.label}
            </Checkbox>
            <DescriptionTypeText>{item.description}</DescriptionTypeText>
            <Margin height={4} />
          </ContainerCheckBox>
          {item.label === 'Renovação do benefício' &&
            type &&
            type.id === item.id && (
              <ContainerAlert>
                <WarningBox label="Ao selecionar essa opção, estará declarando que não houve alteração dos dados de moradia informados na solicitação anterior. Em caso de alteração, refaça a solicitação selecionando as opções abaixo." />
              </ContainerAlert>
            )}
        </>
      ))}
    </Container>
  );
};

export default SocialTariffStepType;
