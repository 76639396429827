import { Reducer } from 'redux';

import {
  RepositoriesTypesLoginCredentials,
  IRepositoriesStateEnrollmentsLoginCredentials,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsLoginCredentials = {
  data: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsLoginCredentials, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesLoginCredentials.ENROLLMENTS_LOGIN_CREDENTIALS:
      return {
        ...state,
        data: { ...action.payload.data },
      };

    default:
      return state;
  }
};

export default reducer;
