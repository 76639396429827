import * as Yup from 'yup';
import {
  checkCnp,
  validateBase64FileData,
  validateBase64FileSize,
  validateEmail,
  validateFile,
  validatePhoneNumber,
} from '~/utils/validation';

export const SignupSchemaLabel = (schema?: any) => {
  let shapeYup = {
    owner: Yup.string().required('Campo obrigatório'),
    email: Yup.mixed()
      .test('email', 'Campo obrigatório', (value) => !!value)
      .test('email', 'Email inválido', (value) => validateEmail(value)),
    cpf_cnpj: Yup.mixed()
      .test('cpf_cnpj', 'Campo obrigatório', (value) => !!value)
      .test('cpf_cnpj', 'CPF/CNPJ inválido', (value) => checkCnp(value)),
    cep: Yup.string().required('Campo obrigatório'),
    street: Yup.string().required('Campo obrigatório'),
    neighborhood: Yup.string().required('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
    name: Yup.string().required('Campo obrigatório'),
    qualification: Yup.string().required('Campo obrigatório'),
    interestedAddress: Yup.string().required('Campo obrigatório'),
    interestedEmail: Yup.mixed()
      .test('interestedEmail', 'Campo obrigatório', (value) => !!value)
      .test('interestedEmail', 'Email inválido', (value) => {
        return validateEmail(value);
      }),
    phone: Yup.mixed()
      .test('phone', 'Campo obrigatório', (value) => !!value)
      .test('phone', 'Telefone inválido', (value) => validatePhoneNumber(value)),
    identificationDocument: Yup.mixed()
      .test('identificationDocument', 'Campo obrigatório', (value) =>
        validateFile(value),
      )
      .test(
        'identificationDocument',
        'Por favor, verifique o seu arquivo',
        (value) => validateBase64FileData(value),
      )
      .test('fileSize', 'Arquivo não pode ser maior que 10 Mb', (value) =>
        validateBase64FileSize(value),
      ),
    locationPlan: Yup.mixed()
      .test('locationPlan', 'Campo obrigatório', (value) => validateFile(value))
      .test(
        'identificationDocument',
        'Por favor, verifique o seu arquivo',
        (value) => validateBase64FileData(value),
      )
      .test('fileSize', 'Arquivo não pode ser maior que 10 Mb', (value) =>
        validateBase64FileSize(value),
      ),
    situationPlan: Yup.mixed()
      .test('situationPlan', 'Campo obrigatório', (value) => validateFile(value))
      .test(
        'identificationDocument',
        'Por favor, verifique o seu arquivo',
        (value) => validateBase64FileData(value),
      )
      .test('fileSize', 'Arquivo não pode ser maior que 10 Mb', (value) =>
        validateBase64FileSize(value),
      ),
  };

  const selectTypeValidation = (type: string) => {
    if (type === ('number' || 'float')) {
      return Yup.number().required('Campo obrigatório');
    }
    return Yup.string().required('Campo obrigatório');
  };

  if (schema)
    schema.forEach((e: any) => {
      shapeYup = {
        ...shapeYup,
        [e.key]: selectTypeValidation(e.type),
      };
    });
  return Yup.object().shape(shapeYup);
};
