import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 20px 0 24px;
  margin: 0;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 100vh;
    height: auto;
    padding-bottom: 28px;
  }
  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
  }
`;

export const CardEmpty = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 30%;
  height: 100%;
  min-width: 500px;
  min-height: 114vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const TitleTextStyled = styled.h1`
  font-weight: 600;
  font-size: 32px;
  color: ${Color.blueIntense};
  margin-bottom: 10px;
`;

export const DescriptionTextStyled = styled.p`
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: ${Color.blueIntense};
  margin-bottom: 10px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 18px;
  }
`;

export const ActionTextStyled = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #c7c9c7;

  a {
    color: #37b4c8;
    text-decoration: underline;
  }
`;

export const ContainerInputStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    width: 49% !important;
    div {
      width: initial !important;
    }
  }
`;

export const ContainerTermsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TextTerm = styled.p`
  font-size: 12px;
  line-height: 122.5%;
  letter-spacing: -0.035em;
  color: ${Color.blueIntense};

  a {
    text-decoration: none;
    font-weight: 700;
    color: ${Color.red};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 18px;
  }
`;
