import styled, { css, keyframes } from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export const Main = styled.div`
  padding: 20px 9px 63px 9px;
  width: 100%;
  background: ${COLORS.white};
  box-sizing: border-box;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    padding: 48px 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    padding: 22px 80px 22px 51px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 22px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  button {
    cursor: pointer;
    position: absolute;
    background: transparent;
    right: 0px;
  }
`;

export const Card = styled.section`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  margin: 0 auto;
  border-top: 25px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
    text-align: left;
    color: ${Color.blueIntense};
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1010px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 0 8px;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 73px;
  }
`;

export const Line = styled.hr`
  border-color: ${Color.grayLightSeconde};
  border-bottom-color: transparent;
  width: 100%;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  color: ${Color.blueIntense};

  b {
    font-weight: 600;
  }

  a {
    text-decoration-line: underline;
    color: ${Color.blueSeconde};
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0 auto;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TitleLine = styled.h2`
  position: absolute;
  text-align: center;
  background: white;
  left: 105px;
  padding: 0 30px 0 30px;

  color: ${Color.blueIntense};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ButtonInfo = styled.button<{ isActive: boolean }>`
  width: 70%;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? Color.blueSeconde : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
  border: 2px solid ${Color.blueSeconde};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  svg {
    position: absolute;
    right: 32px;
    path {
      fill: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  svg {
    position: static;
    margin-right: 4px;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

export const EmptyContainer = styled.td`
  width: 100%;
  padding: 70px;
`;

export const TitleFilter = styled.p`
  margin: 24px 0px;
  font-size: 16px;
  font-weight: 500;
  color: ${Color.blueIntense};
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface IPropsContainerCodeBarLabelInvoice {
  copied: boolean;
  clicked: boolean;
}

export const ButtonCodeInvoice = styled.button<IPropsContainerCodeBarLabelInvoice>`
  width: calc(50% - 24px);
  margin: 8px 16px 0 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ copied }) => (copied ? Color.green : '#e9ecef')};
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  & + & {
    margin: 8px 16px 0 0;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  ${({ clicked }) =>
    clicked &&
    css`
      width: 32px;
      height: 32px;
      margin: 8px auto 0 auto !important;
      padding: 0;
      border: 1px solid ${Color.grayDark};
      border-color: ${Color.grayDark};
      border-radius: 50%;
      border-width: 3px;
      font-size: 0;
      border-left-color: ${Color.green};
      animation: ${rotating} 2s 0.25s linear infinite;
    `}

  ${({ copied }) =>
    copied &&
    css`
      background: ${Color.green};
    `}
`;

export const ContainerCodeBarLabelInvoice = styled.div`
  width: calc(100% - 64px);
  margin: 36px auto 0 auto;
  height: 36px;
  display: flex;
  align-items: left;
  justify-content: center;
  position: relative;
  border: 1px solid ${Color.grayDark};
  border-radius: 8px;
  padding: 0 16px;
  text-align: center;

  p {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 14px;
    height: 16px;
    cursor: pointer;
  }

  button {
    border: none;
    background-color: ${Color.white};
  }

  span {
    position: absolute;
    top: -22px;
    left: 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 122.5%;
    color: #000;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    height: 36px;

    span {
      font-size: 14px;
    }

    span {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
`;

export const Divisor = styled.div`
  border: 1px solid #e9e9e9;
  width: calc(100% - 24px);
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
`;

export const ContainerBox = styled.div`
  border-radius: 16px;
  background: #f2f2f2;
`;

export const ContainerQRCode = styled.div`
  position: relative;
  height: min-content;

  span {
    display: flex;
    padding: 24px;

    svg {
      height: 200px;
      width: 200px;
    }
  }

  .topRight {
    top: 0;
    right: 0;
    position: absolute;
    transform: rotate(90deg);
  }
  .topLeft {
    top: 0;
    left: 0;
    position: absolute;
  }
  .bottomLeft {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: rotate(270deg);
  }
  .bottomRight {
    bottom: 0;
    right: 0;
    position: absolute;
    transform: rotate(180deg);
  }
`;

export const SubTitle = styled.h6`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${Color.gray};
`;

export const ContainerButtonPayment = styled.div`
  display: flex;
`;

export const ButtonPayment = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2 !important;

  border-radius: 4px;
  width: calc(50% - 20px);
  padding: 10px;
  margin: 24px 0px;
  cursor: pointer;

  color: ${Color.blueIntense};
  text-align: center;
  font-size: 10px;
  font-weight: 700;

  & + & {
    margin-left: 40px;
  }
  ${({ selected }) =>
    !selected &&
    ` 
    background: #fff !important;
    box-shadow: 0px 2.72542px 4.08813px 0px rgba(0, 0, 0, 0.19);
  `};
`;

interface IPropsRowInfo {
  backgroundWhite?: boolean;
}

export const RowInfo = styled.div<IPropsRowInfo>`
  width: 100%;
  padding: 0;
  min-height: 24px;
  background: ${({ backgroundWhite }) =>
    backgroundWhite ? Color.white : '#f2f2f2'};
  display: flex;
  p {
    width: 100%;
    margin-right: 50px;
    color: ${Color.blueIntense};
    font-weight: 500;
    font-size: 16px;
    margin: 4px 0;
    padding: 0 16px;
    line-height: 150%;
  }

  p + p {
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .text-end {
    text-align: right;
  }

  .capitalize {
    text-transform: capitalize;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    p {
      width: auto;
      padding-right: 20px;
    }
  }
`;

export const ContainerInfoPayment = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 16px;
  width: 100%;

  p {
    color: ${Color.blueIntense};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 4px 24px;
  }
`;

export const HeaderInfoPayment = styled.div`
  display: flex;
  background-color: #aad7e1;
  border-radius: 16px 16px 0px 0px;
  padding: 2px 24px;
  color: ${Color.blueIntense};

  width: calc(100% - 48px);

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
