import React from 'react';
import CloseModalSVG from '~/assets/Icons/CloseModal';
import { CloseIconButton, Wrapper } from './styles';
import bannerSocialTariffImg from '~/assets/Images/banner-social-tariff-side-bar.png';
import Navigation from '~/utils/Navigation';

import { RouteName } from '~/routes/Routes.name';

interface IProps {
  onClose: (status: boolean) => void;
}

const ModalBanner: React.FC<IProps> = ({ onClose }) => {
  return (
    <Wrapper>
      <CloseIconButton onClick={() => onClose(false)} type="button">
        <div>
          <CloseModalSVG width={14} height={14} isBold />
        </div>
      </CloseIconButton>
      <button
        onClick={() => {
          Navigation.navigate(RouteName.SOCIALTARIFF);
        }}
        type="button"
      >
        <img src={bannerSocialTariffImg} alt="recadastro tarifa social" />
      </button>
    </Wrapper>
  );
};

export default ModalBanner;
