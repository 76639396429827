import React, { FC, useEffect, useState } from 'react';
import {
  ContainerBody,
  ContainerTitle,
  ContainerViability,
  Header,
  Main,
  SeparatorTitle,
  TextTutorial,
  Title,
} from './styles';
import { useMediaQuery } from 'react-responsive';
import { Margin } from '~/components/Margin';
import { Breakpoints } from '~/styles';
import { IResponseAttachment } from '~/store/modules/customerObligation/types';
import { IState } from '~/store/modules/types';
import { useDispatch, useSelector } from 'react-redux';
import steps from './steps.json';
import {
  clearCustomerObligationRequest,
  clearDataAttachFilesCustomerObligationRequest,
  clearDataCreateCustomerObligation,
  clearDataValidateCustomerObligation,
  getErrorCloseValidateCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import ProcessIdentification from './steps/ProcessIdentification';
import DarmRequest from './steps/DarmRequest';
import StatusLine from './components/StatusLine';
import DocumentationWorkLicensing from './steps/DocumentationWorkLicensing';
import DocumentationApproval from './steps/DocumentationApproval';
import LoadingComponent from '~/components/Loading';
import Modal from '~/components/Modal';
import ModalError from './components/ModalError';
import WorkStartRequest from './steps/WorkStartRequest';
import { NetworkAcceptance } from './steps/NetworkAcceptance';
import { LicenseTermination } from './steps/LicenseTermination';

const CustomerObligation: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const loadingItem = useSelector(
    (item: IState) => item.customerObligation.loadingItem,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment: IResponseAttachment) => attachment.review === true,
  );

  const [actualStep, setActualStep] = useState<1 | 2 | 3 | 4 | 5 | 7 | 8>(
    caseOC?.step?.number || 1,
  );
  const errorValidate = useSelector((item: IState) => item.customerObligation.error);
  const errorCreate = useSelector(
    (item: IState) => item.customerObligation.errorCreate,
  );

  const stepsContent = {
    1: <ProcessIdentification setActualStep={setActualStep} />,
    2: <DocumentationApproval setActualStep={setActualStep} />,
    3: <DocumentationWorkLicensing setActualStep={setActualStep} />,
    4: <DarmRequest setActualStep={setActualStep} />,
    5: <WorkStartRequest />,
    7: <LicenseTermination />,
    8: <NetworkAcceptance />,
  };

  useEffect(() => {
    if (caseOC?.step?.number) {
      setActualStep(caseOC?.step?.number);
    }
  }, [caseOC?.step?.number]);

  useEffect(() => {
    dispatch(getErrorCloseValidateCustomerObligationRequest());
    dispatch(clearDataValidateCustomerObligation());
    dispatch(clearDataCreateCustomerObligation());
    dispatch(clearDataAttachFilesCustomerObligationRequest());
  }, []);

  useEffect(
    () => () => {
      dispatch(clearCustomerObligationRequest());
    },
    [],
  );

  return (
    <>
      {loadingItem && <LoadingComponent labelWait="Carregando..." />}
      <Modal
        open={
          !!(
            (errorValidate &&
              (errorValidate.status === 500 || errorValidate?.status === 502)) ||
            (errorCreate &&
              (errorCreate.status === 500 || errorCreate?.status === 502))
          )
        }
        type="error"
        title="Ops!"
      >
        <ModalError />
      </Modal>
      <Main>
        <ContainerViability>
          <Header>Obrigação do cliente (OC)</Header>
          {!isMobile && (
            <>
              <Margin height={22} />
              <TextTutorial>
                A OBRIGAÇÃO DO CLIENTE é o processo gerado através da elaboração da
                CPAE. Caso a Declaração de Possibilidade de Abastecimento e/ou
                Esgotamento indique que o cliente deverá executar obra, se faz
                obrigatório a abertura de uma O.C. Esse processo é aberto para que os
                clientes possam legalizar e executar a obra em via pública com
                acompanhamento da Iguá, tendo em vista que a rede executada pelo
                cliente será doada a concessionária.
              </TextTutorial>
              <Margin height={40} />
              <ContainerTitle>
                <SeparatorTitle />
                <Title>{steps[actualStep - 1]?.title || steps[0]?.title}</Title>
              </ContainerTitle>
              <Margin height={50} />
            </>
          )}
          <ContainerBody isRevision={!!isRevision || false}>
            {isMobile && (
              <>
                <TextTutorial>
                  A OBRIGAÇÃO DO CLIENTE é o processo gerado através da elaboração da
                  CPAE. Caso a Declaração de Possibilidade de Abastecimento e/ou
                  Esgotamento indique que o cliente deverá executar obra, se faz
                  obrigatório a abertura de uma O.C. Esse processo é aberto para que
                  os clientes possam legalizar e executar a obra em via pública com
                  acompanhamento da Iguá, tendo em vista que a rede executada pelo
                  cliente será doada a concessionária.
                </TextTutorial>
                <Margin height={22} />
                <ContainerTitle>
                  <SeparatorTitle />
                  <Title>{steps[actualStep - 1]?.label || steps[0]?.label}</Title>
                </ContainerTitle>
                <Margin height={35} />
              </>
            )}
            <StatusLine actualStep={actualStep} />
            <Margin height={50} />
            {stepsContent[actualStep]}
          </ContainerBody>
        </ContainerViability>
      </Main>
    </>
  );
};

export default CustomerObligation;
