import styled from 'styled-components';

export const StyledCheckbox: any = styled.div<{ checked: boolean }>`
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  width: 16px;
  height: 16px;

  ${({ checked }) =>
    checked
      ? `background: #37B4C8;`
      : 'border: 1px solid #ccc;  width: 14px; height: 14px;'}

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      transition: all 0.2s ease-in-out;
    }
  }
`;
