import { Reducer } from 'redux';

import {
  RepositoriesTypesUsersNotClient,
  IRepositoriesStateEnrollmentsUsersNotClient,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsUsersNotClient = {
  loading: false,
  error: null,
  loadingRegister: false,
  errorRegister: null,
  success: false,
  protocol: '',
  modalNotExists: false,
  locations: [],
  operationId: '',
};

const reducer: Reducer<IRepositoriesStateEnrollmentsUsersNotClient, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_BAD_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS:
      return {
        ...state,
        operationId: action.payload.operationId,
        loading: false,
        error: null,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_REQUEST:
      return {
        ...state,
        loadingRegister: true,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS:
      return {
        ...state,
        loadingRegister: false,
        success: true,
        protocol: action.payload.data.protocol,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        success: false,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE:
      return {
        ...state,
        errorRegister: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingRegister: false,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorRegister: null,
        loadingRegister: false,
      };

    case RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL:
      return {
        ...state,
        modalNotExists: true,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_CLEAR_MODAL:
      return {
        ...state,
        modalNotExists: false,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        locations: action.payload.data.locations,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE:
      return {
        ...state,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorRegister: null,
        loadingRegister: false,
      };

    default:
      return state;
  }
};

export default reducer;
