import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container } from './styles';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { DigitalClientIcon } from './icons';
import { Breakpoints } from '~/styles';

export const DigitalClientInfo = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  return (
    <Container>
      {isMobile ? (
        <>
          <div>
            <DigitalClientIcon />
            <p className="title">
              Aproveite o seu <br /> período de teste!
            </p>
          </div>
          <div>
            <p className="subtitle">
              Conheça os benefícios da medição digital em{' '}
              <Link to={RouteName.SMARTMETER}>saiba mais.</Link>
            </p>
          </div>
        </>
      ) : (
        <>
          <div>
            <DigitalClientIcon />
          </div>
          <div>
            <p className="title">Aproveite o seu período de teste!</p>
            <p className="subtitle">
              Conheça os benefícios da medição digital em{' '}
              <Link to={RouteName.SMARTMETER}>saiba mais.</Link>
            </p>
          </div>
        </>
      )}
    </Container>
  );
};
