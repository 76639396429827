import React, {
  InputHTMLAttributes,
  HTMLInputTypeAttribute,
  CSSProperties,
} from 'react';

import {
  ContainerInputStyled,
  InputStyled,
  LabelStyled,
  ContainerIconStyled,
  LabelRow,
  LabelError,
} from './styles';

import InvisiblePassword from '~/assets/Icons/InvisiblePassword';
import VisiblePasswordSVG from '~/assets/Icons/VisiblePassword';
import EditSVG from '~/assets/Icons/editIcon';

interface Props extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  name?: string;
  value?: string | number;
  label?: string;
  type?: string;
  onChange?: (ev: any) => void;
  iconPassword?: boolean;
  error?: boolean;
  errorText?: string;
  passwordVisible?: boolean;
  setPasswordVisible?: (statusVisible: boolean) => void;
  editIcon?: boolean;
  maxLength?: number;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: any;
  onBlur?: (ev: any) => void;
  refEl?: React.MutableRefObject<any>;
  disabled?: boolean;
  as?: any;
  borderColor?: string;
}

export const Input: React.FC<Props> = ({
  value,
  label,
  type,
  onChange,
  iconPassword,
  error,
  errorText,
  passwordVisible,
  setPasswordVisible,
  editIcon,
  maxLength,
  placeholder,
  style,
  styleInput,
  name,
  onBlur,
  refEl,
  disabled,
  as,
  borderColor,
  ...props
}) => {
  return (
    <ContainerInputStyled style={styleInput}>
      <LabelRow>{label && <LabelStyled>{label}</LabelStyled>}</LabelRow>
      <InputStyled
        name={name}
        value={value}
        type={type}
        error={error}
        borderColor={borderColor}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        autoComplete="off"
        ref={refEl}
        disabled={disabled}
        style={style}
        inputMode={props.inputMode}
        as={as}
        {...props}
      />
      {error && errorText && <LabelError>{errorText}</LabelError>}
      {iconPassword && (
        <ContainerIconStyled
          error={!!(error && errorText)}
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          {passwordVisible ? <VisiblePasswordSVG /> : <InvisiblePassword />}
        </ContainerIconStyled>
      )}
      {editIcon && (
        <ContainerIconStyled
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          <EditSVG />
        </ContainerIconStyled>
      )}
    </ContainerInputStyled>
  );
};
