import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Dispatch } from 'redux';
import { getEnrollmentsConsumptionHistoryService } from '~/services/enrollmentsConsumptionHistory';
import { IState } from '../types';
import {
  IDateQuery,
  IResponseEnrollmentsConsumptionHistory,
  RepositoriesTypesEnrollmentsConsumptionHistory,
} from './types';

const getEnrollmentsConsumptionHistoryRequest =
  (base?: IDateQuery) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsConsumptionHistoryService(
        registration,
        base,
      )) as IResponseEnrollmentsConsumptionHistory;

      const average = response.historyDataAverage;
      const averageIot = response.iotDataAverage;

      const totalHistoryData = response?.historyData?.reduce(
        (total: any, number: any) => total + number.real_consumption,
        0,
      );

      const historyData = await response?.historyData.map((item) => {
        return {
          ...item,
          formatedReference: `${format(
            new Date(item.read_date_reference),
            'dd',
          )} de ${format(new Date(item.read_date_reference), 'MMM', {
            locale: pt,
          })}.`,
          formatedConsumption: item.real_consumption,
          average_consumption: response.historyDataAverage,
          amt: response.alert?.is_active ? response.alert?.cubic_meter : null,
        };
      });

      const totalHistoryIotData = response?.iotData?.reduce(
        (total: any, number: any) => total + number.consumption_value,
        0,
      );

      const iotData = await response?.iotData.map((item) => {
        return {
          ...item,
          formatedReference: `${format(
            new Date(item.reading_date),
            'dd',
          )} de ${format(new Date(item.reading_date), 'MMM', {
            locale: pt,
          })}.`,
          formatedConsumption: item.consumption_value,
          average_consumption: response.iotDataAverage,
          read_date_reference: item.reading_date,
          amt: response.alert?.is_active ? response.alert?.cubic_meter : null,
        };
      });

      return dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
            historyData,
            iotData,
            average,
            averageIot,
            totalHistoryData,
            totalHistoryIotData: totalHistoryIotData.toFixed(2),
          },
          loading: false,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
          loading: false,
        },
      });
    }
  };

export { getEnrollmentsConsumptionHistoryRequest };
