import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.main`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 22px 239px 22px;
  margin: 0;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    position: relative;
    padding-right: 147px;
  }
`;

export const DescriptionTextStyled = styled.p`
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: ${Color.blueIntense};

  span {
    color: ${Color.blueSeconde};
  }
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 265px;
  }
`;
