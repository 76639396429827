import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

export const TitleHeader = styled.div`
  color: ${Color.blueIntense};
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 80px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 28px;
  }
`;
