import React from 'react';

const StatusSuccessIconSVG = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.90625" width="44" height="44" rx="8" fill="#37B482" />
      <path
        d="M17 22.9062L20.75 26.6562L27 20.4062M32 22.9062C32 28.4291 27.5228 32.9062 22 32.9062C16.4772 32.9062 12 28.4291 12 22.9062C12 17.3834 16.4772 12.9062 22 12.9062C25.158 12.9062 27.9741 14.3701 29.8067 16.6562C31.1791 18.3682 32 20.5414 32 22.9062Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StatusSuccessIconSVG;
