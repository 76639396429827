import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  width: calc(100% - 48px);
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 0 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0;
    flex-direction: row;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-top: 34px;
    flex-direction: row;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  h2 {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  button {
    height: 48px;
    max-width: 300px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }

  :last-of-type {
    justify-content: flex-end;
  }
`;

// export const ContainerDate = styled.div`
//   width: 100%;
//   height: 91px;
//   margin: 76px 0 24px 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-weight: 300;
//   font-size: 14px;
//   color: '#000000';

//   p:last-of-type {
//     font-weight: 500;
//     font-size: 18px;
//     margin-top: 6px;
//   }

//   @media screen and (min-width: ${Breakpoints.laptop.min}px) {
//     margin: 0;
//   }
// `;

export const ContainerDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 76px 0 24px 0;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p:first-of-type {
      font-size: 14px;
    }
    p:last-of-type {
      font-weight: 500;
      font-size: 18px;
      margin-top: 6px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 128px;
  background: ${Color.grayLightSeconde};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: '#000000';
  margin-bottom: 80px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-bottom: 0;
  }
`;

export const Separator = styled.div`
  border: 1px solid ${Color.grayLight};
`;

export const ContainerProtocol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 300;
    font-size: 14px;
    color: '#000000';
    text-align: center;
  }
  b {
    font-weight: 500;
    font-size: 18px;
  }

  button {
    background: none !important;
    border: none;
    margin-left: 7px;
    box-shadow: none;
    height: min-content;
    cursor: pointer;
    svg {
      width: 13px;
      height: 15px;
    }
  }
`;

export const CopyText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${Color.blueSeconde};
`;

export const BoxProtocol = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentProtocol = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentProtocolButtons = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    button {
      max-width: 300px;
    }
  }
`;
