import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import MaterialInspectionDates from './MaterialInspectionDates';
import StartWorkDates from './StartWorkDates';

const WorkStartRequest: FC = () => {
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  return (
    <>
      {caseOC?.step.substep === 'A' && <StartWorkDates />}
      {caseOC?.step.substep === 'B' && <MaterialInspectionDates />}
    </>
  );
};

export default WorkStartRequest;
