import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsRowInfo {
  backgroundWhite?: boolean;
}

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 26px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 38px 35px 203px 35px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 80px);
    padding: 24px 40px 241px 40px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 80px);
    padding: 24px 40px 241px 40px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 0 24px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 32px);
  padding: 44px 16px 64px 16px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px;

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    height: 62px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 160px);
    padding: 25px 80px 64px 80px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(700px - 80px);
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(1010px - 80px);
    max-width: calc(1010px - 80px);
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 32px);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.blueIntense};
    text-align: left;
    background: ${Color.white};
    padding: 0 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 160px);

    h2 {
      position: absolute;
      left: 20px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    h2 {
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const ContainerShare = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  button {
    width: 92px;
    height: 32px;
    background: #e9ecef;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    cursor: pointer;
    padding: 7px 8px 7px 12px;
    display: flex;
    justify-content: center;

    svg {
      margin-left: 8px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-right: 40px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  margin-top: 24px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: ${Color.white};
    color: ${Color.blueSeconde};
    border: 2px solid ${Color.blueSeconde};
    max-width: 240px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 0;

    button {
      max-width: 252px;
    }
  }
`;

export const ContainerDetail = styled.div`
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;

  div {
    padding: 0;
    width: 100%;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 160px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    div {
      padding: 0 80px;
      width: calc(100% - 160px);
    }
  }
`;

export const RowInfo = styled.div<IPropsRowInfo>`
  width: calc(100% + 32px);
  min-height: 24px;
  background: ${({ backgroundWhite }) =>
    backgroundWhite ? Color.white : '#f2f2f2'};
  display: flex;
  justify-content: space-between;
  p {
    width: 100%;
    margin-right: 50px;
    color: ${Color.blueIntense};
    font-weight: 500;
    font-size: 16px;
    margin: 4px 0;
    padding: 0 16px;
  }

  .text-end {
    text-align: right;
  }

  .capitalize {
    text-transform: capitalize;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 160px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0 80px;

    p {
      width: auto;
      padding-right: 20px;
    }
  }
`;
