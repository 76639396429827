import styled from 'styled-components';

export const DeliveryFormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
`;

export const DeliveryFormGroup = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
