import React, { FC, ReactNode } from 'react';
import { Container, Header, Content } from './styles';

interface IProps {
  children: ReactNode;
  bgColor: string;
  status: string;
}

const PaymentContainer: FC<IProps> = ({ children, bgColor, status }) => {
  return (
    <Container>
      <Header bgColor={bgColor}>
        <p>{status}</p>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export { PaymentContainer };
