import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface Props {
  isActive?: boolean;
}

export const Wrapper = styled.div`
  padding: 64px 64px 104px 64px;
  margin: 0px auto;
  max-width: 640px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 48px;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    padding: 24px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StepNumber = styled.div<Props>`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 19px;
  background: ${(p) => (p.isActive ? Color.blueIntense : Color.gray)};
  border-radius: 36px;
`;

export const StepLabel = styled.div<Props>`
  width: 180px;
  /* height: 40px; */
  position: absolute;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(p) => (p.isActive ? Color.green : Color.gray)};
  font-weight: 500;
  line-height: 1.3;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    display: none;
  }
`;

export const StepDivider = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 3px;
  background: ${Color.gray};
`;
