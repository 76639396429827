import React, { FC } from 'react';
import bannerDownloadAppImg from '~/assets/Images/banner-download-app-v3.png';
import bannerSocialTariffImg from '~/assets/Images/banner-social-tariff-side-bar.png';
import appleStoreImg from '~/assets/Images/apple-store.png';
import googleStoreImg from '~/assets/Images/google-store.png';
import { Container } from './styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  app?: boolean;
  notClient?: boolean;
}

const BannerApp: FC<IProps> = ({ app, notClient }) => {
  const needRenovationSocialTariff = useSelector(
    (item: IState) => item.socialTariff.needRenovation,
  );
  const operation = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.operation.name,
  );

  return (
    <Container
      clickabled={needRenovationSocialTariff && !app}
      notClientLogged={notClient}
      onClick={() =>
        needRenovationSocialTariff &&
        !app &&
        operation?.toLocaleUpperCase() === 'RIO DE JANEIRO' &&
        Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
      }
    >
      {needRenovationSocialTariff &&
      !app &&
      operation?.toLocaleUpperCase() === 'RIO DE JANEIRO' ? (
        <img src={bannerSocialTariffImg} alt="recadastro tarifa social" />
      ) : (
        <>
          <img src={bannerDownloadAppImg} alt="medição inteligente" />
          <a
            href="https://play.google.com/store/apps/details?id=com.iguaapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleStoreImg} alt="ícone - loja google" />
          </a>
          <a
            href="https://apps.apple.com/br/app/digi-igu%C3%A1/id1588069396"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appleStoreImg} alt="ícone - loja apple" />
          </a>
        </>
      )}
    </Container>
  );
};

export { BannerApp };
