import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsContainerNotification {
  is_read?: boolean;
  read_view?: boolean;
}

export const Container = styled.div`
  min-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 31px 17px 0 17px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 31px 29px;
    min-width: calc(488px - 58px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    color: ${Color.blueIntense};
    font-size: 24px;
    font-weight: 600;
  }

  span {
    color: ${Color.blueSeconde};
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid ${Color.grayLightSeconde};
    position: relative;
    height: 24px;
    display: flex;
    align-items: center;

    #border {
      width: 110px;
      height: 2px;
      background: ${Color.blueSeconde};
      position: absolute;
      bottom: -1px;
      left: 0;
      margin: 0;
      z-index: 9999;
    }
  }
`;

export const ContainerNotification = styled.div`
  width: 100%;
  height: auto;
  max-height: 260px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    max-height: 390px;
  }
`;

export const CardNotification = styled.button<IPropsContainerNotification>`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #02505b;
  border: none;
  background: none;

  border-bottom: ${({ read_view }) =>
    read_view ? 'none' : `1px solid ${Color.grayLightSeconde}`};

  cursor: pointer;
  background: ${({ is_read }) => (is_read ? Color.white : '#F8F9FA')};
  padding: 0 13px;
  padding: ${({ read_view }) => (read_view ? '13px' : `0 13px`)};

  div {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 18px;

    p {
      text-wrap: wrap;
      color: ${({ is_read }) =>
        is_read ? Color.grayLightSeconde : Color.blueSeconde};
      font-size: 12px;
      font-weight: 600;

      :last-of-type {
        color: ${Color.blueIntense};
        font-size: 12px;
        font-weight: 500;
        text-align: left;

        ${({ read_view }) =>
          !read_view &&
          `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        `};
      }
    }
  }

  p {
    color: ${Color.grayLightSeconde};
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    margin-left: 6px;
    text-wrap: nowrap;
  }
`;

export const ContainerEmptyMessage = styled.div<IPropsContainerNotification>`
  margin: ${({ read_view }) => (read_view ? '14px 0px' : '28px auto 14px auto')};
  margin-inline-start: ${({ read_view }) =>
    read_view ? 'initial !important' : 'auto'};

  svg {
    width: 41px;
    height: 51px;
  }

  button {
    background: transparent;
    cursor: pointer;
  }

  p {
    color: ${Color.grayLightSeconde};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
`;
