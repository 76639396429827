import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Input } from '~/common/InputV3';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';

import { Main, DescriptionTextStyled, NavWrapper } from './styles';
import { Breakpoints } from '~/styles/breakpoints';

import { Button } from '~/common/Button';
import { Card } from '~/common/Card';
import { ButtonLink } from '~/common';
import Navigation from '~/utils/Navigation';
import PasswordRequirements from '~/components/PasswordRequirements';
import ModalSuccess from './ModalSuccess';
import { Dialog } from '~/components/Dialog';
import ModalInfoPassword from './ModalInfoPassword';
import {
  putCloseErrorUsersUpdatePassword,
  putCloseSuccessUsersUpdatePassword,
  putEnrollmentsUsersUpdatePasswordRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import ModalError from '~/components/ModalError';
import Modal from '~/components/Modal';
import { history } from '~/store';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';

const PasswordExpirationNewPassword: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataHistory = history.location.state as any;

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingPutPasswordRecovery,
  );
  const success = useSelector(
    (item: IState) => item.enrollmentsUsers.successUpdatePasswordRecovery,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorPutPasswordRecovery,
  );
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  useEffect(() => {
    dispatch(putCloseSuccessUsersUpdatePassword());
    dispatch(putCloseErrorUsersUpdatePassword());
  }, []);

  useEffect(() => {
    if (error && error.state) {
      if (error.type === EnumResponseCode.RECENTLY_USED_PASSWORD) {
        setOpenInfo(true);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [errorPassword, error]);

  useEffect(() => {
    if (password && confirmPassword && !errorPassword && isValidPassword) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword, errorPassword, isValidPassword]);

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setErrorPassword('Senhas não coincidem.');
    } else {
      setErrorPassword('');
    }
  }, [password, confirmPassword]);

  const handleSubmit = () => {
    if (disabled || loading) return;
    dispatch(
      putEnrollmentsUsersUpdatePasswordRequest({
        token: dataHistory?.token,
        newPassword: password,
      }),
    );
  };

  return (
    <Main>
      {open && (
        <Modal open={open} type="error" title="Ops!" v3>
          <ModalError onClose={() => dispatch(putCloseErrorUsersUpdatePassword())} />
        </Modal>
      )}
      {openInfo && (
        <Dialog
          open={openInfo}
          onClose={() => {
            dispatch(putCloseErrorUsersUpdatePassword());
            setOpenInfo(false);
          }}
          fullScreen
        >
          <ModalInfoPassword setOpen={setOpenInfo} />
        </Dialog>
      )}
      {success && (
        <Dialog
          open={success}
          onClose={() => dispatch(putCloseSuccessUsersUpdatePassword())}
          fullScreen
        >
          <ModalSuccess />
        </Dialog>
      )}
      <Card title="Alterar senha">
        <Margin height={16} />
        <DescriptionTextStyled>
          Agora só falta criar sua senha. Para uma senha forte, siga os requisitos
          apresentados:
        </DescriptionTextStyled>
        <Margin height={isMobile ? 20 : 24} />
        <Input
          value={password}
          onChange={(ev) => {
            setPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Senha"
          type={!passwordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          maxLength={16}
        />
        <Margin height={isMobile ? 8 : 13} />
        <Input
          value={confirmPassword}
          onChange={(ev) => {
            setConfirmPassword(ev.target.value);
            setErrorPassword('');
          }}
          label="Confirmação de senha"
          type={!confirmPasswordVisible ? 'password' : 'text'}
          iconPassword
          passwordVisible={confirmPasswordVisible}
          setPasswordVisible={setConfirmPasswordVisible}
          maxLength={16}
          error={!!errorPassword}
          errorText={errorPassword}
        />
        <Margin height={28} />
        <PasswordRequirements password={password} isValid={setIsValidPassword} />
        <Margin height={isMobile ? 20 : 31} />
        <NavWrapper>
          <Button onClick={handleSubmit} loading={loading} disabled={disabled}>
            Continuar
          </Button>
          <ButtonLink type="button" color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </NavWrapper>
        <Margin height={isMobile ? 20 : 31} />
      </Card>
    </Main>
  );
};

export default PasswordExpirationNewPassword;
