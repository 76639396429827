import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { ContainerButtons, Wrapper } from './styles';

import { Button } from '~/components/Button';
import { InfoIcon, WarningIcon } from './Icons';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

type TypeInfo =
  | 'registration_supply_rotation'
  | 'water_supply_interrupted'
  | 'reported_water_supply'
  | 'water_connection_inactive'
  | 'empty_registration';
interface IModalReportLackWaterInfoProps {
  onClose: () => void;
  typeInfo: TypeInfo;
}

const ModalReportLackWaterInfo: FC<IModalReportLackWaterInfoProps> = ({
  onClose,
  typeInfo,
}) => {
  const message = {
    registration_supply_rotation: (
      <p>
        O abastecimento na sua região é intermitente e está fora do horário de
        abastecimento. Por favor, aguarde o retorno do abastecimento.
      </p>
    ),
    water_supply_interrupted: (
      <p>
        Abastecimento de água interrompido na sua região. Pedimos desculpas pelo
        transtorno, para maiores informações, fale conosco.
      </p>
    ),
    reported_water_supply: (
      <p>
        Você já reportou falta d’água na sua região. Pedimos desculpas pelo
        transtorno, acompanhe o andamento da solicitação em{' '}
        <button
          type="button"
          onClick={() => {
            onClose();
            Navigation.navigate(RouteName.SUPPORTCENTER);
          }}
        >
          Minhas solicitações
        </button>
        .
      </p>
    ),
    water_connection_inactive: (
      <p>
        Sua ligação de água está inativa. Para maiores informações, fale conosco.
      </p>
    ),
    empty_registration: (
      <p>
        Nenhuma matrícula vinculada ao seu CPF/CNPJ.
        <br />
        <br />
        Para reportar falta d água, entre em contato via
        <br />
        fale conosco.
      </p>
    ),
  };

  const hasModalInfo =
    typeInfo === 'registration_supply_rotation' ||
    typeInfo === 'water_supply_interrupted' ||
    typeInfo === 'reported_water_supply';

  const actionLabel = {
    registration_supply_rotation: {
      category: 'Falta d’água',
      action:
        '[WEB][Falta D’água][AL][Home] – Apresentação do modal de rotatividade de abastecimento',
      actionOk:
        '[WEB][Falta D’água][AL][Home][rotatividade de abastecimento] - Entendi',
    },
    water_supply_interrupted: {
      category: 'Falta d’água',
      action: '[WEB][AL][Falta D’água][Modal de ocorrência de abastecimento]',
      actionOk:
        '[WEB][AL][Falta D’água][Modal de ocorrência de abastecimento][Entendi]',
    },
    reported_water_supply: {
      category: 'Falta d’água',
      action: '[WEB][AL][Falta D’água][Home][OS já aberta]',
      actionOk: '[WEB][AL][Falta D’água][Home][OS já aberta][Entendi]',
    },
    water_connection_inactive: {
      category: 'Falta d’água',
      action: '[WEB][Falta D’água][AL][Home[Ligação inativa]',
      actionOk: '[WEB][Falta D’água][AL][Home][ligação inativa][Entendi]',
    },
    empty_registration: {
      category: 'Falta d’água',
      action: '[WEB][Falta D’água][AL][Home[Nenhuma matrícula vinculada]',
      actionOk:
        '[WEB][Falta D’água][AL][Home][Nenhuma matrícula vinculada][Entendi]',
    },
  };

  useEffect(() => {
    ReactGA.event({
      category: actionLabel[typeInfo]?.category,
      action: actionLabel[typeInfo]?.action,
    });
  }, []);

  return (
    <Wrapper hasModalInfo={hasModalInfo}>
      <div>{hasModalInfo ? <InfoIcon /> : <WarningIcon />}</div>
      <h1>Atenção</h1>
      {message[typeInfo]}
      <ContainerButtons hasModalInfo={hasModalInfo}>
        <Button
          text="Entendi"
          type="button"
          onClick={() => {
            ReactGA.event({
              category: actionLabel[typeInfo]?.category,
              action: actionLabel[typeInfo]?.actionOk,
            });
            onClose();
          }}
        />
      </ContainerButtons>
    </Wrapper>
  );
};

export default ModalReportLackWaterInfo;
