import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Margin } from '~/components/Margin';

import {
  Main,
  ButtonService,
  TextService,
  Card,
  RowServices,
  RowInfoService,
  TextStyled,
} from './styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import DocumentSVG from '~/assets/Icons/DocumentIcon';
import NegativeSVG from '~/assets/Icons/CertNegative';
import ChartSVG from '~/assets/Icons/ChartIcon';
import {
  ChangeOwnerShipIcon,
  RepairCenterIcon,
  DpaDpeIcon,
  DpaDpeIconServices,
  SocialTariffIcon,
  RepairCenterServicesIcon,
} from './icons';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { BannerApp } from '~/components/BannerApp';
import { Dialog } from '~/components/Dialog';
import ModalReportLackWaterInfo from '../../../components/ModalReportLackWaterInfo';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { clearRouteReportLackWater } from '~/store/modules/outsides/actions';

const HomeNotClientNew: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const reportLackWaterRoute = useSelector(
    (item: IState) => item.outsides.reportLackWaterRoute,
  );

  const [modalReportLackWater, setModalReportLackWater] = useState<boolean>(false);

  const handleOnCloseReportLackWaterInfo = () => {
    dispatch(clearRouteReportLackWater());
    setModalReportLackWater(false);
  };

  useEffect(() => {
    setModalReportLackWater(reportLackWaterRoute);
  }, [reportLackWaterRoute]);

  return (
    <Main>
      <Dialog open={modalReportLackWater} onClose={handleOnCloseReportLackWaterInfo}>
        <ModalReportLackWaterInfo
          onClose={handleOnCloseReportLackWaterInfo}
          typeInfo="empty_registration"
        />
      </Dialog>
      <BannerApp notClient />
      <Card isBorder>
        <h1>Bem vindo!</h1>
        <Margin height={24} />
        <TextStyled>
          Você ainda não possui imóveis (matrículas) cadastrados.
        </TextStyled>
        <Margin height={24} />
        <RowInfoService>
          <ChangeOwnerShipIcon />
          <Margin width={25} />
          <TextStyled>
            Se você acabou de comprar ou alugar um imóvel que já possui hidrômetro
            instalado e quer pedir para trocar a titularidade da fatura para o seu
            nome:
            <br />
            <button
              type="button"
              onClick={() => {
                Navigation.navigate(RouteName.LINKENROLLMENT);
                ReactGA.event({
                  category: 'Recadastramento',
                  action: `[Cliente Não Saneamento] – Vincular Matricula`,
                });
              }}
            >
              Vincular Matrícula
            </button>
          </TextStyled>
        </RowInfoService>
        <Margin height={24} />
        <RowInfoService>
          <RepairCenterIcon />
          <Margin width={28} />
          <TextStyled>
            Caso queira solicitar uma nova ligação ou reparos referentes à vazamento
            e desobstrução de água ou esgoto:
            <br />
            Fale conosco
          </TextStyled>
        </RowInfoService>
        <Margin height={24} />
        <RowInfoService>
          <DpaDpeIcon />
          <Margin width={49} />
          <TextStyled>
            Caso seja um cliente Iguá + e queira verificar se no local do
            empreendimento existe possibilidade de abastecimento e esgotamento:
            <br />
            <button
              type="button"
              onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
            >
              DPA/DPE
            </button>
          </TextStyled>
        </RowInfoService>
        <Margin height={24} />
        <TextStyled style={{ padding: isMobile ? '0 10px' : '0' }}>
          Caso já tenha solicitações em aberto, acompanhe o andamento em Fale conosco
        </TextStyled>
      </Card>
      <Margin height={33} />
      <Card>
        <h2>Nossos serviços</h2>
        <Margin height={isMobile ? 38 : 32} />
        <RowServices>
          <ButtonService
            bgColor="green"
            withMargin
            onClick={() => Navigation.navigate(RouteName.INVOICES)}
          >
            <DocumentSVG />
            <Margin height={8} />
            <TextService>Faturas</TextService>
          </ButtonService>
          {isMobile ? (
            <>
              <ButtonService
                bgColor="blue"
                withMargin
                onClick={() => Navigation.navigate(RouteName.DETAILEDHISTORY)}
              >
                <ChartSVG />
                <Margin height={8} />
                <TextService>
                  Histórico
                  <br />
                  detalhado
                </TextService>
              </ButtonService>
              <ButtonService
                bgColor="red"
                withMargin
                onClick={() => Navigation.navigate(RouteName.NEGATIVECERTIFICATE)}
              >
                <NegativeSVG />
                <Margin height={8} />
                <TextService>
                  Certidão
                  <br />
                  Negativa
                </TextService>
              </ButtonService>
            </>
          ) : (
            <>
              <ButtonService
                bgColor="yellow"
                withMargin
                onClick={() => Navigation.navigate(RouteName.NEGATIVECERTIFICATE)}
              >
                <NegativeSVG />
                <Margin height={8} />
                <TextService>
                  Certidão
                  <br />
                  Negativa
                </TextService>
              </ButtonService>
              <ButtonService
                bgColor="blue"
                withMargin
                onClick={() => Navigation.navigate(RouteName.DETAILEDHISTORY)}
              >
                <ChartSVG />
                <Margin height={8} />
                <TextService>
                  Histórico
                  <br />
                  detalhado
                </TextService>
              </ButtonService>
            </>
          )}
          <ButtonService
            bgColor="red"
            withMargin
            onClick={() => Navigation.navigate(RouteName.SUPPORTCENTER)}
          >
            <RepairCenterServicesIcon />
            <Margin height={8} />
            <TextService>
              Minhas
              <br />
              solicitações
            </TextService>
          </ButtonService>
          <ButtonService
            bgColor="blueIntense"
            withMargin
            onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          >
            <DpaDpeIconServices />
            <Margin height={8} />
            <TextService>DPA/DPE</TextService>
          </ButtonService>
          <ButtonService
            bgColor="green"
            withMargin
            onClick={() => Navigation.navigate(RouteName.SOCIALTARIFF)}
          >
            <SocialTariffIcon />
            <Margin height={8} />
            <TextService>Tarifa social</TextService>
          </ButtonService>
        </RowServices>
      </Card>
    </Main>
  );
};

export default HomeNotClientNew;
