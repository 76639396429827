export enum RepositoriesTypesOutsidesCredentials {
  OUTSIDES_EASY_INVOICES_CREDENTIALS = '@repositories/OUTSIDES_EASY_INVOICES_CREDENTIALS',
}

export interface IDataEasyInvoicesCredentials {
  remember: boolean;
  cnp: string;
  enrollment: string;
}

export interface IRepositoriesStateOutsidesCredentials {
  readonly data: IDataEasyInvoicesCredentials | null;
}
