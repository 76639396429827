import React from 'react';

import { Header, CardLinks, Items } from './styles';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';

const Side: React.FC = () => {
  return (
    <CardLinks>
      <Header>Mais facilidades para você</Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 30px',
          marginTop: 30,
        }}
      >
        <div style={{ display: 'flex' }}>
          {`\u2022`}
          <Items onClick={() => Navigation.navigate(RouteName.AUTOMATICDEBIT)}>
            Débito automático
          </Items>
        </div>
        <div style={{ display: 'flex' }}>
          {`\u2022`}
          <Items onClick={() => Navigation.navigate(RouteName.DETAILEDHISTORY)}>
            Histórico de consumo
          </Items>
        </div>
        <div style={{ display: 'flex' }}>
          {`\u2022`}
          <Items
            onClick={() => window.open('https://igua.com.br/informacoes-para-voce')}
          >
            Entenda sua conta
          </Items>
        </div>
        <div style={{ display: 'flex' }}>
          {`\u2022`}
          <Items
            onClick={() => window.open('https://igua.com.br/informacoes-para-voce')}
          >
            Como identificar vazamento
          </Items>
        </div>
      </div>
    </CardLinks>
  );
};

export default Side;
