import {
  IRequestIPaymentVoucher,
  IRequestPayment,
  IRequestSituationPix,
} from '~/store/modules/enrollmentsPayment/types';
import { api } from './api';

function getEnrollmentsSituationPixService({
  enrollmentId,
  barCodeNumber,
}: IRequestSituationPix) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSituationPix: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/invoices/pix/situation/${barCodeNumber}`,
        );
        return response.data;
      };
      resolve(getEnrollmentsSituationPix());
    } catch (error) {
      reject(error);
    }
  });
}

function postEnrollmentsPaymentService(
  {
    enrollmentId,
    invoiceId,
    paymentCardNumber,
    paymentCardHolder,
    paymentExpirationDate,
    paymentSecurityCode,
    paymentBrand,
    installments,
  }: IRequestPayment,
  isBraspag: boolean,
) {
  return new Promise((resolve, reject) => {
    try {
      console.log({ isBraspag });
      const postEnrollmentsPayment: any = async () => {
        const response = await api.post(
          `v3/enrollments/${enrollmentId}/invoices/${invoiceId}/payment/braspag`,
          {
            paymentCardNumber,
            paymentCardHolder,
            paymentExpirationDate,
            paymentSecurityCode,
            paymentBrand,
            installments,
          },
        );
        return response.data;
      };
      resolve(postEnrollmentsPayment());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsPaymentReceiptService({
  enrollmentId,
  invoiceId,
  paymentBrand,
}: IRequestPayment) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsPaymentReceipt: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/invoices/${invoiceId}/payment/voucher`,
        );

        return {
          ...response.data,
          brand: paymentBrand,
        };
      };
      resolve(postEnrollmentsPaymentReceipt());
    } catch (error) {
      reject(error);
    }
  });
}

function getEnrollmentsPaymentReceiptNewService({
  enrollmentId,
  invoiceId,
}: IRequestIPaymentVoucher) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsPaymentReceipt: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/invoices/${invoiceId}/payment/voucher`,
        );

        return response.data;
      };
      resolve(postEnrollmentsPaymentReceipt());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEnrollmentsSituationPixService,
  postEnrollmentsPaymentService,
  getEnrollmentsPaymentReceiptService,
  getEnrollmentsPaymentReceiptNewService,
};
