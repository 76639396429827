import styled, { css } from 'styled-components';
import { Color } from '~/styles';

import { Breakpoints } from '../../styles/breakpoints';

interface IPropsCardStyled {
  isLarge?: boolean;
}

export const CardStyled = styled.div<IPropsCardStyled>`
  border-radius: 16px;
  min-height: 440px;
  padding: 16px;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.blueIntense};
    text-transform: uppercase;
    margin-top: ${({ isLarge }) => (isLarge ? '0' : '26px')};

    svg {
      margin-right: 12px;
    }
  }

  a {
    color: ${Color.blueSeconde};
  }

  .description-card {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.gray};
    margin-top: ${({ isLarge }) => (isLarge ? '16px' : '32px')};
    text-align: center;

    ${({ isLarge }) =>
      isLarge &&
      css`
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      `}
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(570px - 169px);
    min-height: 536px;
    padding: 40px 80px;

    ${({ isLarge }) =>
      isLarge &&
      css`
        @media screen and (min-width: ${Breakpoints.laptop.min}px) {
          min-height: 536px;
          padding: 80px 51px;
          max-width: calc(570px - 102px);
        }
      `}
  }
`;
