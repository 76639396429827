import { Dispatch } from 'redux';
import {
  deleteAutomaticDebitsService,
  getAutomaticDebitsBanksService,
  getAutomaticDebitsService,
  postAutomaticDebitsService,
} from '~/services/automaticDebits';
import { IState } from '../types';
import {
  RepositoriesTypesAutomaticDebits,
  IResponseGetAutomaticDebits,
  IRequestCreateAutomaticDebits,
  ICheckModalAutomaticDebits,
  IResponseGetBanksAutomaticDebits,
} from './types';

const getAutomaticDebitsBanksRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getAutomaticDebitsBanksService(
        registration,
      )) as IResponseGetBanksAutomaticDebits[];

      const convertArray: any = [];

      await response.map((bank: IResponseGetBanksAutomaticDebits) => {
        return convertArray.push({
          value: bank.id,
          label: `${bank.cod_bank} - ${bank.name}`,
        });
      });

      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_SUCCESS,
        payload: {
          data: convertArray,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getAutomaticDebitsRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getAutomaticDebitsService(
        registration,
      )) as IResponseGetAutomaticDebits;

      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseAutomaticDebitsRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_FAILURE_CLOSE,
  });
};

const postAutomaticDebitsRequest =
  (data: IRequestCreateAutomaticDebits) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await postAutomaticDebitsService(
        registration,
        data,
      )) as IResponseGetAutomaticDebits;

      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postSuccessCloseAutomaticDebitsRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_SUCCESS_CLOSE,
  });
};

const postErrorCloseAutomaticDebitsRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_FAILURE_CLOSE,
  });
};

const deleteAutomaticDebitsRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      await deleteAutomaticDebitsService(registration);

      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const deleteSuccessCloseAutomaticDebitsRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS_CLOSE,
    });
  };

const deleteErrorCloseAutomaticDebitsRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE_CLOSE,
  });
};

const activateModalAutomaticDebits =
  (data: ICheckModalAutomaticDebits) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_ACTIVATE_MODAL,
      payload: {
        data,
      },
    });
  };

const clearModalAutomaticDebits = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_CLEAR_MODAL,
  });
};

export {
  getAutomaticDebitsBanksRequest,
  getAutomaticDebitsRequest,
  getErrorCloseAutomaticDebitsRequest,
  postAutomaticDebitsRequest,
  postSuccessCloseAutomaticDebitsRequest,
  postErrorCloseAutomaticDebitsRequest,
  deleteAutomaticDebitsRequest,
  deleteSuccessCloseAutomaticDebitsRequest,
  deleteErrorCloseAutomaticDebitsRequest,
  activateModalAutomaticDebits,
  clearModalAutomaticDebits,
};
