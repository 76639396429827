import styled from 'styled-components';
import { Color } from '~/styles';

interface IPropsIndicator {
  backgroundColor: string;
}

export const ContainerIndicators = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

export const Indicator = styled.div<IPropsIndicator>`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  stroke-width: 4px;
  background: ${({ backgroundColor }) => backgroundColor || Color.grayLightSeconde};
`;
