import styled from 'styled-components';

export const SubTitle = styled.div`
  padding: 0 80px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 122.5%;
  color: #999;
`;
