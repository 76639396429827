import React from 'react';
import ReactGA from 'react-ga4';
import { Color } from '~/styles';
import InvoiceSlick from './InvoiceSlick';
import {
  Container,
  PaymentGroupCard,
  PaymentGroupButton,
  ContainerInvoices,
  ContainerPaymentGroup,
} from './styles';
import {
  StatusGroupCongratulations,
  StatusGroupInstallment,
  StatusGroupPending,
  StatusGroupWait,
} from './icon';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const EasyInvoice = () => {
  const loading = useSelector((item: IState) => item?.enrollmentsInvoices?.loading);
  const invoices = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.invoices,
  );
  const invoicesGroup = invoices?.filter(
    (invoice) =>
      invoice.status.label === 'PENDENTE' || invoice.status.label === 'VENCIDA',
  );

  const getStatusInvoicesGroup = () => {
    const invoicesGroupLengths = invoicesGroup?.length || 0;
    const invoicesGroupPending = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'PENDENTE',
    );
    const invoicesGroupDefeated = invoicesGroup?.filter(
      (invoice) => invoice.status.label === 'VENCIDA',
    );

    if (!invoices || invoices.length < 1 || loading) {
      return {
        color: Color.blueSeconde,
        icon: <StatusGroupWait />,
        title: 'Aguarde!',
        description:
          'Sua matrícula ainda não tem faturas. Em caso de dúvidas, favor entre em contato pelo Fale conosco.',
      };
    }

    if (invoicesGroup && invoicesGroup?.length > 1) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: `${invoicesGroupLengths} faturas em aberto`,
        description: 'Pague suas faturas em aberto em até 12X sem juros!',
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupPending &&
      invoicesGroupPending.length === 1
    ) {
      return {
        color: Color.blueIntense,
        icon: <StatusGroupPending />,
        title: 'Sua fatura vence em breve',
        description:
          'Com o Digi Iguá você pode pagar com PIX, boleto ou parcelar em até 12X no cartão de crédito.',
      };
    }

    if (
      invoicesGroupLengths === 1 &&
      invoicesGroupDefeated &&
      invoicesGroupDefeated.length === 1
    ) {
      return {
        color: Color.red,
        icon: <StatusGroupInstallment />,
        title: 'Não perca tempo!',
        description:
          'Mantenha suas contas em dia pagando com PIX, boleto ou parcelando em até 12X no cartão de crédito.',
      };
    }

    return {
      color: Color.green,
      icon: <StatusGroupCongratulations />,
      title: 'Parabéns!',
      description: 'Agradecemos por manter as suas contas em dia.',
    };
  };

  return (
    <Container>
      <ContainerPaymentGroup>
        <PaymentGroupCard
          fullHeight={!(invoicesGroup && invoicesGroup?.length > 1 && !loading)}
          color={getStatusInvoicesGroup().color}
        >
          <p>
            <span>{getStatusInvoicesGroup().icon}</span>
            {getStatusInvoicesGroup().title}
          </p>
          <p>
            <span>{getStatusInvoicesGroup().icon} </span>
            {!invoices || invoices.length < 1 || loading ? (
              <div>{getStatusInvoicesGroup().description}</div>
            ) : (
              getStatusInvoicesGroup().description
            )}
          </p>
        </PaymentGroupCard>
        {invoicesGroup && invoicesGroup?.length > 1 && !loading && (
          <PaymentGroupButton
            type="button"
            onClick={() => {
              ReactGA.event({
                category: 'Pagamento Único',
                action: '[Pag.Único] [WEB] Parcelar meus débitos',
              });
              Navigation.navigate(RouteName.SINGLEPAYMENT, {
                invoices: invoicesGroup.reverse(),
              });
            }}
          >
            Parcelar meus débitos
          </PaymentGroupButton>
        )}
      </ContainerPaymentGroup>
      <ContainerInvoices>
        <InvoiceSlick />
      </ContainerInvoices>
    </Container>
  );
};

export default EasyInvoice;
