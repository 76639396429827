import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${Color.blueIntense};
  background: ${Color.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 16px;
    color: ${Color.blueIntense};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  button {
    height: 48px;
    background: ${Color.red};
    border-radius: 8px;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: auto;
    border-radius: 16px;
    max-width: calc(392px - 58px);
    min-height: calc(336px - 64px);
    padding: 32px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  button {
    border-radius: 8px;
    border: 1px solid #000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    filter: none;
    box-shadow: none;

    :first-of-type {
      background: ${Color.blueIntense};
    }

    :last-of-type {
      border: none;
      background: ${Color.white};
      color: ${Color.blueIntense};
      text-decoration: underline;
      max-width: fit-content;
      margin: 0 auto;
    }
  }
`;
