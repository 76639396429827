import React, { FC } from 'react';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import NegotiationDetail from '~/components/NegotiationDetail';
import { Main } from './styles';

const NegotiationsDetail: FC = () => {
  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Minhas solicitações',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Detalhes da negociação',
      active: true,
    },
  ];

  return (
    <Main>
      <NegotiationDetail links={links} loading={false} />;
    </Main>
  );
};

export default NegotiationsDetail;
