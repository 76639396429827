/* eslint-disable prefer-destructuring */
import React, { FC, useEffect, useState } from 'react';
import creditCardType from 'credit-card-type';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerPayment,
  BoxDivider,
  ContainerCardTemplate,
  LabelPaymentMethods,
  ContainerPix,
  ContentSinglePayment,
  AlertFraudBox,
  PaymentButton,
  ContainerSinglePayment,
  ContainerQrCodePix,
} from './styles';
import CreditCardTemplate from './CardTemplate';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import formatter from '~/utils/formatter';

import { IState } from '~/store/modules/types';
import { CopyCode } from './CopyCode';
import { DescriptionPayment } from './DescriptionPayment';
import LoadingComponent from '~/components/Loading';
import { PaymentMethods } from './PaymentMethods';
import { QrCode } from './QrCode';
import ModalError from './ModalError';
import { checkToggle } from '~/utils/toggles';
import WarningBox from '~/components/WarningBox';
import { PaymentIcon } from './icons';
import ModalPaymentUnavailable, {
  ModalPaymentUnavailableTypeInfo,
} from './ModalPaymentUnavailable';
import { IErrors, IInstallment, IPaymentProps } from './types';
import AlertPixFraud from '~/components/AlertPixFraud';
import { Dialog } from '~/components/Dialog';

const Payment: FC<IPaymentProps> = ({
  links,
  loading,
  loadingLabel,
  openModalError,
  paymentUnavailable,
  paymentData,
  handlePaymentGroup,
  handleDownloadBillet,
}) => {
  // const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isMobileTablet = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const error = useSelector((item: IState) => item.enrollmentsPayment.error);
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);
  const infoGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.info,
  );

  const [openPaymentUnavailable, setOpenPaymentUnavailable] =
    useState<boolean>(false);
  const [paymentUnavailableTypeInfo, setPaymentUnavailableTypeInfo] =
    useState<ModalPaymentUnavailableTypeInfo>('credit_card');
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [dataPayment, setDataPayment] = useState<any>({
    installment: '',
    cardNumber: '',
    cardHolder: '',
    validity: '',
    securityCode: '',
  });
  const [brandCard, setBrandCard] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'Validade do cartão inválida',
    },
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [codeVisible, setCodeVisible] = useState<boolean>(false);
  const [typePayment, setTypePayment] = useState<'credit_card' | 'pix' | 'billet'>(
    'pix',
  );
  const [braspagExists, setBraspagExists] = useState<boolean>(false);
  const [groupPayment, setGroupPayment] = useState<boolean>(true);

  const typePaymentNumber = braspagExists ? 1 : 3;

  console.log({ typePaymentNumber });

  useEffect(() => {
    const array: IInstallment[] = [
      {
        label: 'Selecione',
        value: undefined,
      },
    ];

    for (
      let index = 0;
      index < paymentData?.possible_quantity_installments;
      index++
    ) {
      array.push({
        label: `${index + 1} x sem juros`,
        value: index + 1,
      });
    }
    setInstallments(array || []);
  }, []);

  const verifyToggles = async () => {
    setBraspagExists(await checkToggle(toggles, 'BRASPAG'));
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },
    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },
    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType(
      (dataPayment?.cardNumber || '').replace(/\D/g, ''),
    ) as any;

    if (brandType?.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType?.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand?.issuer === brandType?.type,
    );

    if (brand?.brand) setBrandCard(brand?.issuer);
    else setBrandCard('');
  };

  useEffect(() => {
    findBrand();
  }, [dataPayment?.cardNumber]);

  useEffect(() => {
    if (
      dataPayment?.installment &&
      dataPayment?.cardNumber &&
      dataPayment?.cardHolder &&
      dataPayment?.validity &&
      dataPayment?.securityCode &&
      !errors?.number?.error &&
      !errors?.validity?.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [dataPayment, errors]);

  const validateCardNumber = () => {
    if (
      (dataPayment?.cardNumber && dataPayment?.cardNumber?.length < 19) ||
      (dataPayment?.cardNumber && dataPayment?.cardNumber?.length > 0 && !brandCard)
    ) {
      setErrors(({ number, validity }: any) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (dataPayment?.validity && dataPayment?.validity?.length < 7) {
      setErrors(({ number, validity }: any) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  const backNavigation = () => Navigation.goBack();

  const errorMessage = () => {
    switch (error?.message) {
      case 'INVALID_DATA':
        return 'Dados inválidos';
      case 'UNAUTHORIZED_TRANSACTION':
        return 'Transação não autorizada';
      case 'UNAUTHORIZED_TRANSACTION_TRY_AGAIN_LATER':
        return 'Transação não concluída';
      case 'UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR':
        return 'Transação não concluída';
      default:
        return 'Transação não concluída';
    }
  };

  const closeModalPaymentUnavailable = () =>
    setOpenPaymentUnavailable(!openPaymentUnavailable);

  return (
    <Main>
      {openModalError && (
        <Modal open={openModalError} type="error" title={errorMessage()}>
          <ModalError />
        </Modal>
      )}
      <Dialog open={openPaymentUnavailable} onClose={closeModalPaymentUnavailable}>
        <ModalPaymentUnavailable
          onClose={closeModalPaymentUnavailable}
          typeInfo={paymentUnavailableTypeInfo}
        />
      </Dialog>
      {loading && <LoadingComponent labelWait={loadingLabel} />}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento de fatura</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Dados de pagamento</h2>
          <Border />
        </ContainerBorder>
        <Margin height={24} />
        <LabelPaymentMethods>Selecione a forma de pagamento:</LabelPaymentMethods>
        <PaymentMethods
          type={typePayment}
          setType={setTypePayment}
          setPaymentUnavailableTypeInfo={setPaymentUnavailableTypeInfo}
          paymentUnavailable={paymentUnavailable}
          setOpenPaymentUnavailable={setOpenPaymentUnavailable}
        />
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(paymentData?.amount)}</p>
        </ContainerAmount>
        <Margin height={24} />
        {typePayment === 'credit_card' && (
          <>
            {infoGroup && infoGroup?.opened > 1 && groupPayment && (
              <ContentSinglePayment>
                <ContainerSinglePayment>
                  <div>
                    <p>Não perca essa chance!</p>
                    <p>
                      Você ainda pode quitar todos seus débitos em apenas uma
                      transação e <br /> até 12X sem juros no cartão de crédito.
                    </p>
                  </div>
                  <PaymentButton type="button" onClick={handlePaymentGroup}>
                    Pagamento único
                    <PaymentIcon />
                  </PaymentButton>
                </ContainerSinglePayment>
              </ContentSinglePayment>
            )}
            <ContainerPayment>
              <BoxDivider>
                <ContainerCardTemplate>
                  <CreditCardTemplate
                    holder={dataPayment?.cardHolder}
                    cardNumber={dataPayment?.cardNumber}
                    securityCode={dataPayment?.securityCode}
                    validity={dataPayment?.validity}
                    issuer={brandCard}
                  />
                </ContainerCardTemplate>
              </BoxDivider>
              <BoxDivider>
                <div className="container-inputs">
                  <InputSelect
                    label="Parcelas"
                    name="installment"
                    values={installments}
                    onChange={(ev) => {
                      setDataPayment({
                        ...dataPayment,
                        installment: ev.target.value,
                      });
                    }}
                    errorPosition="bottom"
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Número do cartão"
                    name="cardNumber"
                    value={dataPayment?.cardNumber}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardNumber: formatter.creditCard(ev.target.value) || '',
                      })
                    }
                    onBlur={validateCardNumber}
                    errorText={errors?.number?.message}
                    error={errors?.number?.error}
                    errorPosition="bottom"
                    maxLength={19}
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Nome do titular"
                    name="cardHolder"
                    value={dataPayment?.cardHolder}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardHolder: ev.target.value,
                      })
                    }
                  />
                </div>
                <div className="container-inputs container-inputs-box">
                  <Input
                    label="Validade"
                    name="validity"
                    value={dataPayment?.validity}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        validity: formatter.creditCardValidity(ev.target.value),
                      })
                    }
                    onBlur={validateCardValidity}
                    errorText={errors?.validity?.message}
                    error={errors?.validity?.error}
                    errorPosition="bottom"
                    placeholder="mm/aaaa"
                  />
                  <Input
                    label={isMobile ? 'Cód. de segurança' : 'Código de segurança'}
                    name="securityCode"
                    value={dataPayment?.securityCode}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        securityCode: formatter.formatarNumber(ev.target.value),
                      })
                    }
                    errorPosition="bottom"
                    maxLength={3}
                    placeholder="000"
                    type={!codeVisible ? 'password' : 'text'}
                    iconPassword
                    passwordVisible={codeVisible}
                    setPasswordVisible={setCodeVisible}
                  />
                </div>
              </BoxDivider>
            </ContainerPayment>
          </>
        )}
        {typePayment === 'pix' && (
          <>
            <ContainerPix>
              <ContainerQrCodePix>
                <QrCode qr_code_key={paymentData?.qr_code_key} />
                {!isMobileTablet && (
                  <AlertFraudBox>
                    <AlertPixFraud
                      company="IGUA RIO SANEAMENTO"
                      cnpj=" 08.159.965/0001-33"
                    />
                  </AlertFraudBox>
                )}
              </ContainerQrCodePix>
              {isMobileTablet && (
                <AlertFraudBox>
                  <AlertPixFraud
                    company="IGUA RIO SANEAMENTO"
                    cnpj=" 08.159.965/0001-33"
                  />
                </AlertFraudBox>
              )}
              <DescriptionPayment type={typePayment} />
            </ContainerPix>
            <CopyCode code_copy={paymentData?.qr_code_key} type={typePayment} />
          </>
        )}
        {typePayment === 'billet' && (
          <>
            <DescriptionPayment type={typePayment} />
            <CopyCode code_copy={paymentData?.bar_code_number} type={typePayment} />
          </>
        )}
        <Margin height={24} />
        <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
        <ContainerButtons>
          <ButtonText text="Voltar" typeBtn="content" onClick={backNavigation} />
          {typePayment === 'credit_card' ? (
            <ButtonText
              typeBtn="content"
              backgroundColor={disabled ? Color.gray : Color.blueSeconde}
              onClick={() => {
                if (disabled || loading) return;
                console.log('Pagamento por cartão');
              }}
              loading={loading}
              nextIcon
            >
              Confirmar pagamento
            </ButtonText>
          ) : (
            <ButtonText
              typeBtn="content"
              backgroundColor={Color.gray}
              onClick={() => {
                return;
                handleDownloadBillet(paymentData?.invoice_id);
              }}
              loading={loading}
            >
              Baixar boleto de pagamento
            </ButtonText>
          )}
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default Payment;
