import { Dispatch } from 'redux';
import {
  validateCustomerObligationService,
  createCustomerObligationService,
  uploadFileCustomerObligationService,
  attachFilesCustomerObligationService,
  fileCodesCustomerObligationService,
  createFileCodeCustomerObligationService,
  getDataDownloadFileCustomerObligationService,
  listCustomerObligationService,
  uploadUpdateFileCustomerObligationService,
  patchCustomerObligationStepService,
  patchProtocolCustomerObligationService,
  getCustomerObligationService,
  getStepCustomerObligationService,
} from '~/services/customerObligation';
import onShareFile from '~/utils/onShareFile';
import {
  RepositoriesTypesCustomerObligation,
  IResponseGetValidateCustomerObligation,
  IRequestGetValidateCustomerObligation,
  IRequestPostCreateCustomerObligation,
  IRequestPostUploadFileCustomerObligation,
  IRequestPostAttachFilesCustomerObligation,
  IResponsePostCreateCustomerObligation,
  IRequestGetFileCodesCustomerObligation,
  IRequestPostFileCodesCustomerObligation,
  IRequestGetDownloadFileCustomerObligation,
  IResponseGetDownloadFileCustomerObligation,
  IResponseGetListCasesOC,
  IRequestPatchUploadFileCustomerObligation,
  IRequestPatchProtocolCustomerObligation,
  IRequestGetCustomerObligation,
  IResponseStep,
} from './types';

const getListCustomerObligationRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_REQUEST,
    });

    const response =
      (await listCustomerObligationService()) as IResponseGetListCasesOC;

    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          message: error?.response?.data.type,
          state: true,
        },
      },
    });
  }
};

const getErrorCloseListCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE_CLOSE,
    });
  };

const getItemCustomerObligationRequest =
  (data: IRequestGetCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_REQUEST,
      });

      const response = (await getCustomerObligationService(
        data,
      )) as IResponseGetListCasesOC;

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_SUCCESS,
        payload: {
          data: { ...response, realStep: response.step },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const clearItemCustomerObligationRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_CLEAR,
  });
};

const getValidateCustomerObligationRequest =
  (data: IRequestGetValidateCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_REQUEST,
      });

      const response = (await validateCustomerObligationService(
        data,
      )) as IResponseGetValidateCustomerObligation;

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            type: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseValidateCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE_CLOSE,
    });
  };

const clearDataValidateCustomerObligation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_CLEAR,
  });
};

const postCreateCustomerObligationRequest =
  (data: IRequestPostCreateCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_REQUEST,
      });

      const response = (await createCustomerObligationService(
        data,
      )) as IResponsePostCreateCustomerObligation;

      const responseDataItem = await getCustomerObligationService({
        caseID: response.id,
      });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_SUCCESS,
        payload: {
          data: response.id,
          dataItem: responseDataItem,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            type: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseCreateCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE_CLOSE,
    });
  };

const clearDataCreateCustomerObligation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_CLEAR,
  });
};

const clearNewActualStepCustomerObligation = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_CLEAR_STEP_METHOD,
  });
};

const postUploadFileCustomerObligationRequest =
  (data: IRequestPostUploadFileCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_REQUEST,
      });

      const response = await uploadFileCustomerObligationService(data);

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_SUCCESS,
        payload: {
          attachments: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseUploadFileCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE_CLOSE,
    });
  };

const patchUploadFileCustomerObligationRequest =
  (data: IRequestPatchUploadFileCustomerObligation) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_REQUEST,
      });

      await uploadUpdateFileCustomerObligationService(data);

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchErrorCloseUploadFileCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE_CLOSE,
    });
  };

const deleteUploadFileCustomerObligationRequest =
  (data: IRequestPostUploadFileCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_REQUEST,
      });

      await uploadFileCustomerObligationService(data);

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const deleteErrorCloseUploadFileCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE_CLOSE,
    });
  };

const setSuccessAttachFilesCodeTrue = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_SUCCESS,
    payload: {
      successAttachFilesCode: true,
    },
  });
};

const patchAttachFilesCustomerObligationRequest =
  (data: IRequestPostAttachFilesCustomerObligation) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_REQUEST,
      });

      await attachFilesCustomerObligationService(data);

      const responseStep = await getStepCustomerObligationService({
        caseID: data.case,
      });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_SUCCESS,
        payload: {
          data: {
            successAttachFiles: true,
            loadingAttachFiles: false,
          },
          step: responseStep,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchAttachRevisionFilesDocumentsAndProjectsOCRequest =
  ({
    document,
    project,
  }: {
    document: IRequestPostAttachFilesCustomerObligation;
    project: IRequestPostAttachFilesCustomerObligation;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_REQUEST,
      });

      await attachFilesCustomerObligationService(document);
      await attachFilesCustomerObligationService(project);

      const response = await getStepCustomerObligationService({
        caseID: document.case,
      });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_SUCCESS,
        payload: {
          step: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchProtocolCustomerObligationRequest =
  (data: IRequestPatchProtocolCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_REQUEST,
      });

      await patchProtocolCustomerObligationService(data);

      const response = await getStepCustomerObligationService({ caseID: data.case });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_SUCCESS,
        payload: {
          step: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const clearProtocolCustomerObligationRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_CLEAR,
  });
};

const patchCustomerObligationStepRequest =
  (data: IRequestPostAttachFilesCustomerObligation) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_REQUEST,
      });

      await patchCustomerObligationStepService(data);
      const responseStep = await getStepCustomerObligationService({
        caseID: data.case,
      });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_SUCCESS,
        payload: {
          step: responseStep,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const patchErrorCloseAttachFilesCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE_CLOSE,
    });
  };

const clearDataAttachFilesCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_CLEAR,
    });
  };

const getFileCodesCustomerObligationRequest =
  (data: IRequestGetFileCodesCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_REQUEST,
      });

      const response = (await fileCodesCustomerObligationService(
        data,
      )) as IResponseGetValidateCustomerObligation;

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_SUCCESS,
        payload: {
          documentType: data.documentType,
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseFileCodesCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE_CLOSE,
    });
  };

const postCreateFileCodesCustomerObligationRequest =
  (data: IRequestPostFileCodesCustomerObligation) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_REQUEST,
      });

      const response = (await createFileCodeCustomerObligationService(
        data,
      )) as IResponseGetValidateCustomerObligation;

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_SUCCESS,
        payload: {
          documentType: data.documentType,
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseCreateFileCodesCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE_CLOSE,
    });
  };

const getDataDownloadFileCustomerObligationRequest =
  (data: IRequestGetDownloadFileCustomerObligation) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_REQUEST,
      });

      const response = (await getDataDownloadFileCustomerObligationService(
        data,
      )) as IResponseGetDownloadFileCustomerObligation;

      onShareFile({ buffer: response.file, fileName: 'documento-oc' });

      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            message: error?.response?.data.type,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseDataDownloadFileCustomerObligationRequest =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE_CLOSE,
    });
  };

const patchStatusStepCustomerObligationRequest =
  (data: IResponseStep) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_REQUEST,
      payload: {
        step: data,
      },
    });
  };

const clearCustomerObligationRequest = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_METHOD_CLEAR,
  });
};

export {
  getListCustomerObligationRequest,
  getItemCustomerObligationRequest,
  clearItemCustomerObligationRequest,
  getErrorCloseListCustomerObligationRequest,
  getValidateCustomerObligationRequest,
  getErrorCloseValidateCustomerObligationRequest,
  clearDataValidateCustomerObligation,
  postCreateCustomerObligationRequest,
  postErrorCloseCreateCustomerObligationRequest,
  clearDataCreateCustomerObligation,
  postUploadFileCustomerObligationRequest,
  postErrorCloseUploadFileCustomerObligationRequest,
  patchUploadFileCustomerObligationRequest,
  patchErrorCloseUploadFileCustomerObligationRequest,
  deleteUploadFileCustomerObligationRequest,
  deleteErrorCloseUploadFileCustomerObligationRequest,
  patchAttachFilesCustomerObligationRequest,
  patchErrorCloseAttachFilesCustomerObligationRequest,
  clearDataAttachFilesCustomerObligationRequest,
  getFileCodesCustomerObligationRequest,
  getErrorCloseFileCodesCustomerObligationRequest,
  postCreateFileCodesCustomerObligationRequest,
  postErrorCloseCreateFileCodesCustomerObligationRequest,
  getDataDownloadFileCustomerObligationRequest,
  getErrorCloseDataDownloadFileCustomerObligationRequest,
  patchCustomerObligationStepRequest,
  clearNewActualStepCustomerObligation,
  setSuccessAttachFilesCodeTrue,
  patchProtocolCustomerObligationRequest,
  clearProtocolCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  clearCustomerObligationRequest,
  patchAttachRevisionFilesDocumentsAndProjectsOCRequest,
};
