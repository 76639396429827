import React from 'react';

import { Main } from './styles';
import { Margin } from '../Margin';

interface IProps {
  label: string;
}
const SuccesBox: React.FC<IProps> = ({ label }) => {
  return (
    <Main>
      <svg
        width="44"
        height="45"
        viewBox="0 0 44 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.474609" width="44" height="44" rx="8" fill="#37B482" />
        <path
          d="M17 22.4746L20.75 26.2246L27 19.9746M32 22.4746C32 27.9975 27.5228 32.4746 22 32.4746C16.4772 32.4746 12 27.9975 12 22.4746C12 16.9518 16.4772 12.4746 22 12.4746C25.158 12.4746 27.9741 13.9385 29.8067 16.2246C31.1791 17.9366 32 20.1097 32 22.4746Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Margin width={24} />
      {label}
    </Main>
  );
};

export default SuccesBox;
