import React from 'react';
import { Color } from '~/styles';

const SuccessIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="8" fill={Color.green} />
      <path
        d="M17 22L20.75 25.75L27 19.5M32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22C12 16.4772 16.4772 12 22 12C25.158 12 27.9741 13.4638 29.8067 15.75C31.1791 17.462 32 19.6351 32 22Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CongratulationsIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.277344" width="48" height="48" rx="8" fill={Color.green} />
      <path
        d="M17.8 23.5773L14 34.2773L24.7 30.4873M16 15.2773H16.01M34 20.2773H34.01M27 14.2773H27.01M34 32.2773H34.01M34 14.2773L31.76 15.0273C31.1224 15.2397 30.5783 15.667 30.2208 16.2361C29.8633 16.8052 29.7146 17.4807 29.8 18.1473C29.9 19.0073 29.23 19.7773 28.35 19.7773H27.97C27.11 19.7773 26.37 20.3773 26.21 21.2173L26 22.2773M34 25.2773L33.18 24.9473C32.32 24.6073 31.36 25.1473 31.2 26.0573C31.09 26.7573 30.48 27.2773 29.77 27.2773H29M23 14.2773L23.33 15.0973C23.67 15.9573 23.13 16.9173 22.22 17.0773C21.52 17.1773 21 17.7973 21 18.5073V19.2773"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 25.2773C24.93 27.2073 25.83 29.4473 25 30.2773C24.17 31.1073 21.93 30.2073 20 28.2773C18.07 26.3473 17.17 24.1073 18 23.2773C18.83 22.4473 21.07 23.3473 23 25.2773Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SuccessIcon, CongratulationsIcon };
