import styled from 'styled-components';

// import { Breakpoints } from '../../../styles/breakpoints';

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #999999;
`;
