import { history } from '../store';

function navigate(pathName: string, state?: any): void {
  if (state) {
    history.push(pathName, state);
  } else {
    history.push(pathName);
  }
}

function replace(pathName: string, state?: any): void {
  if (state) {
    history.replace(pathName, state);
  } else {
    history.replace(pathName);
  }
}

function goBack() {
  history.goBack();
}

export default { navigate, replace, goBack };
