import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  AlertBtn,
  AlertForm,
  AlertInput,
  AlertInputLabel,
  AlertInputWrapper,
} from './styles';
import { ConfigureAlertIcon } from './icons';
import { IState } from '~/store/modules/types';
import {
  getEnrollmentsAlertsRequest,
  patchEnrollmentsAlertRequest,
  postEnrollmentsCreateAlertRequest,
} from '~/store/modules/enrollmentsAlerts/actions';
import { ClipLoader } from 'react-spinners';

const renderAlertInput = (
  cubicMeter: string,
  setCubicMeter: (cubic_meter: string) => void,
) => {
  return (
    <AlertInputWrapper>
      <AlertInput
        value={cubicMeter}
        onChange={(ev) => {
          if (Number(ev.target.value) < 0) return;
          setCubicMeter(ev.target.value.replace(',', '.'));
        }}
      />
      <AlertInputLabel id="cubic-meter">m³</AlertInputLabel>
    </AlertInputWrapper>
  );
};

const renderAlertBtn = (
  icon: '+' | '-',
  cubicMeter: string,
  setCubicMeter: (cubic_meter: string) => void,
) => {
  return (
    <AlertBtn
      onClick={() => {
        if (Number(cubicMeter) === 0 && icon === '-') return;
        setCubicMeter(
          icon === '+'
            ? (Number(cubicMeter) + 1).toString()
            : (Number(cubicMeter) - 1).toString(),
        );
      }}
    >
      {icon}
    </AlertBtn>
  );
};

const renderAlertForm = (
  cubicMeter: string,
  setCubicMeter: (cubic_meter: string) => void,
) => {
  return (
    <AlertForm>
      {renderAlertBtn('-', cubicMeter, setCubicMeter)}
      {renderAlertInput(cubicMeter, setCubicMeter)}
      {renderAlertBtn('+', cubicMeter, setCubicMeter)}
    </AlertForm>
  );
};

export const ConfigureAlert = () => {
  const dispatch = useDispatch();

  const [cubicMeter, setCubicMeter] = useState<string>('');
  const alertsData = useSelector((item: IState) => item?.enrollmentsAlerts.data);
  const loadingCreateAlert = useSelector(
    (item: IState) => item?.enrollmentsAlerts.loadingCreateAlert,
  );
  const loadingPatchAlert = useSelector(
    (item: IState) => item?.enrollmentsAlerts.loadingPatchAlert,
  );

  useEffect(() => {
    dispatch(getEnrollmentsAlertsRequest());
    if (alertsData && alertsData[0]?.is_active && alertsData[0]?.cubic_meter)
      setCubicMeter(alertsData[0]?.cubic_meter.toString());
  }, []);

  return (
    <Container>
      <div className="header">
        {' '}
        <div>
          {' '}
          <ConfigureAlertIcon />
        </div>
        <h2>Alerta de consumo</h2>
      </div>
      <p>
        A Medição Digital permite que você configure até quanto deseja{' '}
        <strong>monitorar diariamente</strong> e será alertado quando o ultrapassar.
      </p>
      {renderAlertForm(cubicMeter, setCubicMeter)}
      <p>
        {(alertsData && !alertsData[0]?.is_active) ||
        (alertsData && alertsData?.length === 0)
          ? 'Sem alerta configurado'
          : `Seu alerta atual: ${alertsData && alertsData[0]?.cubic_meter}m³`}
      </p>
      <button
        type="button"
        onClick={() => {
          if (Number(cubicMeter) < 0 || loadingCreateAlert || loadingPatchAlert)
            return;
          if (alertsData && alertsData.length > 0) {
            dispatch(
              patchEnrollmentsAlertRequest({
                cubic_meter: Number(cubicMeter),
                alertId: alertsData[0]?.id,
              }),
            );
          } else {
            dispatch(
              postEnrollmentsCreateAlertRequest({
                cubic_meter: Number(cubicMeter),
              }),
            );
          }
        }}
        id="create-alert"
      >
        {loadingCreateAlert || loadingPatchAlert ? (
          <ClipLoader color="#FFF" loading size={10} />
        ) : (
          'Configurar alerta'
        )}
      </button>
    </Container>
  );
};
