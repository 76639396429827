import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from '~/components/Button';
import { Margin } from '~/components/Margin';

import { Main } from './styles';
import { RepositoriesTypesUsers } from '~/store/modules/enrollmentsUsers/types';

const ModalError: FC = () => {
  const dispatch = useDispatch();
  return (
    <Main>
      Não foi possivel processar sua solicitação.
      <br />
      Por favor tente mais tarde!
      <Margin height={39} />
      <Button
        modalType="error"
        text="Entendi"
        type="button"
        onClick={() =>
          dispatch({
            type: RepositoriesTypesUsers.CLEAN,
          })
        }
      />
    </Main>
  );
};

export default ModalError;
