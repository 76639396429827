/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerHeader,
  ContainerInvoice,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Breakpoints, Color } from '~/styles';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { statusColor } from '~/utils/invoiceStatus';

interface IDadosDebtsCustom extends IDadosDebts {
  active: boolean;
}

interface IInvoiceGroupDebts {
  address: string;
  debts: IDadosDebtsCustom[];
  activeAll: boolean;
}

const DebtNegotiationSinglePayment: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.laptop.min });

  const dataDados = history.location.state as any;

  const [invoicesGroupDebts, setInvoicesGroupDebts] = useState<
    Array<IInvoiceGroupDebts>
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Quitação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Pagamento único',
      active: true,
    },
  ];

  useEffect(() => {
    setInvoicesGroupDebts(dataDados?.dados);
  }, []);

  const getActiveDebts = () => {
    let activeDebts: Array<any> = [];

    invoicesGroupDebts?.map((item) => {
      item?.debts.map((invoice) => {
        if (invoice?.active)
          return activeDebts.push({
            ...invoice,
          });

        return invoice;
      });
    });

    return activeDebts;
  };

  const addInvoices = () => {
    const amountInvoices = getActiveDebts()?.reduce(
      (total: any, number: any) => total + number.amount,
      0,
    );

    setTotalAmount(amountInvoices);
  };

  const handleCheck = ({ checked, index }: { checked: boolean; index: number }) => {
    let data: Array<IInvoiceGroupDebts> = [...invoicesGroupDebts];

    data[index].activeAll = !checked;

    data[index].debts = data[index]?.debts?.map((debt) => {
      return {
        ...debt,
        active: !checked,
      };
    });

    setInvoicesGroupDebts(data);
  };

  const handleAllCheck = ({ debts }: { debts: IDadosDebtsCustom[] }) => {
    const debtInactive = debts?.find((debt) => !debt.active);
    return !debtInactive;
  };

  const getDescriptionDebt = (type: string) => {
    return type?.toUpperCase() === 'INVOICE' ? (
      'Fatura'
    ) : (
      <>
        {' '}
        Outros <br />
        serviços{' '}
      </>
    );
  };

  useEffect(() => {
    if (invoicesGroupDebts) addInvoices();
  }, [invoicesGroupDebts]);

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento único</h1>
        {invoicesGroupDebts &&
          invoicesGroupDebts?.map((item, index) => (
            <React.Fragment key={item?.address}>
              <ContainerBorder>
                <Border />
              </ContainerBorder>
              {invoicesGroupDebts?.length > 1 && <h3>Endereço: {item?.address}</h3>}
              <ContainerHeader>
                <div>
                  <label className="container">
                    <input
                      type="checkbox"
                      name="checkAll"
                      onChange={() =>
                        handleCheck({ checked: item?.activeAll, index })
                      }
                      checked={item?.activeAll}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <p>Status/tipo</p>
                {!isMobile && <p>Vencimento</p>}
                <p>Valor</p>
                {!isMobile && <p>Referência</p>}
              </ContainerHeader>
              {item?.debts?.map((invoice: IDadosDebtsCustom, indexItem: number) => (
                <ContainerInvoice
                  key={invoice?.id}
                  color={statusColor(invoice?.status?.label, true)}
                >
                  <div>
                    <span />
                    <label className="container">
                      <input
                        type="checkbox"
                        name={`check${invoice?.id}`}
                        onChange={() => {
                          let data: Array<IInvoiceGroupDebts> = [
                            ...invoicesGroupDebts,
                          ];

                          data[index].debts[indexItem].active = !invoice?.active;
                          data[index].activeAll = handleAllCheck({
                            debts: data[index].debts,
                          });

                          setInvoicesGroupDebts(data);
                        }}
                        checked={invoice.active}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <p className="status">
                    {invoice?.status?.label} <br />{' '}
                    <span>{getDescriptionDebt(invoice?.type)}</span>
                  </p>
                  {!isMobile && (
                    <p>{moment(invoice?.maturity_date).format('DD/MM/YYYY')}</p>
                  )}
                  <p>{formatter.formatCurrency(invoice?.amount)}</p>
                  {!isMobile && <p>{invoice?.reference}</p>}
                </ContainerInvoice>
              ))}
            </React.Fragment>
          ))}
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(totalAmount)}</p>
        </ContainerAmount>
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() => {
              ReactGA.event({
                category: 'Negociação de dívidas',
                action: '[Negociação de dívidas][Pagamento Único] Voltar',
              });

              Navigation.goBack();
            }}
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={
              getActiveDebts()?.length > 0 ? Color.blueSeconde : Color.gray
            }
            onClick={() => {
              if (getActiveDebts()?.length < 1) return;

              ReactGA.event({
                category: 'Negociação de dívidas',
                action:
                  '[Negociação de dívidas][Pagamento Único] Ir para o pagamento',
              });

              let data: Array<any> = [];

              getActiveDebts()?.map((invoice: any) => {
                return data.push({
                  ...invoice,
                  invoice_id: invoice?.invoice_id || invoice?.id,
                });
              });

              Navigation.navigate(RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT);
            }}
            nextIcon
          >
            Ir para pagamento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default DebtNegotiationSinglePayment;
