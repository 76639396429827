import { EnumResponseCode } from '~/enums/reponseCodesEnum';

interface IError {
  message?: string;
  status: number;
  state: boolean;
}

export const showRecaptchaV2 = (error?: IError) => {
  const showRecaptchaV2 =
    error?.message === EnumResponseCode.TOKENS_RECAPTCHA_MISSING ||
    error?.message === EnumResponseCode.TOKEN_V3_RECAPTCHA_MISSING ||
    error?.message === EnumResponseCode.TOKEN_V3_RECAPTCHA_INVALID ||
    error?.message === EnumResponseCode.TOKEN_V2_RECAPTCHA_INVALID ||
    error?.message === EnumResponseCode.RECAPTCHA_GENERIC;

  return showRecaptchaV2;
};
