import { api } from '~/services/api';

interface IOutsideToken {
  token: string;
}

export const apiHeaderSetOutsideToken = ({ token }: IOutsideToken) => {
  localStorage.removeItem('@digiIgua_data_userLogin');

  delete api.defaults.headers.common.authorization;

  api.defaults.headers.common.authorization = `Bearer ${token}`;
};
