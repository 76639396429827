import {
  IRequestSubscribeCreate,
  IRequestSubscribeDelete,
} from '~/store/modules/enrollmentsSubscribes/types';
import { api } from './api';

const getEnrollmentsSubscribesMeService = () => {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSubscribesMe = async () => {
        const response = await api.get('v3/subscribes/me');
        return response.data;
      };

      resolve(getEnrollmentsSubscribesMe());
    } catch (error) {
      reject(error);
    }
  });
};

const getEnrollmentsSubscribesMeEnrollmentService = (enrollment: string) => {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSubscribesMe = async () => {
        const response = await api.get(`v3/subscribes/me/enrollments/${enrollment}`);
        return response.data;
      };

      resolve(getEnrollmentsSubscribesMe());
    } catch (error) {
      reject(error);
    }
  });
};

const getEnrollmentsSubscribesAllPlansService = () => {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSubscribesAllPlans = async () => {
        const response = await api.get('v3/plans');
        return response.data;
      };

      resolve(getEnrollmentsSubscribesAllPlans());
    } catch (error) {
      reject(error);
    }
  });
};

const postEnrollmentsSubscribesMeService = (data: IRequestSubscribeCreate) => {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSubscribesMe = async () => {
        const response = await api.post('v3/subscribes/me', { ...data });
        return response.data;
      };

      resolve(getEnrollmentsSubscribesMe());
    } catch (error) {
      reject(error);
    }
  });
};

const deleteEnrollmentsSubscribesMeService = (data: IRequestSubscribeDelete) => {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsSubscribesMe = async () => {
        const response = await api.delete('v3/subscribes/me', { data: { ...data } });
        return response.data;
      };

      resolve(getEnrollmentsSubscribesMe());
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getEnrollmentsSubscribesMeService,
  getEnrollmentsSubscribesMeEnrollmentService,
  getEnrollmentsSubscribesAllPlansService,
  postEnrollmentsSubscribesMeService,
  deleteEnrollmentsSubscribesMeService,
};
