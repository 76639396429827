import styled from 'styled-components/';
import { Breakpoints } from '~/styles';

export const Card = styled.div`
  min-height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 12px;
  flex: 1;
  background: #ffffff;
  border: 1px solid #c7c9c7;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;

  #text-info-user {
    padding: 0 16px 0 78px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 88px;
    flex-direction: row;
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 3px solid #004b55;
  border-radius: 50px;
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  align-items: center;
  text-align: center;
  color: #004b55;
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 18px;
  cursor: pointer;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  color: #004b55;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 191px;
  }
`;
