import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsMain {
  background?: string;
}

export const Main = styled.div<IPropsMain>`
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  display: flex;
  border-radius: 15px;
  box-shadow: cardSmall;
  justify-content: flex-end;
  flex-direction: column;
  background: ${({ background }) => background};

  svg {
    max-width: 100px;
    height: 70px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    padding: 32px;
  }
`;

export const RowCardNumber = styled.div`
  margin: 8px 0;

  @media (min-width: ${Breakpoints.laptop.max}px) {
    margin: 16px 0;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  color: ${Color.white};
  margin: 0;
`;
