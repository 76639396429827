export const mockInstallments = [
  {
    parcela: 'Entrada',
    valor: 'R$ 200,00',
    situacao: 'Quitada',
    referenciaFaturamento: '02/03/2022',
  },
  {
    parcela: '1/4',
    valor: 'R$ 267,02',
    situacao: 'Faturada',
    referenciaFaturamento: '04/2022',
  },
  {
    parcela: '2/4',
    valor: 'R$ 267,02',
    situacao: 'A faturar',
    referenciaFaturamento: '-',
  },
  {
    parcela: '3/4',
    valor: 'R$ 267,02',
    situacao: 'A faturar',
    referenciaFaturamento: '-',
  },
  {
    parcela: '4/4',
    valor: 'R$ 267,02',
    situacao: 'A faturar',
    referenciaFaturamento: '-',
  },
];
