/* eslint-disable no-useless-escape */
export const checkPassword = (value: string, old?: boolean) => {
  if (!value) return 'Por favor, digite uma senha para acessar o Digi Iguá';

  const hasNumber = value.replace(/\D/g, '').length > 0;
  if (!hasNumber) return 'A sua senha precisa ter pelo menos um número';

  const hasUp = value.replace(/[^A-ZÀÁÂÃÄÅÇČĆÈÉÊËÒÓÔÕÖØSSÙÚÛÜŸÆ]/g, '').length > 0;
  if (!hasUp) return 'A sua senha precisa ter pelo menos uma letra maiúscula';

  const hasLower =
    value.replace(/[^a-zàáâãäåçčćèéêëòóôõöøssùúûüÿæ]/g, '').length > 0;
  if (!hasLower) return 'A sua senha precisa ter pelo menos uma letra minúscula';

  const hasSpecial =
    value.replace(/[^-!\@\#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g, '').length > 0;
  if (!hasSpecial) return 'A sua senha precisa ter pelo menos um caractere especial';
  if (value.length < 8) return 'A sua senha precisa ter pelo menos 8 caracteres';

  if (old) return 'ok';
  return '';
};
