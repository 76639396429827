import styled, { css } from 'styled-components';
import { Color } from '~/styles';

interface IProps {
  selected?: boolean;
  error: any;
}

const opt = {
  selected: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${Color.gray};
    font-size: 12px;
    font-weight: 300;
    border: none;
  `,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  .left {
    border-right: none;
  }
  .right {
    border-left: none;
  }
  .empty {
    background: none;
    border: none;
  }
`;

export const UploadLabel = styled.label`
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const Upload = styled.button<IProps>`
  padding: 12px;
  width: 100%;
  min-height: 64px;
  background: none;
  outline: none;
  color: ${Color.blueIntense};
  font-weight: 400;
  line-height: 1.2;
  border: 1px solid ${(p) => (p.error ? Color.red : Color.grayLightSeconde)};
  white-space: pre-wrap;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  ${(p) => (p.selected ? opt.selected : null)}
  span {
    color: ${Color.blueIntense};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const UploadSpecs = styled.div`
  color: ${Color.grayDark};
  font-size: 14px;
`;

export const Box = styled.div``;

export const Button = styled.button`
  /* height: 64px; */
  min-width: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.grayLight};
  border: 1px solid ${Color.grayLightSeconde};
  &:hover {
    cursor: pointer;
  }
`;
