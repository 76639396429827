import React, { InputHTMLAttributes, HTMLInputTypeAttribute } from 'react';

import {
  ContainerInputStyled,
  InputStyled,
  LabelStyled,
  LabelRow,
  LabelError,
} from './styles';

interface Props extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  value?: string;
  label?: string;
  type?: string;
  onChange?: (ev: any) => void;
  error?: boolean;
  errorText?: string;
  maxLength?: number;
  values: Array<any>;
  disabled?: boolean;
  newUx?: boolean;
  style?: any;
  styleInput?: any;
  styleError?: any;
  placeholder?: string;
  errorPosition?: 'top' | 'bottom';
}

export const InputSelect: React.FC<Props> = ({
  value,
  label,
  type,
  onChange,
  error,
  errorText,
  maxLength,
  values,
  disabled,
  newUx,
  style,
  styleInput,
  styleError,
  placeholder,
  errorPosition = 'top',
}) => {
  return (
    <ContainerInputStyled style={styleInput}>
      <LabelRow>
        {label && <LabelStyled>{label}</LabelStyled>}
        {error && errorPosition === 'top' && <LabelError>{errorText}</LabelError>}
      </LabelRow>
      <InputStyled
        type={type}
        error={error}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
        newUx={newUx}
        value={value}
        style={style}
        placeholder={placeholder}
      >
        {values.map((valueData) => (
          <option
            key={valueData.value}
            value={valueData.value}
            selected={value === valueData.value}
          >
            {valueData.label}
          </option>
        ))}
      </InputStyled>
      {error && errorPosition === 'bottom' && (
        <LabelError style={styleError}>{errorText}</LabelError>
      )}
    </ContainerInputStyled>
  );
};
