import React from 'react';

import { Color } from '../../styles';

interface IProp {
  color?: string;
}

const DocumentSVG = ({ color = Color.white }: IProp) => {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06753 22.17H19.6088V25.3053H7.06753V22.17ZM7.06753 15.8993H19.6088V19.0346H7.06753V15.8993ZM16.4735 0.222656H3.9322C2.20777 0.222656 0.796875 1.63355 0.796875 3.35798V28.4406C0.796875 30.165 2.1921 31.5759 3.91653 31.5759H22.7442C24.4686 31.5759 25.8795 30.165 25.8795 28.4406V9.62864L16.4735 0.222656ZM22.7442 28.4406H3.9322V3.35798H14.9059V11.1963H22.7442V28.4406Z"
        fill={color}
      />
    </svg>
  );
};

export default DocumentSVG;
