export enum RepositoriesTypesEnrollmentsTogglesMe {
  TOGGLES_ME_GET_METHOD_REQUEST = '@repositories/TOGGLES_ME_GET_METHOD_REQUEST',
  TOGGLES_ME_GET_METHOD_SUCCESS = '@repositories/TOGGLES_ME_GET_METHOD_SUCCESS',
  TOGGLES_ME_GET_METHOD_FAILURE = '@repositories/TOGGLES_ME_GET_METHOD_FAILURE',
}

export interface IResponse {
  id: number;
  featureToggle: string;
  toggle: boolean;
  description: string;
  appVersion: string;
  scope: string;
  logged: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IRepositoriesStateEnrollmentsTogglesMe {
  readonly data: IResponse[] | [];
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
}
