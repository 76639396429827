import { Reducer } from 'redux';
import {
  RepositoriesTypesCustomerObligation,
  IRepositoriesStateCustomerObligation,
} from './types';

const INITIAL_STATE: IRepositoriesStateCustomerObligation = {
  dataList: null,
  loadingList: false,
  errorList: null,
  data: null,
  dataItem: null,
  loadingItem: false,
  errorItem: null,
  idCreate: '',
  fileCodes: {
    executed_work_descriptive_memorial: [],
    executed_work_calculation_memorial: [],
    executed_work_executive_project: [],
  },
  loading: false,
  error: null,
  loadingCreate: false,
  errorCreate: null,
  successCreate: false,
  errorUploadFile: null,
  loadingUploadFile: false,
  errorUploadUpdateFile: null,
  loadingUploadUpdateFile: false,
  errorAttachFiles: null,
  loadingAttachFiles: false,
  successAttachFiles: false,
  errorFileCodes: null,
  loadingFileCodes: false,
  errorCreateFileCode: null,
  loadingCreateFileCode: false,
  errorAttachFilesCode: null,
  successAttachFilesCode: false,
  errorDownloadFile: null,
  loadingDownloadFile: false,
  loadingDarmCode: false,
  successDarmCode: null,
  newActualStep: null,
};

const reducer: Reducer<IRepositoriesStateCustomerObligation, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_SUCCESS:
      return {
        ...state,
        dataList: action.payload.data,
        loadingList: false,
        errorList: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE:
      return {
        ...state,
        dataList: null,
        errorList: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loadingList: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorList: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_REQUEST:
      return {
        ...state,
        loadingItem: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: action.payload.data,
        loadingItem: false,
        errorList: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_FAILURE:
      return {
        ...state,
        dataList: null,
        errorList: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loadingList: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_ITEM_METHOD_CLEAR:
      return {
        ...state,
        dataItem: null,
        loadingItem: false,
        errorItem: false,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.type,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_CLEAR:
      return {
        ...state,
        data: null,
        idCreate: '',
        error: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_SUCCESS:
      return {
        ...state,
        idCreate: action.payload.data,
        dataItem: {
          ...action.payload.dataItem,
          realStep: action.payload.dataItem.step,
        },
        successCreate: true,
        loadingCreate: false,
        errorCreate: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE:
      return {
        ...state,
        errorCreate: {
          status: action.payload.error.status,
          type: action.payload.error.type,
          state: true,
        },
        loadingCreate: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreate: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_CREATE_METHOD_CLEAR:
      return {
        ...state,
        errorCreate: null,
        successCreate: false,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_REQUEST:
      return {
        ...state,
        loadingUploadFile: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          attachments: action.payload.attachments || action.payload.attachments,
        },
        loadingUploadFile: false,
        errorUploadFile: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE:
      return {
        ...state,
        errorUploadFile: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingUploadFile: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorUploadFile: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_REQUEST:
      return {
        ...state,
        loadingUploadUpdateFile: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_SUCCESS:
      return {
        ...state,
        loadingUploadUpdateFile: false,
        errorUploadUpdateFile: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE:
      return {
        ...state,
        errorUploadUpdateFile: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingUploadUpdateFile: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorUploadUpdateFile: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_REQUEST:
      return {
        ...state,
        loadingAttachFiles: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        dataItem: {
          ...state.dataItem,
          step: action.payload.step || state.dataItem?.step,
          realStep: action.payload.step || state.dataItem?.realStep,
        },
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE:
      return {
        ...state,
        errorAttachFiles: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingAttachFiles: false,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_REQUEST:
      return {
        ...state,
        loadingAttachFiles: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          step: action.payload.step,
          realStep: action.payload.step,
        },
        loadingAttachFiles: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_CLEAR_STEP_METHOD:
      return {
        ...state,
        newActualStep: undefined,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_FAILURE:
      return {
        ...state,
        errorAttachFiles: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingAttachFiles: false,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorAttachFiles: null,
        errorAttachFilesCode: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_CLEAR:
      return {
        ...state,
        loadingAttachFiles: false,
        errorAttachFiles: null,
        successAttachFiles: false,
        errorAttachFilesCode: null,
        successAttachFilesCode: false,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_REQUEST:
      return {
        ...state,
        loadingFileCodes: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_SUCCESS:
      return {
        ...state,
        fileCodes: {
          ...state.fileCodes,
          [action.payload.documentType]: action.payload.data,
        },
        loadingFileCodes: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE:
      return {
        ...state,
        errorFileCodes: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingFileCodes: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorFileCodes: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_REQUEST:
      return {
        ...state,
        loadingCreateFileCode: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_SUCCESS:
      return {
        ...state,
        fileCodes: {
          ...state.fileCodes,
          [action.payload.documentType]: action.payload.data,
        },
        loadingCreateFileCode: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE:
      return {
        ...state,
        errorCreateFileCode: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingCreateFileCode: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreateFileCode: null,
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_REQUEST:
      return {
        ...state,
        loadingDownloadFile: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS:
      return {
        ...state,
        loadingDownloadFile: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE:
      return {
        ...state,
        errorDownloadFile: {
          status: action.payload.status,
          message: action.payload?.type,
          state: true,
        },
        loadingDownloadFile: false,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDownloadFile: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_REQUEST:
      return {
        ...state,
        loadingDarmCode: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          step: action.payload.step,
          realStep: action.payload.step,
        },
        loadingDarmCode: false,
        successDarmCode: true,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_CLEAR:
      return {
        ...state,
        loadingDarmCode: false,
        successDarmCode: null,
      };
    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_REQUEST:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          step: action.payload.step,
        },
      };

    case RepositoriesTypesCustomerObligation.CUSTOMER_OBLIGATION_METHOD_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
