import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

interface ITitlePros {
  disabled?: boolean;
}

export const Main = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 20px 0 24px;
  margin: 0;
  position: relative;
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 100vh;
    height: auto;
    overflow: scroll;
    padding-bottom: 28px;
    justify-content: flex-end;
    padding-right: 147px;
  }
`;

export const Title = styled.h1`
  font-weight: 550;
  font-size: 28px;
  color: ${Color.blueIntense};
`;

export const SubTitle = styled.p`
  font-size: 16px;
  color: ${Color.blueIntense};
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  width: 85%;
`;

export const ProfileButton = styled.div`
  width: 70%;
  box-shadow: 1px 5px 3px rgba(0, 0, 0, 0.3);
  border-radius: 9.76px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  margin-bottom: 15px;
  align-items: center;

  svg {
    margin-top: 0;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const ButtonTitles = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
`;

export const ButtonTitle = styled.h1<ITitlePros>`
  color: ${(props) => {
    return props.disabled ? Color.grayLight : '#37b4c8';
  }};
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const ButtonSubtitle = styled.h1`
  color: #c7c9c7;
  font-size: 12px;
  font-weight: 500;
`;

export const CancelButton = styled.p`
  color: #872d2d;
  text-decoration-line: underline;
  font-weight: 600;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
