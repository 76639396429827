import React, { FC } from 'react';
import { Button, TextButton } from './styles';

interface IBackButtonProps {
  label: string;
  onClick: () => void;
}

const BackButton: FC<IBackButtonProps> = ({ label, onClick }) => {
  return (
    <Button type="button" onClick={onClick}>
      <TextButton>{label}</TextButton>
    </Button>
  );
};

export default BackButton;
