import { Dispatch } from 'redux';
import {
  getEnrollmentsNegativeCertificatesService,
  getEnrollmentsNegativeCertificateDownloadService,
} from '~/services/enrollmentsNegativeCertificates';
import onShareFile from '~/utils/onShareFile';
import { IState } from '../types';
import {
  IResponseNegativeCertificateDownload,
  IResponseNegativeCertificates,
  RepositoriesTypesNegativeCertificatesHistory,
} from './types';

const getEnrollmentsNegativeCertificatesRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsNegativeCertificatesService(
        registration,
      )) as IResponseNegativeCertificates[];

      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsNegativeCertificateDownloadServiceRequest =
  (year: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = (await getEnrollmentsNegativeCertificateDownloadService(
        registration,
        year,
      )) as IResponseNegativeCertificateDownload;

      onShareFile({
        buffer: response?.buffer,
        fileName: 'certidao-negativa.pdf',
      });

      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

export {
  getEnrollmentsNegativeCertificatesRequest,
  getEnrollmentsNegativeCertificateDownloadServiceRequest,
};
