import styled from 'styled-components';
import { Color } from '~/styles';

interface IPropsRevision {
  isRevision?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  margin-bottom: 24px;

  :last-of-type {
    margin: 0;
  }
`;

export const ButtonInfo = styled.button<{ isActive: boolean; isRevision?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isActive }) => (isActive ? '0' : '40px')};
  padding: 0 32px 0 32px;
  background: ${({ isActive, isRevision }) =>
    isActive ? (isRevision ? Color.grayDark : Color.blueSeconde) : Color.white};
  color: ${({ isActive, isRevision }) =>
    isActive ? Color.white : isRevision ? Color.grayDark : Color.blueSeconde};
  border: 2px solid
    ${({ isRevision }) => (isRevision ? Color.grayDark : Color.blueSeconde)};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 32px;
    path {
      fill: ${({ isActive, isRevision }) =>
        isActive ? Color.white : isRevision ? Color.grayDark : Color.blueSeconde};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }

  :last-of-type {
    margin: 0;
  }
`;

export const ContainerGenerateCode = styled.div<IPropsRevision>`
  min-height: calc(202px - 52px);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 24px 32px;
  background: #f2f2f2;
  border: 2px solid
    ${({ isRevision }) => (isRevision ? Color.red : Color.blueSeconde)};
  border-radius: 8px;
`;

export const BoxGeneratedCode = styled.div`
  min-height: calc(70px - 24px);
  display: flex;
  margin-top: 24px;
  padding: 12px;
  border: 1px dashed #999999;
  border-radius: 8px;

  button {
    height: 48px;
    cursor: pointer;
    padding: 14px 16px;
    background: ${Color.green};
    border: 2px solid ${Color.green};
    border-radius: 8px;
    color: ${Color.white};
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 40px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: ${Color.gray};
    }

    p:first-of-type {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #000;
    }

    .code {
      color: #000000;
    }
  }
`;
