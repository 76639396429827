export default {
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  DISABLED_TWO_FACTOR_AUTH: false,
  DISABLED_TWO_FACTOR_AUTH_VERIFY_PHONE: false,
  HTTP_MODE: 'http://',
  URL_BASE_SALESFORCE: 'https://iguasaneamento--qas.my.salesforce.com/services',
  REACT_APP_MOCK: false,
  REACT_APP_LINK_PAYMENT: 'www.google.com.br',
  REACT_APP_EXPIRATION_DATE_NUMBER: 20,
  pixVerificationAttempts: 30,
  modules: {
    authSalesForce: false,
    subDropdown: true,
    invoiceDueDate: true,
    nagativeCertificate: true,
    consumptionHistory: true,
    rateDeliveryType: false,
    debitStatement: true,
    payment: true,
    ioT: false,
  },
  services: {
    mock: {
      salesForceAuthToken: true,
      salesForceQueryRegistrations: true,
      registrationNegativeCertificate: false,
      consumptionHistoryFind: false,
      paymentCardCredit: false,
    },
  },
  firestore: {
    LOCAL: {
      apiKey: 'AIzaSyAihUWfaRiaKgTWdbLfcqGzE5YI85wHsc8',
      authDomain: 'igua-hmg.firebaseapp.com',
      projectId: 'igua-hmg',
      storageBucket: 'igua-hmg.appspot.com',
      messagingSenderId: '1083414474896',
      appId: '1:1083414474896:web:ba1c31d916725e0342a815',
    },
    DEV: {
      apiKey: 'AIzaSyAihUWfaRiaKgTWdbLfcqGzE5YI85wHsc8',
      authDomain: 'igua-hmg.firebaseapp.com',
      projectId: 'igua-hmg',
      storageBucket: 'igua-hmg.appspot.com',
      messagingSenderId: '1083414474896',
      appId: '1:1083414474896:web:ba1c31d916725e0342a815',
    },
    HMG: {
      apiKey: 'AIzaSyAihUWfaRiaKgTWdbLfcqGzE5YI85wHsc8',
      authDomain: 'igua-hmg.firebaseapp.com',
      projectId: 'igua-hmg',
      storageBucket: 'igua-hmg.appspot.com',
      messagingSenderId: '1083414474896',
      appId: '1:1083414474896:web:ba1c31d916725e0342a815',
    },
    PRD: {
      apiKey: 'AIzaSyDf5gVlIAPktLwZ_mnlEr97Y69fo_1swNw',
      authDomain: 'igua-2cb99.firebaseapp.com',
      projectId: 'igua-2cb99',
      storageBucket: 'igua-2cb99.appspot.com',
      messagingSenderId: '318450918790',
      appId: '1:318450918790:web:bb58791fad9dcb612d7807',
      measurementId: 'G-CYQXJSCYVP',
    },
  },
  GOOGLE_RECAPTCHA: {
    LOCAL: '6LdrbBcqAAAAAItQU0mwTX2S_m35Tpc5qYIwch8E',
    DEV: '6LdrbBcqAAAAAItQU0mwTX2S_m35Tpc5qYIwch8E',
    HMG: '6LdrbBcqAAAAAItQU0mwTX2S_m35Tpc5qYIwch8E',
    PRD: '6LdrbBcqAAAAAItQU0mwTX2S_m35Tpc5qYIwch8E',
  },
  api: {
    LOCAL: {
      url: 'http://localhost:3525',
    },
    DEV: {
      url: 'https://api-dev-digiigua.igua.com.br/api',
    },
    HMG: {
      url: 'https://api-hml-digiigua.igua.com.br/api',
    },
    PRD: {
      url: 'https://api-digiigua.igua.com.br/api',
    },
  },
  LOCAL: {
    DISABLED_CAPTCHA: true,
    DISABLED_FACEBOOK: true,
    DISABLED_GMAIL: true,
  },
  DEV: {
    DISABLED_CAPTCHA: true,
    DISABLED_FACEBOOK: true,
    DISABLED_GMAIL: true,
  },
  HMG: {
    DISABLED_CAPTCHA: true,
    DISABLED_FACEBOOK: true,
    DISABLED_GMAIL: true,
  },
  PRD: {
    DISABLED_FACEBOOK: true,
    DISABLED_GMAIL: true,
  },
  GUEST_USERS: [
    '44244564047',
    '34673463838',
    '12016577096',
    '62516220081',
    '30087580020',
  ],
  GOOGLE_GEOCODING_API: {
    API_KEY: '',
  },
};
