export enum RepositoriesTypesEnrollmentsCards {
  CARDS_GET_METHOD_REQUEST = '@repositories/CARDS_GET_METHOD_REQUEST',
  CARDS_GET_METHOD_SUCCESS = '@repositories/CARDS_GET_METHOD_SUCCESS',
  CARDS_GET_METHOD_FAILURE = '@repositories/CARDS_GET_METHOD_FAILURE',
  CARDS_GET_METHOD_CLOSE = '@repositories/CARDS_GET_METHOD_CLOSE',

  CARDS_CREATE_POST_METHOD_REQUEST = '@repositories/CARDS_CREATE_POST_METHOD_REQUEST',
  CARDS_CREATE_POST_METHOD_SUCCESS = '@repositories/CARDS_CREATE_POST_METHOD_SUCCESS',
  CARDS_CREATE_POST_METHOD_SUCCESS_CLOSE = '@repositories/CARDS_CREATE_POST_METHOD_SUCCESS_CLOSE',
  CARDS_CREATE_POST_METHOD_FAILURE = '@repositories/CARDS_CREATE_POST_METHOD_FAILURE',
  CARDS_CREATE_POST_METHOD_FAILURE_CLOSE = '@repositories/CARDS_CREATE_POST_METHOD_CLOSE',

  CARDS_DELETE_METHOD_REQUEST = '@repositories/CARDS_DELETE_METHOD_REQUEST',
  CARDS_DELETE_METHOD_SUCCESS = '@repositories/CARDS_DELETE_METHOD_SUCCESS',
  CARDS_DELETE_METHOD_FAILURE = '@repositories/CARDS_DELETE_METHOD_FAILURE',
  CARDS_DELETE_METHOD_FAILURE_CLOSE = '@repositories/CARDS_DELETE_METHOD_FAILURE_CLOSE',

  CARDS_UPDATE_DEFAULT_PATCH_METHOD_REQUEST = '@repositories/CARDS_UPDATE_DEFAULT_PATCH_METHOD_REQUEST',
  CARDS_UPDATE_DEFAULT_PATCH_METHOD_SUCCESS = '@repositories/CARDS_UPDATE_DEFAULT_PATCH_METHOD_SUCCESS',
  CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE = '@repositories/CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE',
  CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE_CLOSE = '@repositories/CARDS_UPDATE_DEFAULT_PATCH_METHOD_CLOSE',
}

export interface IResponseGetCards {
  id: number;
  card_id_cielo_palestina: string;
  card_id_cielo_sanessol: string;
  card_id_cielo_castilho: string;
  card_id_cielo_andradina: string;
  card_id_cielo_piquete: string;
  card_id_cielo_paranagua: string;
  card_id_cielo_cuiaba: string;
  card_id_cielo_alta_floresta: string;
  card_id_cielo_canarana: string;
  card_id_cielo_colider: string;
  card_id_cielo_comodoro: string;
  card_id_cielo_pontes_lacerda: string;
  card_id_cielo_itapoa: string;
  card_id_cielo_paty_do_alferes: string;
  card_id_cielo_miguel_pereira: string;
  card_id_cielo_rio_de_janeiro: string;
  card_id_cielo_fluxx: string;
  card_number: string;
  brand: string;
  type: string;
  holder_name: string;
  expiration_date: string;
  is_default: boolean;
}

export interface IRequestCreateCard {
  card_number: string;
  holder_name: string;
  expiration_month: string;
  expiration_year: string;
}

export interface IRepositoriesStateEnrollmentsCards {
  readonly data: IResponseGetCards[] | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreateCard: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDeleteCard: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPatchCard: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingCreateCard: boolean;
  readonly loadingDeleteCard: boolean;
  readonly loadingPatchCard: boolean;
  readonly successCreateCard: boolean;
}
