/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable new-cap */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  RowInfo,
  ContainerShare,
  ContainerDetail,
} from './styles';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { DownloadIcon } from './icon';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import LoadingComponent from '~/components/Loading';
import {
  activateModalDataUpdate,
  activateModalSalesforceSatisfaction,
} from '~/store/modules/outsides/actions';
import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';

const SimplifiedInvoicePaymentsReceipt: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const invoiceReceipt = useSelector(
    (item: IState) => item.enrollmentsPayment.paymentVoucher,
  );
  const cnp = useSelector((item: IState) => item.enrollmentsUsers.data?.cnp);
  const loadingDetailInvoice = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDetail,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const printPDF = async () => {
    setLoading(true);
    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Baixar Comprovante',
    });
    setTimeout(async () => {
      const pdf = new jsPDF('portrait', 'pt', 'a4', true);
      const domElement = document.getElementById('download-receipt');
      if (domElement) {
        const data = await html2canvas(domElement);
        const img = data.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('comprovante.pdf');
      }

      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    dataRegistrationUpdate?.isUpdatable
      ? dispatch(activateModalDataUpdate())
      : dispatch(activateModalSalesforceSatisfaction());
  }, []);

  return (
    <Main>
      {(loading || loadingDetailInvoice) && (
        <LoadingComponent
          labelWait={loadingDetailInvoice ? 'Carregando...' : 'Baixando...'}
        />
      )}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Faturas',
              active: false,
              onClick: () => Navigation.navigate(RouteName.INVOICES),
            },
            {
              label: 'Fatura completa',
              active: false,
              onClick: () =>
                dispatch(
                  getEnrollmentsInvoicesDetailRequest(
                    invoiceReceipt?.invoice_id || 0,
                  ),
                ),
            },
            {
              label: 'Pagamento de fatura',
              active: true,
            },
            {
              label: 'Comprovante de pagamento',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card id="download-receipt">
        <h1>Comprovante de pagamento</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Sucesso!</h2>
          <Border />
        </ContainerBorder>
        <Margin height={14} />
        {!loading && (
          <>
            <ContainerShare>
              <button type="button" onClick={printPDF}>
                Baixar
                <DownloadIcon />
              </button>
            </ContainerShare>
            <Margin height={24} />
          </>
        )}
        <RowInfo>
          <p className="capitalize">
            Cliente: &ensp;{invoiceReceipt?.consumer.name.toLowerCase()}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>
            {cnp && cnp?.length > 11 ? 'CNPJ' : 'CPF'}: &ensp;
            {formatter.cpfCnpjMask(cnp || '')}
          </p>
        </RowInfo>
        <RowInfo>
          <p>Endereço: &ensp;{invoiceReceipt?.consumer?.address}</p>
        </RowInfo>
        <RowInfo backgroundWhite />
        <RowInfo>
          <p>Valor total:</p>
          <p className="text-end">
            {formatter.formatCurrency(invoiceReceipt?.amount)}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>Tipo/bandeira: Crédito - {invoiceReceipt?.brand}</p>
        </RowInfo>
        <RowInfo>
          <p>
            Pagamento: &ensp;
            {moment(invoiceReceipt?.received_date).format('DD/MM/YYYY HH:mm')}
          </p>
        </RowInfo>
        <RowInfo backgroundWhite>
          <p>Autenticação de pagamento: {invoiceReceipt?.payment_id}</p>
        </RowInfo>
        <RowInfo backgroundWhite />
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Detalhes</h2>
          <Border />
        </ContainerBorder>
        {invoiceReceipt && invoiceReceipt?.items.length > 0 && (
          <ContainerDetail>
            <RowInfo>
              <p>Referência: &ensp;{invoiceReceipt?.reference}</p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>Fatura: &ensp;{invoiceReceipt?.invoice_id}</p>
            </RowInfo>
            <RowInfo>
              <p>
                Vencimento: &ensp;
                {moment(invoiceReceipt.maturity_date).format('DD/MM/YYYY')}
              </p>
            </RowInfo>
            <RowInfo backgroundWhite>
              <p>
                Emissão: &ensp;
                {moment(invoiceReceipt?.issue_date_hour).format('DD/MM/YYYY HH:mm')}
              </p>
            </RowInfo>
            {invoiceReceipt.items?.map((item, index) => (
              <RowInfo key={item.description}>
                <p>
                  {index === 0 ? (
                    'Descrição:  '
                  ) : (
                    <>
                      {!isMobile && (
                        <>
                          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                        </>
                      )}
                    </>
                  )}{' '}
                  {item.description.toLowerCase()}
                </p>
                <p className="text-end">{formatter.formatCurrency(item.amount)}</p>
              </RowInfo>
            ))}
            <RowInfo backgroundWhite>
              <p>Valor:</p>
              <p>{formatter.formatCurrency(invoiceReceipt?.amount)}</p>
            </RowInfo>
            <RowInfo backgroundWhite />
          </ContainerDetail>
        )}
        {!loading && (
          <ContainerButtons>
            <ButtonText
              text="Ir para home"
              typeBtn="content"
              onClick={() => Navigation.navigate(RouteName.HOME)}
            />
          </ContainerButtons>
        )}
      </Card>
    </Main>
  );
};

export default SimplifiedInvoicePaymentsReceipt;
