export enum RepositoriesTypesDpaDpe {
  DPADPE_MENAGER_DATA_SET_SELECTED_FEATURE = '@repositories/DPADPE_MENAGER_DATA_SELECTED_SET_FEATURE',

  DPADPE_MENAGER_DATA_SET_VALUES_OBJECT = '@repositories/DPADPE_MENAGER_DATA_SET_VALUES_OBJECT',

  DPADPE_MENAGER_DATA_SET_CASE_VALUES = '@repositories/DPADPE_MENAGER_DATA_SET_CASE_VALUES',

  DPADPE_MENAGER_DATA_SET_TRIEND_SEND = '@repositories/DPADPE_MENAGER_DATA_SET_TRIEND_SEND',
}

export interface IResponseCharacterCase {
  name?: string;
  characteristic_type?: string;
  lots_quantity?: string;
  lot_area?: string;
  number_unities_by_lot?: string;
  number_unities_by_building?: string;
  number_stores?: string;
  number_commercial_rooms?: string;
  quantity_edifications?: string;
  built_area?: string;
  number_rooms_unity?: string;
  dependecies?: string;
  quantity_apartments_unity?: string;
  type?: string;
  quantity?: string;
  number_rooms?: string;
  quantity_apartments?: string;
  business_structure?: string;
  industrial_process_predicted_discharge?: string;
  industrial_process_predicted_consume?: string;
  number_employees_day?: string;
  business_structure_enterprise?: string;
  number_consumers_day?: string;
}

export interface IResponseListCases {
  id: string;
  owner: string;
  email: string;
  cpf_cnpj: string;
  cep: string;
  street: string;
  neighborhood: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  qualification?: string | null;
  phone: string;
  status: string;
  case_number: string;
  created_at: string;
  characteristics?: IResponseCharacterCase[] | [];
}

export interface ICaseValues {
  characteristic_type?: string;
  owner?: string;
  email?: string;
  cpf_cnpj?: string;
  cep?: string;
  street?: string;
  neighborhood?: string;
  number?: string;
  complement?: string;
  city?: string;
  state?: string;
  qualification?: string;
  phone?: string;
  name?: string;
  address?: string;
  interestedAddress?: string;
  interestedEmail?: string;
  identification_document_file?: any;
  location_plan_file?: any;
  situation_plan_file?: any;
}

export interface ICharacter {
  name: string;
  characteristic_type: string;
  lots_quantity: string;
  lot_area: string;
  number_unities_by_lot: string;
  number_unities_by_building: string;
  number_stores: string;
  number_commercial_rooms: string;
  quantity_edifications: string;
  built_area: string;
  number_rooms_unity: string;
  dependecies: string;
  quantity_apartments_unity: string;
  type: string;
  quantity: string;
  number_rooms: string;
  quantity_apartments: string;
  business_structure: string;
  industrial_process_predicted_discharge: string;
  industrial_process_predicted_consume: string;
  number_employees_day: string;
  business_structure_enterprise: string;
  number_consumers_day: string;
}

export interface IRequestRegisterCharacter {
  dna: string;
  characters: ICharacter[];
}

export interface IFieldObject {
  key: string;
  label: string;
  type?: string;
  value: string;
  values?: Array<any>;
  maxLength?: number;
}

export interface ISelectedCharacters {
  key: string;
  label: string;
  type: string;
  fields: IFieldAgrgregatort[] | IFieldObject[];
  maxLength?: number;
  values?: Array<any>;
  comments?: string;
  subComments?: string;
  listComments?: string[];
}

export interface IFieldAgrgregatort {
  key: string;
  label: string;
  type: string;
  value: string;
  fields: IFieldObject[][];
}

export interface ICharacterFilds {
  key: string;
  label: string;
  type: string;
  fields: any;
}

export interface IDataTriendSend {
  setAlreadyTriedSend: boolean;
}

export interface IRepositoriesStateDpaDpeMenager {
  readonly caseValues?: ICaseValues;
  readonly selectedFeature?: ISelectedCharacters;
  readonly ValuesObject?: IFieldObject[];
  readonly type?: string;
  readonly alreadyTriedSend?: boolean;
}
