import moment from 'moment';
import React, { FC, HTMLAttributes, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ButtonLink, ContainerCard } from '~/common';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { getStatusDetail } from '~/utils/ownership';
import { DownloadButton, OrderStatusSection } from '../Common';
import {
  Container,
  DateBox,
  Detail,
  Info,
  Message,
  MyProtocol,
  Wrapper,
} from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

const OwnershipProtocol: FC<IProps> = ({ ...props }) => {
  const printRef = useRef(null);
  const order = useSelector((item: IState) => item.ownership.detail.data);

  return (
    <MyProtocol {...props}>
      <ContainerCard title="Troca de Titularidade">
        <Container>
          <Wrapper ref={printRef}>
            <Message>{getStatusDetail(order?.status || '').message}</Message>
            <Detail>
              <p>O número do seu protocolo é:</p>
              <span>{order?.caseNumber ?? ''}</span>
            </Detail>
            <OrderStatusSection status={order?.status ?? ''} />
            <Info>
              <DateBox>
                <p>Data de solicitação</p>
                <span>{moment(order?.createdDate).format('DD/MM/YYYY')}</span>
              </DateBox>

              {order?.closedDate && order?.closedDate.length > 0 && (
                <DateBox style={{ marginLeft: 30 }}>
                  <p>{getStatusDetail(order?.status || '').msgButton}</p>
                  <span>{moment(order?.closedDate).format('DD/MM/YYYY')}</span>
                </DateBox>
              )}
            </Info>
          </Wrapper>
          <DownloadButton reference={printRef}>Baixar protocolo</DownloadButton>
          <ButtonLink color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </Container>
      </ContainerCard>
    </MyProtocol>
  );
};

export default OwnershipProtocol;
