import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';
import { COLORS } from '~/utils/constants';

const variants = {
  warning: css`
    --bg-color: ${COLORS.warning};
    --text-color: ${COLORS.warning};
    --border-color: ${COLORS.warning};
  `,
  loading: css`
    --bg-color: ${COLORS.highlight};
    --text-color: ${COLORS.highlight};
    --border-color: ${COLORS.highlight};
  `,
  success: css`
    --bg-color: ${COLORS.success};
    --text-color: ${COLORS.success};
    --border-color: ${COLORS.success};
  `,
  info: css`
    --bg-color: ${COLORS.secondary};
    --text-color: ${COLORS.secondary};
    --border-color: ${COLORS.secondary};
  `,
};

export const MyMessageBox = styled.div<{
  variant?: 'warning' | 'loading' | 'success' | 'info';
}>`
  height: calc(136px - 48px);
  padding: 24px 23px;
  display: flex;
  align-items: center;
  gap: 24px;
  background: ${COLORS.gray200};
  border: 2px solid var(--border-color);
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 122.5%;
  letter-spacing: -0.025em;
  ${({ variant }) => (variant ? variants[variant] : variants.warning)};

  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    max-width: calc(300px - 50px);
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: calc(104px - 48px);
  }
`;

export const Icon = styled.div`
  flex: 0 0 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-color);
  color: ${COLORS.white};
  border-radius: 8px;

  svg {
    flex: 0 0 20px;
    height: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: inherit;
`;

export const Title = styled.p`
  color: var(--text-color);
  font-size: inherit;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: inherit;
  font-weight: 500;
  a {
    display: inline;
    color: ${COLORS.highlight};
    font-weight: 700;
  }
`;
