import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface IPropsCard {
  isBorder?: boolean;
  height?: number;
  hiddenPadding?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 37px 10px 68px 10px;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 47px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
    padding: 47px 0 47px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 0 24px 0;
  /* display: none; */

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const ContainerAlert = styled.div`
  @media (min-width: 1340px) {
    display: none;
  }
`;

export const ContainerAlertLarge = styled.div`
  position: relative;
  width: 270px;
  height: 448px;
  position: absolute;
  right: 0;
  top: 88px;
  display: none;

  @media (min-width: 1340px) {
    display: inherit;
  }
`;

export const Card = styled.section<IPropsCard>`
  width: 100%;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  margin: 0 auto;
  border-top: 25px solid ${Color.blueIntense};
  border-radius: 24px 24px 0px 0px;
  padding-bottom: 40px;

  h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    color: ${Color.blueIntense};
    margin-top: 24px;
  }

  p {
    color: ${Color.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 870px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    margin: 0;
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.blueIntense};
    text-align: left;
    background: ${Color.white};
    padding: 0 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    h2 {
      position: absolute;
      left: 20px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    h2 {
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const Content = styled.div`
  padding: 0 8px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 40px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    padding: 0 73px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  display: none;

  div {
    flex-basis: 5%;
    height: 100%;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    flex-basis: 35%;

    &.small {
      flex-basis: 30%;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: flex;
  }
`;
