import React from 'react';

function BarcodeSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="31"
      viewBox="0 0 39 31"
      fill="none"
    >
      <path
        d="M2.0842 0C1.749 0 1.42752 0.13316 1.1905 0.370185C0.953472 0.60721 0.820313 0.928685 0.820312 1.26389V29.0694C0.820312 29.4046 0.953472 29.7261 1.1905 29.9632C1.42752 30.2002 1.749 30.3333 2.0842 30.3333C2.41941 30.3333 2.74088 30.2002 2.97791 29.9632C3.21493 29.7261 3.34809 29.4046 3.34809 29.0694V1.26389C3.34809 0.928685 3.21493 0.60721 2.97791 0.370185C2.74088 0.13316 2.41941 0 2.0842 0Z"
        fill="#004B55"
      />
      <path
        d="M7.13889 0C6.80368 0 6.48221 0.13316 6.24518 0.370185C6.00816 0.60721 5.875 0.928685 5.875 1.26389V24.0139C5.875 24.3491 6.00816 24.6706 6.24518 24.9076C6.48221 25.1446 6.80368 25.2778 7.13889 25.2778C7.47409 25.2778 7.79557 25.1446 8.03259 24.9076C8.26962 24.6706 8.40278 24.3491 8.40278 24.0139V1.26389C8.40278 0.928685 8.26962 0.60721 8.03259 0.370185C7.79557 0.13316 7.47409 0 7.13889 0Z"
        fill="#004B55"
      />
      <path
        d="M12.1936 0C11.8584 0 11.5369 0.13316 11.2999 0.370185C11.0628 0.60721 10.9297 0.928685 10.9297 1.26389V24.0139C10.9297 24.3491 11.0628 24.6706 11.2999 24.9076C11.5369 25.1446 11.8584 25.2778 12.1936 25.2778C12.5288 25.2778 12.8503 25.1446 13.0873 24.9076C13.3243 24.6706 13.4575 24.3491 13.4575 24.0139V1.26389C13.4575 0.928685 13.3243 0.60721 13.0873 0.370185C12.8503 0.13316 12.5288 0 12.1936 0Z"
        fill="#004B55"
      />
      <path
        d="M17.2502 0C16.915 0 16.5935 0.13316 16.3565 0.370185C16.1195 0.60721 15.9863 0.928685 15.9863 1.26389V24.0139C15.9863 24.3491 16.1195 24.6706 16.3565 24.9076C16.5935 25.1446 16.915 25.2778 17.2502 25.2778C17.5854 25.2778 17.9069 25.1446 18.1439 24.9076C18.3809 24.6706 18.5141 24.3491 18.5141 24.0139V1.26389C18.5141 0.928685 18.3809 0.60721 18.1439 0.370185C17.9069 0.13316 17.5854 0 17.2502 0Z"
        fill="#004B55"
      />
      <path
        d="M22.3088 0C21.9736 0 21.6521 0.13316 21.4151 0.370185C21.1781 0.60721 21.0449 0.928685 21.0449 1.26389V24.0139C21.0449 24.3491 21.1781 24.6706 21.4151 24.9076C21.6521 25.1446 21.9736 25.2778 22.3088 25.2778C22.644 25.2778 22.9655 25.1446 23.2025 24.9076C23.4395 24.6706 23.5727 24.3491 23.5727 24.0139V1.26389C23.5727 0.928685 23.4395 0.60721 23.2025 0.370185C22.9655 0.13316 22.644 0 22.3088 0Z"
        fill="#004B55"
      />
      <path
        d="M27.3635 0C27.0283 0 26.7068 0.13316 26.4698 0.370185C26.2328 0.60721 26.0996 0.928685 26.0996 1.26389V24.0139C26.0996 24.3491 26.2328 24.6706 26.4698 24.9076C26.7068 25.1446 27.0283 25.2778 27.3635 25.2778C27.6987 25.2778 28.0202 25.1446 28.2572 24.9076C28.4942 24.6706 28.6274 24.3491 28.6274 24.0139V1.26389C28.6274 0.928685 28.4942 0.60721 28.2572 0.370185C28.0202 0.13316 27.6987 0 27.3635 0Z"
        fill="#004B55"
      />
      <path
        d="M32.4182 0C32.083 0 31.7615 0.13316 31.5245 0.370185C31.2875 0.60721 31.1543 0.928685 31.1543 1.26389V24.0139C31.1543 24.3491 31.2875 24.6706 31.5245 24.9076C31.7615 25.1446 32.083 25.2778 32.4182 25.2778C32.7534 25.2778 33.0749 25.1446 33.3119 24.9076C33.5489 24.6706 33.6821 24.3491 33.6821 24.0139V1.26389C33.6821 0.928685 33.5489 0.60721 33.3119 0.370185C33.0749 0.13316 32.7534 0 32.4182 0Z"
        fill="#004B55"
      />
      <path
        d="M37.4748 0C37.1396 0 36.8181 0.13316 36.5811 0.370185C36.3441 0.60721 36.2109 0.928685 36.2109 1.26389V29.0694C36.2109 29.4047 36.3441 29.7261 36.5811 29.9632C36.8181 30.2002 37.1396 30.3333 37.4748 30.3333C37.81 30.3333 38.1315 30.2002 38.3685 29.9632C38.6056 29.7261 38.7387 29.4047 38.7387 29.0694V1.26389C38.7387 0.928685 38.6056 0.60721 38.3685 0.370185C38.1315 0.13316 37.81 0 37.4748 0Z"
        fill="#004B55"
      />
    </svg>
  );
}

export default BarcodeSVG;
