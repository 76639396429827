import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  position: relative;
  padding: 40px 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 40px 24px;
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;

export const ListWrap = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: flex-start;
  @media (max-width: ${Breakpoints.tablet.max}px) {
    order: 2;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
`;

export const ErrorMsg = styled.div`
  margin-bottom: 24px;
  max-width: 400px;
  color: ${Color.gray};
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
`;
