/* eslint-disable prefer-destructuring */
import React, { FC, useEffect, useState } from 'react';
import creditCardType from 'credit-card-type';
import { useMediaQuery } from 'react-responsive';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import { Breakpoints, Color } from '~/styles';
import formatter from '~/utils/formatter';
import CardTemplate from '../SimplifiedInvoice/CreditCard/CardTemplate';
import paymentMethodsMobileImg from './img/payment-methods-mobile.png';
import paymentMethodsImg from './img/payment-methods.png';

import {
  ContainerBody,
  ContainerButtons,
  ContainerCard,
  ContainerCardTemplate,
  ContainerFormCard,
  ContainerInputs,
  ContainerPaymentMethods,
  ContainerWallet,
  Header,
  Main,
  Separator,
  TitleCard,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  postEnrollmentsCreateCardRequest,
  postErrorCloseEnrollmentsCreateCardRequest,
  postSuccessCloseEnrollmentsCreateCardRequest,
} from '~/store/modules/enrollmentsCards/actions';
import Modal from '~/components/Modal';
import ModalAddCard from './Modal';

interface Card {
  number?: string;
  cvc?: string;
  validity?: string;
  brand?: string;
  name?: string;
}

const WalletAddCard: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const dispatch = useDispatch();

  const loading = useSelector(
    (state: IState) => state.enrollmentsCards.loadingCreateCard,
  );
  const successCreateCard = useSelector(
    (item: IState) => item.enrollmentsCards.successCreateCard,
  );
  const error = useSelector((item: IState) => item.enrollmentsCards.errorCreateCard);

  const [card, setCard] = useState<Card>({
    number: '',
    cvc: '',
    validity: '',
    brand: '',
    name: '',
  });
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },

    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },

    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  useEffect(() => {
    dispatch(postSuccessCloseEnrollmentsCreateCardRequest());
    dispatch(postErrorCloseEnrollmentsCreateCardRequest());
  }, []);

  useEffect(() => {
    if (card.number && card.cvc && card.validity && card.brand && card.name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [card]);

  useEffect(() => {
    if (successCreateCard || (error && error?.state)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [successCreateCard, error]);

  const findBrand = () => {
    let brandType = creditCardType((card.number || '').replace(/\D/g, '')) as any;

    if (brandType.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand.issuer === brandType?.type,
    );

    if (brand?.brand) setCard({ ...card, brand: brand.issuer });
  };

  useEffect(() => {
    findBrand();
  }, [card.number]);

  const createCard = () => {
    dispatch(
      postEnrollmentsCreateCardRequest({
        card_number: card.number?.replaceAll(' ', '') || '',
        holder_name: card.name || '',
        expiration_month: card.validity?.substring(0, 2) || '',
        expiration_year: card.validity?.substring(3, card?.validity?.length) || '',
      }),
    );
  };

  return (
    <>
      <Modal
        open={open}
        type={successCreateCard ? 'success' : 'error'}
        title={successCreateCard ? 'Sucesso!' : 'Ops!'}
      >
        <ModalAddCard />
      </Modal>
      <Main>
        <ContainerWallet>
          <Header>Carteira</Header>
          <ContainerBody>
            {!isMobile && <TitleCard>Adicionar forma de pagamento</TitleCard>}
            <ContainerPaymentMethods>
              <p>Cartões aceitos:</p>
              <img
                src={isMobile ? paymentMethodsMobileImg : paymentMethodsImg}
                alt="métodos de pagamento"
              />
            </ContainerPaymentMethods>
            {isMobile && <Separator />}
            <ContainerCard>
              <ContainerCardTemplate>
                <CardTemplate
                  holder={card.name}
                  cardNumber={card.number}
                  securityCode={card.cvc}
                  validity={card.validity}
                  issuer={card.brand}
                />
              </ContainerCardTemplate>
            </ContainerCard>
            {isMobile && <Separator />}
            <ContainerFormCard>
              <div
                style={{
                  padding: '18px 11px 0 16px',
                }}
              >
                <Input
                  value={card.number}
                  name="cardNumber"
                  onChange={(ev) =>
                    setCard({
                      ...card,
                      number: formatter.creditCard(ev.target.value) || '',
                    })
                  }
                  label="Número do Cartão"
                  maxLength={19}
                  payment
                />
                <Margin height={isMobile ? 7 : 10} />
                <Input
                  value={card.name}
                  name="cardName"
                  onChange={(ev) =>
                    setCard({ ...card, name: ev.target.value || '' })
                  }
                  label="Nome do Titular"
                  payment
                />
                <Margin height={isMobile ? 7 : 10} />
                {isMobile ? (
                  <>
                    <Input
                      value={card.validity || ''}
                      name="cardValidity"
                      onChange={(ev) => {
                        const clean = String(ev?.target?.value)
                          .substring(0, 7)
                          .replace(/\D$/g, '')
                          .replace(/(\d{2})/, '$1')
                          .replace(/(\d{2})(\d{4})/, '$1/$2');
                        setCard({ ...card, validity: clean || '' });
                      }}
                      label="Validade"
                      placeholder="mm/aaaa"
                      payment
                    />
                    <Margin height={isMobile ? 7 : 10} />
                    <Input
                      value={card.cvc}
                      name="cardCvc"
                      onChange={(ev) =>
                        setCard({
                          ...card,
                          cvc: formatter.formatarNumber(ev.target.value) || '',
                        })
                      }
                      label="Cod. de Segurança"
                      placeholder="000"
                      type={!openPassword ? 'password' : 'text'}
                      iconPassword
                      passwordVisible={openPassword}
                      setPasswordVisible={setOpenPassword}
                      maxLength={3}
                      payment
                    />
                  </>
                ) : (
                  <ContainerInputs>
                    <Input
                      value={card.validity || ''}
                      name="cardValidity"
                      onChange={(ev) => {
                        const clean = String(ev?.target?.value)
                          .substring(0, 7)
                          .replace(/\D$/g, '')
                          .replace(/(\d{2})/, '$1')
                          .replace(/(\d{2})(\d{4})/, '$1/$2');
                        setCard({ ...card, validity: clean || '' });
                      }}
                      label="Validade"
                      placeholder="mm/aaaa"
                      payment
                    />
                    <Margin width={23} />
                    <Input
                      value={card.cvc}
                      name="cardCvc"
                      onChange={(ev) =>
                        setCard({
                          ...card,
                          cvc: formatter.formatarNumber(ev.target.value) || '',
                        })
                      }
                      label="Cod. de Segurança"
                      placeholder="000"
                      type={!openPassword ? 'password' : 'text'}
                      iconPassword
                      passwordVisible={openPassword}
                      setPasswordVisible={setOpenPassword}
                      maxLength={3}
                      payment
                    />
                  </ContainerInputs>
                )}
                <Margin height={isMobile ? 82 : 41} />
                <ContainerButtons>
                  <ButtonText
                    text="Adicionar cartão"
                    typeBtn="content"
                    Color={Color.white}
                    backgroundColor={disabled ? Color.gray : Color.green}
                    onClick={() => {
                      if (disabled) return;
                      createCard();
                    }}
                    loading={loading}
                    style={{
                      height: isMobile ? '32px' : '59px',
                      borderRadius: '16px',
                    }}
                  />
                  <Margin height={isMobile ? 19 : 21} />
                  <ButtonText
                    text="Cancelar"
                    typeBtn="outlined"
                    Color={Color.red}
                    onClick={() => Navigation.navigate(RouteName.WALLET)}
                    style={{ backgroundColor: 'transparent' }}
                  />
                  <Margin height={15} />
                </ContainerButtons>
              </div>
            </ContainerFormCard>
          </ContainerBody>
        </ContainerWallet>
      </Main>
    </>
  );
};

export default WalletAddCard;
