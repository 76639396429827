import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import DownloadSVG from '~/assets/Icons/DownloadIcon';
import { getEnrollmentsNegativeCertificateDownloadServiceRequest } from '~/store/modules/enrollmentsNegativeCertificates/actions';
import { IResponseNegativeCertificates } from '~/store/modules/enrollmentsNegativeCertificates/types';

import * as S from './styles';

const Row = ({
  issue_date,
  issue_ref,
  printed,
  year,
}: IResponseNegativeCertificates) => {
  const dispatch = useDispatch();

  return (
    <S.TableRow>
      <S.TableCell>
        <S.TableCellText>{year}</S.TableCellText>
      </S.TableCell>
      <S.TableCell>
        <S.TableCellText>{issue_ref}</S.TableCellText>
      </S.TableCell>
      <S.TableCell>
        <S.TableCellText>{moment(issue_date).format('DD/MM/YYYY')}</S.TableCellText>
      </S.TableCell>
      <S.TableCell>
        <S.TableCellText>{printed ? 'Sim' : 'Não'}</S.TableCellText>
      </S.TableCell>
      <S.TableCell>
        <S.TableCellButton
          onClick={() =>
            dispatch(getEnrollmentsNegativeCertificateDownloadServiceRequest(year))
          }
        >
          <DownloadSVG />
        </S.TableCellButton>
      </S.TableCell>
      <S.TableCellApp>
        <S.TableCellTextApp>
          {'Ano de Quitação : '}
          <S.TableCellText>{year}</S.TableCellText>
        </S.TableCellTextApp>

        <S.TableCellTextApp>
          {'Referência Emissão : '}
          <S.TableCellText>{issue_ref}</S.TableCellText>
        </S.TableCellTextApp>

        <S.TableCellTextApp>
          {'Data Emissão : '}
          <S.TableCellText>
            {moment(issue_date).format('DD/MM/YYYY')}
          </S.TableCellText>
        </S.TableCellTextApp>

        <S.TableCellTextApp>
          {'Impresso : '}
          <S.TableCellText>{printed ? 'Sim' : 'Não'}</S.TableCellText>
        </S.TableCellTextApp>
      </S.TableCellApp>
      <S.TableCellApp>
        <S.TableCellButton
          onClick={() =>
            dispatch(getEnrollmentsNegativeCertificateDownloadServiceRequest(year))
          }
        >
          <DownloadSVG />
        </S.TableCellButton>
      </S.TableCellApp>
    </S.TableRow>
  );
};

export default Row;
