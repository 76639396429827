import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';

import { Button, Container, Row } from './styled';
import { Text } from '~/components/TextInfo';
import { Margin } from '~/components/Margin';
import { Separator } from '../../styles';
import { Input } from '~/components/Input';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Breakpoints, Color } from '~/styles';
import { updateEnrollmentsUsersRequest } from '~/store/modules/enrollmentsUsers/actions';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import SuccesBox from '~/components/SuccesBox';
import { RepositoriesTypesUsers } from '~/store/modules/enrollmentsUsers/types';

interface IProps {
  email?: string;
  mobile?: string;
  mobileSecondary?: string;
  phone?: string;
  phoneSecondary?: string;
}

const Contact: React.FC = () => {
  const isLaptop = useMediaQuery({
    minWidth: Breakpoints.laptop.min,
  });

  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );
  const dna = useSelector((item: IState) => item.enrollmentsAuth.token);
  const password = useSelector((item: IState) => item.enrollmentsAuth.password);
  const loading = useSelector((item: IState) => item.enrollmentsUsers.loadingUpdate);
  const succes = useSelector((item: IState) => item.enrollmentsUsers.successUpdate);

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );

  const dispatch = useDispatch();

  const cleanPhone = (text?: string) => {
    return formatter.formatarPhone(
      text?.replace('(', '').replace(')', '').replace(' ', ''),
    );
  };

  const [form, setForm] = useState<IProps>();
  const [phoneError, setPhoneError] = useState<string>('');
  const [phoneAddError, addPhoneAddError] = useState<string>('');
  const [telError, setTelError] = useState<string>('');

  useEffect(() => {
    setForm({
      email: enrollmentsUserInfo?.email,
      mobile: cleanPhone(enrollmentsUserInfo?.mobile.primary),
      mobileSecondary: cleanPhone(enrollmentsUserInfo?.mobile.secondary),
      phone: cleanPhone(enrollmentsUserInfo?.phone.primary),
      phoneSecondary: cleanPhone(enrollmentsUserInfo?.phone.secondary),
    });
  }, [enrollmentsUserInfo]);

  useEffect(() => {
    dispatch({
      type: RepositoriesTypesUsers.CLEAN,
    });
  }, []);

  const [errorEmail, setErrorEmal] = useState(false);

  const send = () => {
    if (form?.email && form.mobile) {
      const dataSend = {
        phone: {
          primary: form?.phone || '',
          secondary: form?.mobileSecondary || ' ',
        },
        mobile: {
          primary: form.mobile,
          secondary: form.mobileSecondary || ' ',
        },
        registration: enrollment,
        email: form.email,
        authData: enrollmentsUserInfo?.cnp.toString().replace(/[^0-9]/g, ''),
        dna,
        password,
      };

      dispatch(updateEnrollmentsUsersRequest(dataSend));
    }
  };

  const validatePhone = () => {
    if (!form?.mobile) return setPhoneError('');

    if (form?.mobile.length < 15) {
      return setPhoneError('Celular inválido');
    }
    return setPhoneError('');
  };

  const validatePhoneAdd = () => {
    if (!form?.mobileSecondary) return addPhoneAddError('');

    if (form?.mobileSecondary.length < 15) {
      return addPhoneAddError('Celular inválido');
    }
    return addPhoneAddError('');
  };

  const validateTel = () => {
    if (!form?.phone) return setTelError('');

    if (form?.phone.length < 14) {
      return setTelError('Telefone inválido');
    }
    return setTelError('');
  };

  console.log(
    { form },
    form?.email &&
      form.mobile &&
      !errorEmail &&
      !phoneError &&
      !phoneAddError &&
      !telError,
  );

  useEffect(() => {
    if (succes) {
      ReactGA.event({
        category: 'Perfil',
        action: 'Sucesso na Solicitação de Atualização Cadastral',
      });
    }
  }, [succes]);

  return (
    <Container>
      <Text color="#004B55" size={28} weight={400} fontFamily="Montserrat-Regular">
        Contato
      </Text>
      <Margin height={34} />
      <Separator />
      <Margin height={24} />
      {succes && (
        <div style={{ flex: 1, width: '100%' }}>
          <SuccesBox label="Dados atualizados!" />
        </div>
      )}
      <Margin height={24} />
      <Row>
        <Input
          isNewHeigthLabelInput
          label="Email*"
          errorPosition="bottom"
          errorText="Email inválido"
          error={errorEmail}
          value={form?.email || ''}
          style={{ borderColor: errorEmail ? Color.red : '#C7C9C7' }}
          labelErroWeigth={400}
          marginLabelError={4}
          onChange={(e) => {
            if (formatter.rejexEmail(e.target.value)) {
              setErrorEmal(false);
              setForm({
                ...form,
                email: e.target.value,
              });
            } else {
              setErrorEmal(true);
              setForm({
                ...form,
                email: e.target.value,
              });
            }
          }}
        />
        <Margin
          width={isLaptop ? 40 : undefined}
          height={isLaptop ? undefined : 40}
        />

        <Input
          isNewHeigthLabelInput
          label="Celular*"
          value={form?.mobile}
          style={{ borderColor: phoneError ? Color.red : '#C7C9C7' }}
          labelErroWeigth={400}
          marginLabelError={4}
          onChange={(e) => {
            setForm({
              ...form,
              mobile: formatter.formatarPhone(e.target.value),
            });
          }}
          error={!!phoneError}
          errorText={phoneError}
          errorPosition="bottom"
          onBlur={validatePhone}
        />
      </Row>

      <Margin height={30} />

      <Row>
        <Input
          isNewHeigthLabelInput
          label="Celular adicional"
          style={{ borderColor: phoneAddError ? Color.red : '#C7C9C7' }}
          labelErroWeigth={400}
          marginLabelError={4}
          value={form?.mobileSecondary}
          onChange={(e) => {
            setForm({
              ...form,
              mobileSecondary: formatter.formatarPhone(e.target.value),
            });
          }}
          error={!!phoneAddError}
          errorText={phoneAddError}
          errorPosition="bottom"
          onBlur={validatePhoneAdd}
        />
        <Margin
          width={isLaptop ? 40 : undefined}
          height={isLaptop ? undefined : 40}
        />
        <Input
          isNewHeigthLabelInput
          label="Telefone"
          value={form?.phone || ''}
          style={{ borderColor: telError ? Color.red : '#C7C9C7' }}
          labelErroWeigth={400}
          marginLabelError={4}
          onChange={(e) => {
            setForm({
              ...form,
              phone: formatter.formatarTel(e.target.value),
            });
          }}
          error={!!telError}
          errorText={telError}
          errorPosition="bottom"
          onBlur={validateTel}
        />
      </Row>

      <Margin height={34} />
      <Separator />
      <Margin height={24} />
      <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
        {form?.email &&
        form.mobile &&
        !errorEmail &&
        !phoneError &&
        !phoneAddError &&
        !telError ? (
          <Button onClick={() => send()}>
            {loading ? (
              <ClipLoader size={12} color="#37b4c8" />
            ) : (
              'Salvar atualizações'
            )}
          </Button>
        ) : (
          <Button
            style={{ backgroundColor: Color.white, cursor: 'default' }}
            disabled
          >
            Salvar atualizações
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Contact;
