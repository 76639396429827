import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 90vh;
  height: 100%;
  max-width: 90vw;
  padding: 32px 0;
  background: ${Color.white};
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    border-radius: 16px;
    max-width: calc(732px - 62px);
    min-height: calc(440px - 64px);
  }

  img,
  embed {
    width: 100%;
    height: calc(100% - 130px);
    object-fit: contain;
  }

  embed {
    height: 500px;
  }
`;

export const CloseIconButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 32px;
  right: 15px;
  cursor: pointer;
  border: 2px solid ${Color.grayLightSeconde};
  border-radius: 50px;
  z-index: 9999;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    right: 30px;
  }
`;

export const Separator = styled.div`
  border: 1px solid #c7c9c7;
  margin-top: 62px;
`;
