import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const LocationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

export const LocationFormGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  align-items: flex-start;
  .large {
    grid-column: span 3;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    grid-template-columns: repeat(2, 1fr);
    .large {
      grid-column: span 2;
    }
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    grid-template-columns: repeat(1, 1fr);
    .large {
      grid-column: span 1;
    }
  }
`;

export const FieldWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  .tag {
    margin-top: 5px;
  }
`;
