import React from 'react';

function DefaultCardSVG() {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9985 0C10.7885 0 11.4268 0.642383 11.4268 1.4375V2.15625H12.1409C12.9309 2.15625 13.5691 2.79863 13.5691 3.59375C13.5691 4.38887 12.9309 5.03125 12.1409 5.03125H11.4268V7.1875H18.5681C19.0278 7.1875 19.4563 7.40762 19.7285 7.78496C20.0008 8.1623 20.0722 8.64297 19.9249 9.07871L16.7426 18.6875H3.25443L0.0720884 9.07871C-0.0707376 8.64297 0.000675408 8.15781 0.268474 7.78496C0.536273 7.41211 0.969214 7.1875 1.42894 7.1875H8.57024V5.03125H7.85611C7.0661 5.03125 6.42785 4.38887 6.42785 3.59375C6.42785 2.79863 7.0661 2.15625 7.85611 2.15625H8.57024V1.4375C8.57024 0.642383 9.20849 0 9.9985 0ZM1.42894 21.5625C1.42894 20.7674 2.06719 20.125 2.8572 20.125H17.1398C17.9298 20.125 18.5681 20.7674 18.5681 21.5625C18.5681 22.3576 17.9298 23 17.1398 23H2.8572C2.06719 23 1.42894 22.3576 1.42894 21.5625Z"
        fill="#999999"
      />
    </svg>
  );
}
export default DefaultCardSVG;
