import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ContainerButtons, Wrapper } from './styles';

import { Button } from '~/components/Button';
import { clearModalSalesforceSatisfaction } from '~/store/modules/outsides/actions';
import { InfoIcon } from './Icons';

const ModalSalesforceSatisfaction: FC = () => {
  const dispatch = useDispatch();

  const clearModal = async () => {
    dispatch(clearModalSalesforceSatisfaction());
  };

  return (
    <Wrapper>
      <div>
        <InfoIcon />
      </div>
      <h1>Como está sendo sua experiência?</h1>
      <ContainerButtons>
        <Button
          text="Avaliar agora"
          type="button"
          onClick={() => {
            window.open(
              'https://iguasaneamento.my.site.com/digi/survey/runtimeApp.app?invitationId=0Ki4W000000V5UZ&surveyName=pesquisa_digi_igu&UUID=1de68320-1cbd-4b36-80ea-b9d891c03b09',
              '_blank',
            );
            clearModal();
          }}
        />
        <Button text="Avaliar depois" type="button" onClick={clearModal} />
      </ContainerButtons>
    </Wrapper>
  );
};

export default ModalSalesforceSatisfaction;
