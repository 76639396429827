import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

const ArrowBackwardSVG: FC<IProps> = ({ fill }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37097 0.189662C7.42967 0.129639 7.49985 0.0819326 7.57737 0.0493576C7.65489 0.0167827 7.73818 0 7.82232 0C7.90646 0 7.98975 0.0167827 8.06727 0.0493576C8.14479 0.0819326 8.21497 0.129639 8.27366 0.189662C8.39401 0.312692 8.46135 0.477642 8.46135 0.649378C8.46135 0.821114 8.39401 0.986063 8.27366 1.10909L1.52703 7.99863L8.27366 14.8895C8.33439 14.9514 8.38228 15.0247 8.4146 15.105C8.44691 15.1853 8.46301 15.2712 8.46198 15.3577C8.46095 15.4443 8.44281 15.5298 8.4086 15.6093C8.37439 15.6889 8.32477 15.7609 8.26259 15.8214C8.20335 15.879 8.13328 15.9244 8.05639 15.9551C7.9795 15.9857 7.8973 16.001 7.81448 16C7.73167 15.9989 7.64987 15.9817 7.57377 15.9491C7.49766 15.9166 7.42875 15.8694 7.37097 15.8104L0.19653 8.47971C0.0705222 8.351 0 8.17837 0 7.99863C0 7.81889 0.0705222 7.64626 0.19653 7.51755L7.37097 0.189662ZM12.9089 0.189662C12.9676 0.129639 13.0378 0.0819326 13.1153 0.0493576C13.1929 0.0167827 13.2761 0 13.3603 0C13.4444 0 13.5277 0.0167827 13.6052 0.0493576C13.6828 0.0819326 13.7529 0.129639 13.8116 0.189662C13.932 0.312692 13.9993 0.477642 13.9993 0.649378C13.9993 0.821114 13.932 0.986063 13.8116 1.10909L7.065 7.99863L13.8116 14.8895C13.8724 14.9514 13.9203 15.0247 13.9526 15.105C13.9849 15.1853 14.001 15.2712 14 15.3577C13.9989 15.4443 13.9808 15.5298 13.9466 15.6093C13.9124 15.6889 13.8627 15.7609 13.8006 15.8214C13.7413 15.879 13.6713 15.9244 13.5944 15.9551C13.5175 15.9857 13.4353 16.001 13.3525 16C13.2696 15.9989 13.1878 15.9817 13.1117 15.9491C13.0356 15.9166 12.9667 15.8694 12.9089 15.8104L5.7345 8.47971C5.60849 8.351 5.53797 8.17837 5.53797 7.99863C5.53797 7.81889 5.60849 7.64626 5.7345 7.51755L12.9089 0.189662Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default ArrowBackwardSVG;
