import React, { FC } from 'react';
import moment from 'moment';
import {
  ContainerBox,
  ContainerInfo,
  ContainerInfoTitle,
  ContainerInvoiceDetails,
  Row,
  Container,
  TextBox,
  ContainerText,
} from './styles';
import formatter from '~/utils/formatter';

interface IInvoiceDetails {
  data: any;
}

export const InvoiceDetails: FC<IInvoiceDetails> = ({ data }) => {
  return (
    <Container>
      <ContainerInfoTitle>Pagar com o PIX é muito mais fácil!</ContainerInfoTitle>
      <ContainerInvoiceDetails>
        <ContainerInfo>
          <TextBox>Cliente</TextBox>
          <ContainerBox>
            <TextBox>{data.consumer.name}</TextBox>
          </ContainerBox>
        </ContainerInfo>
        <ContainerInfo>
          <TextBox>Endereço</TextBox>
          <ContainerBox>
            <TextBox>{data.consumer.address}</TextBox>
          </ContainerBox>
        </ContainerInfo>
        <Row>
          <ContainerInfo>
            <TextBox>Data da leitura</TextBox>
            <ContainerBox>
              <TextBox>{moment(data?.read_date_hour).format('DD/MM/YYYY')}</TextBox>
            </ContainerBox>
          </ContainerInfo>
          <ContainerInfo>
            <TextBox>Hora da leitura</TextBox>
            <ContainerBox>
              <TextBox>{moment(data?.read_date_hour).format('HH:mm')} Hrs</TextBox>
            </ContainerBox>
          </ContainerInfo>
          <ContainerInfo>
            <TextBox>Tipo</TextBox>
            <ContainerBox>
              <TextBox>{data.rate_type}</TextBox>
            </ContainerBox>
          </ContainerInfo>
        </Row>
        <Row>
          <ContainerInfo>
            <TextBox>Consumo</TextBox>
            <ContainerBox>
              <TextBox>{data.billed_consumption}</TextBox>
            </ContainerBox>
          </ContainerInfo>
          <ContainerInfo>
            <TextBox>Período</TextBox>
            <ContainerBox>
              <TextBox>{data?.reference}</TextBox>
            </ContainerBox>
          </ContainerInfo>
          <ContainerInfo>
            <TextBox>Vencimento</TextBox>
            <ContainerBox>
              <TextBox>{moment(data?.maturity_date).format('DD/MM/YYYY')}</TextBox>
            </ContainerBox>
          </ContainerInfo>
        </Row>
        <ContainerInfo>
          <TextBox>Detalhamento da fatura</TextBox>
          <ContainerBox isDetail>
            {data?.items?.map((item: any, index: number) => (
              <ContainerText>
                <TextBox key={index && index}>{`${item.description}`}</TextBox>
                <TextBox key={index && index}>{`${formatter.formatCurrency(
                  item.amount,
                )}`}</TextBox>
              </ContainerText>
            ))}
          </ContainerBox>
        </ContainerInfo>
      </ContainerInvoiceDetails>
    </Container>
  );
};
