/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { Container } from './styles';
import { SubscribesInfoIcon } from './icons';
import { Breakpoints } from '~/styles';
import { IState } from '~/store/modules/types';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';

export const SubscribesInfo: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const successCreate = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.successCreate,
  );

  return (
    <Container successCreate={successCreate}>
      {successCreate ? (
        <>
          {isMobile ? (
            <>
              <div>
                <SubscribesInfoIcon />
                <p className="title">Reduza o consumo de papel!</p>
              </div>
              <div>
                <p className="subtitle">
                  Altere o{' '}
                  <Link to={RouteName.INVOICESCHANGEDELIVERYTYPE}>
                    tipo de entrega da fatura
                  </Link>{' '}
                  para via e-mail e torne a sua experiência conosco ainda mais
                  sustentável!
                  <br />
                  <br />
                  Estamos comprometidos com a preservação do meio ambiente e buscamos
                  constantemente formas de reduzir nosso impacto ambiental.
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <SubscribesInfoIcon />
              </div>
              <div>
                <p className="title">Reduza o consumo de papel!</p>
                <p className="subtitle">
                  Altere o{' '}
                  <Link to={RouteName.INVOICESCHANGEDELIVERYTYPE}>
                    tipo de entrega da fatura
                  </Link>{' '}
                  para via e-mail e torne a sua experiência conosco ainda mais
                  sustentável!
                  <br />
                  <br />
                  Estamos comprometidos com a preservação do meio ambiente e buscamos
                  constantemente formas de reduzir nosso impacto ambiental.
                </p>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {isMobile ? (
            <>
              <div>
                <SubscribesInfoIcon />
                <p className="title">30 dias grátis!</p>
              </div>
              <div>
                <p className="subtitle">
                  Conclua o cadastro e você só será cobrado a partir de 30 dias, caso
                  deseje continuar com a assinatura.
                  <br />
                  <br />
                  Caso contrário, você poderá cancelar a recorrência do plano a
                  qualquer momento e em todas as plataformas Digi Iguá!
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <SubscribesInfoIcon />
              </div>
              <div>
                <p className="title">30 dias grátis!</p>
                <p className="subtitle">
                  Conclua o cadastro e você só será cobrado a partir de 30 dias, caso
                  deseje continuar com a assinatura.
                  <br />
                  <br />
                  Caso contrário, você poderá cancelar a recorrência do plano a
                  qualquer momento e em todas as plataformas Digi Iguá!
                </p>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};
