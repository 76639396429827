/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container } from './styles';
import { SubscribesInfoIcon } from './icons';
import { Breakpoints } from '~/styles';

export const SubscribesInfo: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  return (
    <Container id="subscribe-card">
      {isMobile ? (
        <>
          <div>
            <SubscribesInfoIcon />
            <p className="title">Volte a ser um consumidor digital!</p>
          </div>
          <div>
            <p className="subtitle">
              Torne a sua experiência conosco mais sustentável!
              <br />
              Estamos comprometidos com a preservação do meio ambiente e buscamos
              constantemente formas de reduzir nosso impacto ambiental.
            </p>
          </div>
        </>
      ) : (
        <>
          <div>
            <SubscribesInfoIcon />
          </div>
          <div>
            <p className="title">Volte a ser um consumidor digital!</p>
            <p className="subtitle">
              Torne a sua experiência conosco mais sustentável!
              <br />
              Estamos comprometidos com a preservação do meio ambiente e buscamos
              constantemente formas de reduzir nosso impacto ambiental.
            </p>
          </div>
        </>
      )}
    </Container>
  );
};
