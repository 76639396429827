import React, { FC } from 'react';
import EmptySVG from '~/assets/Icons/Empty';
import {
  BarStatus,
  Order,
  OrderBar,
  OrderContent,
  OrderEmpty,
  Orders,
  OrdersWrapper,
  Title,
} from './styles';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { getOwnershipOrderDetailRequest } from '~/store/modules/ownership/actions';
import { getStatusDetail } from '~/utils/ownership';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface OrdersProps {
  orders: any[] | null;
}

const OrderList: FC<OrdersProps> = () => {
  const dispatch = useDispatch();

  const ownership = useSelector((item: IState) => item.ownership);

  const orders = ownership.data ? [...ownership.data] : [];

  type TColors =
    | 'transparent'
    | 'white'
    | 'black'
    | 'red'
    | 'redLight'
    | 'redSecond'
    | 'redAscendet'
    | 'redAscendetTwo'
    | 'Blue'
    | 'blueAscednet'
    | 'blueIntense'
    | 'gray'
    | 'graySeconde'
    | 'grayLight'
    | 'grayLightSeconde'
    | 'grayRgba'
    | 'grayDark'
    | 'blueSeconde'
    | 'greenLight'
    | 'green'
    | 'greenDark'
    | 'yellow'
    | 'yellowSeconde';

  return (
    <Orders>
      {orders && orders.length > 0 ? (
        <OrdersWrapper>
          <Title>Solicitações anteriores</Title>
          {orders.map((item) => (
            <Order
              key={item.case_id}
              onClick={() => {
                dispatch(getOwnershipOrderDetailRequest(item.case_id));
                Navigation.navigate(RouteName.OWNERSHIPMANAGEMENTVIEWORDER);
              }}
            >
              <BarStatus background={getStatusDetail(item.status).color} />

              <OrderBar bgColor={getStatusDetail(item.status).color as TColors} />
              <OrderContent>
                <div>
                  <div className="status">{getStatusDetail(item.status).label}</div>
                  <div className="protocol">Protocolo {item.caseNumber}</div>
                </div>
              </OrderContent>
            </Order>
          ))}
        </OrdersWrapper>
      ) : (
        <OrderEmpty>
          <Title>Solicitações anteriores</Title>
          <EmptySVG />
          <p>Você não possui solicitações anteriores!</p>
        </OrderEmpty>
      )}
    </Orders>
  );
};

export { OrderList };
