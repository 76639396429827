import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export const ContainerBreadCrumb = styled.div`
  width: 100%;
`;

export const Main = styled.div`
  padding: 20px 9px 63px 9px;
  width: 100%;
  background: ${COLORS.white};
  box-sizing: border-box;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    padding: 48px 0;
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    padding: 22px 80px 22px 51px;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 22px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${COLORS.secondary};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const TextContent = styled.p`
  color: ${COLORS.secondary};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  strong {
    font-weight: 700;
  }

  a {
    color: ${COLORS.highlight};
    font-weight: 700;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: 100%;
    max-width: 252px;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    filter: none;
  }

  button:first-of-type {
    svg {
      left: 16px;
    }
  }

  button:last-of-type {
    svg {
      right: 16px;
    }
  }
`;

export const ContainerButtonLogged = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  button {
    width: 100%;
    max-width: 252px;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    filter: none;
  }

  svg {
    right: 16px;
  }
`;

export const ButtonInfo = styled.button<{ isActive: boolean }>`
  width: 60%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 14px;
  border-radius: 11px;
  background: ${({ isActive }) => (isActive ? Color.blueSeconde : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
  border: 2px solid ${Color.blueSeconde};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 12px;
    path {
      fill: ${({ isActive }) => (isActive ? Color.white : Color.blueSeconde)};
    }
    ${({ isActive }) =>
      !isActive &&
      `
      transform: rotate(180deg);
  `};
  }
`;
