import ReactGA from 'react-ga4';
import { Dispatch } from 'redux';
import { RouteName } from '~/routes/Routes.name';
import {
  getEnrollmentsPaymentReceiptNewService,
  getEnrollmentsPaymentReceiptService,
  getEnrollmentsSituationPixService,
  postEnrollmentsPaymentService,
} from '~/services/enrollmentsPayment';
import Navigation from '~/utils/Navigation';
import {
  IPaymentVoucher,
  IRequestIPaymentVoucher,
  IRequestPayment,
  IRequestSituationPix,
  IResponseSituationPix,
  RepositoriesTypesPayment,
} from './types';

const getEnrollmentsSituationPixRequest =
  ({ enrollmentId, barCodeNumber }: IRequestSituationPix) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsSituationPixService({
        enrollmentId,
        barCodeNumber,
      })) as IResponseSituationPix;

      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_SUCCESS,
        payload: {
          data: {
            isPaid: response.isPaid,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_SITUATION_PIX_METHOD_FAILURE,
      });
    }
  };

const postEnrollmentsPaymentRequest =
  (data: IRequestPayment, isBraspag: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_REQUEST,
      });

      await postEnrollmentsPaymentService(data, isBraspag);

      const responseReceipt = (await getEnrollmentsPaymentReceiptService(
        data,
      )) as IPaymentVoucher;

      Navigation.navigate(RouteName.INVOICERECEIP);

      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Pagamento com Sucesso',
      });

      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_SUCCESS,
        payload: {
          data: {
            ...responseReceipt,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const postErrorCloseEnrollmentsPayment = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

const getEnrollmentsPaymentReceiptRequest =
  (data: IRequestIPaymentVoucher) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsPaymentReceiptNewService(
        data,
      )) as IPaymentVoucher;

      Navigation.navigate(RouteName.INVOICERECEIP);

      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPayment.ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getErrorClosePaymentReceipt = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPayment.ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

export {
  getEnrollmentsSituationPixRequest,
  postEnrollmentsPaymentRequest,
  postErrorCloseEnrollmentsPayment,
  getEnrollmentsPaymentReceiptRequest,
  getErrorClosePaymentReceipt,
};
