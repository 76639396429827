import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

export const Main = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 26px 10px 100px 10px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 198px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 70px);
    padding: 38px 35px 203px 35px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    padding: 24px 0 241px 0;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 0 24px 0;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    display: inherit;
  }
`;

export const Card = styled.div`
  width: calc(100% - 16px);
  padding: 44px 8px 64px 8px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-top: 30px solid ${Color.blueIntense};
  border-radius: 24px;

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    height: 62px;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 96px);
    padding: 25px 40px 64px 40px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 160px);
    max-width: calc(700px - 80px);
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(1010px - 80px);
    max-width: calc(1010px - 80px);
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 16px);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.blueIntense};
    text-align: left;
    background: ${Color.white};
    padding: 0 24px;
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 80px);

    h2 {
      position: absolute;
      left: 20px;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    h2 {
      left: 80px;
    }
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const ContainerPayment = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;

  .container-inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 8px;

    input,
    select {
      height: 36px;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #000;
    }

    select {
      padding: 0 16px 0 12px;
      background-position: 97% 50%;
    }
  }

  .container-inputs-box {
    flex-wrap: nowrap;

    > div {
      width: calc(50% - 8px);
      label {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-wrap: nowrap;

    .container-inputs {
      flex-wrap: nowrap;
    }
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    .container-inputs-box {
      > div {
        width: 100%;
      }
    }
  }
`;

export const BoxDivider = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 50%;
  }
`;

export const ContainerCardTemplate = styled.div`
  height: 172px;
  width: 100%;

  @media screen and (min-width: ${Breakpoints.tablet.min}px) {
    height: 196px;
    max-width: 299px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 245px;
    max-width: inherit;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  margin-top: 24px;

  button {
    height: 48px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    :first-of-type {
      order: 2;
      background: ${Color.white};
      color: ${Color.blueSeconde};
      border: 2px solid ${Color.blueSeconde};
    }

    :last-of-type {
      order: 1;
      svg {
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 0;

    button {
      max-width: 252px;

      :first-of-type {
        order: 1;
      }

      :last-of-type {
        order: 2;
      }
    }
  }
`;

export const ContainerAmount = styled.div`
  width: calc(100% + 16px);
  height: 24px;
  background: #f2f2f2;

  p {
    width: 100%;
    text-align: center;
    margin-right: 50px;
    color: ${Color.blueIntense};
    font-weight: 500;
    font-size: 16px;
    margin: 4px 0;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% + 80px);
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 0 40px;

    p {
      width: auto;
      text-align: right;
    }
  }
`;
