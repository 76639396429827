import React from 'react';
import { Color } from '../../styles';

interface IProp {
  color?: string;
  width?: number;
  height?: number;
  fillOpacity?: number;
}

const ArrowLeftSVG = ({
  color = Color.gray,
  width = 41,
  height = 69,
  fillOpacity = 1,
}: IProp) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9071 66.9038C39.8977 65.9446 40.4541 64.6438 40.4541 63.2875C40.4541 61.9312 39.8977 60.6305 38.9071 59.6713L12.7535 34.3524L38.9071 9.03354C39.8696 8.06885 40.4021 6.77681 40.3901 5.43569C40.3781 4.09457 39.8224 2.81168 38.8428 1.86333C37.8632 0.914986 36.538 0.37706 35.1527 0.36541C33.7673 0.353753 32.4327 0.869309 31.4362 1.80103L1.54712 30.7362C0.556611 31.6954 0.000168566 32.9961 0.000168685 34.3524C0.000168803 35.7087 0.556611 37.0095 1.54712 37.9687L31.4362 66.9038C32.427 67.8627 33.7707 68.4014 35.1717 68.4014C36.5727 68.4014 37.9163 67.8627 38.9071 66.9038Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default ArrowLeftSVG;
