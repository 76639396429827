import styled from 'styled-components';
import { Color } from '~/styles/colors';

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Color.gray};
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  color: ${Color.black};
`;
