import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 16px;
  }
`;

export const ButtonPayment = styled.button`
  width: 100%;
  height: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 8px 12px;
  gap: 4px;
  margin: auto 0 0 auto;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 176px;
  }
`;

export const LabelButtonPayment = styled.p`
  width: 100%;
  height: 12px;
  background: #afd7c3;
  border-radius: 4px;
  padding: 0 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #004b55;
`;

export const NextLabelButtonPayment = styled.p`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
`;
