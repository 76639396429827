import React from 'react';

const BorderQrCode = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 37.7816V18C2 9.16345 9.16344 2 18 2H37.7768"
        stroke="#C7C9C7"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BorderQrCode };
