import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  ContainerDrop,
  ContainerInfo,
  ContainerSelectedFileName,
  TextError,
} from './styles';
import UploadFileSVG from '~/assets/Icons/UploadFile';
import { Color } from '~/styles';
import TrashSVG from '~/assets/Icons/Trash';

interface Props {
  onFileUploaded: (file: File | null) => void;
  infoFileName?: string | '';
  disabled?: boolean;
  disabledDeleteFile?: boolean;
  name?: string;
  content?: File | null;
  error?: string;
}

const DropZone: React.FC<Props> = ({
  onFileUploaded,
  infoFileName,
  disabled,
  disabledDeleteFile,
  name,
  content,
  error,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  useEffect(() => {
    content && setSelectedFileUrl(content.name);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      setSelectedFileUrl(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/pdf': ['.pdf', '.jpg', '.jpeg', '.png'],
    },
    disabled,
  });

  return (
    <>
      <ContainerDrop {...getRootProps()} error={error}>
        <input {...getInputProps()} name={name} />
        {selectedFileUrl ? (
          <ContainerSelectedFileName error={!!error}>
            {' '}
            <p>{selectedFileUrl}</p>
            <button
              type="button"
              onClick={() => {
                if (disabledDeleteFile) return;
                setSelectedFileUrl('');
                onFileUploaded(null);
              }}
            >
              <TrashSVG width={20} height={24} />
            </button>
          </ContainerSelectedFileName>
        ) : (
          <ContainerInfo error={content ? false : !!error}>
            <p>{infoFileName}</p>
            <div>
              <UploadFileSVG color={Color.gray} newIcon />
            </div>
          </ContainerInfo>
        )}
      </ContainerDrop>
      {error && (
        <TextError>
          {error === 'empty' ? 'Campo obrigatório' : 'Arquivo vazio ou corrompido'}
        </TextError>
      )}
    </>
  );
};

export default DropZone;
