import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  select,
  textarea {
    color: #000;
  }

  textarea {
    height: calc(74px - 18px);
  }
`;
