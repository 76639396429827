import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  margin: 0px auto;
  max-width: 1010px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 4px 26px 0px ${Color.black}19;
`;

interface IHeader {
  bgColor: string;
}

export const Header = styled.div<IHeader>`
  padding: 12px 80px;
  background: ${(p) => p.bgColor};
  text-align: center;
  text-transform: uppercase;
  color: ${Color.white};
  font-size: 16px;
  font-weight: 300;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  > :nth-child(1) {
    align-self: center;
    grid-column-start: 2;
  }
`;

export const Content = styled.div`
  padding: 0;
`;
