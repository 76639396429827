import { Color } from '~/styles/colors';

export const statusNegotiationColor = (status: string): string => {
  switch (status?.toUpperCase()) {
    case 'NEGOCIAÇÃO EFETIVADA':
      return Color.blueSeconde;
    case 'QUITADO':
      return Color.green;
    case 'PENDENTE':
      return Color.yellow;
    default:
      return Color.grayLightFourth;
  }
};
